import React from 'react';
import {
  Box,
  Typography,
  Chip,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@mui/material';
import {
  Settings as SettingsIcon,
  Code as ApiIcon,
  Store as StoreIcon,
  ReceiptLong as ReceiptIcon,
  Inventory as InventoryIcon,
  Payment as PaymentIcon,
  Check as CheckIcon,
  Sync as SyncIcon,
  BugReport as BugIcon
} from '@mui/icons-material';

// Ícones para diferentes tipos de integração
const integrationIcons = {
  erp: <StoreIcon fontSize="small" />,
  invoice: <ReceiptIcon fontSize="small" />,
  inventory: <InventoryIcon fontSize="small" />,
  payment: <PaymentIcon fontSize="small" />,
  webhook: <ApiIcon fontSize="small" />,
  default: <SyncIcon fontSize="small" />
};

// Cores para diferentes status de integração
const statusColors = {
  active: 'success',
  pending: 'warning',
  error: 'error',
  disabled: 'default'
};

const IntegrationPanel = ({ integrations = [] }) => {
  if (!integrations || integrations.length === 0) {
    return (
      <Box className="text-center py-2">
        <Typography variant="body2" className="text-gray-500 italic">
          Nenhuma integração configurada
        </Typography>
      </Box>
    );
  }

  return (
    <List dense className="p-0">
      {integrations.map((integration) => {
        const IconComponent = integrationIcons[integration.type] || integrationIcons.default;
        const statusColor = statusColors[integration.status] || 'default';

        return (
          <ListItem 
            key={integration.id}
            className="py-1 px-2 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <ListItemIcon className="min-w-0 mr-2">
              {IconComponent}
            </ListItemIcon>
            
            <ListItemText 
              primary={
                <Typography variant="body2" className="font-medium">
                  {integration.name}
                </Typography>
              }
              secondary={
                <Typography variant="caption" className="text-gray-500">
                  {integration.description || `Integração com ${integration.type.toUpperCase()}`}
                </Typography>
              }
            />
            
            <Box className="ml-2 flex items-center">
              <Chip 
                label={integration.status}
                size="small"
                color={statusColor}
                variant="outlined"
                className="mr-1"
              />
              
              <Tooltip title="Configurar integração">
                <IconButton size="small">
                  <SettingsIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </ListItem>
        );
      })}
      
      {/* Status de saúde das integrações */}
      {integrations.some(i => i.status === 'error') && (
        <Box className="mt-2 p-2 bg-red-50 rounded-lg border border-red-100">
          <Typography variant="caption" className="text-red-700 flex items-center">
            <BugIcon fontSize="small" className="mr-1" />
            Há problemas em uma ou mais integrações.
          </Typography>
        </Box>
      )}
    </List>
  );
};

export default IntegrationPanel;
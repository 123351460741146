import React, { useState } from 'react';
import {
  Box,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  Person as PersonIcon,
  AssignmentInd as AssignmentIndIcon,
  Clear as ClearIcon
} from '@mui/icons-material';
import { User } from './types/CardTypes';

interface UserAssignmentDropdownProps {
  assignedUser: User | undefined;
  handleUserAssignment: (userId: string) => void;
  availableUsers: User[];
  isLoadingUsers: boolean;
}

const UserAssignmentDropdown: React.FC<UserAssignmentDropdownProps> = ({
  assignedUser,
  handleUserAssignment,
  availableUsers,
  isLoadingUsers
}) => {
  console.log("UserAssignmentDropdown rendering with:", { 
    assignedUser, 
    availableUsersCount: availableUsers.length,
    isLoadingUsers 
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserSelect = (userId: string) => {
    handleUserAssignment(userId);
    handleClose();
  };

  // Encontrar usuário por ID se assignedUser não estiver definido mas formData.assignedToId estiver
  console.log("Rendering dropdown with assignedUser:", assignedUser);
  
  // Debug para entender melhor o problema
  if (!assignedUser && availableUsers.length > 0) {
    console.log("Available users:", availableUsers.map(u => ({ id: u.id, name: u.name })));
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isLoadingUsers ? (
        <CircularProgress size={20} sx={{ mr: 1 }} />
      ) : assignedUser ? (
        <Tooltip title="Alterar responsável">
          <Chip
            avatar={
              <Avatar 
                sx={{ 
                  bgcolor: '#635AFF',
                }}
              >
                {assignedUser.name.charAt(0).toUpperCase()}
              </Avatar>
            }
            label="Alterar responsável"
            variant="filled"
            sx={{
              mx: 1,
              bgcolor: 'rgba(99, 90, 255, 0.1)',
              color: '#635AFF',
              fontWeight: 500,
              border: '1px solid rgba(99, 90, 255, 0.2)',
              height: '32px',
              '& .MuiChip-label': { px: 1 },
              '& .MuiChip-avatar': { ml: 0.5 }
            }}
            onDelete={() => handleUserAssignment('')}
            deleteIcon={
              <ClearIcon 
                fontSize="small" 
                sx={{ 
                  color: 'rgba(99, 90, 255, 0.6)', 
                  '&:hover': { color: '#635AFF' } 
                }} 
              />
            }
            onClick={handleClick}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Atribuir responsável">
          <Button
            size="small"
            startIcon={<AssignmentIndIcon />}
            variant="outlined"
            onClick={handleClick}
            sx={{
              mx: 1,
              color: '#635AFF',
              borderColor: 'rgba(99, 90, 255, 0.3)',
              '&:hover': {
                borderColor: '#635AFF',
                bgcolor: 'rgba(99, 90, 255, 0.05)'
              }
            }}
          >
            Atribuir
          </Button>
        </Tooltip>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            maxHeight: 300,
            width: 250,
            overflow: 'auto',
            mt: 1.5,
            borderRadius: 2,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.1)',
              borderRadius: '4px',
            },
          }
        }}
      >
        <Box sx={{ px: 2, py: 1.5, borderBottom: '1px solid rgba(0,0,0,0.06)' }}>
          <Typography variant="subtitle2" fontWeight={600} color="#2d3748">
            Selecione um responsável
          </Typography>
        </Box>
        
        {availableUsers.length === 0 && !isLoadingUsers ? (
          <MenuItem disabled>
            <ListItemText primary="Nenhum usuário disponível" />
          </MenuItem>
        ) : (
          availableUsers.map((user) => (
            <MenuItem 
              key={user.id} 
              onClick={() => handleUserSelect(user.id)}
              sx={{ 
                py: 1,
                '&:hover': { bgcolor: 'rgba(99, 90, 255, 0.05)' }
              }}
            >
              <ListItemIcon>
                <Avatar sx={{ bgcolor: '#635AFF', width: 32, height: 32 }}>
                  {user.name.charAt(0).toUpperCase()}
                </Avatar>
              </ListItemIcon>
              <ListItemText 
                primary={user.name} 
                secondary={user.email}
                primaryTypographyProps={{ fontWeight: 500 }}
                secondaryTypographyProps={{ 
                  fontSize: '0.75rem', 
                  color: '#64748b',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </MenuItem>
          ))
        )}
      </Menu>
    </Box>
  );
};

export default UserAssignmentDropdown;
import React from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'

interface ProdutoLoadingProps {
  message?: string
}

export const ProdutoLoading: React.FC<ProdutoLoadingProps> = ({ 
  message = 'Carregando produtos...' 
}) => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        py: 8,
        flexDirection: 'column',
        gap: 2
      }}
    >
      <CircularProgress size={40} />
      <Typography variant="body1">{message}</Typography>
    </Box>
  )
}
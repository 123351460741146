'use client'

import { BotMessageSquare, MessageSquare, BotMessageSquareIcon, User } from 'lucide-react'
import React, { ReactElement } from 'react'


interface ChatBoxProps {
  value?: string
  onClick?: () => void
  onOptionsClick?: (e) => void
  isSelected?: boolean
  isWhatsApp?: boolean
}

export const ChatBox: React.FC<ChatBoxProps> = ({
  value,
  onClick,
  onOptionsClick,
  isSelected = false,
  isWhatsApp = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex flex-row items-center hover:bg-gray-100 rounded-xl p-2 w-full ${
        isSelected ? 'bg-indigo-100 text-indigo-800' : ''
      }`}
    >
      <div className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full">
        {isWhatsApp ? (
          <MessageSquare size={16} className="text-indigo-600" />
        ) : (
          value.charAt(0).toUpperCase()
        )}
      </div>
      <div className="ml-2 text-sm font-semibold">{value}</div>
      <div className="ml-auto">
        <button onClick={onOptionsClick} className="text-gray-500 hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
          </svg>
        </button>
      </div>
    </button>
  )
}

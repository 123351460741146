import React from 'react'
import { Box } from '@mui/material'
import { Produto } from '../types'
import { ProdutoCard } from './ProdutoCard'
import { ProdutoEmpty } from './ProdutoEmpty'
import { ProdutoLoading } from './ProdutoLoading'
import { PaginationComponent } from '../../../../../components/PaginationComponent/PaginationComponent'

interface ProdutoListProps {
  produtos: Produto[]
  loading: boolean
  categoryFilter: string
  currentPage: number
  produtosPerPage: number
  hasFilters: boolean
  onCardClick: (produto: Produto) => void
  onCategorySelect: (category: string) => void
  onPageChange: (page: number) => void
  onAddNew: () => void
}

export const ProdutoList: React.FC<ProdutoListProps> = ({
  produtos,
  loading,
  categoryFilter,
  currentPage,
  produtosPerPage,
  hasFilters,
  onCardClick,
  onCategorySelect,
  onPageChange,
  onAddNew
}) => {
  // Obtém os produtos da página atual
  const getCurrentPageProducts = () => {
    const startIndex = (currentPage - 1) * produtosPerPage
    const endIndex = startIndex + produtosPerPage
    return produtos.slice(startIndex, endIndex)
  }

  if (loading) {
    return <ProdutoLoading />
  }

  if (produtos.length === 0) {
    return <ProdutoEmpty hasFilters={hasFilters} onAddNew={onAddNew} />
  }

  return (
    <Box>
      {/* Grid para os produtos da página atual */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
          gap: '16px',
          mb: 4,
          maxHeight: 'calc(100vh - 320px)',
          overflow: 'auto',
          pb: 2
        }}
      >
        {getCurrentPageProducts().map((produto) => (
          <ProdutoCard
            key={produto.id}
            produto={produto}
            categoryFilter={categoryFilter}
            onCategorySelect={onCategorySelect}
            onClick={() => onCardClick(produto)}
          />
        ))}
      </Box>
      
      {/* Componente de paginação */}
      {produtos.length > 0 && (
        <PaginationComponent
          currentPage={currentPage}
          totalItems={produtos.length}
          itemsPerPage={produtosPerPage}
          onPageChange={onPageChange}
          itemName="produtos"
        />
      )}
    </Box>
  )
}
// src/mocks/products.ts
export const mockProducts = [
  {
    id: "prod-001",
    nome: "Notebook Dell Inspiron",
    tipo: "produto",
    valor: 3999.9,
    codigo: "NOTE-001",
  },
  {
    id: "prod-002",
    nome: "Monitor 24 polegadas",
    tipo: "produto",
    valor: 899.9,
    codigo: "MON-001",
  },
  {
    id: "prod-003",
    nome: "Cadeira de Escritório",
    tipo: "produto",
    valor: 559.9,
    codigo: "CAD-002",
  },
  {
    id: "serv-001",
    nome: "Consultoria em TI",
    tipo: "servico",
    valor: 150.0,
    codigo: "CONS-TI-001",
  },
  {
    id: "serv-002",
    nome: "Desenvolvimento de Website",
    tipo: "servico",
    valor: 4800.0,
    codigo: "DEV-WEB-001",
  },
  {
    id: "serv-003",
    nome: "Suporte Técnico Mensal",
    tipo: "servico",
    valor: 349.9,
    codigo: "SUP-TEC-001",
  },
];

// src/mocks/contacts.ts
export const mockContacts = [
  {
    id: "cont-001",
    nome: "João Silva",
    email: "joao@empresa.com",
    telefone: "(11) 98765-4321",
    empresa: "Empresa ABC",
  },
  {
    id: "cont-002",
    nome: "Maria Santos",
    email: "maria@tech.com",
    telefone: "(21) 91234-5678",
    empresa: "Tech Solutions",
  },
  {
    id: "cont-003",
    nome: "Pedro Oliveira",
    email: "pedro@consultoria.com",
    telefone: "(31) 99876-5432",
    empresa: "Consultoria XYZ",
  },
];

// src/mocks/companies.ts
export const mockCompanies = [
  {
    id: "emp-001",
    nome: "Empresa ABC",
    cnpj: "12.345.678/0001-99",
    endereco: "Rua A, 123",
  },
  {
    id: "emp-002",
    nome: "Tech Solutions",
    cnpj: "98.765.432/0001-10",
    endereco: "Av. B, 456",
  },
  {
    id: "emp-003",
    nome: "Consultoria XYZ",
    cnpj: "45.678.912/0001-33",
    endereco: "Praça C, 789",
  },
];

// src/mocks/debits.ts
export const mockDebits = [
  {
    id: "deb-001",
    valor: 1250.0,
    vencimento: "2023-05-15",
    descricao: "Fatura #12458",
    dias_atraso: 35,
    empresa: "Empresa ABC",
  },
  {
    id: "deb-002",
    valor: 780.5,
    vencimento: "2023-06-01",
    descricao: "Fatura #12502",
    dias_atraso: 18,
    empresa: "Tech Solutions",
  },
  {
    id: "deb-003",
    valor: 3200.0,
    vencimento: "2023-04-30",
    descricao: "Fatura #12445",
    dias_atraso: 50,
    empresa: "Consultoria XYZ",
  },
];

// src/mocks/activities.ts
export const mockActivities = [
  {
    id: "act-001",
    type: "call",
    title: "Primeira abordagem",
    description: "Entrei em contato para apresentar nossa solução.",
    date: "2023-05-10",
    completed: true,
    outcome: "Cliente mostrou interesse no produto X",
    userId: "user-1",
    userName: "Carlos Vendas",
    createdAt: "2023-05-10T14:30:00Z",
  },
  {
    id: "act-002",
    type: "meeting",
    title: "Reunião de apresentação",
    description: "Apresentação detalhada dos produtos.",
    date: "2023-05-15",
    scheduled: "2023-05-15T10:00:00Z",
    completed: true,
    outcome: "Cliente solicitou proposta comercial",
    userId: "user-1",
    userName: "Carlos Vendas",
    createdAt: "2023-05-12T09:15:00Z",
  },
  {
    id: "act-003",
    type: "email",
    title: "Envio de proposta",
    description: "Enviei proposta comercial conforme solicitado",
    date: "2023-05-17",
    completed: true,
    outcome: "Proposta enviada, aguardando feedback",
    userId: "user-1",
    userName: "Carlos Vendas",
    createdAt: "2023-05-17T16:45:00Z",
  },
  {
    id: "act-004",
    type: "whatsapp",
    title: "Follow-up da proposta",
    description:
      "Verificação do recebimento da proposta e esclarecimento de dúvidas",
    date: "2023-05-20",
    completed: false,
    userId: "user-1",
    userName: "Carlos Vendas",
    createdAt: "2023-05-19T11:20:00Z",
  },
];

import { useEffect } from 'react'

import { ThemeProvider } from '@emotion/react'
import { ToastContainer, theme } from '@intraversa-lab/styleguide'
import {
  Box,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material'
import { queryClient } from 'configs/queryClient'
import { QueryClientProvider } from 'react-query'
import { WizardsProvider } from './pages/Main/containers/chat/context/WizardsContext'
import WizardsContainer from './pages/Main/containers/chat/molecules/wizardsContainer/WizardsContainer'
import { BrowserRouter } from 'react-router-dom'

import { Routes } from './routes/Routes'

export default function Root(props) {
  useEffect(() => {
    return () => {
      queryClient.clear()
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <WizardsProvider>
            <Box sx={{ overflow: 'hidden', height: '100vh', width: '100vw' }}>
              <BrowserRouter>
                <CssBaseline />
                <ToastContainer />
                <Routes {...props} />
                <WizardsContainer />
              </BrowserRouter>
            </Box>
          </WizardsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  )
}

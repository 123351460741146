import React from 'react';
import { Button } from '@mui/material';

export const EmptyChatList = ({ searchTerm, handleNewChat }) => {
  return (
    <div className="flex flex-col items-center justify-center text-center p-6 text-gray-500">
      <div className="bg-gray-100 p-3 rounded-full mb-2">
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
          ></path>
        </svg>
      </div>
      <p className="text-sm">
        Nenhuma conversa encontrada
        {searchTerm ? ' para sua busca' : ''}.
      </p>
      <Button
        variant="text"
        color="primary"
        onClick={handleNewChat}
        size="small"
        sx={{ mt: 1, textTransform: 'none' }}
      >
        Iniciar nova conversa
      </Button>
    </div>
  );
};
import { useState, useEffect, useCallback } from "react";
import {
  fetchBoardCards,
  updateCardPosition,
  saveCard,
  deleteCardById,
} from "../services/kanbanApi";

import { useParams } from "react-router-dom";

/**
 * Hook para gerenciar os dados do kanban
 *
 * @param {string} boardType - Tipo do quadro ('lead' ou 'inadimplencia')
 * @returns {Object} - Métodos e estados para manipular os dados do kanban
 */
const useKanbanData = (boardType) => {
  const [cards, setCards] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { rpaId } = useParams();

  // Função para carregar os cards do quadro (memoizada)
  const loadCards = useCallback(async () => {
    setLoading(true);
    try {
      // Em produção, isso viria da API
      const response = await fetchBoardCards(boardType, rpaId);
      setCards(response.cards);
      setColumns(response.columns);
      setError(null);
    } catch (err) {
      console.error("Erro ao carregar cards:", err);
      setError("Falha ao carregar os dados do kanban");
    } finally {
      setLoading(false);
    }
  }, [boardType, rpaId]);

  // Carrega os cards do quadro somente quando o tipo de quadro ou rpaId mudam
  useEffect(() => {
    loadCards();
  }, [loadCards]);

  /**
   * Move um card entre colunas
   */
  const moveCard = useCallback(
    async (
      cardId,
      sourceColumnId,
      destinationColumnId,
      sourceIndex,
      destinationIndex,
    ) => {
      // Primeiro, atualizamos o estado local para feedback imediato ao usuário
      setCards((prevCards) => {
        const updatedCards = prevCards.map((card) => {
          if (card.id === cardId) {
            return { ...card, columnId: destinationColumnId };
          }
          return card;
        });

        // Reorganiza os cards para refletir a nova ordem
        // Na implementação completa, você poderia ter um campo 'order' em cada card
        return updatedCards;
      });

      try {
        // Em produção, isso seria uma chamada à API
        await updateCardPosition(
          cardId,
          destinationColumnId,
          destinationIndex,
          rpaId,
        );
      } catch (error) {
        console.error("Erro ao atualizar posição do card:", error);
        // Rollback em caso de erro
        setCards((prevCards) => {
          const updatedCards = prevCards.map((card) => {
            if (card.id === cardId) {
              return { ...card, columnId: sourceColumnId };
            }
            return card;
          });
          return updatedCards;
        });

        setError("Falha ao mover o card. Tente novamente.");
      }
    },
    [rpaId], // Adicionado rpaId como dependência
  );

  /**
   * Adiciona um novo card
   */
  const addCard = useCallback(async (cardData) => {
    try {
      // Em produção, isso seria uma chamada à API
      const newCard = await saveCard(cardData);

      setCards((prevCards) => [...prevCards, newCard]);
      return newCard;
    } catch (error) {
      console.error("Erro ao adicionar card:", error);
      setError("Falha ao adicionar o card");
      throw error;
    }
  }, []);

  /**
   * Atualiza um card existente
   */
  const updateCard = useCallback(async (cardData) => {
    try {
      // Em produção, isso seria uma chamada à API
      const updatedCard = await saveCard(cardData);

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.id === updatedCard.id ? updatedCard : card,
        ),
      );
      return updatedCard;
    } catch (error) {
      console.error("Erro ao atualizar card:", error);
      setError("Falha ao atualizar o card");
      throw error;
    }
  }, []);

  /**
   * Remove um card
   */
  const deleteCard = useCallback(async (cardId) => {
    try {
      // Em produção, isso seria uma chamada à API
      await deleteCardById(cardId);

      setCards((prevCards) => prevCards.filter((card) => card.id !== cardId));
    } catch (error) {
      console.error("Erro ao excluir card:", error);
      setError("Falha ao excluir o card");
      throw error;
    }
  }, []);

  /**
   * Filtra os cards com base em critérios
   */
  const filterCards = useCallback(
    (filters) => {
      if (!filters || Object.keys(filters).length === 0) {
        return cards;
      }

      return cards.filter((card) => {
        // Implementar lógica de filtro conforme necessário
        let matchesFilter = true;

        if (filters.title) {
          matchesFilter =
            matchesFilter &&
            card.title.toLowerCase().includes(filters.title.toLowerCase());
        }

        if (filters.priority) {
          matchesFilter = matchesFilter && card.priority === filters.priority;
        }

        if (filters.assignedTo) {
          matchesFilter =
            matchesFilter && card.assignedTo?.id === filters.assignedTo;
        }

        return matchesFilter;
      });
    },
    [cards],
  );

  const reloadCards = useCallback(async () => {
    setLoading(true);
    try {
      // Em produção, isso viria da API
      const response = await fetchBoardCards(boardType, rpaId);
      setCards(response.cards);
      setColumns(response.columns);
      setError(null);
    } catch (err) {
      console.error("Erro ao recarregar cards:", err);
      setError("Falha ao recarregar os dados do kanban");
    } finally {
      setLoading(false);
    }
  }, [boardType, rpaId]);

  return {
    cards,
    columns,
    loading,
    error,
    moveCard,
    addCard,
    updateCard,
    deleteCard,
    filterCards,
    reloadCards,
  };
};

export default useKanbanData;

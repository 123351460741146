import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Tooltip
} from '@mui/material';
import {
  Storage,
  InfoOutlined
} from '@mui/icons-material';
import { IntegrationModule } from './types';
import * as S from './styles';

interface ModulesSectionProps {
  modules: IntegrationModule[];
  onModuleChange: (moduleId: string, checked: boolean) => void;
  loading: boolean;
}

const ModulesSection: React.FC<ModulesSectionProps> = ({
  modules,
  onModuleChange,
  loading
}) => {
  /**
   * Função para obter descrição de cada módulo
   * @param key Chave do módulo
   * @returns Descrição do módulo
   */
  const getModuleDescription = (key: string) => {
    switch(key) {
      case 'product':
        return 'Sincronização de produtos, preços e estoques';
      case 'customer':
        return 'Gestão de clientes e contatos';
      case 'order':
        return 'Pedidos de venda e compra';
      case 'financial':
        return 'Contas a pagar e receber, movimentações';
      default:
        return 'Módulo de integração com o ERP';
    }
  };

  return (
    <S.FormSection>
      <S.SectionTitle>
        <Storage fontSize="small" sx={{ mr: 1, verticalAlign: 'middle' }} />
        Módulos de Integração
      </S.SectionTitle>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Selecione os módulos que deseja integrar. Apenas os módulos selecionados sincronizarão dados entre sistemas.
      </Typography>
      
      <S.ModulesGrid>
        {modules.map(module => (
          <Card key={module.id || module.key} variant="outlined" sx={{ 
            position: 'relative',
            border: module.active ? '1px solid #3f51b5' : '1px solid #e0e0e0',
            bgcolor: module.active ? 'rgba(63, 81, 181, 0.04)' : 'white'
          }}>
            <CardContent>
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={module.active}
                    onChange={(e) => onModuleChange(module.id || module.key, e.target.checked)}
                    color="primary"
                    disabled={loading}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body2" fontWeight={500}>
                      {module.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {getModuleDescription(module.key)}
                    </Typography>
                  </Box>
                }
                sx={{ mb: 0 }}
              />
              <Tooltip title={getModuleDescription(module.key)} placement="top">
                <InfoOutlined
                  fontSize="small"
                  color="action"
                  sx={{ position: 'absolute', top: 12, right: 12 }}
                />
              </Tooltip>
            </CardContent>
          </Card>
        ))}
      </S.ModulesGrid>
    </S.FormSection>
  );
};

export default ModulesSection;
import React, { useState } from 'react';
import {
  Box,
  Avatar,
  Typography,
  Chip,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  Search as SearchIcon,
  Person as PersonIcon,
  Info as InfoIcon,
  Check as CheckIcon,
  PersonAdd as PersonAddIcon
} from '@mui/icons-material';

// Lista de usuários mockada
const mockUsers = [
  { id: 'user1', name: 'Ana Silva', department: 'Vendas', avatar: '' },
  { id: 'user2', name: 'Bruno Costa', department: 'Financeiro', avatar: '' },
  { id: 'user3', name: 'Carla Mendes', department: 'Vendas', avatar: '' },
  { id: 'user4', name: 'Daniel Alves', department: 'Atendimento', avatar: '' },
  { id: 'user5', name: 'Eduardo Santos', department: 'Marketing', avatar: '' }
];

const UserAssignment = ({ 
  user, 
  onAssign = () => {}, 
  allowChange = true 
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  const handleClick = (event) => {
    if (allowChange) {
      setAnchorEl(event.currentTarget);
    }
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm('');
  };
  
  const handleUserSelect = (selectedUser) => {
    onAssign(selectedUser);
    handleClose();
  };
  
  const open = Boolean(anchorEl);
  
  // Filtrar usuários com base no termo de pesquisa
  const filteredUsers = mockUsers.filter(
    u => u.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
         u.department.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  return (
    <>
      {/* Exibição do usuário atribuído */}
      <Box 
        className={`flex items-center p-1 rounded ${allowChange ? 'cursor-pointer hover:bg-gray-100' : ''}`}
        onClick={handleClick}
      >
        <Avatar 
          src={user?.avatar} 
          alt={user?.name} 
          className="w-6 h-6 mr-2"
        >
          {user?.name ? user.name.charAt(0) : <PersonIcon fontSize="small" />}
        </Avatar>
        
        <Box>
          <Typography variant="body2" className="font-medium leading-tight">
            {user?.name || 'Não atribuído'}
          </Typography>
          
          {user?.department && (
            <Typography variant="caption" className="text-gray-500 block leading-tight">
              {user.department}
            </Typography>
          )}
        </Box>
        
        {allowChange && (
          <Tooltip title="Clique para alterar">
            <InfoIcon fontSize="small" className="ml-1 text-gray-400" />
          </Tooltip>
        )}
      </Box>
      
      {/* Popover para seleção de usuário */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: 'shadow-lg rounded-lg',
          sx: { width: 300 }
        }}
      >
        <Box className="p-3">
          <TextField
            placeholder="Buscar usuário..."
            fullWidth
            size="small"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          
          <List className="mt-2" dense>
            {filteredUsers.map((user) => (
              <ListItem 
                key={user.id}
                button
                onClick={() => handleUserSelect(user)}
                className="rounded-lg hover:bg-gray-100 transition-colors"
              >
                <ListItemAvatar>
                  <Avatar src={user.avatar}>
                    {user.name.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                
                <ListItemText
                  primary={user.name}
                  secondary={user.department}
                  primaryTypographyProps={{ className: 'font-medium' }}
                />
                
                <CheckIcon 
                  fontSize="small" 
                  className="text-green-500"
                  style={{ 
                    visibility: user.id === (user?.id || '') ? 'visible' : 'hidden' 
                  }}
                />
              </ListItem>
            ))}
            
            {filteredUsers.length === 0 && (
              <ListItem className="text-center justify-center">
                <ListItemText
                  primary="Nenhum usuário encontrado"
                  primaryTypographyProps={{ 
                    variant: 'body2',
                    className: 'text-gray-500 italic' 
                  }}
                />
              </ListItem>
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default UserAssignment;
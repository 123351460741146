import React from 'react'
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  Chip 
} from '@mui/material'
import { 
  AttachMoney as MoneyIcon,
  Inventory as InventoryIcon 
} from '@mui/icons-material'
import { Produto } from '../types'
import { extractTags } from '../utils/helpers'

interface ProdutoCardProps {
  produto: Produto
  categoryFilter: string
  onCategorySelect: (category: string) => void
  onClick: () => void
}

export const ProdutoCard: React.FC<ProdutoCardProps> = ({ 
  produto, 
  categoryFilter, 
  onCategorySelect, 
  onClick 
}) => {
  const handleCategoryClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onCategorySelect(produto.categoria)
  }

  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        overflow: 'hidden',
        position: 'relative',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        height: '100%',
        minHeight: '180px',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          transform: 'translateY(-3px)',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)'
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          backgroundColor: produto.isServico ? '#9c27b0' : '#3f51b5'
        }
      }}
    >
      <CardContent sx={{ 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        height: '100%'
      }}>
        {/* Cabeçalho com nome e valor */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1.5 }}>
          <Box>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontWeight: 'bold',
                color: '#333',
                fontSize: '16px'
              }}
            >
              {produto.nome}
            </Typography>
            
            <Typography 
              variant="body2"
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                color: '#666',
                mt: 0.5,
                fontSize: '14px'
              }}
            >
              <span style={{ marginRight: '8px' }}>🏷️</span>
              {produto.codigo}
            </Typography>
          </Box>
          
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 'bold',
              color: produto.isServico ? '#9c27b0' : '#3f51b5',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <MoneyIcon fontSize="small" sx={{ mr: 0.5 }} />
            {produto.valor.toLocaleString('pt-BR', { 
              style: 'currency', 
              currency: 'BRL' 
            })}
          </Typography>
        </Box>
        
        {/* Descrição do produto */}
        <Typography
          variant="body2"
          sx={{
            fontSize: '13px',
            color: '#777',
            mb: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
          }}
        >
          {produto.descricao}
        </Typography>
        
        {/* Informações adicionais */}
        <Box sx={{ mb: 2 }}>
          {/* Exibir estoque para produtos */}
          {!produto.isServico && produto.estoque !== undefined && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <InventoryIcon 
                fontSize="small" 
                sx={{ 
                  mr: 1, 
                  color: Number(produto.estoque) > 5 ? '#4caf50' : '#f44336' 
                }} 
              />
              <Typography variant="body2">
                Estoque: <strong>{produto.estoque}</strong> {produto.unidadeMedida}
              </Typography>
            </Box>
          )}
          
          {/* Exibir categoria */}
          <Chip
            label={produto.categoria}
            size="small"
            sx={{
              mt: 1,
              backgroundColor: categoryFilter === produto.categoria ? '#3f51b5' : '#f5f5f5',
              color: categoryFilter === produto.categoria ? 'white' : '#666',
              '&:hover': {
                backgroundColor: categoryFilter === produto.categoria ? '#303f9f' : '#e0e0e0',
                cursor: 'pointer'
              }
            }}
            onClick={handleCategoryClick}
          />
        </Box>
        
        {/* Tags do produto (se houver) */}
        {produto.tags && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1.5 }}>
            {extractTags(produto.tags).map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                size="small"
                sx={{
                  height: '20px',
                  fontSize: '11px',
                  backgroundColor: '#f5f5f5',
                  color: '#666',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                    cursor: 'pointer'
                  }
                }}
              />
            ))}
          </Box>
        )}
        
        {/* Rodapé com tipo e mensagem */}
        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                backgroundColor: produto.isServico ? '#f3e5f5' : '#e8eaf6',
                color: produto.isServico ? '#9c27b0' : '#3f51b5',
                padding: '3px 8px',
                borderRadius: '4px',
                fontSize: '11px',
                fontWeight: 'bold'
              }}
            >
              {produto.isServico ? 'Serviço' : 'Produto'}
            </Typography>
            
            <Typography
              sx={{
                fontSize: '12px',
                color: '#919eab',
                fontStyle: 'italic'
              }}
            >
              Clique para editar
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getUser } from 'hooks/getUser'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Chip,
  Snackbar,
  Alert,
  Avatar,
} from '@mui/material'
import {
  Close as CloseIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  EditNote as EditNoteIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { CardFormData, Column, BoardType } from './types/CardTypes' // Ajuste o caminho
import { useCardForm } from './hooks/useCardForm'

// Componentes modulares
import BasicInfoSection from './BasicInfoSection'
import ContactSection from './ContactSection'
import DebitsSection from './DebitsSection'
import ProductsSection from './ProductsSection'
import CompanySection from './CompanySection'
import DescriptionSection from './DescriptionSection'
import ActivitySection from './ActivitySection'
import UserAssignmentDropdown from './UserAssignmentDropdown'
import NewProductDialog from './NewProductDialog'
import EmpresaFormModal from 'pages/Main/containers/empresas/EmpresaForm'
import useEmpresaModal from 'pages/Main/containers/empresas/hooks/useEmpresaModal'
import { ContactFormDialog } from 'pages/Main/containers/contatos/ContactFormDialog'
import { Contato } from 'pages/Main/containers/contatos/ContactTypes'
import { ContactService } from 'pages/Main/containers/contatos/ContactService'
import ContatosDialog from 'pages/Main/containers/empresas/ContatosDialog'


// Endereço padrão para inicialização
const defaultEndereco = {
  zipCode: '',
  state: '',
  city: '',
  neighborhood: '',
  street: '',
  number: '',
  complement: '',
};

// Estilos compartilhados com design moderno de slide lateral
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '0',
    boxShadow: '-10px 0 40px rgba(0,0,0,0.15)',
    overflow: 'hidden',
    maxWidth: '100vw',
    width: '1200px', // Tamanho maior para melhor organização
    height: '100%',
    margin: 0,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    transform: 'none !important', // Previne a centralização padrão do Dialog
    transition: 'transform 0.3s ease-out !important',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(30, 30, 30, 0.5)',
    backdropFilter: 'blur(4px)',
  },
  '&.MuiModal-hidden .MuiDialog-paper': {
    transform: 'translateX(100%) !important',
  },
}))

const DialogHeader = styled(DialogTitle)(({ theme }) => ({
  background: 'linear-gradient(90deg, #fcfcfc, #f8f9fa)',
  borderBottom: '1px solid rgba(0,0,0,0.08)',
  padding: '24px 32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  zIndex: 10,
  backdropFilter: 'blur(8px)',
  '& .MuiTypography-h6': {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: '#2d3748',
  },
}))

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  overflow-y: auto;
  background-color: #f8f9fa;
  height: calc(100% - 70px - 80px); /* Altura total - header - footer */
  
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.02);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(99, 90, 255, 0.15);
    border-radius: 10px;
    border: 2px solid #f8f9fa;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(99, 90, 255, 0.25);
  }
`

const ActionButtons = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid rgba(0,0,0,0.08)',
  padding: '20px 32px',
  background: 'linear-gradient(90deg, #fcfcfc, #f8f9fa)',
  gap: '16px',
  position: 'sticky',
  bottom: 0,
  zIndex: 10,
  boxShadow: '0 -5px 15px rgba(0,0,0,0.03)',
}))

interface CardDialogProps {
  open: boolean
  onClose: () => void
  onSave: (card: CardFormData) => void
  onDelete: (cardId: string) => void
  card: CardFormData | null
  columns: Column[]
  boardType: BoardType
}

const CardDialog: React.FC<CardDialogProps> = ({
  open,
  onClose,
  onSave,
  onDelete,
  card,
  columns,
  boardType,
}) => {
  // Obtendo o rpaId da URL para uso em chamadas de API
  const { rpaId } = useParams();

  // Funções para carregar e exibir os formulários de edição
  const handleEditContact = (contactId: string) => {
    setEditContactId(contactId);
  };
  
  // Função para editar um produto
  const handleEditProduct = (productId: string) => {
    setEditProductId(productId);
  };
  
  // Função para buscar contatos
  const fetchContatos = async (term = '', page = 0, size = 10) => {
    if (!rpaId) return null;
    
    try {
      const axios = (await import("axios")).default;
      const { llmEndpoint } = await import("infra");
      
      console.log(`Buscando contatos: ${llmEndpoint.url}/whatsapp/contacts/${rpaId}/search com termo: "${term}"`);
      
      // Usar endpoint correto para busca de contatos
      const response = await axios.get(
        `${llmEndpoint.url}/whatsapp/contacts/${rpaId}/search`,
        {
          params: {
            term,
            page,
            size,
            sort: 'name',
            direction: 'asc'
          },
          headers: {
            accept: "*/*",
          },
        }
      );
      
      if (response.data) {
        console.log('Contatos disponíveis:', response.data);
        
        // Adaptação para diferentes formatos de resposta da API
        if (response.data.content) {
          console.log('Contatos disponíveis no formato content:', response.data.content);
          return response.data;
        } else if (Array.isArray(response.data)) {
          console.log('Contatos disponíveis como array:', response.data);
          // Se a resposta é um array, adaptamos para o formato esperado
          return {
            content: response.data,
            last: response.data.length < size
          };
        }
        
        return {
          content: [],
          last: true
        };
      }
      
      return {
        content: [],
        last: true
      };
    } catch (error) {
      console.error('Error fetching contatos:', error);
      return {
        content: [],
        last: true
      };
    }
  };
  
  // Função para abrir o diálogo de contatos e carregar a primeira página
  const handleOpenContatosDialog = async () => {
    console.log("handleOpenContatosDialog chamado");
    
    // Resetar estados de paginação e busca
    setContatoPage(0);
    setContatosHasMore(true);
    setSearchContato('');
    setContatosSelecionados([]);
    setLoadingContatos(true);
    
    console.log("Dados da empresa atual:", empresaData);
    
    // Carregar a primeira página de contatos
    const result = await fetchContatos('', 0, 10);
    console.log("Resultado da busca de contatos:", result);
    
    if (result && result.content) {
      // Obter IDs de contatos já adicionados à empresa
      const idsContatosJaAdicionados = empresaData.contatos?.map((c) => c.id) || [];
      console.log("IDs de contatos já adicionados:", idsContatosJaAdicionados);
      
      // Filtrar contatos que ainda não foram adicionados
      const disponiveis = Array.isArray(result.content) 
        ? result.content.filter((c) => !idsContatosJaAdicionados.includes(c.id))
        : [];
      
      console.log("Contatos disponíveis após filtragem:", disponiveis);

      setContatosDisponiveis(disponiveis);
      setContatosHasMore(!result.last);
    } else {
      console.log("Nenhum contato encontrado ou result.content é null/undefined");
      setContatosDisponiveis([]);
      setContatosHasMore(false);
    }
    
    setLoadingContatos(false);
    console.log("Abrindo diálogo de contatos");
    setContatosDialogOpen(true);
  };
  
  // Função para carregar mais contatos (paginação)
  const loadMoreContatos = async () => {
    console.log("loadMoreContatos chamado, contatosHasMore:", contatosHasMore, "loadingContatos:", loadingContatos);
    if (!contatosHasMore || loadingContatos) return;
    
    setLoadingContatos(true);
    const nextPage = contatoPage + 1;
    console.log("Carregando próxima página de contatos:", nextPage);
    
    const result = await fetchContatos(searchContato, nextPage, 10);
    console.log("Resultado da busca de mais contatos:", result);
    
    if (result && result.content) {
      // Obter IDs de contatos já adicionados à empresa
      const idsContatosJaAdicionados = empresaData.contatos?.map((c) => c.id) || [];
      console.log("IDs de contatos já adicionados:", idsContatosJaAdicionados);
      
      // Filtrar contatos que ainda não foram adicionados
      const novosContatos = Array.isArray(result.content) 
        ? result.content.filter((c) => !idsContatosJaAdicionados.includes(c.id))
        : [];
      
      console.log("Novos contatos para adicionar:", novosContatos);

      // Adicionar os novos contatos à lista existente
      setContatosDisponiveis(prev => [...prev, ...novosContatos]);
      setContatoPage(nextPage);
      setContatosHasMore(!result.last);
    } else {
      console.log("Nenhum contato encontrado na página adicional");
      setContatosHasMore(false);
    }
    
    setLoadingContatos(false);
  };
  
  // Função para buscar contatos com o termo de busca
  const handleSearchContatos = async (term: string) => {
    console.log("handleSearchContatos chamado com termo:", term);
    setContatoPage(0);
    setLoadingContatos(true);
    
    const result = await fetchContatos(term, 0, 10);
    console.log("Resultado da busca por termo:", result);
    
    if (result && result.content) {
      // Obter IDs de contatos já adicionados à empresa
      const idsContatosJaAdicionados = empresaData.contatos?.map((c) => c.id) || [];
      console.log("IDs de contatos já adicionados:", idsContatosJaAdicionados);
      
      // Filtrar contatos que ainda não foram adicionados
      const disponiveis = Array.isArray(result.content) 
        ? result.content.filter((c) => !idsContatosJaAdicionados.includes(c.id))
        : [];
      
      console.log("Contatos disponíveis após filtragem por termo:", disponiveis);

      setContatosDisponiveis(disponiveis);
      setContatosHasMore(!result.last);
    } else {
      console.log("Nenhum contato encontrado para o termo de busca");
      setContatosDisponiveis([]);
      setContatosHasMore(false);
    }
    
    setLoadingContatos(false);
  };
  
  // Função para definir o termo de busca
  const handleSetSearchContato = (term: string) => {
    console.log("Atualizando termo de busca para:", term);
    setSearchContato(term);
  };
  
  // Função para manipular a seleção de contatos no diálogo
  const handleToggleContato = (contatoId: string) => {
    setContatosSelecionados((prev) => {
      if (prev.includes(contatoId)) {
        return prev.filter((id) => id !== contatoId);
      } else {
        return [...prev, contatoId];
      }
    });
  };
  
  // Função para adicionar os contatos selecionados à empresa
  const handleAddContatosSelecionados = () => {
    if (!Array.isArray(contatosDisponiveis) || contatosDisponiveis.length === 0) {
      setContatosDialogOpen(false);
      return;
    }
    
    const novosContatos = contatosDisponiveis.filter((c) =>
      contatosSelecionados.includes(c.id)
    );

    // Adiciona cada contato selecionado usando o hook
    novosContatos.forEach((contato) => {
      handleAddContato(contato);
    });

    setContatosDialogOpen(false);
  };
  const {
    isOpen,
    isEdit,
    empresaData,
    openCreateModal,
    openEditModal,
    closeModal,
    setEmpresaNome,
    setEmpresaDocumento,
    setEmpresaDescricao,
    setEmpresaEndereco,
    setEmpresaTags,
    handleRemoveContato,
    handleAddContato,
  } = useEmpresaModal()
  
  // Estados para controlar os diálogos de edição
  const [editContactId, setEditContactId] = useState<string | null>(null);
  const [editCompanyId, setEditCompanyId] = useState<string | null>(null);
  const [editProductId, setEditProductId] = useState<string | null>(null);
  
  // Estados para controle do diálogo de contatos para vincular à empresa
  const [contatosDialogOpen, setContatosDialogOpen] = useState(false);
  const [contatosDisponiveis, setContatosDisponiveis] = useState<any[]>([]);
  const [contatosSelecionados, setContatosSelecionados] = useState<string[]>([]);
  const [searchContato, setSearchContato] = useState('');
  const [contatoPage, setContatoPage] = useState(0);
  const [contatosHasMore, setContatosHasMore] = useState(true);
  const [loadingContatos, setLoadingContatos] = useState(false);
  
  // Estados para o Snackbar de feedback
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  
  // Função para carregar dados da empresa quando o botão de editar for clicado
  const handleEditCompany = (companyId: string) => {
    console.log("handleEditCompany chamado com ID:", companyId);
    // Buscamos os dados da empresa selecionada para preencher o formulário
    const fetchCompanyData = async () => {
      try {
        const axios = (await import("axios")).default;
        const { llmEndpoint } = await import("infra");
        
        console.log(`Buscando dados da empresa: ${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/${companyId}`);
        
        const response = await axios.get(
          `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/${companyId}`,
          {
            headers: {
              accept: "*/*",
            },
          }
        );
        
        if (response.data) {
          console.log("Dados da empresa recebidos:", response.data);
          // Atualizar os dados da empresa no hook useEmpresaModal
          const companyData = response.data;
          const empresaFormatada = {
            id: companyData.id,
            nome: companyData.name || "",
            document: companyData.document || "",
            descricao: companyData.description || "",
            address: companyData.address || {
              zipCode: "",
              state: "",
              city: "",
              neighborhood: "",
              street: "",
              number: "",
              complement: "",
            },
            tags: companyData.tags || "",
            contatos: companyData.contacts || [],
          };
          
          console.log("Dados formatados da empresa:", empresaFormatada);
          
          // Resetar o editCompanyId antes de abrir o modal
          setEditCompanyId(companyId);
          
          // Abrir o modal de edição com os dados preenchidos (depois de um pequeno delay para garantir que tudo está configurado)
          setTimeout(() => {
            openEditModal(empresaFormatada);
          }, 0);
        }
      } catch (error) {
        console.error("Erro ao buscar dados da empresa:", error);
        // Em caso de erro, ainda abrimos o modal, mas sem dados preenchidos
        setEditCompanyId(companyId);
        
        // Mesmo em caso de erro, tentar abrir o modal
        setTimeout(() => {
          openEditModal({
            id: companyId,
            nome: "Empresa não encontrada",
            document: "",
            descricao: "",
            address: defaultEndereco,
            tags: "",
            contatos: [],
          });
        }, 0);
      }
    };
    
    fetchCompanyData();
  };

  const {
    formData,
    setFormData,
    errors,
    saveLoading,
    searchContactTerm,
    searchCompanyTerm,
    searchProdutoTerm,
    searchDebitoTerm,
    searchUserTerm,
    showNewContactDialog,
    showNewCompanyDialog,
    setSearchContactTerm,
    setSearchCompanyTerm,
    setSearchProdutoTerm,
    setSearchDebitoTerm,
    setSearchUserTerm,
    setShowNewContactDialog,
    setShowNewCompanyDialog,
    handleChange,
    handleContactChange,
    handleSubmit,
    handleDelete,
    handleAddProduto,
    handleRemoveProduto,
    handleSelectDebito,
    handleSelectContact,
    handleSelectCompany,
    handleAddActivity,
    handleUpdateActivity,
    handleRemoveActivity,
    calcularValorTotal,
    handleSelectChange,
    handleUserAssignment,
    fetchContactDetails,
    fetchCompanyDetails,
    fetchProductDetails,
    fetchUsers,
    // Relações empresa-contato
    fetchCompanyContacts,
    fetchContactCompany,
    isContactLinkedToCompany,
    companyContacts,
    contactCompany,
    isLoadingRelations,
    // Estados para usuários
    availableUsers,
    isLoadingUsers
  } = useCardForm({
    card,
    boardType,
    columns,
    onSave,
    onDelete,
  })
  
  // Referência para rastrear o ID do card que já carregamos
  const loadedCardId = React.useRef<string | null>(null);
  
  // Função de utilidade para criar um usuário mock
  const createMockUser = (userId: string) => {
    if (!userId) return null;
    
    return {
      id: userId,
      name: `Usuário ${userId.substring(0, 4)}`,
      email: `user-${userId.substring(0, 4)}@example.com`,
      active: true,
      createdAt: new Date().toISOString(),
      rpaId: rpaId || "",
      userId: getUser().id,
      isAdmin: false
    };
  };

  // Efeito para carregar dados complementares quando o diálogo é aberto
  useEffect(() => {  
    // Caso 1: Diálogo aberto com um card existente para edição
    if (open && card && card.id && card.id !== loadedCardId.current) {
      console.log("Dialog opened with existing card:", card.id);
      loadedCardId.current = card.id;
      
      // Debug para verificar se o card tem usuário atribuído
      console.log("Card being opened with assignedToId:", card.assignedToId);
      console.log("Card being opened with assignedTo:", card.assignedTo);
      
      // Carregar dados complementares quando o diálogo é aberto com um card existente
      // Usamos um pequeno timeout para evitar problemas de renderização
      setTimeout(() => {
        // Nota: Os carregamentos de dados complementares são feitos automaticamente pelo hook useCardForm
        // Não precisamos mais fazer chamadas extras aqui
        console.log("Card data will be loaded by useCardForm hook");
        
        // Se o card tiver usuário atribuído, tentar localizar nos usuários disponíveis
        if (card.assignedToId) {
          console.log("Card has assignedToId:", card.assignedToId);
          
          // Se já temos o objeto assignedTo completo, usá-lo diretamente
          if (card.assignedTo && card.assignedTo.name) {
            console.log("Card already has complete assignedTo object:", card.assignedTo);
            handleUserAssignment(card.assignedToId);
          } 
          // Se tiver apenas o ID, buscar nos usuários disponíveis
          else if (availableUsers.length > 0) {
            console.log("Checking for assigned user in availableUsers:", card.assignedToId);
            const foundUser = availableUsers.find(user => user.id === card.assignedToId);
            if (foundUser) {
              console.log("Found assigned user in availableUsers:", foundUser);
              // Atualizar formData diretamente com o usuário encontrado
              handleUserAssignment(foundUser.id);
            } else {
              console.log("Assigned user not found in availableUsers, fetching details...");
              // Se não encontrou nos usuários disponíveis, criar um mock temporário
              // para garantir que algo aparece, e então buscar os detalhes reais
              const mockUser = createMockUser(card.assignedToId);
              if (mockUser) {
                console.log("Created mock user while fetching real data:", mockUser);
                // Forçar a atualização do formData com o usuário mock
                setFormData(prev => ({
                  ...prev,
                  assignedTo: mockUser
                }));
              }
              
              // Buscar detalhes reais
              fetchUsers().then(() => {
                // Após carregar a lista de usuários, verificar novamente
                if (availableUsers.length > 0) {
                  const user = availableUsers.find(u => u.id === card.assignedToId);
                  if (user) {
                    handleUserAssignment(user.id);
                  }
                }
              });
            }
          } else {
            // Se não temos usuários disponíveis, criar um mock temporário
            const mockUser = createMockUser(card.assignedToId);
            if (mockUser) {
              console.log("Created mock user while fetching real data:", mockUser);
              // Forçar a atualização do formData com o usuário mock
              setFormData(prev => ({
                ...prev,
                assignedTo: mockUser
              }));
            }
            
            // Buscar detalhes reais
            fetchUsers();
          }
        }
      }, 100);
    } 
    // Caso 2: Diálogo aberto para criar um novo card
    else if (open && (!card || !card.id)) {
      console.log("Dialog opened for new card, resetting form");
      // Limpar referência para forçar novo carregamento
      loadedCardId.current = null;
      // Termos de busca já são limpos no useCardForm
    }
    // Caso 3: Diálogo sendo fechado
    else if (!open) {
      // Resetar o ID do card carregado quando o diálogo fecha
      loadedCardId.current = null;
    }
  }, [open, card, fetchContactDetails, fetchCompanyDetails, fetchProductDetails, fetchContactCompany, fetchCompanyContacts, availableUsers, handleUserAssignment, fetchUsers]);

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth={false} 
      TransitionProps={{
        appear: true,
        timeout: {
          enter: 400,
          exit: 300,
        },
      }}
    >
      <DialogHeader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton 
            onClick={onClose} 
            size="small" 
            sx={{ 
              color: '#757575',
              borderRadius: '10px',
              width: 36,
              height: 36,
              mr: 2.5,
              transition: 'all 0.2s',
              border: '1px solid rgba(0,0,0,0.05)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                transform: 'translateX(-3px)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          
          <Typography
            variant="h6"
            sx={{ 
              fontWeight: 600, 
              display: 'flex', 
              alignItems: 'center',
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                width: '40px',
                height: '3px',
                background: 'linear-gradient(90deg, #635AFF, rgba(99, 90, 255, 0.3))',
                bottom: -6,
                left: 0,
                borderRadius: '8px',
              }
            }}
          >
            {card?.id ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(99, 90, 255, 0.1)',
                  width: 36,
                  height: 36,
                  borderRadius: '10px',
                  mr: 1.5,
                }}
              >
                <EditNoteIcon sx={{ color: '#635AFF' }} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'linear-gradient(135deg, #635AFF, #4338ca)',
                  width: 36,
                  height: 36,
                  borderRadius: '10px',
                  mr: 1.5,
                  boxShadow: '0 2px 5px rgba(99, 90, 255, 0.3)',
                }}
              >
                <AddCircleOutlineIcon sx={{ color: 'white' }} />
              </Box>
            )}
            <span>{card?.id ? 'Editar Card' : 'Novo Card'}</span>
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mb: 0.5 }}>
            <Typography variant="caption" sx={{ color: '#666', textAlign: 'right' }}>
              ID: {card?.id || 'Novo registro'}
            </Typography>
            
            {formData.assignedTo && (
              <Chip
                size="small"
                avatar={
                  <Avatar sx={{ bgcolor: '#635AFF', width: 16, height: 16, fontSize: '10px' }}>
                    {formData.assignedTo.name.charAt(0).toUpperCase()}
                  </Avatar>
                }
                label={`Atribuído a: ${formData.assignedTo.name}`}
                sx={{
                  ml: 2,
                  height: '20px',
                  fontSize: '0.65rem',
                  bgcolor: 'rgba(99, 90, 255, 0.08)',
                  color: '#635AFF',
                  '& .MuiChip-label': { px: 1, fontWeight: 600 },
                  '& .MuiChip-avatar': { ml: 0.5, width: 16, height: 16 }
                }}
              />
            )}
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UserAssignmentDropdown
              assignedUser={formData.assignedTo}
              handleUserAssignment={handleUserAssignment}
              availableUsers={availableUsers}
              isLoadingUsers={isLoadingUsers}
            />
            
            <Chip 
              color="primary" 
              variant="filled" 
              label={boardType === 'lead' ? 'Funil de Vendas' : 'Inadimplência'} 
              size="small"
              sx={{ 
                borderRadius: '8px',
                background: boardType === 'lead' 
                  ? 'linear-gradient(135deg, #635AFF, #4338ca)'
                  : 'linear-gradient(135deg, #ff9800, #ed6c02)',
                fontSize: '0.7rem',
                fontWeight: 600,
                '& .MuiChip-label': { px: 1.5 }
              }}
            />
          </Box>
        </Box>
      </DialogHeader>

      <StyledDialogContent>
        <Box sx={{ px: 4, py: 4 }}>
          {/* Layout repensado para melhor fluidez e organização */}
          
          {/* Linha superior com informações básicas */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3, 
                  borderRadius: '16px',
                  border: '1px solid rgba(0,0,0,0.04)',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.03)',
                }}
              >
                <BasicInfoSection
                  formData={formData}
                  errors={errors}
                  columns={columns}
                  handleChange={handleChange}
                  handleSelectChange={handleSelectChange}
                />
              </Paper>
            </Grid>
          </Grid>
          
          {/* Linha de relacionamento - Empresa e Contato lado a lado */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            {/* Seção de empresa */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3, 
                  height: '100%',
                  borderRadius: '16px',
                  border: '1px solid rgba(0,0,0,0.04)',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.03)',
                }}
              >
                <CompanySection
                  company={formData.company}
                  empresaId={formData.empresaId}
                  searchCompanyTerm={searchCompanyTerm}
                  setSearchCompanyTerm={setSearchCompanyTerm}
                  setShowNewCompanyDialog={setShowNewCompanyDialog}
                  handleSelectCompany={handleSelectCompany}
                  onEditCompany={handleEditCompany}
                />
              </Paper>
            </Grid>
            
            {/* Seção de contato */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3, 
                  height: '100%',
                  borderRadius: '16px',
                  border: '1px solid rgba(0,0,0,0.04)',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.03)',
                }}
              >
                <ContactSection
                  contact={formData.contact}
                  contatoId={formData.contatoId}
                  errors={errors}
                  searchContactTerm={searchContactTerm}
                  setSearchContactTerm={setSearchContactTerm}
                  setShowNewContactDialog={setShowNewContactDialog}
                  handleContactChange={handleContactChange}
                  handleSelectContact={handleSelectContact}
                  onEditContact={handleEditContact}
                  // Props para vinculação com empresa
                  empresaId={formData.empresaId}
                  companyContacts={companyContacts}
                  isContactLinkedToCompany={isContactLinkedToCompany}
                  isLoadingRelations={isLoadingRelations}
                  contactCompany={contactCompany}
                  // Para evitar duplicação de formulário quando vinculado à empresa
                  hideContactForm={!!formData.empresaId && !!formData.contatoId && isContactLinkedToCompany(formData.contatoId)}
                />
              </Paper>
            </Grid>
          </Grid>
          
          {/* Linha de descrição */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3, 
                  borderRadius: '16px',
                  border: '1px solid rgba(0,0,0,0.04)',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.03)',
                }}
              >
                <DescriptionSection
                  description={formData.description}
                  handleChange={handleChange}
                />
              </Paper>
            </Grid>
          </Grid>
          
          {/* Linha inferior com produtos/débitos e atividades */}
          <Grid container spacing={3}>
            {/* Campos específicos por tipo de funil */}
            <Grid item xs={12} md={6}>
              {boardType === 'lead' && (
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 3, 
                    height: '100%',
                    borderRadius: '16px',
                    border: '1px solid rgba(0,0,0,0.04)',
                    backgroundColor: '#ffffff',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.03)',
                  }}
                >
                  <ProductsSection
                    value={formData.value}
                    errors={errors}
                    produtos={formData.produtos}
                    searchProdutoTerm={searchProdutoTerm}
                    setSearchProdutoTerm={setSearchProdutoTerm}
                    handleChange={handleChange}
                    handleAddProduto={handleAddProduto}
                    handleRemoveProduto={handleRemoveProduto}
                    calcularValorTotal={calcularValorTotal}
                    onEditProduct={handleEditProduct}
                  />
                </Paper>
              )}

              {boardType === 'inadimplencia' && (
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 3, 
                    height: '100%',
                    borderRadius: '16px',
                    border: '1px solid rgba(0,0,0,0.04)',
                    backgroundColor: '#ffffff',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.03)',
                  }}
                >
                  <DebitsSection
                    formData={formData}
                    errors={errors}
                    searchDebitoTerm={searchDebitoTerm}
                    setSearchDebitoTerm={setSearchDebitoTerm}
                    handleChange={handleChange}
                    handleSelectDebito={handleSelectDebito}
                  />
                </Paper>
              )}
            </Grid>

            {/* Atividades */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3, 
                  height: '100%',
                  borderRadius: '16px',
                  border: '1px solid rgba(0,0,0,0.04)',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.03)',
                  mb: 2,
                }}
              >
                <ActivitySection
                  activities={formData.activities}
                  onAddActivity={handleAddActivity}
                  onUpdateActivity={handleUpdateActivity}
                  onRemoveActivity={handleRemoveActivity}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </StyledDialogContent>

      <ActionButtons>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mr: 'auto' }}>
          {card?.id && (
            <Button
              startIcon={<DeleteIcon />}
              color="error"
              variant="outlined"
              onClick={handleDelete}
              sx={{ 
                borderRadius: '10px',
                borderWidth: '1px',
                fontWeight: 500,
                py: 1,
                px: 2,
                '&:hover': {
                  backgroundColor: 'rgba(211, 47, 47, 0.04)',
                  borderWidth: '1px',
                }
              }}
            >
              Excluir
            </Button>
          )}
          
          {card?.id && (
            <Tooltip title="Criado em 01/04/2024 às 10:30">
              <Typography variant="caption" sx={{ color: '#64748b', ml: 1 }}>
                <span style={{ opacity: 0.6 }}>Modificado em:</span> {new Date().toLocaleDateString()}
              </Typography>
            </Tooltip>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            onClick={onClose}
            color="inherit"
            variant="outlined"
            startIcon={<CancelIcon />}
            sx={{ 
              borderRadius: '10px',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              color: '#64748b',
              fontWeight: 500,
              py: 1,
              px: 2,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                borderColor: 'rgba(0, 0, 0, 0.2)',
              }
            }}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            startIcon={
              saveLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <CheckIcon />
              )
            }
            disabled={saveLoading}
            sx={{ 
              borderRadius: '10px',
              background: 'linear-gradient(135deg, #635AFF, #4338ca)',
              fontWeight: 500,
              py: 1.2,
              px: 3,
              minWidth: '180px',
              boxShadow: '0 4px 10px rgba(99, 90, 255, 0.2)',
              transition: 'all 0.2s',
              '&:hover': {
                boxShadow: '0 6px 15px rgba(99, 90, 255, 0.3)',
                transform: 'translateY(-2px)',
              }
            }}
          >
            {card?.id ? 'Salvar Alterações' : 'Criar Card'}
          </Button>
        </Box>
      </ActionButtons>

      {/* Diálogos adicionais */}
      {showNewContactDialog && (
        <ContactFormDialog
          open={showNewContactDialog}
          onClose={() => setShowNewContactDialog(false)}
          contact={null} // Passando null para indicar que é um novo contato
          rpaId={rpaId || ''}
          companyId={formData.empresaId} // Passar o ID da empresa selecionada
          onSave={async (contact: Contato) => {
            try {
              // Preparar dados do contato
              const contactData: Partial<Contato> = {
                name: contact.name,
                description: contact.description || '',
                number: contact.number.replace(/\D/g, ''), // Remove formatação
                tags: contact.tags || '',
                email: contact.email || '',
                rpaId: rpaId || '',
                companyId: formData.empresaId // Vincular ao companyId do card, se existir
              };

              console.log("Criando novo contato:", contactData);
              
              // Criar contato usando o ContactService
              const savedContact = await ContactService.createContact(contactData);
              console.log("Contato criado com sucesso:", savedContact);
              
              // Adaptar o formato do contato para o formato esperado pelo handleSelectContact
              const adaptedContact = {
                id: savedContact.id,
                nome: savedContact.name,
                email: savedContact.email || '',
                telefone: savedContact.number,
                whatsapp: savedContact.number,
                empresa: '',
                // Manter a associação com a empresa se existir
                companyId: savedContact.companyId || formData.empresaId
              };
              
              // Selecionar o contato no card
              handleSelectContact(adaptedContact);
              
              // Se temos uma empresa selecionada, atualizar a lista de contatos da empresa
              if (formData.empresaId) {
                fetchCompanyContacts(formData.empresaId);
              }
            } catch (error) {
              console.error("Erro ao criar contato:", error);
            }
          }}
        />
      )}

      {/* Diálogo para editar um contato existente */}
      {editContactId && (
        <ContactFormDialog
          open={!!editContactId}
          onClose={() => setEditContactId(null)}
          contact={{
            id: editContactId,
            name: formData.contact?.name || '',
            email: formData.contact?.email || '',
            number: formData.contact?.phone || '',
            description: '',
            active: true,
            createdAt: '',
            rpaId: rpaId || '',
            // Adicionamos a empresa ID como contexto para o formulário de contato
            companyId: formData.empresaId || undefined
          }}
          rpaId={rpaId || ''}
          onSave={async (contact: Contato) => {
            try {
              // Preparar dados do contato para atualização
              const contactData: Partial<Contato> = {
                name: contact.name,
                description: contact.description || '',
                number: contact.number.replace(/\D/g, ''), // Remove formatação
                tags: contact.tags || '',
                email: contact.email || '',
                rpaId: rpaId || '',
                companyId: contact.companyId || formData.empresaId
              };
              
              console.log("Atualizando contato:", editContactId, contactData);
              
              // Atualizar contato usando o ContactService
              const updatedContact = await ContactService.updateContact(
                rpaId || '',
                editContactId,
                contactData
              );
              
              console.log("Contato atualizado com sucesso:", updatedContact);
              
              // Adaptar o formato do contato para o formato esperado pelo handleSelectContact
              const adaptedContact = {
                id: updatedContact.id,
                nome: updatedContact.name,
                email: updatedContact.email || '',
                telefone: updatedContact.number,
                whatsapp: updatedContact.number,
                empresa: '',
                // Incluir qualquer informação de empresa que possa ter sido adicionada
                companyId: updatedContact.companyId || formData.empresaId
              };
              
              // Atualizar o contato no card
              handleSelectContact(adaptedContact);
              
              // Se temos uma empresa selecionada, atualizar a lista de contatos da empresa
              if (formData.empresaId) {
                fetchCompanyContacts(formData.empresaId);
              }
            } catch (error) {
              console.error("Erro ao atualizar contato:", error);
            }
          }}
        />
      )}

      {showNewCompanyDialog && (
        <EmpresaFormModal
          open={showNewCompanyDialog}
          onClose={() => setShowNewCompanyDialog(false)}
          isEdit={false}
          empresaNome={empresaData.nome}
          setEmpresaNome={setEmpresaNome}
          empresaDocumento={empresaData.document}
          setEmpresaDocumento={setEmpresaDocumento}
          empresaDescricao={empresaData.descricao}
          setEmpresaDescricao={setEmpresaDescricao}
          endereco={empresaData.address}
          setEndereco={setEmpresaEndereco}
          empresaTags={empresaData.tags}
          setEmpresaTags={setEmpresaTags}
          empresaContatos={empresaData.contatos}
          handleRemoveContato={handleRemoveContato}
          handleOpenContatosDialog={handleOpenContatosDialog}
          handleDeleteEmpresa={() => {}}
          handleSubmit={async (e) => {
            e.preventDefault();
            
            try {
              console.log("Criando empresa com contatos:", empresaData.contatos);
              
              // Preparar dados da empresa para enviar à API
              const empresaDataAPI = {
                name: empresaData.nome,
                description: empresaData.descricao,
                tags: empresaData.tags,
                document: empresaData.document,
                rpaId: rpaId || '',
                contacts: empresaData.contatos.map((c) => c.id),
                address: {
                  zipCode: empresaData.address.zipCode,
                  street: empresaData.address.street,
                  number: empresaData.address.number,
                  city: empresaData.address.city,
                  neighborhood: empresaData.address.neighborhood,
                  state: empresaData.address.state,
                  complement: empresaData.address.complement,
                },
              };
              
              console.log("Dados para criação de empresa:", empresaDataAPI);
              
              // Enviar dados para a API
              const axios = (await import('axios')).default;
              const { llmEndpoint } = await import('infra');
              
              const response = await axios.post(
                `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}`,
                empresaDataAPI,
                {
                  headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                  }
                }
              );
              
              if (response.data) {
                console.log("Empresa criada com sucesso:", response.data);
                
                // Selecionar a empresa recém-criada para o card
                handleSelectCompany(response.data);
                
                // Mostrar feedback de sucesso
                setSnackbarMessage("Empresa criada com sucesso!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                
                // Fechar o modal
                setShowNewCompanyDialog(false);
              }
            } catch (error) {
              console.error("Erro ao criar empresa:", error);
              
              // Mostrar feedback de erro
              setSnackbarMessage("Erro ao criar empresa. Tente novamente.");
              setSnackbarSeverity("error");
              setSnackbarOpen(true);
            }
          }}
        />
      )}
      
      {/* Diálogo para editar uma empresa existente */}
      {isOpen && isEdit && editCompanyId && (
        <EmpresaFormModal
          open={isOpen}
          onClose={() => {
            closeModal();
            setEditCompanyId(null);
          }}
          isEdit={true}
          empresaNome={empresaData.nome}
          setEmpresaNome={setEmpresaNome}
          empresaDocumento={empresaData.document}
          setEmpresaDocumento={setEmpresaDocumento}
          empresaDescricao={empresaData.descricao}
          setEmpresaDescricao={setEmpresaDescricao}
          endereco={empresaData.address}
          setEndereco={setEmpresaEndereco}
          empresaTags={empresaData.tags}
          setEmpresaTags={setEmpresaTags}
          empresaContatos={empresaData.contatos}
          handleRemoveContato={handleRemoveContato}
          handleOpenContatosDialog={handleOpenContatosDialog}
          handleDeleteEmpresa={() => {}}
          handleSubmit={async () => {
            try {
              console.log("Salvando empresa com contatos:", empresaData.contatos);
              
              // Capturar dados atualizados da empresa para enviar à API
              const empresaAtualizada = {
                id: editCompanyId,
                name: empresaData.nome,
                document: empresaData.document,
                description: empresaData.descricao,
                address: empresaData.address,
                tags: empresaData.tags,
                contacts: empresaData.contatos.map(c => c.id), // Incluir IDs dos contatos
                rpaId: rpaId || ''
              };
              
              console.log("Dados da empresa a serem enviados:", empresaAtualizada);
              
              // Enviar dados atualizados para a API
              const axios = (await import('axios')).default;
              const { llmEndpoint } = await import('infra');
              
              const updateResponse = await axios.put(
                `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/${editCompanyId}`,
                empresaAtualizada,
                {
                  headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                  }
                }
              );
              
              console.log("Resposta da atualização:", updateResponse.data);
              
              // Depois de salvar, atualizar o card com os novos dados da empresa
              const getResponse = await axios.get(
                `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/${editCompanyId}`,
                {
                  headers: {
                    'accept': '*/*'
                  }
                }
              );
              
              if (getResponse.data) {
                console.log("Dados atualizados da empresa:", getResponse.data);
                handleSelectCompany(getResponse.data);
                
                // Mostrar feedback de sucesso
                setSnackbarMessage("Empresa atualizada com sucesso!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
              }
              
              // Fechar o modal
              closeModal();
              setEditCompanyId(null);
              
              return Promise.resolve();
            } catch (error) {
              console.error('Erro ao atualizar empresa:', error);
              
              // Mostrar feedback de erro
              setSnackbarMessage("Erro ao atualizar empresa. Tente novamente.");
              setSnackbarSeverity("error");
              setSnackbarOpen(true);
              
              closeModal();
              setEditCompanyId(null);
              return Promise.resolve();
            }
          }}
        />
      )}
      
      {/* Diálogo para editar um produto existente */}
      {editProductId && (
        <NewProductDialog
          open={!!editProductId}
          onClose={() => setEditProductId(null)}
          onSave={(updatedProduct) => {
            // Usar as funções do hook para atualizar os produtos
            // Primeiro removemos o produto original
            handleRemoveProduto(updatedProduct.id);
            // Depois adicionamos o produto atualizado
            handleAddProduto(updatedProduct);
            
            // Fechar o modal de edição
            setEditProductId(null);
          }}
          productId={editProductId}
          isEdit={true}
        />
      )}
      
      {/* Diálogo para vincular contatos a empresas */}
      <ContatosDialog
        open={contatosDialogOpen}
        onClose={() => setContatosDialogOpen(false)}
        contatos={contatosDisponiveis}
        contatosSelecionados={contatosSelecionados}
        handleToggleContato={handleToggleContato}
        handleAddContatosSelecionados={handleAddContatosSelecionados}
        loading={loadingContatos}
        hasMore={contatosHasMore}
        loadMoreContatos={loadMoreContatos}
        searchContato={searchContato}
        setSearchContato={handleSetSearchContato}
        handleSearchContatos={handleSearchContatos}
      />
      
      {/* Snackbar para feedback de operações */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledDialog>
  )
}

export default CardDialog

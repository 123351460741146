import React, { useCallback, useEffect, useState } from 'react'
import { 
  Card, 
  CardContent, 
  Button, 
  Grid, 
  Typography, 
  Paper, 
  Box, 
  CircularProgress, 
  Avatar,
  Fade,
  Divider
} from '@mui/material'
import { QRCodeCanvas } from 'qrcode.react'
import { createClient } from '@supabase/supabase-js'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { 
  CheckCircle, 
  Logout, 
  Download, 
  Smartphone, 
  Wifi, 
  WifiOff, 
  Info, 
  RefreshRounded 
} from '@mui/icons-material'
import { toast } from '@intraversa-lab/styleguide'

import { llmEndpoint } from 'infra'

interface Instance {
  id: string
  rpaId: string
  instanceId: string
  phoneConnected: string
  connected: boolean
  qrCode: string
  token: string
}

const mapToInstance = (data: any): Instance => ({
  id: data.id,
  rpaId: data.rpa_id,
  instanceId: data.instance_id,
  connected: data.connected,
  qrCode: data.qr_code,
  token: data.token,
  phoneConnected: data.phone_connected,
})

// 🔹 Criar cliente Supabase FORA do componente
const supabase = createClient(
  'https://gqbrrxdaumvkiibgcddw.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdxYnJyeGRhdW12a2lpYmdjZGR3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAzMjg5NTEsImV4cCI6MjA1NTkwNDk1MX0.Ceyfm58OrsEgZPtD7OtHFkqfUUltl2FhFD5reHSNqdk',
)

// Componente de status de conexão
const ConnectionStatus = ({ connected, phoneNumber }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        borderRadius: '12px',
        bgcolor: connected ? 'rgba(46, 204, 113, 0.1)' : 'rgba(255, 76, 48, 0.1)',
        border: `1px solid ${connected ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 76, 48, 0.3)'}`,
        mb: 2
      }}
    >
      <Avatar
        sx={{
          bgcolor: connected ? '#2ecc71' : '#ff4c30',
          width: 40,
          height: 40,
          mr: 2
        }}
      >
        {connected ? <Wifi /> : <WifiOff />}
      </Avatar>
      <Box>
        <Typography variant="subtitle1" fontWeight={600} color={connected ? '#2ecc71' : '#ff4c30'}>
          {connected ? 'Conexão Ativa' : 'Desconectado'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {connected 
            ? `Conectado ao número ${phoneNumber || 'N/A'}` 
            : 'Escaneie o QR Code para conectar'}
        </Typography>
      </Box>
    </Box>
  );
};

export const TelefoneConfig = () => {
  const { rpaId } = useParams()
  const [erro, setErro] = useState<string | null>(null)
  const [instanceData, setInstanceData] = useState<Instance>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [refreshing, setRefreshing] = useState(false)

  const fetchApiData = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await axios.get<Instance>(
         `${llmEndpoint.url}/z-api/instances/${rpaId}`,
        {
          headers: {
            accept: '*/*',
          },
        },
      )
      setInstanceData(response.data)
    } catch (error) {
      toast({
        text: 'Erro ao buscar dados da API',
        description: 'Erro ao buscar dados da API.',
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }, [])

  const handleRefresh = async () => {
    setRefreshing(true)
    await fetchApiData()
    setTimeout(() => setRefreshing(false), 600)
  }

  useEffect(() => {
    fetchApiData()
  }, [fetchApiData])

  useEffect(() => {
    const channel = supabase
      .channel('custom-all-channel')
      .on(
        'postgres_changes',
        { event: 'UPDATE', schema: 'public', table: 'z_api_intances' },
        (payload) => {
          console.log('UPDATE recebido:', payload)
          const newInstance: Instance = mapToInstance(payload.new)
          const oldInstance: Instance = mapToInstance(payload.old)
          console.log('Antigo:', oldInstance)
          console.log('Novo:', newInstance)
          if (newInstance && newInstance.rpaId === rpaId) {
            setInstanceData((prevState) => ({ ...prevState, ...newInstance }))
            if (oldInstance.connected && !newInstance.connected) {
              toast({
                text: 'Desconectado do telefone',
                description: 'O telefone foi desconectado da integração.',
                type: 'success',
              })
            }
            if (!oldInstance.connected && newInstance.connected) {
              toast({
                text: 'Telefone conectado!',
                description: 'O telefone foi conectado a integração.',
                type: 'success',
              })
            }
          }
        },
      )
      .subscribe()

    return () => {
      console.log('🧹 Limpando assinatura do canal...')
      supabase.removeChannel(channel)
    }
  }, [instanceData])

  const disconnectInstance = useCallback(async () => {
    try {
      await axios.post(
        `${llmEndpoint.url}/z-api/instances/${rpaId}/disconnect`,
        {},
        {
          headers: {
            accept: '*/*',
          },
        },
      )
      console.log('Instância desconectada com sucesso')
    } catch (error) {
      console.error('Erro ao desconectar a instância:', error)
      setErro('Erro ao desconectar a instância')
    }
  }, [instanceData?.instanceId])

  const downloadQRCode = () => {
    const canvas = document.querySelector('canvas')
    if (canvas) {
      const link = document.createElement('a')
      link.href = canvas.toDataURL('image/png')
      link.download = 'qrcode.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  if (isLoading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '70vh',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <CircularProgress size={60} thickness={4} />
        <Typography variant="h6" color="text.secondary">
          Carregando configurações do telefone...
        </Typography>
      </Box>
    )
  }

  return (
    <Box 
      sx={{ 
        padding: { xs: 2, sm: 3, md: 4 },
        maxWidth: '1200px',
        margin: '0 auto',
        animation: 'fadeIn 0.5s ease-in-out',
        '@keyframes fadeIn': {
          '0%': { opacity: 0, transform: 'translateY(10px)' },
          '100%': { opacity: 1, transform: 'translateY(0)' },
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography 
          variant="h4" 
          fontWeight={600}
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            '&::before': {
              content: '""',
              display: 'block',
              width: '4px',
              height: '24px',
              backgroundColor: 'primary.main',
              marginRight: '12px',
              borderRadius: '4px'
            }
          }}
        >
          Configuração do Telefone
        </Typography>
        <Button 
          variant="outlined" 
          startIcon={<RefreshRounded />}
          onClick={handleRefresh}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          Atualizar
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ConnectionStatus 
            connected={instanceData?.connected} 
            phoneNumber={instanceData?.phoneConnected} 
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <Card 
            elevation={0}
            sx={{ 
              borderRadius: '16px', 
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CardContent sx={{ p: 3, flexGrow: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{
                    bgcolor: 'primary.light',
                    width: 40,
                    height: 40,
                    mr: 2
                  }}
                >
                  <Smartphone />
                </Avatar>
                <Typography variant="h6" fontWeight={600}>
                  {instanceData?.connected ? 'Dispositivo Conectado' : 'Conectar Dispositivo'}
                </Typography>
              </Box>
              
              <Divider sx={{ my: 2 }} />
              
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', py: 3, flexGrow: 1 }}>
                {instanceData?.connected ? (
                  <Fade in={true}>
                    <Box sx={{ textAlign: 'center' }}>
                      <Avatar
                        sx={{
                          bgcolor: '#2ecc71',
                          width: 80,
                          height: 80,
                          margin: '0 auto 20px',
                          boxShadow: '0 4px 12px rgba(46, 204, 113, 0.3)'
                        }}
                      >
                        <CheckCircle sx={{ fontSize: 40 }} />
                      </Avatar>
                      <Typography variant="h5" fontWeight={600} color="#2ecc71" gutterBottom>
                        Whatsapp Conectado
                      </Typography>
                      <Typography variant="body1" color="text.secondary" paragraph>
                        O dispositivo {instanceData.phoneConnected} está conectado e pronto para uso.
                      </Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<Logout />}
                        onClick={disconnectInstance}
                        sx={{
                          mt: 2,
                          borderRadius: '8px',
                          textTransform: 'none',
                          fontWeight: 500
                        }}
                      >
                        Desconectar Dispositivo
                      </Button>
                    </Box>
                  </Fade>
                ) : (
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body1" color="text.secondary" paragraph>
                      Escaneie o QR code com seu WhatsApp para conectar seu dispositivo
                    </Typography>
                    <Box 
                      sx={{ 
                        p: 3, 
                        border: '1px dashed rgba(0,0,0,0.1)', 
                        borderRadius: '16px',
                        display: 'inline-flex',
                        mb: 2,
                        position: 'relative',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: -2,
                          left: -2,
                          right: -2,
                          bottom: -2,
                          borderRadius: '18px',
                          background: 'linear-gradient(45deg, #f3f3f3, #ffffff)',
                          zIndex: -1
                        }
                      }}
                    >
                      <QRCodeCanvas 
                        value={instanceData?.qrCode || ''} 
                        size={200}
                        includeMargin={true}
                        bgColor="#ffffff"
                      />
                    </Box>
                    <Button
                      variant="outlined"
                      startIcon={<Download />}
                      onClick={downloadQRCode}
                      sx={{
                        borderRadius: '8px',
                        textTransform: 'none',
                        fontWeight: 500
                      }}
                    >
                      Baixar QR Code
                    </Button>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={5}>
          <Card 
            elevation={0}
            sx={{ 
              borderRadius: '16px', 
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
              height: '100%'
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{
                    bgcolor: 'primary.light',
                    width: 40,
                    height: 40,
                    mr: 2
                  }}
                >
                  <Info />
                </Avatar>
                <Typography variant="h6" fontWeight={600}>
                  Detalhes da Conexão
                </Typography>
              </Box>
              
              <Divider sx={{ my: 2 }} />
              
              {instanceData ? (
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    p: 2, 
                    borderRadius: '10px',
                    bgcolor: 'background.paper',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
                    mb: 2
                  }}>
                    <Avatar
                      sx={{
                        bgcolor: 'primary.light',
                        width: 36,
                        height: 36,
                        mr: 2
                      }}
                    >
                      <Smartphone sx={{ fontSize: 20 }} />
                    </Avatar>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Número conectado
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {instanceData.phoneConnected || 'Não conectado'}
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    p: 2, 
                    borderRadius: '10px',
                    bgcolor: 'background.paper',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
                    mb: 2
                  }}>
                    <Avatar
                      sx={{
                        bgcolor: instanceData.connected ? 'success.light' : 'error.light',
                        width: 36,
                        height: 36,
                        mr: 2
                      }}
                    >
                      {instanceData.connected ? <Wifi sx={{ fontSize: 20 }} /> : <WifiOff sx={{ fontSize: 20 }} />}
                    </Avatar>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Status da conexão
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={600} color={instanceData.connected ? 'success.main' : 'error.main'}>
                        {instanceData.connected ? 'Conectado' : 'Desconectado'}
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    p: 2, 
                    borderRadius: '10px',
                    bgcolor: 'background.paper',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
                  }}>
                    <Avatar
                      sx={{
                        bgcolor: 'primary.light',
                        width: 36,
                        height: 36,
                        mr: 2
                      }}
                    >
                      <div style={{ fontSize: 18, fontWeight: 600 }}>ID</div>
                    </Avatar>
                    <Box sx={{ overflow: 'hidden' }}>
                      <Typography variant="body2" color="text.secondary">
                        ID da instância
                      </Typography>
                      <Typography 
                        variant="subtitle1" 
                        fontWeight={600}
                        sx={{ 
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          maxWidth: '240px'
                        }}
                      >
                        {instanceData.instanceId || 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Typography variant="body1" color="text.secondary" align="center" sx={{ py: 4 }}>
                  Carregando informações...
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
  
        {erro && (
          <Grid item xs={12}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 3, 
                bgcolor: 'error.light', 
                borderRadius: '12px',
                border: '1px solid',
                borderColor: 'error.main',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'error.main',
                  width: 40,
                  height: 40,
                  mr: 2
                }}
              >
                <WifiOff />
              </Avatar>
              <Typography color="error.dark" fontWeight={500}>{erro}</Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { TemplateCardProps } from './types'

export const TemplateCard = ({ template, onClick }: TemplateCardProps) => {
  // Função para definir a cor da borda lateral baseada na categoria
  const getCategoryColor = (category) => {
    switch (category) {
      case 'COBRANÇA':
        return '#f44336' // vermelho
      case 'VENDA':
        return '#4caf50' // verde
      case 'PROMOÇÃO':
        return '#ff9800' // laranja
      case 'INFORMATIVO':
        return '#2196f3' // azul
      default:
        return '#3f51b5' // indigo (padrão)
    }
  }

  // Função para definir o ícone do canal
  const getChannelBadge = (channel) => {
    switch (channel) {
      case 'WHATSAPP':
        return '📱'
      case 'EMAIL':
        return '📧'
      case 'BOTH':
        return '📱📧'
      default:
        return ''
    }
  }

  return (
    <Card
      onClick={() => onClick(template)}
      sx={{
        cursor: 'pointer',
        width: '100%',
        transition: 'all 0.3s ease',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '6px',
          height: '100%',
          backgroundColor: getCategoryColor(template.category),
          borderTopLeftRadius: '12px',
          borderBottomLeftRadius: '12px',
        },
      }}
    >
      <CardContent sx={{ padding: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: 600,
              color: '#32394a',
              fontSize: '18px',
              maxWidth: '80%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {template.name}
          </Typography>
          <div
            style={{
              backgroundColor: '#f0f4f8',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#3f51b5',
            }}
          >
            {template.name.charAt(0).toUpperCase()}
          </div>
        </div>

        <Typography
          sx={{
            mb: 1.5,
            color: '#637381',
            fontSize: '14px',
            lineHeight: 1.5,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            height: '40px',
          }}
        >
          {template.description || 'Sem descrição'}
        </Typography>

        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '6px',
            }}
          >
            <div
              style={{
                padding: '4px 10px',
                borderRadius: '16px',
                fontSize: '12px',
                fontWeight: '600',
                backgroundColor:
                  template.status === 'Ativo'
                    ? '#e6f7ee'
                    : template.status === 'Inativo'
                      ? '#ffe9e9'
                      : '#f0f4f8',
                color:
                  template.status === 'Ativo'
                    ? '#1e9d6a'
                    : template.status === 'Inativo'
                      ? '#e53935'
                      : '#637381',
              }}
            >
              {template.status}
            </div>

            <div
              style={{
                padding: '4px 10px',
                borderRadius: '16px',
                fontSize: '12px',
                fontWeight: '600',
                backgroundColor: '#f5f5f5',
                color: '#666',
              }}
            >
              {getChannelBadge(template.channel)} {template.channel}
            </div>
          </div>

          <Typography
            sx={{
              fontSize: '12px',
              color: '#919eab',
              fontStyle: 'italic',
            }}
          >
            Clique para editar
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}
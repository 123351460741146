import { ReactNode } from "react";

export enum TemplateType {
  MARKETING = "MARKETING",
  UTILITY = "UTILITY",
  AUTHENTICATION = "AUTHENTICATION",
}

export enum TemplateModel {
  SIMPLES = "SIMPLES",
  AVANCADO = "AVANCADO",
}

export enum TemplateChannel {
  WHATSAPP = "WHATSAPP",
  EMAIL = "EMAIL",
  BOTH = "BOTH",
}

export enum TemplateCategory {
  COBRANCA = "COBRANÇA",
  VENDA = "VENDA",
  PROMOCAO = "PROMOÇÃO",
  INFORMATIVO = "INFORMATIVO",
  OUTROS = "OUTROS",
}
export interface PlaceholderBinding {
  objectType: string; // 'card', 'contact', 'enterprise'
  propertyKey: string; // 'name', 'email', etc.
}

export interface Template {
  id: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  type: TemplateType;
  template: string;
  status: string;
  defaultValues: string;
  rpaId: string;
  channel: TemplateChannel;
  model: TemplateModel;
  category: TemplateCategory;
}

export interface LoadingBackdropProps {
  open: boolean;
  message: string;
}

export interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  itemName: string;
}

export interface TemplateCardProps {
  template: Template;
  onClick: (template: Template) => void;
}

export interface TemplateHeaderProps {
  showForm: boolean;
  handleBackToSearch: () => void;
  addNewProfile: () => void;
  search: string;
  setSearch: (search: string) => void;
  showInputSearch: boolean;
  setShowInputSearch: (show: boolean) => void;
}

export interface TemplateFormProps {
  templateName: string;
  setTemplateName: (name: string) => void;
  templateDescription: string;
  setTemplateDescription: (desc: string) => void;
  templateType: TemplateType;
  templateModel: TemplateModel;
  setTemplateModel: (type: TemplateModel) => void;
  setTemplateType: (type: TemplateType) => void;
  templateChannel: TemplateChannel;
  setTemplateChannel: (channel: TemplateChannel) => void;
  templateCategory: TemplateCategory;
  setTemplateCategory: (category: TemplateCategory) => void;
  templateText: string;
  handleTemplateTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddPlaceholder: () => void;
  placeholders: string[];
  placeholderValues: string[];
  handlePlaceholderValueChange: (index: number, value: string) => void;
  isSubmitting: boolean;
  edit: boolean;
  handleSubmit: (e: React.FormEvent) => void;
  handleDeleteTemplate: () => void;
  nextPlaceholderNumber: number;
  placeholderBindings?: PlaceholderBinding[];
  setPlaceholderBindings?: (bindings: PlaceholderBinding[]) => void;
}

export interface PaginatedResponse<T> {
  content: T[];
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      empty: boolean;
      unsorted: boolean;
      sorted: boolean;
    };
    offset: number;
    unpaged: boolean;
    paged: boolean;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    unsorted: boolean;
    sorted: boolean;
  };
  numberOfElements: number;
  empty: boolean;
}

import styled from "styled-components";

export const TabContentContainer = styled.div`
  position: relative;
  height: 100%;
  max-height: calc(100vh - 64px); /* Ajuste conforme a altura do seu header */
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  /* Estilo opcional para a barra de rolagem */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
  }
`;

export const WrapperSerach = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;

  .filters {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .newProfile {
    background-color: #f9f9f9;
    color: #3c454d;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 4px;
    text-transform: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .filters {
      width: 100%;
    }

    .newProfile {
      width: 100%;
    }
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 48px 24px;
  height: 100%;
  min-height: 300px;

  h3 {
    margin: 0 0 8px 0;
    color: #333;
  }

  p {
    margin: 0 0 24px 0;
    color: #666;
    max-width: 400px;
  }
`;

import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MessageContainer } from './molecules/messageContainer/MessageContainer'
import { MediaMessageInput } from './molecules/mediaMessageInput/MediaMessageInput'
import { NewChatModal } from './molecules/newChatModal/NewChatModal'
import { ChatSidebar } from './molecules/chatSideBar/ChatSideBar'
import { ChatHeader } from './molecules/chatHeader/ChatHeader'
import { EmptyStateMessage } from './molecules/emptyStateMessage/EmptyStateMessage'
import { ChatOptionsPopup } from './molecules/chatOptionsPopUp/ChatOptionsPopup'
import { DeleteConfirmationModal } from './molecules/deleteConfirmationModal/DeleteConfirmationModal'

// Import custom hooks
import {
  useResponsiveChat,
  usePopupClickOutside,
  useChatData,
  useChatEdit,
} from './useChat'

export const ChatContainer = () => {
  const { rpaId } = useParams()
  const messagesEndRef = useRef(null)
  const messagesContainerRef = useRef(null)

  // UI state
  const [isMobile, setIsMobile] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  const [isNewChatModalOpen, setIsNewChatModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(
    (rpaId === '663457c4-abce-4724-9fce-de4021e1717b' || rpaId === '323457c4-abce-4724-9fce-de4021e1717b') ? 'messages' : 'llm',
  )
  const [searchTerm, setSearchTerm] = useState('')

  // Search in chat state
  const [chatSearchResults, setChatSearchResults] = useState([])
  const [messageElements, setMessageElements] = useState([])

  // Apply custom hooks to manage different aspects of the chat
  const { toggleSidebar } = useResponsiveChat(setIsMobile, setIsSidebarOpen)

  // Toggle sidebar collapse state
  const toggleSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }
  
  // Update UI elements when sidebar collapse state changes
  useEffect(() => {
    // This is handled by inline styles now with the paddingLeft approach
  }, [isSidebarCollapsed])

  // Track if user has scrolled up
  const [hasScrolledUp, setHasScrolledUp] = useState(false)
  // Track if this is the first load of a specific chat
  const [initialChatLoad, setInitialChatLoad] = useState({})

  const {
    chats,
    setChats,
    selectedChatId,
    setSelectedChatId,
    messages,
    isLoading,
    handleChatBoxClick: originalHandleChatBoxClick,
    sendMessage,
  } = useChatData(rpaId)

  const {
    editingChatId,
    setEditingChatId,
    editingChatName,
    setEditingChatName,
    isDeleteConfirmationOpen,
    setIsDeleteConfirmationOpen,
    openPopupId,
    popupPosition,
    setPopupPosition,
    handleOptionsClick,
    closePopup,
    handleRenameChat,
    handleSaveRename,
    handleDeleteChat,
    confirmDeleteChat,
  } = useChatEdit(chats, setChats)

  // Apply the popup click outside effect
  usePopupClickOutside(popupPosition, setPopupPosition)

  // Scroll to bottom when messages change or on initial chat load
  useEffect(() => {
    if (messagesEndRef.current && messages.length > 0) {
      // Check if this is the first load of this chat
      if (!initialChatLoad[selectedChatId]) {
        // Set this chat as having been initially loaded
        setInitialChatLoad((prev) => ({
          ...prev,
          [selectedChatId]: true,
        }))
        // Immediate scroll for first load
        messagesEndRef.current.scrollIntoView({ behavior: 'auto' })
      } else if (!hasScrolledUp) {
        // Smooth scroll for new messages if user hasn't manually scrolled up
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [messages, selectedChatId, initialChatLoad, hasScrolledUp])

  // Handle scroll events to detect when user scrolls up
  const handleScroll = (e) => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current
      const isScrolledUp =
        container.scrollTop <
        container.scrollHeight - container.clientHeight - 100
      setHasScrolledUp(isScrolledUp)
    }
  }

  // Add scroll event listener
  useEffect(() => {
    const container = messagesContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
      return () => {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [messagesContainerRef.current])

  // Scroll to bottom manually
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      setHasScrolledUp(false)
    }
  }

  const [replyingTo, setReplyingTo] = useState(null)

  // Adicione a função para lidar com a resposta a uma mensagem
  const handleReplyMessage = (message) => {
    setReplyingTo(message)
    // Opcional: role até o campo de entrada de mensagem
    const inputElement = document.querySelector('textarea')
    if (inputElement) {
      inputElement.focus()
    }
  }

  // Função para cancelar a resposta
  const handleCancelReply = () => {
    setReplyingTo(null)
  }

  // Store references to message elements after render
  useEffect(() => {
    if (messagesContainerRef.current) {
      const elements =
        messagesContainerRef.current.querySelectorAll('.message-container')
      setMessageElements(Array.from(elements))
    }
  }, [messages, messagesContainerRef.current])

  // Handler functions
  const handleNewChat = () => {
    setIsNewChatModalOpen(true)
    if (isMobile) {
      setIsSidebarOpen(false)
    }
  }

  const handleChatBoxClick = (chatId) => {
    originalHandleChatBoxClick(chatId, isMobile, setIsSidebarOpen)
    // Clear any existing search results when changing chats
    setChatSearchResults([])
    // Reset scroll state for the new chat
    setHasScrolledUp(false)
  }

  const handleSendMessage = (e) => {
    console.log('Enviando mensagem:', e.message)
    console.log('Enviando mall:', e)
    sendMessage(e.message, e)
    setReplyingTo(null)
  }

  // Search in chat functionality
  const handleSearchInChat = (query) => {
    if (!query.trim() || messages.length === 0) {
      return []
    }

    const searchRegex = new RegExp(query, 'gi')
    const results = []

    // Check each message for matches
    messages.forEach((message, messageIndex) => {
      // Skip messages that don't have text content
      if (!message.message || typeof message.message !== 'string') {
        return
      }

      // Find all matches in this message
      let match
      while ((match = searchRegex.exec(message.message)) !== null) {
        results.push({
          messageIndex,
          position: match.index,
          length: query.length,
          messageElement: messageElements[messageIndex] || null,
          text: message.message.substring(
            Math.max(0, match.index - 20),
            Math.min(message.message.length, match.index + query.length + 20),
          ),
        })
      }
    })

    setChatSearchResults(results)
    return results
  }

  // Scroll to specific search match
  const handleScrollToMatch = (match) => {
    if (match && match.messageElement) {
      match.messageElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      // Optionally add a highlight effect
      match.messageElement.classList.add('highlight-match')
      setTimeout(() => {
        match.messageElement.classList.remove('highlight-match')
      }, 1500)
    }
  }

  const getAvailableDates = (messages) => {
    const dates = new Set()

    if (messages && messages.length > 0) {
      messages.forEach((message) => {
        // Tenta obter a data da mensagem
        let messageDate

        if (message.timestamp) {
          messageDate = new Date(message.timestamp)
        } else if (message.date) {
          messageDate = new Date(message.date)
        } else if (message.createdAt) {
          messageDate = new Date(message.createdAt)
        } else if (message.time) {
          messageDate = new Date(message.time)
        } else {
          // Se não tiver uma data explícita, usa a data atual
          messageDate = new Date()
        }

        // Formata a data para YYYY-MM-DD
        const dateStr = messageDate.toISOString().split('T')[0]
        dates.add(dateStr)
      })
    }

    return Array.from(dates)
  }
  // Function to scroll to messages from a specific date
  const handleDateSelect = (date) => {
    if (!date || !messages || messages.length === 0) return

    // Formata a data selecionada para YYYY-MM-DD
    const selectedDateStr = date.toISOString().split('T')[0]

    // Encontra a primeira mensagem dessa data
    let targetMessageIndex = -1

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i]
      let messageDate

      // Tenta obter a data da mensagem
      if (message.timestamp) {
        messageDate = new Date(message.timestamp)
      } else if (message.date) {
        messageDate = new Date(message.date)
      } else if (message.createdAt) {
        messageDate = new Date(message.createdAt)
      } else if (message.time) {
        messageDate = new Date(message.time)
      } else {
        continue // Pula mensagens sem data
      }

      // Compara as datas
      const messageDateStr = messageDate.toISOString().split('T')[0]

      if (messageDateStr === selectedDateStr) {
        targetMessageIndex = i
        break
      }
    }

    if (targetMessageIndex >= 0 && messageElements[targetMessageIndex]) {
      // Rola até a mensagem
      messageElements[targetMessageIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })

      // Opcional: Destaca a mensagem
      messageElements[targetMessageIndex].classList.add('date-highlight')
      setTimeout(() => {
        messageElements[targetMessageIndex].classList.remove('date-highlight')
      }, 2000)
    }
  }

  // Extract timestamp from message
  const getMessageTimestamp = (message) => {
    // Try to get timestamp from different possible fields
    if (message.timestamp) {
      return new Date(message.timestamp)
    } else if (message.date) {
      return new Date(message.date)
    } else if (message.createdAt) {
      return new Date(message.createdAt)
    } else if (message.time) {
      return new Date(message.time)
    } else if (
      typeof message.message === 'string' &&
      message.message.includes('08/03/2')
    ) {
      // Extract date from message text if it contains a date pattern
      const dateMatch = message.message.match(
        /(\d{2}\/\d{2}\/\d{4})\s*-\s*(\d{2}:\d{2}:\d{2})/,
      )
      if (dateMatch) {
        const [_, datePart, timePart] = dateMatch
        const [day, month, year] = datePart.split('/')
        const [hours, minutes, seconds] = timePart.split(':')
        return new Date(year, month - 1, day, hours, minutes, seconds)
      }
    }

    // Default to current date if no timestamp found
    return new Date()
  }

  // Group messages by date
  const groupMessagesByDate = (messagesList) => {
    const groups = []
    let currentDate = null
    let currentGroup = null

    messagesList.forEach((message) => {
      // Extract date from message timestamp
      const messageDate = getMessageTimestamp(message)

      // Format date to YYYY-MM-DD for comparison
      const dateStr = messageDate.toISOString().split('T')[0]

      if (dateStr !== currentDate) {
        currentDate = dateStr
        currentGroup = {
          date: messageDate,
          messages: [],
        }
        groups.push(currentGroup)
      }

      currentGroup.messages.push(message)
    })

    return groups
  }

  // Format date header for display
  const formatDateHeader = (date) => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    // Format for comparison
    const dateStr = date.toISOString().split('T')[0]
    const todayStr = today.toISOString().split('T')[0]
    const yesterdayStr = yesterday.toISOString().split('T')[0]

    if (dateStr === todayStr) {
      return 'Hoje'
    } else if (dateStr === yesterdayStr) {
      return 'Ontem'
    } else {
      // Format date as DD/MM/YYYY for other dates
      return date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    }
  }

  // Filter chats based on active tab and search term
  const filteredChats = chats
    .filter(
      (chat) =>
        (activeTab === 'messages' && chat.whatsapp) ||
        (activeTab === 'llm' && !chat.whatsapp),
    )
    .filter(
      (chat) =>
        !searchTerm ||
        chat.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )

  return (
    <div className="flex flex-col h-full w-full overflow-hidden bg-white">
      {/* Mobile backdrop when sidebar is open */}
      {isMobile && isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-20"
          onClick={toggleSidebar}
        />
      )}
      <div className="flex h-full w-full relative">
        {/* Secondary ChatSidebar - positioned next to the Main sidebar */}
        <div 
          className={`${isSidebarCollapsed ? 'w-16' : 'w-[300px] md:w-[320px] lg:w-[350px]'} h-full border-r border-gray-200 transition-all duration-300`}
          style={{ boxShadow: '1px 0 5px rgba(0,0,0,0.03)' }}
        >
          <ChatSidebar
            isMobile={isMobile}
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filteredChats={filteredChats}
            handleNewChat={handleNewChat}
            handleChatBoxClick={handleChatBoxClick}
            handleOptionsClick={handleOptionsClick}
            selectedChatId={selectedChatId}
            editingChatId={editingChatId}
            editingChatName={editingChatName}
            setEditingChatName={setEditingChatName}
            handleSaveRename={handleSaveRename}
            setEditingChatId={setEditingChatId}
            isCollapsed={isSidebarCollapsed}
            toggleCollapse={toggleSidebarCollapse}
          />
        </div>

        {/* Chat content area */}
        <div className="flex-1 flex flex-col h-full relative overflow-hidden bg-white">
          {/* Fixed header */}
          <div className="sticky top-0 z-10 w-full">
            <ChatHeader
              selectedChatId={selectedChatId}
              chats={chats}
              isMobile={isMobile}
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              onSearch={handleSearchInChat}
              onScrollToMatch={handleScrollToMatch}
              availableDates={getAvailableDates(messages)}
              onDateSelect={handleDateSelect}
            />
          </div>

          {/* Message container */}
          <div
            className="flex-grow overflow-y-auto w-full h-[calc(100%-72px-80px)]"
            ref={messagesContainerRef}
          >
            <div className="flex flex-col gap-y-3 w-full p-4 pb-6">
              {selectedChatId ? (
                messages.length > 0 ? (
                  groupMessagesByDate(messages).map((group, groupIndex) => (
                    <React.Fragment key={`day-group-${groupIndex}`}>
                      {/* Day separator */}
                      <div className="flex justify-center my-4">
                        <div className="bg-gray-200 text-gray-600 rounded-full px-4 py-1 text-xs font-medium">
                          {formatDateHeader(group.date)}
                        </div>
                      </div>

                      {/* Messages for this day */}
                      {group.messages.map((message, index) => (
                        <div
                          className="message-container w-full"
                          key={`message-wrapper-${groupIndex}-${index}`}
                          data-message-id={message.id} // Garante que o ID seja adicionado para cada mensagem
                        >
                          <MessageContainer
                            key={index}
                            variant={message.isBot ? 'left' : 'right'}
                            value={message.message}
                            contentType={message.type}
                            contentUrl={message.contentUrl}
                            isMobile={isMobile}
                            message={message}
                            messages={messages}
                            onReplyMessage={handleReplyMessage}
                          />
                        </div>
                      ))}
                    </React.Fragment>
                  ))
                ) : (
                  <EmptyStateMessage
                    selectedChatId={selectedChatId}
                    messages={messages}
                    handleNewChat={handleNewChat}
                  />
                )
              ) : (
                <EmptyStateMessage
                  selectedChatId={selectedChatId}
                  messages={messages}
                  handleNewChat={handleNewChat}
                />
              )}
              <div ref={messagesEndRef} />
            </div>

            {/* Floating scroll-to-bottom button */}
            {hasScrolledUp && messages.length > 0 && (
              <button
                onClick={scrollToBottom}
                className="scroll-to-bottom-btn absolute bottom-[100px] right-6 bg-white text-primary rounded-full shadow-lg hover:bg-gray-100 transition-all duration-200 flex items-center justify-center z-20"
                aria-label="Scroll to bottom"
                style={{
                  width: '48px',
                  height: '48px',
                  border: '2px solid #635AFF'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.5}
                    d="M19 14l-7 7m0 0l-7-7m7 7V3"
                  />
                </svg>
              </button>
            )}
          </div>

          {/* Fixed message input */}
          <div className="sticky bottom-0 w-full z-10">
            {selectedChatId && (
              <MediaMessageInput
                disabled={!selectedChatId}
                isLoading={isLoading}
                onSendMessage={handleSendMessage}
                placeholder={
                  selectedChatId
                    ? 'Digite sua mensagem...'
                    : 'Selecione uma conversa para enviar mensagens'
                }
                replyingTo={replyingTo}
                onCancelReply={handleCancelReply}
              />
            )}
          </div>
        </div>
      </div>

      {/* Popup menu for chat options */}
      <ChatOptionsPopup
        popupPosition={popupPosition}
        isMobile={isMobile}
        openPopupId={openPopupId}
        chats={chats}
        handleRenameChat={handleRenameChat}
        handleDeleteChat={handleDeleteChat}
        closePopup={closePopup}
      />

      {/* Delete confirmation modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={confirmDeleteChat}
      />

      {/* New chat modal */}
      <NewChatModal
        isOpen={isNewChatModalOpen}
        onClose={() => setIsNewChatModalOpen(false)}
      />

      {/* Add a style for highlighting search matches */}
      <style>{`
        .reply-highlight {
            animation: replyHighlight 2s ease-in-out;
            position: relative;
            z-index: 10;
            box-shadow: 0 0 0 3px rgba(99, 90, 255, 0.5);
            border-radius: 8px;
          }

          @keyframes replyHighlight {
            0% { background-color: transparent; }
            25% { background-color: rgba(99, 90, 255, 0.2); }
            75% { background-color: rgba(99, 90, 255, 0.2); }
            100% { background-color: transparent; }
          }
          .highlight-match {
            animation: pulse 1.5s ease-in-out;
          }
          
          @keyframes pulse {
            0% { background-color: transparent; }
            25% { background-color: rgba(97, 90, 255, 0.2); }
            75% { background-color: rgba(97, 90, 255, 0.2); }
            100% { background-color: transparent; }
          }
          
          .scroll-to-bottom-btn {
            animation: fadeIn 0.3s ease-in-out;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
          }
          
          .scroll-to-bottom-btn:hover {
            transform: scale(1.05);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
          }
          
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          
          .sidebar-transition {
            transition: width 0.3s ease-in-out;
          }
          
          /* Add styles for date navigation highlighting */
          .date-highlight {
            animation: dateHighlight 2s ease-in-out;
          }
          
          @keyframes dateHighlight {
            0% { background-color: transparent; }
            25% { background-color: rgba(99, 90, 255, 0.15); }
            75% { background-color: rgba(99, 90, 255, 0.15); }
            100% { background-color: transparent; }
          }
      `}</style>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { 
  Box, 
  Typography, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  FormControlLabel, 
  Switch, 
  Button, 
  Chip, 
  InputAdornment,
  SelectChangeEvent
} from '@mui/material'
import { Produto, ProdutoFormData } from '../types'
import { ProdutoLoading } from './ProdutoLoading'
import { extractTags } from '../utils/helpers'

// Categorias predefinidas (na prática, poderiam vir de uma API)
const categoriasPredefinidas = [
  'Eletrônicos',
  'Móveis',
  'Vestuário',
  'Alimentação',
  'Beleza',
  'Informática',
  'Consultoria',
  'Desenvolvimento',
  'Manutenção',
  'Treinamento',
  'Suporte Técnico',
  'Design'
]

// Unidades de medida predefinidas
const unidadesMedida = [
  'Unidade',
  'Kg',
  'g',
  'L',
  'ml',
  'Metro',
  'cm',
  'm²',
  'Pacote',
  'Caixa',
  'Hora'
]

interface ProdutoFormProps {
  produto?: Produto | null
  edit: boolean
  loading: boolean
  onSubmit: (data: ProdutoFormData) => void
  onDelete?: () => void
}

export const ProdutoForm: React.FC<ProdutoFormProps> = ({
  produto,
  edit,
  loading,
  onSubmit,
  onDelete
}) => {
  // Estados para os campos do produto
  const [produtoNome, setProdutoNome] = useState('')
  const [produtoDescricao, setProdutoDescricao] = useState('')
  const [produtoValor, setProdutoValor] = useState('')
  const [produtoCodigo, setProdutoCodigo] = useState('')
  const [produtoCategoria, setProdutoCategoria] = useState('')
  const [produtoIsServico, setProdutoIsServico] = useState(false)
  const [produtoEstoque, setProdutoEstoque] = useState('')
  const [produtoUnidadeMedida, setProdutoUnidadeMedida] = useState('')
  const [produtoMarca, setProdutoMarca] = useState('')
  const [produtoFornecedor, setProdutoFornecedor] = useState('')
  const [produtoTags, setProdutoTags] = useState('')

  // Preenche o formulário quando um produto é carregado para edição
  useEffect(() => {
    if (produto) {
      setProdutoNome(produto.nome)
      setProdutoDescricao(produto.descricao || '')
      setProdutoValor(produto.valor.toString())
      setProdutoCodigo(produto.codigo)
      setProdutoCategoria(produto.categoria)
      setProdutoIsServico(produto.isServico)
      setProdutoEstoque(produto.estoque?.toString() || '')
      setProdutoUnidadeMedida(produto.unidadeMedida || '')
      setProdutoMarca(produto.marca || '')
      setProdutoFornecedor(produto.fornecedor || '')
      setProdutoTags(produto.tags || '')
    } else {
      // Limpa o formulário para criar novo produto
      setProdutoNome('')
      setProdutoDescricao('')
      setProdutoValor('')
      setProdutoCodigo('')
      setProdutoCategoria('')
      setProdutoIsServico(false)
      setProdutoEstoque('')
      setProdutoUnidadeMedida('')
      setProdutoMarca('')
      setProdutoFornecedor('')
      setProdutoTags('')
    }
  }, [produto])

  // Formatação de valores monetários
  const handleValorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valor = e.target.value.replace(/\D/g, '')
    if (valor === '') {
      setProdutoValor('')
      return
    }
    
    const valorNumerico = parseInt(valor) / 100
    setProdutoValor(valorNumerico.toString())
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const produtoData: ProdutoFormData = {
      nome: produtoNome,
      descricao: produtoDescricao,
      valor: produtoValor,
      codigo: produtoCodigo,
      categoria: produtoCategoria,
      isServico: produtoIsServico,
      estoque: produtoIsServico ? undefined : produtoEstoque,
      unidadeMedida: produtoUnidadeMedida || undefined,
      marca: produtoMarca || undefined,
      fornecedor: produtoFornecedor || undefined,
      tags: produtoTags
    }

    onSubmit(produtoData)
  }

  if (loading) {
    return <ProdutoLoading message="Processando..." />
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* Título do formulário */}
      <Typography 
        variant="h6" 
        sx={{ 
          mb: 3, 
          color: produtoIsServico ? '#9c27b0' : '#3f51b5',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {edit ? 'Editar' : 'Novo'} {produtoIsServico ? 'Serviço' : 'Produto'}
      </Typography>

      {/* Tipo de item (Produto/Serviço) */}
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ mr: 2 }}>Tipo:</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={produtoIsServico}
              onChange={(e) => setProdutoIsServico(e.target.checked)}
              color={produtoIsServico ? "secondary" : "primary"}
            />
          }
          label={produtoIsServico ? "Serviço" : "Produto"}
        />
      </Box>
      
      {/* Dados básicos */}
      <TextField
        label="Nome do produto/serviço"
        value={produtoNome}
        onChange={(e) => setProdutoNome(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      
      <TextField
        label="Código"
        value={produtoCodigo}
        onChange={(e) => setProdutoCodigo(e.target.value)}
        fullWidth
        margin="normal"
        required
        helperText="Código único para identificação do produto/serviço"
      />
      
      <TextField
        label="Descrição"
        value={produtoDescricao}
        onChange={(e) => setProdutoDescricao(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={3}
      />
      
      {/* Valor */}
      <TextField
        label="Valor"
        value={produtoValor}
        onChange={handleValorChange}
        fullWidth
        margin="normal"
        required
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
      />
      
      {/* Categoria */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="categoria-label">Categoria</InputLabel>
        <Select
          labelId="categoria-label"
          value={produtoCategoria}
          onChange={(e: SelectChangeEvent) => setProdutoCategoria(e.target.value)}
          label="Categoria"
          required
        >
          {categoriasPredefinidas.map((categoria) => (
            <MenuItem key={categoria} value={categoria}>
              {categoria}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      {/* Campos específicos para produtos */}
      {!produtoIsServico && (
        <>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label="Estoque"
              value={produtoEstoque}
              onChange={(e) => setProdutoEstoque(e.target.value.replace(/\D/g, ''))}
              margin="normal"
              type="number"
              sx={{ flex: 1 }}
            />
            
            <FormControl sx={{ flex: 1 }} margin="normal">
              <InputLabel id="unidade-medida-label">Unidade de Medida</InputLabel>
              <Select
                labelId="unidade-medida-label"
                value={produtoUnidadeMedida}
                onChange={(e: SelectChangeEvent) => setProdutoUnidadeMedida(e.target.value)}
                label="Unidade de Medida"
              >
                {unidadesMedida.map((unidade) => (
                  <MenuItem key={unidade} value={unidade}>
                    {unidade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label="Marca"
              value={produtoMarca}
              onChange={(e) => setProdutoMarca(e.target.value)}
              margin="normal"
              fullWidth
            />
            
            <TextField
              label="Fornecedor"
              value={produtoFornecedor}
              onChange={(e) => setProdutoFornecedor(e.target.value)}
              margin="normal"
              fullWidth
            />
          </Box>
        </>
      )}
      
      {/* Tags */}
      <TextField
        label="Tags"
        value={produtoTags}
        onChange={(e) => setProdutoTags(e.target.value)}
        fullWidth
        margin="normal"
        placeholder="tecnologia, hardware, periférico"
        helperText="Separe as tags por vírgulas"
      />

      {/* Preview das tags */}
      {produtoTags && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1, mb: 2 }}>
          {extractTags(produtoTags).map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              size="small"
              sx={{ 
                backgroundColor: '#f5f5f5',
                color: '#666',
                fontSize: '0.75rem'
              }}
            />
          ))}
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 2, mt: 4, mb: 2 }}>
        {edit && onDelete ? (
          <>
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={onDelete}
              disabled={loading}
            >
              Excluir {produtoIsServico ? 'Serviço' : 'Produto'}
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color={produtoIsServico ? "secondary" : "primary"}
              disabled={loading}
            >
              Atualizar {produtoIsServico ? 'Serviço' : 'Produto'}
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            variant="contained"
            color={produtoIsServico ? "secondary" : "primary"}
            disabled={loading}
          >
            Salvar {produtoIsServico ? 'Serviço' : 'Produto'}
          </Button>
        )}
      </Box>
    </form>
  )
}
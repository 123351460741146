// useAISalesAssistantWithContext.js
import React from 'react';
import { useWizards } from '../../context/WizardsContext';
import { IconButton, Tooltip, Box } from '@mui/material';
import { Psychology } from '@mui/icons-material';

/**
 * Hook para adicionar funcionalidade de assistente de vendas IA ao MediaMessageInput
 * Versão adaptada para usar o WizardsContext
 * 
 * @param {Object} options - Opções de configuração
 * @param {boolean} options.enabled - Se o assistente de IA está habilitado
 * @param {Array} options.chatMessages - Mensagens do chat atual
 * @param {Array} options.productCatalog - Catálogo de produtos disponíveis
 * @param {Object} options.clientInfo - Informações do cliente atual
 * @param {Function} options.setInputMessage - Função para atualizar o valor do campo de entrada de mensagem
 * @param {string} options.inputMessage - Valor atual do campo de entrada de mensagem
 * @param {boolean} options.disabled - Se o componente está desabilitado
 * @param {boolean} options.isLoading - Se está carregando
 * @returns {Object} - Objeto contendo elementos e handlers para a integração com o assistente IA
 */
const useAISalesAssistantWithContext = ({
  enabled = false,
  chatMessages = [],
  productCatalog = [],
  clientInfo = null,
  setInputMessage,
  inputMessage = '',
  disabled = false,
  isLoading = false,
}) => {
  const { openAIAssistant } = useWizards();

  // Função para abrir o wizard do assistente IA
  const handleOpenAIAssistant = () => {
    openAIAssistant({
      clientInfo,
      productCatalog,
      chatMessages,
      onDataSelected: (formattedData) => {
        setInputMessage(inputMessage + (inputMessage ? '\n\n' : '') + formattedData);
      }
    });
  };

  // Componente do botão de atalho do Assistente IA
  const AIAssistantShortcutButton = () => {
    if (!enabled) return null;
    
    return (
      <Tooltip title="Assistente IA de Vendas">
        <span>
          <IconButton
            color="secondary"
            onClick={handleOpenAIAssistant}
            disabled={disabled || isLoading}
            sx={{ mr: 1 }}
          >
            <Psychology />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  // Componente do item do menu de mídia do Assistente IA
  const AIAssistantMenuItem = ({ onClose }) => {
    if (!enabled) return null;
    
    return (
      <Box
        onClick={() => {
          handleOpenAIAssistant();
          if (onClose) onClose();
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px',
          borderRadius: '8px',
          cursor: 'pointer',
          gridColumn: 'span 2',
          backgroundColor: 'rgba(156, 39, 176, 0.1)', // Cor secundária (roxo) para diferenciar do Omie
          '&:hover': {
            backgroundColor: 'rgba(156, 39, 176, 0.2)',
          },
        }}
      >
        <Psychology color="secondary" />
        <Box sx={{ fontSize: '0.75rem', mt: '4px' }}>Assistente IA</Box>
      </Box>
    );
  };

  return {
    AIAssistantShortcutButton,
    AIAssistantMenuItem,
    handleOpenAIAssistant,
  };
};

export default useAISalesAssistantWithContext;
// NotificationContext.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react'
import { Badge, IconButton, Typography, Box, Button } from '@mui/material'
import { NotificationsNone as NotificationIcon } from '@mui/icons-material'

const NotificationSoundPlayer = () => {
  // Referência para o elemento de áudio
  const audioRef = useRef(null)

  // Função para reproduzir o som
  const playSound = useCallback((soundType = 'default') => {
    if (audioRef.current) {
      // Reproduz o som
      audioRef.current.currentTime = 0 // Reinicia o áudio caso já esteja tocando
      audioRef.current.play().catch((err) => {
        // Captura erros (geralmente ocorrem quando o usuário não interagiu com a página)
        console.log('Não foi possível reproduzir o som:', err)
      })
    }
  }, [])

  // Expõe a função de reprodução globalmente
  useEffect(() => {
    window.playNotificationSound = playSound
    return () => {
      delete window.playNotificationSound
    }
  }, [playSound])

  return (
    <audio ref={audioRef} preload="auto" style={{ display: 'none' }}>
      <source
        src="data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4Ljc2LjEwMAAAAAAAAAAAAAAA/+M4wAAAAAAAAAAAAEluZm8AAAAPAAAAAwAAAbAAiIiIiIiIiIiIiIiIiIiIiIiIiIjMzMzMzMzMzMzMzMzMzMzMzMzMzMz///////////////////8AAAAATGF2YzU4LjEzAAAAAAAAAAAAAAAAJAYFAAAAAAAAAbDPTEEhAAAA/+MYxAAUmAqiSAUwYsGwfLCfROie1JHbIBgMBgRPDIJnDNBmz5sqDIPggCOYHQXDA4xiAYyD4Jh+sRnHoCAaDoXg+GAYR6UEAkEQQBAIgmEYRhEIhILhEREMYxiMIhCGQ+D4fD4wBAEAwj//4xjECRgPiyoIBjDAcBAIAgGAYBBEMRjEQhDIIhcMiYIgqG4aBoYzKdTqfUSkREpdERDRHYxGI6ISEhISESRJEkSRJEmTc3Nzc3Nzc3P/8wSJEkSRJEnNzc3Nzc3Nzc3Nzf/jGMQFGBQixAAGMKAAAABJEnNzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3M="
        type="audio/mpeg"
      />
      Seu navegador não suporta o elemento de áudio.
    </audio>
  )
}

// Crie o contexto com um valor padrão
const NotificationContext = createContext({
  notifications: [],
  unreadCount: 0,
  showBanner: false,
  latestNotification: null,
  addNotification: () => {},
  markAsRead: () => {},
  markAllAsRead: () => {},
  setShowBanner: () => {},
})

// Tipos de notificações
export const NOTIFICATION_TYPES = {
  CHAT: 'chat',
  TASK: 'task',
  ACTIVITY: 'activity',
  CARD: 'card',
}

// Provider de notificações
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])
  const [unreadCount, setUnreadCount] = useState(0)
  const [showBanner, setShowBanner] = useState(false)
  const [latestNotification, setLatestNotification] = useState(null)
  const [soundEnabled, setSoundEnabled] = useState(true) //

  // Função para adicionar nova notificação
  const addNotification = (notification) => {
    const newNotification = {
      id: Date.now(),
      timestamp: new Date(),
      read: false,
      ...notification,
    }

    setNotifications((prev) => [newNotification, ...prev])
    setLatestNotification(newNotification)
    setShowBanner(true)

    // Atualiza contador de não lidas
    setUnreadCount((prev) => prev + 1)

    if (soundEnabled && window.playNotificationSound) {
      window.playNotificationSound(notification.type)
    }

    // Esconde o banner após 5 segundos
    setTimeout(() => {
      // setShowBanner(false)
    }, 5000)
  }

  const toggleSound = () => {
    setSoundEnabled((prev) => !prev)
    // Opcionalmente, salvar preferência no localStorage
    localStorage.setItem('notificationSoundEnabled', (!soundEnabled).toString())
  }

  useEffect(() => {
    const savedPreference = localStorage.getItem('notificationSoundEnabled')
    if (savedPreference !== null) {
      setSoundEnabled(savedPreference === 'true')
    }
  }, [])

  // Função para marcar notificação como lida
  const markAsRead = (id) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification,
      ),
    )

    // Atualiza contador de não lidas
    recountUnread()
  }

  // Função para marcar todas como lidas
  const markAllAsRead = () => {
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, read: true })),
    )
    setUnreadCount(0)
  }

  // Reconta não lidas
  const recountUnread = () => {
    const count = notifications.filter((n) => !n.read).length
    setUnreadCount(count)
  }

  // Mock de recebimento de notificações via websocket/API
  useEffect(() => {
    // Simula recebimento de novas notificações a cada 30 segundos
    const mockNotifications = [
      {
        type: NOTIFICATION_TYPES.CHAT,
        title: 'Nova mensagem',
        message: 'João enviou uma nova mensagem',
        entityId: 'chat-123',
        sender: 'João Silva',
        avatar: 'JS',
      },
      {
        type: NOTIFICATION_TYPES.TASK,
        title: 'Tarefa atribuída',
        message: 'Você recebeu uma nova tarefa: Ligar para cliente',
        entityId: 'task-456',
        priority: 'alta',
      },
      {
        type: NOTIFICATION_TYPES.CARD,
        title: 'Card movido',
        message: 'Card "Proposta Comercial" foi movido para "Em Aprovação"',
        entityId: 'card-789',
        boardType: 'inadimplencia',
      },
      {
        type: NOTIFICATION_TYPES.ACTIVITY,
        title: 'Atividade para hoje',
        message: 'Lembrete: Reunião com cliente XYZ às 15h',
        entityId: 'activity-101',
        date: new Date(),
      },
    ]

    const interval = setInterval(() => {
      // Seleciona uma notificação aleatória para simular
      // const randomIndex = Math.floor(Math.random() * mockNotifications.length)
      // addNotification(mockNotifications[randomIndex])
    }, 30000)

    // Adiciona uma notificação inicial para teste
    setTimeout(() => {
      // addNotification(mockNotifications[0])
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        showBanner,
        latestNotification,
        addNotification,
        markAsRead,
        markAllAsRead,
        setShowBanner,
        soundEnabled,
        toggleSound,
      }}
    >
      {/* Componente que gerencia o som */}
      <NotificationSoundPlayer />
      {children}
    </NotificationContext.Provider>
  )
}

// Hook personalizado para usar o contexto
export const useNotifications = () => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error(
      'useNotifications must be used within a NotificationProvider',
    )
  }
  return context
}

// Componente Banner de Notificação// Componente Banner de Notificação moderno e minimalista
export const NotificationBanner = () => {
  const { showBanner, latestNotification, setShowBanner, markAsRead } =
    useNotifications()
  const [isExiting, setIsExiting] = useState(false)
  const bannerRef = useRef(null)

  if (!showBanner || !latestNotification) return null

  const handleClick = () => {
    markAsRead(latestNotification.id)
    setIsExiting(true)

    // Animação de saída antes de fechar completamente
    setTimeout(() => {
      setShowBanner(false)
      setIsExiting(false)
    }, 300)

    // Navegar para a entidade relacionada
    if (window.navigateToEntity) {
      window.navigateToEntity(
        latestNotification.type,
        latestNotification.entityId,
      )
    }
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setIsExiting(true)

    // Animação de saída antes de fechar completamente
    setTimeout(() => {
      setShowBanner(false)
      setIsExiting(false)
    }, 300)
  }

  // Determina a cor do indicador baseada no tipo
  const getIndicatorColor = () => {
    switch (latestNotification.type) {
      case NOTIFICATION_TYPES.CHAT:
        return '#1976d2' // azul
      case NOTIFICATION_TYPES.TASK:
        return '#e53935' // vermelho
      case NOTIFICATION_TYPES.CARD:
        return '#43a047' // verde
      case NOTIFICATION_TYPES.ACTIVITY:
        return '#fb8c00' // laranja
      default:
        return '#757575' // cinza
    }
  }

  // Determina o ícone baseado no tipo
  const getIcon = () => {
    switch (latestNotification.type) {
      case NOTIFICATION_TYPES.CHAT:
        return '💬'
      case NOTIFICATION_TYPES.TASK:
        return '📋'
      case NOTIFICATION_TYPES.CARD:
        return '🔄'
      case NOTIFICATION_TYPES.ACTIVITY:
        return '📅'
      default:
        return '🔔'
    }
  }

  return (
    <div
      ref={bannerRef}
      onClick={handleClick}
      style={{
        position: 'fixed',
        top: '16px',
        right: '16px',
        width: '320px',
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        overflow: 'hidden',
        cursor: 'pointer',
        zIndex: 1400,
        transform: isExiting ? 'translateX(380px)' : 'translateX(0)',
        opacity: isExiting ? 0 : 1,
        transition: 'transform 0.3s ease, opacity 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Barra indicadora de cor no topo */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '4px',
          height: '100%',
          backgroundColor: getIndicatorColor(),
        }}
      />

      <div
        style={{
          display: 'flex',
          padding: '14px 16px 14px 20px',
        }}
      >
        {/* Ícone */}
        <div
          style={{
            fontSize: '20px',
            marginRight: '12px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {getIcon()}
        </div>

        {/* Conteúdo */}
        <div style={{ flex: 1 }}>
          <div
            style={{
              fontWeight: 600,
              fontSize: '14px',
              marginBottom: '4px',
              color: '#212121',
            }}
          >
            {latestNotification.title}
          </div>
          <div
            style={{
              fontSize: '13px',
              color: '#616161',
              lineHeight: 1.4,
            }}
          >
            {latestNotification.message}
          </div>
        </div>

        {/* Botão de fechar */}
        <button
          onClick={handleClose}
          style={{
            background: 'transparent',
            border: 'none',
            color: '#9e9e9e',
            fontSize: '18px',
            cursor: 'pointer',
            padding: '0 0 0 8px',
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '-2px',
          }}
        >
          ×
        </button>
      </div>

      {/* Barra de progresso para auto-fechar */}
      <div
        style={{
          height: '2px',
          backgroundColor: '#f5f5f5',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            backgroundColor: getIndicatorColor(),
            width: '100%',
            animation: 'progress 5s linear forwards',
          }}
        />
      </div>

      {/* Estilo para a animação da barra de progresso */}
      <style>
        {`
          @keyframes progress {
            from { width: 100%; }
            to { width: 0%; }
          }
        `}
      </style>
    </div>
  )
}
// Componente Menu de Notificações
// No componente NotificationMenu, ajuste o posicionamento do dropdown

// Solução aprimorada para o posicionamento do menu de notificações

export const NotificationMenu = () => {
  const { notifications, unreadCount, markAsRead, markAllAsRead } =
    useNotifications()
  const [open, setOpen] = useState(false)
  const menuRef = useRef(null)
  const buttonRef = useRef(null)

  // Função para navegar para a entidade
  const navigateToEntity = (type, entityId) => {
    if (window.navigateToEntity) {
      window.navigateToEntity(type, entityId)
      setOpen(false)
    }
  }

  // Ícone baseado no tipo
  const getNotificationIcon = (type) => {
    switch (type) {
      case NOTIFICATION_TYPES.CHAT:
        return '💬'
      case NOTIFICATION_TYPES.TASK:
        return '📋'
      case NOTIFICATION_TYPES.CARD:
        return '🔄'
      case NOTIFICATION_TYPES.ACTIVITY:
        return '📅'
      default:
        return '🔔'
    }
  }

  // Ajusta a posição do menu baseado nos limites da tela
  const adjustMenuPosition = useCallback(() => {
    if (!menuRef.current || !buttonRef.current) return

    const menu = menuRef.current
    const button = buttonRef.current
    const buttonRect = button.getBoundingClientRect()

    // Inicialmente, definimos o menu com estilos básicos
    menu.style.position = 'fixed'
    menu.style.width = '320px'
    menu.style.maxHeight = '500px'
    menu.style.zIndex = '1200'

    // Posicionamento inicial abaixo do botão
    menu.style.top = `${buttonRect.bottom + 5}px`

    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    // Verificar melhor posição horizontal
    // Tentar centralizar em relação ao botão primeiro
    const idealLeft = buttonRect.left + buttonRect.width / 2 - 320 / 2 // 320px é a largura do menu

    // Verificar se sai pela esquerda
    if (idealLeft < 10) {
      menu.style.left = '10px' // Margem mínima da esquerda
    }
    // Verificar se sai pela direita
    else if (idealLeft + 320 > viewportWidth - 10) {
      menu.style.left = `${viewportWidth - 320 - 10}px` // Margem mínima da direita
    }
    // Caso contrário, centralizar
    else {
      menu.style.left = `${idealLeft}px`
    }

    // Verificar se cabe abaixo do botão
    const menuHeight = menu.offsetHeight
    if (buttonRect.bottom + menuHeight > viewportHeight - 10) {
      // Se não couber abaixo, posicionar acima do botão
      menu.style.top = 'auto'
      menu.style.bottom = `${viewportHeight - buttonRect.top + 5}px`

      // Verificar se a altura disponível acima é suficiente
      const spaceAbove = buttonRect.top
      if (spaceAbove < menuHeight) {
        menu.style.maxHeight = `${spaceAbove - 10}px`
      }
    }

    // Aplicar box-shadow e outras propriedades visuais
    menu.style.backgroundColor = 'white'
    menu.style.borderRadius = '8px'
    menu.style.boxShadow = '0 4px 20px rgba(0,0,0,0.15)'
    menu.style.overflow = 'hidden'
  }, [])

  // Usar useLayoutEffect para calcular a posição antes da renderização
  useLayoutEffect(() => {
    if (open) {
      adjustMenuPosition()

      // Também atualizar ao redimensionar a janela
      window.addEventListener('resize', adjustMenuPosition)
      return () => window.removeEventListener('resize', adjustMenuPosition)
    }
  }, [open, adjustMenuPosition])

  // Fechar o menu quando clicar fora
  useEffect(() => {
    if (!open) return

    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [open])

  return (
    <div style={{ position: 'relative' }}>
      <div ref={buttonRef}>
        <Badge
          badgeContent={unreadCount}
          color="error"
          onClick={() => setOpen(!open)}
        >
          <NotificationIcon style={{ cursor: 'pointer' }} />
        </Badge>
      </div>

      {open && (
        <div ref={menuRef} style={{ position: 'fixed' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px',
              borderBottom: '1px solid rgba(0,0,0,0.06)',
            }}
          >
            <Typography variant="subtitle1" fontWeight={600}>
              Notificações
            </Typography>
            {unreadCount > 0 && (
              <Button
                size="small"
                onClick={markAllAsRead}
                sx={{ textTransform: 'none', fontSize: '0.75rem' }}
              >
                Marcar todas como lidas
              </Button>
            )}
          </div>

          <div style={{ maxHeight: '440px', overflowY: 'auto' }}>
            {notifications.length === 0 ? (
              <div
                style={{
                  padding: '20px',
                  textAlign: 'center',
                  color: 'rgba(0,0,0,0.6)',
                }}
              >
                Nenhuma notificação
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  onClick={() => {
                    markAsRead(notification.id)
                    navigateToEntity(notification.type, notification.entityId)
                  }}
                  style={{
                    padding: '12px 16px',
                    borderBottom: '1px solid rgba(0,0,0,0.06)',
                    backgroundColor: notification.read
                      ? 'transparent'
                      : 'rgba(25, 118, 210, 0.05)',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '20px',
                        lineHeight: '1',
                        marginTop: '2px',
                      }}
                    >
                      {getNotificationIcon(notification.type)}
                    </div>
                    <div style={{ flex: 1 }}>
                      <div
                        style={{ fontWeight: notification.read ? 400 : 600 }}
                      >
                        {notification.title}
                      </div>
                      <div
                        style={{
                          fontSize: '0.875rem',
                          marginTop: '4px',
                          color: 'rgba(0,0,0,0.7)',
                        }}
                      >
                        {notification.message}
                      </div>
                      <div
                        style={{
                          fontSize: '0.75rem',
                          marginTop: '4px',
                          color: 'rgba(0,0,0,0.5)',
                        }}
                      >
                        {new Date(notification.timestamp).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  )
}

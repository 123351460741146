import React from 'react';
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import {
  Title as TitleIcon,
  PriorityHigh as PriorityIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { FormSection, SectionTitle, FormFieldGroup, FormRow } from './styles';
import { CardFormData, Column, FormErrors } from './types/CardTypes'; // Ajuste o caminho

interface BasicInfoSectionProps {
  formData: CardFormData;
  errors: FormErrors;
  columns: Column[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
  handleSelectChange: (e: SelectChangeEvent<string>) => void; // Nova propriedade
}

const BasicInfoSection: React.FC<BasicInfoSectionProps> = ({
  formData,
  errors,
  columns,
  handleChange,
  handleSelectChange, // Adicione o novo parâmetro
}) => {
  return (
    <FormSection elevation={0}>
      <SectionTitle>
        <TitleIcon />
        Informações Básicas
      </SectionTitle>

      <FormFieldGroup>
        <TextField
          name="title"
          label="Título *"
          fullWidth
          variant="outlined"
          value={formData.title}
          onChange={handleChange}
          error={!!errors.title}
          helperText={errors.title}
          InputProps={{
            startAdornment: errors.title ? null : (
              <InputAdornment position="start">
                <TitleIcon color="action" fontSize="small" />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#635AFF',
                borderWidth: '1px',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#635AFF',
                borderWidth: '1px',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#635AFF',
            },
          }}
        />

        <FormRow>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errors.columnId}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#635AFF',
                  borderWidth: '1px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#635AFF',
                  borderWidth: '1px',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#635AFF',
              },
            }}
          >
            <InputLabel>Coluna *</InputLabel>
            <Select
              name="columnId"
              value={formData.columnId}
              onChange={handleSelectChange} // Use handleSelectChange aqui
              label="Coluna *"
              sx={{
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              {Array.isArray(columns) &&
                columns.map((column) => (
                  <MenuItem key={column.id} value={column.id}>
                    {column.title}
                  </MenuItem>
                ))}
            </Select>
            {errors.columnId && (
              <FormHelperText>{errors.columnId}</FormHelperText>
            )}
          </FormControl>

          <FormControl 
            fullWidth 
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#635AFF',
                  borderWidth: '1px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#635AFF',
                  borderWidth: '1px',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#635AFF',
              },
            }}
          >
            <InputLabel>Prioridade</InputLabel>
            <Select
              name="priority"
              value={formData.priority}
              onChange={handleSelectChange} // Use handleSelectChange aqui
              label="Prioridade"
              sx={{
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  '&:before': {
                    content: '""',
                    display: 'inline-block',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    marginRight: '8px',
                    backgroundColor:
                      formData.priority === 'alta'
                        ? '#f44336'
                        : formData.priority === 'média'
                          ? '#ff9800'
                          : '#4caf50',
                  },
                },
              }}
              endAdornment={
                <Tooltip title="Define a prioridade do card no quadro">
                  <InfoIcon 
                    fontSize="small" 
                    sx={{ 
                      color: 'rgba(0,0,0,0.3)',
                      position: 'absolute',
                      right: 32,
                      cursor: 'pointer',
                      '&:hover': { color: '#635AFF' },
                    }} 
                  />
                </Tooltip>
              }
            >
              <MenuItem
                value="alta"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  borderRadius: '6px',
                  m: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(244, 67, 54, 0.08)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(244, 67, 54, 0.12) !important',
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    width: 14,
                    height: 14,
                    borderRadius: '50%',
                    bgcolor: '#f44336',
                    display: 'inline-block',
                    mr: 1.5,
                    boxShadow: '0 0 0 2px rgba(244, 67, 54, 0.2)',
                  }}
                />
                Alta
              </MenuItem>
              <MenuItem
                value="média"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  borderRadius: '6px',
                  m: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 152, 0, 0.08)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(255, 152, 0, 0.12) !important',
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    width: 14,
                    height: 14,
                    borderRadius: '50%',
                    bgcolor: '#ff9800',
                    display: 'inline-block',
                    mr: 1.5,
                    boxShadow: '0 0 0 2px rgba(255, 152, 0, 0.2)',
                  }}
                />
                Média
              </MenuItem>
              <MenuItem
                value="baixa"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  borderRadius: '6px',
                  m: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(76, 175, 80, 0.08)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(76, 175, 80, 0.12) !important',
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    width: 14,
                    height: 14,
                    borderRadius: '50%',
                    bgcolor: '#4caf50',
                    display: 'inline-block',
                    mr: 1.5,
                    boxShadow: '0 0 0 2px rgba(76, 175, 80, 0.2)',
                  }}
                />
                Baixa
              </MenuItem>
            </Select>
          </FormControl>
        </FormRow>
      </FormFieldGroup>
    </FormSection>
  );
};

export default BasicInfoSection;
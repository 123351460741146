import React from 'react'
import { TextField } from '@mui/material'

export const TemplateFields = ({
  modalSelectedTemplate,
  dynamicFields,
  handleTemplateFieldChange,
  replacedTemplateText,
}) => {
  if (!modalSelectedTemplate) return null
  
  return (
    <>
      {dynamicFields.length > 0 && (
        <div className="p-3 bg-gray-50 rounded-lg">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Campos do Template
          </label>
          <div className="space-y-3">
            {dynamicFields.map((field, index) => {
              const fieldKey = field.replace(/[{}]/g, '')
              return (
                <div key={index}>
                  <label className="block text-xs text-gray-500 mb-1">
                    Campo {fieldKey}
                  </label>
                  <TextField
                    fullWidth
                    size="small"
                    onChange={(e) =>
                      handleTemplateFieldChange(fieldKey, e.target.value)
                    }
                    variant="outlined"
                    placeholder={`Valor para ${fieldKey}`}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        fontSize: '0.875rem',
                      },
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}

      {modalSelectedTemplate && (
        <div className="p-3 bg-indigo-50 rounded-lg">
          <label className="block text-sm font-medium text-indigo-900 mb-1">
            Preview da mensagem
          </label>
          <p
            className="text-sm text-indigo-800"
            dangerouslySetInnerHTML={{
              __html: replacedTemplateText,
            }}
          />
        </div>
      )}
    </>
  )
}
import React, { useCallback, useEffect, useState } from 'react'
import { 
  Card, 
  CardContent, 
  Button, 
  Grid, 
  Typography, 
  Paper, 
  Box, 
  CircularProgress, 
  Avatar,
  Fade,
  Divider,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
  Collapse,
  IconButton,
  Tooltip,
  Chip
} from '@mui/material'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { 
  CheckCircle, 
  Email, 
  Lock, 
  Person, 
  Send, 
  Settings,
  Info, 
  RefreshRounded,
  Dns,
  Numbers,
  VerifiedUser,
  Add,
  Close,
  MailOutline,
  Visibility,
  VisibilityOff
} from '@mui/icons-material'
import { toast } from '@intraversa-lab/styleguide'

import { llmEndpoint } from 'infra'

interface EmailConfig {
  id: string
  rpaId: string
  smtpServer: string
  smtpPort: number
  smtpUsername: string
  smtpPassword: string
  smtpSender: string
  smtpCc: string[]
  isConfigured: boolean
  useSSL: boolean
}

// Componente de status de conexão
const EmailStatus = ({ isConfigured }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        borderRadius: '12px',
        bgcolor: isConfigured ? 'rgba(46, 204, 113, 0.1)' : 'rgba(255, 76, 48, 0.1)',
        border: `1px solid ${isConfigured ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 76, 48, 0.3)'}`,
        mb: 2
      }}
    >
      <Avatar
        sx={{
          bgcolor: isConfigured ? '#2ecc71' : '#ff4c30',
          width: 40,
          height: 40,
          mr: 2
        }}
      >
        {isConfigured ? <CheckCircle /> : <Email />}
      </Avatar>
      <Box>
        <Typography variant="subtitle1" fontWeight={600} color={isConfigured ? '#2ecc71' : '#ff4c30'}>
          {isConfigured ? 'E-mail Configurado' : 'E-mail Não Configurado'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {isConfigured 
            ? 'As configurações de e-mail estão ativas e prontas para uso' 
            : 'Configure os parâmetros de SMTP para enviar e-mails'}
        </Typography>
      </Box>
    </Box>
  );
};

export const EmailConfig = () => {
  const { rpaId } = useParams()
  const [erro, setErro] = useState<string | null>(null)
  const [emailConfig, setEmailConfig] = useState<EmailConfig>({
    id: '',
    rpaId: rpaId || '',
    smtpServer: '',
    smtpPort: 587,
    smtpUsername: '',
    smtpPassword: '',
    smtpSender: '',
    smtpCc: [],
    isConfigured: false,
    useSSL: true
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [testLoading, setTestLoading] = useState(false)
  const [newCcEmail, setNewCcEmail] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const fetchEmailConfig = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await axios.get<EmailConfig>(
        `${llmEndpoint.url}/email-config/${rpaId}`,
        {
          headers: {
            accept: '*/*',
          },
        },
      )
      setEmailConfig(response.data)
    } catch (error) {
      console.error('Erro ao buscar configurações de e-mail:', error)
      // Se é um novo cliente, podemos apenas inicializar com os valores padrão
      // setErro('Não foi possível carregar as configurações de e-mail.')
    } finally {
      setIsLoading(false)
    }
  }, [rpaId])

  const handleRefresh = async () => {
    setRefreshing(true)
    await fetchEmailConfig()
    setTimeout(() => setRefreshing(false), 600)
  }

  useEffect(() => {
    fetchEmailConfig()
  }, [fetchEmailConfig])

  const handleInputChange = (field: keyof EmailConfig, value: any) => {
    setEmailConfig(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const handleAddCcEmail = () => {
    if (newCcEmail && !emailConfig.smtpCc.includes(newCcEmail)) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (emailRegex.test(newCcEmail)) {
        handleInputChange('smtpCc', [...emailConfig.smtpCc, newCcEmail])
        setNewCcEmail('')
      } else {
        toast({
          text: 'E-mail inválido',
          description: 'Por favor, informe um endereço de e-mail válido.',
          type: 'error',
        })
      }
    }
  }

  const handleRemoveCcEmail = (email: string) => {
    handleInputChange('smtpCc', emailConfig.smtpCc.filter(e => e !== email))
  }

  const saveEmailConfig = async () => {
    setIsSaving(true)
    setErro(null)

    try {
      // Validações básicas
      if (!emailConfig.smtpServer || !emailConfig.smtpUsername || !emailConfig.smtpPassword || !emailConfig.smtpSender) {
        throw new Error('Preencha todos os campos obrigatórios.')
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(emailConfig.smtpSender)) {
        throw new Error('O e-mail do remetente é inválido.')
      }

      // Enviar para a API
      if (emailConfig.id) {
        // Atualização
        await axios.put(
          `${llmEndpoint.url}/email-config/${emailConfig.id}`,
          emailConfig,
          {
            headers: {
              accept: '*/*',
            },
          },
        )
      } else {
        // Criação
        const response = await axios.post(
          `${llmEndpoint.url}/email-config`,
          emailConfig,
          {
            headers: {
              accept: '*/*',
            },
          },
        )
        setEmailConfig(response.data)
      }

      handleInputChange('isConfigured', true)
      
      toast({
        text: 'Configurações salvas',
        description: 'As configurações de e-mail foram salvas com sucesso.',
        type: 'success',
      })
    } catch (error) {
      console.error('Erro ao salvar configurações:', error)
      
      const errorMessage = error.message || 'Erro ao salvar as configurações de e-mail.'
      setErro(errorMessage)
      
      toast({
        text: 'Erro ao salvar',
        description: errorMessage,
        type: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }

  const testEmailConfig = async () => {
    setTestLoading(true)
    setErro(null)

    try {
      await axios.post(
        `${llmEndpoint.url}/email-config/test`,
        emailConfig,
        {
          headers: {
            accept: '*/*',
          },
        },
      )
      
      toast({
        text: 'Teste realizado',
        description: 'E-mail de teste enviado com sucesso!',
        type: 'success',
      })
    } catch (error) {
      console.error('Erro ao testar e-mail:', error)
      
      const errorMessage = error.response?.data?.message || 'Erro ao testar as configurações de e-mail.'
      setErro(errorMessage)
      
      toast({
        text: 'Falha no teste',
        description: errorMessage,
        type: 'error',
      })
    } finally {
      setTestLoading(false)
    }
  }

  if (isLoading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '70vh',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <CircularProgress size={60} thickness={4} />
        <Typography variant="h6" color="text.secondary">
          Carregando configurações de e-mail...
        </Typography>
      </Box>
    )
  }

  return (
    <Box 
      sx={{ 
        padding: { xs: 2, sm: 3, md: 4 },
        maxWidth: '1200px',
        margin: '0 auto',
        animation: 'fadeIn 0.5s ease-in-out',
        '@keyframes fadeIn': {
          '0%': { opacity: 0, transform: 'translateY(10px)' },
          '100%': { opacity: 1, transform: 'translateY(0)' },
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography 
          variant="h4" 
          fontWeight={600}
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            '&::before': {
              content: '""',
              display: 'block',
              width: '4px',
              height: '24px',
              backgroundColor: 'primary.main',
              marginRight: '12px',
              borderRadius: '4px'
            }
          }}
        >
          Configuração de E-mail
        </Typography>
        <Button 
          variant="outlined" 
          startIcon={<RefreshRounded />}
          onClick={handleRefresh}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          Atualizar
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EmailStatus isConfigured={emailConfig.isConfigured} />
        </Grid>

        <Grid item xs={12} md={7}>
          <Card 
            elevation={0}
            sx={{ 
              borderRadius: '16px', 
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CardContent sx={{ p: 3, flexGrow: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{
                    bgcolor: 'primary.light',
                    width: 40,
                    height: 40,
                    mr: 2
                  }}
                >
                  <Settings />
                </Avatar>
                <Typography variant="h6" fontWeight={600}>
                  Configurações de SMTP
                </Typography>
              </Box>
              
              <Divider sx={{ my: 2 }} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    label="Servidor SMTP"
                    placeholder="smtp.gmail.com"
                    value={emailConfig.smtpServer}
                    onChange={(e) => handleInputChange('smtpServer', e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Dns fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Porta"
                    placeholder="587"
                    type="number"
                    value={emailConfig.smtpPort}
                    onChange={(e) => handleInputChange('smtpPort', parseInt(e.target.value))}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Numbers fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Usuário"
                    placeholder="seu.email@gmail.com"
                    value={emailConfig.smtpUsername}
                    onChange={(e) => handleInputChange('smtpUsername', e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Senha"
                    type={showPassword ? "text" : "password"}
                    value={emailConfig.smtpPassword}
                    onChange={(e) => handleInputChange('smtpPassword', e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="E-mail do Remetente"
                    placeholder="nome@empresa.com.br"
                    value={emailConfig.smtpSender}
                    onChange={(e) => handleInputChange('smtpSender', e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutline fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    required
                    helperText="E-mail que aparecerá como remetente das mensagens"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={emailConfig.useSSL}
                        onChange={(e) => handleInputChange('useSSL', e.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <VerifiedUser fontSize="small" sx={{ mr: 1, color: emailConfig.useSSL ? 'primary.main' : 'text.secondary' }} />
                        <Typography>Usar SSL/TLS</Typography>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    E-mails em cópia (CC)
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="email@empresa.com"
                      value={newCcEmail}
                      onChange={(e) => setNewCcEmail(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleAddCcEmail();
                          e.preventDefault();
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ ml: 1, height: 40, minWidth: '40px', borderRadius: '8px' }}
                      onClick={handleAddCcEmail}
                    >
                      <Add />
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {emailConfig.smtpCc.map((email) => (
                      <Chip
                        key={email}
                        label={email}
                        onDelete={() => handleRemoveCcEmail(email)}
                        deleteIcon={<Close fontSize="small" />}
                        sx={{ borderRadius: '6px' }}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
              
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={testEmailConfig}
                  disabled={testLoading || isSaving || !emailConfig.smtpServer || !emailConfig.smtpUsername || !emailConfig.smtpPassword}
                  startIcon={testLoading ? <CircularProgress size={20} /> : <Send />}
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 500
                  }}
                >
                  Enviar E-mail de Teste
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveEmailConfig}
                  disabled={isSaving}
                  startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : <CheckCircle />}
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 500
                  }}
                >
                  Salvar Configurações
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={5}>
          <Card 
            elevation={0}
            sx={{ 
              borderRadius: '16px', 
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
              height: '100%'
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{
                    bgcolor: 'primary.light',
                    width: 40,
                    height: 40,
                    mr: 2
                  }}
                >
                  <Info />
                </Avatar>
                <Typography variant="h6" fontWeight={600}>
                  Informações de E-mail
                </Typography>
              </Box>
              
              <Divider sx={{ my: 2 }} />
              
              <Box sx={{ mt: 3 }}>
                <Paper
                  elevation={0}
                  sx={{ 
                    p: 2, 
                    mb: 2, 
                    bgcolor: 'rgba(25, 118, 210, 0.05)', 
                    borderRadius: '12px',
                    border: '1px solid rgba(25, 118, 210, 0.1)'
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={600} gutterBottom>
                    Sobre a configuração SMTP
                  </Typography>
                  <Typography variant="body2" paragraph>
                    O SMTP (Simple Mail Transfer Protocol) é o protocolo padrão para envio de e-mails na internet.
                    Configure corretamente os parâmetros do seu provedor de e-mail para garantir o envio das mensagens.
                  </Typography>
                  <Typography variant="body2">
                    Alguns provedores populares:
                  </Typography>
                  <Box component="ul" sx={{ pl: 2, mt: 1 }}>
                    <Box component="li">
                      <Typography variant="body2">
                        <strong>Gmail:</strong> smtp.gmail.com, Porta: 587
                      </Typography>
                    </Box>
                    <Box component="li">
                      <Typography variant="body2">
                        <strong>Outlook/Office365:</strong> smtp.office365.com, Porta: 587
                      </Typography>
                    </Box>
                    <Box component="li">
                      <Typography variant="body2">
                        <strong>Yahoo:</strong> smtp.mail.yahoo.com, Porta: 587
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
                
                <Paper
                  elevation={0}
                  sx={{ 
                    p: 2, 
                    bgcolor: 'rgba(46, 204, 113, 0.05)', 
                    borderRadius: '12px',
                    border: '1px solid rgba(46, 204, 113, 0.1)'
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={600} gutterBottom>
                    Dicas para o envio de e-mails
                  </Typography>
                  <Box component="ul" sx={{ pl: 2, mt: 1 }}>
                    <Box component="li">
                      <Typography variant="body2" paragraph>
                        Para serviços como Gmail, você precisa habilitar "Acesso a app menos seguro" ou usar uma senha de app.
                      </Typography>
                    </Box>
                    <Box component="li">
                      <Typography variant="body2" paragraph>
                        É recomendado usar SSL/TLS para uma conexão segura com o servidor de e-mail.
                      </Typography>
                    </Box>
                    <Box component="li">
                      <Typography variant="body2">
                        Utilize um endereço de e-mail corporativo para evitar que as mensagens caiam em spam.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </CardContent>
          </Card>
        </Grid>
  
        {erro && (
          <Grid item xs={12}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 3, 
                bgcolor: 'error.light', 
                borderRadius: '12px',
                border: '1px solid',
                borderColor: 'error.main',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'error.main',
                  width: 40,
                  height: 40,
                  mr: 2
                }}
              >
                <Info />
              </Avatar>
              <Typography color="error.dark" fontWeight={500}>{erro}</Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
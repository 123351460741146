import React from 'react'
import { Department } from '../../../../types'
import { Card, CardContent, Typography, Box, Switch, IconButton, Chip, Tooltip } from '@mui/material'
import { Delete, Edit, Group, Dashboard } from '@mui/icons-material'
import styled from 'styled-components'

interface DepartmentCardProps {
  department: Department
  onClick: () => void
  onDelete: () => void
  onToggleActive: (active: boolean) => void
}

const CardContainer = styled(Card)`
  position: relative;
  transition: all 0.3s ease;
  height: 100%;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const CardActions = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  gap: 8px;
`

const StyledCardContent = styled(CardContent)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const DepartmentName = styled(Typography)`
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
`

const DepartmentDescription = styled(Typography)`
  color: #637381;
  margin-bottom: 16px;
  flex-grow: 1;
`

const InfoSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`

const InfoItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ChipContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
  max-height: 60px;
  overflow-y: auto;
`

export const DepartmentCard: React.FC<DepartmentCardProps> = ({
  department,
  onClick,
  onDelete,
  onToggleActive,
}) => {
  console.log('DepartmentCard:', department)  
  const handleToggleActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    onToggleActive(event.target.checked)
  }

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation()
    onDelete()
  }

  return (
    <CardContainer onClick={onClick}>
      <CardActions>
        <Tooltip title="Excluir">
          <IconButton size="small" onClick={handleDelete} color="error">
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
      </CardActions>

      <StyledCardContent>
        <DepartmentName variant="h6">{department.name}</DepartmentName>
        <DepartmentDescription variant="body2">
          {department.description || 'Sem descrição'}
        </DepartmentDescription>

        <InfoSection>
          <InfoItem>
            <Group fontSize="small" color="primary" />
            <Typography variant="body2" fontWeight={500}>
              Usuários ({department.users.length})
            </Typography>
          </InfoItem>
          <ChipContainer>
            {department.users.slice(0, 3).map((user) => (
              <Chip
                key={user.id}
                label={user.name}
                size="small"
                variant="outlined"
                color={user.isAdmin ? 'primary' : 'default'}
                icon={user.isAdmin ? <Edit fontSize="small" /> : undefined}
              />
            ))}
            {department.users.length > 3 && (
              <Chip
                label={`+${department.users.length - 3}`}
                size="small"
                variant="outlined"
              />
            )}
          </ChipContainer>

          <InfoItem>
            <Dashboard fontSize="small" color="primary" />
            <Typography variant="body2" fontWeight={500}>
              Boards ({department.boards.length})
            </Typography>
          </InfoItem>
          <ChipContainer>
            {department.boards.slice(0, 3).map((board) => (
              <Chip
                key={board.id}
                label={board.title}
                size="small"
                variant="outlined"
              />
            ))}
            {department.boards.length > 3 && (
              <Chip
                label={`+${department.boards.length - 3}`}
                size="small"
                variant="outlined"
              />
            )}
          </ChipContainer>
        </InfoSection>
      </StyledCardContent>
    </CardContainer>
  )
}
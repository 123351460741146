// src/pages/ERPManagement/index.tsx
import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { Add, ArrowBack } from '@mui/icons-material'
import { Button } from '@intraversa-lab/styleguide'
import { llmEndpoint } from 'infra'

import { InputSearch, SearchBarMobile } from 'components'
import { FilterListProfile } from './components/Filter/FilterListProfile'
import { ERPCard } from './components/ERPCard'
import { ERPForm } from './components/ERPForm/ERPForm'
import { PaginationComponent } from '../../../../components/PaginationComponent/PaginationComponent'
import { ERP } from './types'
import { ErpAPI } from './services/api'
import * as S from './styles'
import { useParams } from 'react-router-dom'

export const ERPManagement = () => {
  const [search, setSearch] = useState('')
  const [showInputSearch, setShowInputSearch] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [edit, setEdit] = useState(false)
  const [erps, setERPs] = useState<ERP[]>([])
  const [selectedERP, setSelectedERP] = useState<ERP | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // Estado para paginação
  const [currentPage, setCurrentPage] = useState(1)
  const erpsPerPage = 9 // Definindo quantos ERPs por página
  const { rpaId } = useParams()
  // ID da RPA atual (normalmente viria de um contexto ou parâmetro de rota)
  //   const rpaId = '663457c4-abce-4724-9fce-de4021e1717b' // Este seria um valor dinâmico em produção

  useEffect(() => {
    fetchERPs()
  }, [])

  const fetchERPs = async () => {
    setLoading(true)
    setError(null)
    try {
      const erpsData = await ErpAPI.getAllErps(rpaId)
      setERPs(erpsData)
    } catch (error) {
      console.error('Error fetching ERPs:', error)
      setError(
        'Não foi possível carregar as integrações ERP. Tente novamente mais tarde.',
      )
      setERPs([])
    } finally {
      setLoading(false)
    }
  }

  const handleCardClick = (erp: ERP) => {
    setSelectedERP(erp)
    setEdit(true)
    setShowForm(true)
  }

  function addNewERP() {
    setShowForm(true)
    setEdit(false)
    setSelectedERP(null)
  }

  function handleBackToSearch() {
    setShowForm(false)
    setSelectedERP(null)
  }

  const handleERPSave = async (erpData: ERP) => {
    setLoading(true)
    setError(null)
    try {
      if (edit) {
        await ErpAPI.updateErp({
          ...erpData,
          rpaId,
        })
      } else {
        await ErpAPI.createErp({
          ...erpData,
          rpaId,
        })
      }
      // Atualizar a lista após sucesso
      await fetchERPs()
      setShowForm(false)
    } catch (error) {
      console.error('Error saving ERP:', error)
      setError('Não foi possível salvar a integração ERP. Tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteERP = async (erpId: string) => {
    if (!confirm('Tem certeza que deseja excluir esta integração ERP?')) {
      return
    }

    setLoading(true)
    setError(null)
    try {
      await ErpAPI.deleteErp(erpId)

      // Atualizar a lista após sucesso
      await fetchERPs()
      if (selectedERP?.id === erpId) {
        handleBackToSearch()
      }
    } catch (error) {
      console.error('Error deleting ERP:', error)
      setError('Não foi possível excluir a integração ERP. Tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handleToggleActive = async (erpId: string, active: boolean) => {
    setLoading(true)
    setError(null)
    try {
      await ErpAPI.toggleErpActive(erpId, active)

      // Atualizar a lista após sucesso
      await fetchERPs()
    } catch (error) {
      console.error('Error toggling ERP active status:', error)
      setError(
        'Não foi possível alterar o status da integração ERP. Tente novamente.',
      )
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateCredentials = async (
    erpId: string,
    appKey: string,
    appSecret: string,
  ) => {
    setLoading(true)
    setError(null)
    try {
      await ErpAPI.updateCredentials(erpId, appKey, appSecret)

      // Atualizar a lista após sucesso
      await fetchERPs()
    } catch (error) {
      console.error('Error updating credentials:', error)
      setError('Não foi possível atualizar as credenciais. Tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  // Filtra os ERPs com base na pesquisa
  const filteredERPs = erps.filter(
    (erp) =>
      erp.name.toLowerCase().includes(search.toLowerCase()) ||
      erp.description?.toLowerCase().includes(search.toLowerCase()) ||
      erp.vendor.toLowerCase().includes(search.toLowerCase()),
  )

  // Obtém os ERPs da página atual
  const getCurrentPageERPs = () => {
    const startIndex = (currentPage - 1) * erpsPerPage
    const endIndex = startIndex + erpsPerPage
    return filteredERPs.slice(startIndex, endIndex)
  }

  // Manipulador de mudança de página
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  // Manipulador para ordenação
  const handleOrderBy = (orderType: string) => {
    let orderedERPs = [...erps]

    switch (orderType) {
      case 'recent':
        orderedERPs.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        )
        break
      case 'oldest':
        orderedERPs.sort(
          (a, b) =>
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        )
        break
      case 'a-z':
        orderedERPs.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'z-a':
        orderedERPs.sort((a, b) => b.name.localeCompare(a.name))
        break
      case 'active':
        orderedERPs.sort((a, b) => Number(b.active) - Number(a.active))
        break
      case 'inactive':
        orderedERPs.sort((a, b) => Number(a.active) - Number(b.active))
        break
      default:
        break
    }

    setERPs(orderedERPs)
  }

  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">
            Gerenciamento de ERPs
          </h1>
          <p style={{ marginTop: '16px' }}>
            Cadastre e gerencie suas integrações com sistemas ERP.
          </p>
        </S.WrapperHeader>
      </S.Container>

      <S.Content>
        <Box
          sx={{
            overflow: 'auto',
            maxHeight: 'calc(100vh - 200px)',
            position: 'relative',
            pb: 4,
            width: '100%',
          }}
        >
          <S.WrapperSerach>
            {!showForm ? (
              <>
                <div className="filters">
                  {showInputSearch ? (
                    <div className="inputSearchMobile">
                      <SearchBarMobile
                        placeholder={'Pesquisar ERP'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onClose={() => setShowInputSearch(!showInputSearch)}
                      />
                    </div>
                  ) : (
                    <>
                      <InputSearch
                        placeholder="Pesquisar ERP"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onShowMobile={() =>
                          setShowInputSearch(!showInputSearch)
                        }
                      />
                      <FilterListProfile orderBy={handleOrderBy} />
                    </>
                  )}
                </div>

                <Button
                  startIcon={<Add />}
                  onClick={() => addNewERP()}
                  className="newProfile"
                  sx={{
                    '&:hover': {
                      backgroundColor: '#3C454D !important',
                      color: '#F9F9F9',
                    },
                  }}
                >
                  Novo ERP
                </Button>
              </>
            ) : (
              <Button
                startIcon={<ArrowBack />}
                onClick={handleBackToSearch}
                className="backToSearch"
                sx={{
                  '&:hover': {
                    backgroundColor: '#3C454D !important',
                    color: '#F9F9F9',
                  },
                }}
              >
                Voltar para pesquisa
              </Button>
            )}
          </S.WrapperSerach>

          {/* Container principal com padding adequado */}
          <Box sx={{ padding: '0 30px', height: '100%', overflow: 'auto' }}>
            {/* Mensagem de erro, se houver */}
            {error && (
              <Box
                sx={{
                  p: 2,
                  mb: 2,
                  backgroundColor: '#ffebee',
                  color: '#d32f2f',
                  borderRadius: 1,
                }}
              >
                {error}
              </Box>
            )}

            {/* Carregando indicador */}
            {loading && (
              <Box
                sx={{
                  p: 2,
                  mb: 2,
                  textAlign: 'center',
                  color: '#3f51b5',
                }}
              >
                Carregando...
              </Box>
            )}

            {/* Listagem de ERPs */}
            {!showForm ? (
              <Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns:
                      'repeat(auto-fill, minmax(300px, 1fr))',
                    gap: '20px',
                    mb: 4,
                  }}
                >
                  {getCurrentPageERPs().map((erp) => (
                    <ERPCard
                      key={erp.id}
                      erp={erp}
                      onClick={() => handleCardClick(erp)}
                      onDelete={() => handleDeleteERP(erp.id)}
                      onToggleActive={(active) =>
                        handleToggleActive(erp.id, active)
                      }
                    />
                  ))}
                </Box>

                {!loading && filteredERPs.length === 0 && (
                  <Box sx={{ textAlign: 'center', my: 4, color: '#637381' }}>
                    Nenhuma integração ERP encontrada. Clique em "Novo ERP" para
                    adicionar.
                  </Box>
                )}

                {/* Componente de paginação para ERPs */}
                {filteredERPs.length > 0 && (
                  <PaginationComponent
                    currentPage={currentPage}
                    totalItems={filteredERPs.length}
                    itemsPerPage={erpsPerPage}
                    onPageChange={handlePageChange}
                    itemName="ERPs"
                  />
                )}
              </Box>
            ) : (
              <ERPForm
                erp={selectedERP}
                isEdit={edit}
                onSave={handleERPSave}
                onDelete={handleDeleteERP}
                onCancel={handleBackToSearch}
                onUpdateCredentials={handleUpdateCredentials}
                loading={loading}
                error={error}
              />
            )}
          </Box>
        </Box>
      </S.Content>
    </>
  )
}

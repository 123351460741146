import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  Container,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  Card,
  CardContent,
  Chip,
  Avatar,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  Drawer,
  List,
} from '@mui/material'
import {
  LocalizationProvider,
  DateCalendar,
  TimePicker,
} from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import 'moment/locale/pt-br'

// Importando ícones
import {
  Today as TodayIcon,
  Add as AddIcon,
  Phone as PhoneIcon,
  EventNote as EventIcon,
  Email as EmailIcon,
  WhatsApp as WhatsAppIcon,
  More as MoreIcon,
  Event as CalendarIcon,
  EventAvailable as ScheduleIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Done as DoneIcon,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  AccessTime as TimeIcon,
  Refresh as RefreshIcon,
  ViewDay as ViewDayIcon,
  ViewWeek as ViewWeekIcon,
  ViewModule as ViewMonthIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'

// Tipo para atividades
interface Activity {
  id: string
  type: 'call' | 'meeting' | 'email' | 'whatsapp' | 'other'
  title: string
  description: string
  date: string // ISO date string
  scheduled: string // ISO date string
  completed: boolean
  outcome?: string
  contactName: string
  contactCompany?: string
  userId: string
  userName: string
  createdAt: string
}

// Tipo para controle de visualização
type CalendarView = 'day' | 'week' | 'month'

// Componente principal
const ActivityCalendar: React.FC = () => {
  // Estados para a aplicação
  const [loading, setLoading] = useState<boolean>(true)
  const [activities, setActivities] = useState<Activity[]>([])
  const [filteredActivities, setFilteredActivities] = useState<Activity[]>([])
  const [selectedDate, setSelectedDate] = useState<Moment>(moment())
  const [calendarView, setCalendarView] = useState<CalendarView>('week')
  const [openActivityDrawer, setOpenActivityDrawer] = useState<boolean>(false)
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(
    null,
  )
  const [openNewActivityDialog, setOpenNewActivityDialog] =
    useState<boolean>(false)
  const [filterType, setFilterType] = useState<string>('all')
  const [filterCompleted, setFilterCompleted] = useState<string>('all')
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'info',
  })

  // Estados para nova atividade
  const [newActivity, setNewActivity] = useState({
    type: 'call' as Activity['type'],
    title: '',
    description: '',
    date: moment().format('YYYY-MM-DD'),
    scheduled: moment().format('YYYY-MM-DD HH:mm'),
    completed: false,
    contactName: '',
    contactCompany: '',
  })

  // Função para carregar atividades (simulação)
  useEffect(() => {
    fetchActivities()
  }, [])

  // Efeito para filtrar atividades quando a data selecionada muda
  useEffect(() => {
    filterActivitiesByDate()
  }, [selectedDate, activities, filterType, filterCompleted, calendarView])

  // Função para buscar atividades do servidor (simulada)
  const fetchActivities = async () => {
    setLoading(true)
    try {
      // Simular uma chamada de API
      setTimeout(() => {
        const mockActivities: Activity[] = [
          {
            id: '1',
            type: 'call',
            title: 'Ligação com cliente ABC',
            description: 'Discutir proposta de serviço',
            date: moment().format('YYYY-MM-DD'),
            scheduled: moment().hour(10).minute(30).format('YYYY-MM-DD HH:mm'),
            completed: false,
            contactName: 'João Silva',
            contactCompany: 'Empresa ABC',
            userId: 'user1',
            userName: 'Usuário Atual',
            createdAt: moment().subtract(2, 'days').format(),
          },
          {
            id: '2',
            type: 'meeting',
            title: 'Reunião de apresentação',
            description: 'Apresentar os novos produtos',
            date: moment().format('YYYY-MM-DD'),
            scheduled: moment().hour(14).minute(0).format('YYYY-MM-DD HH:mm'),
            completed: false,
            contactName: 'Maria Santos',
            contactCompany: 'Tech Solutions',
            userId: 'user1',
            userName: 'Usuário Atual',
            createdAt: moment().subtract(1, 'days').format(),
          },
          {
            id: '3',
            type: 'email',
            title: 'Enviar proposta comercial',
            description: 'Envio da proposta atualizada para cliente',
            date: moment().add(1, 'days').format('YYYY-MM-DD'),
            scheduled: moment()
              .add(1, 'days')
              .hour(9)
              .minute(0)
              .format('YYYY-MM-DD HH:mm'),
            completed: false,
            contactName: 'Carlos Oliveira',
            contactCompany: 'Consultoria XYZ',
            userId: 'user1',
            userName: 'Usuário Atual',
            createdAt: moment().subtract(5, 'hours').format(),
          },
          {
            id: '4',
            type: 'whatsapp',
            title: 'Follow-up com cliente',
            description: 'Verificar feedback sobre a proposta',
            date: moment().add(2, 'days').format('YYYY-MM-DD'),
            scheduled: moment()
              .add(2, 'days')
              .hour(11)
              .minute(15)
              .format('YYYY-MM-DD HH:mm'),
            completed: false,
            contactName: 'Ana Souza',
            contactCompany: 'Distribuidora ABC',
            userId: 'user1',
            userName: 'Usuário Atual',
            createdAt: moment().subtract(1, 'days').format(),
          },
          {
            id: '5',
            type: 'call',
            title: 'Ligação de suporte',
            description: 'Resolver problema técnico reportado',
            date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            scheduled: moment()
              .subtract(1, 'days')
              .hour(16)
              .minute(0)
              .format('YYYY-MM-DD HH:mm'),
            completed: true,
            outcome: 'Problema resolvido com sucesso',
            contactName: 'Paulo Mendes',
            contactCompany: 'Empresa XYZ',
            userId: 'user1',
            userName: 'Usuário Atual',
            createdAt: moment().subtract(2, 'days').format(),
          },
          {
            id: '6',
            type: 'meeting',
            title: 'Reunião de alinhamento',
            description: 'Discussão sobre o novo projeto',
            date: moment().add(3, 'days').format('YYYY-MM-DD'),
            scheduled: moment()
              .add(3, 'days')
              .hour(10)
              .minute(0)
              .format('YYYY-MM-DD HH:mm'),
            completed: false,
            contactName: 'Equipe Interna',
            userId: 'user1',
            userName: 'Usuário Atual',
            createdAt: moment().subtract(1, 'days').format(),
          },
        ]

        setActivities(mockActivities)
        setLoading(false)
      }, 1000)
    } catch (error) {
      console.error('Erro ao buscar atividades:', error)
      setSnackbar({
        open: true,
        message: 'Erro ao carregar atividades',
        severity: 'error',
      })
      setLoading(false)
    }
  }

  // Filtrar atividades com base na data e filtros selecionados
  const filterActivitiesByDate = () => {
    let filtered = [...activities]

    // Filtrar por tipo
    if (filterType !== 'all') {
      filtered = filtered.filter((activity) => activity.type === filterType)
    }

    // Filtrar por status (completado/pendente)
    if (filterCompleted !== 'all') {
      filtered = filtered.filter((activity) =>
        filterCompleted === 'completed'
          ? activity.completed
          : !activity.completed,
      )
    }

    // Filtrar por data com base na visualização
    if (calendarView === 'day') {
      filtered = filtered.filter(
        (activity) =>
          moment(activity.scheduled).format('YYYY-MM-DD') ===
          selectedDate.format('YYYY-MM-DD'),
      )
    } else if (calendarView === 'week') {
      const startOfWeek = selectedDate.clone().startOf('week')
      const endOfWeek = selectedDate.clone().endOf('week')

      filtered = filtered.filter((activity) => {
        const activityDate = moment(activity.scheduled)
        return (
          activityDate.isSameOrAfter(startOfWeek, 'day') &&
          activityDate.isSameOrBefore(endOfWeek, 'day')
        )
      })
    } else if (calendarView === 'month') {
      const startOfMonth = selectedDate.clone().startOf('month')
      const endOfMonth = selectedDate.clone().endOf('month')

      filtered = filtered.filter((activity) => {
        const activityDate = moment(activity.scheduled)
        return (
          activityDate.isSameOrAfter(startOfMonth, 'day') &&
          activityDate.isSameOrBefore(endOfMonth, 'day')
        )
      })
    }

    // Ordenar por data/hora
    filtered.sort(
      (a, b) => moment(a.scheduled).valueOf() - moment(b.scheduled).valueOf(),
    )

    setFilteredActivities(filtered)
  }

  // Manipular alteração do tipo de visualização
  const handleViewChange = (view: CalendarView) => {
    setCalendarView(view)
  }

  // Navegar entre períodos (dia, semana, mês)
  const navigatePeriod = (direction: 'prev' | 'next') => {
    if (calendarView === 'day') {
      setSelectedDate((prev) =>
        direction === 'prev'
          ? prev.clone().subtract(1, 'day')
          : prev.clone().add(1, 'day'),
      )
    } else if (calendarView === 'week') {
      setSelectedDate((prev) =>
        direction === 'prev'
          ? prev.clone().subtract(1, 'week')
          : prev.clone().add(1, 'week'),
      )
    } else if (calendarView === 'month') {
      setSelectedDate((prev) =>
        direction === 'prev'
          ? prev.clone().subtract(1, 'month')
          : prev.clone().add(1, 'month'),
      )
    }
  }

  // Obter título do período atual
  const getPeriodTitle = () => {
    if (calendarView === 'day') {
      return selectedDate.format('DD [de] MMMM [de] YYYY')
    } else if (calendarView === 'week') {
      const startOfWeek = selectedDate.clone().startOf('week')
      const endOfWeek = selectedDate.clone().endOf('week')
      return `${startOfWeek.format('DD [de] MMMM')} - ${endOfWeek.format('DD [de] MMMM [de] YYYY')}`
    } else if (calendarView === 'month') {
      return selectedDate.format('MMMM [de] YYYY')
    }
  }

  // Manipular o clique em uma atividade
  const handleActivityClick = (activity: Activity) => {
    setSelectedActivity(activity)
    setOpenActivityDrawer(true)
  }

  // Manipular o fechamento da gaveta de detalhes
  const handleCloseDrawer = () => {
    setOpenActivityDrawer(false)
    setSelectedActivity(null)
  }

  // Manipular a marcação de uma atividade como concluída
  const handleCompleteActivity = (activity: Activity, completed: boolean) => {
    const updatedActivities = activities.map((act) =>
      act.id === activity.id ? { ...act, completed } : act,
    )

    setActivities(updatedActivities)

    setSnackbar({
      open: true,
      message: completed
        ? 'Atividade marcada como concluída'
        : 'Atividade marcada como pendente',
      severity: 'success',
    })

    // Se a atividade estiver aberta no drawer, atualizar lá também
    if (selectedActivity?.id === activity.id) {
      setSelectedActivity({ ...activity, completed })
    }
  }

  // Manipular alterações no formulário de nova atividade
  const handleNewActivityChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
  ) => {
    const { name, value } = e.target
    if (name) {
      setNewActivity((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  // Manipular data/hora no formulário de nova atividade
  const handleDateTimeChange = (name: string, value: Moment | null) => {
    if (value) {
      if (name === 'date') {
        // Quando a data muda, atualizar tanto a data quanto o agendamento
        const currentTime = moment(newActivity.scheduled).format('HH:mm')
        setNewActivity((prev) => ({
          ...prev,
          date: value.format('YYYY-MM-DD'),
          scheduled: `${value.format('YYYY-MM-DD')} ${currentTime}`,
        }))
      } else if (name === 'scheduledTime') {
        // Quando o horário muda, atualizar apenas o agendamento
        const currentDate = moment(newActivity.date).format('YYYY-MM-DD')
        setNewActivity((prev) => ({
          ...prev,
          scheduled: `${currentDate} ${value.format('HH:mm')}`,
        }))
      }
    }
  }

  // Salvar nova atividade
  const handleSaveActivity = () => {
    // Validação básica
    if (!newActivity.title || !newActivity.date || !newActivity.scheduled) {
      setSnackbar({
        open: true,
        message: 'Preencha todos os campos obrigatórios',
        severity: 'error',
      })
      return
    }

    const newActivityObject: Activity = {
      id: `activity-${Date.now()}`,
      ...newActivity,
      userId: 'user1',
      userName: 'Usuário Atual',
      createdAt: moment().format(),
    }

    setActivities((prev) => [...prev, newActivityObject])
    setOpenNewActivityDialog(false)

    // Limpar formulário
    setNewActivity({
      type: 'call',
      title: '',
      description: '',
      date: moment().format('YYYY-MM-DD'),
      scheduled: moment().format('YYYY-MM-DD HH:mm'),
      completed: false,
      contactName: '',
      contactCompany: '',
    })

    setSnackbar({
      open: true,
      message: 'Atividade criada com sucesso',
      severity: 'success',
    })
  }

  // Obter ícone com base no tipo de atividade
  const getActivityTypeIcon = (type: Activity['type']) => {
    switch (type) {
      case 'call':
        return <PhoneIcon />
      case 'meeting':
        return <EventIcon />
      case 'email':
        return <EmailIcon />
      case 'whatsapp':
        return <WhatsAppIcon style={{ color: '#25D366' }} />
      default:
        return <MoreIcon />
    }
  }

  // Obter cor com base no tipo de atividade
  const getActivityColor = (type: Activity['type']) => {
    switch (type) {
      case 'call':
        return '#3f51b5'
      case 'meeting':
        return '#9c27b0'
      case 'email':
        return '#4caf50'
      case 'whatsapp':
        return '#25D366'
      default:
        return '#ff9800'
    }
  }

  // Agrupar atividades por data
  const groupActivitiesByDate = () => {
    const grouped: { [key: string]: Activity[] } = {}

    filteredActivities.forEach((activity) => {
      const dateKey = moment(activity.scheduled).format('YYYY-MM-DD')
      if (!grouped[dateKey]) {
        grouped[dateKey] = []
      }
      grouped[dateKey].push(activity)
    })

    return grouped
  }

  // Renderizar dias da semana (para visualização semanal)
  const renderWeekDays = () => {
    const startOfWeek = selectedDate.clone().startOf('week')
    const days = []

    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.clone().add(i, 'days')
      days.push(day)
    }

    return days
  }

  // Renderizar os dias do mês atual (para visualização mensal)
  const renderMonthDays = () => {
    const startOfMonth = selectedDate.clone().startOf('month')
    const endOfMonth = selectedDate.clone().endOf('month')
    const startDate = startOfMonth.clone().startOf('week')
    const endDate = endOfMonth.clone().endOf('week')

    const days = []
    let day = startDate.clone()

    while (day.isSameOrBefore(endDate, 'day')) {
      days.push(day.clone())
      day.add(1, 'day')
    }

    return days
  }

  // Fechar snackbar
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }))
  }

  const handleActivityTypeChange = (
    e: SelectChangeEvent<'call' | 'meeting' | 'email' | 'whatsapp' | 'other'>,
  ) => {
    const value = e.target.value as
      | 'call'
      | 'meeting'
      | 'email'
      | 'whatsapp'
      | 'other'
    setNewActivity((prev) => ({
      ...prev,
      type: value,
    }))
  }

  const handleFilterTypeChange = (e: SelectChangeEvent<string>) => {
    setFilterType(e.target.value)
  }

  const handleFilterCompletedChange = (e: SelectChangeEvent<string>) => {
    setFilterCompleted(e.target.value)
  }

  // Exibir uma tela de carregamento enquanto os dados são buscados
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        height: 'auto',
        overflow: 'visible',
        width: '100%',
        position: 'relative',
      }}
    >
      <Container maxWidth="xl" sx={{ pb: 10 }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            component="h1"
            fontWeight="bold"
            sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
          >
            <TodayIcon sx={{ mr: 1, color: '#3f51b5' }} />
            Calendário de Atividades
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Gerencie suas atividades e compromissos com clientes e contatos.
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* Sidebar de Calendário */}
          <Grid item xs={12} md={3}>
            <Paper
              sx={{
                p: 3,
                borderRadius: '10px',
                position: 'sticky',
                top: '80px',
              }}
            >
              <Typography
                variant="h6"
                sx={{ mb: 3, display: 'flex', alignItems: 'center' }}
              >
                <CalendarIcon sx={{ mr: 1, color: '#3f51b5' }} />
                Calendário
              </Typography>

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="pt-br"
              >
                <DateCalendar
                  value={selectedDate}
                  onChange={(newDate) => newDate && setSelectedDate(newDate)}
                  showDaysOutsideCurrentMonth
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>

              <Divider sx={{ my: 2 }} />

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Filtrar por Tipo
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={filterType}
                    onChange={handleFilterTypeChange} // Use o manipulador específico aqui
                  >
                    <MenuItem value="all">Todos os Tipos</MenuItem>
                    <MenuItem value="call">Ligações</MenuItem>
                    <MenuItem value="meeting">Reuniões</MenuItem>
                    <MenuItem value="email">E-mails</MenuItem>
                    <MenuItem value="whatsapp">WhatsApp</MenuItem>
                    <MenuItem value="other">Outros</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Filtrar por Status
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={filterCompleted}
                    onChange={handleFilterCompletedChange} // Use o manipulador específico aqui
                  >
                    <MenuItem value="all">Todos os Status</MenuItem>
                    <MenuItem value="pending">Pendentes</MenuItem>
                    <MenuItem value="completed">Concluídos</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<AddIcon />}
                onClick={() => setOpenNewActivityDialog(true)}
                sx={{ mt: 2 }}
              >
                Nova Atividade
              </Button>
            </Paper>
          </Grid>

          {/* Área Principal de Calendário */}
          <Grid item xs={12} md={9}>
            <Paper sx={{ p: 3, borderRadius: '10px' }}>
              {/* Cabeçalho do Calendário */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => navigatePeriod('prev')}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ mx: 2 }}>
                    {getPeriodTitle()}
                  </Typography>
                  <IconButton onClick={() => navigatePeriod('next')}>
                    <ArrowForwardIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setSelectedDate(moment())}
                    sx={{ ml: 1 }}
                    color="primary"
                  >
                    <TodayIcon />
                  </IconButton>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Visualização diária">
                    <IconButton
                      color={calendarView === 'day' ? 'primary' : 'default'}
                      onClick={() => handleViewChange('day')}
                    >
                      <ViewDayIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Visualização semanal">
                    <IconButton
                      color={calendarView === 'week' ? 'primary' : 'default'}
                      onClick={() => handleViewChange('week')}
                    >
                      <ViewWeekIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Visualização mensal">
                    <IconButton
                      color={calendarView === 'month' ? 'primary' : 'default'}
                      onClick={() => handleViewChange('month')}
                    >
                      <ViewMonthIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Atualizar">
                    <IconButton onClick={fetchActivities}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {/* Renderização do calendário com base no tipo de visualização */}
              {calendarView === 'day' && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 2, fontWeight: 'bold' }}
                  >
                    Atividades para {selectedDate.format('DD/MM/YYYY')}
                  </Typography>

                  {filteredActivities.length > 0 ? (
                    <List>
                      {filteredActivities.map((activity) => (
                        <Card
                          key={activity.id}
                          sx={{
                            mb: 2,
                            borderLeft: `4px solid ${getActivityColor(activity.type)}`,
                            backgroundColor: activity.completed
                              ? '#f9f9f9'
                              : 'white',
                            '&:hover': { boxShadow: 3 },
                          }}
                          onClick={() => handleActivityClick(activity)}
                        >
                          <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                              }}
                            >
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Avatar
                                  sx={{
                                    bgcolor: getActivityColor(activity.type),
                                    mr: 2,
                                  }}
                                >
                                  {getActivityTypeIcon(activity.type)}
                                </Avatar>
                                <Box>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontWeight: 'bold',
                                      textDecoration: activity.completed
                                        ? 'line-through'
                                        : 'none',
                                      color: activity.completed
                                        ? 'text.secondary'
                                        : 'text.primary',
                                    }}
                                  >
                                    {activity.title}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {activity.contactName}
                                    {activity.contactCompany &&
                                      ` - ${activity.contactCompany}`}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Chip
                                  icon={<TimeIcon />}
                                  label={moment(activity.scheduled).format(
                                    'HH:mm',
                                  )}
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                />
                                {activity.completed && (
                                  <Chip
                                    icon={<CheckCircleIcon />}
                                    label="Concluído"
                                    size="small"
                                    color="success"
                                    sx={{ ml: 1 }}
                                  />
                                )}
                              </Box>
                            </Box>

                            {activity.description && (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  mt: 1,
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {activity.description}
                              </Typography>
                            )}

                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 1,
                              }}
                            >
                              <Tooltip
                                title={
                                  activity.completed
                                    ? 'Marcar como pendente'
                                    : 'Marcar como concluído'
                                }
                              >
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleCompleteActivity(
                                      activity,
                                      !activity.completed,
                                    )
                                  }}
                                >
                                  {activity.completed ? (
                                    <DoneIcon color="success" />
                                  ) : (
                                    <DoneIcon color="action" />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                    </List>
                  ) : (
                    <Box sx={{ textAlign: 'center', py: 5 }}>
                      <TodayIcon
                        sx={{ fontSize: 60, color: '#eeeeee', mb: 2 }}
                      />
                      <Typography variant="h6" color="text.secondary">
                        Nenhuma atividade encontrada para esta data
                      </Typography>
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenNewActivityDialog(true)}
                        sx={{ mt: 2 }}
                      >
                        Adicionar Atividade
                      </Button>
                    </Box>
                  )}
                </Box>
              )}

              {calendarView === 'week' && (
                <Box>
                  <Grid container spacing={1} sx={{ mb: 2 }}>
                    {renderWeekDays().map((day, index) => (
                      <Grid item xs key={index} sx={{ textAlign: 'center' }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            p: 1,
                            backgroundColor: day.isSame(moment(), 'day')
                              ? '#e3f2fd'
                              : '#f5f5f5',
                            borderRadius: '4px',
                            fontWeight: day.isSame(moment(), 'day')
                              ? 'bold'
                              : 'normal',
                          }}
                        >
                          {day.format('ddd')}
                          <br />
                          {day.format('DD')}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>

                  {/* Conteúdo da semana */}
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    {renderWeekDays().map((day, dayIndex) => {
                      const dayActivities = filteredActivities.filter(
                        (activity) =>
                          moment(activity.scheduled).format('YYYY-MM-DD') ===
                          day.format('YYYY-MM-DD'),
                      )

                      return dayActivities.length > 0 ? (
                        <Box key={dayIndex}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              mb: 1,
                              fontWeight: day.isSame(moment(), 'day')
                                ? 'bold'
                                : 'normal',
                              color: day.isSame(moment(), 'day')
                                ? 'primary.main'
                                : 'text.primary',
                            }}
                          >
                            {day.format('dddd, DD [de] MMMM')}
                          </Typography>

                          <Paper variant="outlined" sx={{ p: 1 }}>
                            {dayActivities.map((activity) => (
                              <Box
                                key={activity.id}
                                sx={{
                                  p: 1,
                                  borderLeft: `3px solid ${getActivityColor(activity.type)}`,
                                  mb: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: activity.completed
                                    ? '#f9f9f9'
                                    : 'white',
                                  cursor: 'pointer',
                                  '&:hover': { backgroundColor: '#f5f5f5' },
                                }}
                                onClick={() => handleActivityClick(activity)}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flex: 1,
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      width: 32,
                                      height: 32,
                                      bgcolor: getActivityColor(activity.type),
                                      mr: 1.5,
                                    }}
                                  >
                                    {getActivityTypeIcon(activity.type)}
                                  </Avatar>
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: 'medium',
                                        textDecoration: activity.completed
                                          ? 'line-through'
                                          : 'none',
                                        color: activity.completed
                                          ? 'text.secondary'
                                          : 'text.primary',
                                      }}
                                    >
                                      {activity.title}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      {moment(activity.scheduled).format(
                                        'HH:mm',
                                      )}{' '}
                                      - {activity.contactName}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Tooltip
                                  title={
                                    activity.completed
                                      ? 'Marcar como pendente'
                                      : 'Marcar como concluído'
                                  }
                                >
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleCompleteActivity(
                                        activity,
                                        !activity.completed,
                                      )
                                    }}
                                  >
                                    {activity.completed ? (
                                      <DoneIcon color="success" />
                                    ) : (
                                      <DoneIcon color="action" />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            ))}
                          </Paper>
                        </Box>
                      ) : null
                    })}

                    {/* Mensagem quando não há atividades na semana */}
                    {filteredActivities.length === 0 && (
                      <Box sx={{ textAlign: 'center', py: 5 }}>
                        <TodayIcon
                          sx={{ fontSize: 60, color: '#eeeeee', mb: 2 }}
                        />
                        <Typography variant="h6" color="text.secondary">
                          Nenhuma atividade encontrada para esta semana
                        </Typography>
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={() => setOpenNewActivityDialog(true)}
                          sx={{ mt: 2 }}
                        >
                          Adicionar Atividade
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {calendarView === 'month' && (
                <Box>
                  {/* Grade do mês */}
                  <Grid container spacing={1}>
                    {/* Cabeçalho da semana */}
                    {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'].map(
                      (day, index) => (
                        <Grid item xs key={index} sx={{ textAlign: 'center' }}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 'bold', p: 1 }}
                          >
                            {day}
                          </Typography>
                        </Grid>
                      ),
                    )}

                    {/* Dias do mês */}
                    {renderMonthDays().map((day, index) => {
                      const isCurrentMonth =
                        day.month() === selectedDate.month()
                      const isToday = day.isSame(moment(), 'day')
                      const dayActivities = filteredActivities.filter(
                        (activity) =>
                          moment(activity.scheduled).format('YYYY-MM-DD') ===
                          day.format('YYYY-MM-DD'),
                      )

                      return (
                        <Grid item xs key={index}>
                          <Paper
                            elevation={0}
                            sx={{
                              p: 1,
                              minHeight: 100,
                              backgroundColor: isToday
                                ? '#e3f2fd'
                                : isCurrentMonth
                                  ? 'white'
                                  : '#f5f5f5',
                              opacity: isCurrentMonth ? 1 : 0.7,
                              border: '1px solid #eee',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: isToday
                                  ? '#bbdefb'
                                  : '#f5f5f5',
                              },
                            }}
                            onClick={() => {
                              setSelectedDate(day)
                              setCalendarView('day')
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                textAlign: 'right',
                                fontWeight: isToday ? 'bold' : 'normal',
                                color: isToday
                                  ? 'primary.main'
                                  : isCurrentMonth
                                    ? 'text.primary'
                                    : 'text.secondary',
                              }}
                            >
                              {day.format('D')}
                            </Typography>

                            <Box sx={{ mt: 1 }}>
                              {dayActivities
                                .slice(0, 3)
                                .map((activity, actIndex) => (
                                  <Box
                                    key={actIndex}
                                    sx={{
                                      backgroundColor: getActivityColor(
                                        activity.type,
                                      ),
                                      color: 'white',
                                      p: 0.5,
                                      borderRadius: '4px',
                                      mb: 0.5,
                                      fontSize: '0.7rem',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      opacity: activity.completed ? 0.7 : 1,
                                      textDecoration: activity.completed
                                        ? 'line-through'
                                        : 'none',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleActivityClick(activity)
                                    }}
                                  >
                                    {moment(activity.scheduled).format('HH:mm')}{' '}
                                    {activity.title}
                                  </Box>
                                ))}

                              {dayActivities.length > 3 && (
                                <Chip
                                  label={`+${dayActivities.length - 3} mais`}
                                  size="small"
                                  sx={{
                                    height: 16,
                                    fontSize: '0.6rem',
                                    width: '100%',
                                    mt: 0.5,
                                  }}
                                />
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {/* Drawer para visualizar detalhes da atividade */}
      <Drawer
        anchor="right"
        open={openActivityDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: 400 },
            p: 3,
          },
        }}
      >
        {selectedActivity && (
          <Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}
            >
              <Typography variant="h6">Detalhes da Atividade</Typography>
              <IconButton onClick={handleCloseDrawer}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Avatar
                sx={{
                  bgcolor: getActivityColor(selectedActivity.type),
                  width: 60,
                  height: 60,
                  mr: 2,
                }}
              >
                {getActivityTypeIcon(selectedActivity.type)}
              </Avatar>

              <Box>
                <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                  {selectedActivity.title}
                </Typography>
                <Chip
                  label={selectedActivity.completed ? 'Concluída' : 'Pendente'}
                  color={selectedActivity.completed ? 'success' : 'warning'}
                  size="small"
                  sx={{ mt: 0.5 }}
                />
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Contato
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}
              >
                <PersonIcon
                  fontSize="small"
                  sx={{ mr: 1, color: 'text.secondary' }}
                />
                {selectedActivity.contactName}
              </Typography>
              {selectedActivity.contactCompany && (
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}
                >
                  <BusinessIcon
                    fontSize="small"
                    sx={{ mr: 1, color: 'text.secondary' }}
                  />
                  {selectedActivity.contactCompany}
                </Typography>
              )}
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Data e Hora
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}
              >
                <ScheduleIcon
                  fontSize="small"
                  sx={{ mr: 1, color: 'text.secondary' }}
                />
                {moment(selectedActivity.scheduled).format(
                  'DD/MM/YYYY [às] HH:mm',
                )}
              </Typography>
            </Box>

            {selectedActivity.description && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  Descrição
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mt: 0.5, whiteSpace: 'pre-line' }}
                >
                  {selectedActivity.description}
                </Typography>
              </Box>
            )}

            {selectedActivity.completed && selectedActivity.outcome && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  Resultado
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mt: 0.5, whiteSpace: 'pre-line' }}
                >
                  {selectedActivity.outcome}
                </Typography>
              </Box>
            )}

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption" color="text.secondary">
                Criada em{' '}
                {moment(selectedActivity.createdAt).format('DD/MM/YYYY')}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Por {selectedActivity.userName}
              </Typography>
            </Box>

            <Box
              sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
            >
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
              >
                Excluir
              </Button>
              <Button
                variant="contained"
                color={selectedActivity.completed ? 'warning' : 'success'}
                startIcon={
                  selectedActivity.completed ? <CloseIcon /> : <DoneIcon />
                }
                onClick={() =>
                  handleCompleteActivity(
                    selectedActivity,
                    !selectedActivity.completed,
                  )
                }
              >
                {selectedActivity.completed ? 'Marcar Pendente' : 'Concluir'}
              </Button>
            </Box>
          </Box>
        )}
      </Drawer>

      {/* Diálogo para criar nova atividade */}
      <Dialog
        open={openNewActivityDialog}
        onClose={() => setOpenNewActivityDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AddIcon sx={{ mr: 1 }} />
            Nova Atividade
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo de Atividade</InputLabel>
                  <Select
                    name="type"
                    value={newActivity.type}
                    onChange={handleActivityTypeChange} // Use o manipulador específico aqui
                    label="Tipo de Atividade"
                  >
                    <MenuItem value="call">Ligação</MenuItem>
                    <MenuItem value="meeting">Reunião</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="whatsapp">WhatsApp</MenuItem>
                    <MenuItem value="other">Outro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="pt-br"
                >
                  <Box sx={{ mt: 2 }}>
                    <DateCalendar
                      value={moment(newActivity.date)}
                      onChange={(newDate) =>
                        handleDateTimeChange('date', newDate)
                      }
                      sx={{ width: '100%' }}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="title"
                  label="Título da Atividade *"
                  fullWidth
                  margin="normal"
                  value={newActivity.title}
                  onChange={handleNewActivityChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="pt-br"
                >
                  <TimePicker
                    label="Horário Agendado"
                    value={moment(newActivity.scheduled, 'YYYY-MM-DD HH:mm')}
                    onChange={(newTime) =>
                      handleDateTimeChange('scheduledTime', newTime)
                    }
                    sx={{ width: '100%', mt: 2 }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="contactName"
                  label="Nome do Contato *"
                  fullWidth
                  margin="normal"
                  value={newActivity.contactName}
                  onChange={handleNewActivityChange}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="contactCompany"
                  label="Empresa"
                  fullWidth
                  margin="normal"
                  value={newActivity.contactCompany}
                  onChange={handleNewActivityChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Descrição"
                  fullWidth
                  margin="normal"
                  value={newActivity.description}
                  onChange={handleNewActivityChange}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenNewActivityDialog(false)}
            color="inherit"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSaveActivity}
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para notificações */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ActivityCalendar

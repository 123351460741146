import React from 'react'
import { Box, Button, Menu, MenuItem, Tooltip } from '@mui/material'
import { Category as CategoryIcon } from '@mui/icons-material'

interface ProdutoCategoryFilterProps {
  categoryFilter: string
  categoryAnchorEl: HTMLElement | null
  categories: string[]
  onMenuOpen: (event: React.MouseEvent<HTMLElement>) => void
  onMenuClose: () => void
  onCategorySelect: (category: string) => void
  onClearFilter: () => void
}

export const ProdutoCategoryFilter: React.FC<ProdutoCategoryFilterProps> = ({
  categoryFilter,
  categoryAnchorEl,
  categories,
  onMenuOpen,
  onMenuClose,
  onCategorySelect,
  onClearFilter
}) => {
  return (
    <>
      <Tooltip title="Filtrar por categoria">
        <Box>
          <Button
            startIcon={<CategoryIcon />}
            onClick={onMenuOpen}
            className="categoryFilter"
            variant={categoryFilter ? "contained" : "outlined"}
            sx={{
              minWidth: 'auto',
              height: '40px',
              borderRadius: '8px',
              backgroundColor: categoryFilter ? '#3f51b5' : 'transparent',
              borderColor: '#3f51b5',
              color: categoryFilter ? 'white' : '#3f51b5',
              '&:hover': {
                backgroundColor: categoryFilter ? '#303f9f' : 'rgba(63, 81, 181, 0.08)',
              },
            }}
          >
            {categoryFilter ? categoryFilter : 'Categoria'}
          </Button>
        </Box>
      </Tooltip>
      
      <Menu
        anchorEl={categoryAnchorEl}
        open={Boolean(categoryAnchorEl)}
        onClose={onMenuClose}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            maxHeight: '300px'
          }
        }}
      >
        {categoryFilter && (
          <MenuItem onClick={onClearFilter} sx={{ color: '#f44336' }}>
            Limpar filtro
          </MenuItem>
        )}
        
        {categories.length > 0 ? (
          categories.map((category: string) => (
            <MenuItem 
              key={category} 
              onClick={() => onCategorySelect(category)}
              selected={categoryFilter === category}
              sx={{
                fontSize: '0.9rem',
                minHeight: '40px',
                '&.Mui-selected': {
                  backgroundColor: 'rgba(63, 81, 181, 0.1)',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(63, 81, 181, 0.2)',
                },
              }}
            >
              {category}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>Nenhuma categoria encontrada</MenuItem>
        )}
      </Menu>
    </>
  )
}
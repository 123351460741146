import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Popover,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Paper,
  Avatar,
} from '@mui/material'
import {
  FilterList as FilterIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  LocalOffer as TagIcon,
  Label as LabelIcon,
  AttachMoney as MoneyIcon,
  PriorityHigh as PriorityIcon,
  CalendarToday as DateIcon,
} from '@mui/icons-material'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment, { Moment } from 'moment'
import 'moment/locale/pt-br'

interface KanbanFilterProps {
  boardType: string
  cards: any[]
  columns: any[]
  onFilter: (filteredCards: any[]) => void
}

const KanbanFilter: React.FC<KanbanFilterProps> = ({
  boardType,
  cards,
  columns,
  onFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([])
  const [selectedErps, setSelectedErps] = useState<string[]>([])
  const [selectedColumns, setSelectedColumns] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [erpsOptions, setErpsOptions] = useState<string[]>([])
  const [valueRange, setValueRange] = useState<{
    min: string
    max: string
  }>({
    min: '',
    max: '',
  })
  const [onlyWithProducts, setOnlyWithProducts] = useState(false)
  const [onlyWithContacts, setOnlyWithContacts] = useState(false)

  // Lista de prioridades disponíveis
  const priorities = ['alta', 'média', 'baixa']

  // Extrair tags únicas de todos os cards
  const allTags = React.useMemo(() => {
    const tagsSet = new Set<string>()
    cards.forEach((card) => {
      if (card.tags) {
        const tags =
          typeof card.tags === 'string'
            ? card.tags.split(',').map((t) => t.trim())
            : card.tags

        tags.forEach((tag: string) => {
          if (tag) tagsSet.add(tag)
        })
      }
    })
    return Array.from(tagsSet)
  }, [cards])

  // Extrair usuários únicos de todos os cards
  const allUsers = React.useMemo(() => {
    const usersSet = new Set<string>()
    cards.forEach((card) => {
      if (card.assignedTo?.name) {
        usersSet.add(card.assignedTo.name)
      }
    })
    return Array.from(usersSet)
  }, [cards])

  const [dateRange, setDateRange] = useState<{
    startDate: Moment | null
    endDate: Moment | null
  }>({
    startDate: null,
    endDate: null,
  })

  // Determinar se há filtros ativos
  const hasActiveFilters =
    searchTerm !== '' ||
    selectedPriorities.length > 0 ||
    selectedColumns.length > 0 ||
    selectedUsers.length > 0 ||
    selectedErps.length > 0 ||
    selectedTags.length > 0 ||
    dateRange.startDate !== null ||
    dateRange.endDate !== null ||
    valueRange.min !== '' ||
    valueRange.max !== '' ||
    onlyWithProducts ||
    onlyWithContacts

  useEffect(() => {
    // Cria um Set para automaticamente remover duplicatas
    const uniqueERPs = new Set<string>()

    // Adiciona cada erpName ao Set (valores duplicados serão ignorados)
    for (let i = 0; i < cards.length; i++) {
      // Verifica se erpName existe e é uma string
      if (cards[i].erpName && typeof cards[i].erpName === 'string') {
        uniqueERPs.add(cards[i].erpName)
      }
    }

    // Converte o Set de volta para um array
    const erps: string[] = Array.from(uniqueERPs)

    setErpsOptions(erps)
  }, [cards])
  // Atualizar a lista de filtros ativos
  useEffect(() => {
    const filters = []

    if (searchTerm) filters.push('Termo de busca')
    if (selectedPriorities.length > 0) filters.push('Prioridade')
    if (selectedColumns.length > 0) filters.push('Coluna')
    if (selectedUsers.length > 0) filters.push('Usuário')
    if (selectedTags.length > 0) filters.push('Tags')
    if (selectedErps.length > 0) filters.push('ERPs')
    if (dateRange.startDate || dateRange.endDate) filters.push('Data')
    if (valueRange.min || valueRange.max) filters.push('Valor')
    if (onlyWithProducts) filters.push('Com produtos')
    if (onlyWithContacts) filters.push('Com contatos')

    setActiveFilters(filters)
  }, [
    selectedErps,
    searchTerm,
    selectedPriorities,
    selectedColumns,
    selectedUsers,
    selectedTags,
    dateRange,
    valueRange,
    onlyWithProducts,
    onlyWithContacts,
  ])

  // Abrir menu de filtro
  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  // Fechar menu de filtro
  const handleCloseFilter = () => {
    setAnchorEl(null)
  }

  // Limpar todos os filtros
  const handleClearFilters = () => {
    setSearchTerm('')
    setSelectedPriorities([])
    setSelectedColumns([])
    setSelectedUsers([])
    setSelectedTags([])
    setDateRange({ startDate: null, endDate: null })
    setValueRange({ min: '', max: '' })
    setOnlyWithProducts(false)
    setOnlyWithContacts(false)
    onFilter(cards) // Redefine para mostrar todos os cards
  }

  // Aplicar os filtros
  const applyFilters = () => {
    const filteredCards = cards.filter((card) => {
      // Filtrar por termo de busca - busca em título, descrição e empresa
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        const titleMatch = card.title?.toLowerCase().includes(searchLower);
        const descriptionMatch = card.description?.toLowerCase().includes(searchLower);
        const companyMatch = card.company?.toLowerCase().includes(searchLower);
        const contactNameMatch = card.contact?.name?.toLowerCase().includes(searchLower);
        
        if (!titleMatch && !descriptionMatch && !companyMatch && !contactNameMatch) {
          return false;
        }
      }

      // Filtrar por prioridade
      if (selectedPriorities.length > 0 && !selectedPriorities.includes(card.priority)) {
        return false;
      }

      // Filtrar por coluna
      if (selectedColumns.length > 0 && !selectedColumns.includes(card.columnId)) {
        return false;
      }

      // Filtrar por usuário atribuído
      if (selectedUsers.length > 0) {
        if (!card.assignedTo?.name || !selectedUsers.includes(card.assignedTo.name)) {
          return false;
        }
      }

      // Filtrar por tags
      if (selectedTags.length > 0) {
        if (!card.tags) return false;
        
        const cardTags = typeof card.tags === 'string' 
          ? card.tags.split(',').map(t => t.trim())
          : card.tags;
          
        const hasSelectedTag = selectedTags.some(tag => 
          cardTags.includes(tag)
        );
        
        if (!hasSelectedTag) return false;
      }

      // Filtrar por ERPs
      if (selectedErps.length > 0) {
        if (!card.erpName || !selectedErps.includes(card.erpName)) {
          return false;
        }
      }

      // Filtrar por data de criação
      if (dateRange.startDate) {
        const startOfDay = moment(dateRange.startDate).startOf('day');
        if (moment(card.createdAt).isBefore(startOfDay)) {
          return false;
        }
      }

      if (dateRange.endDate) {
        const endOfDay = moment(dateRange.endDate).endOf('day');
        if (moment(card.createdAt).isAfter(endOfDay)) {
          return false;
        }
      }

      // Filtrar por faixa de valor
      if (valueRange.min && parseFloat(valueRange.min) > 0) {
        const cardValue = parseFloat(card.value || '0');
        if (cardValue < parseFloat(valueRange.min)) {
          return false;
        }
      }

      if (valueRange.max && parseFloat(valueRange.max) > 0) {
        const cardValue = parseFloat(card.value || '0');
        if (cardValue > parseFloat(valueRange.max)) {
          return false;
        }
      }

      // Filtrar cards com produtos
      if (onlyWithProducts) {
        const hasProducts = card.products && card.products.length > 0;
        if (!hasProducts) {
          return false;
        }
      }

      // Filtrar cards com contatos
      if (onlyWithContacts) {
        const hasContact = card.contact?.name || card.contactName;
        if (!hasContact) {
          return false;
        }
      }

      // Se passar por todos os filtros, incluir o card
      return true;
    });

    onFilter(filteredCards);
    handleCloseFilter();
  }

  // Verificar se o filtro está aberto
  const open = Boolean(anchorEl)
  const id = open ? 'kanban-filter-popover' : undefined

  return (
    <>
      <Button
        variant={hasActiveFilters ? 'contained' : 'outlined'}
        size="small"
        startIcon={<FilterIcon />}
        onClick={handleOpenFilter}
        color={hasActiveFilters ? 'primary' : 'inherit'}
        sx={{
          position: 'relative',
          minWidth: '100px',
          pl: 2,
          pr: hasActiveFilters ? 3 : 2,
          py: 0.8,
          borderRadius: '8px',
          transition: 'all 0.2s ease-in-out',
          ...(hasActiveFilters && {
            boxShadow: '0 2px 10px rgba(99, 90, 255, 0.25)',
            '&:hover': {
              boxShadow: '0 4px 12px rgba(99, 90, 255, 0.3)',
            }
          })
        }}
      >
        Filtrar
        {activeFilters.length > 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: -6,
              right: -6,
              backgroundColor: 'white',
              color: '#4338ca',
              borderRadius: '50%',
              width: 22,
              height: 22,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
              fontWeight: 'bold',
              border: '2px solid #4338ca',
              boxShadow: '0 2px 6px rgba(0,0,0,0.15)',
              zIndex: 2,
            }}
          >
            {activeFilters.length}
          </Box>
        )}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: { xs: '90vw', sm: 450 },
            maxHeight: { xs: '80vh', sm: 650 },
            p: { xs: 2, sm: 3 },
            mt: 1,
            boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
            borderRadius: '12px',
            overflowY: 'auto',
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Filtros
            </Typography>
            <Button
              variant="text"
              size="small"
              startIcon={<ClearIcon />}
              onClick={handleClearFilters}
              disabled={!hasActiveFilters}
            >
              Limpar
            </Button>
          </Box>

          <Divider sx={{ mb: 2 }} />

          {/* Termo de busca */}

          <Box 
            sx={{ 
              mb: 3,
              p: 2,
              backgroundColor: 'rgba(99, 90, 255, 0.03)', 
              borderRadius: '10px',
              border: '1px solid rgba(99, 90, 255, 0.08)'
            }}
          >
            <Typography 
              variant="subtitle2" 
              sx={{ 
                mb: 1.5, 
                fontWeight: 600,
                color: '#4338ca',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <SearchIcon
                fontSize="small"
                sx={{ mr: 1, verticalAlign: 'middle' }}
              />
              Buscar
            </Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="Buscar em títulos, descrições, empresa..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    sx={{ mr: 1, color: 'action.active' }}
                  />
                ),
                sx: { 
                  backgroundColor: 'white',
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 3px rgba(99, 90, 255, 0.1)'
                  }
                }
              }}
            />
            {searchTerm && (
              <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button 
                  size="small" 
                  color="inherit" 
                  onClick={() => setSearchTerm('')}
                  startIcon={<ClearIcon fontSize="small" />}
                  sx={{ fontSize: '0.75rem' }}
                >
                  Limpar busca
                </Button>
              </Box>
            )}
          </Box>

          {/* Categorias (ERPs, Prioridades, Colunas, Usuários, Tags) */}
          <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
            
            {/* ERPs */}
            {erpsOptions.length > 0 && (
              <Paper
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: '10px',
                  backgroundColor: 'rgba(99, 90, 255, 0.02)',
                  border: '1px solid rgba(99, 90, 255, 0.08)'
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 2, 
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#4338ca'
                  }}
                >
                  <BusinessIcon
                    fontSize="small"
                    sx={{ mr: 1, verticalAlign: 'middle' }}
                  />
                  ERPs
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {erpsOptions.map((erp) => (
                    <Chip
                      key={erp}
                      label={erp}
                      size="small"
                      variant={selectedErps.includes(erp) ? 'filled' : 'outlined'}
                      color={selectedErps.includes(erp) ? 'primary' : 'default'}
                      onClick={() => {
                        if (selectedErps.includes(erp)) {
                          setSelectedErps(selectedErps.filter((p) => p !== erp))
                        } else {
                          setSelectedErps([...selectedErps, erp])
                        }
                      }}
                      sx={{ 
                        borderRadius: '6px',
                        fontWeight: selectedErps.includes(erp) ? 500 : 400,
                        '& .MuiChip-label': { px: 1.2 }
                      }}
                    />
                  ))}
                </Box>
                {selectedErps.length > 0 && (
                  <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      size="small" 
                      color="inherit" 
                      onClick={() => setSelectedErps([])}
                      startIcon={<ClearIcon fontSize="small" />}
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Limpar ERPs
                    </Button>
                  </Box>
                )}
              </Paper>
            )}

            {/* Prioridades */}
            <Paper
              elevation={0}
              sx={{ 
                p: 2,
                borderRadius: '10px',
                backgroundColor: 'rgba(239, 68, 68, 0.03)',
                border: '1px solid rgba(239, 68, 68, 0.1)'
              }}
            >
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  mb: 2, 
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#b91c1c' 
                }}
              >
                <PriorityIcon
                  fontSize="small"
                  sx={{ mr: 1, verticalAlign: 'middle' }}
                />
                Prioridade
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {priorities.map((priority) => {
                  // Define cores por prioridade
                  const colors = {
                    alta: {
                      bg: '#fee2e2',
                      border: '#ef4444',
                      selectedBg: '#ef4444',
                      selectedText: 'white'
                    },
                    média: {
                      bg: '#fef3c7',
                      border: '#f59e0b',
                      selectedBg: '#f59e0b',
                      selectedText: 'white'
                    },
                    baixa: {
                      bg: '#dcfce7',
                      border: '#22c55e',
                      selectedBg: '#22c55e',
                      selectedText: 'white'
                    }
                  };
                  
                  const isSelected = selectedPriorities.includes(priority);
                  const color = colors[priority];
                  
                  return (
                    <Chip
                      key={priority}
                      label={priority}
                      size="small"
                      onClick={() => {
                        if (isSelected) {
                          setSelectedPriorities(selectedPriorities.filter((p) => p !== priority))
                        } else {
                          setSelectedPriorities([...selectedPriorities, priority])
                        }
                      }}
                      sx={{ 
                        borderRadius: '6px',
                        fontWeight: 500,
                        textTransform: 'capitalize',
                        backgroundColor: isSelected ? color.selectedBg : color.bg,
                        color: isSelected ? color.selectedText : 'rgba(0,0,0,0.7)',
                        border: `1px solid ${isSelected ? 'transparent' : color.border}`,
                        '&:hover': {
                          backgroundColor: isSelected ? color.selectedBg : color.bg,
                          opacity: 0.9
                        }
                      }}
                    />
                  );
                })}
              </Box>
              {selectedPriorities.length > 0 && (
                <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button 
                    size="small" 
                    color="inherit" 
                    onClick={() => setSelectedPriorities([])}
                    startIcon={<ClearIcon fontSize="small" />}
                    sx={{ fontSize: '0.75rem' }}
                  >
                    Limpar prioridades
                  </Button>
                </Box>
              )}
            </Paper>

            {/* Colunas */}
            <Paper
              elevation={0}
              sx={{ 
                p: 2,
                borderRadius: '10px',
                backgroundColor: 'rgba(79, 70, 229, 0.02)',
                border: '1px solid rgba(79, 70, 229, 0.08)'
              }}
            >
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  mb: 2, 
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#4338ca'
                }}
              >
                <LabelIcon
                  fontSize="small"
                  sx={{ mr: 1, verticalAlign: 'middle' }}
                />
                Colunas
              </Typography>
              <Grid container spacing={1}>
                {columns.map((column) => (
                  <Grid item xs={12} sm={6} key={column.id}>
                    <Chip
                      key={column.id}
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: '50%',
                              mr: 0.8,
                              backgroundColor: column.color || '#e5e7eb',
                            }}
                          />
                          <Typography variant="body2" noWrap>
                            {column.title}
                          </Typography>
                        </Box>
                      }
                      size="small"
                      variant={selectedColumns.includes(column.id) ? 'filled' : 'outlined'}
                      color={selectedColumns.includes(column.id) ? 'primary' : 'default'}
                      onClick={() => {
                        if (selectedColumns.includes(column.id)) {
                          setSelectedColumns(selectedColumns.filter((id) => id !== column.id))
                        } else {
                          setSelectedColumns([...selectedColumns, column.id])
                        }
                      }}
                      sx={{ 
                        width: '100%', 
                        justifyContent: 'flex-start',
                        borderRadius: '6px',
                        '& .MuiChip-label': { width: '100%' }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              {selectedColumns.length > 0 && (
                <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button 
                    size="small" 
                    color="inherit" 
                    onClick={() => setSelectedColumns([])}
                    startIcon={<ClearIcon fontSize="small" />}
                    sx={{ fontSize: '0.75rem' }}
                  >
                    Limpar colunas
                  </Button>
                </Box>
              )}
            </Paper>

            {/* Usuários atribuídos */}
            {allUsers.length > 0 && (
              <Paper
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: '10px',
                  backgroundColor: 'rgba(79, 70, 229, 0.02)',
                  border: '1px solid rgba(79, 70, 229, 0.08)'
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 2, 
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#4338ca'
                  }}
                >
                  <PersonIcon
                    fontSize="small"
                    sx={{ mr: 1, verticalAlign: 'middle' }}
                  />
                  Atribuído a
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {allUsers.map((user) => (
                    <Chip
                      key={user}
                      avatar={<Avatar sx={{ width: 20, height: 20 }}>{user[0]}</Avatar>}
                      label={user}
                      size="small"
                      variant={selectedUsers.includes(user) ? 'filled' : 'outlined'}
                      color={selectedUsers.includes(user) ? 'primary' : 'default'}
                      onClick={() => {
                        if (selectedUsers.includes(user)) {
                          setSelectedUsers(selectedUsers.filter((u) => u !== user))
                        } else {
                          setSelectedUsers([...selectedUsers, user])
                        }
                      }}
                      sx={{ 
                        borderRadius: '6px',
                        '& .MuiChip-label': { px: 1 }
                      }}
                    />
                  ))}
                </Box>
                {selectedUsers.length > 0 && (
                  <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      size="small" 
                      color="inherit" 
                      onClick={() => setSelectedUsers([])}
                      startIcon={<ClearIcon fontSize="small" />}
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Limpar usuários
                    </Button>
                  </Box>
                )}
              </Paper>
            )}

            {/* Tags */}
            {allTags.length > 0 && (
              <Paper
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: '10px',
                  backgroundColor: 'rgba(79, 70, 229, 0.02)',
                  border: '1px solid rgba(79, 70, 229, 0.08)'
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 2, 
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#4338ca'
                  }}
                >
                  <TagIcon
                    fontSize="small"
                    sx={{ mr: 1, verticalAlign: 'middle' }}
                  />
                  Tags
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {allTags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      size="small"
                      icon={<TagIcon fontSize="small" />}
                      variant={selectedTags.includes(tag) ? 'filled' : 'outlined'}
                      color={selectedTags.includes(tag) ? 'secondary' : 'default'}
                      onClick={() => {
                        if (selectedTags.includes(tag)) {
                          setSelectedTags(selectedTags.filter((t) => t !== tag))
                        } else {
                          setSelectedTags([...selectedTags, tag])
                        }
                      }}
                      sx={{ 
                        borderRadius: '6px',
                        fontWeight: selectedTags.includes(tag) ? 500 : 400,
                        '& .MuiChip-label': { px: 1 }
                      }}
                    />
                  ))}
                </Box>
                {selectedTags.length > 0 && (
                  <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      size="small" 
                      color="inherit" 
                      onClick={() => setSelectedTags([])}
                      startIcon={<ClearIcon fontSize="small" />}
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Limpar tags
                    </Button>
                  </Box>
                )}
              </Paper>
            )}
          </Box>

          {/* Filtros avançados em um painel */}
          <Paper
            elevation={0}
            sx={{ 
              mb: 3, 
              p: 2,
              borderRadius: '10px',
              border: '1px solid #e5e7eb',
            }}
          >
            <Typography 
              variant="subtitle2" 
              sx={{ 
                mb: 2, 
                fontWeight: 600,
                borderBottom: '1px solid #e5e7eb',
                pb: 1
              }}
            >
              Filtros Avançados
            </Typography>

            {/* Filtro de Data */}
            <Box sx={{ mb: 3 }}>
              <Typography 
                variant="body2" 
                sx={{ 
                  mb: 1.5, 
                  fontWeight: 500,
                  color: '#4b5563',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <DateIcon
                  fontSize="small"
                  sx={{ mr: 1, verticalAlign: 'middle', color: '#4338ca' }}
                />
                Data de Criação
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label="De"
                    value={dateRange.startDate}
                    onChange={(date) =>
                      setDateRange({ ...dateRange, startDate: date })
                    }
                    slotProps={{ 
                      textField: { 
                        size: 'small', 
                        fullWidth: true,
                        InputProps: {
                          sx: { 
                            backgroundColor: 'white',
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 3px rgba(99, 90, 255, 0.1)'
                            }
                          }
                        }
                      } 
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="Até"
                    value={dateRange.endDate}
                    onChange={(date) =>
                      setDateRange({ ...dateRange, endDate: date })
                    }
                    slotProps={{ 
                      textField: { 
                        size: 'small', 
                        fullWidth: true,
                        InputProps: {
                          sx: { 
                            backgroundColor: 'white',
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 3px rgba(99, 90, 255, 0.1)'
                            }
                          }
                        }
                      } 
                    }}
                  />
                </Grid>
              </Grid>
              
              {(dateRange.startDate || dateRange.endDate) && (
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button 
                    size="small" 
                    color="inherit" 
                    onClick={() => setDateRange({ startDate: null, endDate: null })}
                    startIcon={<ClearIcon fontSize="small" />}
                    sx={{ fontSize: '0.75rem' }}
                  >
                    Limpar datas
                  </Button>
                </Box>
              )}
            </Box>

            {/* Filtro de Valor (somente para lead) */}
            {boardType === 'lead' && (
              <Box sx={{ mb: 3 }}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 1.5, 
                    fontWeight: 500,
                    color: '#4b5563',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <MoneyIcon
                    fontSize="small"
                    sx={{ mr: 1, verticalAlign: 'middle', color: '#047857' }}
                  />
                  Faixa de Valor
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Valor mínimo"
                      size="small"
                      fullWidth
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <Typography 
                            variant="body2" 
                            sx={{ color: '#047857', fontWeight: 500, mr: 0.5 }}
                          >
                            R$
                          </Typography>
                        ),
                        sx: { 
                          backgroundColor: 'white',
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 3px rgba(4, 120, 87, 0.1)'
                          }
                        }
                      }}
                      value={valueRange.min}
                      onChange={(e) =>
                        setValueRange({ ...valueRange, min: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Valor máximo"
                      size="small"
                      fullWidth
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <Typography 
                            variant="body2" 
                            sx={{ color: '#047857', fontWeight: 500, mr: 0.5 }}
                          >
                            R$
                          </Typography>
                        ),
                        sx: { 
                          backgroundColor: 'white',
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 3px rgba(4, 120, 87, 0.1)'
                          }
                        }
                      }}
                      value={valueRange.max}
                      onChange={(e) =>
                        setValueRange({ ...valueRange, max: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
                
                {(valueRange.min || valueRange.max) && (
                  <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      size="small" 
                      color="inherit" 
                      onClick={() => setValueRange({ min: '', max: '' })}
                      startIcon={<ClearIcon fontSize="small" />}
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Limpar valores
                    </Button>
                  </Box>
                )}
              </Box>
            )}

            {/* Filtros adicionais */}
            <Box sx={{ mb: 1 }}>
              <Typography 
                variant="body2" 
                sx={{ 
                  mb: 1.5, 
                  fontWeight: 500,
                  color: '#4b5563',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <BusinessIcon
                  fontSize="small"
                  sx={{ mr: 1, verticalAlign: 'middle', color: '#7c3aed' }}
                />
                Incluir apenas
              </Typography>
              <FormGroup>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={onlyWithProducts}
                        onChange={(e) => setOnlyWithProducts(e.target.checked)}
                        size="small"
                        color="secondary"
                      />
                    }
                    label="Com produtos/serviços"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={onlyWithContacts}
                        onChange={(e) => setOnlyWithContacts(e.target.checked)}
                        size="small"
                        color="secondary"
                      />
                    }
                    label="Com contatos"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
                  />
                </Box>
              </FormGroup>
            </Box>
          </Paper>


          {/* Resumo de filtros */}
          {activeFilters.length > 0 && (
            <Paper
              sx={{ 
                mt: 3, 
                p: 1.5, 
                backgroundColor: 'rgba(99, 90, 255, 0.05)',
                borderRadius: '8px',
                border: '1px solid rgba(99, 90, 255, 0.2)'
              }}
              elevation={0}
            >
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, display: 'block', mb: 1, color: '#4338ca' }}
              >
                Filtros ativos:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.8 }}>
                {activeFilters.map((filter) => (
                  <Chip
                    key={filter}
                    label={filter}
                    size="small"
                    color="primary"
                    sx={{ 
                      borderRadius: '4px',
                      '& .MuiChip-label': { px: 1 }
                    }}
                  />
                ))}
              </Box>
            </Paper>
          )}

          {/* Botões de ação */}
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              mt: 3,
              pt: 2,
              borderTop: '1px solid #e5e7eb'
            }}
          >
            <Button 
              variant="outlined" 
              color="inherit" 
              onClick={handleCloseFilter}
              sx={{ color: 'text.secondary' }}
            >
              Cancelar
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={applyFilters}
              startIcon={<FilterIcon />}
              sx={{
                boxShadow: '0 4px 12px rgba(99, 90, 255, 0.2)',
                '&:hover': {
                  boxShadow: '0 6px 16px rgba(99, 90, 255, 0.3)',
                }
              }}
            >
              Aplicar {activeFilters.length > 0 ? `(${activeFilters.length})` : ''}
            </Button>
          </Box>
        </LocalizationProvider>
      </Popover>
    </>
  )
}

export default KanbanFilter

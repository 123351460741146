import { useState, useEffect, useCallback } from 'react'
import { getUser } from 'hooks/getUser'
import { llmEndpoint } from 'infra'

export const useResponsiveChat = (setIsMobile, setIsSidebarOpen) => {
  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth < 768
      setIsMobile(isMobileView)

      if (isMobileView) {
        setIsSidebarOpen(false)
      } else {
        setIsSidebarOpen(true)
      }
    }

    window.addEventListener('resize', handleResize)
    // Initial check
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [setIsMobile, setIsSidebarOpen])

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prev) => !prev)
  }, [setIsSidebarOpen])

  return { toggleSidebar }
}

export const usePopupClickOutside = (popupPosition, setPopupPosition) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupPosition.isOpen && !event.target.closest('.popup-menu')) {
        setPopupPosition((prev) => ({ ...prev, isOpen: false }))
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupPosition.isOpen, setPopupPosition])
}

export const useChatData = (rpaId) => {
  const [chats, setChats] = useState([])
  const [selectedChatId, setSelectedChatId] = useState(null)
  const [messages, setMessages] = useState([])
  const [isPolling, setIsPolling] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  // Fetch all chats for the user
  const fetchChats = useCallback(async () => {
    try {
      const userId = getUser().id
      console.log('Buscando dados da user:', userId)

      const response = await fetch(
        `${llmEndpoint.url}/folder/user/${userId}?rpaId=${rpaId}`,
      )

      if (!response.ok) {
        throw new Error('Erro ao buscar chats')
      }

      const data = await response.json()
      setChats(data)
    } catch (error) {
      console.error('Erro ao buscar chats:', error)
    }
  }, [rpaId])

  // Fetch messages for a specific chat
  const fetchMessages = useCallback(async (chatId) => {
    try {
      const response = await fetch(`${llmEndpoint.url}/chat?folderId=${chatId}`)

      if (!response.ok) {
        throw new Error('Erro ao buscar mensagens')
      }

      const data = await response.json()
      setMessages(data)
      setSelectedChatId(chatId)
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error)
    }
  }, [])

  // Fetch messages periodically (polling)
  const fetchMessagesForPolling = useCallback(async (chatId) => {
    try {
      const response = await fetch(`${llmEndpoint.url}/chat?folderId=${chatId}`)

      if (!response.ok) {
        throw new Error('Erro ao buscar mensagens')
      }

      const data = await response.json()
      setMessages(data)
    } catch (error) {
      console.error('Erro ao buscar mensagens (polling):', error)
    }
  }, [])

  // Set up polling interval
  useEffect(() => {
    if (!selectedChatId || !isPolling) return

    const intervalId = setInterval(() => {
      fetchMessagesForPolling(selectedChatId)
    }, 5000)

    return () => clearInterval(intervalId)
  }, [selectedChatId, isPolling, fetchMessagesForPolling])

  // Initial fetch of chats
  useEffect(() => {
    fetchChats()
  }, [fetchChats])

  // Send a message
  const sendMessage = useCallback(
    async (message, metada) => {
      console.log('Enviando mensagem:', message)
      console.log('Enviando metada:', metada)
      
    //   if (!selectedChatId || message.trim() === '') return

      setIsLoading(true)
      setMessages((prev) => [...prev, { message, variant: 'right' }])

      try {
        const response = await fetch(
          `${llmEndpoint.url}/chat?folderId=${selectedChatId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message,
              isBot: false,
              replyTo: metada.replyingTo,
              attachments: metada?.attachments ?? null,
            }),
          },
        )

        if (!response.ok) {
          throw new Error('Erro na resposta da API')
        }

        await response.json()
      } catch (error) {
        console.error('Erro ao enviar mensagem:', error)
      } finally {
        setIsLoading(false)
      }
    },
    [selectedChatId],
  )

  // Handle clicking on a chat
  const handleChatBoxClick = useCallback(
    (chatId, isMobile, setIsSidebarOpen) => {
      fetchMessages(chatId)
      setIsPolling(true)

      if (isMobile) {
        setIsSidebarOpen(false)
      }
    },
    [fetchMessages],
  )

  return {
    chats,
    setChats,
    selectedChatId,
    setSelectedChatId,
    messages,
    setMessages,
    isPolling,
    setIsPolling,
    isLoading,
    fetchChats,
    fetchMessages,
    sendMessage,
    handleChatBoxClick,
  }
}

export const useChatEdit = (chats, setChats) => {
  const [editingChatId, setEditingChatId] = useState(null)
  const [editingChatName, setEditingChatName] = useState('')
  const [chatToDelete, setChatToDelete] = useState(null)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)
  const [openPopupId, setOpenPopupId] = useState(false)
  const [popupPosition, setPopupPosition] = useState({
    isOpen: false,
    x: 0,
    y: 0,
  })

  // Handle options menu click
  const handleOptionsClick = useCallback((e, chatId) => {
    e.stopPropagation()
    setOpenPopupId(chatId)
    setPopupPosition({
      isOpen: true,
      x: e.clientX,
      y: e.clientY,
    })
  }, [])

  // Close the popup menu
  const closePopup = useCallback(() => {
    setPopupPosition((prev) => ({ ...prev, isOpen: false }))
  }, [])

  // Handle renaming a chat
  const handleRenameChat = useCallback((chatId, currentName) => {
    setEditingChatId(chatId)
    setEditingChatName(currentName)
    setPopupPosition((prev) => ({ ...prev, isOpen: false }))
  }, [])

  // Save the renamed chat
  const handleSaveRename = useCallback(async () => {
    if (editingChatName.trim() === '') return

    try {
      const response = await fetch(
        `${llmEndpoint.url}/folder/name/${editingChatId}?newName=${editingChatName}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: editingChatName }),
        },
      )

      if (!response.ok) {
        throw new Error('Erro ao renomear chat')
      }

      setChats(
        chats.map((chat) =>
          chat.id === editingChatId ? { ...chat, name: editingChatName } : chat,
        ),
      )
      setEditingChatId(null)
      setEditingChatName('')
    } catch (error) {
      console.error('Erro ao renomear chat:', error)
    }
  }, [editingChatId, editingChatName, chats, setChats])

  // Delete a chat
  const handleDeleteChat = useCallback((chatId) => {
    setChatToDelete(chatId)
    setIsDeleteConfirmationOpen(true)
    setPopupPosition((prev) => ({ ...prev, isOpen: false }))
  }, [])

  // Confirm chat deletion
  const confirmDeleteChat = useCallback(async () => {
    if (!chatToDelete) return

    try {
      const response = await fetch(
        `${llmEndpoint.url}/folder/${chatToDelete}`,
        {
          method: 'DELETE',
        },
      )

      if (!response.ok) {
        throw new Error('Erro ao deletar chat')
      }

      setChats(chats.filter((chat) => chat.id !== chatToDelete))
    } catch (error) {
      console.error('Erro ao deletar chat:', error)
    } finally {
      setIsDeleteConfirmationOpen(false)
      setChatToDelete(null)
    }
  }, [chatToDelete, chats, setChats])

  return {
    editingChatId,
    setEditingChatId,
    editingChatName,
    setEditingChatName,
    chatToDelete,
    setChatToDelete,
    isDeleteConfirmationOpen,
    setIsDeleteConfirmationOpen,
    openPopupId,
    setOpenPopupId,
    popupPosition,
    setPopupPosition,
    handleOptionsClick,
    closePopup,
    handleRenameChat,
    handleSaveRename,
    handleDeleteChat,
    confirmDeleteChat,
  }
}

import React from 'react'
import { Button } from '@mui/material'

export const ChatTypeSelector = ({ modalChatType, setModalChatType, rpaId }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Tipo de Chat
      </label>
      <div className="flex rounded-md shadow-sm">
        <Button
          variant={modalChatType === 'llm' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => setModalChatType('llm')}
          sx={{
            flexGrow: 1,
            borderRadius: '8px 0 0 8px',
            textTransform: 'none',
            boxShadow: 'none',
          }}
        >
          LLM
        </Button>
        <Button
          variant={modalChatType === 'contact' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => setModalChatType('contact')}
          sx={{
            flexGrow: 1,
            borderRadius: '0 8px 8px 0',
            textTransform: 'none',
            boxShadow: 'none',
          }}
          disabled={(rpaId !== '663457c4-abce-4724-9fce-de4021e1717b' || rpaId !== '323457c4-abce-4724-9fce-de4021e1717b')}
        >
          Contato
        </Button>
      </div>
    </div>
  )
}
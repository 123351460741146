import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Chip,
  Tooltip,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Card,
  CardContent,
  Badge,
} from '@mui/material';
import {
  Add as AddIcon,
  Phone as PhoneIcon,
  EventNote as EventIcon,
  Email as EmailIcon,
  WhatsApp as WhatsAppIcon,
  More as MoreIcon,
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterIcon,
  Schedule as ScheduleIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Event as CalendarIcon,
  Done as DoneIcon,
  Replay as ReplayIcon,
} from '@mui/icons-material';
import { Activity } from './types/CardTypes';
import NewActivityDialog from './NewActivityDialog';
import { useActivities } from './hooks/useActivities';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 600,
  marginBottom: '12px',
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
    fontSize: '18px',
  },
}));

const ActivityItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '8px',
  marginBottom: '8px',
  border: '1px solid #e0e0e0',
  transition: 'all 0.2s',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

interface ActivitySectionProps {
  activities: Activity[];
  onAddActivity: (activity: Activity) => void;
  onUpdateActivity: (activity: Activity) => void;
  onRemoveActivity: (activityId: string) => void;
}

const ActivitySection: React.FC<ActivitySectionProps> = ({
  activities,
  onAddActivity,
  onUpdateActivity,
  onRemoveActivity,
}) => {
  const [showNewActivityDialog, setShowNewActivityDialog] = useState(false);
  const [expandedActivityId, setExpandedActivityId] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState(false);

  const {
    activities: filteredActivities,
    newActivityForm,
    editActivityId,
    filterType,
    filterCompleted,
    setFilterType,
    setFilterCompleted,
    handleNewActivityChange,
    handleNewActivityCheckbox,
    saveActivity,
    editActivity,
    deleteActivity,
    completeActivity,
    resetNewActivityForm,
  } = useActivities({
    cardActivities: activities,
    onAddActivity,
    onUpdateActivity,
    onRemoveActivity,
  });

  // Total de atividades pendentes
  const pendingActivitiesCount = activities.filter(a => !a.completed).length;

  // Ícone baseado no tipo de atividade
  const getActivityIcon = (type: string) => {
    switch (type) {
      case 'call':
        return <PhoneIcon fontSize="small" color="primary" />;
      case 'meeting':
        return <EventIcon fontSize="small" color="secondary" />;
      case 'email':
        return <EmailIcon fontSize="small" style={{ color: '#4caf50' }} />;
      case 'whatsapp':
        return <WhatsAppIcon fontSize="small" style={{ color: '#25D366' }} />;
      default:
        return <MoreIcon fontSize="small" color="action" />;
    }
  };

  // Formatar data
  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'dd/MM/yyyy', { locale: ptBR });
    } catch (error) {
      return dateString;
    }
  };

  // Formatar data e hora
  const formatDateTime = (dateString?: string) => {
    if (!dateString) return '';
    try {
      return format(new Date(dateString), 'dd/MM/yyyy HH:mm', { locale: ptBR });
    } catch (error) {
      return dateString;
    }
  };

  // Expandir/colapsar um item de atividade
  const toggleExpanded = (activityId: string) => {
    setExpandedActivityId(expandedActivityId === activityId ? null : activityId);
  };

  // Iniciar edição de uma atividade
  const handleEditActivity = (activity: Activity) => {
    editActivity(activity);
    setShowNewActivityDialog(true);
  };

  // Salvar uma atividade nova ou editada
  const handleSaveActivity = (activityData: Omit<Activity, 'id' | 'createdAt'>) => {
    const isSuccess = saveActivity();
    if (isSuccess) {
      setShowNewActivityDialog(false);
      resetNewActivityForm();
    }
  };

  // Fechar diálogo
  const handleCloseDialog = () => {
    setShowNewActivityDialog(false);
    resetNewActivityForm();
  };

  // Alternar visibilidade dos filtros
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <SectionTitle variant="h6">
          <EventIcon color="primary" />
          Atividades e Histórico
          {pendingActivitiesCount > 0 && (
            <Badge
              badgeContent={pendingActivitiesCount}
              color="error"
              sx={{ ml: 1 }}
            />
          )}
        </SectionTitle>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size="small"
            startIcon={<FilterIcon />}
            variant={showFilters ? "contained" : "outlined"}
            color="secondary"
            onClick={toggleFilters}
            sx={{ height: 36 }}
          >
            Filtros
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              resetNewActivityForm();
              setShowNewActivityDialog(true);
            }}
            size="small"
            sx={{ height: 36 }}
          >
            Nova Atividade
          </Button>
        </Box>
      </Box>

      {/* Filtros */}
      <Collapse in={showFilters}>
        <Paper variant="outlined" sx={{ p: 2, mb: 2, bgcolor: '#f8f9fa' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="filter-type-label">Tipo</InputLabel>
              <Select
                labelId="filter-type-label"
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                label="Tipo"
                sx={{ bgcolor: 'white' }}
              >
                <MenuItem value="all">Todos</MenuItem>
                <MenuItem value="call">Ligações</MenuItem>
                <MenuItem value="meeting">Reuniões</MenuItem>
                <MenuItem value="email">Emails</MenuItem>
                <MenuItem value="whatsapp">WhatsApp</MenuItem>
                <MenuItem value="other">Outros</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" fullWidth>
              <InputLabel id="filter-status-label">Status</InputLabel>
              <Select
                labelId="filter-status-label"
                value={filterCompleted}
                onChange={(e) => setFilterCompleted(e.target.value)}
                label="Status"
                sx={{ bgcolor: 'white' }}
              >
                <MenuItem value="all">Todos</MenuItem>
                <MenuItem value="pending">Pendentes</MenuItem>
                <MenuItem value="completed">Concluídos</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Paper>
      </Collapse>

      {/* Lista de atividades */}
      {filteredActivities.length > 0 ? (
        <List disablePadding>
          {filteredActivities.map((activity) => (
            <Card key={activity.id} variant="outlined" sx={{ mb: 2 }}>
              <ActivityItem
                disableGutters
                onClick={() => toggleExpanded(activity.id)}
                sx={{
                  bgcolor: activity.completed ? '#f9f9f9' : 'white',
                  borderBottomLeftRadius: expandedActivityId === activity.id ? 0 : 8,
                  borderBottomRightRadius: expandedActivityId === activity.id ? 0 : 8,
                  borderBottom: expandedActivityId === activity.id ? '1px dashed #e0e0e0' : 'none',
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  {getActivityIcon(activity.type)}
                </ListItemIcon>

                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: 600,
                          textDecoration: activity.completed ? 'line-through' : 'none',
                          color: activity.completed ? '#757575' : 'inherit',
                        }}
                      >
                        {activity.title}
                      </Typography>
                      {activity.scheduled && (
                        <Tooltip title="Atividade agendada">
                          <Chip
                            icon={<ScheduleIcon fontSize="small" />}
                            label={formatDateTime(activity.scheduled)}
                            size="small"
                            color="info"
                            variant="outlined"
                            sx={{ ml: 1 }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  }
                  secondary={
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                      <Chip
                        label={formatDate(activity.date)}
                        size="small"
                        icon={<CalendarIcon fontSize="small" />}
                        variant="outlined"
                        sx={{ mr: 1 }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {activity.userName}
                      </Typography>
                    </Box>
                  }
                />

                <ListItemSecondaryAction>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {activity.completed ? (
                      <Tooltip title="Marcar como pendente">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            completeActivity(activity, false);
                          }}
                          sx={{ color: '#4caf50' }}
                        >
                          <ReplayIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Marcar como concluído">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            completeActivity(activity, true);
                          }}
                          sx={{ color: '#757575' }}
                        >
                          <DoneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Editar">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditActivity(activity);
                        }}
                        sx={{ color: '#1976d2' }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Excluir">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteActivity(activity.id);
                        }}
                        sx={{ color: '#f44336' }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    {expandedActivityId === activity.id ? (
                      <ExpandLessIcon fontSize="small" sx={{ color: '#757575', ml: 1 }} />
                    ) : (
                      <ExpandMoreIcon fontSize="small" sx={{ color: '#757575', ml: 1 }} />
                    )}
                  </Box>
                </ListItemSecondaryAction>
              </ActivityItem>

              {/* Detalhes expandidos da atividade */}
              <Collapse in={expandedActivityId === activity.id} timeout="auto">
                <CardContent sx={{ pt: 1, pb: 2, px: 2, bgcolor: '#fafafa' }}>
                  {activity.description && (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="caption" color="text.secondary" gutterBottom>
                        Descrição:
                      </Typography>
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                        {activity.description}
                      </Typography>
                    </Box>
                  )}

                  {activity.completed && activity.outcome && (
                    <Box>
                      <Typography variant="caption" color="text.secondary" gutterBottom>
                        Resultado/Observações:
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-line' }}>
                        {activity.outcome}
                      </Typography>
                    </Box>
                  )}

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, pt: 1, borderTop: '1px dashed #e0e0e0' }}>
                    <Typography variant="caption" color="text.secondary">
                      Criado em: {formatDateTime(activity.createdAt)}
                    </Typography>
                    <Chip 
                      size="small" 
                      label={activity.completed ? 'Concluído' : 'Pendente'} 
                      color={activity.completed ? 'success' : 'warning'}
                      variant="outlined"
                    />
                  </Box>
                </CardContent>
              </Collapse>
            </Card>
          ))}
        </List>
      ) : (
        <Paper
          variant="outlined"
          sx={{
            p: 3,
            textAlign: 'center',
            bgcolor: '#f9f9f9',
            borderRadius: '8px',
            borderStyle: 'dashed',
          }}
        >
          <EventIcon sx={{ fontSize: 40, color: '#bdbdbd', mb: 1 }} />
          <Typography variant="body1" sx={{ color: '#757575' }}>
            Nenhuma atividade registrada
          </Typography>
          <Typography variant="body2" sx={{ color: '#9e9e9e', mt: 0.5 }}>
            Clique em "Nova Atividade" para registrar interações com este cliente
          </Typography>
        </Paper>
      )}

      {/* Diálogo para criar/editar atividade */}
      <NewActivityDialog
        open={showNewActivityDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveActivity}
        initialData={editActivityId ? newActivityForm : undefined}
        isEditing={!!editActivityId}
      />
    </Box>
  );
};

export default ActivitySection;
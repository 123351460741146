import { useState } from 'react'
import { Box, Button, Dialog, CircularProgress, Paper, Typography } from '@mui/material'
import { Add, Person } from '@mui/icons-material'

import { InputSearch, SearchBarMobile } from 'components'
import { PaginationComponent } from '../../../../../../../../components/PaginationComponent/PaginationComponent'
import { FilterListProfile } from '../../../../../erp/components/Filter/FilterListProfile'
import { UserCard } from '../../../UserManagement/components/UserCard'
import { UserForm } from '../../../UserManagement/components/UserForm'
import { User } from '../../../../types'
import * as S from './style'

interface UserListProps {
  users: User[]
  loading: boolean
  onSave: (user: User) => Promise<boolean>
  onDelete: (id: string) => Promise<boolean>
  onToggleActive: (id: string, active: boolean) => Promise<boolean>
}

export const UserList: React.FC<UserListProps> = ({
  users,
  loading,
  onSave,
  onDelete,
  onToggleActive
}) => {
  const [search, setSearch] = useState('')
  const [showInputSearch, setShowInputSearch] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const usersPerPage = 9
  const [localLoading, setLocalLoading] = useState(false)
  const [sortedUsers, setSortedUsers] = useState<User[]>(users)

  // Atualiza os usuários ordenados quando a prop de usuários muda
  if (users !== sortedUsers && !localLoading) {
    setSortedUsers(users)
  }

  const handleCardClick = (user: User) => {
    setSelectedUser(user)
    setIsEdit(true)
    setShowForm(true)
  }

  const handleAddNew = () => {
    setSelectedUser(null)
    setIsEdit(false)
    setShowForm(true)
  }

  const handleCloseForm = () => {
    setShowForm(false)
    setSelectedUser(null)
  }

  const handleSave = async (userData: User) => {
    setLocalLoading(true)
    const success = await onSave(userData)
    setLocalLoading(false)
    
    if (success) {
      setShowForm(false)
    }
  }

  const handleDelete = async (id: string) => {
    setLocalLoading(true)
    const success = await onDelete(id)
    setLocalLoading(false)
    
    if (success && selectedUser?.id === id) {
      setShowForm(false)
    }
  }

  const handleToggleActive = async (id: string, active: boolean) => {
    setLocalLoading(true)
    await onToggleActive(id, active)
    setLocalLoading(false)
  }

  // Filtra os usuários baseado na pesquisa
  const filteredUsers = sortedUsers.filter(
    (user) =>
      user.name.toLowerCase().includes(search.toLowerCase()) ||
      user.email.toLowerCase().includes(search.toLowerCase()) ||
      (user.phone && user.phone.includes(search)) ||
      user.integrationCode.includes(search)
  )

  // Paginação dos usuários
  const getCurrentPageUsers = () => {
    const startIndex = (currentPage - 1) * usersPerPage
    const endIndex = startIndex + usersPerPage
    return filteredUsers.slice(startIndex, endIndex)
  }

  // Manipulador para ordenação
  const handleOrderBy = (orderType: string) => {
    setLocalLoading(true)
    let orderedUsers = [...sortedUsers]

    switch (orderType) {
      case 'recent':
        orderedUsers.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )
        break
      case 'oldest':
        orderedUsers.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        )
        break
      case 'a-z':
        orderedUsers.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'z-a':
        orderedUsers.sort((a, b) => b.name.localeCompare(a.name))
        break
      case 'active':
        orderedUsers.sort((a, b) => Number(b.active) - Number(a.active))
        break
      case 'inactive':
        orderedUsers.sort((a, b) => Number(a.active) - Number(b.active))
        break
      default:
        break
    }

    setSortedUsers(orderedUsers)
    setLocalLoading(false)
  }

  return (
    <S.TabContentContainer>
      <S.WrapperSerach>
        <div className="filters">
          {showInputSearch ? (
            <div className="inputSearchMobile">
              <SearchBarMobile
                placeholder={'Pesquisar usuário'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClose={() => setShowInputSearch(!showInputSearch)}
              />
            </div>
          ) : (
            <>
              <InputSearch
                placeholder="Pesquisar usuário"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onShowMobile={() =>
                  setShowInputSearch(!showInputSearch)
                }
              />
              <FilterListProfile orderBy={handleOrderBy} />
            </>
          )}
        </div>

        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddNew}
          className="newProfile"
          disabled={loading || localLoading}
          sx={{
            borderRadius: '6px',
            boxShadow: '0 2px 8px rgba(63, 81, 181, 0.25)',
            transition: 'all 0.2s ease',
            '&:hover': {
              boxShadow: '0 4px 12px rgba(63, 81, 181, 0.4)',
            },
          }}
        >
          Novo Usuário
        </Button>
      </S.WrapperSerach>

      {loading ? (
        <S.LoadingContainer>
          <CircularProgress size={40} />
          <p>Carregando usuários...</p>
        </S.LoadingContainer>
      ) : (
        <>
          {filteredUsers.length > 0 ? (
            <>
              <Box sx={{ padding: '24px 24px 0' }}>
                <S.CardGrid>
                  {getCurrentPageUsers().map((user) => (
                    <UserCard
                      key={user.id}
                      user={user}
                      onClick={() => handleCardClick(user)}
                      onDelete={() => handleDelete(user.id)}
                      onToggleActive={(active) =>
                        handleToggleActive(user.id, active)
                      }
                    />
                  ))}
                </S.CardGrid>
              </Box>

              {filteredUsers.length > usersPerPage && (
                <Box sx={{ padding: '0 24px 24px' }}>
                  <PaginationComponent
                    currentPage={currentPage}
                    totalItems={filteredUsers.length}
                    itemsPerPage={usersPerPage}
                    onPageChange={setCurrentPage}
                    itemName="Usuários"
                  />
                </Box>
              )}
            </>
          ) : (
            <S.EmptyState>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4, 
                  borderRadius: 4,
                  backgroundColor: 'rgba(245, 247, 250, 0.8)',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  width: '100%',
                  maxWidth: '500px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Box 
                  sx={{ 
                    color: '#f50057', 
                    fontSize: '4rem',
                    backgroundColor: 'rgba(245, 0, 87, 0.1)',
                    borderRadius: '50%',
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2
                  }}
                >
                  <Person fontSize="inherit" />
                </Box>
                <h3>Nenhum usuário encontrado</h3>
                <p>
                  {search ? 
                    `Não encontramos usuários para "${search}". Tente outro termo ou crie um novo usuário.` :
                    'Crie usuários para que eles possam acessar o sistema com diferentes níveis de permissões.'
                  }
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<Add />}
                  onClick={handleAddNew}
                  sx={{
                    borderRadius: '6px',
                    boxShadow: '0 2px 8px rgba(63, 81, 181, 0.25)',
                    padding: '10px 24px',
                    fontWeight: 500,
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(63, 81, 181, 0.4)',
                    },
                  }}
                >
                  Criar Usuário
                </Button>
              </Paper>
            </S.EmptyState>
          )}
        </>
      )}

      {/* Dialog para o formulário de usuário */}
      <Dialog
        open={showForm}
        onClose={localLoading ? undefined : handleCloseForm}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { 
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)'
          }
        }}
        TransitionProps={{
          style: { transition: 'all 0.3s ease' }
        }}
      >
        <Box sx={{ p: 0 }}>
          <UserForm
            user={selectedUser}
            isEdit={isEdit}
            onSave={handleSave}
            onDelete={handleDelete}
            onCancel={handleCloseForm}
            loading={localLoading}
            error={null}
          />
        </Box>
      </Dialog>
    </S.TabContentContainer>
  )
}
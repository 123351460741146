// useOmieIntegrationWithContext.js
import React from 'react';
import { useWizards } from '../../context/WizardsContext';
import { IconButton, Tooltip, Box } from '@mui/material';
import { BusinessCenter } from '@mui/icons-material';

/**
 * Hook para adicionar funcionalidade de integração com Omie ao MediaMessageInput
 * Versão adaptada para usar o WizardsContext
 * 
 * @param {Object} options - Opções de configuração
 * @param {boolean} options.enabled - Se a integração com Omie está habilitada
 * @param {Object} options.clientInfo - Informações do cliente atual
 * @param {string} options.apiKey - Chave da API do Omie
 * @param {string} options.apiSecret - Secret da API do Omie
 * @param {string} options.inputMessage - Valor atual do campo de entrada de mensagem
 * @param {Function} options.setInputMessage - Função para atualizar o valor do campo de entrada de mensagem
 * @param {boolean} options.disabled - Se o componente está desabilitado
 * @param {boolean} options.isLoading - Se está carregando
 * @returns {Object} - Objeto contendo elementos e handlers para a integração com Omie
 */
const useOmieIntegrationWithContext = ({
  enabled = false,
  clientInfo = null,
  apiKey = '',
  apiSecret = '',
  inputMessage = '',
  setInputMessage,
  disabled = false,
  isLoading = false,
}) => {
  const { openOmieWizard } = useWizards();

  // Função para abrir o wizard do Omie
  const handleOpenOmieWizard = () => {
    openOmieWizard({
      clientInfo,
      apiKey,
      apiSecret,
      onDataSelected: (formattedData) => {
        setInputMessage(inputMessage + (inputMessage ? '\n\n' : '') + formattedData);
      }
    });
  };

  // Componente do botão de atalho do Omie
  const OmieShortcutButton = () => {
    if (!enabled) return null;
    
    return (
      <Tooltip title="Adicionar dados do Omie">
        <span>
          <IconButton
            color="primary"
            onClick={handleOpenOmieWizard}
            disabled={disabled || isLoading}
            sx={{ mr: 1 }}
          >
            <BusinessCenter />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  // Componente do item do menu de mídia do Omie
  const OmieMenuItem = ({ onClose }) => {
    if (!enabled) return null;
    
    return (
      <Box
        onClick={() => {
          handleOpenOmieWizard();
          if (onClose) onClose();
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px',
          borderRadius: '8px',
          cursor: 'pointer',
          gridColumn: 'span 2',
          backgroundColor: 'rgba(72, 123, 248, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(72, 123, 248, 0.2)',
          },
        }}
      >
        <BusinessCenter color="primary" />
        <Box sx={{ fontSize: '0.75rem', mt: '4px' }}>Dados Omie</Box>
      </Box>
    );
  };

  return {
    OmieShortcutButton,
    OmieMenuItem,
    handleOpenOmieWizard,
  };
};

export default useOmieIntegrationWithContext;

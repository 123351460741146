import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  InputAdornment,
  Fade,
  CircularProgress,
} from '@mui/material';
import {
  AttachMoney as AttachMoneyIcon,
  ShoppingBasket as ShoppingBasketIcon,
  ShoppingCart as CartIcon,
  Money as MoneyIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { FormSection, SectionTitle, ProductItem, ProductIcon } from './styles';
import { Product, FormErrors } from './types/CardTypes';
import axios from 'axios';
import { llmEndpoint } from 'infra';

interface ApiProduct {
  id: string;
  nome: string;
  descricao?: string;
  valor: number;
  codigo: string;
  categoria?: string | null;
  isServico: boolean;
  active?: boolean;
  estoque?: number | null;
  unidadeMedida?: string | null;
  marca?: string | null;
  fornecedor?: string | null;
  tags?: string | null;
  rpaId?: string;
}

interface ProductsSectionProps {
  value: string;
  errors: FormErrors;
  produtos: Product[];
  searchProdutoTerm: string;
  setSearchProdutoTerm: (term: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
  handleAddProduto: (produto: Product) => void;
  handleRemoveProduto: (produtoId: string) => void;
  calcularValorTotal: () => number;
  onEditProduct?: (productId: string) => void;
}

const ProductsSection: React.FC<ProductsSectionProps> = ({
  value,
  errors,
  produtos,
  searchProdutoTerm,
  setSearchProdutoTerm,
  handleChange,
  handleAddProduto,
  handleRemoveProduto,
  calcularValorTotal,
  onEditProduct,
}) => {
  const [filteredProdutos, setFilteredProdutos] = useState<ApiProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Função para buscar produtos da API
  const fetchProducts = async (term: string) => {
    if (term.length < 2) return;
    
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${llmEndpoint.url}/product/search`,
        {
          params: {
            term,
            page: 0,
            size: 10,
            sort: 'nome',
            direction: 'asc',
          },
          headers: {
            accept: '*/*',
          },
        }
      );
      
      if (response.data && response.data.content) {
        setFilteredProdutos(response.data.content);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setFilteredProdutos([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Função para mapear produtos da API para o formato usado no componente
  const mapApiProductToComponentProduct = (apiProduct: ApiProduct): Product => {
    return {
      id: apiProduct.id,
      nome: apiProduct.nome,
      codigo: apiProduct.codigo,
      valor: apiProduct.valor,
      tipo: apiProduct.isServico ? 'servico' : 'produto',
      // Removido campo descricao que não existe no tipo Product
    };
  };

  // Handle input change with debounce
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchProdutoTerm(term);
    
    // Clear any existing timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    
    // Only start a new search if term has at least 2 characters
    if (term.length >= 2) {
      debounceTimerRef.current = setTimeout(() => {
        fetchProducts(term);
      }, 500); // 500ms debounce
    } else {
      setFilteredProdutos([]);
    }
  };

  // Handle search button/icon click or Enter key press
  const handleSearch = () => {
    if (searchProdutoTerm.length >= 2) {
      // Clear any existing timer
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      fetchProducts(searchProdutoTerm);
    }
  };

  // Handle Enter key press in search input
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return (
    <FormSection>
      <SectionTitle variant="subtitle2" sx={{ fontSize: '14px', mb: 1 }}>
        <AttachMoneyIcon fontSize="small" />
        Valor e Produtos
      </SectionTitle>

      <TextField
        name="value"
        label="Valor"
        fullWidth
        variant="outlined"
        value={value}
        onChange={handleChange}
        error={!!errors.value}
        helperText={
          errors.value ||
          'Valor total (automático se selecionar produtos)'
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">R$</InputAdornment>
          ),
          readOnly: produtos.length > 0,
        }}
        sx={{ mb: 2 }}
      />

      {/* Seleção de produtos/serviços */}
      <SectionTitle
        variant="subtitle2"
        sx={{ fontSize: '14px', mt: 2, mb: 1 }}
      >
        <ShoppingBasketIcon fontSize="small" />
        Produtos e Serviços
      </SectionTitle>

      <TextField
        placeholder="Buscar produto ou serviço..."
        fullWidth
        variant="outlined"
        value={searchProdutoTerm}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={handleSearch}
                edge="start"
                disabled={searchProdutoTerm.length < 2}
                size="small"
              >
                <SearchIcon color={searchProdutoTerm.length < 2 ? "disabled" : "action"} fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        size="small"
        sx={{ mb: 1 }}
      />

      {/* Mensagem informativa sobre requisito de 2 caracteres */}
      {searchProdutoTerm.length > 0 && searchProdutoTerm.length < 2 && (
        <Box sx={{ fontSize: '12px', color: 'text.secondary', mt: 1, mb: 1 }}>
          Digite pelo menos 2 caracteres para buscar
        </Box>
      )}

      {/* Indicador de carregamento */}
      {isLoading && (
        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: 'text.secondary', mt: 1, mb: 1 }}>
          <CircularProgress size={16} sx={{ mr: 1 }} />
          Buscando produtos...
        </Box>
      )}

      {/* Lista de produtos/serviços encontrados */}
      {searchProdutoTerm.length >= 2 && !isLoading && filteredProdutos.length > 0 && (
        <Fade in={!!searchProdutoTerm && searchProdutoTerm.length >= 2 && filteredProdutos.length > 0}>
          <Paper
            variant="outlined"
            sx={{ maxHeight: '150px', overflow: 'auto', mb: 2, position: 'relative', zIndex: 10 }}
          >
            <List dense>
              {filteredProdutos.map((produto) => (
                <ListItem
                  key={produto.id}
                  button
                  onClick={() => {
                    // Converte o produto da API para o formato necessário
                    const formattedProduct = mapApiProductToComponentProduct(produto);
                    // Adiciona o produto à lista
                    handleAddProduto(formattedProduct);
                    // Limpa o campo de busca
                    setSearchProdutoTerm('');
                    // Limpa os resultados
                    setFilteredProdutos([]);
                  }}
                  disabled={produtos.some(
                    (p) => p.id === produto.id,
                  )}
                  sx={{
                    borderRadius: '4px',
                    m: 0.5,
                    opacity: produtos.some(
                      (p) => p.id === produto.id,
                    )
                      ? 0.5
                      : 1,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    {produto.isServico ? (
                      <MoneyIcon
                        fontSize="small"
                        color="secondary"
                      />
                    ) : (
                      <CartIcon
                        fontSize="small"
                        color="primary"
                      />
                    )}
                  </ListItemIcon>

                  <ListItemText
                    primary={produto.nome}
                    secondary={`${produto.codigo} - ${(produto.valor || 0).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      },
                    )}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Fade>
      )}

      {/* Mensagem quando nenhum produto é encontrado */}
      {searchProdutoTerm.length >= 2 && !isLoading && filteredProdutos.length === 0 && (
        <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
          <ListItemText
            primary="Nenhum produto encontrado"
            secondary="Tente outros termos de busca"
            primaryTypographyProps={{
              style: {
                textAlign: 'center',
                color: '#757575',
              },
            }}
            secondaryTypographyProps={{
              style: { textAlign: 'center' },
            }}
          />
        </Paper>
      )}

      {/* Produtos/serviços selecionados */}
      {produtos.length > 0 ? (
        <Box>
          <Paper
            variant="outlined"
            sx={{ p: 1, borderRadius: '8px' }}
          >
            <List dense disablePadding>
              {produtos.map((produto) => (
                <ProductItem
                  key={produto.id}
                  disableGutters
                  divider
                  sx={{
                    cursor: onEditProduct ? 'pointer' : 'default',
                    '&:hover': {
                      backgroundColor: onEditProduct ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                    }
                  }}
                  onClick={onEditProduct ? () => onEditProduct(produto.id) : undefined}
                >
                  <ProductIcon productType={produto.tipo}>
                    {produto.tipo === 'produto' ? (
                      <CartIcon fontSize="small" />
                    ) : (
                      <MoneyIcon fontSize="small" />
                    )}
                  </ProductIcon>

                  <ListItemText
                    primary={produto.nome}
                    secondary={`${produto.codigo} - ${(produto.valor || 0).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      },
                    )}`}
                  />

                  <ListItemSecondaryAction>
                    {onEditProduct && (
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditProduct(produto.id);
                        }}
                        sx={{ color: '#3f51b5', mr: 1 }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveProduto(produto.id);
                      }}
                      sx={{ color: '#f44336' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ProductItem>
              ))}

              <Box
                sx={{
                  backgroundColor: '#f5f9ff',
                  borderRadius: '0 0 8px 8px',
                  p: 1.5,
                  mt: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 600,
                  }}
                >
                  <span>Valor Total</span>
                  <span>
                    {(calcularValorTotal() || 0).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </Typography>
              </Box>
            </List>
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: 3,
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            border: '1px dashed #ccc',
          }}
        >
          <ShoppingBasketIcon
            sx={{ fontSize: 40, color: '#bdbdbd', mb: 1 }}
          />
          <Typography
            variant="body2"
            sx={{ color: '#757575', textAlign: 'center' }}
          >
            Nenhum produto ou serviço selecionado
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: '#9e9e9e', textAlign: 'center', mt: 0.5 }}
          >
            Use a busca acima para adicionar itens
          </Typography>
        </Box>
      )}
    </FormSection>
  );
};

export default ProductsSection;
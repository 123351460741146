import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material'
import {
  WhatsApp as WhatsAppIcon,
  Email as EmailIcon,
  MoreVert as MoreVertIcon,
  AccessTime as ClockIcon,
  AttachMoney as MoneyIcon,
  Warning as WarningIcon,
} from '@mui/icons-material'

import ActionMenu from './ActionMenu'
import MessageDialog from './MessageDialog'

// Função auxiliar para formatar data
const formatDate = (dateString) => {
  const date = new Date(dateString)
  return new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date)
}

const KanbanCard = ({ card, index, onClick, onAction }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [messageDialogOpen, setMessageDialogOpen] = useState(false)
  const [messageType, setMessageType] = useState(null)

  const handleMenuOpen = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuAction = (action) => {
    handleMenuClose()

    if (action === 'whatsapp' || action === 'email') {
      setMessageType(action)
      setMessageDialogOpen(true)
    } else {
      onAction(action)
    }
  }

  const handleMessageDialogClose = () => {
    setMessageDialogOpen(false)
    setMessageType(null)
  }

  // Determina a cor da prioridade
  const getPriorityColor = (priority) => {
    switch (priority?.toLowerCase()) {
      case 'alta':
        return 'error'
      case 'média':
        return 'warning'
      case 'baixa':
        return 'success'
      default:
        return 'default'
    }
  }

  // Função para formatar valor em reais
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value)
  }

  // Renderiza informações específicas com base no tipo de card
  const renderCardInfo = () => {
    if (card.type === 'lead') {
      return (
        <>
          {card.value && (
            <Box className="flex items-center mt-2">
              <MoneyIcon fontSize="small" className="text-green-600 mr-1" />
              <Typography
                variant="body2"
                className="text-green-600 font-medium"
              >
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(card.value)}
              </Typography>
            </Box>
          )}
        </>
      )
    } else if (card.type === 'inadimplencia') {
      return (
        <>
          {card.diasAtraso && (
            <Box className="flex items-center mt-2">
              <WarningIcon
                fontSize="small"
                className={`mr-1 ${
                  card.diasAtraso > 30 ? 'text-red-600' : 'text-amber-500'
                }`}
              />
              <Typography
                variant="body2"
                className={`font-medium ${
                  card.diasAtraso > 30 ? 'text-red-600' : 'text-amber-500'
                }`}
              >
                {card.diasAtraso} dias em atraso
              </Typography>
            </Box>
          )}
        </>
      )
    }
    return null
  }

  return (
    <>
      <Draggable draggableId={card.id} index={index}>
        {(provided, snapshot) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`mb-2 cursor-pointer hover:shadow-md transition-shadow ${
              snapshot.isDragging ? 'shadow-lg' : 'shadow-sm'
            }`}
            onClick={onClick}
            style={{
              ...provided.draggableProps.style,
              backgroundColor: snapshot.isDragging ? '#f1f5f9' : 'white',
              borderLeft: card.priority
                ? `4px solid ${
                    card.priority.toLowerCase() === 'alta'
                      ? '#ef4444'
                      : card.priority.toLowerCase() === 'média'
                        ? '#f97316'
                        : '#22c55e'
                  }`
                : '4px solid #e5e7eb',
            }}
          >
            <CardContent className="p-3">
              {/* Cabeçalho do card */}
              <Box className="flex justify-between items-start mb-2">
                <Box>
                  <Typography variant="subtitle2" className="font-medium">
                    {card.title}
                  </Typography>

                  {card.company && (
                    <Typography
                      variant="caption"
                      className="text-gray-500 block"
                    >
                      {card.company}
                    </Typography>
                  )}

                  {/* Removido Chip de valor duplicado */}

                  {card.erpName && (
                    <Chip
                      label={card.erpName}
                      color="error"
                      size="small"
                      sx={{
                        fontWeight: 'bold',
                        '& .MuiChip-label': { fontWeight: 600 },
                      }}
                    />
                  )}
                </Box>

                <Box className="flex">
                  <Tooltip title="Mais ações">
                    <IconButton
                      size="small"
                      onClick={handleMenuOpen}
                      className="ml-1"
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {/* Conteúdo específico por tipo de card */}
              {renderCardInfo()}

              {/* Informações de contato */}
              {card.contact && (
                <Box className="mt-2">
                  <Typography variant="body2">{card.contact.name}</Typography>

                  <Box className="flex mt-1">
                    {card.contact.whatsapp && (
                      <Tooltip title="Enviar WhatsApp">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation()
                            setMessageType('whatsapp')
                            setMessageDialogOpen(true)
                          }}
                        >
                          <WhatsAppIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}

                    {card.contact.email && (
                      <Tooltip title="Enviar Email">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation()
                            setMessageType('email')
                            setMessageDialogOpen(true)
                          }}
                        >
                          <EmailIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              )}

              {/* Rodapé do card */}
              <Box className="flex justify-between items-center mt-3 pt-2 border-t border-gray-100">
                {/* Data de atualização */}
                {card.updatedAt && (
                  <Tooltip title="Última atualização">
                    <Box className="flex items-center">
                      <ClockIcon
                        fontSize="small"
                        className="text-gray-400 mr-1"
                      />
                      <Typography variant="caption" className="text-gray-500">
                        {formatDate(card.updatedAt)}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}

                {/* Usuário responsável */}
                {card.assignedTo && (
                  <Tooltip title={`Responsável: ${card.assignedTo.name}`}>
                    <Avatar
                      src={card.assignedTo.avatar}
                      alt={card.assignedTo.name}
                      className="w-6 h-6 ml-auto"
                    >
                      {card.assignedTo.name.charAt(0)}
                    </Avatar>
                  </Tooltip>
                )}
              </Box>
            </CardContent>
          </Card>
        )}
      </Draggable>

      {/* Menu de ações */}
      <ActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onAction={handleMenuAction}
        card={card}
      />

      {/* Dialog para envio de mensagens */}
      <MessageDialog
        open={messageDialogOpen}
        onClose={handleMessageDialogClose}
        type={messageType}
        contact={card.contact}
      />
    </>
  )
}

export default KanbanCard

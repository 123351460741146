// src/types/index.ts

/**
 * Interface para módulo de integração
 */
export interface IntegrationModule {
  id: string;
  key: string;
  name: string;
  active: boolean;
}

/**
 * Interface para vendedores disponíveis de ERP
 */
export interface ERPVendor {
  id: string;
  name: string;
  logo?: string;
}

/**
 * Array de módulos disponíveis para integração
 */
export const AVAILABLE_MODULES: IntegrationModule[] = [
  { id: "", key: "product", name: "Produtos", active: false },
  { id: "", key: "customer", name: "Clientes", active: false },
  { id: "", key: "order", name: "Pedidos", active: false },
  { id: "", key: "financial", name: "Financeiro", active: false },
];

/**
 * Array de vendedores disponíveis de ERP
 */
export const AVAILABLE_VENDORS: ERPVendor[] = [
  { id: "omie", name: "Omie" },
  { id: "bling", name: "Bling" },
  { id: "nfe", name: "NFe.io" },
  { id: "tiny", name: "Tiny ERP" },
];

/**
 * Interface para configuração de lembretes de inadimplência
 */
export interface DebtReminderConfig {
  days: number;
  enabled: boolean;
  whatsappTemplateId: string;
  emailTemplateId: string;
  channel: "whatsapp" | "email" | "both";
}

/**
 * Interface para dados de ERP
 */
export interface ERP {
  id: string;
  vendor: string;
  name: string;
  description?: string;
  appKey: string;
  appSecret: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  modules: IntegrationModule[];
  leadFunnelIntegration: boolean;
  debtFunnelIntegration: boolean;
  debtReminderConfig?: DebtReminderConfig[];
}

/**
 * Props para o componente de formulário de ERP
 */
export interface ERPFormProps {
  erp?: ERP;
  isEdit: boolean;
  onSave: (erp: ERP) => void;
  onDelete: (id: string) => void;
  onCancel: () => void;
  onUpdateCredentials: (id: string, appKey: string, appSecret: string) => void;
  loading: boolean;
  error?: string;
}

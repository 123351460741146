import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Tab,
  Tabs,
  Switch,
  FormControlLabel,
  Paper,
  Chip,
  Tooltip,
  InputAdornment,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Alert,
  Card,
  CardContent,
  RadioGroup,
  Radio,
  styled,
  AlertTitle,
  Avatar,
  Badge,
  Autocomplete,
} from '@mui/material'
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  DragIndicator as DragIcon,
  Save as SaveIcon,
  IntegrationInstructions as IntegrationIcon,
  ViewColumn as ColumnIcon,
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
  Link as LinkIcon,
  Settings as SettingsIcon,
  DoneAll as DoneAllIcon,
  Person as PersonIcon,
  People as PeopleIcon,
  Shuffle as ShuffleIcon,
  CompareArrows as DistributeIcon,
  Timeline as ProcessIcon,
  LoopOutlined as RotationIcon,
  StackedBarChart as LoadIcon,
  LowPriority as PriorityIcon,
  PersonAdd as PersonAddIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@mui/icons-material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { llmEndpoint } from 'infra'
import { useParams } from 'react-router-dom'

interface ColumnType {
  id: string
  title: string
  color?: string
  closed?: boolean
  wip?: number
  isDone?: boolean
  assignedTo?: User[]
  integrations?: Array<any>
  distributionStrategy?: string
  [key: string]: any
}

interface IntegrationType {
  id: string
  name: string
  type: string
  description?: string
  status: string
}

interface BoardConfigType {
  id: string
  title: string
  type: string
  columns: ColumnType[]
  [key: string]: any
}

interface User {
  id: string
  name: string
  avatar?: string
  email?: string
  role?: string
}

interface KanbanSettingsProps {
  open: boolean
  onClose: () => void
  boardConfig: BoardConfigType | null
  onSaveConfig: (config: BoardConfigType) => void
  availableUsers?: User[]
}

// Tipos de integração disponíveis
const integrationTypes = [
  { value: 'webhook', label: 'Webhook' },
  { value: 'erp', label: 'Sistema ERP' },
  { value: 'invoice', label: 'Faturamento' },
  { value: 'payment', label: 'Pagamento' },
  { value: 'email', label: 'Email' },
  { value: 'sms', label: 'SMS' },
  { value: 'crm', label: 'CRM' },
]

// Estratégias de distribuição disponíveis
const distributionStrategies = [
  {
    value: 'round-robin',
    label: 'Rodízio',
    description:
      'Cards são distribuídos sequencialmente entre os responsáveis, em ciclo contínuo',
    icon: <RotationIcon />,
  },
  {
    value: 'load-balance',
    label: 'Carga balanceada',
    description:
      'Cards são atribuídos ao responsável com menor número de cards ativos',
    icon: <LoadIcon />,
  },
  {
    value: 'random',
    label: 'Aleatório',
    description: 'Cards são atribuídos aleatoriamente entre os responsáveis',
    icon: <ShuffleIcon />,
  },
  {
    value: 'manual',
    label: 'Manual',
    description: 'Sem distribuição automática, atribuição manual de cards',
    icon: <PersonIcon />,
  },
  {
    value: 'priority',
    label: 'Por prioridade',
    description: 'Responsáveis com maior prioridade recebem os cards primeiro',
    icon: <PriorityIcon />,
  },
]

// Dados mockados de usuários (para demonstração)
const mockUsers: User[] = [
  {
    id: 'user-1',
    name: 'João Silva',
    email: 'joao@empresa.com',
    avatar: 'https://i.pravatar.cc/150?img=1',
    role: 'Desenvolvedor',
  },
  {
    id: 'user-2',
    name: 'Maria Santos',
    email: 'maria@empresa.com',
    avatar: 'https://i.pravatar.cc/150?img=5',
    role: 'Gerente',
  },
  {
    id: 'user-3',
    name: 'Pedro Oliveira',
    email: 'pedro@empresa.com',
    avatar: 'https://i.pravatar.cc/150?img=3',
    role: 'Analista',
  },
  {
    id: 'user-4',
    name: 'Ana Costa',
    email: 'ana@empresa.com',
    avatar: 'https://i.pravatar.cc/150?img=4',
    role: 'Desenvolvedor',
  },
  {
    id: 'user-5',
    name: 'Carlos Souza',
    email: 'carlos@empresa.com',
    avatar: 'https://i.pravatar.cc/150?img=6',
    role: 'Suporte',
  },
  {
    id: 'user-6',
    name: 'Luciana Ferreira',
    email: 'luciana@empresa.com',
    avatar: 'https://i.pravatar.cc/150?img=7',
    role: 'Vendas',
  },
  {
    id: 'user-7',
    name: 'Rafael Mendes',
    email: 'rafael@empresa.com',
    avatar: 'https://i.pravatar.cc/150?img=8',
    role: 'Suporte',
  },
]

// Componente para as abas
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  )
}

// Componente estilizado para título de seção
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  marginBottom: '12px',
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
    fontSize: '18px',
  },
}))

const KanbanSettings: React.FC<KanbanSettingsProps> = ({
  open,
  onClose,
  boardConfig,
  onSaveConfig,
  availableUsers = mockUsers, // Usar dados mockados por padrão
}) => {
  console.log(boardConfig)
  const [activeTab, setActiveTab] = useState(0)
  const [localConfig, setLocalConfig] = useState<BoardConfigType | null>(null)
  const [saving, setSaving] = useState(false)
  const [editingColumn, setEditingColumn] = useState<string | null>(null)
  const [newColumnTitle, setNewColumnTitle] = useState('')
  const [newColumnColor, setNewColumnColor] = useState('#e0e0e0')
  const [newIntegration, setNewIntegration] = useState({
    id: '',
    name: '',
    type: 'webhook',
    description: '',
    status: 'active',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [doneColumnId, setDoneColumnId] = useState<string | null>(null)

  // Inicializar estado local com as props
  useEffect(() => {
    if (boardConfig) {
      const configWithDefaults = JSON.parse(JSON.stringify(boardConfig))

      // Garantir que cada coluna tenha os campos necessários
      configWithDefaults.columns = configWithDefaults.columns.map(
        (col: ColumnType) => ({
          ...col,
          assignedTo: Array.isArray(col.assignedTo) ? col.assignedTo : [],
          distributionStrategy: col.distributionStrategy || 'manual',
        }),
      )

      setLocalConfig(configWithDefaults)

      // Encontrar a coluna marcada como "done"
      const doneColumn = configWithDefaults.columns.find(
        (col: ColumnType) => col.closed,
      )
      setDoneColumnId(doneColumn?.id || null)
    }
  }, [boardConfig, open])

  // Manipuladores para mudança de aba
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  // Manipulador para salvar todas as configurações
  const handleSaveConfig = () => {
    if (!localConfig) return

    setSaving(true)

    // Simulando uma operação assíncrona
    setTimeout(() => {
      onSaveConfig(localConfig)
      setSaving(false)
      onClose()
    }, 800)
  }

  // Manipuladores para edição de colunas
  const handleEditColumn = (columnId: string) => {
    if (!localConfig) return

    const column = localConfig.columns.find((col) => col.id === columnId)
    if (column) {
      setEditingColumn(columnId)
      setNewColumnTitle(column.title)
      setNewColumnColor(column.color || '#e0e0e0')
    }
  }

  const { rpaId } = useParams()

  const handleAddColumn = async () => {
    if (!localConfig) return
    // Set loading state
    setSaving(true)
    try {
      // Generate a temporary ID for optimistic UI update
      const tempColumnId = `col-${Date.now()}`
      // Prepare request payload
      const payload = {
        title: 'Nova Coluna',
        color: '#e0e0e0',
        wip: 100,
        position: localConfig.columns.length, // Add at the end
        // Optionally assign if you want to set a default assignee
        // assignedToId: "your-default-assignee-id"
      }
      
      // Optimistic UI update
      setLocalConfig({
        ...localConfig,
        columns: [
          ...localConfig.columns,
          {
            id: tempColumnId,
            title: payload.title,
            color: payload.color,
            integrations: [],
            assignedTo: [],
            distributionStrategy: 'manual',
          },
        ],
      })
      
      // Make API call
      const response = await fetch(
        `${llmEndpoint.url}/kanban/boards/${rpaId}/${boardConfig.id}/columns`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
          body: JSON.stringify(payload),
        },
      )
      
      if (!response.ok) {
        throw new Error(`API responded with status: ${response.status}`)
      }
      
      // Parse the response to get the newly created column data with real ID
      const newColumn = await response.json()
      
      // CORREÇÃO: Use prevState para garantir que temos o estado mais recente
      setLocalConfig(prevConfig => {
        if (!prevConfig) return prevConfig;
        
        return {
          ...prevConfig,
          columns: prevConfig.columns.map((col) =>
            col.id === tempColumnId
              ? {
                  ...col,
                  id: newColumn.id,
                  // Mantenha todas as propriedades originais e adicione as novas da API
                  ...newColumn,
                }
              : col
          ),
        };
      });
      
      // Adicione um pequeno delay antes de editar a coluna
      // Isso dá tempo para o estado ser atualizado completamente
      setTimeout(() => {
        handleEditColumn(newColumn.id);
      }, 100);
      
    } catch (error) {
      console.error('Error creating new column:', error)
      setErrorMessage('Erro ao adicionar coluna. Por favor, tente novamente.')
      
      // CORREÇÃO: Use prevState para remover corretamente a coluna temporária
      setLocalConfig(prevConfig => {
        if (!prevConfig) return prevConfig;
        
        return {
          ...prevConfig,
          columns: prevConfig.columns.filter(
            (col) => !col.id.startsWith('col-')
          ),
        };
      });
    } finally {
      setSaving(false)
    }
  }

  const handleUpdateColumn = async (columnId) => {
    if (!localConfig) return

    setSaving(true)
    try {
      // Find the column to be updated
      const column = localConfig.columns.find((col) => col.id === columnId)
      if (!column) throw new Error('Column not found')

      // Update UI immediately (optimistic update)
      setLocalConfig({
        ...localConfig,
        columns: localConfig.columns.map((col) =>
          col.id === columnId
            ? { ...col, title: newColumnTitle, color: newColumnColor }
            : col,
        ),
      })

      // Format the request payload
      const payload = {
        title: newColumnTitle,
        color: newColumnColor,
        wip: column.wip || 0,
        position: localConfig.columns.findIndex((col) => col.id === columnId),
        // Include other required fields
      }

      // Make API call to update the column
      const response = await fetch(
        `${llmEndpoint.url}/kanban/boards/${rpaId}/${boardConfig.id}/columns/${columnId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
          body: JSON.stringify(payload),
        },
      )

      if (!response.ok) {
        throw new Error(`API responded with status: ${response.status}`)
      }
    } catch (error) {
      console.error('Error updating column:', error)
      setErrorMessage('Erro ao atualizar coluna. Por favor, tente novamente.')

      // Revert changes if there was an error
      // This would require keeping a copy of the previous state
    } finally {
      setSaving(false)
      setEditingColumn(null)
    }
  }

  const handleDeleteColumn = async (columnId: string) => {
    if (!localConfig) return

    // Não permitir excluir se tiver apenas uma coluna
    if (localConfig.columns.length <= 1) {
      setErrorMessage('O quadro deve ter pelo menos uma coluna')
      return
    }

    setLocalConfig({
      ...localConfig,
      columns: localConfig.columns.filter((col) => col.id !== columnId),
    })

    const response = await fetch(
      `${llmEndpoint.url}/kanban/boards/${rpaId}/${boardConfig.id}/columns/${columnId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*',
        }
      },
    )

    if (!response.ok) {
      throw new Error(`API responded with status: ${response.status}`)
    }

    // Se a coluna excluída for a coluna "done", reset o estado
    if (columnId === doneColumnId) {
      setDoneColumnId(null)
    }
  }

  // Manipulador para reordenar colunas
  const handleDragEnd = (result: any) => {
    if (!result.destination || !localConfig) return

    const { source, destination } = result
    const columns = Array.from(localConfig.columns)
    const [removed] = columns.splice(source.index, 1)
    columns.splice(destination.index, 0, removed)

    setLocalConfig({
      ...localConfig,
      columns: columns,
    })
  }

  // Manipulador para definir a coluna "done"
  const handleSetDoneColumn = (columnId: string) => {
    if (!localConfig) return

    setDoneColumnId(columnId)

    setLocalConfig({
      ...localConfig,
      columns: localConfig.columns.map((col) => ({
        ...col,
        isDone: col.id === columnId,
      })),
    })
  }

  // Manipuladores para integrações
  const handleAddIntegration = (columnId: string) => {
    if (!localConfig) return

    const newIntegrationWithId = {
      ...newIntegration,
      id: `integration-${Date.now()}`,
    }

    setLocalConfig({
      ...localConfig,
      columns: localConfig.columns.map((col) =>
        col.id === columnId
          ? {
              ...col,
              integrations: [...(col.integrations || []), newIntegrationWithId],
            }
          : col,
      ),
    })

    // Reset o formulário
    setNewIntegration({
      id: '',
      name: '',
      type: 'webhook',
      description: '',
      status: 'active',
    })
  }

  const handleDeleteIntegration = (columnId: string, integrationId: string) => {
    if (!localConfig) return

    setLocalConfig({
      ...localConfig,
      columns: localConfig.columns.map((col) =>
        col.id === columnId
          ? {
              ...col,
              integrations: (col.integrations || []).filter(
                (integration) => integration.id !== integrationId,
              ),
            }
          : col,
      ),
    })
  }

  // Manipuladores para responsáveis
  const handleAddAssignee = (columnId: string, user: User) => {
    if (!localConfig) return

    setLocalConfig({
      ...localConfig,
      columns: localConfig.columns.map((col) =>
        col.id === columnId
          ? {
              ...col,
              assignedTo: [...(col.assignedTo || []), user],
            }
          : col,
      ),
    })
  }

  const handleRemoveAssignee = (columnId: string, userId: string) => {
    if (!localConfig) return

    setLocalConfig({
      ...localConfig,
      columns: localConfig.columns.map((col) =>
        col.id === columnId
          ? {
              ...col,
              assignedTo: (col.assignedTo || []).filter(
                (user) => user.id !== userId,
              ),
            }
          : col,
      ),
    })
  }

  // Manipuladores para estratégia de distribuição
  const handleChangeDistributionStrategy = (
    columnId: string,
    strategy: string,
  ) => {
    if (!localConfig) return

    setLocalConfig({
      ...localConfig,
      columns: localConfig.columns.map((col) =>
        col.id === columnId
          ? {
              ...col,
              distributionStrategy: strategy,
            }
          : col,
      ),
    })
  }

  // Filtrar usuários que ainda não foram atribuídos a uma coluna específica
  const getAvailableUsersForColumn = (columnId: string) => {
    if (!localConfig) return availableUsers

    const column = localConfig.columns.find((col) => col.id === columnId)
    if (!column) return availableUsers

    const assignedIds = new Set(
      (column.assignedTo || []).map((user) => user.id),
    )
    return availableUsers.filter((user) => !assignedIds.has(user.id))
  }

  if (!localConfig) return null

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#f8f9fa',
          borderBottom: '1px solid #e0e0e0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
          <SettingsIcon sx={{ mr: 1 }} />
          Configurações do Quadro
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ px: 2 }}>
          <Tab
            icon={<ColumnIcon />}
            label="Colunas"
            iconPosition="start"
            sx={{
              textTransform: 'none',
              minHeight: '48px',
              fontWeight: activeTab === 0 ? 600 : 400,
              px: 3,
              mr: 1,
            }}
          />
          {/* <Tab
            icon={<PeopleIcon />}
            label="Responsáveis"
            iconPosition="start"
            sx={{
              textTransform: 'none',
              minHeight: '48px',
              fontWeight: activeTab === 1 ? 600 : 400,
              px: 3,
              mr: 1,
            }}
          /> */}
          {/* <Tab
            icon={<IntegrationIcon />}
            label="Integrações"
            iconPosition="start"
            sx={{
              textTransform: 'none',
              minHeight: '48px',
              fontWeight: activeTab === 2 ? 600 : 400,
              px: 3,
              mr: 1,
            }}
          /> */}
          <Tab
            icon={<DoneAllIcon />}
            label="Estado Final"
            iconPosition="start"
            sx={{
              textTransform: 'none',
              minHeight: '48px',
              fontWeight: activeTab === 3 ? 600 : 400,
              px: 3,
            }}
          />
        </Tabs>
      </Box>

      <DialogContent sx={{ px: 3, py: 0 }}>
        {errorMessage && (
          <Alert
            severity="error"
            sx={{ mb: 2 }}
            onClose={() => setErrorMessage('')}
          >
            {errorMessage}
          </Alert>
        )}

        {/* Aba de Colunas */}
        <TabPanel value={activeTab} index={0}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddColumn}
            >
              Adicionar Coluna
            </Button>
          </Box>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="columns">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {localConfig.columns.map((column, index) => (
                    <Draggable
                      key={column.id}
                      draggableId={column.id}
                      index={index}
                    >
                      {(provided) => (
                        <Paper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          elevation={1}
                          sx={{
                            p: 2,
                            mb: 2,
                            borderRadius: '8px',
                            borderLeft: `4px solid ${column.color || '#e0e0e0'}`,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              {...provided.dragHandleProps}
                              sx={{
                                mr: 2,
                                color: 'text.secondary',
                                cursor: 'grab',
                              }}
                            >
                              <DragIcon />
                            </Box>

                            {editingColumn === column.id ? (
                              <Box sx={{ flex: 1, display: 'flex', gap: 2 }}>
                                <TextField
                                  fullWidth
                                  value={newColumnTitle}
                                  onChange={(e) =>
                                    setNewColumnTitle(e.target.value)
                                  }
                                  label="Nome da coluna"
                                  size="small"
                                  autoFocus
                                />
                                <input
                                  type="color"
                                  value={newColumnColor}
                                  onChange={(e) =>
                                    setNewColumnColor(e.target.value)
                                  }
                                  style={{
                                    width: '40px',
                                    height: '40px',
                                    padding: 0,
                                    border: 'none',
                                  }}
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => handleUpdateColumn(column.id)}
                                  startIcon={<SaveIcon />}
                                >
                                  Salvar
                                </Button>
                              </Box>
                            ) : (
                              <>
                                <Box sx={{ flex: 1 }}>
                                  <Typography variant="subtitle1">
                                    {column.title}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      mt: 0.5,
                                      flexWrap: 'wrap',
                                      gap: 0.5,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: 16,
                                        height: 16,
                                        borderRadius: '50%',
                                        bgcolor: column.color || '#e0e0e0',
                                        mr: 1,
                                      }}
                                    />

                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      sx={{ mr: 1 }}
                                    >
                                      {column.assignedTo?.length || 0}{' '}
                                      responsáveis
                                    </Typography>

                                    {column.distributionStrategy && (
                                      <Chip
                                        icon={
                                          distributionStrategies.find(
                                            (s) =>
                                              s.value ===
                                              column.distributionStrategy,
                                          )?.icon
                                        }
                                        label={
                                          distributionStrategies.find(
                                            (s) =>
                                              s.value ===
                                              column.distributionStrategy,
                                          )?.label || 'Manual'
                                        }
                                        size="small"
                                        sx={{
                                          height: '20px',
                                          fontSize: '0.7rem',
                                          mr: 1,
                                        }}
                                      />
                                    )}

                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      sx={{ mr: 1 }}
                                    >
                                      {column.integrations?.length || 0}{' '}
                                      integrações
                                    </Typography>

                                    {column.isDone && (
                                      <Chip
                                        icon={<CheckCircleIcon />}
                                        label="Coluna Final"
                                        size="small"
                                        color="success"
                                      />
                                    )}
                                  </Box>
                                </Box>

                                <IconButton
                                  onClick={() => handleEditColumn(column.id)}
                                  size="small"
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>

                                <IconButton
                                  onClick={() => handleDeleteColumn(column.id)}
                                  size="small"
                                  color="error"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </Box>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </TabPanel>

        {/* Aba de Responsáveis */}
        {/* <TabPanel value={activeTab} index={1}>
          {localConfig.columns.map((column) => (
            <Card
              key={column.id}
              variant="outlined"
              sx={{
                mb: 3,
                borderRadius: '8px',
                borderLeft: `4px solid ${column.color || '#e0e0e0'}`,
              }}
            >
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 2,
                    pb: 1,
                    borderBottom: '1px solid #e0e0e0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      borderRadius: '50%',
                      bgcolor: column.color || '#e0e0e0',
                      mr: 1,
                    }}
                  />
                  {column.title}
                </Typography>

                <Box sx={{ mb: 3 }}>
                  <SectionTitle>
                    <DistributeIcon fontSize="small" />
                    Estratégia de Distribuição
                  </SectionTitle>

                  <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                    <InputLabel>Estratégia</InputLabel>
                    <Select
                      value={column.distributionStrategy || 'manual'}
                      label="Estratégia"
                      onChange={(e) =>
                        handleChangeDistributionStrategy(
                          column.id,
                          e.target.value,
                        )
                      }
                    >
                      {distributionStrategies.map((strategy) => (
                        <MenuItem key={strategy.value} value={strategy.value}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {React.cloneElement(strategy.icon, {
                              fontSize: 'small',
                              sx: { mr: 1 },
                            })}
                            {strategy.label}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {distributionStrategies.find(
                      (s) => s.value === column.distributionStrategy,
                    )?.description ||
                      'Sem distribuição automática. Os cards precisam ser atribuídos manualmente.'}
                  </Typography>
                </Box>

                <SectionTitle>
                  <PeopleIcon fontSize="small" />
                  Responsáveis pela Coluna
                </SectionTitle>

                {(column.assignedTo || []).length > 0 ? (
                  <Box sx={{ mb: 3 }}>
                    <List>
                      {(column.assignedTo || []).map((user: User) => (
                        <ListItem
                          key={user.id}
                          sx={{
                            py: 1,
                            px: 2,
                            border: '1px solid #e0e0e0',
                            borderRadius: '4px',
                            mb: 1,
                          }}
                        >
                          <Avatar
                            src={user.avatar}
                            alt={user.name}
                            sx={{ mr: 2, width: 32, height: 32 }}
                          >
                            {user.name.charAt(0)}
                          </Avatar>
                          <ListItemText
                            primary={user.name}
                            secondary={user.email || user.role || ''}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() =>
                                handleRemoveAssignee(column.id, user.id)
                              }
                              color="error"
                            >
                              <RemoveCircleIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      py: 2,
                      border: '1px dashed #ccc',
                      borderRadius: '4px',
                      mb: 3,
                    }}
                  >
                    <InfoIcon sx={{ color: '#9e9e9e', mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      Nenhum responsável atribuído
                    </Typography>
                  </Box>
                )}

                <Box sx={{ mb: 1 }}>
                  <Autocomplete
                    id={`assignee-select-${column.id}`}
                    options={getAvailableUsersForColumn(column.id)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Adicionar responsável"
                        size="small"
                        placeholder="Buscar usuário..."
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                <PersonAddIcon color="action" />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={option.avatar}
                            alt={option.name}
                            sx={{ mr: 2, width: 24, height: 24 }}
                          >
                            {option.name.charAt(0)}
                          </Avatar>
                          <Box>
                            <Typography variant="body2">
                              {option.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {option.role || option.email || ''}
                            </Typography>
                          </Box>
                        </Box>
                      </li>
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        handleAddAssignee(column.id, value)
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={null}
                  />
                </Box>
              </CardContent>
            </Card>
          ))}
        </TabPanel> */}

        {/* Aba de Integrações */}
        {/* <TabPanel value={activeTab} index={2}>
          <Grid container spacing={3}>
            {localConfig.columns.map((column) => (
              <Grid item xs={12} md={6} key={column.id}>
                <Card
                  variant="outlined"
                  sx={{
                    mb: 2,
                    borderRadius: '8px',
                    borderLeft: `4px solid ${column.color || '#e0e0e0'}`,
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mb: 2,
                        pb: 1,
                        borderBottom: '1px solid #e0e0e0',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          bgcolor: column.color || '#e0e0e0',
                          mr: 1,
                        }}
                      />
                      {column.title}
                    </Typography>

                    <List>
                      {(column.integrations || []).length > 0 ? (
                        (column.integrations || []).map(
                          (integration: IntegrationType) => (
                            <ListItem
                              key={integration.id}
                              sx={{
                                py: 1,
                                px: 2,
                                border: '1px solid #e0e0e0',
                                borderRadius: '4px',
                                mb: 1,
                              }}
                            >
                              <ListItemText
                                primary={integration.name}
                                secondary={
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      mt: 0.5,
                                    }}
                                  >
                                    <Chip
                                      label={integration.type}
                                      size="small"
                                      sx={{ mr: 1, height: '20px' }}
                                    />
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      {integration.description}
                                    </Typography>
                                  </Box>
                                }
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  size="small"
                                  onClick={() =>
                                    handleDeleteIntegration(
                                      column.id,
                                      integration.id,
                                    )
                                  }
                                  color="error"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ),
                        )
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            py: 2,
                            border: '1px dashed #ccc',
                            borderRadius: '4px',
                          }}
                        >
                          <InfoIcon sx={{ color: '#9e9e9e', mr: 1 }} />
                          <Typography variant="body2" color="text.secondary">
                            Sem integrações
                          </Typography>
                        </Box>
                      )}
                    </List>

                    <Box sx={{ mt: 2 }}>
                      <SectionTitle>
                        <AddIcon fontSize="small" />
                        Nova Integração
                      </SectionTitle>

                      <Grid container spacing={2} sx={{ mb: 1 }}>
                        <Grid item xs={7}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Nome"
                            value={newIntegration.name}
                            onChange={(e) =>
                              setNewIntegration({
                                ...newIntegration,
                                name: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth size="small">
                            <InputLabel>Tipo</InputLabel>
                            <Select
                              value={newIntegration.type}
                              label="Tipo"
                              onChange={(e) =>
                                setNewIntegration({
                                  ...newIntegration,
                                  type: e.target.value,
                                })
                              }
                            >
                              {integrationTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                  {type.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Descrição"
                            value={newIntegration.description}
                            onChange={(e) =>
                              setNewIntegration({
                                ...newIntegration,
                                description: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      </Grid>

                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => handleAddIntegration(column.id)}
                        disabled={!newIntegration.name}
                        startIcon={<AddIcon />}
                      >
                        Adicionar
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel> */}

        {/* Aba de Estado Final */}
        <TabPanel value={activeTab} index={1}>
          <Box sx={{ mb: 3 }}>
            <Alert severity="info" sx={{ mb: 2 }}>
              <AlertTitle>Definição da Coluna Final</AlertTitle>A coluna final
              representa o estágio concluído do seu fluxo de trabalho. Cards
              nesta coluna serão considerados finalizados para fins de
              relatórios e automações.
            </Alert>

            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Selecione a coluna que representa o estágio final:
            </Typography>

            <RadioGroup
              value={doneColumnId || ''}
              onChange={(e) => handleSetDoneColumn(e.target.value)}
            >
              {localConfig.columns.map((column) => (
                <FormControlLabel
                  key={column.id}
                  value={column.id}
                  control={<Radio color="primary" />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          bgcolor: column.color || '#e0e0e0',
                          mr: 1,
                        }}
                      />
                      <Typography variant="body1">{column.title}</Typography>
                      {column.closed && (
                        <Chip
                          icon={<CheckCircleIcon />}
                          label="Atual"
                          size="small"
                          color="success"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px',
                    p: 1,
                    mb: 1,
                    backgroundColor:
                      column.id === doneColumnId
                        ? 'rgba(46, 125, 50, 0.08)'
                        : 'transparent',
                  }}
                />
              ))}
            </RadioGroup>
          </Box>

          {/* <Box sx={{ mt: 4 }}>
            <SectionTitle>
              <DoneAllIcon fontSize="small" />
              Ações Automáticas na Conclusão
            </SectionTitle>

            <Paper variant="outlined" sx={{ p: 2, borderRadius: '8px' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={localConfig.autoArchive || false}
                    onChange={(e) =>
                      setLocalConfig({
                        ...localConfig,
                        autoArchive: e.target.checked,
                      })
                    }
                  />
                }
                label="Arquivar automaticamente cards após 30 dias na coluna final"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={localConfig.autoNotify || false}
                    onChange={(e) =>
                      setLocalConfig({
                        ...localConfig,
                        autoNotify: e.target.checked,
                      })
                    }
                  />
                }
                label="Notificar equipe quando um card chegar à coluna final"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={localConfig.autoAssignHistory || false}
                    onChange={(e) =>
                      setLocalConfig({
                        ...localConfig,
                        autoAssignHistory: e.target.checked,
                      })
                    }
                  />
                }
                label="Manter histórico de responsáveis anteriores"
              />
            </Paper>
          </Box> */}

          {/* <Box sx={{ mt: 4 }}>
            <SectionTitle>
              <ProcessIcon fontSize="small" />
              Configurações do Processo de Distribuição
            </SectionTitle>

            <Paper variant="outlined" sx={{ p: 2, borderRadius: '8px' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={localConfig.assignOnMove || false}
                    onChange={(e) =>
                      setLocalConfig({
                        ...localConfig,
                        assignOnMove: e.target.checked,
                      })
                    }
                  />
                }
                label="Atribuir automaticamente ao mover card para nova coluna"
                sx={{ display: 'block', mb: 1 }}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={localConfig.notifyAssignees || false}
                    onChange={(e) =>
                      setLocalConfig({
                        ...localConfig,
                        notifyAssignees: e.target.checked,
                      })
                    }
                  />
                }
                label="Notificar responsáveis quando um card for atribuído a eles"
                sx={{ display: 'block', mb: 1 }}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={localConfig.resetAssigneeOnMove || false}
                    onChange={(e) =>
                      setLocalConfig({
                        ...localConfig,
                        resetAssigneeOnMove: e.target.checked,
                      })
                    }
                  />
                }
                label="Remover responsável atual ao mover para outra coluna"
                sx={{ display: 'block' }}
              />
            </Paper>
          </Box> */}
        </TabPanel>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          backgroundColor: '#f8f9fa',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Button onClick={onClose} color="inherit" variant="outlined">
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveConfig}
          disabled={saving}
          startIcon={
            saving ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <SaveIcon />
            )
          }
        >
          {saving ? 'Salvando...' : 'Salvar Configurações'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default KanbanSettings

// AISalesAssistantWithContext.jsx
import React from 'react';
import AISalesAssistantWizard from './AISalesAssistantWizard'; // Seu componente original
import { useWizards } from '../../context/WizardsContext';

/**
 * Versão adaptada do AISalesAssistant para trabalhar com o Context
 */
const AISalesAssistantWithContext = () => {
  const {
    aiWizardOpen,
    closeAIAssistant,
    aiWizardData,
    updateAIWizardData,
    handleAIDataSelected,
    clientInfo,
    productCatalog,
    chatMessages,
    activeWizard,
    bringAIToFront
  } = useWizards();

  // Adaptadores para manter a API compatível com o componente original
  const handleSendMessage = (message) => {
    const newMessage = { role: 'user', content: message };
    const updatedConversation = [...aiWizardData.conversation, newMessage];
    
    updateAIWizardData({ 
      conversation: updatedConversation,
      userInput: '' // Limpar input após envio
    });
    
    // Simular resposta do assistente (adapte para a lógica do seu componente)
    setTimeout(() => {
      const aiResponse = { role: 'assistant', content: 'Resposta simulada...' };
      updateAIWizardData({ 
        conversation: [...updatedConversation, aiResponse], 
        isLoading: false 
      });
    }, 1000);
  };

  const handleTabChange = (_, newValue) => {
    updateAIWizardData({ activeTab: newValue });
  };

  const handleSelectSuggestion = (suggestion) => {
    updateAIWizardData({ selectedItem: suggestion });
    
    // Executar a sugestão (adapte para a lógica do seu componente)
    switch (suggestion.type) {
      case 'summary':
        generateChatSummary();
        break;
      // Outros casos...
      default:
        break;
    }
  };

  // Função para gerar resumo de chat
  const generateChatSummary = () => {
    updateAIWizardData({ 
      isLoading: true,
      activeTab: 1 // Tab de resumo 
    });
    
    setTimeout(() => {
      updateAIWizardData({
        summary: "Resumo simulado da conversa...",
        isLoading: false
      });
    }, 1500);
  };

  // Função que prepara os dados para enviar ao chat
  const onSendToChat = (formattedData) => {
    handleAIDataSelected(formattedData);
  };

  return (
    <AISalesAssistantWizard
      open={aiWizardOpen}
      onClose={closeAIAssistant}
      onSendToChat={onSendToChat}
      chatMessages={chatMessages}
      productCatalog={productCatalog}
      clientInfo={clientInfo}
      // Props adicionais para controlar o estado interno
      conversation={aiWizardData.conversation}
      suggestions={aiWizardData.suggestions}
      activeTab={aiWizardData.activeTab}
      onTabChange={handleTabChange}
      selectedItem={aiWizardData.selectedItem}
      summary={aiWizardData.summary}
      productInfo={aiWizardData.productInfo}
      isLoading={aiWizardData.isLoading}
      onSendMessage={handleSendMessage}
      onSelectSuggestion={handleSelectSuggestion}
      zIndex={activeWizard === 'ai' ? 1301 : 1300}
      onFocus={bringAIToFront}
    />
  );
};

export default AISalesAssistantWithContext;
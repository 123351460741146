import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Add, Inventory as InventoryIcon } from '@mui/icons-material'

interface ProdutoEmptyProps {
  hasFilters: boolean
  onAddNew: () => void
}

export const ProdutoEmpty: React.FC<ProdutoEmptyProps> = ({ 
  hasFilters,
  onAddNew
}) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center',
      py: 8
    }}>
      <InventoryIcon sx={{ fontSize: 60, color: '#ccc', mb: 2 }} />
      <Typography variant="h6" color="textSecondary">
        Nenhum produto encontrado
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
        {hasFilters 
          ? "Tente remover os filtros aplicados" 
          : "Adicione seu primeiro produto ou serviço"}
      </Typography>
      <Button 
        variant="outlined" 
        startIcon={<Add />} 
        onClick={onAddNew}
        sx={{ mt: 3 }}
      >
        Adicionar Produto/Serviço
      </Button>
    </Box>
  )
}
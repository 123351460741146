import React, { createContext, useState, useContext, useCallback } from 'react';

// Criando o contexto
const WizardsContext = createContext();

/**
 * Provider para gerenciar o estado dos wizards (Omie e IA Assistant)
 * independentemente do polling do chat principal
 */
export function WizardsProvider({ children }) {
  // Estado do Omie Wizard
  const [omieWizardOpen, setOmieWizardOpen] = useState(false);
  const [omieWizardData, setOmieWizardData] = useState({
    selectedItem: null,
    activeTab: 0,
    searchTerm: '',
    searchResults: [],
    products: [],
    purchaseHistory: [],
    receivables: [],
  });
  
  // Estado do AI Assistant Wizard
  const [aiWizardOpen, setAiWizardOpen] = useState(false);
  const [aiWizardData, setAiWizardData] = useState({
    conversation: [],
    suggestions: [],
    activeTab: 0,
    selectedItem: null,
    summary: '',
    productInfo: [],
  });

  // Estado compartilhado
  const [clientInfo, setClientInfo] = useState(null);
  const [productCatalog, setProductCatalog] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);

  const [activeWizard, setActiveWizard] = useState(null);
  
  // Credenciais da API Omie
  const [omieCredentials, setOmieCredentials] = useState({
    apiKey: '',
    apiSecret: ''
  });

  // Callbacks
  const [omieDataCallback, setOmieDataCallback] = useState(null);
  const [aiDataCallback, setAiDataCallback] = useState(null);

  // Funções para abrir os wizards
  const openOmieWizard = useCallback((options = {}) => {
    const {
      clientInfo: newClientInfo,
      apiKey,
      apiSecret,
      onDataSelected
    } = options;

    if (newClientInfo) {
      setClientInfo(newClientInfo);
    }

    if (apiKey && apiSecret) {
      setOmieCredentials({ apiKey, apiSecret });
    }

    if (onDataSelected && typeof onDataSelected === 'function') {
      setOmieDataCallback(() => onDataSelected);
    }

    setOmieWizardOpen(true);
  }, []);

  const openAIAssistant = useCallback((options = {}) => {
    const {
      clientInfo: newClientInfo,
      productCatalog: newProductCatalog,
      chatMessages: newChatMessages,
      onDataSelected
    } = options;

    if (newClientInfo) {
      setClientInfo(newClientInfo);
    }

    if (newProductCatalog) {
      setProductCatalog(newProductCatalog);
    }

    if (newChatMessages) {
      setChatMessages(newChatMessages);
    }

    if (onDataSelected && typeof onDataSelected === 'function') {
      setAiDataCallback(() => onDataSelected);
    }

    setAiWizardOpen(true);
  }, []);

  const bringOmieToFront = useCallback(() => {
    setActiveWizard('omie');
  }, []);
  
  const bringAIToFront = useCallback(() => {
    setActiveWizard('ai');
  }, []);

  // Funções para fechar os wizards
  const closeOmieWizard = useCallback(() => {
    setOmieWizardOpen(false);
  }, []);

  const closeAIAssistant = useCallback(() => {
    setAiWizardOpen(false);
  }, []);

  // Funções para atualizar os dados dos wizards
  const updateOmieWizardData = useCallback((data) => {
    setOmieWizardData(prev => ({ ...prev, ...data }));
  }, []);

  const updateAIWizardData = useCallback((data) => {
    setAiWizardData(prev => ({ ...prev, ...data }));
  }, []);

  // Funções para processar os dados selecionados
  const handleOmieDataSelected = useCallback((data) => {
    if (omieDataCallback) {
      omieDataCallback(data);
    }
  }, [omieDataCallback]);

  const handleAIDataSelected = useCallback((data) => {
    if (aiDataCallback) {
      aiDataCallback(data);
    }
  }, [aiDataCallback]);

  // Valor exposto pelo contexto
  const value = {
    // Estado do Omie
    omieWizardOpen,
    omieWizardData,
    omieCredentials,
    
    // Estado do AI Assistant
    aiWizardOpen,
    aiWizardData,
    
    // Estado compartilhado
    clientInfo,
    productCatalog,
    chatMessages,
    
    activeWizard,
    bringOmieToFront,
    bringAIToFront,
    
    // Funções do Omie
    openOmieWizard,
    closeOmieWizard,
    updateOmieWizardData,
    handleOmieDataSelected,
    
    // Funções do AI Assistant
    openAIAssistant,
    closeAIAssistant,
    updateAIWizardData,
    handleAIDataSelected,
  };

  return (
    <WizardsContext.Provider value={value}>
      {children}
    </WizardsContext.Provider>
  );
}

// Hook para usar o contexto
export function useWizards() {
  const context = useContext(WizardsContext);
  
  if (!context) {
    throw new Error('useWizards must be used within a WizardsProvider');
  }
  
  return context;
}

export default WizardsContext;
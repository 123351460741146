import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
  Divider,
  Tooltip,
  Chip,
  Grid
} from '@mui/material';
import {
  Close as CloseIcon,
  Send as SendIcon,
  WhatsApp as WhatsAppIcon,
  Email as EmailIcon,
  InsertDriveFile as TemplateIcon,
  AddCircleOutline as AddIcon,
  AttachFile as AttachIcon
} from '@mui/icons-material';

const MessageDialog = ({
  open,
  onClose,
  type, // 'whatsapp' ou 'email'
  contact,
  onSend = () => {}
}) => {
  const [messageData, setMessageData] = useState({
    subject: '', // Apenas para email
    message: '',
    template: ''
  });

  // Lista de modelos de mensagem pré-definidos
  const messageTemplates = [
    { id: 'followup1', name: 'Acompanhamento inicial', content: 'Olá {{nome}}, gostaria de confirmar o recebimento das informações que enviamos anteriormente...' },
    { id: 'promotion1', name: 'Promoção mensal', content: 'Olá {{nome}}, temos uma oferta especial este mês para a sua empresa {{empresa}}...' },
    { id: 'payment', name: 'Lembrete de pagamento', content: 'Prezado(a) {{nome}}, gostaríamos de lembrar sobre o pagamento pendente no valor de R$ {{valor}}...' },
    { id: 'thank_you', name: 'Agradecimento', content: 'Agradecemos o contato, {{nome}}! Estamos à disposição para qualquer esclarecimento sobre {{assunto}}...' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessageData({
      ...messageData,
      [name]: value
    });
  };

  const applyTemplate = (templateId) => {
    const selectedTemplate = messageTemplates.find(t => t.id === templateId);
    if (selectedTemplate) {
      // Aplicar substituições de variáveis
      let content = selectedTemplate.content;
      if (contact) {
        content = content.replace(/{{nome}}/g, contact.name || '');
      }

      setMessageData({
        ...messageData,
        template: templateId,
        message: content
      });
    }
  };

  const handleSubmit = () => {
    onSend({
      type,
      to: type === 'whatsapp' ? contact?.whatsapp : contact?.email,
      subject: messageData.subject,
      message: messageData.message
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        className: 'rounded-lg'
      }}
    >
      <DialogTitle className="flex justify-between items-center bg-gray-50 border-b">
        <Box className="flex items-center">
          {type === 'whatsapp' ? (
            <>
              <WhatsAppIcon color="primary" className="mr-2" />
              <Typography variant="h6">Enviar WhatsApp</Typography>
            </>
          ) : (
            <>
              <EmailIcon color="primary" className="mr-2" />
              <Typography variant="h6">Enviar Email</Typography>
            </>
          )}
        </Box>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className="p-4">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Box className="space-y-4">
              {/* Para quem será enviado */}
              <Box className="flex items-center p-2 bg-gray-50 rounded-lg">
                <Typography variant="body2" className="text-gray-600 mr-2">
                  Para:
                </Typography>
                <Chip
                  label={`${contact?.name || 'Contato'} ${type === 'whatsapp' ? `(${contact?.whatsapp})` : `(${contact?.email})`}`}
                  variant="outlined"
                  size="small"
                  icon={type === 'whatsapp' ? <WhatsAppIcon fontSize="small" /> : <EmailIcon fontSize="small" />}
                />
              </Box>

              {/* Assunto - apenas para email */}
              {type === 'email' && (
                <TextField
                  name="subject"
                  label="Assunto"
                  fullWidth
                  variant="outlined"
                  value={messageData.subject}
                  onChange={handleChange}
                />
              )}

              {/* Mensagem */}
              <TextField
                name="message"
                label="Mensagem"
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                value={messageData.message}
                onChange={handleChange}
              />

              {/* Opção de anexo - apenas para email */}
              {type === 'email' && (
                <Box className="flex mt-2">
                  <Button
                    startIcon={<AttachIcon />}
                    variant="outlined"
                    size="small"
                    className="text-gray-700"
                  >
                    Anexar arquivo
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box className="p-3 bg-gray-50 rounded-lg">
              <Typography variant="subtitle2" className="mb-2 flex items-center">
                <TemplateIcon fontSize="small" className="mr-1" />
                Modelos de Mensagem
              </Typography>
              <Divider className="mb-3" />

              {messageTemplates.map((template) => (
                <Box key={template.id} className="mb-2">
                  <Button
                    variant={messageData.template === template.id ? "contained" : "outlined"}
                    size="small"
                    fullWidth
                    onClick={() => applyTemplate(template.id)}
                    className="justify-start text-left normal-case"
                    color={messageData.template === template.id ? "primary" : "inherit"}
                  >
                    {template.name}
                  </Button>
                </Box>
              ))}

              <Tooltip title="Adicionar novo modelo">
                <Button
                  startIcon={<AddIcon />}
                  className="mt-2"
                  size="small"
                  fullWidth
                  variant="dashed"
                >
                  Novo modelo
                </Button>
              </Tooltip>
            </Box>

            <Box className="mt-4 p-3 bg-blue-50 rounded-lg border border-blue-100">
              <Typography variant="caption" className="text-blue-700 block">
              <strong>Dica:</strong> Você pode usar variáveis nos seus modelos como {'{nome}'}, {'{empresa}'}, etc. Elas serão substituídas automaticamente com os dados do contato.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="p-3 bg-gray-50 border-t">
        <Button onClick={onClose} color="inherit">
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          startIcon={<SendIcon />}
          disabled={!messageData.message.trim()}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageDialog;
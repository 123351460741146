import { useForm, Controller } from 'react-hook-form';
import { Modal, TextField } from '@intraversa-lab/styleguide';
import { InputSelectdRHF, TProps } from '../../../FormRHF/InputSelectRHF';
import { Button, CircularProgress } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { toast } from '@intraversa-lab/styleguide';
import { llmEndpoint, rpaId } from 'infra';
import { getUser } from 'hooks/getUser';

interface IModalConnectivity {
  isOpen: boolean;
  onClose: () => void;
}

interface IModalConnectivityScript {
  isOpen: boolean;
  onClose: () => void;
  initialScript?: string;
}

interface FormValues {
  typeScheduler: string;
  frequency?: string;
}

export const ModalEdit = ({ isOpen, onClose }: IModalConnectivity) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px 56px 40px'}
      >
        <div className="space-y-4 mb-8">
          <h1 className="text-[#161F28] text-4xl font-normal font-merriweather">
            Altere o Script Inicial
          </h1>
          <h3 className="text-[#75808A] text-base">
            Altere o Script Inicial de seu LLM para alterar a forma de comunicação.
          </h3>
        </div>
        <div className="flex items-center" style={{ gap: '78px' }}>
          <p
            className="font-medium"
            style={{ fontFamily: 'Roboto', textWrap: 'nowrap' }}
          >
            Script
          </p>

          <div className="w-full">
            <TextField
              type={'password'}
              showIconPassword={false}
              placeholder="Digite seu script inicial"
              multiline
              rows={10}  
            />
          </div>
        </div>

        <div className="flex items-center space-x-4 mt-8">
          <Button
            variant="outlined"
            className="flex-1"
            onClick={onClose}
            style={{ textTransform: 'none', fontSize: '16px' }}
          >
            Cancelar
          </Button>
          <Button
            className="flex-1"
            type="submit"
            // onClick={() => handleSubmit(onSubmit)()}
            style={{ textTransform: 'none', fontSize: '16px' }}
          >
            Salvar alterações
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export const ModalEditScript = ({
  isOpen,
  onClose,
  initialScript,
}: IModalConnectivityScript) => {

  const [script, setScript] = useState(initialScript || '');
  const [loading, setLoading] = useState(false);


  const handleScriptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScript(event.target.value);
  };
  
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const companyId = getUser().companies[0].id;
      const response = await axios.put(
        `${llmEndpoint.url}/company-llm/${companyId}/script?rpaId=${rpaId.value}`,
        { script },
        {
          headers: {
            'accept': '*/*',
            'Content-Type': 'application/json'
          }
        }
      );
  
      console.log('Resposta da API:', response.data);
      toast({
        type: 'success',
        text: 'Script atualizado',
        description: 'O script foi atualizado com sucesso'
      });
      onClose();
    } catch (error) {
      console.error('Erro ao atualizar o script:', error);
      toast({
        type: 'error',
        text: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao tentar atualizar o script'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px 56px 40px'}
      >
        <div className="space-y-4 mb-8">
          <h1 className="text-[#161F28] text-4xl font-normal font-merriweather">
            Altere o Script Inicial
          </h1>
          <h3 className="text-[#75808A] text-base">
            Altere o Script Inicial de seu LLM para alterar a forma de comunicação.
          </h3>
        </div>
        <div className="flex items-center" style={{ gap: '78px' }}>
          <p
            className="font-medium"
            style={{ fontFamily: 'Roboto', textWrap: 'nowrap' }}
          >
            Script
          </p>

          <div className="w-full">
            <TextField
              value={script}
              onChange={handleScriptChange}
              type={'password'}
              showIconPassword={false}
              placeholder="Digite seu script inicial"
              multiline
              rows={15}  
            />
          </div>
        </div>

        <div className="flex items-center space-x-4 mt-8">
          <Button
            variant="outlined"
            className="flex-1"
            onClick={onClose}
            style={{ textTransform: 'none', fontSize: '16px' }}
          >
            Cancelar
          </Button>
          <Button
            className="flex-1"
            onClick={handleSubmit}
            disabled={loading}
            style={{ textTransform: 'none', fontSize: '16px' }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Salvar alterações'
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export const ModalSchedulerEdit = ({ isOpen, onClose }: IModalConnectivity) => {
  const { control, handleSubmit, watch } = useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  const selectedValue = watch('typeScheduler', '');

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    try {
      console.log(data);
      const response = await axios.post(
        'https://your-api-url.com/endpoint',
        data
      );
      console.log('Requisição bem-sucedida:', response.data);

      toast({
        type: 'success',
        text: 'Alterações salvas',
        description: 'Suas alterações foram salvas com sucesso'
      });
    } catch (error) {
      toast({
        type: 'success',
        text: 'Alterações salvas',
        description: 'Suas alterações foram salvas com sucesso'
      });
    } finally {
      setLoading(false); // Encerra o carregamento
    }
  };

  const optionsScheduler: TProps[] = [
    { label: 'Por Hora', value: 'PER_HOUR' },
    { label: 'Por Minuto', value: 'PER_MINUTE' },
    { label: 'Por Dia', value: 'DAILY' },
    { label: 'Por Semana', value: 'WEEKLY' },
    { label: 'Por Mês', value: 'MONTHLY' }
  ];

  const optionsMinutes: TProps[] = [
    { label: 'Cada 10 Minutos', value: '10' },
    { label: 'Cada 20 Minutos', value: '20' },
    { label: 'Cada 30 Minutos', value: '30' },
    { label: 'Cada 40 Minutos', value: '40' },
    { label: 'Cada 50 Minutos', value: '50' }
  ];

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px 56px 40px'}
      >
        <div className="space-y-4 mb-8">
          <h1 className="text-[#161F28] text-4xl font-normal font-merriweather">
            Agendador
          </h1>
          <h3 className="text-[#75808A] text-base">
            Altere a programação do agendador.
          </h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center" style={{ gap: '78px' }}>
            <p
              className="font-medium"
              style={{ fontFamily: 'Roboto', textWrap: 'nowrap' }}
            >
              Peridiocidade
            </p>

            <div className="w-full">
              <InputSelectdRHF
                placeholder={'Selecione a peridiocidade'}
                options={optionsScheduler}
                name="typeScheduler"
                control={control}
                isError={false}
              />
            </div>
          </div>
          {(selectedValue === 'PER_MINUTE' || selectedValue === 'PER_HOUR') && (
            <div
              className="flex items-center"
              style={{ gap: '68px', marginTop: '16px' }}
            >
              <p
                className="font-medium"
                style={{ fontFamily: 'Roboto', textWrap: 'nowrap' }}
              >
                Defina o tempo
              </p>

              <div className="w-full">
                <Controller
                  name="frequency"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      type="number"
                      placeholder="Frequência"
                      value={value || ''}
                      onChange={e =>
                        onChange(
                          e.target.value ? parseInt(e.target.value, 10) : ''
                        )
                      }
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="flex items-center space-x-4 mt-8">
            <Button
              variant="outlined"
              className="flex-1"
              onClick={onClose}
              style={{ textTransform: 'none', fontSize: '16px' }}
            >
              Cancelar
            </Button>
            <Button
              className="flex-1"
              type="submit"
              style={{ textTransform: 'none', fontSize: '16px' }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Salvar alterações'
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

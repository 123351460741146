import React from 'react'
import { Box, TextField } from '@mui/material'

export const SearchField = ({ searchTerm, setSearchTerm, recipientType }) => {
  return (
    <div>
      <TextField
        fullWidth
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={`Buscar ${
          recipientType === 'contact'
            ? 'contato'
            : recipientType === 'empresa'
              ? 'empresa'
              : 'lista'
        }`}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <Box component="span" sx={{ color: 'text.secondary', mr: 1 }}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
      />
    </div>
  )
}
import { Close } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'

export const StyledModal = ({
  isOpen,
  onClose,
  title,
  children,
  primaryAction,
  primaryActionText,
  secondaryActionText,
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50 flex items-center justify-center transition-all duration-300">
      <div className="bg-white rounded-xl shadow-2xl w-full max-w-md transform transition-all duration-300 overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-100 flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900">{title}</h3>
          <IconButton onClick={onClose} size="small">
            <Close fontSize="small" />
          </IconButton>
        </div>

        <div className="px-6 py-4">{children}</div>

        <div className="px-6 py-3 bg-gray-50 flex justify-end space-x-3">
          <Button
            variant="outlined"
            color="inherit"
            onClick={onClose}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 500,
            }}
          >
            {secondaryActionText || 'Cancelar'}
          </Button>

          {primaryAction && (
            <Button
              variant="contained"
              color="primary"
              onClick={primaryAction}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 500,
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
              }}
            >
              {primaryActionText || 'Confirmar'}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

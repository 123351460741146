import React from 'react'
import { Box, FormControlLabel, Switch } from '@mui/material'

interface ProdutoTypeFilterProps {
  tipoFilter: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ProdutoTypeFilter: React.FC<ProdutoTypeFilterProps> = ({ 
  tipoFilter, 
  onChange 
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
      <FormControlLabel
        control={
          <Switch 
            checked={tipoFilter === 'produto'} 
            onChange={onChange}
            name="produto"
            color="primary"
            size="small"
          />
        }
        label="Produtos"
        sx={{ mr: 1, '.MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
      />
      
      <FormControlLabel
        control={
          <Switch 
            checked={tipoFilter === 'servico'} 
            onChange={onChange}
            name="servico"
            color="secondary"
            size="small"
          />
        }
        label="Serviços"
        sx={{ '.MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
      />
    </Box>
  )
}
import React from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  Switch,
  FormGroup,
  Button
} from '@mui/material';
import {
  Storage,
  Settings
} from '@mui/icons-material';
import * as S from './styles';

interface FunnelsSectionProps {
  leadFunnelIntegration: boolean;
  setLeadFunnelIntegration: (active: boolean) => void;
  debtFunnelIntegration: boolean;
  setDebtFunnelIntegration: (active: boolean) => void;
  onConfigureDebtReminders: () => void;
  loading: boolean;
}

const FunnelsSection: React.FC<FunnelsSectionProps> = ({
  leadFunnelIntegration,
  setLeadFunnelIntegration,
  debtFunnelIntegration,
  setDebtFunnelIntegration,
  onConfigureDebtReminders,
  loading
}) => {
  return (
    <S.FormSection>
      <S.SectionTitle>
        <Storage fontSize="small" sx={{ mr: 1, verticalAlign: 'middle' }} />
        Integrações com Funis
      </S.SectionTitle>
      
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Configure como o ERP deve se integrar com os funis de vendas e inadimplência.
        Estas configurações determinam como os dados do ERP serão utilizados em outros módulos do sistema.
      </Typography>
      
      <S.FunnelIntegrationSection>
        <FormGroup>
          <Box sx={{ 
            p: 2, 
            border: leadFunnelIntegration ? '1px solid rgba(25, 118, 210, 0.5)' : '1px solid #e0e0e0',
            borderRadius: 2,
            mb: 2,
            bgcolor: leadFunnelIntegration ? 'rgba(25, 118, 210, 0.04)' : 'white'
          }}>
            <FormControlLabel
              control={
                <Switch 
                  checked={leadFunnelIntegration}
                  onChange={(e) => setLeadFunnelIntegration(e.target.checked)}
                  color="primary"
                  disabled={loading}
                />
              }
              label={
                <Box>
                  <Typography variant="body1" fontWeight={500}>
                    Funil de Leads de Vendas
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Integrar este ERP com o funil de leads de vendas
                  </Typography>
                </Box>
              }
            />
            
            {leadFunnelIntegration && (
              <Box sx={{ mt: 2, pl: 4 }}>
                <Typography variant="body2" color="text.secondary">
                  Esta integração permitirá:
                </Typography>
                <ul style={{ color: '#637381', fontSize: '14px', paddingLeft: '20px' }}>
                  <li>Importar leads do ERP para o funil de vendas</li>
                  <li>Atualizar status de negociações no ERP</li>
                  <li>Sincronizar oportunidades entre sistemas</li>
                </ul>
              </Box>
            )}
          </Box>
          
          <Box sx={{ 
            p: 2, 
            border: debtFunnelIntegration ? '1px solid rgba(156, 39, 176, 0.5)' : '1px solid #e0e0e0',
            borderRadius: 2,
            bgcolor: debtFunnelIntegration ? 'rgba(156, 39, 176, 0.04)' : 'white'
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={debtFunnelIntegration}
                    onChange={(e) => setDebtFunnelIntegration(e.target.checked)}
                    color="secondary"
                    disabled={loading}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body1" fontWeight={500}>
                      Funil de Inadimplência
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Integrar este ERP com o funil de gestão de inadimplência
                    </Typography>
                  </Box>
                }
              />
              
              {debtFunnelIntegration && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={<Settings />}
                  onClick={onConfigureDebtReminders}
                  sx={{ mt: 1 }}
                  disabled={loading}
                >
                  Configurar Lembretes
                </Button>
              )}
            </Box>
            
            {debtFunnelIntegration && (
              <Box sx={{ mt: 2, pl: 4 }}>
                <Typography variant="body2" color="text.secondary">
                  Esta integração permitirá:
                </Typography>
                <ul style={{ color: '#637381', fontSize: '14px', paddingLeft: '20px' }}>
                  <li>Importar clientes inadimplentes do ERP</li>
                  <li>Atualizar status de pagamentos</li>
                  <li>Sincronizar ações de cobrança entre sistemas</li>
                </ul>
              </Box>
            )}
          </Box>
        </FormGroup>
      </S.FunnelIntegrationSection>
    </S.FormSection>
  );
};

export default FunnelsSection;
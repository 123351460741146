import { format, subHours } from 'date-fns'
import { WhatsAppAudioPlayer } from './../whatsAppAudioPlayer/WhatsAppAudioPlayer'
import { Avatar, Tooltip, IconButton, Snackbar } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import { useState, useMemo } from 'react'
import { ReplyIcon } from 'lucide-react'
import DownloadIcon from '@mui/icons-material/Download'
import DescriptionIcon from '@mui/icons-material/Description'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import TableChartIcon from '@mui/icons-material/TableChart'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

// Função para processar texto com formatação markdown simples
const processMarkdown = (text) => {
  if (!text) return '';
  
  // Verifica se o HTML já foi processado (contém tags HTML)
  if (/<\/?[a-z][\s\S]*>/i.test(text)) {
    return text;
  }
  
  // Copia o texto para não modificar o original
  let processed = text;
  
  // Converte quebras de linha escapadas para <br>
  processed = processed.replace(/\\n/g, '<br>');
  // Converte quebras de linha normais para <br>
  processed = processed.replace(/\n/g, '<br>');
  
  // Processa títulos markdown (###, ##, #)
  processed = processed.replace(/### (.*?)(?=<br>|$)/g, '<div style="font-size: 16px; font-weight: bold; margin-top: 10px; margin-bottom: 6px;">$1</div>');
  processed = processed.replace(/## (.*?)(?=<br>|$)/g, '<div style="font-size: 18px; font-weight: bold; margin-top: 12px; margin-bottom: 8px;">$1</div>');
  processed = processed.replace(/# (.*?)(?=<br>|$)/g, '<div style="font-size: 20px; font-weight: bold; margin-top: 15px; margin-bottom: 10px;">$1</div>');
  
  // Processa negrito em duas passagens para pegar diferentes padrões
  // Primeiro padrão: **texto**
  processed = processed.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  // Segundo padrão: __texto__
  processed = processed.replace(/__(.*?)__/g, '<strong>$1</strong>');
  
  // Processa itálico em duas passagens
  // Primeiro padrão: *texto*
  processed = processed.replace(/\*([^\*]+)\*/g, '<em>$1</em>');
  // Segundo padrão: _texto_
  processed = processed.replace(/_([^_]+)_/g, '<em>$1</em>');
  
  // Processa código inline (`código`)
  processed = processed.replace(/`([^`]+)`/g, '<span style="font-family: monospace; background-color: rgba(0, 0, 0, 0.1); padding: 2px 4px; border-radius: 3px; font-size: 0.9em;">$1</span>');
  
  // Processa links [texto](url)
  processed = processed.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer" style="color: inherit; text-decoration: underline;">$1</a>');
  
  // Processa emojis Unicode (garantir que sejam exibidos)
  processed = processed.replace(/[\u{1F300}-\u{1F6FF}]/gu, match => `<span>${match}</span>`);
  
  return processed;
};

export const MessageContainer = ({
  variant,
  value,
  contentType,
  contentUrl,
  isMobile,
  onReplyMessage,
  message,
  messages,
}) => {
  const isBot = variant === 'left'
  const [copied, setCopied] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  // Encontra as respostas para esta mensagem
  const repliesReceived = useMemo(() => {
    if (!messages || !message || !message.id) return []
    return messages.filter((msg) => msg.replyTo === message.id)
  }, [messages, message])

  const hasReplies = repliesReceived.length > 0
  const hasMediaContent = contentType === 'AUDIO' || contentType === 'IMAGE'

  // Processa o conteúdo markdown
  const processedValue = useMemo(() => {
    // Para mensagens tipo 'MESSAGE' ou quando temos texto e mídia juntos
    if (contentType === 'MESSAGE' || (hasMediaContent && value)) {
      if (typeof value === 'string') {
        const result = processMarkdown(value);
        return result;
      }
    }
    return value;
  }, [value, contentType, hasMediaContent]);

  // Função para formatar a data
  const formatMessageDate = (dateString) => {
    if (!dateString) return ''
    const date = subHours(new Date(dateString), 0)
    return format(date, 'dd/MM/yyyy - HH:mm:ss')
  }

  const handleReply = () => {
    if (onReplyMessage && message) {
      onReplyMessage(message)
    }
  }

  // Função para encontrar a mensagem original com base no ID
  const findOriginalMessage = (replyToId) => {
    if (!replyToId || !messages || !messages.length) return null
    return messages.find((msg) => msg.id === replyToId)
  }

  const getDocumentIcon = () => {
    if (!contentUrl) return <InsertDriveFileIcon />

    const fileExtension = contentUrl.split('.').pop().toLowerCase()

    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon />
      case 'doc':
      case 'docx':
        return <DescriptionIcon />
      case 'xls':
      case 'xlsx':
        return <TableChartIcon />
      default:
        return <InsertDriveFileIcon />
    }
  }

  const getDocumentType = () => {
    if (!contentUrl) return 'Documento'

    const fileExtension = contentUrl.split('.').pop().toLowerCase()

    switch (fileExtension) {
      case 'pdf':
        return 'PDF'
      case 'doc':
      case 'docx':
        return 'Word'
      case 'xls':
      case 'xlsx':
        return 'Excel'
      default:
        return 'Documento'
    }
  }

  const renderReplyPreview = () => {
    if (!message.replyTo) return null

    // Encontra a mensagem original sendo respondida
    const originalMessage = findOriginalMessage(message.replyTo)
    if (!originalMessage) return null

    const previewContent = originalMessage.message
    const previewContentType = originalMessage.type || 'MESSAGE'
    const isReplyToBot = originalMessage.isBot

    // Determina se estamos em uma mensagem do usuário (fundo escuro) ou do bot (fundo claro)
    const isDarkBackground = !isBot

    // Cores ajustadas com base no fundo
    const borderColor = isDarkBackground
      ? isReplyToBot
        ? 'rgba(199, 210, 254, 0.8)'
        : 'rgba(187, 247, 208, 0.8)' // Cores mais claras para fundo escuro
      : isReplyToBot
        ? 'rgba(79, 70, 229, 0.8)'
        : 'rgba(22, 163, 74, 0.8)' // Cores mais escuras para fundo claro

    const backgroundColor = isDarkBackground
      ? isReplyToBot
        ? 'rgba(165, 180, 252, 0.3)'
        : 'rgba(134, 239, 172, 0.3)' // Semitransparente para fundo escuro
      : isReplyToBot
        ? 'rgba(224, 231, 255, 0.9)'
        : 'rgba(220, 252, 231, 0.9)' // Mais sólido para fundo claro

    const titleColor = isDarkBackground
      ? isReplyToBot
        ? '#c7d2fe'
        : '#bbf7d0' // Cores claras para fundo escuro
      : isReplyToBot
        ? '#4338ca'
        : '#15803d' // Cores escuras para fundo claro

    const subtitleColor = isDarkBackground ? '#e2e8f0' : '#4b5563'
    const contentColor = isDarkBackground ? '#ffffff' : '#1f2937'

    return (
      <div
        className="mb-2 pl-2 pr-2 pt-1 pb-1 text-xs rounded-sm"
        style={{
          maxHeight: '60px',
          overflow: 'hidden',
          opacity: 1, // Aumentado de 0.8 para 1
          cursor: 'pointer',
          borderLeftWidth: '2px',
          borderLeftStyle: 'solid',
          borderLeftColor: borderColor,
          backgroundColor: backgroundColor,
        }}
        onClick={() => {
          // Encontra e rola para a mensagem original
          const originalMessageElement = document.querySelector(
            `[data-message-id="${message.replyTo}"]`,
          )

          if (originalMessageElement) {
            originalMessageElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })

            originalMessageElement.classList.add('reply-highlight')
            setTimeout(() => {
              originalMessageElement.classList.remove('reply-highlight')
            }, 2000)
          }
        }}
      >
        <div
          className="font-medium mb-1 flex items-center"
          style={{ color: titleColor }}
        >
          {isReplyToBot ? 'AI' : 'Você'}
          <span className="ml-1 text-xs" style={{ color: subtitleColor }}>
            → Clique para ver a mensagem original
          </span>
        </div>
        <div className="truncate" style={{ color: contentColor }}>
          {previewContentType === 'IMAGE' && (
            <span className="font-medium">[Imagem] </span>
          )}
          {previewContentType === 'AUDIO' && (
            <span className="font-medium">[Áudio] </span>
          )}
          {previewContent
            ? previewContent.length > 100
              ? previewContent.substring(0, 100) + '...'
              : previewContent
            : previewContentType !== 'MESSAGE'
              ? ''
              : 'Mensagem'}
        </div>
      </div>
    )
  }

  // Função para copiar o texto da mensagem
  const copyToClipboard = () => {
    if (!value) return

    // Remove tags HTML do texto processado
    const tempElement = document.createElement('div')
    tempElement.innerHTML = processedValue
    const textToCopy = tempElement.textContent || tempElement.innerText || value

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true)
        setOpenSnackbar(true)
        setTimeout(() => setCopied(false), 2000)
      })
      .catch((err) => {
        console.error('Falha ao copiar texto: ', err)
      })
  }

  // Função para renderizar o conteúdo com base no tipo
  const renderContent = () => {
    switch (contentType) {
      case 'AUDIO':
        return (
          <div className="mb-2">
            <WhatsAppAudioPlayer src={contentUrl} isBot={isBot} />
          </div>
        )
      case 'IMAGE':
        return (
          <div className="mb-2">
            <img
              src={contentUrl}
              alt="Imagem"
              className="max-w-full rounded-lg cursor-pointer"
              style={{
                maxHeight: '200px',
                objectFit: 'cover',
              }}
              onClick={() => window.open(contentUrl, '_blank')}
            />
          </div>
        )
      case 'DOCUMENT':
        return (
          <div className="mb-2">
            <div
              className={`flex items-center p-3 rounded-lg cursor-pointer ${
                isBot ? 'bg-gray-200' : 'bg-indigo-700'
              }`}
              onClick={() => window.open(contentUrl, '_blank')}
            >
              <div
                className={`mr-3 ${isBot ? 'text-indigo-600' : 'text-white'}`}
              >
                {getDocumentIcon()}
              </div>
              <div className="flex-grow">
                <div
                  className={`font-medium ${isBot ? 'text-gray-800' : 'text-white'}`}
                >
                  {'Documento'}
                </div>
                <div
                  className={`text-xs ${isBot ? 'text-gray-600' : 'text-indigo-200'}`}
                >
                  {getDocumentType()}
                </div>
              </div>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(contentUrl, '_blank')
                }}
                sx={{
                  color: isBot ? '#635AFF' : 'white',
                  '&:hover': {
                    bgcolor: isBot
                      ? 'rgba(99, 90, 255, 0.1)'
                      : 'rgba(255, 255, 255, 0.1)',
                  },
                }}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        )
      case 'MESSAGE':
      default:
        return (
          <div 
            style={{
              lineHeight: 1.5,
              whiteSpace: 'pre-line',
              wordBreak: 'break-word',
            }} 
            dangerouslySetInnerHTML={{ __html: processedValue }}
          />
        )
    }
  }

  const handleViewReplies = () => {
    if (repliesReceived.length > 0) {
      const replyId = repliesReceived[0].id
      const replyElement = document.querySelector(
        `[data-message-id="${replyId}"]`,
      )

      if (replyElement) {
        replyElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
        replyElement.classList.add('reply-highlight')
        setTimeout(() => {
          replyElement.classList.remove('reply-highlight')
        }, 2000)
      }
    }
  }

  return (
    <div
      className={`col-span-12 flex ${isBot ? 'justify-start' : 'justify-end'} ${isMobile ? 'px-2' : 'px-4'} mb-2`}
      data-message-id={message.id}
    >
      <div className="flex items-start gap-2.5 max-w-[85%]">
        {isBot && (
          <div className="flex-shrink-0">
            <Avatar
              sx={{
                bgcolor: '#635AFF',
                width: 36,
                height: 36,
                fontSize: '1rem',
              }}
            >
              AI
            </Avatar>
          </div>
        )}
        <div
          className={`p-3 rounded-2xl text-sm relative ${
            isBot
              ? 'bg-gray-100 text-gray-800 rounded-tl-none'
              : 'bg-indigo-600 text-white rounded-tr-none'
          } ${contentType === 'IMAGE' ? 'p-1 overflow-hidden' : ''}`}
          style={{
            boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
            maxWidth: '100%',
            wordBreak: 'break-word',
          }}
        >
          {/* Estilos para animação de destaque */}
          <style dangerouslySetInnerHTML={{ __html: `
            @keyframes highlight-message {
              0% { background-color: rgba(99, 90, 255, 0.3); }
              100% { background-color: transparent; }
            }
            .reply-highlight {
              animation: highlight-message 2s ease;
            }
          `}} />

          {/* Exibir prévia da mensagem respondida */}
          {renderReplyPreview()}

          <div className="group relative">
            {renderContent()}
            {hasMediaContent && value && (
              <div
                className={`mt-2 ${contentType === 'IMAGE' ? 'px-2 pb-2' : ''}`}
                style={{ lineHeight: 1.5, whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{ __html: processedValue }}
              >
              </div>
            )}

            {hasReplies && (
              <div
                className={`mt-2 text-xs cursor-pointer hover:underline ${
                  isBot ? 'text-indigo-600' : 'text-indigo-200'
                }`}
                onClick={handleViewReplies}
              >
                {repliesReceived.length === 1
                  ? '1 resposta'
                  : `${repliesReceived.length} respostas`}
              </div>
            )}

            {/* Botões de ação na mensagem - visíveis apenas no hover */}
            <div
              className="absolute right-0 top-0 opacity-0 group-hover:opacity-100 transition-opacity flex"
              style={{
                transform: 'translate(10px, -10px)',
              }}
            >
              {/* Botão de resposta */}
              <Tooltip title="Responder">
                <IconButton
                  size="small"
                  onClick={handleReply}
                  sx={{
                    bgcolor: isBot ? 'white' : 'rgba(255, 255, 255, 0.2)',
                    color: isBot ? '#635AFF' : 'white',
                    '&:hover': {
                      bgcolor: isBot ? '#f5f5f5' : 'rgba(255, 255, 255, 0.3)',
                    },
                    width: 28,
                    height: 28,
                    marginRight: '4px',
                  }}
                >
                  <ReplyIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              {/* Botão de cópia - visível apenas quando há texto para copiar */}
              {value && (contentType === 'MESSAGE' || hasMediaContent) && (
                <Tooltip title={copied ? 'Copiado!' : 'Copiar texto'}>
                  <IconButton
                    size="small"
                    onClick={copyToClipboard}
                    sx={{
                      bgcolor: isBot ? 'white' : 'rgba(255, 255, 255, 0.2)',
                      color: isBot ? '#635AFF' : 'white',
                      '&:hover': {
                        bgcolor: isBot ? '#f5f5f5' : 'rgba(255, 255, 255, 0.3)',
                      },
                      width: 28,
                      height: 28,
                    }}
                  >
                    {copied ? (
                      <DoneIcon fontSize="small" />
                    ) : (
                      <ContentCopyIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>

          <div
            className={`text-xs mt-1 ${isBot ? 'text-gray-500' : 'text-indigo-200'}`}
          >
            {formatMessageDate(message.createdAt)}
          </div>
        </div>
        {!isBot && (
          <div className="flex-shrink-0">
            <Avatar
              sx={{
                bgcolor: '#22C55E',
                width: 36,
                height: 36,
                fontSize: '0.875rem',
              }}
            >
              Você
            </Avatar>
          </div>
        )}
      </div>

      {/* Notificação de cópia bem-sucedida */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        message="Texto copiado para a área de transferência"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  )
}
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { 
  Box, 
  Button,
  Typography,
  Snackbar,
  Alert,
  Chip
} from '@mui/material'
import { Add, ArrowBack, Category as CategoryIcon } from '@mui/icons-material'

import * as S from '../styles/styles'
import { Produto, ProdutoFormData } from './types'
import { extractUniqueCategories } from './utils/helpers'
import { useProdutoAPI } from './hooks/useProdutoAPI'
import { useProdutoFilters } from './hooks/useProdutoFilters'

// Componentes
import { ProdutoFilters } from './components/ProdutoFilters'
import { ProdutoList } from './components/ProdutoList'
import { ProdutoForm } from './components/ProdutoForm'

export const ListProdutos: React.FC = () => {
  const { rpaId } = useParams<{ rpaId: string }>()
  const navigate = useNavigate()
  
  // Estado para controle da UI
  const [showInputSearch, setShowInputSearch] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [edit, setEdit] = useState(false)
  const [selectedProduto, setSelectedProduto] = useState<Produto | null>(null)
  
  // Produtos por página (paginação)
  const produtosPerPage = 12
  
  // Custom hooks
  const {
    search,
    categoryFilter,
    categoryAnchorEl,
    tipoFilter,
    currentPage,
    setCurrentPage,
    getFilterOptions,
    handleCategoryMenuOpen,
    handleCategoryMenuClose,
    handleCategorySelect,
    clearCategoryFilter,
    handleTipoFilterChange,
    handleSearchChange,
    clearAllFilters
  } = useProdutoFilters()
  
  const {
    produtos,
    loading,
    error,
    successMessage,
    fetchProdutos,
    createProduto,
    updateProduto,
    deleteProduto,
    clearMessages
  } = useProdutoAPI({ rpaId })
  
  // Carrega produtos ao iniciar ou quando filtros mudam
  useEffect(() => {
    if (rpaId) {
      fetchProdutos(getFilterOptions())
    }
  }, [rpaId, fetchProdutos, getFilterOptions])
  
  // Verifica se há filtros ativos
  const hasActiveFilters = !!search || !!categoryFilter || !!tipoFilter
  
  // Abre o formulário para adicionar novo produto
  const handleAddNewProduto = () => {
    setShowForm(true)
    setEdit(false)
    setSelectedProduto(null)
  }
  
  // Volta para lista de produtos
  const handleBackToSearch = () => {
    setShowForm(false)
    setSelectedProduto(null)
  }
  
  // Seleciona um produto para edição
  const handleCardClick = (produto: Produto) => {
    setSelectedProduto(produto)
    setEdit(true)
    setShowForm(true)
  }
  
  // Manipula a criação/atualização de produto
  const handleFormSubmit = async (formData: ProdutoFormData) => {
    let success = false
    
    if (edit && selectedProduto) {
      // Atualiza produto existente
      success = await updateProduto(selectedProduto.id, formData)
    } else {
      // Cria novo produto
      success = await createProduto(formData)
    }
    
    if (success) {
      setShowForm(false)
    }
  }
  
  // Manipula a exclusão de produto
  const handleDeleteProduto = async () => {
    if (selectedProduto) {
      const success = await deleteProduto(selectedProduto.id)
      if (success) {
        handleBackToSearch()
      }
    }
  }
  
  // Verifica se o rpaId existe
  if (!rpaId) {
    return (
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h6" color="error" gutterBottom>
          ID do RPA não encontrado na URL
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => navigate(-1)}
          startIcon={<ArrowBack />}
          sx={{ mt: 2 }}
        >
          Voltar
        </Button>
      </Box>
    )
  }

  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">
            Gerenciamento de Produtos e Serviços
          </h1>
          <p style={{ marginTop: '16px' }}>
            Cadastre e gerencie produtos e serviços para sua empresa.
          </p>
        </S.WrapperHeader>
      </S.Container>

      {/* Mensagens de erro e sucesso */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={clearMessages}>
        <Alert onClose={clearMessages} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={clearMessages}>
        <Alert onClose={clearMessages} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <S.Content>
        <Box sx={{ 
          overflow: 'auto',
          maxHeight: 'calc(100vh - 200px)', 
          position: 'relative',
          pb: 4,
          width: '100%'
        }}>
          <S.WrapperSerach>
            {!showForm ? (
              <>
                <ProdutoFilters
                  search={search}
                  showInputSearch={showInputSearch}
                  categoryFilter={categoryFilter}
                  categoryAnchorEl={categoryAnchorEl}
                  tipoFilter={tipoFilter}
                  categories={extractUniqueCategories(produtos)}
                  onSearchChange={(e) => handleSearchChange(e.target.value)}
                  onShowMobileToggle={() => setShowInputSearch(!showInputSearch)}
                  onCategoryMenuOpen={handleCategoryMenuOpen}
                  onCategoryMenuClose={handleCategoryMenuClose}
                  onCategorySelect={handleCategorySelect}
                  onClearCategoryFilter={clearCategoryFilter}
                  onTipoFilterChange={handleTipoFilterChange}
                />

                <Button
                  startIcon={<Add />}
                  onClick={handleAddNewProduto}
                  className="newProfile"
                  sx={{
                    '&:hover': {
                      backgroundColor: '#3C454D !important',
                      color: '#F9F9F9',
                    },
                  }}
                  disabled={loading}
                >
                  Novo Produto/Serviço
                </Button>
              </>
            ) : (
              <Button
                startIcon={<ArrowBack />}
                onClick={handleBackToSearch}
                className="backToSearch"
                sx={{
                  '&:hover': {
                    backgroundColor: '#3C454D !important',
                    color: '#F9F9F9',
                  },
                }}
                disabled={loading}
              >
                Voltar para listagem
              </Button>
            )}
          </S.WrapperSerach>
          
          {/* Status do filtro de categoria */}
          {!showForm && (categoryFilter || tipoFilter) && (
            <Box sx={{ px: '30px', pb: 2 }}>
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 2,
                  color: '#666'
                }}
              >
                {categoryFilter && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CategoryIcon fontSize="small" />
                    <Typography variant="body2">
                      Categoria: 
                      <Chip 
                        label={categoryFilter} 
                        size="small" 
                        onDelete={clearCategoryFilter}
                        sx={{ 
                          ml: 1,
                          backgroundColor: '#3f51b5',
                          color: 'white',
                          height: '24px',
                          '& .MuiChip-deleteIcon': {
                            color: 'white',
                            '&:hover': {
                              color: '#f1f1f1'
                            }
                          }
                        }}
                      />
                    </Typography>
                  </Box>
                )}
                
                {tipoFilter && (
                  <Chip 
                    label={tipoFilter === 'produto' ? 'Produtos' : 'Serviços'} 
                    size="small" 
                    color={tipoFilter === 'produto' ? 'primary' : 'secondary'}
                    onDelete={() => handleTipoFilterChange({
                      target: { checked: false, name: tipoFilter }
                    } as React.ChangeEvent<HTMLInputElement>)}
                    sx={{ height: '24px' }}
                  />
                )}
              </Box>
            </Box>
          )}
          
          <Box sx={{ padding: '0 30px', height: '100%' }}>
            {!showForm ? (
              // Lista de produtos
              <ProdutoList 
                produtos={produtos}
                loading={loading}
                categoryFilter={categoryFilter}
                currentPage={currentPage}
                produtosPerPage={produtosPerPage}
                hasFilters={hasActiveFilters}
                onCardClick={handleCardClick}
                onCategorySelect={handleCategorySelect}
                onPageChange={setCurrentPage}
                onAddNew={handleAddNewProduto}
              />
            ) : (
              // Formulário de produto
              <Box sx={{ maxWidth: '100%', mx: 'auto', pt: 2, pb: 6, maxHeight: 'calc(100vh - 280px)', overflowY: 'auto' }}>
                <ProdutoForm 
                  produto={selectedProduto}
                  edit={edit}
                  loading={loading}
                  onSubmit={handleFormSubmit}
                  onDelete={edit ? handleDeleteProduto : undefined}
                />
              </Box>
            )}
          </Box>
        </Box>
      </S.Content>
    </>
  )
}

export default ListProdutos
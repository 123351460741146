import React, { useState, useEffect } from 'react'

import { If } from '@intraversa-lab/styleguide'
import {
  AccessTime as ClockIcon,
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Message as MessageIcon,
  ContactPhone as ContactIcon,
  Business as BusinessIcon,
  ViewKanban as KanbanIcon,
  Settings as SettingsIcon,
  Smartphone as PhoneIcon,
  FormatListBulleted as ListIcon,
  ChatBubbleOutline as TemplateIcon,
  NotificationsNone as NotificationIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  CircleRounded as CircleIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  FilterAlt as FunnelIcon,
  NotificationAdd,
  Engineering,
  Email,
} from '@mui/icons-material'
import {
  Box,
  Typography,
  Chip,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  Divider,
  Avatar,
  useMediaQuery,
  useTheme,
  Button,
  Tooltip,
  Zoom,
  SwipeableDrawer,
  Badge,
  Collapse,
} from '@mui/material'
import { HeaderMain } from 'components/HeaderMain/HeaderMain'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { UploaderConfig } from 'types/UploaderConfig'

// Containers
import { Assinaturas } from './containers/assinaturas/Assinaturas'
import { ChatContainer } from './containers/chat/Chat'
import { Configuracoes } from './containers/configuracoes/Configuracoes'
import { ListContatos } from './containers/contatos/ListContatos'
import { ListEmpresas } from './containers/empresas/index'
import {ManagementScreen} from './containers/gerenciamento/pages/ManagementScreen/ManagementScreen'
import { AllNotifications } from './containers/notificacoes/AllNotifications.jsx'
import { TelefoneConfig } from './containers/telefone_config/TelefoneConfig'
import { ListTransmissao } from './containers/lista_transmissao/ListTransmissao'
import { ChatTemplate } from './containers/chat_templates/ChatTemplate'
import { useStore } from '../../stores/ChatStore'
import Inventory2Icon from '@mui/icons-material/Inventory2'
import KanbanApp from './containers/kanban/KanbanApp'
import { ListProdutos } from './containers/produtos/'
import { Calendar, HomeIcon, User } from 'lucide-react'
import CompanySettings from './containers/minha_empresa/MinhaEmpresa'
import ActivityCalendar from './containers/calendario/ActivityCalendar'
import {
  NotificationProvider,
  NotificationBanner,
  NotificationMenu,
  useNotifications,
  NOTIFICATION_TYPES,
} from './containers/notificacoes/NotificationContext.jsx'
import { ERPManagement } from './containers/erp/ERPManagement'
import { EmailConfig } from './containers/email_config/EmailConfig'
import UserManagement from './containers/usuarios/UserManagement'

interface Window {
  navigateToEntity?: (type: string, entityId: string) => void
}
// Interface das opções de menu
interface MenuOption {
  value: string
  label: string
  icon: JSX.Element
  hasSubMenu?: boolean
  subMenuItems?: SubMenuItem[]
}

// Interface para itens de submenu
interface SubMenuItem {
  value: string
  label: string
  parentValue: string
}

// Larguras do drawer
const drawerWidth = 260
const collapsedDrawerWidth = 68

export const Main = ({
  title,
  type,
  data,
}: {
  title: string
  type: string
  data: UploaderConfig
}) => {
  const [activeTab, setActiveTab] = useState('resumo')
  const [activeFunil, setActiveFunil] = useState<string | null>(null)
  const [drawerOpen, setDrawerOpen] = useState(true)
  const [collapsed, setCollapsed] = useState(false)
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([])
  const theme = useTheme()

  // Breakpoints responsivos com memorização para evitar re-renderizações desnecessárias
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true})
  const isSmall = useMediaQuery(theme.breakpoints.down('md'), {noSsr: true})
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'), {noSsr: true})
  const isLarge = useMediaQuery(theme.breakpoints.down('xl'), {noSsr: true})

  const { rpaId } = useParams()

  const initialize = useStore((state) => state.initialize)

  const navigateToEntity = (type, entityId) => {
    // Extrair o ID da entidade (pode ser customizado conforme necessário)

    switch (type) {
      case NOTIFICATION_TYPES.CHAT:
        // Navegar para a tab de chat e selecionar o chat específico
        setActiveTab('resumo')
        // Você pode adicionar lógica adicional para selecionar o chat específico
        // Por exemplo, atualizar estado no ChatStore
        // useStore.getState().selectChat(id);
        break

      case NOTIFICATION_TYPES.TASK:
      case NOTIFICATION_TYPES.ACTIVITY:
        // Navegar para o calendário de atividades
        setActiveTab('calendar')
        // Lógica adicional para selecionar a atividade específica
        break

      case NOTIFICATION_TYPES.CARD:
        // Navegar para o kanban apropriado
        setActiveTab('kanban')
        if (entityId.includes('inadimplencia')) {
          setActiveFunil('lead-collection')
        } else {
          setActiveFunil('debt-collection')
        }
        // Lógica adicional para destacar o card específico
        break

      default:
        console.log('Tipo de entidade desconhecido:', type)
    }

    // Fechar o drawer no mobile quando navegar
    if (isXSmall) {
      setDrawerOpen(false)
    }
  }

  // 4. Expor a função de navegação globalmente (solução sem router)
  useEffect(() => {
    // Disponibiliza a função globalmente para ser usada pelos componentes de notificação
    ;(window as any).navigateToEntity = navigateToEntity

    // Cleanup
    return () => {
      delete (window as any).navigateToEntity
    }
  }, [navigateToEntity])

  useEffect(() => {
    initialize(rpaId)
  }, [initialize, rpaId])

  // Ref para rastrear o estado anterior (deve ser declarada fora do useEffect)
  const prevStateRef = React.useRef({ isXSmall: false, isSmall: false, isMedium: false, isLarge: false });
  
  // Configurações responsivas, com lógica melhorada para evitar loops e problemas de colapso
  useEffect(() => {
    const handleResponsiveLayout = () => {
      // Em telas muito pequenas (mobile), drawer fechado por padrão
      if (isXSmall) {
        setDrawerOpen(false);
        setCollapsed(false);
      } 
      // Em telas pequenas (tablet), menu colapsado por padrão
      else if (isSmall) {
        setDrawerOpen(true);
        setCollapsed(true);
      } 
      // Em telas médias e grandes, menu expandido por padrão
      else {
        setDrawerOpen(true);
        if (prevStateRef.current.isSmall || prevStateRef.current.isXSmall) {
          setCollapsed(false);
        }
      }
      
      // Atualizamos a ref com os novos valores
      prevStateRef.current = { isXSmall, isSmall, isMedium, isLarge };
    };
    
    // Aplicar as configurações iniciais baseadas no tamanho da tela
    handleResponsiveLayout();
    
    // Adicionar um listener para o redimensionamento da janela
    window.addEventListener('resize', handleResponsiveLayout);
    
    // Limpeza ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResponsiveLayout);
    };
  }, [isXSmall, isSmall, isMedium, isLarge]);

  const isWhatsAppRpa =
    rpaId === '663457c4-abce-4724-9fce-de4021e1717b' ||
    rpaId === '323457c4-abce-4724-9fce-de4021e1717b'

  // Definição das opções de menu para WhatsApp RPA com submenu para Funis
  const whatsAppOptions: MenuOption[] = [
    {
      value: 'resumo',
      label: data.tabs.operation.label,
      icon: <DashboardIcon />,
    },
    { value: 'calendar', label: 'Calendário', icon: <Calendar /> },
    { value: 'notificacoes', label: 'Notificações', icon: <NotificationAdd /> },
    {
      value: 'list_transmissao',
      label: 'Lista de transmissões',
      icon: <ListIcon />,
    },
    {
      value: 'chat_template',
      label: 'Template de Mensagens',
      icon: <TemplateIcon />,
    },
    { value: 'contatos', label: 'Contatos', icon: <ContactIcon /> },
    { value: 'empresas', label: 'Empresas', icon: <BusinessIcon /> },
    { value: 'produtos', label: 'Produtos', icon: <Inventory2Icon /> },
    {
      value: 'minha_empresa',
      label: 'Minha Empresa',
      icon: <HomeIcon />,
    },
    {
      value: 'erp',
      label: 'Gerenciamento ERP',
      icon: <Engineering />,
    },
    {
      value: 'funis',
      label: 'Funis',
      icon: <FunnelIcon />,
      hasSubMenu: true,
      subMenuItems: [
        {
          value: 'debt-collection',
          label: 'Leads',
          parentValue: 'funis',
        },
        {
          value: 'lead-collection',
          label: 'Inadimplência',
          parentValue: 'funis',
        },
      ],
    },
    {
      value: 'users',
      label: 'Usuários',
      icon: <User />,
    },
    {
      value: 'configuracoes',
      label: 'Configurações LLM',
      icon: <SettingsIcon />,
    },
    {
      value: 'telefone_config',
      label: 'Configurações de telefone',
      icon: <PhoneIcon />,
    },
    {
      value: 'email_config',
      label: 'Configurações de email',
      icon: <Email />,
    },
  ]

  // Definição das opções de menu para outros RPAs
  const standardOptions: MenuOption[] = [
    {
      value: 'resumo',
      label: data.tabs.operation.label,
      icon: <DashboardIcon />,
    },
    { value: 'configuracoes', label: 'Configurações', icon: <SettingsIcon /> },
  ]

  // Seleciona qual conjunto de opções de menu usar
  const menuOptions = isWhatsAppRpa ? whatsAppOptions : standardOptions

  // Manipulador para alteração da opção do menu - com preventDefault para evitar comportamento de scroll
  const handleMenuItemClick = (event: React.MouseEvent, value: string) => {
    // Previne comportamento padrão que poderia causar scroll
    event.preventDefault();
    
    const menuItem = menuOptions.find((option) => option.value === value)

    if (menuItem?.hasSubMenu) {
      // Se o item tem submenu, apenas toggle o submenu
      toggleSubMenu(value)
    } else {
      // Caso contrário, ativa a tab
      setActiveTab(value)
      setActiveFunil(null)

      if (isXSmall) {
        setDrawerOpen(false)
      }
    }
  }

  // Manipulador para clique em item de submenu - com preventDefault para evitar comportamento de scroll
  const handleSubMenuItemClick = (event: React.MouseEvent, item: SubMenuItem) => {
    // Previne comportamento padrão que poderia causar scroll
    event.preventDefault();
    
    if (item.parentValue === 'funis') {
      setActiveTab('kanban') // Ainda carrega o componente Kanban
      setActiveFunil(item.value) // Mas armazena qual funil está ativo
    }

    if (isXSmall) {
      setDrawerOpen(false)
    }
  }

  // Toggle do submenu aberto/fechado com prevenção de scroll automático
  const toggleSubMenu = (value: string) => {
    // Captura a posição de scroll atual antes de atualizar o estado
    const menuElement = document.querySelector('[data-menu-container]');
    const scrollPosition = menuElement?.scrollTop || 0;
    
    // Registra qual menu estamos abrindo/fechando para posicionar corretamente
    const currentMenuIndex = menuOptions.findIndex(option => option.value === value);
    
    setOpenSubMenus((prev) => {
      const isOpening = !prev.includes(value);
      if (!isOpening) {
        return prev.filter((item) => item !== value)
      } else {
        return [...prev, value]
      }
    });
    
    // Usa um timeout mínimo para permitir que o DOM seja atualizado antes de restaurar a posição
    setTimeout(() => {
      if (menuElement) {
        menuElement.scrollTop = scrollPosition;
      }
    }, 50);
  }

  // Toggle do drawer aberto/fechado
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  // Toggle do drawer expandido/collapsed com animação suave
  const toggleCollapsed = () => {
    // Usar o estado anterior para evitar problemas de atualização assíncrona
    setCollapsed(prev => !prev);
  }

  // Calcula a largura atual do drawer
  const currentDrawerWidth = collapsed ? collapsedDrawerWidth : drawerWidth

  // Determina o título da página atual
  const getCurrentPageTitle = () => {
    if (activeTab === 'kanban' && activeFunil) {
      if (activeFunil === 'debt-collection') {
        return 'Funil de Inadimplência'
      }
      return 'Funil'
    }
    return menuOptions.find((option) => option.value === activeTab)?.label || ''
  }


  // Componente MenuContent com design moderno e correções de responsividade
  const MenuContent = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: isXSmall ? '85vw' : currentDrawerWidth,
        bgcolor: '#fcfcfc', // Fundo ligeiramente off-white para reduzir o contraste
        borderRight: '1px solid rgba(0, 0, 0, 0.06)',
        boxShadow: '0 1px 3px rgba(0,0,0,0.02)',
        transition: 'width 0.3s ease', // Animação suave ao colapsar/expandir
        overflow: 'hidden', // Previne que o conteúdo transborde durante transições
        position: 'relative',
        zIndex: 1200, // Assegura que fique acima de outros elementos
      }}
    >
      {/* Cabeçalho com Logo e botão de colapso */}
      <Box
        sx={{
          py: 2,
          px: collapsed ? 1.5 : 2.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: collapsed ? 'center' : 'space-between',
          borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
          transition: 'padding 0.3s ease',
        }}
      >
        {!collapsed && (
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Box
              sx={{
                color: '#635AFF',
                fontSize: { xs: 16, sm: 18 },
                fontWeight: 700,
                letterSpacing: '-0.3px',
                background: 'linear-gradient(45deg, #635AFF, #4338ca)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Pórtico CRM
            </Box>
          </Box>
        )}

        {collapsed && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 36,
              height: 36,
              borderRadius: '10px',
              background: 'linear-gradient(135deg, #635AFF, #4338ca)',
              boxShadow: '0 2px 5px rgba(99, 90, 255, 0.3)',
            }}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              P
            </Typography>
          </Box>
        )}

        {/* Botão para colapsar com animação melhorada */}
        {!isXSmall && (
          <IconButton
            size="small"
            onClick={toggleCollapsed}
            sx={{
              color: 'text.secondary',
              width: 30,
              height: 30,
              borderRadius: '8px',
              transition: 'all 0.2s ease',
              '&:hover': {
                color: '#635AFF',
                bgcolor: 'rgba(99, 90, 255, 0.08)',
                transform: 'scale(1.05)',
              },
            }}
          >
            {collapsed ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronLeftIcon fontSize="small" />
            )}
          </IconButton>
        )}

        {isXSmall && (
          <IconButton
            size="small"
            onClick={toggleDrawer}
            sx={{
              color: 'text.secondary',
              width: 30,
              height: 30,
              borderRadius: '8px',
              '&:hover': {
                color: '#635AFF',
                bgcolor: 'rgba(99, 90, 255, 0.08)',
              },
            }}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      {/* Área de status e notificações - visível apenas no modo expandido */}
      {!collapsed && (
        <Box
          sx={{
            pt: 2,
            px: 2.5,
            pb: 1.5,
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
          }}
        >
          {/* Status com indicador e horário */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'rgba(16, 185, 129, 0.1)',
                borderRadius: '12px',
                px: 1,
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  bgcolor: '#10b981',
                  mr: 1,
                  animation: 'pulse 2s infinite', // Animação de pulsar para o status
                  '@keyframes pulse': {
                    '0%': { boxShadow: '0 0 0 0 rgba(16, 185, 129, 0.5)' },
                    '70%': { boxShadow: '0 0 0 6px rgba(16, 185, 129, 0)' },
                    '100%': { boxShadow: '0 0 0 0 rgba(16, 185, 129, 0)' },
                  },
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  color: '#10b981',
                  fontWeight: 600,
                  fontSize: '0.7rem',
                  textTransform: 'uppercase',
                  letterSpacing: '0.05em',
                }}
              >
                Ativo
              </Typography>
            </Box>
            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                fontSize: '0.7rem',
                bgcolor: 'rgba(0, 0, 0, 0.03)',
                borderRadius: '12px',
                px: 1,
                py: 0.5,
              }}
            >
              <ClockIcon sx={{ fontSize: 12 }} />
              {moment().format('HH:mm')}
            </Typography>
          </Box>

          {/* Título da página atual com destaque e sem quebra */}
          <Box
            sx={{
              mt: 0.5,
              mb: 1.5,
              position: 'relative',
              maxWidth: '100%',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                width: '2rem',
                height: '3px',
                borderRadius: '4px',
                background: 'linear-gradient(90deg, #635AFF, rgba(99, 90, 255, 0.3))',
              },
            }}
          >
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                fontWeight: 700,
                color: 'text.primary',
                fontSize: '0.95rem',
                lineHeight: 1.3,
                wordBreak: 'break-word',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '2.6em',
              }}
            >
              {getCurrentPageTitle()}
            </Typography>
          </Box>

          {/* Área de notificações e perfil */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 1.5,
              mb: 0.5,
            }}
          >
            <NotificationMenu />
            <Box sx={{ marginLeft: 'auto' }}>
              <HeaderMain />
            </Box>
          </Box>
        </Box>
      )}

      {/* Separador visual com gradiente sutil */}
      {!collapsed && (
        <Box
          sx={{
            px: 2.5,
            my: 0.5,
          }}
        >
          <Box
            sx={{
              height: '1px',
              background: 'linear-gradient(90deg, rgba(0,0,0,0.06), rgba(0,0,0,0.02) 50%, rgba(0,0,0,0))',
            }}
          />
        </Box>
      )}

      {/* Menu de navegação com design aprimorado e scroll suave - com fix para evitar scroll automático */}
      <Box
        data-menu-container
        sx={{
          overflow: 'auto',
          flexGrow: 1,
          py: 1.5,
          px: collapsed ? 0.5 : 1.5,
          scrollbarWidth: 'thin',
          scrollBehavior: 'auto', // Previne scroll suave automático
          overscrollBehavior: 'contain', // Evita scroll de inércia
          scrollbarColor: 'rgba(0, 0, 0, 0.1) transparent',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
          },
          // Efeito de fade no topo e no final do menu para indicar rolagem
          maskImage: 'linear-gradient(to bottom, transparent, black 10px, black calc(100% - 10px), transparent)',
          // Adiciona espaçamento entre os grupos de menu para melhor organização visual
          '& > div': {
            mb: 0.3,
            // Agrupa menus relacionados visualmente
            '&:not(:last-child):after': {
              content: '""',
              display: 'block',
              height: '1px',
              background: 'linear-gradient(90deg, rgba(0,0,0,0.03), transparent)',
              my: 1.5,
              mx: 2,
            },
          },
        }}
      >
        {menuOptions.map((option) => {
          // Verifica se este é um item de menu ativo
          const isActive =
            option.value === activeTab ||
            (option.hasSubMenu &&
              option.subMenuItems?.some(
                (item) => item.value === activeFunil && activeTab === 'kanban',
              ));

          // Verifica se o submenu está aberto
          const isSubMenuOpen = openSubMenus.includes(option.value);

          return (
            <React.Fragment key={option.value}>
              <Tooltip
                title={collapsed ? option.label : ''}
                placement="right"
                TransitionComponent={Zoom}
                arrow
                enterDelay={400}
              >
                <Box
                  sx={{
                    mb: option.hasSubMenu && isSubMenuOpen && !collapsed ? 0 : 0.75,
                    px: collapsed ? 0.75 : 0,
                  }}
                >
                  <Button
                    onClick={(event) => handleMenuItemClick(event, option.value)}
                    fullWidth
                    disableRipple
                    sx={{
                      minHeight: 42,
                      borderRadius: '10px',
                      py: collapsed ? 1.25 : 1,
                      px: collapsed ? 1 : 1.5,
                      justifyContent: collapsed ? 'center' : 'flex-start',
                      color: isActive ? '#635AFF' : 'text.secondary',
                      bgcolor: isActive ? 'rgba(99, 90, 255, 0.08)' : 'transparent',
                      textTransform: 'none',
                      transition: 'all 0.2s ease',
                      position: 'relative',
                      overflow: 'hidden',
                      border: isActive ? '1px solid rgba(99, 90, 255, 0.15)' : '1px solid transparent',
                      // Efeito de sombra sutil para item ativo
                      boxShadow: isActive ? '0 2px 6px rgba(99, 90, 255, 0.1)' : 'none',
                      // Efeito de hover aprimorado
                      '&:hover': {
                        bgcolor: isActive ? 'rgba(99, 90, 255, 0.12)' : 'rgba(0, 0, 0, 0.04)',
                        transform: collapsed ? 'scale(1.05)' : 'translateX(3px)',
                        boxShadow: '0 2px 8px rgba(99, 90, 255, 0.15)',
                        '& .MuiListItemIcon-root': {
                          transform: 'scale(1.1)',
                          color: '#635AFF',
                        },
                      },
                      // Indicador de item ativo - barra lateral mais estilizada
                      '&:before': isActive ? {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '4px',
                        height: '70%',
                        borderRadius: '0 4px 4px 0',
                        background: 'linear-gradient(180deg, #635AFF, #4338ca)',
                        boxShadow: '0 1px 3px rgba(99, 90, 255, 0.3)',
                      } : {},
                    }}
                  >
                    <ListItemIcon
                      className="MuiListItemIcon-root"
                      sx={{
                        minWidth: collapsed ? 0 : 32,
                        color: isActive ? '#635AFF' : 'text.secondary',
                        opacity: isActive ? 1 : 0.8,
                        fontSize: 20,
                        mr: collapsed ? 0 : 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'all 0.2s ease',
                        ...(isActive && {
                          filter: 'drop-shadow(0 1px 2px rgba(99, 90, 255, 0.4))',
                        }),
                        ...(collapsed && isActive && {
                          background: 'rgba(99, 90, 255, 0.1)',
                          borderRadius: '6px',
                          padding: '4px',
                        })
                      }}
                    >
                      {option.icon}
                    </ListItemIcon>

                    {!collapsed && (
                      <>
                        <ListItemText
                          primary={option.label}
                          primaryTypographyProps={{
                            fontWeight: isActive ? 600 : 500,
                            fontSize: '0.875rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            letterSpacing: isActive ? '0.01em' : 'normal',
                            ...(isActive && {
                              background: 'linear-gradient(90deg, #635AFF, #4338ca)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              display: 'inline',
                            })
                          }}
                          sx={{ 
                            ml: 0.5,
                            '& .MuiTypography-root': {
                              position: 'relative',
                              display: 'inline-block',
                            },
                          }}
                        />

                        {option.hasSubMenu && (
                          <Box 
                            sx={{ 
                              opacity: 0.8,
                              transition: 'transform 0.2s ease',
                              transform: isSubMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                          >
                            <ExpandMoreIcon sx={{ fontSize: 18 }} />
                          </Box>
                        )}
                      </>
                    )}
                  </Button>
                </Box>
              </Tooltip>

              {/* Submenu com design melhorado e layout vertical */}
              {!collapsed && option.hasSubMenu && (
                <Collapse in={isSubMenuOpen} timeout={300} unmountOnExit mountOnEnter={false}>
                  <Box
                    sx={{
                      pl: 2,
                      pr: 0.5,
                      mb: 0.75,
                      ml: 2,
                      borderLeft: '1px dashed rgba(99, 90, 255, 0.2)',
                      transition: 'all 0.3s ease',
                      maxWidth: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      overflowY: 'visible', // Assegura que submenus permaneçam visíveis
                      overflowX: 'visible',
                      position: 'static', // Assegura que a posição não seja alterada
                    }}
                  >
                    {option.subMenuItems?.map((subItem) => {
                      const isSubItemActive =
                        activeTab === 'kanban' && activeFunil === subItem.value;

                      return (
                        <Button
                          key={subItem.value}
                          onClick={(event) => handleSubMenuItemClick(event, subItem)}
                          fullWidth
                          disableRipple
                          sx={{
                            minHeight: 36,
                            borderRadius: '8px',
                            py: 0.75,
                            px: 1.5,
                            justifyContent: 'flex-start',
                            color: isSubItemActive ? '#635AFF' : 'text.secondary',
                            bgcolor: isSubItemActive ? 'rgba(99, 90, 255, 0.08)' : 'transparent',
                            textTransform: 'none',
                            my: 0.4,
                            transition: 'all 0.2s ease',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '&:hover': {
                              bgcolor: isSubItemActive ? 'rgba(99, 90, 255, 0.12)' : 'rgba(0, 0, 0, 0.04)',
                              transform: 'translateX(3px)',
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: 6,
                              height: 6,
                              borderRadius: '50%',
                              backgroundColor: isSubItemActive ? '#635AFF' : 'rgba(0, 0, 0, 0.2)',
                              mr: 1.5,
                              flexShrink: 0,
                              transition: 'all 0.2s ease',
                            }}
                          />
                          <Typography
                            variant="body2"
                            noWrap
                            sx={{
                              fontWeight: isSubItemActive ? 600 : 400,
                              fontSize: '0.8rem',
                              opacity: isSubItemActive ? 1 : 0.85,
                              transition: 'all 0.2s ease',
                              maxWidth: '100%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {subItem.label}
                          </Typography>
                        </Button>
                      );
                    })}
                  </Box>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </Box>

      {/* Rodapé com perfil do usuário - redesenhado */}
      <Box
        sx={{
          mt: 'auto',
          borderTop: '1px solid rgba(0, 0, 0, 0.06)',
          py: 1.5,
          px: collapsed ? 1 : 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: collapsed ? 'center' : 'flex-start',
          background: 'linear-gradient(to bottom, transparent, rgba(250,250,250,0.8) 20%)',
        }}
      >
        {collapsed ? (
          <Avatar
            sx={{
              width: 36,
              height: 36,
              background: 'linear-gradient(135deg, #635AFF, #4338ca)',
              color: 'white',
              fontSize: '0.85rem',
              fontWeight: 600,
              boxShadow: '0 2px 5px rgba(99, 90, 255, 0.3)',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 3px 8px rgba(99, 90, 255, 0.4)',
              },
            }}
          >
            A
          </Avatar>
        ) : (
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              width: '100%',
              py: 0.5,
              px: 1,
              borderRadius: '10px',
              transition: 'all 0.2s ease',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.02)',
              },
            }}
          >
            <Avatar
              sx={{
                width: 36,
                height: 36,
                background: 'linear-gradient(135deg, #635AFF, #4338ca)',
                color: 'white',
                fontSize: '0.85rem',
                fontWeight: 600,
                boxShadow: '0 2px 5px rgba(99, 90, 255, 0.2)',
              }}
            >
              A
            </Avatar>
            <Box sx={{ ml: 1.5, flex: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  color: 'text.primary',
                  fontSize: '0.85rem',
                }}
              >
                Admin
              </Typography>
            </Box>

            {/* Botão de configurações com tooltip */}
            <Tooltip title="Configurações" placement="top" arrow>
              <IconButton
                size="small"
                sx={{
                  width: 32,
                  height: 32,
                  borderRadius: '8px',
                  color: 'text.secondary',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    bgcolor: 'rgba(99, 90, 255, 0.08)',
                    color: '#635AFF',
                    transform: 'rotate(30deg)',
                  },
                }}
              >
                <SettingsIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  )
  const { showBanner } = useNotifications()

  const MainContent = () => {
    return (
      <>
        <NotificationBanner />
        <Box
          sx={{
            padding: '0px',
            display: 'flex',
            minHeight: '100vh',
            bgcolor: '#ffffff',
            // Adicionar margem superior quando o banner estiver visível
            pt: showBanner ? '60px' : 0,
            transition: 'padding-top 0.3s ease',
          }}
        >
          {/* Menu Lateral - Renderização condicional baseada no tamanho da tela */}
          {isXSmall ? (
            // Versão mobile: SwipeableDrawer com animações e gestos otimizados
            <>
              {/* Ícone de menu para abrir o drawer no modo mobile */}
              {!drawerOpen && (
                <Box 
                  sx={{
                    position: 'fixed',
                    left: 16,
                    top: 16,
                    zIndex: 1100,
                    display: { xs: 'block', sm: 'none' }
                  }}
                >
                  <IconButton
                    onClick={toggleDrawer}
                    sx={{
                      bgcolor: 'white',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      borderRadius: '10px',
                      width: 40,
                      height: 40,
                      color: '#635AFF',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 3px 12px rgba(0,0,0,0.15)',
                      },
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              )}
              
              <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer}
                onOpen={toggleDrawer}
                swipeAreaWidth={30}
                disableBackdropTransition={false}
                disableDiscovery={false}
                PaperProps={{
                  elevation: 4,
                }}
                ModalProps={{
                  keepMounted: true, // Melhor desempenho em mobile
                }}
                transitionDuration={{
                  enter: 300,
                  exit: 200
                }}
                sx={{
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    border: 'none',
                    boxShadow: '0 0 25px rgba(0,0,0,0.1)',
                    zIndex: theme.zIndex.appBar + 1,
                    borderRadius: '0 16px 16px 0',
                    overflowX: 'hidden',
                  },
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    backdropFilter: 'blur(3px)'
                  }
                }}
              >
                <MenuContent />
              </SwipeableDrawer>
            </>
          ) : (
            // Versão desktop: Drawer permanente com animações suaves
            <Drawer
              variant="permanent"
              open={true}
              sx={{
                width: currentDrawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                '& .MuiDrawer-paper': {
                  position: 'relative',
                  width: currentDrawerWidth,
                  boxSizing: 'border-box',
                  border: 'none',
                  boxShadow: 'none',
                  zIndex: theme.zIndex.appBar - 1,
                  overflowX: 'hidden',
                  backgroundColor: 'transparent',
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.easeInOut,
                    duration: 300,
                  }),
                },
              }}
            >
              <MenuContent />
            </Drawer>
          )}

          {/* Área de Conteúdo Principal com transições suaves */}
          <Box
            sx={{
              padding: '0px',
              flexGrow: 1,
              width: {
                xs: '100%',
                sm: `calc(100% - ${collapsed ? collapsedDrawerWidth : 0}px)`,
                md: `calc(100% - ${currentDrawerWidth}px)`,
              },
              transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.easeInOut,
                duration: 300,
              }),
              backgroundColor: '#ffffff',
              borderRadius: { xs: 0, sm: '16px 0 0 0' },
              boxShadow: { xs: 'none', sm: '-1px 0 3px rgba(0,0,0,0.01)' },
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            {/* REMOVED: AppBar - Content moved to sidebar */}

            {/* Conteúdo principal com padding e scroll adaptativo */}
            <Box
              sx={{
                p: { xs: 2, sm: 2.5, md: 3 },
                maxWidth: '100%',
                height: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0, 0, 0, 0.1) transparent',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                },
                transition: 'all 0.3s ease',
              }}
            >
              <If condition={activeTab === 'resumo'}>
                <ChatContainer />
              </If>
              <If condition={activeTab === 'minha_empresa'}>
                <CompanySettings />
              </If>
              <If condition={activeTab === 'empresas'}>
                <ListEmpresas />
              </If>
              <If condition={activeTab === 'produtos'}>
                <ListProdutos />
              </If>
              <If condition={activeTab === 'calendar'}>
                <ActivityCalendar />
              </If>
              <If condition={activeTab === 'notificacoes'}>
                <AllNotifications />
              </If>
              <If condition={activeTab === 'erp'}>
                <ERPManagement />
              </If>
              <If
                condition={
                  activeTab === 'kanban' && activeFunil === 'debt-collection'
                }
              >
                <KanbanApp boardType="lead" />
              </If>
              <If
                condition={
                  activeTab === 'kanban' && activeFunil === 'lead-collection'
                }
              >
                <KanbanApp boardType="inadimplencia" />
              </If>
              <If condition={activeTab === 'list_transmissao'}>
                <ListTransmissao />
              </If>
              <If condition={activeTab === 'contatos'}>
                <ListContatos />
              </If>
              <If condition={activeTab === 'chat_template'}>
                <ChatTemplate />
              </If>
              <If condition={activeTab === 'configuracoes'}>
                <Configuracoes />
              </If>
              <If condition={activeTab === 'users'}>
                <ManagementScreen />
              </If>
              <If condition={activeTab === 'telefone_config'}>
                <TelefoneConfig />
              </If>
              <If condition={activeTab === 'email_config'}>
                <EmailConfig />
              </If>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <NotificationProvider>
      <MainContent />
    </NotificationProvider>
  )
}

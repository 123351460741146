import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Box,
  Button,
  Typography,
  Chip,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  InputAdornment,
  Avatar,
  Fade,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  Person as PersonIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
  Add as AddIcon,
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  EditNote as EditNoteIcon,
  ContactPhone as ContactPhoneIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { FormSection, SectionTitle, FormFieldGroup } from './styles';
import { Contact, FormErrors } from './types/CardTypes';
import axios from 'axios';
import { llmEndpoint } from 'infra';
import { useParams } from 'react-router-dom';

interface ApiContact {
  id: string;
  name: string;
  email: string | null;
  number: string;
  description?: string;
  tags?: string;
  type?: string;
  active?: boolean;
  companyId?: string;
}

interface ContactSectionProps {
  contact: Contact;
  contatoId: string;
  errors: FormErrors;
  searchContactTerm: string;
  setSearchContactTerm: (term: string) => void;
  setShowNewContactDialog: (show: boolean) => void;
  handleContactChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectContact: (contact: any) => void;
  onEditContact?: (contactId: string) => void; // Novo prop para editar contato
  // Props para vinculação com empresa
  empresaId?: string;
  companyContacts?: any[];
  isContactLinkedToCompany?: (contactId: string) => boolean;
  isLoadingRelations?: boolean;
  contactCompany?: any | null;
  // Para evitar duplicação de formulário
  hideContactForm?: boolean;
}

const ContactSection: React.FC<ContactSectionProps> = ({
  contact,
  contatoId,
  errors,
  searchContactTerm,
  setSearchContactTerm,
  setShowNewContactDialog,
  handleContactChange,
  handleSelectContact,
  onEditContact,
  // Props para vinculação com empresa
  empresaId,
  companyContacts = [],
  isContactLinkedToCompany = () => false,
  isLoadingRelations = false,
  contactCompany = null,
  // Para evitar duplicação de formulário
  hideContactForm = false,
}) => {
  const { rpaId } = useParams();
  const [filteredContatos, setFilteredContatos] = useState<ApiContact[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<ApiContact | null>(null);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Verificar se existe um contato selecionado com o ID atual
  useEffect(() => {
    if (contatoId) {
      // Se já temos um contato selecionado pelo ID, verificamos se precisamos buscar os detalhes
      const fetchContactDetails = async () => {
        try {
          console.log('Fetching contact details for ID:', contatoId);
          const response = await axios.get(
            `${llmEndpoint.url}/whatsapp/contacts/${contatoId}`,
            {
              headers: {
                accept: '*/*',
              },
            }
          );
          
          if (response.data) {
            console.log('Contact details fetched:', response.data);
            setSelectedContact(response.data);
          }
        } catch (error) {
          console.error('Error fetching contact details:', error);
        }
      };
      
      // Só buscamos detalhes se ainda não tivermos o objeto do contato
      if (!selectedContact || selectedContact.id !== contatoId) {
        fetchContactDetails();
      }
    } else {
      setSelectedContact(null);
    }
  }, [contatoId]);

  // Função para formatar número de telefone
  const formatPhoneNumber = (phone: string): string => {
    if (!phone) return '';
    
    // Remove caracteres não numéricos
    const numericOnly = phone.replace(/\D/g, '');
    
    // Se o número começar com 55 (Brasil) e tiver mais de 11 dígitos
    if (numericOnly.startsWith('55') && numericOnly.length >= 12) {
      const ddd = numericOnly.slice(2, 4);
      const numero = numericOnly.slice(4);
      
      // Se for número de celular (9 dígitos)
      if (numero.length === 9) {
        return `(${ddd}) ${numero.slice(0, 5)}-${numero.slice(5)}`;
      }
      
      // Se for número fixo (8 dígitos)
      if (numero.length === 8) {
        return `(${ddd}) ${numero.slice(0, 4)}-${numero.slice(4)}`;
      }
    }
    
    // Retorna o número como está se não reconhecer o padrão
    return phone;
  };

  const fetchContacts = async (term: string) => {
    if (term.length < 3) return;
    
    setIsLoading(true);
    try {
      // Se uma empresa estiver selecionada, primeiro verificamos se queremos mostrar apenas os contatos
      // vinculados a ela ou todos os contatos
      if (empresaId && companyContacts && companyContacts.length > 0) {
        // Buscar apenas nos contatos da empresa
        const filteredCompanyContacts = companyContacts.filter(contact => 
          contact.name && contact.name.toLowerCase().includes(term.toLowerCase())
        );
        
        // Se encontramos contatos correspondentes da empresa, usamos eles
        if (filteredCompanyContacts.length > 0) {
          console.log('Found matching company contacts:', filteredCompanyContacts);
          setFilteredContatos(filteredCompanyContacts);
          setIsLoading(false);
          return;
        }
        
        // Se não encontramos na empresa, continuamos com a busca global
        console.log('No matching company contacts, continuing with global search');
      }
      
      // Busca global de contatos
      const response = await axios.get(
        `${llmEndpoint.url}/whatsapp/contacts/${rpaId}/search`,
        {
          params: {
            term,
            page: 0,
            size: 10,
            sort: 'name',
            direction: 'asc',
          },
          headers: {
            accept: '*/*',
          },
        }
      );
      
      if (response.data && response.data.content) {
        // Se uma empresa já estiver selecionada, dá preferência aos contatos vinculados a ela
        if (empresaId && companyContacts && companyContacts.length > 0) {
          // Ordenar a lista para que os contatos da empresa apareçam primeiro
          const sortedContacts = [...response.data.content].sort((a, b) => {
            const aIsLinked = companyContacts.some(c => c.id === a.id);
            const bIsLinked = companyContacts.some(c => c.id === b.id);
            
            if (aIsLinked && !bIsLinked) return -1;
            if (!aIsLinked && bIsLinked) return 1;
            return 0;
          });
          
          setFilteredContatos(sortedContacts);
        } else {
          setFilteredContatos(response.data.content);
        }
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setFilteredContatos([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change with debounce
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchContactTerm(term);
    
    // Clear any existing timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    
    // Se temos uma empresa selecionada e o termo está vazio, mostramos os contatos da empresa
    if (empresaId && term.length === 0) {
      debounceTimerRef.current = setTimeout(() => {
        fetchContacts("");
      }, 500);
      return;
    }
    
    // Only start a new search if term has at least 3 characters
    if (term.length >= 3) {
      debounceTimerRef.current = setTimeout(() => {
        fetchContacts(term);
      }, 500); // 500ms debounce
    } else {
      setFilteredContatos([]);
    }
  };

  // Manipulador para aplicar contato selecionado ao formulário
  const applySelectedContact = (apiContact: ApiContact) => {
    // Cria um objeto de contato no formato esperado pelo formulário
    const formContact = {
      id: apiContact.id,
      name: apiContact.name || '',
      email: apiContact.email || '',
      phone: apiContact.number || '',
      whatsapp: apiContact.number || '',
    };
    
    // Chama a função de seleção de contato passada como prop
    handleSelectContact(formContact);
    
    // Atualiza o estado local
    setSelectedContact(apiContact);
  };

  // Handle search button/icon click or Enter key press
  const handleSearch = () => {
    // Se temos uma empresa selecionada, permitimos buscar mesmo com campo vazio
    // para listar todos os contatos da empresa
    if (empresaId || searchContactTerm.length >= 3) {
      // Clear any existing timer
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      fetchContacts(searchContactTerm);
    }
  };

  // Handle Enter key press in search input
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);
  
  // Debug useEffect para monitorar o estado do contato e contatoId
  useEffect(() => {
    console.log('Current contact state:', contact);
    console.log('Current contatoId:', contatoId);
    console.log('Selected contact:', selectedContact);
  }, [contact, contatoId, selectedContact]);

  // Preenchimento do formulário quando um contato é selecionado
  useEffect(() => {
    if (selectedContact) {
      // Simula um evento de mudança para preencher os campos do formulário
      const fields = [
        { name: 'name', value: selectedContact.name || '' },
        { name: 'email', value: selectedContact.email || '' },
        { name: 'phone', value: selectedContact.number || '' },
        { name: 'whatsapp', value: selectedContact.number || '' }
      ];
      
      // Preenche cada campo do formulário
      fields.forEach(field => {
        const syntheticEvent = {
          target: {
            name: field.name,
            value: field.value
          }
        } as React.ChangeEvent<HTMLInputElement>;
        
        handleContactChange(syntheticEvent);
      });
    }
  }, [selectedContact]);

  return (
    <FormSection elevation={0}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <SectionTitle>
          <ContactPhoneIcon />
          Informações de Contato
        </SectionTitle>
        
        {empresaId && (
          <Chip
            icon={<CheckCircleIcon fontSize="small" />}
            label="Vinculado à empresa"
            color="success"
            variant="outlined"
            size="small"
            sx={{ 
              height: 24, 
              borderRadius: '6px',
              visibility: (contatoId && isContactLinkedToCompany(contatoId)) ? 'visible' : 'hidden'
            }}
          />
        )}
      </Box>

      {/* Informações sobre a empresa selecionada e seus contatos */}
      {empresaId && (
        <Box 
          sx={{ 
            mb: 3, 
            py: 2.5,
            px: 3,
            borderRadius: '12px',
            background: 'linear-gradient(135deg, rgba(76, 175, 80, 0.05), rgba(76, 175, 80, 0.1))',
            boxShadow: '0 4px 15px rgba(76, 175, 80, 0.1)',
            border: '1px solid rgba(76, 175, 80, 0.2)',
            position: 'relative',
            overflow: 'hidden',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '6px',
              height: '100%',
              background: 'linear-gradient(to bottom, #4caf50, rgba(76, 175, 80, 0.5))',
              borderTopLeftRadius: '12px',
              borderBottomLeftRadius: '12px',
            }
          }}
        >
          <Typography 
            variant="subtitle1" 
            sx={{ 
              mb: 1.5, 
              color: '#2e7d32', 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              fontWeight: 600,
            }}
          >
            <Avatar
              sx={{
                bgcolor: '#4caf50',
                width: 32,
                height: 32,
                boxShadow: '0 3px 8px rgba(76, 175, 80, 0.25)',
              }}
            >
              <CheckCircleIcon fontSize="small" sx={{ color: 'white' }} />
            </Avatar>
            Empresa selecionada
          </Typography>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pl: 1 }}>
            {companyContacts && companyContacts.length > 0 ? (
              <>
                <Typography variant="body2" sx={{ color: '#333' }}>
                  A empresa possui <strong>{companyContacts.length}</strong> contato{companyContacts.length !== 1 ? 's' : ''} vinculado{companyContacts.length !== 1 ? 's' : ''}
                </Typography>
                
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 1, 
                    mt: 1,
                    background: 'rgba(255,255,255,0.5)',
                    p: 1.5,
                    borderRadius: '10px',
                    maxHeight: '180px',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'rgba(255,255,255,0.7)',
                      borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'rgba(76, 175, 80, 0.3)',
                      borderRadius: '10px',
                      '&:hover': {
                        background: 'rgba(76, 175, 80, 0.5)',
                      }
                    }
                  }}
                >
                  {companyContacts.slice(0, 3).map((contact) => (
                    <Button
                      key={contact.id}
                      variant="text"
                      color="success"
                      size="small"
                      startIcon={
                        <Avatar
                          sx={{
                            bgcolor: 'rgba(76, 175, 80, 0.1)',
                            width: 24,
                            height: 24,
                          }}
                        >
                          <PersonIcon fontSize="small" sx={{ color: '#4caf50', fontSize: '14px' }} />
                        </Avatar>
                      }
                      onClick={() => applySelectedContact(contact)}
                      sx={{ 
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        borderRadius: '8px',
                        py: 1,
                        px: 1.5,
                        color: '#2e7d32',
                        transition: 'all 0.2s',
                        '&:hover': { 
                          bgcolor: 'rgba(76, 175, 80, 0.08)',
                          transform: 'translateX(3px)'
                        }
                      }}
                    >
                      {contact.name}
                    </Button>
                  ))}
                  
                  {companyContacts.length > 3 && (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      startIcon={<PersonIcon />}
                      onClick={() => {
                        setSearchContactTerm("");
                        fetchContacts("");
                      }}
                      sx={{
                        mt: 1,
                        borderRadius: '8px',
                        fontWeight: 500,
                        textTransform: 'none',
                        borderColor: 'rgba(76, 175, 80, 0.5)',
                        '&:hover': {
                          borderColor: '#4caf50',
                          backgroundColor: 'rgba(76, 175, 80, 0.08)',
                        }
                      }}
                    >
                      Ver todos os contatos ({companyContacts.length})
                    </Button>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Typography variant="body2" sx={{ color: '#555' }}>
                  Esta empresa não possui contatos vinculados.
                </Typography>
                
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => setShowNewContactDialog(true)}
                  sx={{
                    mt: 1,
                    borderRadius: '8px',
                    fontWeight: 500,
                    textTransform: 'none',
                    borderColor: 'rgba(76, 175, 80, 0.5)',
                    '&:hover': {
                      borderColor: '#4caf50',
                      backgroundColor: 'rgba(76, 175, 80, 0.08)',
                    }
                  }}
                >
                  Adicionar contato à empresa
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}

      {/* Busca de contato existente */}
      <Box sx={{ display: 'flex', gap: 1.5, mb: 2 }}>
        <TextField
          placeholder={empresaId ? "Buscar contatos da empresa ou outros..." : "Buscar contato..."}
          fullWidth
          variant="outlined"
          value={searchContactTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={handleSearch}
                  edge="start"
                  disabled={searchContactTerm.length < 3 && !empresaId}
                  size="small"
                  sx={{
                    transition: 'all 0.2s',
                    '&:hover': {
                      backgroundColor: 'rgba(99, 90, 255, 0.08)',
                      transform: 'scale(1.1)',
                    }
                  }}
                >
                  <SearchIcon 
                    color={(searchContactTerm.length < 3 && !empresaId) ? "disabled" : "primary"} 
                    fontSize="small" 
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#635AFF',
                borderWidth: '1px',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#635AFF',
                borderWidth: '1px',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#635AFF',
            },
          }}
        />

        <Button
          variant="contained"
          color={empresaId ? "success" : "primary"}
          onClick={() => setShowNewContactDialog(true)}
          startIcon={<AddIcon />}
          size="small"
          sx={{ 
            minWidth: '120px',
            borderRadius: '10px',
            background: empresaId 
              ? 'linear-gradient(135deg, #4caf50, #2e7d32)'
              : 'linear-gradient(135deg, #635AFF, #4338ca)',
            boxShadow: empresaId
              ? '0 4px 10px rgba(76, 175, 80, 0.2)'
              : '0 4px 10px rgba(99, 90, 255, 0.2)',
            fontWeight: 500,
            transition: 'all 0.2s',
            '&:hover': {
              boxShadow: empresaId
                ? '0 6px 15px rgba(76, 175, 80, 0.3)'
                : '0 6px 15px rgba(99, 90, 255, 0.3)',
              transform: 'translateY(-2px)',
            }
          }}
        >
          Novo
        </Button>
      </Box>
      
      {/* Texto auxiliar que muda conforme contexto */}
      {empresaId && (
        <Typography 
          variant="caption" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0.5,
            mb: 2, 
            color: '#4caf50',
            bgcolor: 'rgba(76, 175, 80, 0.08)',
            py: 0.75,
            px: 1.5,
            borderRadius: '6px',
            fontWeight: 500,
          }}
        >
          <InfoIcon fontSize="small" sx={{ fontSize: 16 }} />
          {searchContactTerm.length === 0 ? 
            "Clique na lupa para ver todos os contatos da empresa" : 
            searchContactTerm.length < 3 ? 
              "Digite pelo menos 3 caracteres ou clique na lupa para buscar" :
              "Buscando contatos..."}
        </Typography>
      )}

      {/* Mensagem informativa sobre requisito de 3 caracteres (só mostra quando não tem empresa selecionada) */}
      {!empresaId && searchContactTerm.length > 0 && searchContactTerm.length < 3 && (
        <Typography 
          variant="caption" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0.5,
            mb: 2,
            color: '#635AFF',
            bgcolor: 'rgba(99, 90, 255, 0.08)',
            py: 0.75,
            px: 1.5,
            borderRadius: '6px',
            fontWeight: 500,
          }}
        >
          <InfoIcon fontSize="small" sx={{ fontSize: 16 }} />
          Digite pelo menos 3 caracteres para buscar
        </Typography>
      )}

      {/* Indicador de carregamento */}
      {isLoading && (
        <Typography 
          variant="caption" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0.5,
            mb: 2,
            color: '#635AFF',
            bgcolor: 'rgba(99, 90, 255, 0.08)',
            py: 0.75,
            px: 1.5,
            borderRadius: '6px',
            fontWeight: 500,
          }}
        >
          <CircularProgress size={14} thickness={5} color="inherit" sx={{ mr: 1 }} />
          Buscando contatos...
        </Typography>
      )}

      {/* Lista de contatos encontrados */}
      {searchContactTerm.length >= 3 && !isLoading && filteredContatos.length > 0 && (
        <Fade in={!!searchContactTerm && searchContactTerm.length >= 3 && filteredContatos.length > 0}>
          <Paper
            elevation={2}
            sx={{ 
              maxHeight: '250px', 
              overflow: 'auto', 
              mb: 3, 
              position: 'relative', 
              zIndex: 10,
              borderRadius: '12px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
              background: 'linear-gradient(to bottom, white, #fcfcfc)',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f8f9fa',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(99, 90, 255, 0.2)',
                borderRadius: '10px',
                '&:hover': {
                  background: 'rgba(99, 90, 255, 0.3)',
                }
              }
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography variant="caption" sx={{ display: 'block', color: '#666', px: 2, py: 1 }}>
                {filteredContatos.length} contato{filteredContatos.length !== 1 ? 's' : ''} encontrado{filteredContatos.length !== 1 ? 's' : ''}
              </Typography>
            </Box>
            <List>
              {filteredContatos.map((contato) => (
                <ListItem
                  key={contato.id}
                  button
                  onClick={() => {
                    // Usa a função centralizada para aplicar o contato selecionado
                    applySelectedContact(contato);
                    
                    // Limpa a pesquisa
                    setSearchContactTerm('');
                    setFilteredContatos([]);
                  }}
                  selected={contatoId === contato.id}
                  sx={{
                    borderRadius: '8px',
                    m: 0.7,
                    p: 1.2,
                    transition: 'all 0.2s',
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(99, 90, 255, 0.08)',
                      '&:hover': {
                        backgroundColor: 'rgba(99, 90, 255, 0.12)',
                      },
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.03)',
                      transform: 'translateX(3px)',
                    },
                    // Destaque para contatos vinculados à empresa
                    ...(empresaId && isContactLinkedToCompany(contato.id) && {
                      borderLeft: '4px solid #4caf50',
                      pl: 1.5,
                      boxShadow: '0 2px 8px rgba(76, 175, 80, 0.1)',
                      backgroundColor: 'rgba(76, 175, 80, 0.03)',
                      '&:hover': {
                        backgroundColor: 'rgba(76, 175, 80, 0.08)',
                      },
                    }),
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '42px' }}>
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        bgcolor:
                          contatoId === contato.id
                            ? '#635AFF'
                            : empresaId && isContactLinkedToCompany(contato.id)
                              ? '#4caf50'
                              : '#9e9e9e',
                        fontSize: '14px',
                        boxShadow: 
                          contatoId === contato.id
                            ? '0 3px 8px rgba(99, 90, 255, 0.25)'
                            : empresaId && isContactLinkedToCompany(contato.id)
                              ? '0 3px 8px rgba(76, 175, 80, 0.25)'
                              : 'none',
                      }}
                    >
                      {contato.name?.charAt(0)?.toUpperCase() || '?'}
                    </Avatar>
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontWeight: contatoId === contato.id ? 600 : 500,
                            fontSize: '0.95rem',
                            color: contatoId === contato.id 
                              ? '#635AFF' 
                              : empresaId && isContactLinkedToCompany(contato.id)
                                ? '#2e7d32'
                                : '#333'
                          }}
                        >
                          {contato.name}
                        </Typography>
                        {empresaId && isContactLinkedToCompany(contato.id) && (
                          <Chip 
                            size="small" 
                            variant="outlined" 
                            color="success" 
                            label="Vinculado"
                            sx={{ 
                              height: '18px', 
                              fontSize: '10px', 
                              borderRadius: '6px',
                              '& .MuiChip-label': { 
                                px: 1, 
                                py: 0 
                              } 
                            }}
                          />
                        )}
                      </Box>
                    }
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1.5, mt: 0.5 }}>
                        {contato.email && (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <EmailIcon 
                              fontSize="small" 
                              sx={{ 
                                color: 'rgba(99, 90, 255, 0.6)', 
                                fontSize: '0.9rem' 
                              }} 
                            />
                            <Typography variant="body2" sx={{ fontSize: '0.8rem', color: '#666' }}>
                              {contato.email}
                            </Typography>
                          </Box>
                        )}
                        {contato.number && (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <PhoneIcon 
                              fontSize="small" 
                              sx={{ 
                                color: 'rgba(76, 175, 80, 0.6)', 
                                fontSize: '0.9rem' 
                              }} 
                            />
                            <Typography variant="body2" sx={{ fontSize: '0.8rem', color: '#666' }}>
                              {formatPhoneNumber(contato.number)}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                    primaryTypographyProps={{
                      style: {
                        fontWeight: contatoId === contato.id ? 600 : 400,
                      },
                    }}
                    sx={{ ml: 0.5 }}
                  />

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {contatoId === contato.id && (
                      <CheckCircleIcon
                        sx={{ color: '#635AFF' }}
                        fontSize="small"
                      />
                    )}
                    {/* Indicador de contato vinculado à empresa */}
                    {empresaId && isContactLinkedToCompany(contato.id) && contatoId !== contato.id && (
                      <CheckCircleIcon
                        color="success"
                        fontSize="small"
                        sx={{ opacity: 0.8 }}
                      />
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Fade>
      )}

      {/* Exibir contato não encontrado */}
      {searchContactTerm.length >= 3 && !isLoading && filteredContatos.length === 0 && (
        <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
          <ListItemText
            primary="Nenhum contato encontrado"
            secondary={
              empresaId 
                ? "Use o botão NOVO para criar um contato para esta empresa"
                : "Use o botão NOVO para criar um contato"
            }
            primaryTypographyProps={{
              style: { textAlign: 'center', color: '#757575' },
            }}
            secondaryTypographyProps={{
              style: { textAlign: 'center' },
            }}
          />
          {/* Adicionar botão específico para empresa selecionada */}
          {empresaId && (
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="success"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => setShowNewContactDialog(true)}
              >
                Criar contato vinculado
              </Button>
            </Box>
          )}
        </Paper>
      )}

      {/* Exibir contato selecionado como card */}
      {(contatoId || selectedContact) && (
        <Box sx={{ 
          mb: 3, 
          mt: 2, 
          p: 3, 
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
          border: empresaId && contatoId && isContactLinkedToCompany(contatoId)
            ? '1px solid rgba(76, 175, 80, 0.3)'
            : '1px solid rgba(99, 90, 255, 0.15)',
          background: empresaId && contatoId && isContactLinkedToCompany(contatoId)
            ? 'linear-gradient(135deg, rgba(76, 175, 80, 0.03), rgba(76, 175, 80, 0.08))'
            : 'linear-gradient(135deg, rgba(99, 90, 255, 0.03), rgba(99, 90, 255, 0.08))',
          position: 'relative',
          transition: 'all 0.2s',
          overflow: 'hidden',
          '&:hover': {
            boxShadow: '0 8px 25px rgba(0,0,0,0.08)',
            transform: 'translateY(-2px)',
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '6px',
            height: '100%',
            background: empresaId && contatoId && isContactLinkedToCompany(contatoId)
              ? 'linear-gradient(to bottom, #4caf50, rgba(76, 175, 80, 0.5))'
              : 'linear-gradient(to bottom, #635AFF, rgba(99, 90, 255, 0.5))',
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px',
          }
        }}>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              mb: 2, 
              fontWeight: 600, 
              color: empresaId && contatoId && isContactLinkedToCompany(contatoId) ? '#2e7d32' : '#4338ca',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
             }}
          >
            <Avatar sx={{
              width: 36,
              height: 36,
              bgcolor: empresaId && contatoId && isContactLinkedToCompany(contatoId) 
                ? '#4caf50'  // Verde se estiver vinculado à empresa
                : '#635AFF',  // Roxo padrão
              boxShadow: empresaId && contatoId && isContactLinkedToCompany(contatoId)
                ? '0 3px 8px rgba(76, 175, 80, 0.25)'
                : '0 3px 8px rgba(99, 90, 255, 0.25)',
            }}>
              {selectedContact?.name?.charAt(0).toUpperCase() || contact.name?.charAt(0).toUpperCase() || '?'}
            </Avatar>
            {selectedContact?.name || contact.name || 'Contato Selecionado'}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            <Button
              size="small"
              variant="outlined"
              startIcon={<EditNoteIcon />}
              onClick={contatoId && onEditContact ? () => onEditContact(contatoId) : undefined}
              disabled={!contatoId || !onEditContact}
              sx={{ 
                borderRadius: '8px',
                fontSize: '0.775rem',
                textTransform: 'none',
                color: empresaId && contatoId && isContactLinkedToCompany(contatoId) ? '#2e7d32' : '#4338ca',
                borderColor: empresaId && contatoId && isContactLinkedToCompany(contatoId) ? 'rgba(76, 175, 80, 0.5)' : 'rgba(99, 90, 255, 0.5)',
                '&:hover': {
                  borderColor: empresaId && contatoId && isContactLinkedToCompany(contatoId) ? '#4caf50' : '#635AFF',
                  backgroundColor: empresaId && contatoId && isContactLinkedToCompany(contatoId) ? 'rgba(76, 175, 80, 0.08)' : 'rgba(99, 90, 255, 0.08)',
                }
              }}
            >
              Editar
            </Button>
            
            <Tooltip title="Remover seleção de contato">
              <Button
                size="small"
                variant="text"
                color="error"
                onClick={() => {
                  handleSelectContact({
                    id: '',
                    name: '',
                    email: '',
                    phone: '',
                    whatsapp: '',
                  });
                  setSelectedContact(null);
                }}
                sx={{ 
                  borderRadius: '8px',
                  fontSize: '0.775rem',
                  textTransform: 'none',
                  minWidth: 'auto',
                  px: 1.5
                }}
              >
                Remover
              </Button>
            </Tooltip>
            
            {/* Indicador de vínculo com empresa */}
            {empresaId && contatoId && isContactLinkedToCompany(contatoId) && (
              <Chip
                icon={<CheckCircleIcon fontSize="small" />}
                label="Vinculado à empresa"
                color="success"
                variant="outlined"
                size="small"
                sx={{ borderRadius: '8px' }}
              />
            )}
          </Box>
          
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1.5,
              backgroundColor: 'rgba(255,255,255,0.6)',
              borderRadius: '8px',
              p: 2
            }}
          >
            {selectedContact?.email && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Avatar
                  sx={{
                    bgcolor: 'rgba(99, 90, 255, 0.1)',
                    width: 32,
                    height: 32,
                  }}
                >
                  <EmailIcon fontSize="small" sx={{ color: '#635AFF' }} />
                </Avatar>
                <Typography variant="body2" sx={{ color: '#333' }}>{selectedContact.email}</Typography>
              </Box>
            )}
            {selectedContact?.number && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Avatar
                  sx={{
                    bgcolor: 'rgba(76, 175, 80, 0.1)',
                    width: 32,
                    height: 32,
                  }}
                >
                  <PhoneIcon fontSize="small" sx={{ color: '#4caf50' }} />
                </Avatar>
                <Typography variant="body2" sx={{ color: '#333' }}>{formatPhoneNumber(selectedContact.number)}</Typography>
              </Box>
            )}
            {selectedContact?.tags && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Typography variant="body2" sx={{ fontWeight: 600, color: '#555' }}>Tags:</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selectedContact.tags.split(',').map((tag, index) => (
                    <Chip 
                      key={index} 
                      label={tag.trim()} 
                      size="small" 
                      variant="outlined"
                      sx={{ 
                        borderRadius: '6px',
                        height: '22px',
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.7rem'
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {selectedContact?.type && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Typography variant="body2" sx={{ fontWeight: 600, color: '#555' }}>Tipo:</Typography>
                <Chip 
                  label={selectedContact.type} 
                  size="small" 
                  color="primary"
                  sx={{ 
                    borderRadius: '6px',
                    height: '22px',
                    backgroundColor: '#635AFF',
                    '& .MuiChip-label': {
                      px: 1,
                      fontSize: '0.7rem'
                    }
                  }}
                />
              </Box>
            )}
            
            {/* Exibir informações da empresa vinculada ao contato (se estiver disponível) */}
            {contatoId && contactCompany && (
              <Box sx={{ 
                mt: 1, 
                pt: 2, 
                borderTop: '1px dashed rgba(0,0,0,0.1)',
                display: 'flex',
                alignItems: 'center',
                gap: 1.5
              }}>
                <Avatar
                  sx={{
                    bgcolor: 'rgba(76, 175, 80, 0.1)',
                    width: 32,
                    height: 32,
                  }}
                >
                  <CheckCircleIcon fontSize="small" sx={{ color: '#4caf50' }} />
                </Avatar>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 600, color: '#2e7d32' }}>
                    Vinculado à empresa
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#333' }}>
                    {contactCompany.name || 'Empresa vinculada'}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {/* Formulário de contato - escondido se o contato estiver vinculado à empresa */}
      {!hideContactForm && (
        <FormFieldGroup>
          <TextField
            name="name"
            label="Nome do contato"
            fullWidth
            variant="outlined"
            value={contact.name}
            onChange={handleContactChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon color="action" fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#635AFF',
                  borderWidth: '1px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#635AFF',
                  borderWidth: '1px',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#635AFF',
              },
            }}
          />

          <TextField
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            value={contact.email}
            onChange={handleContactChange}
            error={!!errors['contact.email']}
            helperText={errors['contact.email']}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon color="action" fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#635AFF',
                  borderWidth: '1px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#635AFF',
                  borderWidth: '1px',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#635AFF',
              },
            }}
          />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              name="phone"
              label="Telefone"
              fullWidth
              variant="outlined"
              value={contact.phone}
              onChange={handleContactChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="action" fontSize="small" />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#635AFF',
                    borderWidth: '1px',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#635AFF',
                    borderWidth: '1px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#635AFF',
                },
              }}
            />

            <TextField
              name="whatsapp"
              label="WhatsApp"
              fullWidth
              variant="outlined"
              value={contact.whatsapp}
              onChange={handleContactChange}
              error={!!errors['contact.whatsapp']}
              helperText={errors['contact.whatsapp']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WhatsAppIcon sx={{ color: '#25D366' }} fontSize="small" />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#635AFF',
                    borderWidth: '1px',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#635AFF',
                    borderWidth: '1px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#635AFF',
                },
              }}
            />
          </Box>
        </FormFieldGroup>
      )}
      
      {/* Informativo se o formulário estiver oculto */}
      {hideContactForm && contatoId && (
        <Box 
          sx={{ 
            p: 2, 
            borderRadius: '10px', 
            backgroundColor: 'rgba(76, 175, 80, 0.05)', 
            border: '1px dashed rgba(76, 175, 80, 0.3)',
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <InfoIcon fontSize="small" sx={{ color: '#4caf50' }} />
          <Typography variant="body2" sx={{ color: '#2e7d32' }}>
            Os dados do contato são gerenciados através da empresa vinculada
          </Typography>
        </Box>
      )}
    </FormSection>
  );
};

export default ContactSection;
import React from 'react';
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormGroup,
  FormHelperText
} from '@mui/material';
import { Settings } from '@mui/icons-material';
import { AVAILABLE_VENDORS } from './types';
import * as S from './styles';

interface BasicInfoSectionProps {
  erpName: string;
  setErpName: (name: string) => void;
  erpVendor: string;
  setErpVendor: (vendor: string) => void;
  erpDescription: string;
  setErpDescription: (description: string) => void;
  isActive: boolean;
  setIsActive: (active: boolean) => void;
  formErrors: {
    name: boolean;
    appKey: boolean;
    appSecret: boolean;
  };
  isEdit: boolean;
  loading: boolean;
}

const BasicInfoSection: React.FC<BasicInfoSectionProps> = ({
  erpName,
  setErpName,
  erpVendor,
  setErpVendor,
  erpDescription,
  setErpDescription,
  isActive,
  setIsActive,
  formErrors,
  isEdit,
  loading
}) => {
  return (
    <S.FormSection>
      <S.SectionTitle>
        <Settings fontSize="small" sx={{ mr: 1, verticalAlign: 'middle' }} />
        Informações Básicas
      </S.SectionTitle>
      
      <Box sx={{ mb: 2 }}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="vendor-select-label">Vendor</InputLabel>
          <Select
            labelId="vendor-select-label"
            value={erpVendor}
            onChange={(e) => setErpVendor(e.target.value)}
            label="Vendor"
            disabled={isEdit || loading} // Não permite alterar o vendor em modo de edição
          >
            {AVAILABLE_VENDORS.map(vendor => (
              <MenuItem key={vendor.id} value={vendor.id}>{vendor.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Selecione o fornecedor do ERP
          </FormHelperText>
        </FormControl>
      </Box>
      
      <TextField
        label="Nome da Integração"
        value={erpName}
        onChange={(e) => setErpName(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={formErrors.name}
        helperText={formErrors.name ? "Nome é obrigatório" : ""}
        disabled={loading}
      />
      
      <TextField
        label="Descrição"
        value={erpDescription}
        onChange={(e) => setErpDescription(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={2}
        disabled={loading}
        placeholder="Descreva o propósito desta integração"
      />
      
      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Switch 
              checked={isActive} 
              onChange={(e) => setIsActive(e.target.checked)}
              color="success"
              disabled={loading}
            />
          }
          label={
            <Box>
              <Typography variant="body2" fontWeight={500}>
                Integração ativa
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Quando desativada, a sincronização de dados é interrompida
              </Typography>
            </Box>
          }
        />
      </FormGroup>
    </S.FormSection>
  );
};

export default BasicInfoSection;
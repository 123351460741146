import React from 'react'
import { Box } from '@mui/material'
import { InputSearch, SearchBarMobile } from 'components'
import { FilterListProfile } from '../Filter/FilterListProfile'
import { ProdutoCategoryFilter } from './ProdutoCategoryFilter'
import { ProdutoTypeFilter } from './ProdutoTypeFilter'

interface ProdutoFiltersProps {
  search: string
  showInputSearch: boolean
  categoryFilter: string
  categoryAnchorEl: HTMLElement | null
  tipoFilter: string
  categories: string[]
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onShowMobileToggle: () => void
  onCategoryMenuOpen: (event: React.MouseEvent<HTMLElement>) => void
  onCategoryMenuClose: () => void
  onCategorySelect: (category: string) => void
  onClearCategoryFilter: () => void
  onTipoFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ProdutoFilters: React.FC<ProdutoFiltersProps> = ({
  search,
  showInputSearch,
  categoryFilter,
  categoryAnchorEl,
  tipoFilter,
  categories,
  onSearchChange,
  onShowMobileToggle,
  onCategoryMenuOpen,
  onCategoryMenuClose,
  onCategorySelect,
  onClearCategoryFilter,
  onTipoFilterChange
}) => {
  return (
    <div className="filters">
      {showInputSearch ? (
        <div className="inputSearchMobile">
          <SearchBarMobile
            placeholder={'Pesquisar produto/serviço'}
            value={search}
            onChange={onSearchChange}
            onClose={onShowMobileToggle}
          />
        </div>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <InputSearch
              placeholder="Pesquisar por nome ou código"
              value={search}
              onChange={onSearchChange}
              onShowMobile={onShowMobileToggle}
            />
            
            {/* Filtro de categoria */}
            <ProdutoCategoryFilter
              categoryFilter={categoryFilter}
              categoryAnchorEl={categoryAnchorEl}
              categories={categories}
              onMenuOpen={onCategoryMenuOpen}
              onMenuClose={onCategoryMenuClose}
              onCategorySelect={onCategorySelect}
              onClearFilter={onClearCategoryFilter}
            />
            
            {/* Filtro de tipo (produto/serviço) */}
            <ProdutoTypeFilter
              tipoFilter={tipoFilter}
              onChange={onTipoFilterChange}
            />
            
            <FilterListProfile orderBy={(v) => console.log(v)} />
          </Box>
        </>
      )}
    </div>
  )
}
import React, { useState } from 'react';
import { 
  Avatar, 
  IconButton, 
  Typography, 
  Tooltip, 
  InputBase, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button,
  Paper,
  Popover,
  ClickAwayListener,
  Grid,
  Box
} from '@mui/material';
import { 
  Menu, 
  Search, 
  ArrowUpward, 
  ArrowDownward, 
  Close, 
  Info,
  Person,
  Chat as ChatIcon,
  CalendarMonth as CalendarIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon
} from '@mui/icons-material';

export const ChatHeader = ({
  selectedChatId,
  chats,
  isMobile,
  isSidebarOpen,
  toggleSidebar,
  onSearch, // Function to search in chat content
  onScrollToMatch, // Function to scroll to match
  availableDates = [], // Array of dates that have messages
  onDateSelect, // Function to scroll to messages from a specific date
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [contactDetailsOpen, setContactDetailsOpen] = useState(false);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  
  // Custom calendar state
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  
  const selectedChat = selectedChatId
    ? chats.find(chat => chat.id === selectedChatId)
    : null;

  // Toggle search input
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      setSearchQuery('');
      setSearchResults([]);
      setCurrentMatchIndex(0);
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    
    if (query.trim() && onSearch) {
      // Call the parent component's search function
      const results = onSearch(query);
      setSearchResults(results || []);
      setCurrentMatchIndex(results.length > 0 ? 0 : -1);
      
      // Scroll to first match if there are any
      if (results.length > 0 && onScrollToMatch) {
        onScrollToMatch(results[0]);
      }
    } else {
      setSearchResults([]);
      setCurrentMatchIndex(-1);
    }
  };

  // Navigate to previous match
  const goToPrevMatch = () => {
    if (searchResults.length > 0) {
      const newIndex = currentMatchIndex <= 0 ? searchResults.length - 1 : currentMatchIndex - 1;
      setCurrentMatchIndex(newIndex);
      
      if (onScrollToMatch) {
        onScrollToMatch(searchResults[newIndex]);
      }
    }
  };

  // Navigate to next match
  const goToNextMatch = () => {
    if (searchResults.length > 0) {
      const newIndex = currentMatchIndex >= searchResults.length - 1 ? 0 : currentMatchIndex + 1;
      setCurrentMatchIndex(newIndex);
      
      if (onScrollToMatch) {
        onScrollToMatch(searchResults[newIndex]);
      }
    }
  };

  // Toggle contact details dialog
  const toggleContactDetails = () => {
    setContactDetailsOpen(!contactDetailsOpen);
  };

  // Calendar functionality
  const handleCalendarOpen = (event) => {
    setCalendarAnchorEl(event.currentTarget);
    setCurrentMonth(new Date().getMonth());
    setCurrentYear(new Date().getFullYear());
  };

  const handleCalendarClose = () => {
    setCalendarAnchorEl(null);
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleDateSelect = (day) => {
    const selectedDate = new Date(currentYear, currentMonth, day);
    if (onDateSelect) {
      onDateSelect(selectedDate);
    }
    handleCalendarClose();
  };

  // Convert availableDates to a set of YYYY-MM-DD strings for easy lookup
  const availableDateSet = new Set(
    availableDates.map(date => {
      if (date instanceof Date) {
        return date.toISOString().split('T')[0];
      } else {
        return date.split('T')[0];
      }
    })
  );

  // Check if a date has messages
  const isDateAvailable = (year, month, day) => {
    if (!availableDates || availableDates.length === 0) {
      return true; // If no dates provided, allow all dates
    }
    
    const dateStr = new Date(year, month, day).toISOString().split('T')[0];
    return availableDateSet.has(dateStr);
  };

  // Render calendar
  const renderCalendar = () => {
    const monthNames = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    
    const days = [];
    
    // Add empty cells for days before the 1st of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="w-10 h-10"></div>);
    }
    
    // Add days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const hasMessages = isDateAvailable(currentYear, currentMonth, day);
      days.push(
        <div 
          key={`day-${day}`}
          onClick={hasMessages ? () => handleDateSelect(day) : undefined}
          className={`
            w-10 h-10 flex items-center justify-center rounded-full m-1
            ${hasMessages ? 'cursor-pointer hover:bg-indigo-100' : 'text-gray-300'}
            ${hasMessages ? 'font-medium' : ''}
          `}
          style={{
            backgroundColor: hasMessages ? '#f0f0ff' : 'transparent',
            color: hasMessages ? '#4F46E5' : '#aaa'
          }}
        >
          {day}
        </div>
      );
    }
    
    return (
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <Typography variant="h6" className="text-indigo-800 font-medium">
            Navegue pelo histórico
          </Typography>
          <IconButton size="small" onClick={handleCalendarClose}>
            <Close fontSize="small" />
          </IconButton>
        </div>
        
        <div className="flex items-center justify-between mb-2">
          <IconButton onClick={handlePrevMonth} size="small">
            <ArrowLeftIcon />
          </IconButton>
          
          <Typography variant="subtitle1" className="font-medium">
            {monthNames[currentMonth]} {currentYear}
          </Typography>
          
          <IconButton onClick={handleNextMonth} size="small">
            <ArrowRightIcon />
          </IconButton>
        </div>
        
        <div className="grid grid-cols-7 gap-0">
          {/* Week days */}
          {['D', 'S', 'T', 'Q', 'Q', 'S', 'S'].map((day, index) => (
            <div 
              key={`weekday-${index}`} 
              className="w-10 h-10 flex items-center justify-center text-xs text-gray-500 font-medium"
            >
              {day}
            </div>
          ))}
          
          {/* Calendar days */}
          {days}
        </div>
        
        <div className="mt-4 flex justify-between items-center">
          <div className="flex items-center">
            <div 
              className="w-4 h-4 rounded-full mr-2"
              style={{ backgroundColor: '#f0f0ff' }}
            ></div>
            <Typography variant="caption" color="textSecondary">
              Dias com mensagens
            </Typography>
          </div>
          
          <Button 
            variant="contained" 
            size="small" 
            onClick={handleCalendarClose}
            sx={{
              bgcolor: '#635AFF',
              '&:hover': {
                bgcolor: '#4F46E5',
              },
            }}
          >
            Fechar
          </Button>
        </div>
      </div>
    );
  };

  const calendarOpen = Boolean(calendarAnchorEl);
  const calendarId = calendarOpen ? 'date-picker-popover' : undefined;

  // Header height constant
  const headerHeight = 72; // Set a consistent height for the header

  return (
    <div 
      className="flex-shrink-0 bg-white border-b border-gray-200 px-4 flex items-center justify-between w-full backdrop-blur-sm"
      style={{ height: `${headerHeight}px`, backgroundColor: 'rgba(255, 255, 255, 0.98)' }}
    >
      {isMobile && !isSidebarOpen && (
        <IconButton onClick={toggleSidebar} sx={{ mr: 2 }}>
          <Menu />
        </IconButton>
      )}
      
      {selectedChat ? (
        <>
          <div className="flex items-center h-full">
            <Avatar
              sx={{
                bgcolor: selectedChat.whatsapp ? '#25D366' : '#635AFF',
                width: 40,
                height: 40,
                fontSize: '1rem',
                mr: 2,
              }}
            >
              {selectedChat.whatsapp ? 'W' : 'AI'}
            </Avatar>
            <div>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {selectedChat.name || 'Chat'}
              </Typography>
              {selectedChat.whatsapp && (
                <Typography
                  variant="caption"
                  sx={{ color: 'text.secondary', cursor: 'pointer' }}
                  onClick={toggleContactDetails}
                >
                  Ver detalhes
                </Typography>
              )}
            </div>
          </div>
          
          <div className="flex items-center">
            {/* Calendar button */}
            {!isSearchOpen && (
              <Tooltip title="Navegar por data">
                <IconButton 
                  onClick={handleCalendarOpen} 
                  sx={{ mr: 1 }}
                  aria-describedby={calendarId}
                >
                  <CalendarIcon />
                </IconButton>
              </Tooltip>
            )}
            
            {/* Search button and input */}
            {isSearchOpen ? (
              <div className="flex items-center mr-2 bg-gray-100 rounded-md px-2">
                <InputBase
                  placeholder="Buscar no chat..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{ ml: 1, flex: 1 }}
                  autoFocus
                />
                
                {searchResults.length > 0 && (
                  <Typography variant="caption" sx={{ mx: 1 }}>
                    {currentMatchIndex + 1}/{searchResults.length}
                  </Typography>
                )}
                
                <IconButton 
                  size="small" 
                  onClick={goToPrevMatch}
                  disabled={searchResults.length === 0}
                >
                  <ArrowUpward fontSize="small" />
                </IconButton>
                
                <IconButton 
                  size="small" 
                  onClick={goToNextMatch}
                  disabled={searchResults.length === 0}
                >
                  <ArrowDownward fontSize="small" />
                </IconButton>
                
                <IconButton size="small" onClick={toggleSearch}>
                  <Close fontSize="small" />
                </IconButton>
              </div>
            ) : (
              <Tooltip title="Buscar no chat">
                <IconButton onClick={toggleSearch} sx={{ mr: 1 }}>
                  <Search />
                </IconButton>
              </Tooltip>
            )}
            
            {/* Contact info button */}
            {selectedChat?.whatsapp && (
              <Tooltip title="Ver detalhes do contato">
                <IconButton onClick={toggleContactDetails}>
                  <Person />
                </IconButton>
              </Tooltip>
            )}
          </div>
          
          {/* Custom Calendar Popover */}
          <Popover
            id={calendarId}
            open={calendarOpen}
            anchorEl={calendarAnchorEl}
            onClose={handleCalendarClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper className="w-80">
              {renderCalendar()}
            </Paper>
          </Popover>
          
          {/* Contact Details Dialog */}
          <Dialog 
            open={contactDetailsOpen} 
            onClose={toggleContactDetails}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              Detalhes do Contato
              <IconButton
                aria-label="close"
                onClick={toggleContactDetails}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <div className="flex flex-col items-center mb-4">
                <Avatar
                  sx={{
                    bgcolor: '#25D366',
                    width: 80,
                    height: 80,
                    fontSize: '2rem',
                    mb: 2
                  }}
                >
                  {selectedChat.avatar || selectedChat.name?.charAt(0) || 'W'}
                </Avatar>
                <Typography variant="h6">{selectedChat.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {selectedChat.phone || 'Telefone não disponível'}
                </Typography>
              </div>
              
              {/* Additional contact details would go here */}
              <div className="border-t pt-4">
                <Typography variant="subtitle2" gutterBottom>
                  Informações do Contato
                </Typography>
                <div className="flex justify-between py-2">
                  <Typography variant="body2" color="textSecondary">
                    E-mail
                  </Typography>
                  <Typography variant="body2">
                    {selectedChat.email || 'Não disponível'}
                  </Typography>
                </div>
                <div className="flex justify-between py-2">
                  <Typography variant="body2" color="textSecondary">
                    Categoria
                  </Typography>
                  <Typography variant="body2">
                    {selectedChat.category || 'Não categorizado'}
                  </Typography>
                </div>
                <div className="flex justify-between py-2">
                  <Typography variant="body2" color="textSecondary">
                    Notas
                  </Typography>
                  <Typography variant="body2">
                    {selectedChat.notes || 'Sem notas'}
                  </Typography>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleContactDetails}>Fechar</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <div className="flex items-center h-full">
          <Avatar
            sx={{
              bgcolor: '#635AFF',
              width: 40,
              height: 40,
              fontSize: '1rem',
              mr: 2,
            }}
          >
            <ChatIcon fontSize="small" />
          </Avatar>
          <div>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Bem-vindo ao Chat
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: 'text.secondary' }}
            >
              Selecione uma conversa para começar
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
import { styled } from '@mui/material/styles';
import { Box, Typography, ListItem, ListItemIcon, Badge, Paper } from '@mui/material';

// Container para seções de formulário
export const FormSection = styled(Paper)(({ theme }) => ({
  borderRadius: '12px',
  padding: '24px',
  marginBottom: '24px',
  backgroundColor: '#fcfcfc',
  border: '1px solid rgba(0,0,0,0.04)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
    transform: 'translateY(-2px)',
  },
}));

// Título de seção
export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  paddingBottom: '12px',
  color: '#2d3748',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '40px',
    height: '3px',
    background: 'linear-gradient(90deg, #635AFF, rgba(99, 90, 255, 0.3))',
    bottom: 0,
    left: 0,
    borderRadius: '8px',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '12px',
    fontSize: '20px',
    color: '#635AFF',
    backgroundColor: 'rgba(99, 90, 255, 0.1)',
    padding: '6px',
    borderRadius: '8px',
  },
}));

// Item de produto na lista
export const ProductItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '10px',
  marginBottom: '12px',
  border: '1px solid rgba(0,0,0,0.06)',
  padding: '12px 16px',
  backgroundColor: '#ffffff',
  transition: 'all 0.2s',
  boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
  '&:hover': {
    backgroundColor: '#f8f9ff',
    borderColor: 'rgba(99, 90, 255, 0.2)',
    transform: 'translateX(3px)',
  },
}));

// Ícone de produto com cor baseada no tipo
interface ProductIconProps {
  productType?: string;
}

export const ProductIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'productType',
})<ProductIconProps>(({ theme, productType }) => ({
  minWidth: '40px',
  '& .MuiSvgIcon-root': {
    color: 'white',
    backgroundColor: productType === 'produto' ? '#635AFF' : '#9c27b0',
    padding: '6px',
    borderRadius: '8px',
    boxShadow: productType === 'produto' 
      ? '0 3px 8px rgba(99, 90, 255, 0.2)' 
      : '0 3px 8px rgba(156, 39, 176, 0.2)',
  },
}));

// Badge estilizado
export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 10,
    padding: '0 6px',
    height: '18px',
    minWidth: '18px',
    backgroundColor: '#635AFF',
    boxShadow: '0 2px 5px rgba(99, 90, 255, 0.3)',
    fontSize: '0.7rem',
    fontWeight: 'bold',
  },
}));

// Container para campos de formulário
export const FormFieldGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '20px',
}));

// Container para linha de formulário com vários campos
export const FormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  '& > *': {
    flex: '1 1 200px',
  },
}));

// Estilo para botão de ação flutuante
export const ActionButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: -8,
  top: -8,
  backgroundColor: '#fff',
  borderRadius: '50%',
  width: '28px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 3px 10px rgba(0,0,0,0.16)',
  },
}));
import React from 'react';
import { TextField, IconButton } from '@mui/material';

export const ChatEditForm = ({
  editingChatName,
  setEditingChatName,
  handleSaveRename,
  cancelEditing,
}) => {
  return (
    <div className="flex items-center p-2 bg-gray-50 rounded-lg">
      <TextField
        fullWidth
        size="small"
        value={editingChatName}
        onChange={(e) => setEditingChatName(e.target.value)}
        autoFocus
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px',
          },
        }}
      />
      <IconButton
        color="success"
        onClick={handleSaveRename}
        size="small"
        sx={{ ml: 1 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </IconButton>
      <IconButton
        color="error"
        onClick={cancelEditing}
        size="small"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </IconButton>
    </div>
  );
};

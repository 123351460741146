// src/pages/Main/containers/empresas/apiUtils.ts
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { llmEndpoint } from "infra";

// Criando uma instância do axios com configurações base
const api = axios.create({
  baseURL: llmEndpoint.url,
  timeout: 30000, // 30 segundos
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptador para adicionar token de autenticação em cada requisição
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Interface para os erros customizados da API
interface ApiError {
  message: string;
  originalError: any;
}

// Utilitário para lidar com erros de forma padronizada
const handleApiError = (error: any): Promise<ApiError> => {
  let errorMessage = "Ocorreu um erro na comunicação com o servidor";

  if (error.response) {
    // Erro retornado pelo servidor
    const { status, data } = error.response;

    if (status === 401) {
      // Token expirado ou inválido
      localStorage.removeItem("token");
      window.location.href = "/login";
      errorMessage = "Sua sessão expirou. Por favor, faça login novamente.";
    } else if (status === 403) {
      errorMessage = "Você não tem permissão para acessar este recurso.";
    } else if (status === 404) {
      errorMessage = "Recurso não encontrado.";
    } else if (data?.message) {
      errorMessage = data.message;
    }
  } else if (error.request) {
    // Erro de conexão, servidor não respondeu
    errorMessage =
      "Não foi possível conectar ao servidor. Verifique sua conexão.";
  }

  return Promise.reject({ message: errorMessage, originalError: error });
};

// Interface para parâmetros de busca
interface SearchParams {
  page?: number;
  size?: number;
  sort?: string;
  direction?: string;
  term?: string;
  tags?: string;
  name?: string;
  document?: string;
  description?: string;
  selectedTags?: string[];
  [key: string]: any; // Para permitir parâmetros adicionais
}

// Métodos para realizar chamadas à API
export const apiUtils = {
  // GET com suporte a parâmetros de consulta
  get: async (url: string, options: AxiosRequestConfig = {}) => {
    try {
      // Sanitização de parâmetros para remover valores vazios ou nulos
      if (options.params) {
        Object.keys(options.params).forEach((key) => {
          if (
            options.params[key] === null ||
            options.params[key] === undefined ||
            options.params[key] === ""
          ) {
            delete options.params[key];
          }
        });
      }

      const response: AxiosResponse = await api.get(url, options);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  // POST com corpo da requisição
  post: async (
    url: string,
    data: any = {},
    options: AxiosRequestConfig = {},
  ) => {
    try {
      const response: AxiosResponse = await api.post(url, data, options);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  // PUT para atualização completa
  put: async (
    url: string,
    data: any = {},
    options: AxiosRequestConfig = {},
  ) => {
    try {
      const response: AxiosResponse = await api.put(url, data, options);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  // PATCH para atualização parcial
  patch: async (
    url: string,
    data: any = {},
    options: AxiosRequestConfig = {},
  ) => {
    try {
      const response: AxiosResponse = await api.patch(url, data, options);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  // DELETE para remover recursos
  delete: async (url: string, options: AxiosRequestConfig = {}) => {
    try {
      const response: AxiosResponse = await api.delete(url, options);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  // Método específico para lidar com requisições de busca/filtro
  search: async (url: string, params: SearchParams) => {
    try {
      const {
        page = 0,
        size = 10,
        sort = "",
        direction = "asc",
        term = "",
        tags = "",
        ...filters
      } = params;

      // Combina todos os parâmetros em um único objeto
      const searchParams: Record<string, any> = {
        page,
        size,
        sort: sort || undefined,
        direction,
        term: term || undefined,
        tags: tags || undefined,
        ...filters,
      };

      // Remove parâmetros vazios
      Object.keys(searchParams).forEach((key) => {
        if (searchParams[key] === undefined || searchParams[key] === "") {
          delete searchParams[key];
        }
      });

      const response: AxiosResponse = await api.get(url, {
        params: searchParams,
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  // Método específico para busca de empresas com combinação de termo e tags
  fetchEmpresas: async (rpaId: string, params: SearchParams) => {
    try {
      // Preparar os parâmetros, garantindo que termo e tags sejam considerados
      const searchParams: Record<string, any> = { ...params };

      // Remover parâmetros vazios
      Object.keys(searchParams).forEach((key) => {
        if (
          searchParams[key] === null ||
          searchParams[key] === undefined ||
          searchParams[key] === ""
        ) {
          delete searchParams[key];
        }
      });

      // Se houver selectedTags, converter em string para o backend
      if (
        searchParams.selectedTags &&
        Array.isArray(searchParams.selectedTags) &&
        searchParams.selectedTags.length > 0
      ) {
        searchParams.tags = searchParams.selectedTags.join(",");
        delete searchParams.selectedTags; // Remover o array já que usamos a string
      }

      const response: AxiosResponse = await api.get(
        `/whatsapp/enterprise/${rpaId}/search`,
        { params: searchParams },
      );
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default apiUtils;

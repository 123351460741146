import React from 'react'

import { ButtonBack } from '@intraversa-lab/styleguide'
import { IconNotification } from 'components/Notification/Notification'
import { navigateToUrl } from 'single-spa'

export const HeaderMain = () => {
  const onBack = () => {
    navigateToUrl('/')
  }

  return (
    <div className="flex justify-between items-center w-full">
      <div className="md:hidden"></div> {/* Espaço vazio para manter o layout em dispositivos móveis */}
      <div className="hidden md:block">
        <ButtonBack onClick={onBack} />
      </div>
      {/* <IconNotification /> */}
      <div className="md:hidden">
        <ButtonBack onClick={onBack} />
      </div>
    </div>
  )
}

import React, { useState, useEffect, useRef } from 'react'

import {
  PhotoCamera,
  Edit as EditIcon,
  Save as SaveIcon,
  AccessTime as TimeIcon,
  Business as BusinessIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  WhatsApp as WhatsAppIcon,
  LocationOn as LocationIcon,
  Description as DescriptionIcon,
  Delete as DeleteIcon,
  Info as InfoIcon,
  QueryBuilder as QueryBuilderIcon,
  Event as EventIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Avatar,
  IconButton,
  FormControlLabel,
  Switch,
  Divider,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  Card,
  CardContent,
  Container,
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import moment, { Moment } from 'moment'
import 'moment/locale/pt-br'
import axios from 'axios'
import { llmEndpoint } from 'infra'
import { useParams } from 'react-router-dom'


// Interface para os dias da semana
interface WeekdayConfig {
  day: string
  isOpen: boolean
  openTime: Moment | null
  closeTime: Moment | null
}

// Interface para os dados da empresa
interface CompanyData {
  id: string
  name: string
  description: string
  logo: string
  phone: string
  whatsapp: string
  email: string
  address: string
  businessHours: WeekdayConfig[]
  autoReply: {
    enabled: boolean
    message: string
  }
}

// API URL
const API_URL = `${llmEndpoint.url}/companies`

// Componente principal
export const CompanySettings = () => {
  const { rpaId } = useParams()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  })

  // Estado para os dados da empresa
  const [companyData, setCompanyData] = useState<CompanyData>({
    id: '',
    name: '',
    description: '',
    logo: '',
    phone: '',
    whatsapp: '',
    email: '',
    address: '',
    businessHours: [
      {
        day: 'Segunda-feira',
        isOpen: true,
        openTime: moment().hours(8).minutes(0).seconds(0),
        closeTime: moment().hours(18).minutes(0).seconds(0),
      },
      {
        day: 'Terça-feira',
        isOpen: true,
        openTime: moment().hours(8).minutes(0).seconds(0),
        closeTime: moment().hours(18).minutes(0).seconds(0),
      },
      {
        day: 'Quarta-feira',
        isOpen: true,
        openTime: moment().hours(8).minutes(0).seconds(0),
        closeTime: moment().hours(18).minutes(0).seconds(0),
      },
      {
        day: 'Quinta-feira',
        isOpen: true,
        openTime: moment().hours(8).minutes(0).seconds(0),
        closeTime: moment().hours(18).minutes(0).seconds(0),
      },
      {
        day: 'Sexta-feira',
        isOpen: true,
        openTime: moment().hours(8).minutes(0).seconds(0),
        closeTime: moment().hours(18).minutes(0).seconds(0),
      },
      {
        day: 'Sábado',
        isOpen: false,
        openTime: moment().hours(9).minutes(0).seconds(0),
        closeTime: moment().hours(13).minutes(0).seconds(0),
      },
      { day: 'Domingo', isOpen: false, openTime: null, closeTime: null },
    ],
    autoReply: {
      enabled: true,
      message:
        'Olá! Agradecemos pelo seu contato. No momento estamos fora do nosso horário de atendimento. Retornaremos assim que possível. Obrigado pela compreensão!',
    },
  })

  const [previewImage, setPreviewImage] = useState<string>('')
  const [isNewCompany, setIsNewCompany] = useState(true)

  // Referência para o botão de salvar
  const saveButtonRef = useRef<HTMLDivElement>(null)

  // Forçar overflow para ser visível em todo o documento
  useEffect(() => {
    document.body.style.overflow = 'auto'
    document.documentElement.style.overflow = 'auto'
    document.body.style.height = 'auto'
    document.documentElement.style.height = 'auto'

    return () => {
      document.body.style.overflow = ''
      document.documentElement.style.overflow = ''
      document.body.style.height = ''
      document.documentElement.style.height = ''
    }
  }, [])

  const [updateLoading, setUpdateLoading] = useState(false)

  // Buscar dados da empresa ao carregar o componente
  useEffect(() => {
    fetchCompanyData()
  }, [])

  const handleUpdateWhatsAppInstance = async () => {
    // Criar estado local para controlar o loading do botão

    try {
      // Usar state para controlar o estado do botão em vez de manipular o DOM
      setUpdateLoading(true)

  
      // Fazer a chamada para atualizar a instância do WhatsApp
      await axios.post(
        `${API_URL}/${companyData.id}/update-instance-data/${rpaId}`,
        '',
      )

      setSnackbar({
        open: true,
        message: 'Dados do WhatsApp atualizados com sucesso!',
        severity: 'success',
      })
    } catch (error) {
      console.error('Erro ao atualizar dados do WhatsApp:', error)
      setSnackbar({
        open: true,
        message: 'Erro ao atualizar dados do WhatsApp',
        severity: 'error',
      })
    } finally {
      // Desativar loading do botão quando a operação terminar
      setUpdateLoading(false)
    }
  }
  // Função para converter Moment para formato de Date ISO
  const momentToDateISOString = (momentObj: Moment | null): string | null => {
    if (!momentObj) return null
    // Criar uma data com a hora especificada, mas mantendo a data atual
    const date = new Date()
    date.setHours(momentObj.hours())
    date.setMinutes(momentObj.minutes())
    date.setSeconds(momentObj.seconds())
    date.setMilliseconds(0)

    return date.toISOString()
  }

  // Função para converter Date ISO string para Moment
  const dateISOStringToMoment = (dateStr: string | null): Moment | null => {
    if (!dateStr) return null

    // Converter a string ISO para um objeto Date
    const date = new Date(dateStr)

    // Criar um objeto Moment com as horas, minutos e segundos da data
    return moment()
      .hours(date.getHours())
      .minutes(date.getMinutes())
      .seconds(date.getSeconds())
  }

  // Função para buscar dados da empresa
  const fetchCompanyData = async () => {
    setLoading(true)
    try {
      // Tentar obter a lista de empresas
      const response = await axios.get(API_URL)
      const companies = response.data || []

      if (companies.length > 0) {
        // Se existir alguma empresa, usa a primeira
        const company = companies[0]
        setIsNewCompany(false)

        // Converte os dados da API para o formato do componente
        const formattedBusinessHours = [
          {
            day: 'Segunda-feira',
            isOpen: false,
            openTime: null,
            closeTime: null,
          },
          {
            day: 'Terça-feira',
            isOpen: false,
            openTime: null,
            closeTime: null,
          },
          {
            day: 'Quarta-feira',
            isOpen: false,
            openTime: null,
            closeTime: null,
          },
          {
            day: 'Quinta-feira',
            isOpen: false,
            openTime: null,
            closeTime: null,
          },
          {
            day: 'Sexta-feira',
            isOpen: false,
            openTime: null,
            closeTime: null,
          },
          { day: 'Sábado', isOpen: false, openTime: null, closeTime: null },
          { day: 'Domingo', isOpen: false, openTime: null, closeTime: null },
        ]

        // Mapear os horários de funcionamento
        if (company.businessHours && company.businessHours.length) {
          company.businessHours.forEach((hour: any) => {
            console.log(hour)
            const index = formattedBusinessHours.findIndex(
              (h) => h.day === hour.dayOfWeek,
            )
            if (index !== -1) {
              formattedBusinessHours[index].isOpen = hour.isOpen
              formattedBusinessHours[index].openTime = dateISOStringToMoment(
                hour.openTime,
              )
              formattedBusinessHours[index].closeTime = dateISOStringToMoment(
                hour.closeTime,
              )
            }
          })
        }

        const formattedData: CompanyData = {
          id: company.id,
          name: company.name || '',
          description: company.description || '',
          logo: company.logoUrl || '',
          phone: company.phone || '',
          whatsapp: company.whatsapp || '',
          email: company.email || '',
          address: company.address || '',
          businessHours: formattedBusinessHours,
          autoReply: {
            enabled: company.autoReply?.enabled || false,
            message: company.autoReply?.message || '',
          },
        }

        setCompanyData(formattedData)
        setPreviewImage(formattedData.logo)
      } else {
        // Se não existir empresa, mantém o estado inicial para criação
        setIsNewCompany(true)
      }

      setLoading(false)
    } catch (error) {
      console.error('Erro ao buscar dados da empresa:', error)
      setSnackbar({
        open: true,
        message: 'Erro ao carregar dados da empresa',
        severity: 'error',
      })
      setLoading(false)
    }
  }

  // Função para converter os dados do componente para o formato da API
  const formatDataForApi = () => {
    // Converter horários de funcionamento
    const businessHours = companyData.businessHours.map((hour) => ({
      dayOfWeek: hour.day,
      isOpen: hour.isOpen,
      openTime: hour.isOpen ? momentToDateISOString(hour.openTime) : null,
      closeTime: hour.isOpen ? momentToDateISOString(hour.closeTime) : null,
    }))

    // Montar payload para API
    return {
      name: companyData.name,
      description: companyData.description,
      logoUrl: companyData.logo,
      phone: companyData.phone,
      whatsapp: companyData.whatsapp,
      email: companyData.email,
      address: companyData.address,
      businessHours,
      autoReply: {
        enabled: companyData.autoReply.enabled,
        message: companyData.autoReply.message,
      },
    }
  }

  // Função para salvar dados da empresa
  const handleSaveCompany = async () => {
    setSaveLoading(true)
    try {
      const apiData = formatDataForApi()
      let response

      if (isNewCompany) {
        // Criar nova empresa
        response = await axios.post(API_URL, apiData)
      } else {
        // Atualizar empresa existente
        response = await axios.put(`${API_URL}/${companyData.id}`, apiData)
      }

      // Atualizar ID se for uma nova empresa
      if (isNewCompany && response.data && response.data.data) {
        setCompanyData((prev) => ({
          ...prev,
          id: response.data.data.id,
        }))
        setIsNewCompany(false)
      }

      setSnackbar({
        open: true,
        message: 'Dados da empresa salvos com sucesso!',
        severity: 'success',
      })

      // Recarregar os dados da empresa
      fetchCompanyData()
    } catch (error) {
      console.error('Erro ao salvar dados da empresa:', error)
      setSnackbar({
        open: true,
        message: 'Erro ao salvar dados da empresa',
        severity: 'error',
      })
    } finally {
      setSaveLoading(false)
    }
  }

  // Manipulador para upload de imagem
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const result = reader.result as string
        setPreviewImage(result)
        setCompanyData((prev) => ({
          ...prev,
          logo: result,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  // Manipulador para abrir o seletor de arquivo
  const handleClickUpload = () => {
    fileInputRef.current?.click()
  }

  // Manipulador para remover a imagem
  const handleRemoveImage = () => {
    setPreviewImage('')
    setCompanyData((prev) => ({
      ...prev,
      logo: '',
    }))
  }

  // Manipulador para alteração de campos de input
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    setCompanyData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Manipulador para alteração no autoReply
  const handleAutoReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target

    if (name === 'autoReplyEnabled') {
      setCompanyData((prev) => ({
        ...prev,
        autoReply: {
          ...prev.autoReply,
          enabled: checked,
        },
      }))
    } else if (name === 'autoReplyMessage') {
      setCompanyData((prev) => ({
        ...prev,
        autoReply: {
          ...prev.autoReply,
          message: value,
        },
      }))
    }
  }

  // Manipulador para alteração nos dias de funcionamento
  const handleBusinessHourToggle = (index: number) => {
    const updatedHours = [...companyData.businessHours]
    updatedHours[index].isOpen = !updatedHours[index].isOpen

    setCompanyData((prev) => ({
      ...prev,
      businessHours: updatedHours,
    }))
  }

  // Manipulador para alteração nos horários usando Moment
  const handleTimeChange = (
    index: number,
    field: 'openTime' | 'closeTime',
    newTime: Moment | null,
  ) => {
    const updatedHours = [...companyData.businessHours]
    updatedHours[index][field] = newTime

    setCompanyData((prev) => ({
      ...prev,
      businessHours: updatedHours,
    }))
  }

  // Manipulador para fechar o snackbar
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({
      ...prev,
      open: false,
    }))
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        height: 'auto',
        overflow: 'visible',
        width: '100%',
        position: 'relative',
      }}
    >
      <Container maxWidth="xl" sx={{ pb: 10 }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            component="h1"
            fontWeight="bold"
            sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
          >
            <BusinessIcon sx={{ mr: 1, color: '#3f51b5' }} />
            Configurações da Minha Empresa
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Configure as informações da sua empresa que serão utilizadas no
            WhatsApp e outros canais de comunicação.
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* Bloco de informações básicas */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, borderRadius: '10px' }}>
              <Typography
                variant="h6"
                sx={{ mb: 3, display: 'flex', alignItems: 'center' }}
              >
                <InfoIcon sx={{ mr: 1, color: '#3f51b5' }} />
                Informações Básicas
              </Typography>

              {/* Área com scroll para informações básicas */}
              <Box sx={{ maxHeight: '75vh', overflowY: 'auto', pr: 1, pb: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: 4,
                  }}
                >
                  <Avatar
                    src={previewImage}
                    alt="Logo da empresa"
                    sx={{
                      width: 150,
                      height: 150,
                      mb: 2,
                      border: '1px solid #e0e0e0',
                      backgroundColor: '#f5f5f5',
                    }}
                  >
                    {!previewImage && <BusinessIcon sx={{ fontSize: 60 }} />}
                  </Avatar>

                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-logo"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                  />

                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant="outlined"
                      startIcon={<PhotoCamera />}
                      onClick={handleClickUpload}
                      size="small"
                    >
                      {previewImage ? 'Trocar Logo' : 'Upload Logo'}
                    </Button>

                    {previewImage && (
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={handleRemoveImage}
                        size="small"
                      >
                        Remover
                      </Button>
                    )}
                  </Box>
                </Box>

                <TextField
                  fullWidth
                  label="Nome da Empresa"
                  name="name"
                  value={companyData.name}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                  InputProps={{
                    startAdornment: (
                      <BusinessIcon color="action" sx={{ mr: 1 }} />
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  label="Descrição da Empresa"
                  name="description"
                  value={companyData.description}
                  onChange={handleInputChange}
                  margin="normal"
                  multiline
                  rows={4}
                  InputProps={{
                    startAdornment: (
                      <DescriptionIcon
                        color="action"
                        sx={{ mr: 1, alignSelf: 'flex-start', mt: 1 }}
                      />
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  label="Telefone"
                  name="phone"
                  value={companyData.phone}
                  onChange={handleInputChange}
                  margin="normal"
                  InputProps={{
                    startAdornment: <PhoneIcon color="action" sx={{ mr: 1 }} />,
                  }}
                />

                {/* <TextField
                  fullWidth
                  label="WhatsApp"
                  name="whatsapp"
                  value={companyData.whatsapp}
                  onChange={handleInputChange}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <WhatsAppIcon color="primary" sx={{ mr: 1 }} />
                    ),
                  }}
                /> */}
                <Box>
                  <TextField
                    fullWidth
                    label="WhatsApp"
                    name="whatsapp"
                    value={companyData.whatsapp}
                    onChange={handleInputChange}
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <WhatsAppIcon color="primary" sx={{ mr: 1 }} />
                      ),
                    }}
                  />

                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="success"
                      startIcon={
                        updateLoading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <WhatsAppIcon />
                        )
                      }
                      onClick={handleUpdateWhatsAppInstance}
                      disabled={!companyData.id || saveLoading || updateLoading}
                      size="medium"
                    >
                      {updateLoading
                        ? 'Atualizando...'
                        : 'Atualizar WhatsApp Conectado'}
                    </Button>
                    <Tooltip title="Atualiza os dados do perfil do WhatsApp conectado usando as informações da empresa">
                      <IconButton size="small">
                        <InfoIcon fontSize="small" color="action" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={companyData.email}
                  onChange={handleInputChange}
                  margin="normal"
                  type="email"
                  InputProps={{
                    startAdornment: <EmailIcon color="action" sx={{ mr: 1 }} />,
                  }}
                />

                <TextField
                  fullWidth
                  label="Endereço"
                  name="address"
                  value={companyData.address}
                  onChange={handleInputChange}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <LocationIcon color="action" sx={{ mr: 1 }} />
                    ),
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          {/* Bloco de horários de funcionamento e mensagem automática */}
          <Grid item xs={12} md={6}>
            {/* Horários de funcionamento */}
            <Paper sx={{ p: 3, borderRadius: '10px', mb: 3 }}>
              <Typography
                variant="h6"
                sx={{ mb: 3, display: 'flex', alignItems: 'center' }}
              >
                <QueryBuilderIcon sx={{ mr: 1, color: '#3f51b5' }} />
                Horários de Funcionamento
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Configure os dias e horários de funcionamento da sua empresa.
                Isso será utilizado para respostas automáticas no WhatsApp.
              </Typography>

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="pt-br"
              >
                <Box sx={{ maxHeight: '300px', overflowY: 'auto', pr: 1 }}>
                  {companyData.businessHours.map((day, index) => (
                    <Box
                      key={day.day}
                      sx={{
                        mb: 2,
                        pb: 2,
                        borderBottom:
                          index < companyData.businessHours.length - 1
                            ? '1px solid #f0f0f0'
                            : 'none',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: 1,
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <EventIcon
                            sx={{
                              mr: 1,
                              color: day.isOpen ? '#4caf50' : '#9e9e9e',
                            }}
                          />
                          <Typography variant="subtitle1">{day.day}</Typography>
                        </Box>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={day.isOpen}
                              onChange={() => handleBusinessHourToggle(index)}
                              color="primary"
                            />
                          }
                          label={day.isOpen ? 'Aberto' : 'Fechado'}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              color: day.isOpen ? '#4caf50' : '#9e9e9e',
                            },
                          }}
                        />
                      </Box>

                      {day.isOpen && (
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TimePicker
                              label="Abertura"
                              value={day.openTime}
                              onChange={(newValue) =>
                                handleTimeChange(index, 'openTime', newValue)
                              }
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  size: 'small',
                                  InputProps: {
                                    startAdornment: (
                                      <TimeIcon
                                        color="action"
                                        sx={{ mr: 0.5, fontSize: '0.9rem' }}
                                      />
                                    ),
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TimePicker
                              label="Fechamento"
                              value={day.closeTime}
                              onChange={(newValue) =>
                                handleTimeChange(index, 'closeTime', newValue)
                              }
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  size: 'small',
                                  InputProps: {
                                    startAdornment: (
                                      <TimeIcon
                                        color="action"
                                        sx={{ mr: 0.5, fontSize: '0.9rem' }}
                                      />
                                    ),
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  ))}
                </Box>
              </LocalizationProvider>
            </Paper>

            {/* Bloco de mensagem automática */}
            <Paper sx={{ p: 3, borderRadius: '10px' }}>
              <Typography
                variant="h6"
                sx={{ mb: 2, display: 'flex', alignItems: 'center' }}
              >
                <WhatsAppIcon sx={{ mr: 1, color: '#25D366' }} />
                Resposta Automática WhatsApp
              </Typography>

              <Box sx={{ maxHeight: '300px', overflowY: 'auto', pr: 1 }}>
                <Box sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={companyData.autoReply.enabled}
                        onChange={handleAutoReplyChange}
                        name="autoReplyEnabled"
                        color="primary"
                      />
                    }
                    label="Habilitar resposta automática fora do horário"
                  />
                </Box>

                <TextField
                  fullWidth
                  label="Mensagem automática"
                  name="autoReplyMessage"
                  value={companyData.autoReply.message}
                  onChange={handleAutoReplyChange}
                  margin="normal"
                  multiline
                  rows={4}
                  disabled={!companyData.autoReply.enabled}
                  helperText="Esta mensagem será enviada automaticamente quando alguém entrar em contato fora do horário de funcionamento"
                />

                {companyData.autoReply.enabled && (
                  <Card
                    variant="outlined"
                    sx={{ mt: 2, backgroundColor: '#f8f9fa' }}
                  >
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        sx={{ mb: 1, color: '#3f51b5' }}
                      >
                        Prévia da mensagem:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'pre-line' }}
                      >
                        {companyData.autoReply.message}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {/* Botão fixo na parte inferior da tela */}
      <Box
        ref={saveButtonRef}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: '12px 24px',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={
            saveLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <SaveIcon />
            )
          }
          onClick={handleSaveCompany}
          disabled={saveLoading}
          sx={{ px: 4 }}
        >
          {saveLoading
            ? 'Salvando...'
            : isNewCompany
              ? 'Criar Empresa'
              : 'Salvar Configurações'}
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default CompanySettings

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Button,
  Chip,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Fade,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Business as BusinessIcon,
  Add as AddIcon,
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  LocationOn as LocationIcon,
  Map as MapIcon,
  EditNote as EditNoteIcon,
} from '@mui/icons-material';
import { FormSection, SectionTitle } from './styles';
import axios from 'axios';
import { llmEndpoint } from 'infra';
import { useParams } from 'react-router-dom';

interface Company {
  id: string;
  name: string;
  document: string;
  address?: {
    street?: string;
    number?: string;
    complement?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
}

interface CompanySectionProps {
  company: string;
  empresaId: string;
  searchCompanyTerm: string;
  setSearchCompanyTerm: (term: string) => void;
  setShowNewCompanyDialog: (show: boolean) => void;
  handleSelectCompany: (company: any) => void;
  onEditCompany?: (companyId: string) => void; // Novo prop para editar empresa
}

// Função para formatar documentos (CNPJ/CPF)
const formatDocument = (document: string): string => {
  if (!document) return '';
  
  // Remove caracteres não numéricos
  const numericOnly = document.replace(/\D/g, '');
  
  // Verifica se é um CNPJ (14 dígitos)
  if (numericOnly.length === 14) {
    return numericOnly.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  }
  
  // Verifica se é um CPF (11 dígitos)
  if (numericOnly.length === 11) {
    return numericOnly.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4'
    );
  }
  
  // Retorna o documento como está se não reconhecer o padrão
  return document;
};

const CompanySection: React.FC<CompanySectionProps> = ({
  company,
  empresaId,
  searchCompanyTerm,
  setSearchCompanyTerm,
  setShowNewCompanyDialog,
  handleSelectCompany,
  onEditCompany,
}) => {
  const [filteredEmpresas, setFilteredEmpresas] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [showMap, setShowMap] = useState<boolean>(false);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {rpaId} = useParams()

  // Verificar se existe uma empresa selecionada com o ID atual
  useEffect(() => {
    if (empresaId) {
      // Se já temos uma empresa selecionada pelo ID, verificamos se precisamos buscar os detalhes
      const fetchCompanyDetails = async () => {
        try {
          // Corrigido para incluir o rpaId na URL
          const response = await axios.get(
            `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/${empresaId}`,
            {
              headers: {
                accept: '*/*',
              },
            }
          );
          
          if (response.data) {
            setSelectedCompany(response.data);
          }
        } catch (error) {
          console.error('Error fetching company details:', error);
        }
      };
      
      // Só buscamos detalhes se ainda não tivermos o objeto da empresa
      if (!selectedCompany || selectedCompany.id !== empresaId) {
        fetchCompanyDetails();
      }
    } else {
      setSelectedCompany(null);
    }
  }, [empresaId]);

  const fetchCompanies = async (term: string) => {
    if (term.length < 4) return;
    
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/search`,
        {
          params: {
            term,
            page: 0,
            size: 10,
            sort: 'name',
            direction: 'asc',
          },
          headers: {
            accept: '*/*',
          },
        }
      );
      
      if (response.data && response.data.content) {
        setFilteredEmpresas(response.data.content);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
      setFilteredEmpresas([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change with debounce
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchCompanyTerm(term);
    
    // Clear any existing timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    
    // Only start a new search if term has at least 4 characters
    if (term.length >= 4) {
      debounceTimerRef.current = setTimeout(() => {
        fetchCompanies(term);
      }, 500); // 500ms debounce
    } else {
      setFilteredEmpresas([]);
    }
  };

  // Handle search button/icon click or Enter key press
  const handleSearch = () => {
    if (searchCompanyTerm.length >= 4) {
      // Clear any existing timer
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      fetchCompanies(searchCompanyTerm);
    }
  };

  // Handle Enter key press in search input
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // Função para verificar se tem endereço válido
  const hasValidAddress = (address: Company['address']) => {
    if (!address) return false;
    
    // Verifica se pelo menos tem rua e cidade, que são necessários para o mapa
    return !!(address.street && address.city);
  };

  // Função para formatar o endereço
  const formatAddress = (address: Company['address']) => {
    if (!address) return '';
    
    const parts = [];
    
    if (address.street) parts.push(address.street);
    if (address.number) parts.push(address.number);
    if (address.complement) parts.push(address.complement);
    
    let line1 = parts.join(', ');
    
    const parts2 = [];
    if (address.neighborhood) parts2.push(address.neighborhood);
    if (address.city) parts2.push(address.city);
    if (address.state) parts2.push(address.state);
    if (address.zipCode) parts2.push(address.zipCode);
    
    let line2 = parts2.join(', ');
    
    return line1 + (line2 ? ` - ${line2}` : '');
  };

  // Função para gerar a URL do Google Maps Embed sem necessidade de API key
  const getGoogleMapsEmbedUrl = (address: Company['address']) => {
    if (!address) return '';
    
    // Montando o endereço para a consulta
    let query = '';
    
    if (address.street) query += address.street;
    if (address.number) query += ` ${address.number}`;
    if (address.city) query += `, ${address.city}`;
    if (address.state) query += `, ${address.state}`;
    if (address.zipCode) query += ` ${address.zipCode}`;
    
    // Codifica a consulta para URL
    const encodedQuery = encodeURIComponent(query);
    
    // Retorna a URL do Google Maps que não requer API key
    return `https://maps.google.com/maps?q=${encodedQuery}&output=embed`;
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return (
    <FormSection>
      <SectionTitle variant="subtitle2" sx={{ fontSize: '14px', mb: 1 }}>
        <BusinessIcon fontSize="small" />
        Empresa
      </SectionTitle>

      <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
        <TextField
          placeholder="Buscar empresa..."
          fullWidth
          variant="outlined"
          value={searchCompanyTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={handleSearch}
                  edge="start"
                  disabled={searchCompanyTerm.length < 4}
                  size="small"
                >
                  <SearchIcon color={searchCompanyTerm.length < 4 ? "disabled" : "action"} fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowNewCompanyDialog(true)}
          startIcon={<AddIcon />}
          size="small"
          sx={{ minWidth: '120px' }}
        >
          NOVA
        </Button>
      </Box>

      {/* Mensagem informativa sobre requisito de 4 caracteres */}
      {searchCompanyTerm.length > 0 && searchCompanyTerm.length < 4 && (
        <Box sx={{ fontSize: '12px', color: 'text.secondary', mt: 1, mb: 1 }}>
          Digite pelo menos 4 caracteres para buscar
        </Box>
      )}

      {/* Indicador de carregamento */}
      {isLoading && (
        <Box sx={{ fontSize: '12px', color: 'text.secondary', mt: 1, mb: 1 }}>
          Buscando empresas...
        </Box>
      )}

      {/* Lista de empresas encontradas */}
      {searchCompanyTerm.length >= 4 && !isLoading && filteredEmpresas.length > 0 && (
        <Fade in={!!searchCompanyTerm && searchCompanyTerm.length >= 4 && filteredEmpresas.length > 0}>
          <Paper
            variant="outlined"
            sx={{ maxHeight: '150px', overflow: 'auto', mb: 2, position: 'relative', zIndex: 10 }}
          >
            <List dense>
              {filteredEmpresas.length > 0 ? (
                filteredEmpresas.map((empresa) => (
                  <ListItem
                    key={empresa.id}
                    button
                    onClick={() => {
                      handleSelectCompany(empresa);
                      setSelectedCompany(empresa);
                      setSearchCompanyTerm('');
                      setFilteredEmpresas([]);
                    }}
                    selected={empresaId === empresa.id}
                    sx={{
                      borderRadius: '4px',
                      m: 0.5,
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(63, 81, 181, 0.1)',
                        '&:hover': {
                          backgroundColor: 'rgba(63, 81, 181, 0.2)',
                        },
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '36px' }}>
                      <BusinessIcon
                        fontSize="small"
                        color={
                          empresaId === empresa.id
                            ? 'primary'
                            : 'action'
                        }
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={empresa.name}
                      secondary={formatDocument(empresa.document)}
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            empresaId === empresa.id
                              ? 600
                              : 400,
                        },
                      }}
                    />

                    {empresaId === empresa.id && (
                      <CheckCircleIcon
                        color="primary"
                        fontSize="small"
                      />
                    )}
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText
                    primary="Nenhuma empresa encontrada"
                    secondary="Use o botão + para criar uma nova"
                    primaryTypographyProps={{
                      style: {
                        textAlign: 'center',
                        color: '#757575',
                      },
                    }}
                    secondaryTypographyProps={{
                      style: { textAlign: 'center' },
                    }}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Fade>
      )}

      {/* Empresa selecionada */}
      {(company || selectedCompany) && (
        <Box sx={{ mt: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Chip
              icon={<BusinessIcon />}
              label={selectedCompany?.name || company}
              onDelete={() => {
                handleSelectCompany({
                  id: '',
                  name: '',
                  document: '',
                });
                setSelectedCompany(null);
                setShowMap(false);
              }}
              onClick={empresaId && onEditCompany ? () => onEditCompany(empresaId) : undefined}
              color="primary"
              variant="outlined"
              sx={{ 
                cursor: empresaId && onEditCompany ? 'pointer' : 'default',
                '&:hover': {
                  backgroundColor: empresaId && onEditCompany ? 'rgba(63, 81, 181, 0.08)' : 'inherit'
                } 
              }}
            />
            
            {selectedCompany?.address && hasValidAddress(selectedCompany.address) && (
              <Button
                variant="outlined"
                size="small"
                color={showMap ? "primary" : "inherit"}
                startIcon={<MapIcon />}
                onClick={() => setShowMap(!showMap)}
                sx={{ ml: 2 }}
              >
                {showMap ? "Ocultar mapa" : "Ver no mapa"}
              </Button>
            )}
          </Box>

          <Box sx={{ mb: 1 }}>
            {selectedCompany?.document && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                <BusinessIcon fontSize="small" sx={{ mr: 1, opacity: 0.7 }} />
                Documento: {formatDocument(selectedCompany.document)}
              </Typography>
            )}
            
            {selectedCompany?.address && selectedCompany.address.street && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                <LocationIcon fontSize="small" sx={{ mr: 1, opacity: 0.7 }} />
                Endereço: {formatAddress(selectedCompany.address)}
              </Typography>
            )}
          </Box>
          
          {/* Mapa da localização */}
          {showMap && selectedCompany?.address && hasValidAddress(selectedCompany.address) && (
            <Box sx={{ mt: 2, height: '250px', borderRadius: '4px', overflow: 'hidden', position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, bgcolor: '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                <Typography variant="body2" color="text.secondary">Carregando mapa...</Typography>
              </Box>
              <iframe
                width="100%"
                height="100%"
                style={{ border: 0, position: 'relative', zIndex: 2 }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={getGoogleMapsEmbedUrl(selectedCompany.address)}
              ></iframe>
            </Box>
          )}
        </Box>
      )}
    </FormSection>
  );
};

export default CompanySection;
import { Box } from "@mui/material";
import { styled } from "styled-components";

type TFilter = {
  isFilter: boolean;
};

export const Wrapper = styled(Box)`
@media (max-width: 922px) {
  width: 100%;
  }
}

`;

export const ContentButtonOrdenar = styled.div`
  position: relative;

  .drop-order {
    position: absolute;
    z-index: 1000;
    right: 0px;
    top: 55px;
    padding: 16px 16px 24px 8px;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    cursor: default;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      margin: 0;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #75808a;
      margin-left: 8px;
    }

    .category-order {
      width: 100%;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #dfe4e9;
      gap: 8px;

      div {
        padding: 0px 8px;
        border-radius: 4px;

        p {
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: #75808a;
          margin: 0px;
          margin-top: 16px;
        }
      }

      label {
        padding: 8px;
        border-radius: 4px;
        color: #3c454d;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        &:active {
          background-color: #bec9d2 !important;
        }

        input[type="radio"] {
          display: none;
        }

        &:has(> input:checked) {
          color: #6d42e1;
        }

        &:hover {
          background-color: #eff2f4;

          label {
            color: #3c454d;
          }
        }
      }
    }
  }
  .overlay-drop-order {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 16px 24px 8px;
    z-index: 2;
  }
  @media (max-width: 750px) {
    width: 100%;
  }
`;
export const ButtonFilter = styled.button<TFilter>`
  padding: 12px 16px;
  height: 44px;
  background-color: transparent;
  border: ${(props) =>
    props.isFilter ? "1px solid #6D42E1" : "1px solid #bec9d2"};
  display: flex;
  align-items: center;

  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: ${(props) => (props.isFilter ? "#6D42E1" : "#161f28")};
  cursor: pointer;
  position: relative;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  svg,
  img {
    filter: ${(props) =>
      props.isFilter
        ? "brightness(0) saturate(100%) invert(30%) sepia(94%) saturate(3555%) hue-rotate(246deg) brightness(90%) contrast(96%)"
        : ""};
  }
  /* &:active {
    background-color: #dfe4e9 !important;
  } */

  /* &:focus {
    background-color: #dfe4e9 !important;
  } */

  .icon-close-filter {
    margin-left: 0px;
    padding-left: 0px;

    svg,
    img {
      filter: ${(props) =>
        props.isFilter
          ? "brightness(0) saturate(100%) invert(30%) sepia(94%) saturate(3555%) hue-rotate(246deg) brightness(90%) contrast(96%)"
          : ""};
    }
  }

  // &:hover {
  //   background-color: #eff2f4;
  // }

  @media (max-width: 922px) {
    justify-content: center;
    }
  }
`;

import React from 'react';
import { Card } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

export const ChatOptionsPopup = ({
  popupPosition,
  isMobile,
  openPopupId,
  chats,
  handleRenameChat,
  handleDeleteChat,
  closePopup,
}) => {
  if (!popupPosition.isOpen) return null;
  
  return (
    <Card
      className="fixed z-50 popup-menu shadow-xl"
      sx={{
        borderRadius: '12px',
        overflow: 'hidden',
        width: isMobile ? '100%' : 'auto',
        position: 'fixed',
        top: isMobile ? 'auto' : `${popupPosition.y}px`,
        bottom: isMobile ? '0' : 'auto',
        left: isMobile ? '0' : `${popupPosition.x}px`,
        right: isMobile ? '0' : 'auto',
        transform: isMobile ? 'none' : 'translate(-100%, 0)',
      }}
    >
      <div className="bg-white">
        <div
          className="px-4 py-3 flex items-center text-gray-700 hover:bg-gray-50 cursor-pointer transition-colors"
          onClick={() => {
            handleRenameChat(
              openPopupId,
              chats.find((chat) => chat.id === openPopupId)?.name,
            );
          }}
        >
          <Edit
            fontSize="small"
            sx={{ mr: 1.5, color: 'text.secondary' }}
          />
          <span>Renomear</span>
        </div>
        <div
          className="px-4 py-3 flex items-center text-red-600 hover:bg-red-50 cursor-pointer transition-colors"
          onClick={() => {
            handleDeleteChat(openPopupId);
          }}
        >
          <Delete fontSize="small" sx={{ mr: 1.5 }} />
          <span>Deletar</span>
        </div>
        {isMobile && (
          <div
            className="px-4 py-3 text-center text-gray-700 border-t border-gray-100 hover:bg-gray-50 cursor-pointer transition-colors"
            onClick={closePopup}
          >
            Cancelar
          </div>
        )}
      </div>
    </Card>
  );
};
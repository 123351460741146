
import React from 'react';
import OmieWizard from './OmieWizard'; // Seu componente original
import { useWizards } from '../../context/WizardsContext';

/**
 * Versão adaptada do OmieWizard para trabalhar com o Context
 */
const OmieWizardWithContext = () => {
  const {
    omieWizardOpen,
    closeOmieWizard,
    omieWizardData,
    updateOmieWizardData,
    handleOmieDataSelected,
    clientInfo,
    omieCredentials,
    activeWizard,
    bringOmieToFront
  } = useWizards();

  // Adaptador para manter a API compatível com o componente original
  const handleSelectItem = (item, type) => {
    updateOmieWizardData({ selectedItem: { ...item, type } });
  };

  const handleTabChange = (_, newValue) => {
    updateOmieWizardData({ activeTab: newValue });
  };

  const handleSearch = (term) => {
    updateOmieWizardData({ searchTerm: term });
    
    // Simulando busca (adapte para a lógica do seu componente)
    setTimeout(() => {
      const results = [/* resultados da busca */];
      updateOmieWizardData({ searchResults: results });
    }, 500);
  };

  // Função que prepara os dados para enviar ao chat
  const onSelectData = (formattedData) => {
    handleOmieDataSelected(formattedData);
  };

  return (
    <OmieWizard
      open={omieWizardOpen}
      onClose={closeOmieWizard}
      onSelectData={onSelectData}
      apiKey={omieCredentials.apiKey}
      apiSecret={omieCredentials.apiSecret}
      clientInfo={clientInfo}
      // Props adicionais para controlar o estado interno
      selectedItem={omieWizardData.selectedItem}
      activeTab={omieWizardData.activeTab}
      onTabChange={handleTabChange}
      searchTerm={omieWizardData.searchTerm}
      onSearch={handleSearch}
      searchResults={omieWizardData.searchResults}
      products={omieWizardData.products}
      purchaseHistory={omieWizardData.purchaseHistory}
      receivables={omieWizardData.receivables}
      onSelectItem={handleSelectItem}
      zIndex={activeWizard === 'omie' ? 1301 : 1300}
      onFocus={bringOmieToFront}
    />
  );
};

export default OmieWizardWithContext;
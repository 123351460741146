import React from 'react'
import { Button } from '@mui/material'

export const RecipientTypeSelector = ({ recipientType, setRecipientType }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Tipo de Destinatário
      </label>
      <div className="flex rounded-md shadow-sm">
        <Button
          variant={recipientType === 'contact' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => setRecipientType('contact')}
          sx={{
            flexGrow: 1,
            borderRadius: '8px 0 0 8px',
            textTransform: 'none',
            boxShadow: 'none',
            fontSize: '0.8rem',
          }}
        >
          Contato
        </Button>
        <Button
          variant={recipientType === 'empresa' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => setRecipientType('empresa')}
          sx={{
            flexGrow: 1,
            borderRadius: '0',
            textTransform: 'none',
            boxShadow: 'none',
            fontSize: '0.8rem',
          }}
        >
          Empresa
        </Button>
        <Button
          variant={recipientType === 'lista' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => setRecipientType('lista')}
          sx={{
            flexGrow: 1,
            borderRadius: '0 8px 8px 0',
            textTransform: 'none',
            boxShadow: 'none',
            fontSize: '0.8rem',
          }}
        >
          Lista
        </Button>
      </div>
    </div>
  )
}
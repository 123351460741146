// ChatTemplate.tsx (Componente Principal)
import { useEffect, useState } from 'react'
import { Box, Typography, Chip, Tooltip, IconButton, Menu, MenuItem, Pagination, TextField, CircularProgress } from '@mui/material'
import { Add, LocalOffer as TagIcon, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button } from '@mui/material'
import * as S from '../styles/styles'
import { llmEndpoint } from 'infra'
import {
  Template,
  TemplateType,
  TemplateChannel,
  TemplateCategory,
  TemplateModel,
  PaginatedResponse
} from './types'
import { TemplateHeader } from './TemplateHeader'
import { TemplateForm } from './TemplateForm'
import { TemplatesGrid } from './TemplatesGrid'
import { LoadingBackdrop } from './LoadingBackdrop'
import { InputSearch, SearchBarMobile } from 'components'
import { useParams } from 'react-router-dom'

export const ChatTemplate = () => {
  const { rpaId } = useParams()
  const [search, setSearch] = useState('')
  const [showInputSearch, setShowInputSearch] = useState(false)
  const [placeholderValues, setPlaceholderValues] = useState<string[]>([])
  const [showForm, setShowForm] = useState(false)
  const [edit, setEdit] = useState(false)
  const [templateName, setTemplateName] = useState('')
  const [templateDescription, setTemplateDescription] = useState('')
  const [templateType, setTemplateType] = useState<TemplateType>(
    TemplateType.MARKETING,
  )
  const [templateChannel, setTemplateChannel] = useState<TemplateChannel>(
    TemplateChannel.WHATSAPP,
  )
  const [templateModel, setTemplateModel] = useState<TemplateModel>(
    TemplateModel.SIMPLES,
  )
  const [templateCategory, setTemplateCategory] = useState<TemplateCategory>(
    TemplateCategory.OUTROS,
  )
  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  )
  const [templateText, setTemplateText] = useState('')
  const [nextPlaceholderNumber, setNextPlaceholderNumber] = useState(1)
  const [placeholders, setPlaceholders] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  
  // Paginação
  const [currentPage, setCurrentPage] = useState(0) // API começa com página 0
  const [totalPages, setTotalPages] = useState(1)
  const [totalElements, setTotalElements] = useState(0)
  const templatesPerPage = 10 // Padrão da API
  
  // Filtros
  const [templateFilter, setTemplateFilter] = useState('')
  const [descriptionFilter, setDescriptionFilter] = useState('')
  const [nameFilter, setNameFilter] = useState('')
  const [typeFilter, setTypeFilter] = useState('')
  
  // Menu states
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null)
  const [sortAnchorEl, setSortAnchorEl] = useState<HTMLElement | null>(null)
  const [typeAnchorEl, setTypeAnchorEl] = useState<HTMLElement | null>(null)
  
  // Ordenação
  const [sortField, setSortField] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('desc')

  const [placeholderBindings, setPlaceholderBindings] = useState([]);

  useEffect(() => {
    fetchTemplates()
  }, [currentPage, search, templateFilter, descriptionFilter, nameFilter, typeFilter, sortField, sortDirection])

  useEffect(() => {
    // Se estiver carregando um template existente que já tem placeholders
    // mas não tem vinculações (ou tem menos vinculações que placeholders)
    // inicialize as vinculações faltantes
    if (placeholders.length > placeholderBindings.length) {
      const newBindings = [...placeholderBindings];
      while (newBindings.length < placeholders.length) {
        newBindings.push({ objectType: '', propertyKey: '' });
      }
      setPlaceholderBindings(newBindings);
    }
  }, [placeholders, placeholderBindings]);
  
  // Atualiza os filtros quando o search muda
  useEffect(() => {
    if (search) {
      setNameFilter(search);
      setDescriptionFilter(search);
      setTemplateFilter(search);
    } else {
      setNameFilter('');
      setDescriptionFilter('');
      setTemplateFilter('');
    }
  }, [search]);
  
  // Manipuladores para o menu de filtros avançados
  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => setFilterAnchorEl(event.currentTarget);
  const handleFilterMenuClose = () => setFilterAnchorEl(null);
  
  // Manipuladores para o menu de ordenação
  const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>) => setSortAnchorEl(event.currentTarget);
  const handleSortMenuClose = () => setSortAnchorEl(null);
  
  // Manipuladores para o menu de tipo
  const handleTypeMenuOpen = (event: React.MouseEvent<HTMLElement>) => setTypeAnchorEl(event.currentTarget);
  const handleTypeMenuClose = () => setTypeAnchorEl(null);
  const handleTypeSelect = async (type: string) => {
    setIsLoading(true);
    setTypeFilter(type);
    handleTypeMenuClose();
    setCurrentPage(0);
    await fetchTemplates();
    setIsLoading(false);
  };
  
  // Aplicar filtros
  const handleApplyFilters = async () => {
    setIsLoading(true);
    setCurrentPage(0);
    await fetchTemplates();
    handleFilterMenuClose();
    setIsLoading(false);
  };
  
  // Limpar todos os filtros
  const handleClearAllFilters = async () => {
    setIsLoading(true);
    setSearch('');
    setNameFilter('');
    setDescriptionFilter('');
    setTemplateFilter('');
    setTypeFilter('');
    setCurrentPage(0);
    await fetchTemplates();
    handleFilterMenuClose();
    setIsLoading(false);
  };
  
  // Manipuladores para ordenação
  const handleSortSelect = async (field: string) => {
    setIsLoading(true);
    if (sortField === field) {
      // Inverte a direção se clicar no mesmo campo
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Define novo campo e reset da direção
      setSortField(field);
      setSortDirection('asc');
    }
    setSortAnchorEl(null);
    setCurrentPage(0);
    await fetchTemplates();
    setIsLoading(false);
  };
  
  // Função para rolar para o topo da lista quando mudar de página
  const scrollToTop = () => {
    const listContainer = document.getElementById('templates-list-container');
    if (listContainer) {
      listContainer.scrollTop = 0;
    }
  };
  
  // Rolar para o topo sempre que mudar de página
  useEffect(() => {
    scrollToTop();
  }, [currentPage]);
  
  const fetchTemplates = async (pageNum?: number) => {
    setIsLoading(true);
    try {
      // Construindo a URL com todos os parâmetros de filtro e paginação
      const url = new URL(`${llmEndpoint.url}/whatsapp/chat-template/${rpaId}/search`);
      
      // Adiciona parâmetros de filtro se não estiverem vazios
      if (nameFilter) url.searchParams.append('name', nameFilter);
      if (descriptionFilter) url.searchParams.append('description', descriptionFilter);
      if (templateFilter) url.searchParams.append('template', templateFilter);
      if (typeFilter) url.searchParams.append('type', typeFilter);
      if (search) url.searchParams.append('term', search);
      
      // Parâmetros de paginação e ordenação
      const pageToUse = pageNum !== undefined ? pageNum : currentPage;
      url.searchParams.append('page', pageToUse.toString());
      url.searchParams.append('size', templatesPerPage.toString());
      url.searchParams.append('sort', sortField);
      url.searchParams.append('direction', sortDirection);
      
      const response = await fetch(url.toString());
      
      if (!response.ok) {
        throw new Error('Erro ao buscar templates');
      }
      
      const data: PaginatedResponse<Template> = await response.json();
      console.log('Templates paginados:', data);
      
      // Atualiza o estado com os dados recebidos
      setTemplates(data.content);
      setTotalPages(data.totalPages);
      setTotalElements(data.totalElements);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const updatePlaceholders = (text: string) => {
    const matches = text.match(/{{(\d+)}}/g) || []
    const uniquePlaceholders = Array.from(
      new Set(matches.map((m) => m.replace(/[{}]/g, ''))),
    )
    setPlaceholders(uniquePlaceholders)

    // Update nextPlaceholderNumber
    const maxNumber = Math.max(...uniquePlaceholders.map(Number), 0)
    setNextPlaceholderNumber(maxNumber + 1)

    // Initialize placeholderValues if it's empty
    if (placeholderValues.length === 0) {
      setPlaceholderValues(
        uniquePlaceholders.map((_, index) =>
          selectedTemplate
            ? JSON.parse(selectedTemplate.defaultValues)[index] ||
              `Valor padrão ${index + 1}`
            : `Valor padrão ${index + 1}`,
        ),
      )
    }
  }

  const handlePlaceholderValueChange = (index: number, value: string) => {
    const newPlaceholderValues = [...placeholderValues]
    newPlaceholderValues[index] = value
    setPlaceholderValues(newPlaceholderValues)
  }

  const handleCardClick = (template: Template) => {
    console.log('Clicou no template:', template)
    setSelectedTemplate(template)
    setTemplateName(template.name)
    setTemplateDescription(template.description)
    setTemplateType(template.type as TemplateType)
    setTemplateChannel(template.channel || TemplateChannel.WHATSAPP)
    setTemplateCategory(template.category || TemplateCategory.OUTROS)
    setTemplateText(template.template)
    
    // Parse defaultValues and update placeholders
    const defaultValues = JSON.parse(template.defaultValues)
    updatePlaceholdersClick(template.template, defaultValues)
    
    setEdit(true)
    setShowForm(true)
  }

  const updatePlaceholdersClick = (text: string, defaultValues: string[] = []) => {
    const matches = text.match(/{{(\d+)}}/g) || []
    const uniquePlaceholders = Array.from(
      new Set(matches.map((m) => m.replace(/[{}]/g, '')))
    )
    setPlaceholders(uniquePlaceholders)
  
    // Update nextPlaceholderNumber
    const maxNumber = Math.max(...uniquePlaceholders.map(Number), 0)
    setNextPlaceholderNumber(maxNumber + 1)
  
    // Set placeholderValues using defaultValues or generate new ones
    setPlaceholderValues(
      uniquePlaceholders.map((_, index) =>
        defaultValues[index] || `Valor padrão ${index + 1}`
      )
    )
  }

  const handleDeleteTemplate = async () => {
    if (selectedTemplate) {
      setIsSubmitting(true)
      try {
        const response = await fetch(
          `${llmEndpoint.url}/whatsapp/chat-template/${selectedTemplate.id}`,
          {
            method: 'DELETE',
          }
        );
        
        if (!response.ok) {
          throw new Error('Erro ao deletar template');
        }
        
        // Após deletar com sucesso, atualiza a lista de templates e volta para a tela de pesquisa
        handleBackToSearch()
        // Recarrega a lista de templates
        fetchTemplates()
      } catch (error) {
        console.error('Erro ao deletar template:', error)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  function addNewProfile() {
    setShowForm(true)
    setEdit(false)
    setSelectedTemplate(null)
    setTemplateName('')
    setTemplateDescription('')
    setTemplateType(TemplateType.MARKETING)
    setTemplateChannel(TemplateChannel.WHATSAPP)
    setTemplateCategory(TemplateCategory.OUTROS)
    setTemplateText('')
    setPlaceholders([])
    setPlaceholderValues([])
    setNextPlaceholderNumber(1)
  }

  function handleBackToSearch() {
    setShowForm(false)
    setSelectedTemplate(null)
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    
    // Ativa o loading
    setIsSubmitting(true)
    
    // Handle form submission here
    const templateData = {
      name: templateName,
      description: templateDescription,
      type: templateType,
      channel: templateChannel,
      category: templateCategory,
      template: templateText,
      defaultValues: JSON.stringify(placeholderValues),
    }

    console.log('Placeholders:', placeholders)
    console.log('Placeholder Values:', placeholderValues)
    console.log('Template data:', templateData)

    try {
      const url = edit && selectedTemplate 
        ? `${llmEndpoint.url}/whatsapp/chat-template/${selectedTemplate.id}`
        : `${llmEndpoint.url}/whatsapp/chat-template/${rpaId}`;
      
      const method = edit ? 'PUT' : 'POST';
      
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(templateData),
      });
  
      if (!response.ok) {
        throw new Error(`Falha ao ${edit ? 'atualizar' : 'salvar'} o template`);
      }
  
      const savedTemplate = await response.json();
      console.log(`Template ${edit ? 'atualizado' : 'salvo'} com sucesso:`, savedTemplate);
  
      // Reset form and hide it
      setShowForm(false);
      setTemplateName('');
      setTemplateDescription('');
      setTemplateType(TemplateType.MARKETING);
      setTemplateChannel(TemplateChannel.WHATSAPP);
      setTemplateCategory(TemplateCategory.OUTROS);
      setTemplateText('');
      setPlaceholders([]);
      setPlaceholderValues([]);
      
      // Recarrega a lista de templates
      fetchTemplates();
  
    } catch (error) {
      console.error(`Erro ao ${edit ? 'atualizar' : 'salvar'} o template:`, error);
    } finally {
      // Desativa o loading, independente do resultado
      setIsSubmitting(false);
    }
  }

  const handleTemplateTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value
    setTemplateText(newText)
    updatePlaceholders(newText)
  }

  const handleAddPlaceholder = () => {
    const updatedText = `${templateText}{{${nextPlaceholderNumber}}}`
    setTemplateText(updatedText)
    updatePlaceholders(updatedText)
    setNextPlaceholderNumber(nextPlaceholderNumber + 1)
  }

  // Manipula a mudança de página
  const handlePageChange = async (_event: React.ChangeEvent<unknown> | null, newPage: number) => {
    setIsLoading(true);
    setCurrentPage(newPage - 1); // API é baseada em zero, UI é baseada em 1
    await fetchTemplates(newPage - 1);
    setIsLoading(false);
  }
  
  const handlePrevPage = async () => {
    if (currentPage > 0) {
      setIsLoading(true);
      setCurrentPage(currentPage - 1);
      await fetchTemplates(currentPage - 1);
      setIsLoading(false);
    }
  };

  const handleNextPage = async () => {
    if (currentPage < totalPages - 1) {
      setIsLoading(true);
      setCurrentPage(currentPage + 1);
      await fetchTemplates(currentPage + 1);
      setIsLoading(false);
    }
  };
  
  // Manipula a mudança de ordenação
  const handleSortChange = (field: string) => {
    if (sortField === field) {
      // Inverte a direção se o campo for o mesmo
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Define novo campo e direção padrão
      setSortField(field);
      setSortDirection('asc');
    }
    
    // Reseta para a primeira página
    setCurrentPage(0);
    fetchTemplates(0);
  }

  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">
            Crie uma mensagem template
          </h1>
          <p style={{ marginTop: '16px' }}>
            Funcionalidade para enviar mensagens em massa via WhatsApp ou e-mail.
          </p>
        </S.WrapperHeader>
      </S.Container>

      <S.Content>
        <Box sx={{ 
          overflow: 'auto',
          maxHeight: 'calc(100vh - 200px)', 
          position: 'relative',
          pb: 4,
          width: '100%'
        }}>
        
        {/* Cabeçalho com pesquisa e filtros */}
        <TemplateHeader 
          showForm={showForm}
          handleBackToSearch={handleBackToSearch}
          addNewProfile={addNewProfile}
          search={search}
          setSearch={setSearch}
          showInputSearch={showInputSearch}
          setShowInputSearch={setShowInputSearch}
        />
        
        {/* Container principal com overflow */}
        <Box 
          sx={{
            padding: '0 30px',
            height: '100%',
            overflow: 'auto', 
            maxHeight: 'calc(100vh - 250px)'
          }}
        >
          {!showForm ? (
            <TemplatesGrid 
              templates={templates}
              currentPage={currentPage + 1} // Converte de volta para exibição (começando do 1)
              templatesPerPage={templatesPerPage}
              onPageChange={handlePageChange}
              onTemplateClick={handleCardClick}
              totalItems={totalElements}
              isLoading={isLoading}
              onSortChange={handleSortChange}
              sortField={sortField}
              sortDirection={sortDirection}
            />
          ) : (
            <TemplateForm
              templateName={templateName}
              setTemplateName={setTemplateName}
              templateModel={templateModel}
              setTemplateModel={setTemplateModel}
              templateDescription={templateDescription}
              setTemplateDescription={setTemplateDescription}
              templateType={templateType}
              setTemplateType={setTemplateType}
              templateChannel={templateChannel}
              setTemplateChannel={setTemplateChannel}
              templateCategory={templateCategory}
              setTemplateCategory={setTemplateCategory}
              templateText={templateText}
              handleTemplateTextChange={handleTemplateTextChange}
              handleAddPlaceholder={handleAddPlaceholder}
              placeholders={placeholders}
              placeholderValues={placeholderValues}
              handlePlaceholderValueChange={handlePlaceholderValueChange}
              isSubmitting={isSubmitting}
              edit={edit}
              handleSubmit={handleSubmit}
              handleDeleteTemplate={handleDeleteTemplate}
              nextPlaceholderNumber={nextPlaceholderNumber}
              placeholderBindings={placeholderBindings}
              setPlaceholderBindings={setPlaceholderBindings}
            />
          )}
        </Box>
      </Box>
      </S.Content>
      
      {/* Loading Backdrop */}
      <LoadingBackdrop 
        open={isSubmitting} 
        message={edit ? 'Atualizando template...' : 'Salvando template...'}
      />
    </>
  )
}
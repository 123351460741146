import React from 'react'
import { Button } from '@intraversa-lab/styleguide'
import { Add, ArrowBack } from '@mui/icons-material'
import { InputSearch, SearchBarMobile } from 'components'
import * as S from '../styles/styles'
import { FilterListProfile } from './Filter/FilterListProfile'
import { TemplateHeaderProps } from './types'

export const TemplateHeader = ({
  showForm,
  handleBackToSearch,
  addNewProfile,
  search,
  setSearch,
  showInputSearch,
  setShowInputSearch,
}: TemplateHeaderProps) => {
  return (
    <S.WrapperSerach>
      {!showForm ? (
        <>
          <div className="filters">
            {showInputSearch ? (
              <div className="inputSearchMobile">
                <SearchBarMobile
                  placeholder={'Pesquisar'}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onClose={() => setShowInputSearch(!showInputSearch)}
                />
              </div>
            ) : (
              <>
                <InputSearch
                  placeholder="Pesquisar"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onShowMobile={() => setShowInputSearch(!showInputSearch)}
                />
                <FilterListProfile orderBy={(v) => console.log(v)} />
              </>
            )}
          </div>

          <Button
            startIcon={<Add />}
            onClick={addNewProfile}
            className="newProfile"
            sx={{
              '&:hover': {
                backgroundColor: '#3C454D !important',
                color: '#F9F9F9',
              },
            }}
          >
            Template
          </Button>
        </>
      ) : (
        <Button
          startIcon={<ArrowBack />}
          onClick={handleBackToSearch}
          className="backToSearch"
          sx={{
            '&:hover': {
              backgroundColor: '#3C454D !important',
              color: '#F9F9F9',
            },
          }}
        >
          Voltar para pesquisa
        </Button>
      )}
    </S.WrapperSerach>
  )
}
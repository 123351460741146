import axios from "axios";
import {
  Department,
  User,
  Board,
  PaginatedResponse,
  DepartmentPayload,
} from "../types";

import { llmEndpoint } from "infra";

const API_URL = llmEndpoint.url;

// Configuração global do axios
axios.defaults.headers.common["Content-Type"] = "application/json";
// Se precisar adicionar um token de autenticação:
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

export const ApiService = {
  // ==================== DEPARTAMENTOS ====================
  async getDepartments(
    rpaId: string,
    page = 0,
    size = 100,
    sort = "name",
    direction = "asc",
  ): Promise<Department[]> {
    try {
      const response = await axios.get<PaginatedResponse<Department>>(
        `${API_URL}/departments-crm/${rpaId}/search?page=${page}&size=${size}&sort=${sort}&direction=${direction}`,
      );
      return response.data.content;
    } catch (error) {
      console.error("Erro ao buscar departamentos:", error);
      throw error;
    }
  },

  async getDepartmentById(id: string): Promise<Department> {
    try {
      const response = await axios.get<Department>(
        `${API_URL}/departments-crm/${id}`,
      );
      return response.data;
    } catch (error) {
      console.error(`Erro ao buscar departamento ${id}:`, error);
      throw error;
    }
  },

  async createDepartment(
    rpaId: string,
    data: DepartmentPayload,
  ): Promise<Department> {
    try {
      // Certifique-se de que o payload está correto
      const payload = {
        name: data.name,
        description: data.description,
        boards: data.boards,
        users: data.users.map((user) => ({
          userId: user.userId,
          role: user.role,
        })),
      };

      const response = await axios.post<Department>(
        `${API_URL}/departments-crm/${rpaId}`,
        payload,
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao criar departamento:", error);
      throw error;
    }
  },

  async updateDepartment(
    rpaId: string,
    id: string,
    data: DepartmentPayload,
  ): Promise<Department> {
    try {
      // Certifique-se de que o payload está correto
      const payload = {
        name: data.name,
        description: data.description,
        boards: data.boards, // Array de IDs
        users: data.users.map((user) => ({
          userId: user.userId,
          role: user.role,
        })),
      };

      const response = await axios.put<Department>(
        `${API_URL}/departments-crm/${rpaId}/${id}`,
        payload,
      );
      return response.data;
    } catch (error) {
      console.error(`Erro ao atualizar departamento ${id}:`, error);
      throw error;
    }
  },

  async deleteDepartment(id: string): Promise<void> {
    try {
      await axios.delete(`${API_URL}/departments-crm/${id}`);
    } catch (error) {
      console.error(`Erro ao excluir departamento ${id}:`, error);
      throw error;
    }
  },

  async toggleDepartmentActive(
    id: string,
    active: boolean,
  ): Promise<Department> {
    try {
      const response = await axios.patch<Department>(
        `${API_URL}/departments-crm/${id}/active`,
        { active },
      );
      return response.data;
    } catch (error) {
      console.error(`Erro ao alterar status do departamento ${id}:`, error);
      throw error;
    }
  },

  // ==================== USUÁRIOS ====================
  async getUsers(
    rpaId: string,
    page = 0,
    size = 100,
    sort = "name",
    direction = "asc",
  ): Promise<User[]> {
    try {
      const response = await axios.get<PaginatedResponse<User>>(
        `${API_URL}/user-crm/${rpaId}/search?page=${page}&size=${size}&sort=${sort}&direction=${direction}`,
      );
      return response.data.content;
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      throw error;
    }
  },

  async getUserById(id: string): Promise<User> {
    try {
      const response = await axios.get<User>(`${API_URL}/user-crm/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao buscar usuário ${id}:`, error);
      throw error;
    }
  },

  async createUser(data: Partial<User>): Promise<User> {
    try {
      const response = await axios.post<User>(`${API_URL}/user-crm`, data);
      return response.data;
    } catch (error) {
      console.error("Erro ao criar usuário:", error);
      throw error;
    }
  },

  async updateUser(id: string, data: Partial<User>): Promise<User> {
    try {
      const response = await axios.put<User>(`${API_URL}/user-crm/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(`Erro ao atualizar usuário ${id}:`, error);
      throw error;
    }
  },

  async deleteUser(id: string): Promise<void> {
    try {
      await axios.delete(`${API_URL}/user-crm/${id}`);
    } catch (error) {
      console.error(`Erro ao excluir usuário ${id}:`, error);
      throw error;
    }
  },

  async toggleUserActive(id: string, active: boolean): Promise<User> {
    try {
      const response = await axios.patch<User>(
        `${API_URL}/user-crm/${id}/active`,
        { active },
      );
      return response.data;
    } catch (error) {
      console.error(`Erro ao alterar status do usuário ${id}:`, error);
      throw error;
    }
  },

  // ==================== BOARDS ====================
  async getBoards(rpaId: string): Promise<Board[]> {
    try {
      const response = await axios.get<Board[]>(
        `${API_URL}/kanban/boards/${rpaId}`,
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao buscar boards:", error);
      throw error;
    }
  },

  // ==================== DEPARTAMENTO-USUÁRIO E DEPARTAMENTO-BOARD ====================
  async addUserToDepartment(
    departmentId: string,
    userId: string,
    isAdmin: boolean,
  ): Promise<Department> {
    try {
      const response = await axios.post<Department>(
        `${API_URL}/departments-crm/${departmentId}/users`,
        { userId, isAdmin },
      );
      return response.data;
    } catch (error) {
      console.error(
        `Erro ao adicionar usuário ${userId} ao departamento ${departmentId}:`,
        error,
      );
      throw error;
    }
  },

  async removeUserFromDepartment(
    departmentId: string,
    userId: string,
  ): Promise<Department> {
    try {
      const response = await axios.delete<Department>(
        `${API_URL}/departments-crm/${departmentId}/users/${userId}`,
      );
      return response.data;
    } catch (error) {
      console.error(
        `Erro ao remover usuário ${userId} do departamento ${departmentId}:`,
        error,
      );
      throw error;
    }
  },

  async addBoardToDepartment(
    departmentId: string,
    boardId: string,
  ): Promise<Department> {
    try {
      const response = await axios.post<Department>(
        `${API_URL}/departments-crm/${departmentId}/boards`,
        { boardId },
      );
      return response.data;
    } catch (error) {
      console.error(
        `Erro ao adicionar board ${boardId} ao departamento ${departmentId}:`,
        error,
      );
      throw error;
    }
  },

  async removeBoardFromDepartment(
    departmentId: string,
    boardId: string,
  ): Promise<Department> {
    try {
      const response = await axios.delete<Department>(
        `${API_URL}/departments-crm/${departmentId}/boards/${boardId}`,
      );
      return response.data;
    } catch (error) {
      console.error(
        `Erro ao remover board ${boardId} do departamento ${departmentId}:`,
        error,
      );
      throw error;
    }
  },

  // Método para atualizar o papel do usuário em um departamento
  async updateUserRoleInDepartment(
    departmentId: string,
    userId: string,
    isAdmin: boolean,
  ): Promise<Department> {
    try {
      const response = await axios.patch<Department>(
        `${API_URL}/departments-crm/${departmentId}/users/${userId}/role`,
        { isAdmin },
      );
      return response.data;
    } catch (error) {
      console.error(
        `Erro ao atualizar papel do usuário ${userId} no departamento ${departmentId}:`,
        error,
      );
      throw error;
    }
  },
};

import { useState, useCallback } from "react";
import { Produto, FilterOptions, ApiResponse } from "../types";
import { prepareProdutoData } from "../utils/helpers";
import { llmEndpoint } from "infra";

interface UseProdutoAPIProps {
  rpaId?: string;
}

interface UseProdutoAPIReturn {
  produtos: Produto[];
  loading: boolean;
  error: string | null;
  successMessage: string | null;
  fetchProdutos: (filters: FilterOptions) => Promise<void>;
  createProduto: (produtoData: Record<string, any>) => Promise<boolean>;
  updateProduto: (
    id: string,
    produtoData: Record<string, any>,
  ) => Promise<boolean>;
  deleteProduto: (id: string) => Promise<boolean>;
  clearMessages: () => void;
}

export const useProdutoAPI = ({
  rpaId,
}: UseProdutoAPIProps): UseProdutoAPIReturn => {
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const clearMessages = useCallback(() => {
    setError(null);
    setSuccessMessage(null);
  }, []);

  const processApiResponse = useCallback(<T>(data: any): T => {
    // Verifica se a API retorna no formato { success: true, data: [...] }
    if (data && data.success && data.data) {
      return data.data;
    }
    // Ou se retorna diretamente os dados
    return data;
  }, []);

  const fetchProdutos = useCallback(
    async (filters: FilterOptions) => {
      if (!rpaId) {
        setError("ID do RPA não encontrado");
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // Construir a URL com parâmetros de filtro
        let url = `${llmEndpoint.url}/product/${rpaId}`;

        const params = new URLSearchParams();
        if (filters.search) params.append("search", filters.search);
        if (filters.categoria) params.append("categoria", filters.categoria);
        if (filters.tipoFilter === "produto")
          params.append("isServico", "false");
        if (filters.tipoFilter === "servico")
          params.append("isServico", "true");

        const queryString = params.toString();
        if (queryString) url += `?${queryString}`;

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Erro na requisição: ${response.status}`);
        }

        const data = await response.json();
        setProdutos(processApiResponse<Produto[]>(data));
      } catch (error) {
        console.error("Erro ao carregar produtos:", error);
        setError("Falha ao carregar produtos. Tente novamente.");
        setProdutos([]);
      } finally {
        setLoading(false);
      }
    },
    [rpaId, processApiResponse],
  );

  const createProduto = useCallback(
    async (produtoData: Record<string, any>): Promise<boolean> => {
      if (!rpaId) {
        setError("ID do RPA não encontrado");
        return false;
      }

      try {
        setLoading(true);
        setError(null);

        const data = prepareProdutoData(produtoData);

        const response = await fetch(`${llmEndpoint.url}/product/${rpaId}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error(`Erro ao criar: ${response.status}`);
        }

        const resultado = await response.json();
        const novoProduto = processApiResponse<Produto>(resultado);

        // Adicionar o novo produto à lista
        setProdutos((prevProdutos) => [...prevProdutos, novoProduto]);
        setSuccessMessage(
          `${data.isServico ? "Serviço" : "Produto"} criado com sucesso!`,
        );
        return true;
      } catch (error) {
        console.error("Erro ao salvar produto:", error);
        setError(
          "Falha ao salvar os dados. Verifique se o código não está duplicado.",
        );
        return false;
      } finally {
        setLoading(false);
      }
    },
    [rpaId, processApiResponse],
  );

  const updateProduto = useCallback(
    async (id: string, produtoData: Record<string, any>): Promise<boolean> => {
      if (!rpaId) {
        setError("ID do RPA não encontrado");
        return false;
      }

      try {
        setLoading(true);
        setError(null);

        const data = prepareProdutoData(produtoData);

        const response = await fetch(
          `${llmEndpoint.url}/product/${rpaId}/${id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          },
        );

        if (!response.ok) {
          throw new Error(`Erro ao atualizar: ${response.status}`);
        }

        const resultado = await response.json();
        const produtoAtualizado = processApiResponse<Produto>(resultado);

        // Atualizar o produto na lista
        setProdutos((prevProdutos) =>
          prevProdutos.map((p) => (p.id === id ? produtoAtualizado : p)),
        );

        setSuccessMessage(
          `${data.isServico ? "Serviço" : "Produto"} atualizado com sucesso!`,
        );
        return true;
      } catch (error) {
        console.error("Erro ao atualizar produto:", error);
        setError("Falha ao atualizar os dados. Tente novamente.");
        return false;
      } finally {
        setLoading(false);
      }
    },
    [rpaId, processApiResponse],
  );

  const deleteProduto = useCallback(
    async (id: string): Promise<boolean> => {
      if (!rpaId) {
        setError("ID do RPA não encontrado");
        return false;
      }

      try {
        setLoading(true);
        setError(null);

        const response = await fetch(
          `${llmEndpoint.url}/product/${rpaId}/${id}`,
          {
            method: "DELETE",
          },
        );

        if (!response.ok) {
          throw new Error(`Erro ao excluir: ${response.status}`);
        }

        // Remover o produto da lista
        setProdutos((prevProdutos) => prevProdutos.filter((p) => p.id !== id));
        setSuccessMessage("Item excluído com sucesso!");
        return true;
      } catch (error) {
        console.error("Erro ao deletar produto:", error);
        setError("Falha ao excluir o item. Tente novamente.");
        return false;
      } finally {
        setLoading(false);
      }
    },
    [rpaId],
  );

  return {
    produtos,
    loading,
    error,
    successMessage,
    fetchProdutos,
    createProduto,
    updateProduto,
    deleteProduto,
    clearMessages,
  };
};

import React from 'react';
import OmieWizardWithContext from '../omieWizard/OmieWizardWithContext';
import AISalesAssistantWithContext from '../aISalesAssistantWizard/AISalesAssistantWithContext';

/**
 * Componente container para renderizar os wizards
 * Deve ser incluído em um nível alto da aplicação
 */
const WizardsContainer = () => {
  return (
    <>
      <OmieWizardWithContext />
      <AISalesAssistantWithContext />
    </>
  );
};

export default WizardsContainer;
// Arquivo: hooks/useEmpresaModal.tsx
import React, { useState, useCallback } from 'react';

// Interfaces
interface Contato {
  id: string;
  name: string;
  number: string;
  description?: string;
  active: boolean;
  rpaId: string;
  tags?: string;
}

interface Endereco {
  zipCode: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  complement?: string;
}

interface EmpresaData {
  id?: string;
  nome: string;
  descricao: string;
  document: string;
  address: Endereco;
  tags: string;
  contatos: Contato[];
}

const defaultEndereco: Endereco = {
  zipCode: '',
  state: '',
  city: '',
  neighborhood: '',
  street: '',
  number: '',
  complement: '',
};

const defaultEmpresaData: EmpresaData = {
  nome: '',
  descricao: '',
  document: '',
  address: defaultEndereco,
  tags: '',
  contatos: [],
};

export const useEmpresaModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [empresaData, setEmpresaData] = useState<EmpresaData>(defaultEmpresaData);
  
  // Funções para manipular os contatos
  const handleRemoveContato = useCallback((id: string) => {
    setEmpresaData(prev => ({
      ...prev,
      contatos: prev.contatos.filter(contato => contato.id !== id)
    }));
  }, []);

  const handleAddContato = useCallback((contato: Contato) => {
    setEmpresaData(prev => ({
      ...prev,
      contatos: [...prev.contatos, contato]
    }));
  }, []);

  // Função para abrir o modal em modo de criação
  const openCreateModal = useCallback(() => {
    setEmpresaData(defaultEmpresaData);
    setIsEdit(false);
    setIsOpen(true);
  }, []);

  // Função para abrir o modal em modo de edição
  const openEditModal = useCallback((empresa: EmpresaData) => {
    setEmpresaData(empresa);
    setIsEdit(true);
    setIsOpen(true);
  }, []);

  // Função para fechar o modal
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  // Funções para atualizar os dados do formulário
  const setEmpresaNome = useCallback((nome: string) => {
    setEmpresaData(prev => ({ ...prev, nome }));
  }, []);

  const setEmpresaDocumento = useCallback((document: string) => {
    setEmpresaData(prev => ({ ...prev, document }));
  }, []);

  const setEmpresaDescricao = useCallback((descricao: string) => {
    setEmpresaData(prev => ({ ...prev, descricao }));
  }, []);

  const setEmpresaEndereco = useCallback((address: Endereco) => {
    setEmpresaData(prev => ({ ...prev, address }));
  }, []);

  const setEmpresaTags = useCallback((tags: string) => {
    setEmpresaData(prev => ({ ...prev, tags }));
  }, []);

  return {
    isOpen,
    isEdit,
    empresaData,
    openCreateModal,
    openEditModal,
    closeModal,
    setEmpresaNome,
    setEmpresaDocumento,
    setEmpresaDescricao,
    setEmpresaEndereco,
    setEmpresaTags,
    handleRemoveContato,
    handleAddContato,
  };
};

export default useEmpresaModal;
import { useEffect, useState } from 'react'

import { Button } from '@intraversa-lab/styleguide'
import {
  Add,
  ArrowBack,
  PersonAdd,
  BusinessCenter,
  Delete,
  Send,
} from '@mui/icons-material'
import {
  Card,
  CardContent,
  TextField,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  FormControlLabel,
  Avatar,
  Box,
  Divider,
  Tab,
  Tabs,
  Badge,
} from '@mui/material'
import { InputSearch, SearchBarMobile } from 'components'
import { llmEndpoint } from 'infra'

import * as S from '../styles/styles'

import { FilterListProfile } from './Filter/FilterListProfile'
import { PaginationComponent } from '../../../../components/PaginationComponent/PaginationComponent' // Importando o componente de paginação
import { useParams } from 'react-router-dom'

// Interface para Contato
interface Contato {
  id: string
  active: boolean
  name: string
  description: string
  number: string
  rpaId: string
}

// Interface para Empresa
interface Empresa {
  id: string
  active: boolean
  createdAt: string
  updatedAt: string
  name: string
  description: string
  endereco: string
  rpaId: string
  contacts: Contato[]
}

// Interface para Lista de Transmissão
interface ListaTransmissao {
  id: string
  name: string
  description: string
  createdAt: string
  updatedAt: string
  rpaId: string
  companies: Empresa[]
  contacts: Contato[]
}

export const ListTransmissao = () => {
  const { rpaId } = useParams();
  const [search, setSearch] = useState('')
  const [showInputSearch, setShowInputSearch] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [edit, setEdit] = useState(false)
  const [listaNome, setListaNome] = useState('')
  const [listaDescricao, setListaDescricao] = useState('')
  const [listas, setListas] = useState<ListaTransmissao[]>([])
  const [empresas, setEmpresas] = useState<Empresa[]>([])
  const [contatos, setContatos] = useState<Contato[]>([])
  const [listaEmpresas, setListaEmpresas] = useState<Empresa[]>([])
  const [listaContatos, setListaContatos] = useState<Contato[]>([])
  const [selectedLista, setSelectedLista] = useState<ListaTransmissao | null>(
    null,
  )
  const [tabValue, setTabValue] = useState(0)
  const [messageDialogOpen, setMessageDialogOpen] = useState(false)
  const [messageText, setMessageText] = useState('')

  // Estados para os diálogos de seleção
  const [contatosDialogOpen, setContatosDialogOpen] = useState(false)
  const [empresasDialogOpen, setEmpresasDialogOpen] = useState(false)
  const [contatosDisponiveis, setContatosDisponiveis] = useState<Contato[]>([])
  const [empresasDisponiveis, setEmpresasDisponiveis] = useState<Empresa[]>([])
  const [contatosSelecionados, setContatosSelecionados] = useState<string[]>([])
  const [empresasSelecionadas, setEmpresasSelecionadas] = useState<string[]>([])
  const [searchContato, setSearchContato] = useState('')
  const [searchEmpresa, setSearchEmpresa] = useState('')
  
  // Estados para paginação
  const [currentPage, setCurrentPage] = useState(1)
  const listasPerPage = 9 // Definindo quantas listas por página
  
  // Estados para paginação nos diálogos
  const [currentContatosPage, setCurrentContatosPage] = useState(1)
  const [currentEmpresasPage, setCurrentEmpresasPage] = useState(1)
  const itemsPerDialogPage = 8 // Itens por página nos diálogos

  useEffect(() => {
    fetchListas()
    fetchEmpresas()
    fetchContatos()
  }, [])

  const fetchListas = async () => {
    try {
      const response = await fetch(
        `${llmEndpoint.url}/whatsapp/broadcast-list/${rpaId}`,
      )
      const data = await response.json()
      setListas(Array.isArray(data) ? data : [data])
    } catch (error) {
      console.error('Erro ao buscar listas de transmissão:', error)
      setListas([])
    }
  }

  const fetchEmpresas = async () => {
    try {
      const response = await fetch(
        `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}`,
      )
      const data = await response.json()
      console.log('Empresas:', data)

      if (Array.isArray(data)) {
        setEmpresas(data)
      } else if (data && typeof data === 'object') {
        setEmpresas([data])
      } else {
        console.error('Formato de resposta inválido:', data)
        setEmpresas([])
      }
    } catch (error) {
      console.error('Erro ao buscar empresas:', error)
      setEmpresas([])
    }
  }

  const fetchContatos = async () => {
    try {
      const response = await fetch(
        `${llmEndpoint.url}/whatsapp/contacts/${rpaId}`,
      )
      const data = await response.json()
      console.log('Contatos disponíveis:', data)
      setContatos(Array.isArray(data) ? data : [])
    } catch (error) {
      console.error('Erro ao buscar contatos:', error)
      setContatos([])
    }
  }

  const handleCardClick = (lista: ListaTransmissao) => {
    console.log('Clicou na lista:', lista)
    setSelectedLista(lista)
    setListaNome(lista.name)
    setListaDescricao(lista.description)
    setListaEmpresas(lista.companies || [])
    setListaContatos(lista.contacts || [])
    setEdit(true)
    setShowForm(true)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleDeleteLista = async () => {
    if (selectedLista) {
      try {
        // Simulação para demo
        setListas(listas.filter((l) => l.id !== selectedLista.id))
        handleBackToSearch()

        // Na implementação real, usar:
        // const response = await fetch(`http://localhost:8080/api/v1/whatsapp/broadcast-lists/${selectedLista.id}`, {
        //   method: 'DELETE',
        // })
      } catch (error) {
        console.error('Erro ao deletar lista de transmissão:', error)
      }
    }
  }

  function addNewLista() {
    setShowForm(true)
    setEdit(false)
    setSelectedLista(null)
    setListaNome('')
    setListaDescricao('')
    setListaEmpresas([])
    setListaContatos([])
    setTabValue(0)
  }

  function handleBackToSearch() {
    setShowForm(false)
    setSelectedLista(null)
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    const listaData = {
      name: listaNome,
      description: listaDescricao,
      rpaId: rpaId,
      companies: listaEmpresas.map((e) => e.id),
      contacts: listaContatos.map((c) => c.id),
    }

    try {
      let url = `${llmEndpoint.url}/whatsapp/broadcast-list/${rpaId}`
      let method = 'POST'
      if (edit && selectedLista) {
        url = `${url}/${selectedLista.id}`
        method = 'PUT'
      }
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(listaData),
      })
      // const data = await response.json()

      setShowForm(false)
      setListaNome('')
      setListaDescricao('')
      setListaEmpresas([])
      setListaContatos([])
    } catch (error) {
      console.error('Erro ao salvar lista de transmissão:', error)
    }
  }

  const handleOpenContatosDialog = () => {
    const idsContatosJaAdicionados = listaContatos.map((c) => c.id)
    const disponiveis = contatos.filter(
      (c) => !idsContatosJaAdicionados.includes(c.id),
    )

    setContatosDisponiveis(disponiveis)
    setContatosSelecionados([])
    setContatosDialogOpen(true)
    setCurrentContatosPage(1) // Reset da página atual
  }

  const handleOpenEmpresasDialog = () => {
    const idsEmpresasJaAdicionadas = listaEmpresas.map((e) => e.id)
    const disponiveis = empresas.filter(
      (e) => !idsEmpresasJaAdicionadas.includes(e.id),
    )

    setEmpresasDisponiveis(disponiveis)
    setEmpresasSelecionadas([])
    setEmpresasDialogOpen(true)
    setCurrentEmpresasPage(1) // Reset da página atual
  }

  const handleToggleContato = (contatoId: string) => {
    setContatosSelecionados((prev) => {
      if (prev.includes(contatoId)) {
        return prev.filter((id) => id !== contatoId)
      } else {
        return [...prev, contatoId]
      }
    })
  }

  const handleToggleEmpresa = (empresaId: string) => {
    setEmpresasSelecionadas((prev) => {
      if (prev.includes(empresaId)) {
        return prev.filter((id) => id !== empresaId)
      } else {
        return [...prev, empresaId]
      }
    })
  }

  const handleAddContatosSelecionados = () => {
    const novosContatos = contatosDisponiveis.filter((c) =>
      contatosSelecionados.includes(c.id),
    )

    setListaContatos([...listaContatos, ...novosContatos])
    setContatosDialogOpen(false)
  }

  const handleAddEmpresasSelecionadas = () => {
    const novasEmpresas = empresasDisponiveis.filter((e) =>
      empresasSelecionadas.includes(e.id),
    )

    setListaEmpresas([...listaEmpresas, ...novasEmpresas])
    setEmpresasDialogOpen(false)
  }

  const handleRemoveContato = (contatoId: string) => {
    setListaContatos(listaContatos.filter((c) => c.id !== contatoId))
  }

  const handleRemoveEmpresa = (empresaId: string) => {
    setListaEmpresas(listaEmpresas.filter((e) => e.id !== empresaId))
  }

  const handleSendMessage = async () => {
    if (!messageText.trim()) {
      alert('Digite uma mensagem para enviar')
      return
    }

    try {
      const destinatarios = [
        ...listaContatos.map((c) => c.number),
        ...listaEmpresas.flatMap((e) => e.contacts || []).map((c) => c.number),
      ]

      const messageData = {
        listaId: selectedLista?.id,
        message: messageText,
        destinatarios,
      }

      console.log('Enviando mensagem para:', messageData)
      alert(`Mensagem será enviada para ${destinatarios.length} destinatários`)

      setMessageDialogOpen(false)
      setMessageText('')
    } catch (error) {
      console.error('Erro ao enviar mensagem em massa:', error)
    }
  }

  const getTotalDestinatarios = () => {
    const contatosDiretos = listaContatos.length
    const contatosEmpresas = listaEmpresas.reduce(
      (acc, empresa) => acc + (empresa.contacts?.length || 0),
      0,
    )

    return contatosDiretos + contatosEmpresas
  }
  
  // Filtra as listas com base na pesquisa
  const filteredListas = listas.filter(
    (lista) =>
      lista?.name?.toLowerCase().includes(search.toLowerCase()) ||
      lista.description?.toLowerCase().includes(search.toLowerCase())
  )
  
  // Filtra os contatos com base na pesquisa
  const filteredContatos = contatosDisponiveis.filter(
    (contato) =>
      contato.name.toLowerCase().includes(searchContato.toLowerCase()) ||
      contato.number.includes(searchContato)
  )
  
  // Filtra as empresas com base na pesquisa
  const filteredEmpresas = empresasDisponiveis.filter(
    (empresa) =>
      empresa.name.toLowerCase().includes(searchEmpresa.toLowerCase()) ||
      empresa.description?.toLowerCase().includes(searchEmpresa.toLowerCase())
  )

  // Obtém os itens da página atual para cada tipo
  const getCurrentPageListas = () => {
    const startIndex = (currentPage - 1) * listasPerPage
    const endIndex = startIndex + listasPerPage
    return filteredListas.slice(startIndex, endIndex)
  }
  
  const getCurrentPageContatos = () => {
    const startIndex = (currentContatosPage - 1) * itemsPerDialogPage
    const endIndex = startIndex + itemsPerDialogPage
    return filteredContatos.slice(startIndex, endIndex)
  }
  
  const getCurrentPageEmpresas = () => {
    const startIndex = (currentEmpresasPage - 1) * itemsPerDialogPage
    const endIndex = startIndex + itemsPerDialogPage
    return filteredEmpresas.slice(startIndex, endIndex)
  }

  // Manipuladores de mudança de página
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }
  
  const handleContatosPageChange = (page: number) => {
    setCurrentContatosPage(page)
  }
  
  const handleEmpresasPageChange = (page: number) => {
    setCurrentEmpresasPage(page)
  }

  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">
            Listas de Transmissão do WhatsApp
          </h1>
          <p style={{ marginTop: '16px' }}>
            Crie e gerencie listas de transmissão para envio de mensagens em
            massa via WhatsApp.
          </p>
        </S.WrapperHeader>
      </S.Container>

      <S.Content>
        <Box sx={{ 
          overflow: 'auto',
          maxHeight: 'calc(100vh - 200px)', 
          position: 'relative',
          pb: 4,
          width: '100%'
        }}>
          <S.WrapperSerach>
            {!showForm ? (
              <>
                <div className="filters">
                  {showInputSearch ? (
                    <div className="inputSearchMobile">
                      <SearchBarMobile
                        placeholder={'Pesquisar lista de transmissão'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onClose={() => setShowInputSearch(!showInputSearch)}
                      />
                    </div>
                  ) : (
                    <>
                      <InputSearch
                        placeholder="Pesquisar lista de transmissão"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onShowMobile={() => setShowInputSearch(!showInputSearch)}
                      />
                      <FilterListProfile orderBy={(v) => console.log(v)} />
                    </>
                  )}
                </div>

                <Button
                  startIcon={<Add />}
                  onClick={() => addNewLista()}
                  className="newProfile"
                  sx={{
                    '&:hover': {
                      backgroundColor: '#3C454D !important',
                      color: '#F9F9F9',
                    },
                  }}
                >
                  Nova Lista de Transmissão
                </Button>
              </>
            ) : (
              <Button
                startIcon={<ArrowBack />}
                onClick={handleBackToSearch}
                className="backToSearch"
                sx={{
                  '&:hover': {
                    backgroundColor: '#3C454D !important',
                    color: '#F9F9F9',
                  },
                }}
              >
                Voltar para pesquisa
              </Button>
            )}
          </S.WrapperSerach>

          {/* Container principal com padding adequado */}
          <Box sx={{ padding: '0 30px', height: '100%', overflow: 'auto' }}>
            {/* Listagem de Listas de Transmissão */}
            {!showForm ? (
              <Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                    gap: '20px',
                    mb: 4
                  }}
                >
                  {getCurrentPageListas().map((lista) => (
                    <Card
                      key={lista.id}
                      onClick={() => handleCardClick(lista)}
                      sx={{
                        cursor: 'pointer',
                        width: '100%',
                        transition: 'all 0.3s ease',
                        borderRadius: '12px',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                        position: 'relative',
                        overflow: 'hidden',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
                        },
                        '&:before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '6px',
                          height: '100%',
                          backgroundColor: '#3f51b5',
                          borderTopLeftRadius: '12px',
                          borderBottomLeftRadius: '12px',
                        },
                      }}
                    >
                      <CardContent sx={{ padding: '20px' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '8px',
                          }}
                        >
                          <Typography
                            variant="h5"
                            component="div"
                            sx={{
                              fontWeight: 600,
                              color: '#32394a',
                              fontSize: '18px',
                              maxWidth: '80%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {lista.name}
                          </Typography>
                          <div
                            style={{
                              backgroundColor: '#f0f4f8',
                              borderRadius: '50%',
                              width: '40px',
                              height: '40px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: '#3f51b5',
                            }}
                          >
                            {lista.name.charAt(0).toUpperCase()}
                          </div>
                        </div>

                        <Typography
                          variant="body2"
                          sx={{
                            mb: 2,
                            color: '#637381',
                            fontSize: '14px',
                            lineHeight: 1.5,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            height: '40px',
                          }}
                        >
                          {lista.description || 'Sem descrição'}
                        </Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            mt: 1,
                            borderTop: '1px solid #f0f4f8',
                            paddingTop: '10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                fontSize: '12px',
                                color: '#637381',
                                fontWeight: 500,
                              }}
                            >
                              <span style={{ fontWeight: 600, color: '#32394a' }}>
                                {lista.contacts?.length || 0}
                              </span>{' '}
                              contatos
                            </Typography>

                            <Typography
                              variant="caption"
                              sx={{
                                fontSize: '12px',
                                color: '#637381',
                                fontWeight: 500,
                              }}
                            >
                              <span style={{ fontWeight: 600, color: '#32394a' }}>
                                {lista.companies?.length || 0}
                              </span>{' '}
                              empresas
                            </Typography>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Chip
                              icon={<Send style={{ fontSize: '16px' }} />}
                              label={`${(lista.contacts?.length || 0) + (lista.companies?.reduce((acc, e) => acc + (e.contacts?.length || 0), 0) || 0)} destinatários`}
                              color="primary"
                              size="small"
                              sx={{
                                height: '28px',
                                fontSize: '12px',
                                fontWeight: 600,
                                '& .MuiChip-icon': {
                                  fontSize: '16px',
                                  color: 'inherit',
                                },
                              }}
                            />

                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: '#919eab',
                                fontStyle: 'italic',
                              }}
                            >
                              Clique para editar
                            </Typography>
                          </div>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
                
                {/* Componente de paginação para listas */}
                {filteredListas.length > 0 && (
                  <PaginationComponent
                    currentPage={currentPage}
                    totalItems={filteredListas.length}
                    itemsPerPage={listasPerPage}
                    onPageChange={handlePageChange}
                    itemName="listas"
                  />
                )}
              </Box>
            ) : (
              /* Formulário de Lista de Transmissão */
              <Box sx={{ overflowY: 'auto', pb: 4 }}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Nome da Lista de Transmissão"
                    value={listaNome}
                    onChange={(e) => setListaNome(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Descrição"
                    value={listaDescricao}
                    onChange={(e) => setListaDescricao(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </form>

                <Box sx={{ mt: 4, mb: 1 }}>
                  <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab
                      label={
                        <Badge badgeContent={listaContatos.length} color="primary">
                          Contatos
                        </Badge>
                      }
                    />
                    <Tab
                      label={
                        <Badge badgeContent={listaEmpresas.length} color="primary">
                          Empresas
                        </Badge>
                      }
                    />
                  </Tabs>
                  <Divider />
                </Box>

                {/* Tab de Contatos */}
                {tabValue === 0 && (
                  <Box sx={{ mt: 2, maxHeight: 'calc(100vh - 400px)', overflow: 'auto' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 1,
                        py: 1
                      }}
                    >
                      <Typography variant="subtitle1">
                        Contatos ({listaContatos.length})
                      </Typography>
                      <Button
                        variant="outlined"
                        startIcon={<PersonAdd />}
                        onClick={handleOpenContatosDialog}
                      >
                        Adicionar Contatos
                      </Button>
                    </Box>

                    {listaContatos.length > 0 ? (
                      <List>
                        {listaContatos.map((contato) => (
                          <ListItem key={contato.id}>
                            <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                              {contato.name.charAt(0).toUpperCase()}
                            </Avatar>
                            <ListItemText
                              primary={contato.name}
                              secondary={contato.number}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                onClick={() => handleRemoveContato(contato.id)}
                              >
                                <Delete />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        Nenhum contato adicionado a esta lista de transmissão.
                      </Typography>
                    )}
                  </Box>
                )}

                {/* Tab de Empresas */}
                {tabValue === 1 && (
                  <Box sx={{ mt: 2, maxHeight: 'calc(100vh - 400px)', overflow: 'auto' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 1,
                        py: 1
                      }}
                    >
                      <Typography variant="subtitle1">
                        Empresas ({listaEmpresas.length})
                      </Typography>
                      <Button
                        variant="outlined"
                        startIcon={<BusinessCenter />}
                        onClick={handleOpenEmpresasDialog}
                      >
                        Adicionar Empresas
                      </Button>
                    </Box>

                    {listaEmpresas.length > 0 ? (
                      <List>
                        {listaEmpresas.map((empresa) => (
                          <ListItem key={empresa.id}>
                            <Avatar sx={{ bgcolor: 'secondary.main', mr: 2 }}>
                              {empresa.name.charAt(0).toUpperCase()}
                            </Avatar>
                            <ListItemText
                              primary={empresa.name}
                              secondary={`${empresa.contacts?.length || 0} contatos associados`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                onClick={() => handleRemoveEmpresa(empresa.id)}
                              >
                                <Delete />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        Nenhuma empresa adicionada a esta lista de transmissão.
                      </Typography>
                    )}
                  </Box>
                )}

                {/* Resumo da Lista */}
                <Box sx={{ mt: 4, bgcolor: '#f5f5f5', p: 2, borderRadius: 1 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Resumo da Lista de Transmissão
                  </Typography>
                  <Typography variant="body2">
                    Total de destinatários: <b>{getTotalDestinatarios()}</b> (
                    {listaContatos.length} contatos diretos +{' '}
                    {listaEmpresas.reduce(
                      (acc, empresa) => acc + (empresa.contacts?.length || 0),
                      0,
                    )}{' '}
                    contatos via empresas)
                  </Typography>
                </Box>

                {/* Botões de Ação */}
                <Box sx={{ mt: 4, mb: 4, display: 'flex', gap: 2 }}>
                  {edit ? (
                    <>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteLista}
                      >
                        Deletar Lista
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Atualizar Lista
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<Send />}
                        onClick={() => setMessageDialogOpen(true)}
                        disabled={getTotalDestinatarios() === 0}
                      >
                        Enviar Mensagem
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Salvar Lista de Transmissão
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </S.Content>

      {/* Diálogo para seleção de contatos com paginação */}
      <Dialog
        open={contatosDialogOpen}
        onClose={() => setContatosDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Adicionar Contatos à Lista</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Buscar contato"
            type="text"
            fullWidth
            variant="outlined"
            value={searchContato}
            onChange={(e) => setSearchContato(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box sx={{ maxHeight: '400px', overflow: 'auto', mb: 2 }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {getCurrentPageContatos().map((contato) => (
                <ListItem
                  key={contato.id}
                  onClick={() => handleToggleContato(contato.id)}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    }
                  }}
                >
                  <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                    {contato.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <FormControlLabel
                    control={
                      <Checkbox
                        edge="start"
                        checked={contatosSelecionados.includes(contato.id)}
                        tabIndex={-1}
                        disableRipple
                      />
                    }
                    label={
                      <ListItemText
                        primary={contato.name}
                        secondary={contato.number}
                      />
                    }
                    sx={{ width: '100%' }}
                  />
                </ListItem>
              ))}

              {filteredContatos.length === 0 && (
                <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
                  Nenhum contato disponível.
                </Typography>
              )}
            </List>
          </Box>
          
          {/* Paginação para contatos */}
          {filteredContatos.length > 0 && (
            <PaginationComponent
              currentPage={currentContatosPage}
              totalItems={filteredContatos.length}
              itemsPerPage={itemsPerDialogPage}
              onPageChange={handleContatosPageChange}
              itemName="contatos"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContatosDialogOpen(false)}>Cancelar</Button>
          <Button
            onClick={handleAddContatosSelecionados}
            variant="contained"
            disabled={contatosSelecionados.length === 0}
          >
            Adicionar ({contatosSelecionados.length})
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para seleção de empresas com paginação */}
      <Dialog
        open={empresasDialogOpen}
        onClose={() => setEmpresasDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Adicionar Empresas à Lista</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Buscar empresa"
            type="text"
            fullWidth
            variant="outlined"
            value={searchEmpresa}
            onChange={(e) => setSearchEmpresa(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box sx={{ maxHeight: '400px', overflow: 'auto', mb: 2 }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {getCurrentPageEmpresas().map((empresa) => (
                <ListItem
                  key={empresa.id}
                  onClick={() => handleToggleEmpresa(empresa.id)}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    }
                  }}
                >
                  <Avatar sx={{ bgcolor: 'secondary.main', mr: 2 }}>
                    {empresa.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <FormControlLabel
                    control={
                      <Checkbox
                        edge="start"
                        checked={empresasSelecionadas.includes(empresa.id)}
                        tabIndex={-1}
                        disableRipple
                      />
                    }
                    label={
                      <ListItemText
                        primary={empresa.name}
                        secondary={`${empresa.contacts?.length || 0} contatos associados`}
                      />
                    }
                    sx={{ width: '100%' }}
                  />
                </ListItem>
              ))}

              {filteredEmpresas.length === 0 && (
                <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
                  Nenhuma empresa disponível.
                </Typography>
              )}
            </List>
          </Box>
          
          {/* Paginação para empresas */}
          {filteredEmpresas.length > 0 && (
            <PaginationComponent
              currentPage={currentEmpresasPage}
              totalItems={filteredEmpresas.length}
              itemsPerPage={itemsPerDialogPage}
              onPageChange={handleEmpresasPageChange}
              itemName="empresas"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEmpresasDialogOpen(false)}>Cancelar</Button>
          <Button
            onClick={handleAddEmpresasSelecionadas}
            variant="contained"
            disabled={empresasSelecionadas.length === 0}
          >
            Adicionar ({empresasSelecionadas.length})
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para envio de mensagem */}
      <Dialog
        open={messageDialogOpen}
        onClose={() => setMessageDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Enviar Mensagem para Lista de Transmissão</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3, mt: 1 }}>
            <Typography variant="body2" gutterBottom>
              Esta mensagem será enviada para <b>{getTotalDestinatarios()}</b>{' '}
              destinatários:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • {listaContatos.length} contatos diretos
            </Typography>
            <Typography variant="body2" color="text.secondary">
              •{' '}
              {listaEmpresas.reduce(
                (acc, empresa) => acc + (empresa.contacts?.length || 0),
                0,
              )}{' '}
              contatos via {listaEmpresas.length} empresas
            </Typography>
          </Box>

          <TextField
            label="Mensagem"
            multiline
            rows={6}
            fullWidth
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            placeholder="Digite sua mensagem para enviar a todos os destinatários..."
            variant="outlined"
            required
          />

          <Box sx={{ mt: 3 }}>
            <Typography variant="caption" color="text.secondary">
              Variáveis disponíveis:
              <Chip label="{{nome}}" size="small" sx={{ ml: 1 }} />
              <Chip label="{{empresa}}" size="small" sx={{ ml: 1 }} />
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMessageDialogOpen(false)}>Cancelar</Button>
          <Button
            onClick={handleSendMessage}
            variant="contained"
            color="primary"
            disabled={!messageText.trim()}
            startIcon={<Send />}
          >
            Enviar para {getTotalDestinatarios()} destinatários
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
// src/pages/Main/containers/gerenciamento/pages/DepartmentManagement/components/DepartmentForm/index.tsx
import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Divider,
  Checkbox,
  Paper,
  Grid,
  Tabs,
  Tab,
  InputAdornment,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from '@mui/material'
import {
  Delete,
  Save,
  Cancel,
  Search,
  Clear,
  Person,
  Check,
  AdminPanelSettings,
} from '@mui/icons-material'
import {
  Department,
  User,
  Board,
  DepartmentUser,
  DepartmentBoard,
  DepartmentPayload,
} from '../../../../types'

interface DepartmentFormProps {
  department: Department | null
  users: User[]
  boards: Board[]
  isEdit: boolean
  onSave: (departmentData: DepartmentPayload) => Promise<void>
  onDelete: (id: string) => Promise<void>
  onCancel: () => void
  loading: boolean
  error: string | null
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`department-tabpanel-${index}`}
      aria-labelledby={`department-tab-${index}`}
      {...other}
      style={{ maxHeight: '400px', overflowY: 'auto' }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const getInitials = (name: string): string => {
  const names = name.split(' ')
  if (names.length === 1) return names[0].charAt(0).toUpperCase()
  return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase()
}

const getAvatarColor = (name: string): string => {
  const colors = [
    '#1976d2', // blue
    '#388e3c', // green
    '#d32f2f', // red
    '#f57c00', // orange
    '#7b1fa2', // purple
    '#0288d1', // light blue
    '#455a64', // blue grey
    '#512da8', // deep purple
  ]

  // Simple hash function to select a color based on the name
  const hash = name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
  return colors[hash % colors.length]
}

export const DepartmentForm: React.FC<DepartmentFormProps> = ({
  department,
  users,
  boards,
  isEdit,
  onSave,
  onDelete,
  onCancel,
  loading,
  error,
}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [active, setActive] = useState(true)
  const [selectedUsers, setSelectedUsers] = useState<DepartmentUser[]>([])
  const [selectedBoards, setSelectedBoards] = useState<DepartmentBoard[]>([])
  const [nameError, setNameError] = useState('')
  const [tabValue, setTabValue] = useState(0)

  // Estados para pesquisa
  const [userSearchTerm, setUserSearchTerm] = useState('')
  const [boardSearchTerm, setBoardSearchTerm] = useState('')

  // Estado para o dialog de seleção de usuários
  const [showUserSelector, setShowUserSelector] = useState(false)

  useEffect(() => {
    if (department) {
      setName(department.name)
      setDescription(department.description || '')
      setActive(department.active)

      // Map department users to the form structure
      const mappedUsers = department.users.map((user) => ({
        userId: user.id,
        isAdmin: user.role === 'ADMIN', // Usando o role === 'ADMIN' para determinar admin
      }))
      setSelectedUsers(mappedUsers)

      // Map department boards to the form structure
      const mappedBoards = department.boards.map((board) => ({
        boardId: board.id,
      }))
      setSelectedBoards(mappedBoards)
    } else {
      // Reset form for new department
      setName('')
      setDescription('')
      setActive(true)
      setSelectedUsers([])
      setSelectedBoards([])
    }
  }, [department])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleUserSelection = (userId: string) => {
    const isUserSelected = selectedUsers.some((user) => user.userId === userId)

    if (isUserSelected) {
      // Remove user
      setSelectedUsers(selectedUsers.filter((user) => user.userId !== userId))
    } else {
      // Add user with default isAdmin = false
      setSelectedUsers([...selectedUsers, { userId, isAdmin: false }])
    }
  }

  const handleUserAdminToggle = (userId: string) => {
    setSelectedUsers(
      selectedUsers.map((user) =>
        user.userId === userId ? { ...user, isAdmin: !user.isAdmin } : user,
      ),
    )
  }

  const handleBoardSelection = (boardId: string) => {
    const isBoardSelected = selectedBoards.some(
      (board) => board.boardId === boardId,
    )

    if (isBoardSelected) {
      // Remove board
      setSelectedBoards(
        selectedBoards.filter((board) => board.boardId !== boardId),
      )
    } else {
      // Add board
      setSelectedBoards([...selectedBoards, { boardId }])
    }
  }

  const validateForm = (): boolean => {
    let isValid = true

    if (!name.trim()) {
      setNameError('O nome do departamento é obrigatório')
      isValid = false
    } else {
      setNameError('')
    }

    return isValid
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    // Formatar os dados de acordo com o schema esperado pelo backend
    const departmentData: DepartmentPayload = {
      ...(isEdit ? { id: department?.id } : {}),
      name,
      description,
      active,
      // Transformar o formato dos boards para um array de IDs (strings)
      boards: selectedBoards.map((board) => board.boardId),
      // Transformar o formato dos usuários para corresponder ao schema esperado
      users: selectedUsers.map((user) => ({
        userId: user.userId,
        role: user.isAdmin ? 'ADMIN' : 'USER', // Convertendo isAdmin para role
      })),
    }

    await onSave(departmentData)
  }

  const handleDelete = () => {
    if (department && department.id) {
      onDelete(department.id)
    }
  }

  // Find user details by userId
  const getUserById = (userId: string): User | undefined => {
    return users.find((user) => user.id === userId)
  }

  // Filtra os usuários baseado na pesquisa
  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(userSearchTerm.toLowerCase()),
  )

  // Filtra os boards baseado na pesquisa
  const filteredBoards = boards.filter(
    (board) =>
      board.title.toLowerCase().includes(boardSearchTerm.toLowerCase()) ||
      board.type.toLowerCase().includes(boardSearchTerm.toLowerCase()),
  )

  // Usuários selecionados com detalhes completos
  const selectedUsersWithDetails = selectedUsers.map((selectedUser) => {
    const user = getUserById(selectedUser.userId)
    return {
      ...selectedUser,
      name: user?.name || '',
      email: user?.email || '',
      // Mantém o isAdmin conforme definido no selectedUsers
      avatarColor: user ? getAvatarColor(user.name) : '#ccc',
    }
  })

  // Boards selecionados com detalhes completos
  const selectedBoardsWithDetails = selectedBoards.map((selectedBoard) => {
    const board = boards.find((b) => b.id === selectedBoard.boardId)
    return {
      ...selectedBoard,
      title: board?.title || '',
      type: board?.type || '',
    }
  })

  const handleRemoveUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter((u) => u.userId !== userId))
  }

  const handleRemoveBoard = (boardId: string) => {
    setSelectedBoards(selectedBoards.filter((b) => b.boardId !== boardId))
  }

  const openUserSelector = () => {
    setShowUserSelector(true)
  }

  const closeUserSelector = () => {
    setShowUserSelector(false)
    setUserSearchTerm('')
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mt: 0,
        p: '20px',
        maxHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {isEdit ? 'Editar Departamento' : 'Novo Departamento'}
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <TextField
              label="Nome do Departamento"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
              error={!!nameError}
              helperText={nameError}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  disabled={loading}
                />
              }
              label="Ativo"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descrição"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              multiline
              rows={3}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Paper>

      <Box
        sx={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="department configuration tabs"
          >
            <Tab label="Usuários" />
            <Tab label="Boards" />
          </Tabs>
        </Box>

        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <TabPanel value={tabValue} index={0}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,
              }}
            >
              <Typography variant="subtitle1">
                Usuários do departamento ({selectedUsers.length})
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={openUserSelector}
                startIcon={<Person />}
                disabled={loading}
              >
                Selecionar Usuários
              </Button>
            </Box>

            {selectedUsers.length > 0 ? (
              <Box>
                <Grid container spacing={2}>
                  {selectedUsersWithDetails.map((user) => (
                    <Grid item xs={12} sm={6} md={4} key={user.userId}>
                      <Paper sx={{ p: 2, border: '1px solid #e0e0e0' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start', // Alterado para flex-start para alinhar no topo
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-start', // Alterado para flex-start para alinhar no topo
                              gap: 1,
                              minWidth: 0, // Importante para permitir que o conteúdo seja truncado
                              flex: 1, // Para que ocupe o espaço disponível
                            }}
                          >
                            <Avatar
                              sx={{
                                bgcolor: user.avatarColor,
                                width: 36,
                                height: 36,
                                fontSize: '0.9rem',
                                flexShrink: 0, // Impede que o avatar seja encolhido
                              }}
                            >
                              {getInitials(user.name)}
                            </Avatar>
                            <Box sx={{ minWidth: 0, overflow: 'hidden' }}>
                              {' '}
                              {/* Garante que esse container possa ser truncado */}
                              <Typography
                                variant="body1"
                                fontWeight={500}
                                sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {user.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                fontSize="0.8rem"
                                sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {user.email}
                              </Typography>
                            </Box>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveUser(user.userId)}
                            disabled={loading}
                            sx={{ flexShrink: 0 }} // Impede que o botão seja encolhido
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Box>
                        <Box mt={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.isAdmin}
                                onChange={() =>
                                  handleUserAdminToggle(user.userId)
                                }
                                size="small"
                                color="secondary"
                                disabled={loading}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 0.5,
                                }}
                              >
                                <AdminPanelSettings fontSize="small" />
                                Administrador
                              </Typography>
                            }
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center', py: 4, color: '#757575' }}>
                <Person sx={{ fontSize: 48, opacity: 0.5, mb: 1 }} />
                <Typography>
                  Nenhum usuário selecionado. Clique em "Selecionar Usuários"
                  para adicionar.
                </Typography>
              </Box>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                placeholder="Buscar boards..."
                value={boardSearchTerm}
                onChange={(e) => setBoardSearchTerm(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: boardSearchTerm && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setBoardSearchTerm('')}
                        edge="end"
                        size="small"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled={loading}
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Boards selecionados ({selectedBoards.length})
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 1 }}>
                {selectedBoardsWithDetails.map((board) => (
                  <Chip
                    key={board.boardId}
                    label={board.title}
                    onDelete={() => handleRemoveBoard(board.boardId)}
                    disabled={loading}
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>

            <Typography variant="subtitle2" gutterBottom>
              Boards disponíveis ({filteredBoards.length})
            </Typography>

            <Grid container spacing={2}>
              {filteredBoards.map((board) => {
                const isSelected = selectedBoards.some(
                  (b) => b.boardId === board.id,
                )

                return (
                  <Grid item xs={12} sm={6} md={4} key={board.id}>
                    <Paper
                      sx={{
                        p: 2,
                        border: isSelected
                          ? '1px solid #3f51b5'
                          : '1px solid #e0e0e0',
                        backgroundColor: isSelected ? '#f5f8ff' : 'white',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          borderColor: '#3f51b5',
                          backgroundColor: isSelected ? '#f5f8ff' : '#fafafa',
                        },
                      }}
                      onClick={() => handleBoardSelection(board.id)}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="body1" fontWeight={500}>
                          {board.title}
                        </Typography>
                        {isSelected && <Check color="primary" />}
                      </Box>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 0.5 }}
                      >
                        Tipo: {board.type}
                      </Typography>
                    </Paper>
                  </Grid>
                )
              })}
            </Grid>

            {filteredBoards.length === 0 && (
              <Box sx={{ textAlign: 'center', py: 4, color: '#757575' }}>
                <Typography>
                  Nenhum board encontrado com o termo "{boardSearchTerm}".
                </Typography>
              </Box>
            )}
          </TabPanel>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '12px',
          mt: 3,
          pt: 3,
          borderTop: '1px solid #eee',
        }}
      >
        {isEdit && (
          <Button
            variant="outlined"
            color="error"
            startIcon={<Delete />}
            onClick={handleDelete}
            disabled={loading}
          >
            Excluir
          </Button>
        )}
        <Button
          variant="outlined"
          startIcon={<Cancel />}
          onClick={onCancel}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Save />}
          disabled={loading}
        >
          {isEdit ? 'Atualizar' : 'Salvar'}
        </Button>
      </Box>

      {/* Dialog para seleção de usuários */}
      <Dialog
        open={showUserSelector}
        onClose={closeUserSelector}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { maxHeight: '80vh' },
        }}
      >
        <DialogTitle>Selecionar Usuários</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            fullWidth
            placeholder="Buscar usuários por nome ou email..."
            value={userSearchTerm}
            onChange={(e) => setUserSearchTerm(e.target.value)}
            variant="outlined"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: userSearchTerm && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setUserSearchTerm('')}
                    edge="end"
                    size="small"
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <List sx={{ mt: 2 }}>
            {filteredUsers.map((user) => {
              const isSelected = selectedUsers.some((u) => u.userId === user.id)
              const avatarColor = getAvatarColor(user.name)

              return (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => handleUserSelection(user.id)}
                  selected={isSelected}
                  sx={{
                    borderRadius: 1,
                    mb: 0.5,
                    backgroundColor: isSelected ? '#f5f8ff' : 'transparent',
                    '&:hover': {
                      backgroundColor: isSelected ? '#e3f2fd' : '#f5f5f5',
                    },
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={isSelected}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <Avatar
                    sx={{
                      bgcolor: avatarColor,
                      mr: 2,
                    }}
                  >
                    {getInitials(user.name)}
                  </Avatar>
                  <ListItemText primary={user.name} secondary={user.email} />
                  {isSelected && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            selectedUsers.find((u) => u.userId === user.id)
                              ?.isAdmin || false
                          }
                          onChange={(e) => {
                            e.stopPropagation()
                            handleUserAdminToggle(user.id)
                          }}
                          onClick={(e) => e.stopPropagation()}
                          color="secondary"
                        />
                      }
                      label="Admin"
                      onClick={(e) => e.stopPropagation()}
                    />
                  )}
                </ListItem>
              )
            })}
            {filteredUsers.length === 0 && (
              <ListItem sx={{ justifyContent: 'center', py: 4 }}>
                <Typography color="text.secondary">
                  Nenhum usuário encontrado com o termo "{userSearchTerm}".
                </Typography>
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Typography
            variant="body2"
            sx={{ mr: 'auto', ml: 2, color: 'text.secondary' }}
          >
            {selectedUsers.length} usuários selecionados
          </Typography>
          <Button onClick={closeUserSelector} color="primary">
            Concluído
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

import styled from "styled-components";

export const Container = styled.div`
  padding: 30px;
  max-width: 1200px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;

  h1 {
    font-size: 24px;
    color: #32394a;
  }

  p {
    color: #637381;
    font-size: 16px;
  }
`;

export const WrapperSerach = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;

  .filters {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 768px) {
      .inputSearchMobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
      }
    }
  }

  .newProfile,
  .backToSearch {
    height: 40px;
    background-color: #f9f9f9;
    color: #3c454d;
    border: 1px solid #e4e7e9;
    font-weight: 600;

    &:hover {
      background-color: #3c454d;
      color: #f9f9f9;
    }
  }
`;

export const Content = styled.div`
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 40px;
`;

export const SectionTitle = styled.h2`
  font-size: 18px;
  color: #32394a;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const FormSection = styled.div`
  margin-bottom: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid #f0f4f8;

  &:last-child {
    border-bottom: none;
  }
`;

export const ModulesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 16px;
`;

export const FunnelIntegrationSection = styled.div`
  background-color: #f9f9fb;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
`;

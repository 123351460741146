import React from 'react'
import { 
  Card, 
  CardContent, 
  Typography, 
  Chip, 
  Divider, 
  Box 
} from '@mui/material'

const EmpresaCard = ({ empresa, handleCardClick, tagFilter, handleTagSelect }) => {
  // Função para formatar endereço
  const formatarEndereco = (endereco) => {
    if (!endereco) return 'Sem endereço';
    
    const partes = [];
    if (endereco.street) {
      partes.push(`${endereco.street}${endereco.number ? `, ${endereco.number}` : ''}`);
    }
    if (endereco.neighborhood) {
      partes.push(endereco.neighborhood);
    }
    if (endereco.city || endereco.state) {
      partes.push(`${endereco.city || ''}${endereco.city && endereco.state ? ' - ' : ''}${endereco.state || ''}`);
    }
    
    return partes.join(', ');
  };

  return (
    <Card
      onClick={() => handleCardClick(empresa)}
      sx={{
        cursor: 'pointer',
        width: '100%',
        borderRadius: '12px',
        transition: 'all 0.2s ease',
        boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
        border: '1px solid rgba(0,0,0,0.05)',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 8px 20px rgba(0,0,0,0.08)',
          borderColor: 'rgba(0,0,0,0.09)',
        },
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          background: 'linear-gradient(90deg, #3f51b5, #5c6bc0)',
          opacity: 0.9,
        },
      }}
    >
      <CardContent
        sx={{
          p: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{
            p: 2.5,
            pb: 1.5,
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontSize: '1.125rem',
              fontWeight: 600,
              mb: 1,
              color: '#2d3748',
            }}
          >
            {empresa.name}
          </Typography>

          {empresa.address && (
            <Typography
              sx={{
                mb: 1.5,
                color: 'text.secondary',
                fontSize: '0.875rem',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <Box
                component="span"
                sx={{
                  display: 'inline-flex',
                  mr: 0.75,
                  mt: 0.125,
                  color: '#718096',
                  '& svg': { fontSize: 16 },
                }}
              >
                {/* Simple location icon */}
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12 22C16 17 20 13.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 13.4183 8 17 12 22Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
              {/* Aqui estava o erro: renderizando o objeto address diretamente */}
              {/* Substituído por uma função que formata o endereço como string */}
              {formatarEndereco(empresa.address)}
            </Typography>
          )}

          {empresa.description && (
            <Typography
              variant="body2"
              sx={{
                mb: 2.5,
                color: '#4a5568',
                fontSize: '0.875rem',
                lineHeight: 1.5,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: '42px', // Approx height for 2 lines
              }}
            >
              {empresa.description}
            </Typography>
          )}
          
          {/* Tags da empresa (se houver) */}
          {empresa.tags && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1.5 }}>
              {empresa.tags.split(',').map(tag => tag.trim()).filter(tag => tag).map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  size="small"
                  sx={{
                    height: '22px',
                    fontSize: '0.7rem',
                    backgroundColor: tagFilter === tag ? '#3f51b5' : 'rgba(63, 81, 181, 0.08)',
                    color: tagFilter === tag ? 'white' : '#3f51b5',
                    '&:hover': {
                      backgroundColor: tagFilter === tag ? '#303f9f' : 'rgba(63, 81, 181, 0.16)',
                      cursor: 'pointer'
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTagSelect(tag);
                  }}
                />
              ))}
            </Box>
          )}
        </Box>

        <Divider sx={{ mx: 0, opacity: 0.6 }} />

        <Box
          sx={{
            px: 2.5,
            py: 1.75,
            bgcolor: 'rgba(0,0,0,0.01)',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mb: 1,
              color: '#718096',
              fontWeight: 500,
            }}
          >
            {empresa.contacts?.length || 0} contatos vinculados
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '6px',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {empresa.contacts?.slice(0, 3).map((contato) => (
              <Chip
                key={contato.id}
                label={contato.name}
                size="small"
                onClick={(e) => e.stopPropagation()}
                sx={{
                  fontSize: '0.75rem',
                  height: '24px',
                  bgcolor: 'rgba(63, 81, 181, 0.08)',
                  color: '#3f51b5',
                  fontWeight: 500,
                  '&:hover': {
                    bgcolor: 'rgba(63, 81, 181, 0.12)',
                  },
                }}
              />
            ))}
            {empresa.contacts?.length > 3 && (
              <Chip
                label={`+${empresa.contacts.length - 3}`}
                size="small"
                variant="outlined"
                sx={{
                  fontSize: '0.75rem',
                  height: '24px',
                  borderColor: 'rgba(63, 81, 181, 0.3)',
                  color: '#3f51b5',
                  fontWeight: 500,
                  '&:hover': {
                    bgcolor: 'rgba(63, 81, 181, 0.04)',
                    borderColor: 'rgba(63, 81, 181, 0.5)',
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default EmpresaCard;
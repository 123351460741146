import { llmEndpoint } from "infra";
import { ERP } from "../types";

const API_BASE_URL = llmEndpoint.url;

/**
 * Serviço de API para gerenciamento de integrações ERP
 */
export const ErpAPI = {
  /**
   * Obter todas as integrações ERP para um RPA específico
   * @param rpaId ID da RPA
   * @returns Lista de integrações ERP
   */
  getAllErps: async (rpaId: string): Promise<ERP[]> => {
    try {
      const response = await fetch(`${API_BASE_URL}/erp-integration/${rpaId}`);
      console.log("Fetched ERPs:", response);
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      return data || [];
    } catch (error) {
      console.error("Error fetching ERPs:", error);
      throw error;
    }
  },

  /**
   * Obter um ERP específico por ID
   * @param id ID do ERP
   * @param rpaId ID da RPA
   * @returns Detalhes do ERP
   */
  getErpById: async (id: string, rpaId: string): Promise<ERP> => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/erp-integration/${rpaId}/${id}`,
      );

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching ERP with ID ${id}:`, error);
      throw error;
    }
  },

  /**
   * Criar um novo ERP
   * @param erpData Dados do ERP a ser criado
   * @returns ERP criado
   */
  createErp: async (erpData: ERP): Promise<ERP> => {
    try {
      const response = await fetch(`${API_BASE_URL}/erp-integration`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(erpData),
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error creating ERP:", error);
      throw error;
    }
  },

  /**
   * Atualizar um ERP existente
   * @param erpData Dados atualizados do ERP
   * @returns ERP atualizado
   */
  updateErp: async (erpData: ERP): Promise<ERP> => {
    try {
      const response = await fetch(`${API_BASE_URL}/erp-integration`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(erpData),
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error updating ERP with ID ${erpData.id}:`, error);
      throw error;
    }
  },

  /**
   * Excluir um ERP
   * @param erpId ID do ERP a ser excluído
   */
  deleteErp: async (erpId: string): Promise<void> => {
    try {
      const response = await fetch(`${API_BASE_URL}/erp-integration/${erpId}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error deleting ERP with ID ${erpId}:`, error);
      throw error;
    }
  },

  /**
   * Ativar/desativar um ERP
   * @param erpId ID do ERP
   * @param active Status de ativação
   * @returns ERP atualizado
   */
  toggleErpActive: async (erpId: string, active: boolean): Promise<ERP> => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/erp-integration/${erpId}/toggle-active`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ active }),
        },
      );

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(
        `Error toggling active status for ERP with ID ${erpId}:`,
        error,
      );
      throw error;
    }
  },

  /**
   * Atualizar credenciais de um ERP
   * @param erpId ID do ERP
   * @param appKey Nova chave de API
   * @param appSecret Novo segredo de API
   * @returns ERP atualizado
   */
  updateCredentials: async (
    erpId: string,
    appKey: string,
    appSecret: string,
  ): Promise<ERP> => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/erp-integration/${erpId}/credentials`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ appKey, appSecret }),
        },
      );

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(
        `Error updating credentials for ERP with ID ${erpId}:`,
        error,
      );
      throw error;
    }
  },

  /**
   * Obter integrações ERP por vendor (ex: Omie)
   * @param vendor Nome do vendor
   * @param rpaId ID da RPA
   * @returns Lista de ERPs do vendor específico
   */
  getErpsByVendor: async (vendor: string, rpaId: string): Promise<ERP[]> => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/erp-integration/${rpaId}/vendor/${vendor}`,
      );

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      return data || [];
    } catch (error) {
      console.error(`Error fetching ERPs for vendor ${vendor}:`, error);
      throw error;
    }
  },
};

import React from 'react'
import { IconButton, Typography, Pagination } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

interface PaginationComponentProps {
  currentPage: number
  totalItems: number
  itemsPerPage: number
  onPageChange: (page: number) => void
  showItemCount?: boolean
  itemName?: string
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  showItemCount = true,
  itemName = 'itens'
}) => {
  // Calcula o número total de páginas
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  // Manipula a mudança de página
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    onPageChange(page)
  }

  // Navega para a página anterior
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  // Navega para a próxima página
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      marginTop: '20px',
      marginBottom: '20px'
    }}>
      {showItemCount && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ color: '#666' }}>
            Mostrando {Math.min(itemsPerPage * currentPage, totalItems)} de {totalItems} {itemName}
          </Typography>
        </div>
      )}
      
      {!showItemCount && <div />}
      
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton 
          onClick={handlePrevPage} 
          disabled={currentPage === 1}
          size="small"
          sx={{ 
            mr: 1,
            backgroundColor: currentPage === 1 ? '#f5f5f5' : '#e0e0e0',
            color: currentPage === 1 ? '#bdbdbd' : '#616161',
            '&:hover': { backgroundColor: '#d5d5d5' } 
          }}
        >
          <ChevronLeft />
        </IconButton>
        
        <Pagination 
          count={totalPages} 
          page={currentPage} 
          onChange={handlePageChange}
          size="small"
          hideNextButton
          hidePrevButton
          sx={{ 
            '& .MuiPaginationItem-root': { 
              minWidth: '30px', 
              height: '30px',
              fontSize: '0.875rem'
            },
            '& .Mui-selected': {
              backgroundColor: '#3f51b5',
              color: 'white',
              '&:hover': {
                backgroundColor: '#303f9f',
              },
            }
          }}
        />
        
        <IconButton 
          onClick={handleNextPage} 
          disabled={currentPage === totalPages}
          size="small"
          sx={{ 
            ml: 1,
            backgroundColor: currentPage === totalPages ? '#f5f5f5' : '#e0e0e0',
            color: currentPage === totalPages ? '#bdbdbd' : '#616161',
            '&:hover': { backgroundColor: '#d5d5d5' } 
          }}
        >
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  )
}
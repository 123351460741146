import React from 'react'
import { TextField } from '@mui/material'

export const ChatNameInput = ({ modalChatName, setModalChatName }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Nome do Chat
      </label>
      <TextField
        fullWidth
        value={modalChatName}
        onChange={(e) => setModalChatName(e.target.value)}
        variant="outlined"
        size="small"
        placeholder="Digite o nome do chat"
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
      />
    </div>
  )
}
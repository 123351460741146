import React, { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  CircularProgress,
  Button,
  Box,
  Paper,
  Typography,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MapIcon from '@mui/icons-material/Map'

// Lista de estados brasileiros
const estadosBrasileiros = [
  { sigla: 'AC', nome: 'Acre' },
  { sigla: 'AL', nome: 'Alagoas' },
  { sigla: 'AP', nome: 'Amapá' },
  { sigla: 'AM', nome: 'Amazonas' },
  { sigla: 'BA', nome: 'Bahia' },
  { sigla: 'CE', nome: 'Ceará' },
  { sigla: 'DF', nome: 'Distrito Federal' },
  { sigla: 'ES', nome: 'Espírito Santo' },
  { sigla: 'GO', nome: 'Goiás' },
  { sigla: 'MA', nome: 'Maranhão' },
  { sigla: 'MT', nome: 'Mato Grosso' },
  { sigla: 'MS', nome: 'Mato Grosso do Sul' },
  { sigla: 'MG', nome: 'Minas Gerais' },
  { sigla: 'PA', nome: 'Pará' },
  { sigla: 'PB', nome: 'Paraíba' },
  { sigla: 'PR', nome: 'Paraná' },
  { sigla: 'PE', nome: 'Pernambuco' },
  { sigla: 'PI', nome: 'Piauí' },
  { sigla: 'RJ', nome: 'Rio de Janeiro' },
  { sigla: 'RN', nome: 'Rio Grande do Norte' },
  { sigla: 'RS', nome: 'Rio Grande do Sul' },
  { sigla: 'RO', nome: 'Rondônia' },
  { sigla: 'RR', nome: 'Roraima' },
  { sigla: 'SC', nome: 'Santa Catarina' },
  { sigla: 'SP', nome: 'São Paulo' },
  { sigla: 'SE', nome: 'Sergipe' },
  { sigla: 'TO', nome: 'Tocantins' },
]

const EnderecoForm = ({ address, setAddress }) => {
  const [loading, setLoading] = useState(false)
  const [cidadesOptions, setCidadesOptions] = useState([])
  const [showMap, setShowMap] = useState(false)
  const [coordinates, setCoordinates] = useState(null)
  const [mapLoading, setMapLoading] = useState(false)
  const [lastSearchedAddress, setLastSearchedAddress] = useState(null)

  console.log('Endereço:', address)

  // Função para buscar endereço pelo CEP
  const buscarCep = async (cep) => {
    if (!cep || cep.length !== 8) return

    setLoading(true)
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
      const data = await response.json()

      if (!data.erro) {
        // Encontra o estado pelo nome ou sigla
        const estadoEncontrado = estadosBrasileiros.find(
          (state) => state.sigla === data.uf,
        )

        setAddress({
          ...address,
          zipCode: data.cep.replace('-', ''),
          state: estadoEncontrado?.sigla || '',
          city: data.localidade,
          neighborhood: data.bairro,
          street: data.logradouro,
          complement: data.complemento,
        })

        // Busca cidades do estado
        if (estadoEncontrado) {
          buscarCidades(estadoEncontrado.sigla)
        }
      }
    } catch (error) {
      console.error('Erro ao buscar CEP:', error)
    } finally {
      setLoading(false)
    }
  }

  // Função para buscar cidades por estado
  const buscarCidades = async (uf) => {
    if (!uf) return

    try {
      // Em uma aplicação real, seria uma chamada a uma API de cidades
      // Aqui estamos simulando apenas para demonstração
      // Normalmente usaria um serviço como IBGE API: https://servicodados.ibge.gov.br/api/docs/localidades

      // Simula um delay para mostrar o loading
      await new Promise((r) => setTimeout(r, 500))

      // Cidades de exemplo para cada estado
      const cidadesPorEstado = {
        SP: ['São Paulo', 'Campinas', 'Santos', 'Ribeirão Preto'],
        RJ: ['Rio de Janeiro', 'Niterói', 'Petrópolis'],
        MG: ['Belo Horizonte', 'Uberlândia', 'Juiz de Fora'],
        // Adicionar mais estados conforme necessário
      }

      setCidadesOptions(cidadesPorEstado[uf] || [])
    } catch (error) {
      console.error('Erro ao buscar cidades:', error)
      setCidadesOptions([])
    }
  }

  // Função para buscar coordenadas geográficas a partir do endereço
  const buscarCoordenadas = async () => {
    if (!address.street || !address.city || !address.state) {
      alert('Preencha pelo menos rua, cidade e estado para visualizar o mapa')
      return
    }

    setMapLoading(true)
    try {
      // Formata o endereço para a busca
      const enderecoCompleto = encodeURIComponent(`${address.street}, ${address.number || ''}, ${address.city}, ${address.state}, Brazil`)
      
      // Utilizando a API Nominatim do OpenStreetMap para geocoding
      // Esta é uma API gratuita e de código aberto, mas tem limitações de uso
      // Para uso em produção, considere APIs como Google Maps ou alternativas comerciais
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${enderecoCompleto}&limit=1`)
      const data = await response.json()
      
      if (data && data.length > 0) {
        const result = data[0]
        const coordenadas = {
          lat: parseFloat(result.lat),
          lng: parseFloat(result.lon)
        }
        
        setCoordinates(coordenadas)
        setShowMap(true)
        console.log('Coordenadas encontradas:', coordenadas)
      } else {
        throw new Error('Endereço não encontrado')
      }
    } catch (error) {
      console.error('Erro ao buscar coordenadas:', error)
      alert('Não foi possível localizar o endereço no mapa. Verifique se o endereço está correto.')
      
      // Fallback para coordenadas padrão (opcional)
      // Você pode remover isso se preferir não mostrar o mapa quando o endereço não for encontrado
      if (address.state === 'SP') {
        setCoordinates({ lat: -23.550520, lng: -46.633308 }) // São Paulo
      } else if (address.state === 'RJ') {
        setCoordinates({ lat: -22.906847, lng: -43.172897 }) // Rio de Janeiro
      } else if (address.state === 'MG') {
        setCoordinates({ lat: -19.916681, lng: -43.934493 }) // Belo Horizonte
      } else {
        setCoordinates({ lat: -15.793889, lng: -47.882778 }) // Brasília (centro do Brasil)
      }
      setShowMap(true)
    } finally {
      setMapLoading(false)
    }
  }

  // Atualiza as cidades quando o estado é alterado
  useEffect(() => {
    if (address.state) {
      buscarCidades(address.state)
    }
  }, [address.state])

  // Manipula a mudança de CEP
  const handleCepChange = (e) => {
    const cep = e.target.value.replace(/\D/g, '')
    setAddress({ ...address, zipCode: cep })

    if (cep.length === 8) {
      buscarCep(cep)
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="CEP"
            fullWidth
            value={address.zipCode || ''}
            onChange={handleCepChange}
            placeholder="00000000"
            inputProps={{ maxLength: 8 }}
            helperText="Digite o CEP sem traço"
            InputProps={{
              endAdornment: loading && <CircularProgress size={20} />,
            }}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <InputLabel id="estado-label">Estado</InputLabel>
            <Select
              labelId="estado-label"
              value={address.state || ''}
              label="Estado"
              onChange={(e) => setAddress({ ...address, state: e.target.value })}
            >
              {estadosBrasileiros.map((estado) => (
                <MenuItem key={estado.sigla} value={estado.sigla}>
                  {estado.nome} ({estado.sigla})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={8}>
          <Autocomplete
            options={cidadesOptions}
            freeSolo
            value={address.city || ''}
            onInputChange={(_, newValue) =>
              setAddress({ ...address, city: newValue })
            }
            renderInput={(params) => (
              <TextField {...params} label="Cidade" fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label="Bairro"
            fullWidth
            value={address.neighborhood || ''}
            onChange={(e) =>
              setAddress({ ...address, neighborhood: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} md={10}>
          <TextField
            label="Rua"
            fullWidth
            value={address.street || ''}
            onChange={(e) => setAddress({ ...address, street: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label="Número"
            fullWidth
            value={address.number || ''}
            onChange={(e) => setAddress({ ...address, number: e.target.value })}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Complemento"
            fullWidth
            value={address.complement || ''}
            onChange={(e) =>
              setAddress({ ...address, complement: e.target.value })
            }
            helperText="Apartamento, bloco, sala, etc."
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" gap={2}>
          <Button
            variant="contained"
            color={showMap ? "error" : "primary"}
            startIcon={showMap ? null : <MapIcon />}
            onClick={() => {
              if (!showMap) {
                buscarCoordenadas();
              } else {
                setShowMap(false);
              }
            }}
            title={showMap ? "Fechar mapa" : "Mostrar localização no mapa"}
            disabled={mapLoading}
          >
            {mapLoading ? 'Carregando mapa...' : showMap ? 'Desativar Mapa' : 'Visualizar no Mapa'}
          </Button>
        </Grid>
      </Grid>

      {showMap && coordinates && (
        <Box mt={3}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6" gutterBottom display="flex" alignItems="center">
                <LocationOnIcon color="primary" sx={{ mr: 1 }} />
                Localização no Mapa
              </Typography>
              
              <Box height={400} width="100%" bgcolor="#f0f0f0" position="relative">
                {/* Em uma aplicação real, aqui você incluiria o componente de mapa */}
                {/* Exemplo com iframe para OpenStreetMap */}
                <iframe
                  title="Mapa de Localização"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="no"
                  style={{ margin: 0 }}
                  src={`https://www.openstreetmap.org/export/embed.html?bbox=${coordinates.lng - 0.01}%2C${coordinates.lat - 0.01}%2C${coordinates.lng + 0.01}%2C${coordinates.lat + 0.01}&layer=mapnik&marker=${coordinates.lat}%2C${coordinates.lng}`}
                />
                
                {/* Alternativa: Usar um placeholder com texto explicativo */}
                {/*
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  flexDirection="column"
                >
                  <img 
                    src="/api/placeholder/600/400" 
                    alt="Mapa de localização"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                  <Typography variant="body1" mt={2} color="textSecondary">
                    Em uma aplicação real, aqui seria exibido um mapa do Google Maps ou OpenStreetMap
                    mostrando a localização em {address.street}, {address.number}, {address.city}/{address.state}
                  </Typography>
                </Box>
                */}
              </Box>

              <Typography variant="body2" mt={1} color="textSecondary">
                Endereço: {address.street}, {address.number} 
                {address.complement ? `, ${address.complement}` : ''} - {address.neighborhood} 
                - {address.city}/{address.state} - CEP: {address.zipCode}
              </Typography>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  )
}

export default EnderecoForm
import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  FormHelperText,
  InputAdornment,
  Grid,
  Autocomplete,
  Chip,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Badge,
  CircularProgress,
  Fade,
  Card,
  CardContent,
  CardHeader,
  styled,
} from '@mui/material'
import {
  Close as CloseIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  Check as CheckIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  WhatsApp as WhatsAppIcon,
  Delete as DeleteIcon,
  Money as MoneyIcon,
  Add as AddIcon,
  Search as SearchIcon,
  ShoppingCart as CartIcon,
  ReceiptLong as ReceiptIcon,
  Link as LinkIcon,
  Title as TitleIcon,
  PriorityHigh as PriorityIcon,
  Description as DescriptionIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Info as InfoIcon,
  AttachMoney as AttachMoneyIcon,
  ShoppingBasket as ShoppingBasketIcon,
  LocalOffer as TagIcon,
  Event as EventIcon, 
  AddCircleOutline as AddCircleOutlineIcon, // Adicionando este ícone
  EditNote as EditNoteIcon, // Adicionando este ícone também
} from '@mui/icons-material'

// Função para selecionar um débito:
interface ProductIconProps extends ListItemIconProps {
  productType?: string
}

interface FormErrors {
  title?: string
  columnId?: string
  value?: string
  diasAtraso?: string
  'contact.email'?: string
  'contact.whatsapp'?: string
  [key: string]: string | undefined // Para permitir outras propriedades dinâmicas
}

interface CardFormData {
  id: string
  title: string
  type: string
  columnId: string
  company: string
  description: string
  priority: string
  value: string
  diasAtraso: string
  contact: {
    name: string
    email: string
    phone: string
    whatsapp: string
  }
  invoice: any
  produtos: any[]
  debitoERP: any
  contatoId: string
  empresaId: string
  updatedAt?: string // Adicione essas propriedades como opcionais
  createdAt?: string
}

// Dados mockados para demonstração
const mockProdutosServicos = [
  {
    id: 'prod-001',
    nome: 'Notebook Dell Inspiron',
    tipo: 'produto',
    valor: 3999.9,
    codigo: 'NOTE-001',
  },
  {
    id: 'prod-002',
    nome: 'Monitor 24 polegadas',
    tipo: 'produto',
    valor: 899.9,
    codigo: 'MON-001',
  },
  {
    id: 'prod-003',
    nome: 'Cadeira de Escritório',
    tipo: 'produto',
    valor: 559.9,
    codigo: 'CAD-002',
  },
  {
    id: 'serv-001',
    nome: 'Consultoria em TI',
    tipo: 'servico',
    valor: 150.0,
    codigo: 'CONS-TI-001',
  },
  {
    id: 'serv-002',
    nome: 'Desenvolvimento de Website',
    tipo: 'servico',
    valor: 4800.0,
    codigo: 'DEV-WEB-001',
  },
  {
    id: 'serv-003',
    nome: 'Suporte Técnico Mensal',
    tipo: 'servico',
    valor: 349.9,
    codigo: 'SUP-TEC-001',
  },
]

const mockContatos = [
  {
    id: 'cont-001',
    nome: 'João Silva',
    email: 'joao@empresa.com',
    telefone: '(11) 98765-4321',
    empresa: 'Empresa ABC',
  },
  {
    id: 'cont-002',
    nome: 'Maria Santos',
    email: 'maria@tech.com',
    telefone: '(21) 91234-5678',
    empresa: 'Tech Solutions',
  },
  {
    id: 'cont-003',
    nome: 'Pedro Oliveira',
    email: 'pedro@consultoria.com',
    telefone: '(31) 99876-5432',
    empresa: 'Consultoria XYZ',
  },
]

const mockEmpresas = [
  {
    id: 'emp-001',
    nome: 'Empresa ABC',
    cnpj: '12.345.678/0001-99',
    endereco: 'Rua A, 123',
  },
  {
    id: 'emp-002',
    nome: 'Tech Solutions',
    cnpj: '98.765.432/0001-10',
    endereco: 'Av. B, 456',
  },
  {
    id: 'emp-003',
    nome: 'Consultoria XYZ',
    cnpj: '45.678.912/0001-33',
    endereco: 'Praça C, 789',
  },
]

// Componentes estilizados personalizados
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
    overflow: 'hidden',
  },
}))

const DialogHeader = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #e0e0e0',
  padding: '16px 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const StyledDialogContent = styled(DialogContent)`
  padding: 24px;
  overflow-y: overlay;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`

const FormSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  padding: '16px',
  marginBottom: '20px',
}))

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 600,
  marginBottom: '12px',
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
    fontSize: '18px',
  },
}))

const ProductItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '8px',
  marginBottom: '8px',
  border: '1px solid #e0e0e0',
  transition: 'all 0.2s',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}))

const ProductIcon = styled(ListItemIcon)<ProductIconProps>(
  ({ theme, productType }) => ({
    minWidth: '36px',
    '& .MuiSvgIcon-root': {
      color: productType === 'produto' ? '#3f51b5' : '#9c27b0',
    },
  }),
)

const ActionButtons = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #e0e0e0',
  padding: '16px 24px',
  backgroundColor: '#f8f9fa',
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 14,
    top: 14,
    padding: '0 4px',
    backgroundColor: '#3f51b5',
  },
}))

const CardDialog = ({
  open,
  onClose,
  onSave,
  onDelete,
  card,
  columns,
  boardType,
}) => {
  const [searchDebitoTerm, setSearchDebitoTerm] = useState('')
  const [errors, setErrors] = useState<FormErrors>({})
  // Adicione os dados mockados para débitos:
  const mockDebitosERP = [
    {
      id: 'deb-001',
      valor: 1250.0,
      vencimento: '2023-05-15',
      descricao: 'Fatura #12458',
      dias_atraso: 35,
      empresa: 'Empresa ABC',
    },
    {
      id: 'deb-002',
      valor: 780.5,
      vencimento: '2023-06-01',
      descricao: 'Fatura #12502',
      dias_atraso: 18,
      empresa: 'Tech Solutions',
    },
    {
      id: 'deb-003',
      valor: 3200.0,
      vencimento: '2023-04-30',
      descricao: 'Fatura #12445',
      dias_atraso: 50,
      empresa: 'Consultoria XYZ',
    },
  ]

  // Função para filtrar débitos:
  const filteredDebitos = mockDebitosERP.filter(
    (debito) =>
      debito.descricao.toLowerCase().includes(searchDebitoTerm.toLowerCase()) ||
      debito.empresa.toLowerCase().includes(searchDebitoTerm.toLowerCase()),
  )

  const handleSelectDebito = (debito) => {
    setFormData({
      ...formData,
      debitoERP: debito,
      value: debito.valor.toString(),
      diasAtraso: debito.dias_atraso.toString(),
      company: debito.empresa,
    })
  }

  const [formData, setFormData] = useState({
    id: '',
    title: '',
    type: boardType,
    columnId: '',
    company: '',
    description: '',
    priority: 'média',
    erpName: '',
    value: '',
    diasAtraso: '',
    contact: {
      name: '',
      email: '',
      phone: '',
      whatsapp: '',
    },
    invoice: null,
    // Novos campos
    produtos: [],
    debitoERP: null,
    contatoId: '',
    empresaId: '',
  })

  // Estados para os novos diálogos
  const [showNewContactDialog, setShowNewContactDialog] = useState(false)
  const [showNewCompanyDialog, setShowNewCompanyDialog] = useState(false)
  const [searchContactTerm, setSearchContactTerm] = useState('')
  const [searchCompanyTerm, setSearchCompanyTerm] = useState('')
  const [searchProdutoTerm, setSearchProdutoTerm] = useState('')

  // Estado para carregamento
  const [loading, setLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)

  // Preencher o formulário quando um card existente é selecionado
  useEffect(() => {
    console.log('Preenchendo formulário com card:', card)
    if (card) {
      setFormData({
        ...formData,
        ...card,
        // Assegurar que todas as propriedades estão presentes
        contact: {
          name: card.contact?.name || '',
          email: card.contact?.email || '',
          phone: card.contact?.phone || '',
          whatsapp: card.contact?.whatsapp || '',
        },
        invoice: {
          invoiceId: card.invoice?.invoiceId || '',
          paymentLink: card.paymentLink?.invoiceId || '',
          paymentType: card.paymentType?.invoiceId || '',
          paymentCode: card.paymentCode?.invoiceId || '',
        },
        // Inicializar novos campos
        produtos: card.produtos || [],
        debitoERP: card.debitoERP || null,
        contatoId: card.contatoId || '',
        empresaId: card.empresaId || '',
      })
    } else {
      // Resetar para um novo card
      setFormData({
        id: '',
        title: '',
        type: boardType,
        columnId:
          Array.isArray(columns) && columns.length > 0 ? columns[0].id : '',
        company: '',
        description: '',
        erpName: "",
        priority: 'média',
        value: '',
        invoice: null,
        diasAtraso: '',
        contact: {
          name: '',
          email: '',
          phone: '',
          whatsapp: '',
        },
        // Inicializar novos campos
        produtos: [],
        debitoERP: null,
        contatoId: '',
        empresaId: '',
      })
    }

    // Limpar erros
    setErrors({})
  }, [card, columns, boardType])

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData({
      ...formData,
      [name]: value,
    })

    // Limpar erro para este campo
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      })
    }
  }

  const handleContactChange = (e) => {
    const { name, value } = e.target

    setFormData({
      ...formData,
      contact: {
        ...formData.contact,
        [name]: value,
      },
    })

    // Limpar erro para este campo de contato
    if (errors[`contact.${name}`]) {
      setErrors({
        ...errors,
        [`contact.${name}`]: '',
      })
    }
  }

  const validateForm = () => {
    const newErrors: FormErrors = {}

    // Validar campos obrigatórios
    if (!formData.title.trim()) {
      newErrors.title = 'Título é obrigatório'
    }

    if (!formData.columnId) {
      newErrors.columnId = 'Coluna é obrigatória'
    }

    // Validações específicas por tipo de funil
    if (boardType === 'lead') {
      if (formData.value && isNaN(Number(formData.value))) {
        newErrors.value = 'O valor deve ser um número'
      }
    } else if (boardType === 'inadimplencia') {
      if (formData.diasAtraso && isNaN(Number(formData.diasAtraso))) {
        newErrors.diasAtraso = 'Dias em atraso deve ser um número'
      }
    }

    // Validar formato de email
    if (
      formData.contact.email &&
      !/\S+@\S+\.\S+/.test(formData.contact.email)
    ) {
      newErrors['contact.email'] = 'Email inválido'
    }

    // Validar telefone/whatsapp
    // if (
    //   formData.contact.whatsapp &&
    //   !/^\d{10,11}$/.test(formData.contact.whatsapp.replace(/\D/g, ''))
    // ) {
    //   newErrors['contact.whatsapp'] = 'WhatsApp inválido'
    // }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (validateForm()) {
      setSaveLoading(true)

      // Simulação de salvamento com tempo de carregamento
      setTimeout(() => {
        // Para novos cards, gerar um ID
        const dataToSave: CardFormData = card?.id
          ? { ...formData }
          : { ...formData, id: `card-${Date.now()}` }

        // Agora o TypeScript entende que dataToSave pode ter updatedAt e createdAt
        dataToSave.updatedAt = new Date().toISOString()
        if (!card?.id) {
          dataToSave.createdAt = new Date().toISOString()
        }

        onSave(dataToSave)
        setSaveLoading(false)
      }, 800)
    }
  }

  const handleDelete = () => {
    if (
      card?.id &&
      window.confirm('Tem certeza que deseja excluir este card?')
    ) {
      onDelete(card.id)
    }
  }

  // Manipuladores para produtos/serviços
  const handleAddProduto = (produto) => {
    if (!formData.produtos.some((p) => p.id === produto.id)) {
      setFormData({
        ...formData,
        produtos: [...formData.produtos, produto],
        // Atualizar o valor total somando este produto, se não houver valor ainda
        value: formData.value ? formData.value : produto.valor.toString(),
      })
    }
  }

  const handleRemoveProduto = (produtoId) => {
    setFormData({
      ...formData,
      produtos: formData.produtos.filter((p) => p.id !== produtoId),
    })
  }

  // Manipuladores para contatos e empresas existentes
  const handleSelectContact = (contato) => {
    setFormData({
      ...formData,
      contatoId: contato.id,
      contact: {
        name: contato.nome,
        email: contato.email,
        phone: contato.telefone,
        whatsapp: contato.telefone,
      },
    })
  }

  const handleSelectCompany = (empresa) => {
    setFormData({
      ...formData,
      empresaId: empresa.id,
      company: empresa.nome,
    })
  }

  // Manipuladores para criação de novo contato/empresa
  const handleSaveNewContact = () => {
    setLoading(true)

    // Simulando chamada de API
    setTimeout(() => {
      // Na prática, aqui seria uma chamada à API para criar um novo contato
      const newContact = {
        id: `cont-${Date.now()}`,
        nome: newContactForm.nome,
        email: newContactForm.email,
        telefone: newContactForm.telefone,
        empresa: newContactForm.empresa,
      }

      // Atualizar o formulário principal com o novo contato
      handleSelectContact(newContact)

      // Limpar e fechar o formulário
      setNewContactForm({
        nome: '',
        email: '',
        telefone: '',
        whatsapp: '',
        empresa: '',
      })

      setLoading(false)
      setShowNewContactDialog(false)
    }, 1000)
  }

  // Função para calcular valor total dos produtos selecionados
  const calcularValorTotal = () => {
    return formData.produtos.reduce(
      (total, produto) => total + produto.valor,
      0,
    )
  }

  // Estados para os novos formulários
  const [newContactForm, setNewContactForm] = useState({
    nome: '',
    email: '',
    telefone: '',
    whatsapp: '',
    empresa: '',
  })

  const [newCompanyForm, setNewCompanyForm] = useState({
    nome: '',
    cnpj: '',
    endereco: '',
  })

  // Filtrar produtos/serviços por termo de pesquisa
  const filteredProdutos = mockProdutosServicos.filter(
    (produto) =>
      produto.nome.toLowerCase().includes(searchProdutoTerm.toLowerCase()) ||
      produto.codigo.toLowerCase().includes(searchProdutoTerm.toLowerCase()),
  )

  // Filtrar contatos por termo de pesquisa
  const filteredContatos = mockContatos.filter(
    (contato) =>
      contato.nome.toLowerCase().includes(searchContactTerm.toLowerCase()) ||
      contato.email.toLowerCase().includes(searchContactTerm.toLowerCase()),
  )

  // Filtrar empresas por termo de pesquisa
  const filteredEmpresas = mockEmpresas.filter(
    (empresa) =>
      empresa.nome.toLowerCase().includes(searchCompanyTerm.toLowerCase()) ||
      empresa.cnpj.toLowerCase().includes(searchCompanyTerm.toLowerCase()),
  )

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogHeader>
        <Typography
          variant="h6"
          style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
        >
          {card?.id ? (
            <EditNoteIcon style={{ marginRight: '8px' }} />
          ) : (
            <AddCircleOutlineIcon style={{ marginRight: '8px' }} />
          )}
          {card?.id ? 'Editar Card' : 'Novo Card'}
        </Typography>
        <IconButton onClick={onClose} size="small" sx={{ color: '#757575' }}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <StyledDialogContent>
        <Grid container spacing={3}>
          {/* Lado esquerdo - Informações básicas */}
          <Grid item xs={12} md={7}>
            {/* Informações básicas */}
            <FormSection>
              <SectionTitle>
                <TitleIcon color="primary" />
                Informações Básicas
              </SectionTitle>

              <TextField
                name="title"
                label="Título *"
                fullWidth
                variant="outlined"
                value={formData.title}
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: errors.title ? null : (
                    <InputAdornment position="start">
                      <TitleIcon color="action" fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.columnId}
                >
                  <InputLabel>Coluna *</InputLabel>
                  <Select
                    name="columnId"
                    value={formData.columnId}
                    onChange={handleChange}
                    label="Coluna *"
                    sx={{
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                      },
                    }}
                  >
                    {Array.isArray(columns) &&
                      columns.map((column) => (
                        <MenuItem key={column.id} value={column.id}>
                          {column.title}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.columnId && (
                    <FormHelperText>{errors.columnId}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth variant="outlined">
                  <InputLabel>Prioridade</InputLabel>
                  <Select
                    name="priority"
                    value={formData.priority}
                    onChange={handleChange}
                    label="Prioridade"
                    sx={{
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        '&:before': {
                          content: '""',
                          display: 'inline-block',
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          marginRight: '8px',
                          backgroundColor:
                            formData.priority === 'alta'
                              ? '#f44336'
                              : formData.priority === 'média'
                                ? '#ff9800'
                                : '#4caf50',
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value="alta"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box
                        component="span"
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          bgcolor: '#f44336',
                          display: 'inline-block',
                          mr: 1,
                        }}
                      />
                      Alta
                    </MenuItem>
                    <MenuItem
                      value="média"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box
                        component="span"
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          bgcolor: '#ff9800',
                          display: 'inline-block',
                          mr: 1,
                        }}
                      />
                      Média
                    </MenuItem>
                    <MenuItem
                      value="baixa"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box
                        component="span"
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          bgcolor: '#4caf50',
                          display: 'inline-block',
                          mr: 1,
                        }}
                      />
                      Baixa
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* Campo de empresa */}
              <Box sx={{ mb: 1 }}>
                <SectionTitle
                  variant="subtitle2"
                  sx={{ fontSize: '14px', mb: 1 }}
                >
                  <BusinessIcon fontSize="small" />
                  Empresa
                </SectionTitle>

                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                  <TextField
                    placeholder="Buscar empresa..."
                    fullWidth
                    variant="outlined"
                    value={searchCompanyTerm}
                    onChange={(e) => setSearchCompanyTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="action" fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowNewCompanyDialog(true)}
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{ minWidth: '120px' }}
                  >
                    NOVA
                  </Button>
                </Box>

                {/* Lista de empresas encontradas */}
                {searchCompanyTerm && (
                  <Fade in={!!searchCompanyTerm}>
                    <Paper
                      variant="outlined"
                      sx={{ maxHeight: '150px', overflow: 'auto', mb: 2 }}
                    >
                      <List dense>
                        {filteredEmpresas.length > 0 ? (
                          filteredEmpresas.map((empresa) => (
                            <ListItem
                              key={empresa.id}
                              button
                              onClick={() => {
                                handleSelectCompany(empresa)
                                setSearchCompanyTerm('')
                              }}
                              selected={formData.empresaId === empresa.id}
                              sx={{
                                borderRadius: '4px',
                                m: 0.5,
                                '&.Mui-selected': {
                                  backgroundColor: 'rgba(63, 81, 181, 0.1)',
                                  '&:hover': {
                                    backgroundColor: 'rgba(63, 81, 181, 0.2)',
                                  },
                                },
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: '36px' }}>
                                <BusinessIcon
                                  fontSize="small"
                                  color={
                                    formData.empresaId === empresa.id
                                      ? 'primary'
                                      : 'action'
                                  }
                                />
                              </ListItemIcon>

                              <ListItemText
                                primary={empresa.nome}
                                secondary={empresa.cnpj}
                                primaryTypographyProps={{
                                  style: {
                                    fontWeight:
                                      formData.empresaId === empresa.id
                                        ? 600
                                        : 400,
                                  },
                                }}
                              />

                              {formData.empresaId === empresa.id && (
                                <CheckCircleIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              )}
                            </ListItem>
                          ))
                        ) : (
                          <ListItem>
                            <ListItemText
                              primary="Nenhuma empresa encontrada"
                              secondary="Use o botão + para criar uma nova"
                              primaryTypographyProps={{
                                style: {
                                  textAlign: 'center',
                                  color: '#757575',
                                },
                              }}
                              secondaryTypographyProps={{
                                style: { textAlign: 'center' },
                              }}
                            />
                          </ListItem>
                        )}
                      </List>
                    </Paper>
                  </Fade>
                )}

                {/* Empresa selecionada */}
                {formData.company && (
                  <Chip
                    icon={<BusinessIcon />}
                    label={formData.company}
                    onDelete={() => {
                      setFormData({
                        ...formData,
                        company: '',
                        empresaId: '',
                      })
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{ mb: 1 }}
                  />
                )}
              </Box>
            </FormSection>

            {/* Campos específicos por tipo de funil */}
            {boardType === 'lead' && (
              <>
                <FormSection>
                  <SectionTitle>
                    <AttachMoneyIcon color="primary" />
                    Valor e Produtos
                  </SectionTitle>

                  <TextField
                    name="value"
                    label="Valor"
                    fullWidth
                    variant="outlined"
                    value={formData.value}
                    onChange={handleChange}
                    error={!!errors.value}
                    helperText={
                      errors.value ||
                      'Valor total (automático se selecionar produtos)'
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                      readOnly: formData.produtos.length > 0,
                    }}
                    sx={{ mb: 2 }}
                  />

                  {/* Seleção de produtos/serviços */}
                  <SectionTitle
                    variant="subtitle2"
                    sx={{ fontSize: '14px', mt: 2, mb: 1 }}
                  >
                    <ShoppingBasketIcon fontSize="small" />
                    Produtos e Serviços
                  </SectionTitle>

                  <TextField
                    placeholder="Buscar produto ou serviço..."
                    fullWidth
                    variant="outlined"
                    value={searchProdutoTerm}
                    onChange={(e) => setSearchProdutoTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="action" fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{ mb: 1 }}
                  />

                  {/* Lista de produtos/serviços encontrados */}
                  {searchProdutoTerm && (
                    <Fade in={!!searchProdutoTerm}>
                      <Paper
                        variant="outlined"
                        sx={{ maxHeight: '150px', overflow: 'auto', mb: 2 }}
                      >
                        <List dense>
                          {filteredProdutos.length > 0 ? (
                            filteredProdutos.map((produto) => (
                              <ListItem
                                key={produto.id}
                                button
                                onClick={() => {
                                  handleAddProduto(produto)
                                  setSearchProdutoTerm('')
                                }}
                                disabled={formData.produtos.some(
                                  (p) => p.id === produto.id,
                                )}
                                sx={{
                                  borderRadius: '4px',
                                  m: 0.5,
                                  opacity: formData.produtos.some(
                                    (p) => p.id === produto.id,
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              >
                                <ListItemIcon sx={{ minWidth: '36px' }}>
                                  {produto.tipo === 'produto' ? (
                                    <CartIcon
                                      fontSize="small"
                                      color="primary"
                                    />
                                  ) : (
                                    <MoneyIcon
                                      fontSize="small"
                                      color="secondary"
                                    />
                                  )}
                                </ListItemIcon>

                                <ListItemText
                                  primary={produto.nome}
                                  secondary={`${produto.codigo} - ${produto.valor.toLocaleString(
                                    'pt-BR',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    },
                                  )}`}
                                />
                              </ListItem>
                            ))
                          ) : (
                            <ListItem>
                              <ListItemText
                                primary="Nenhum produto encontrado"
                                primaryTypographyProps={{
                                  style: {
                                    textAlign: 'center',
                                    color: '#757575',
                                  },
                                }}
                              />
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                    </Fade>
                  )}

                  {/* Produtos/serviços selecionados */}
                  {formData.produtos.length > 0 ? (
                    <Box>
                      <Paper
                        variant="outlined"
                        sx={{ p: 1, borderRadius: '8px' }}
                      >
                        <List dense disablePadding>
                          {formData.produtos.map((produto) => (
                            <ProductItem
                              key={produto.id}
                              disableGutters
                              divider
                            >
                              <ProductIcon productType={produto.tipo}>
                                {produto.tipo === 'produto' ? (
                                  <CartIcon fontSize="small" />
                                ) : (
                                  <MoneyIcon fontSize="small" />
                                )}
                              </ProductIcon>

                              <ListItemText
                                primary={produto.nome}
                                secondary={`${produto.codigo} - ${produto.valor.toLocaleString(
                                  'pt-BR',
                                  {
                                    style: 'currency',
                                    currency: 'BRL',
                                  },
                                )}`}
                              />

                              <IconButton
                                edge="end"
                                aria-label="delete"
                                size="small"
                                onClick={() => handleRemoveProduto(produto.id)}
                                sx={{ color: '#f44336' }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </ProductItem>
                          ))}

                          <Box
                            sx={{
                              backgroundColor: '#f5f9ff',
                              borderRadius: '0 0 8px 8px',
                              p: 1.5,
                              mt: 1,
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontWeight: 600,
                              }}
                            >
                              <span>Valor Total</span>
                              <span>
                                {calcularValorTotal().toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </span>
                            </Typography>
                          </Box>
                        </List>
                      </Paper>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: 3,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '8px',
                        border: '1px dashed #ccc',
                      }}
                    >
                      <ShoppingBasketIcon
                        sx={{ fontSize: 40, color: '#bdbdbd', mb: 1 }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ color: '#757575', textAlign: 'center' }}
                      >
                        Nenhum produto ou serviço selecionado
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: '#9e9e9e', textAlign: 'center', mt: 0.5 }}
                      >
                        Use a busca acima para adicionar itens
                      </Typography>
                    </Box>
                  )}
                </FormSection>
              </>
            )}

            {boardType === 'inadimplencia' && (
              <FormSection>
                <SectionTitle>
                  <ReceiptIcon color="error" />
                  Dados do Débito
                </SectionTitle>

                {/* Campos para valor e dias em atraso */}
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField
                    name="value"
                    label="Valor"
                    fullWidth
                    variant="outlined"
                    value={formData.value}
                    onChange={handleChange}
                    error={!!errors.value}
                    helperText={errors.value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                      readOnly: formData.debitoERP !== null,
                    }}
                  />

                  <TextField
                    name="diasAtraso"
                    label="Dias em atraso"
                    fullWidth
                    variant="outlined"
                    value={formData.diasAtraso}
                    onChange={handleChange}
                    error={!!errors.diasAtraso}
                    helperText={errors.diasAtraso}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">dias</InputAdornment>
                      ),
                      readOnly: formData.debitoERP !== null,
                    }}
                  />
                </Box>

                {/* Seção para vincular débito do ERP */}
                <Box>
                  <SectionTitle
                    variant="subtitle2"
                    sx={{ fontSize: '14px', mt: 2, mb: 1 }}
                  >
                    <LinkIcon fontSize="small" />
                    Vincular a Débito Existente
                  </SectionTitle>

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <TextField
                      placeholder="Buscar débito no sistema..."
                      fullWidth
                      variant="outlined"
                      value={searchDebitoTerm}
                      onChange={(e) => setSearchDebitoTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="action" fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                    />
                  </Box>

                  {/* Mensagem informativa */}
                  {!formData.debitoERP && !searchDebitoTerm && (
                    <Box
                      sx={{
                        backgroundColor: '#f5f5f5',
                        borderRadius: '4px',
                        p: 1.5,
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                      }}
                    >
                      <InfoIcon
                        fontSize="small"
                        sx={{ color: '#757575', mr: 1 }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ color: '#757575', fontSize: '13px' }}
                      >
                        Você pode buscar um débito existente ou preencher os
                        dados manualmente
                      </Typography>
                    </Box>
                  )}

                  {/* Lista de débitos encontrados */}
                  {searchDebitoTerm && (
                    <Fade in={!!searchDebitoTerm}>
                      <Paper
                        variant="outlined"
                        sx={{ maxHeight: '200px', overflow: 'auto', mb: 2 }}
                      >
                        <List dense>
                          {filteredDebitos.length > 0 ? (
                            filteredDebitos.map((debito) => (
                              <ListItem
                                key={debito.id}
                                button
                                onClick={() => {
                                  handleSelectDebito(debito)
                                  setSearchDebitoTerm('')
                                }}
                                sx={{
                                  borderRadius: '4px',
                                  m: 0.5,
                                  border: '1px solid #f0f0f0',
                                  '&:hover': {
                                    backgroundColor: '#fff5f5',
                                  },
                                }}
                              >
                                <ListItemIcon sx={{ minWidth: '36px' }}>
                                  <ReceiptIcon fontSize="small" color="error" />
                                </ListItemIcon>

                                <ListItemText
                                  primary={
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {debito.descricao}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontWeight: 600,
                                          color: '#f44336',
                                        }}
                                      >
                                        {debito.valor.toLocaleString('pt-BR', {
                                          style: 'currency',
                                          currency: 'BRL',
                                        })}
                                      </Typography>
                                    </Box>
                                  }
                                  secondary={
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mt: 0.5,
                                      }}
                                    >
                                      <Typography variant="caption">
                                        {debito.empresa} - Venc:{' '}
                                        {new Date(
                                          debito.vencimento,
                                        ).toLocaleDateString('pt-BR')}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          fontWeight: 500,
                                          color: '#d32f2f',
                                        }}
                                      >
                                        {debito.dias_atraso} dias em atraso
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                            ))
                          ) : (
                            <ListItem>
                              <ListItemText
                                primary="Nenhum débito encontrado"
                                primaryTypographyProps={{
                                  style: {
                                    textAlign: 'center',
                                    color: '#757575',
                                  },
                                }}
                              />
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                    </Fade>
                  )}

                  {/* Débito selecionado */}
                  {formData.debitoERP && (
                    <Paper
                      variant="outlined"
                      sx={{
                        p: 2,
                        mt: 2,
                        mb: 3,
                        borderRadius: '8px',
                        bgcolor: '#fff5f5',
                        borderColor: '#ffcdd2',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <LinkIcon
                            fontSize="small"
                            sx={{ mr: 1, color: '#d32f2f' }}
                          />
                          Débito Vinculado
                        </Typography>

                        <IconButton
                          size="small"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              debitoERP: null,
                              diasAtraso: '',
                              value: '',
                            })
                          }
                          sx={{ color: '#d32f2f' }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>

                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 500, mb: 1 }}
                      >
                        {formData.debitoERP.descricao}
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 2,
                          mt: 1,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <BusinessIcon
                            fontSize="small"
                            sx={{ mr: 0.5, opacity: 0.7 }}
                          />
                          {formData.debitoERP.empresa}
                        </Typography>

                        <Typography
                          variant="body2"
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <EventIcon
                            fontSize="small"
                            sx={{ mr: 0.5, opacity: 0.7 }}
                          />
                          Venc:{' '}
                          {new Date(
                            formData.debitoERP.vencimento,
                          ).toLocaleDateString('pt-BR')}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 2,
                          pt: 2,
                          borderTop: '1px dashed rgba(0,0,0,0.1)',
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 500, color: '#d32f2f' }}
                        >
                          {formData.debitoERP.dias_atraso} dias em atraso
                        </Typography>

                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {formData.debitoERP.valor.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Typography>
                      </Box>
                    </Paper>
                  )}
                </Box>
              </FormSection>
            )}

            {/* Descrição */}
            <FormSection>
              <SectionTitle>
                <DescriptionIcon color="primary" />
                Descrição
              </SectionTitle>

              <TextField
                name="description"
                placeholder="Adicione uma descrição detalhada..."
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={formData.description}
                onChange={handleChange}
              />
            </FormSection>
          </Grid>

          {/* Lado direito - Informações de contato */}
          <Grid item xs={12} md={5}>
            <FormSection>
              <SectionTitle>
                <PersonIcon color="primary" />
                Informações de Contato
              </SectionTitle>

              {/* Busca de contato existente */}
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <TextField
                  placeholder="Buscar contato..."
                  fullWidth
                  variant="outlined"
                  value={searchContactTerm}
                  onChange={(e) => setSearchContactTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="action" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowNewContactDialog(true)}
                  startIcon={<AddIcon />}
                  size="small"
                  sx={{ minWidth: '120px' }}
                >
                  NOVO
                </Button>
              </Box>

              {/* Lista de contatos encontrados */}
              {searchContactTerm && (
                <Fade in={!!searchContactTerm}>
                  <Paper
                    variant="outlined"
                    sx={{ maxHeight: '150px', overflow: 'auto', mb: 2 }}
                  >
                    <List dense>
                      {filteredContatos.length > 0 ? (
                        filteredContatos.map((contato) => (
                          <ListItem
                            key={contato.id}
                            button
                            onClick={() => {
                              handleSelectContact(contato)
                              setSearchContactTerm('')
                            }}
                            selected={formData.contatoId === contato.id}
                            sx={{
                              borderRadius: '4px',
                              m: 0.5,
                              '&.Mui-selected': {
                                backgroundColor: 'rgba(63, 81, 181, 0.1)',
                                '&:hover': {
                                  backgroundColor: 'rgba(63, 81, 181, 0.2)',
                                },
                              },
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: '36px' }}>
                              <Avatar
                                sx={{
                                  width: 28,
                                  height: 28,
                                  bgcolor:
                                    formData.contatoId === contato.id
                                      ? '#3f51b5'
                                      : '#9e9e9e',
                                  fontSize: '14px',
                                }}
                              >
                                {contato.nome.charAt(0).toUpperCase()}
                              </Avatar>
                            </ListItemIcon>

                            <ListItemText
                              primary={contato.nome}
                              secondary={`${contato.email} - ${contato.empresa}`}
                              primaryTypographyProps={{
                                style: {
                                  fontWeight:
                                    formData.contatoId === contato.id
                                      ? 600
                                      : 400,
                                },
                              }}
                            />

                            {formData.contatoId === contato.id && (
                              <CheckCircleIcon
                                color="primary"
                                fontSize="small"
                              />
                            )}
                          </ListItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary="Nenhum contato encontrado"
                            secondary="Use o botão + para criar um novo"
                            primaryTypographyProps={{
                              style: { textAlign: 'center', color: '#757575' },
                            }}
                            secondaryTypographyProps={{
                              style: { textAlign: 'center' },
                            }}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Paper>
                </Fade>
              )}

              {/* Formulário de contato */}
              <Box sx={{ py: 1 }}>
                <TextField
                  name="name"
                  label="Nome do contato"
                  fullWidth
                  variant="outlined"
                  value={formData.contact.name}
                  onChange={handleContactChange}
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon color="action" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  value={formData.contact.email}
                  onChange={handleContactChange}
                  error={!!errors['contact.email']}
                  helperText={errors['contact.email']}
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon color="action" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  name="phone"
                  label="Telefone"
                  fullWidth
                  variant="outlined"
                  value={formData.contact.phone}
                  onChange={handleContactChange}
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon color="action" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  name="whatsapp"
                  label="WhatsApp"
                  fullWidth
                  variant="outlined"
                  value={formData.contact.whatsapp}
                  onChange={handleContactChange}
                  error={!!errors['contact.whatsapp']}
                  helperText={errors['contact.whatsapp']}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WhatsAppIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </FormSection>
          </Grid>
        </Grid>
      </StyledDialogContent>

      <ActionButtons>
        {card?.id && (
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            variant="outlined"
            onClick={handleDelete}
            sx={{ mr: 'auto' }}
          >
            Excluir
          </Button>
        )}

        <Button
          onClick={onClose}
          color="inherit"
          variant="outlined"
          startIcon={<CancelIcon />}
          sx={{ mr: 1 }}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          startIcon={
            saveLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <CheckIcon />
            )
          }
          disabled={saveLoading}
        >
          {card?.id ? 'Salvar Alterações' : 'Criar Card'}
        </Button>
      </ActionButtons>

      {/* Diálogo para criação de novo contato */}
      {showNewContactDialog && (
        <Dialog
          open={showNewContactDialog}
          onClose={() => setShowNewContactDialog(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '12px',
              boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
            },
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: '#f8f9fa',
              borderBottom: '1px solid #e0e0e0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h6"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <PersonIcon sx={{ mr: 1 }} />
              Novo Contato
            </Typography>
            <IconButton
              onClick={() => setShowNewContactDialog(false)}
              size="small"
              sx={{ color: '#757575' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ p: 3 }}>
            <Box sx={{ py: 1 }}>
              <TextField
                label="Nome"
                fullWidth
                value={newContactForm.nome}
                onChange={(e) =>
                  setNewContactForm({ ...newContactForm, nome: e.target.value })
                }
                required
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon color="action" fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Email"
                fullWidth
                value={newContactForm.email}
                onChange={(e) =>
                  setNewContactForm({
                    ...newContactForm,
                    email: e.target.value,
                  })
                }
                type="email"
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon color="action" fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Telefone"
                fullWidth
                value={newContactForm.telefone}
                onChange={(e) =>
                  setNewContactForm({
                    ...newContactForm,
                    telefone: e.target.value,
                  })
                }
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon color="action" fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label="WhatsApp"
                fullWidth
                value={newContactForm.whatsapp}
                onChange={(e) =>
                  setNewContactForm({
                    ...newContactForm,
                    whatsapp: e.target.value,
                  })
                }
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WhatsAppIcon color="primary" fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Empresa"
                fullWidth
                value={newContactForm.empresa}
                onChange={(e) =>
                  setNewContactForm({
                    ...newContactForm,
                    empresa: e.target.value,
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon color="action" fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              p: 2,
              backgroundColor: '#f8f9fa',
              borderTop: '1px solid #e0e0e0',
            }}
          >
            <Button
              onClick={() => setShowNewContactDialog(false)}
              color="inherit"
              variant="outlined"
              startIcon={<CancelIcon />}
              sx={{ mr: 1 }}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveNewContact}
              disabled={!newContactForm.nome || loading}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckIcon />
                )
              }
            >
              Salvar Contato
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </StyledDialog>
  )
}

export default CardDialog

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  Avatar,
  IconButton,
  Tooltip,
  Divider,
  Switch
} from '@mui/material';
import { 
  Delete, 
  Business, 
  Sync,
  CheckCircle,
  Cancel,
  Settings
} from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ERPCardProps } from '../../types';

export const ERPCard: React.FC<ERPCardProps> = ({ erp, onClick, onDelete, onToggleActive }) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete();
  };
  
  const handleToggleActive = (event: React.MouseEvent) => {
    event.stopPropagation();
    onToggleActive(!erp.active);
  };

  // Calcular o número de módulos ativos
  const activeModulesCount = erp.modules.filter(module => module.active).length;
  
  // Formatar a data de atualização
  const formattedDate = erp.updatedAt ? 
    format(parseISO(erp.updatedAt), "dd 'de' MMMM 'de' yyyy", { locale: ptBR }) : 
    'Data desconhecida';
  
  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        width: '100%',
        transition: 'all 0.3s ease',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '6px',
          height: '100%',
          backgroundColor: erp.active ? '#4caf50' : '#ff9800',
          borderTopLeftRadius: '12px',
          borderBottomLeftRadius: '12px',
        },
      }}
    >
      <CardContent sx={{ padding: '20px' }}>
        {/* Cabeçalho com nome e ações */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: 600,
              color: '#32394a',
              fontSize: '18px',
              maxWidth: '80%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {erp.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#f0f4f8',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#3f51b5',
                marginRight: '8px'
              }}
            >
              <Business />
            </Box>
            <Tooltip title="Excluir" placement="top">
              <IconButton 
                size="small" 
                color="error" 
                onClick={handleDelete}
                sx={{ height: '30px', width: '30px' }}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {/* Chips para vendor e status */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5, justifyContent: 'space-between' }}>
          <Box>
            <Chip 
              label={erp.vendor} 
              size="small" 
              color="primary" 
              variant="outlined" 
              sx={{ mr: 1 }}
            />
            <Chip 
              icon={erp.active ? <CheckCircle fontSize="small" /> : <Cancel fontSize="small" />}
              label={erp.active ? "Ativo" : "Inativo"} 
              size="small" 
              color={erp.active ? "success" : "default"}
              variant="outlined"
            />
          </Box>
          
          <Tooltip title={erp.active ? "Desativar integração" : "Ativar integração"} placement="top">
            <Switch
              checked={erp.active}
              onChange={(e) => e.stopPropagation()}
              onClick={handleToggleActive}
              color="success"
              size="small"
            />
          </Tooltip>
        </Box>

        {/* Descrição */}
        <Typography
          variant="body2"
          sx={{
            mb: 2,
            color: '#637381',
            fontSize: '14px',
            lineHeight: 1.5,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            height: '42px',
          }}
        >
          {erp.description || 'Sem descrição'}
        </Typography>

        {/* Seção de Credenciais */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            mb: 2,
            backgroundColor: '#f9fafb',
            p: 1.5,
            borderRadius: '8px'
          }}
        >
          <Typography variant="caption" color="text.secondary" fontWeight={500}>
            Credenciais de API
          </Typography>
          <Typography variant="caption" sx={{ fontSize: '11px', color: '#637381' }}>
            <Box component="span" sx={{ fontWeight: 600 }}>App Key:</Box> {erp.appKey.substring(0, 8)}...
          </Typography>
          <Typography variant="caption" sx={{ fontSize: '11px', color: '#637381' }}>
            <Box component="span" sx={{ fontWeight: 600 }}>App Secret:</Box> {'•'.repeat(12)}
          </Typography>
        </Box>

        <Divider sx={{ my: 1.5 }} />

        {/* Módulos e informações de integração */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            mt: 1,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption" sx={{ fontSize: '12px', color: '#637381' }}>
              <Box component="span" sx={{ fontWeight: 600, color: '#32394a' }}>
                {activeModulesCount}
              </Box>{' '}
              de {erp.modules.length} módulos ativos
            </Typography>
            
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Tooltip title="Integrado ao funil de leads" placement="top">
                <Chip
                  label="Leads"
                  color={erp.leadFunnelIntegration ? "primary" : "default"}
                  size="small"
                  variant={erp.leadFunnelIntegration ? "filled" : "outlined"}
                  sx={{
                    height: '20px',
                    fontSize: '10px',
                  }}
                />
              </Tooltip>
              <Tooltip title="Integrado ao funil de inadimplência" placement="top">
                <Chip
                  label="Inadimplência"
                  color={erp.debtFunnelIntegration ? "secondary" : "default"}
                  size="small"
                  variant={erp.debtFunnelIntegration ? "filled" : "outlined"}
                  sx={{
                    height: '20px',
                    fontSize: '10px',
                  }}
                />
              </Tooltip>
            </Box>
          </Box>

          {/* Linha inferior */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
            <Chip
              icon={<Settings style={{ fontSize: '14px' }} />}
              label="Configurar"
              color="default"
              size="small"
              sx={{
                height: '24px',
                fontSize: '11px',
                '& .MuiChip-icon': {
                  fontSize: '14px',
                },
              }}
            />

            <Typography
              sx={{
                fontSize: '11px',
                color: '#919eab',
                fontStyle: 'italic',
              }}
            >
              Atualizado em {formattedDate}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
import styled from "styled-components";

export const Container = styled.div`
  padding: 32px 40px;
  margin-bottom: 20px;
  background-color: #fafbfc;
  border-bottom: 1px solid #eaeaea;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

export const WrapperHeader = styled.div`
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #222;
    margin: 0;
  }

  p {
    font-size: 0.9rem;
    color: #637381;
    line-height: 1.5;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 220px);
  background-color: #fff;
  border-radius: 8px;
  margin: 0 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  
  @media (max-width: 768px) {
    margin: 0 12px;
    height: calc(100vh - 200px);
  }
`;

export const WrapperSerach = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #f0f0f0;

  .filters {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
    max-width: 70%;
    
    /* Estilização para o InputSearch */
    div[class*="InputSearch"] {
      width: 300px;
      min-width: 300px;
      
      input {
        background-color: #f5f7fa;
        border-radius: 8px;
        padding: 10px 16px;
        font-size: 0.9rem;
        border: 1px solid #e0e4e8;
        height: 44px;
        transition: all 0.2s ease;
        
        &:focus {
          border-color: #3f51b5;
          box-shadow: 0 0 0 3px rgba(63, 81, 181, 0.1);
        }
      }
    }
  }

  .newProfile {
    background-color: #3f51b5;
    color: white;
    font-weight: 500;
    padding: 10px 20px;
    height: 44px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    text-transform: none;
    box-shadow: 0 2px 8px rgba(63, 81, 181, 0.25);
    transition: all 0.2s ease;
    
    &:hover {
      background-color: #303f9f;
      box-shadow: 0 4px 12px rgba(63, 81, 181, 0.4);
    }
    
    svg {
      margin-right: 8px;
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;

    .filters {
      width: 100%;
      max-width: 100%;
      
      div[class*="InputSearch"] {
        width: 100%;
        min-width: 100%;
      }
    }

    .newProfile {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
  }
`;

export const FormContainer = styled.div`
  padding: 32px;
  background-color: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  
  svg {
    color: #3f51b5;
    font-size: 28px;
    margin-right: 16px;
  }
  
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  
  p {
    margin: 8px 0 0;
    color: #637381;
    font-size: 0.9rem;
  }
`;

export const FormSection = styled.div`
  margin-bottom: 32px;
  background-color: #fafbfc;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;

  h2 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
    display: flex;
    align-items: center;
    
    & > svg {
      margin-right: 10px;
      font-size: 1.3rem;
      color: #3f51b5;
    }
  }

  .section-content {
    margin-top: 16px;
  }
  
  .MuiFormControl-root {
    margin-bottom: 16px;
  }
  
  .MuiInputBase-root {
    background-color: white;
    border-radius: 8px;
  }
  
  .MuiInputLabel-root {
    font-weight: 500;
  }
  
  .MuiFormHelperText-root {
    margin-top: 4px;
    font-size: 0.75rem;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid #f0f0f0;
  
  button {
    padding: 10px 24px;
    font-weight: 500;
    border-radius: 8px;
    
    &.delete-button {
      background-color: rgba(211, 47, 47, 0.1);
      color: #d32f2f;
      &:hover {
        background-color: rgba(211, 47, 47, 0.2);
      }
    }
    
    &.cancel-button {
      color: #637381;
      border-color: #ced4da;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    
    &.save-button {
      background-color: #3f51b5;
      color: white;
      box-shadow: 0 2px 8px rgba(63, 81, 181, 0.25);
      &:hover {
        background-color: #303f9f;
        box-shadow: 0 4px 12px rgba(63, 81, 181, 0.4);
      }
    }
  }
  
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 12px;
    
    & > button {
      width: 100%;
    }
  }
`;

export const TabContentContainer = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fafbfc;

  /* Estilo para a barra de rolagem */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 6px;
    border: 2px solid #f5f5f5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
  }
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 24px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 24px;

  h3 {
    margin-top: 16px;
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
  }

  p {
    margin-top: 12px;
    color: #637381;
    max-width: 500px;
    margin-bottom: 32px;
    line-height: 1.5;
  }
  
  button {
    padding: 8px 24px;
    font-weight: 500;
    border-radius: 6px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  color: #3f51b5;
  gap: 16px;
  
  p {
    color: #637381;
    margin-top: 8px;
  }
`;

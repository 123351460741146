// ListContatos.tsx (Main Component)
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { PaginationComponent } from '../../../../components/PaginationComponent/PaginationComponent';
import * as S from '../styles/styles';
import { Contato, ContactsResponse } from './ContactTypes';
import { ContactService } from './ContactService';
import { SearchBar } from './SearchBar';
import { ContactCard } from './ContactCard';
import { ContactFormDialog } from './ContactFormDialog';
import { useParams } from 'react-router-dom';

export const ListContatos = () => {
  // State for search and filtering
  const [search, setSearch] = useState('');
  const [tagFilter, setTagFilter] = useState('');
  const [contatos, setContatos] = useState<Contato[]>([]);
  const [selectedContato, setSelectedContato] = useState<Contato | null>(null);
  
  // State for pagination
  const [currentPage, setCurrentPage] = useState(0); // API uses 0-based indexing
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const contactsPerPage = 12;
  
  // State for dialog
  const [openDialog, setOpenDialog] = useState(false);
  
  // RPA ID - could be from props or context in the future
  
  const { rpaId } = useParams()

  // Fetch contacts on component mount and when search, page, or tag filter changes
  useEffect(() => {
    fetchContatos();
  }, [search, currentPage, tagFilter]);

  const fetchContatos = async () => {
    try {
      const response = await ContactService.fetchContacts(
        rpaId,
        search,
        currentPage,
        contactsPerPage,
        'name',
        'asc'
      );
      
      setContatos(response.content);
      setTotalElements(response.totalElements);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  // Extract all unique tags from contacts
  const getAllTags = (): string[] => {
    const tagSet = new Set<string>();
    
    contatos.forEach(contato => {
      if (contato.tags) {
        contato.tags.split(',').map(tag => tag.trim()).forEach(tag => {
          if (tag) tagSet.add(tag);
        });
      }
    });
    
    return Array.from(tagSet).sort();
  };

  // Open dialog to edit contact
  const handleEditContact = (contato: Contato) => {
    setSelectedContato(contato);
    setOpenDialog(true);
  };

  // Open dialog to create new contact
  const handleAddNewContact = () => {
    setSelectedContato(null);
    setOpenDialog(true);
  };

  // Close dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Handle saving contact (create or update)
  const handleSaveContact = (contact: Contato) => {
    fetchContatos(); // Refresh the list after saving
  };

  // Handle deleting contact
  const handleDeleteContact = (contactId: string) => {
    fetchContatos(); // Refresh the list after deletion
  };

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1); // Convert from 1-based to 0-based
  };

  // Handle tag selection
  const handleTagSelect = (tag: string) => {
    setTagFilter(tag);
    setCurrentPage(0); // Reset to first page
  };

  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">
            Gerenciamento de Contatos
          </h1>
          <p style={{ marginTop: '16px' }}>
            Cadastre e gerencie seus contatos para envio de mensagens.
          </p>
        </S.WrapperHeader>
      </S.Container>

      <S.Content>
        <Box sx={{ 
          overflow: 'auto',
          maxHeight: 'calc(100vh - 200px)', 
          position: 'relative',
          pb: 4,
          width: '100%'
        }}>
          <S.WrapperSerach>
            <SearchBar 
              search={search}
              setSearch={setSearch}
              tagFilter={tagFilter}
              setTagFilter={setTagFilter}
              allTags={getAllTags()}
              onAddNewContact={handleAddNewContact}
            />
          </S.WrapperSerach>
          
          <Box sx={{ padding: '0 30px', height: '100%' }}>
            <Box>
              {/* Grid para os contatos */}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                  gap: '16px',
                  mb: 4,
                  maxHeight: 'calc(100vh - 220px)',
                  pb: 2
                }}
              >
                {contatos.map((contato) => (
                  <ContactCard
                    key={contato.id}
                    contact={contato}
                    onClick={handleEditContact}
                    tagFilter={tagFilter}
                    onTagSelect={handleTagSelect}
                  />
                ))}
              </Box>
              
              {/* Componente de paginação */}
              {contatos.length > 0 && (
                <PaginationComponent
                  currentPage={currentPage + 1} // Convert from 0-based to 1-based
                  totalItems={totalElements}
                  itemsPerPage={contactsPerPage}
                  onPageChange={handlePageChange}
                  itemName="contatos"
                />
              )}
            </Box>
          </Box>
        </Box>
      </S.Content>
      
      {/* Contact Form Dialog */}
      <ContactFormDialog
        open={openDialog}
        onClose={handleCloseDialog}
        contact={selectedContato}
        rpaId={rpaId}
        onSave={handleSaveContact}
        onDelete={handleDeleteContact}
      />
    </>
  );
};
import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  IconButton, 
  Box, 
  Tabs, 
  Tab, 
  Chip, 
  Menu, 
  MenuItem, 
  Tooltip, 
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  Badge,
  ToggleButtonGroup,
  ToggleButton,
  Typography
} from '@mui/material';
import { 
  Add, 
  Close, 
  Search, 
  ChevronLeft, 
  ChevronRight, 
  Message as MessageIcon,
  SmartToy as SmartToyIcon,
  LocalOffer as TagIcon,
  FilterList as FilterIcon,
  Person as PersonIcon,
  PersonOutline as PersonOutlineIcon,
  PersonAdd as PersonAddIcon,
} from '@mui/icons-material';
import { ChatBox } from 'components/ChatBox/ChatBox';
import { ChatEditForm } from '../chatEditForm/ChatEditForm';
import { EmptyChatList } from '../emptyChatList/EmptyChatList';

// Current user info - mock para exemplo (normalmente virá de um context ou props)
const currentUser = {
  id: "1ffc2a92-b42f-4f7e-81af-d2b79527549c", // Mesmo ID que vem no objeto de chat
  name: "Usuário Atual",
  avatar: "UA"
};

export const ChatSidebar = ({
  isMobile,
  isSidebarOpen,
  toggleSidebar,
  activeTab,
  setActiveTab,
  searchTerm,
  setSearchTerm,
  filteredChats,
  handleNewChat,
  handleChatBoxClick,
  handleOptionsClick,
  selectedChatId,
  editingChatId,
  editingChatName,
  setEditingChatName,
  handleSaveRename,
  setEditingChatId,
  isCollapsed,
  toggleCollapse,
}) => {
  // State for tag filtering
  const [tagFilter, setTagFilter] = useState<string>('');
  const [tagAnchorEl, setTagAnchorEl] = useState<HTMLElement | null>(null);
  
  // State for assignment filtering
  const [assignmentFilter, setAssignmentFilter] = useState<string>('all'); // 'all', 'mine', 'unassigned'
  
  // State for assignment dialog
  const [assignDialogOpen, setAssignDialogOpen] = useState<boolean>(false);
  const [selectedChatForAssignment, setSelectedChatForAssignment] = useState<string | null>(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);
  
  // Mock users - será substituído pela chamada API
  const mockUsers = [
    { id: currentUser.id, name: currentUser.name, avatar: currentUser.avatar },
    { id: "user2", name: "João Silva", avatar: "JS" },
    { id: "user3", name: "Maria Santos", avatar: "MS" },
    { id: "user4", name: "Carlos Eduardo", avatar: "CE" }
  ];
  
  // Extract all unique tags from all chats
  const allTags = React.useMemo(() => {
    const tagSet = new Set<string>();
    
    filteredChats.forEach(chat => {
      if (chat.contact && chat.contact.tags) {
        chat.contact.tags.split(',').map(tag => tag.trim()).forEach(tag => {
          if (tag) tagSet.add(tag);
        });
      }
    });
    
    return Array.from(tagSet).sort();
  }, [filteredChats]);
  
  // Filter chats by tag and assignment
  const filteredAndAssignedChats = React.useMemo(() => {
    // Primeiro filtra por tag
    let result = !tagFilter 
      ? filteredChats 
      : filteredChats.filter(chat => 
          chat.contact && 
          chat.contact.tags && 
          chat.contact.tags.split(',').map(tag => tag.trim()).includes(tagFilter)
        );
    
    // Depois filtra por atribuição
    if (assignmentFilter === 'mine') {
      result = result.filter(chat => chat.userId === currentUser.id);
    } else if (assignmentFilter === 'unassigned') {
      result = result.filter(chat => !chat.userId);
    }
    
    return result;
  }, [filteredChats, tagFilter, assignmentFilter]);
  
  const handleTagMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTagAnchorEl(event.currentTarget);
  };
  
  const handleTagMenuClose = () => {
    setTagAnchorEl(null);
  };
  
  const handleTagSelect = (tag: string) => {
    setTagFilter(tag);
    handleTagMenuClose();
  };
  
  const clearTagFilter = () => {
    setTagFilter('');
  };
  
  // Abre o diálogo de atribuição
  const handleOpenAssignDialog = (chatId: string, e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation(); // Evita selecionar o chat ao clicar no botão de atribuição
    }
    setSelectedChatForAssignment(chatId);
    setAssignDialogOpen(true);
    
    // Simulação de carregamento de usuários
    setIsLoadingUsers(true);
    // Aqui virá uma chamada para a API para buscar usuários
    setTimeout(() => {
      setUsers(mockUsers);
      setIsLoadingUsers(false);
    }, 500);
  };
  
  // Atribui um chat a um usuário
  const handleAssignChat = (userId: string | null) => {
    if (!selectedChatForAssignment) return;
    
    // Aqui virá uma chamada para a API para atribuir o chat
    console.log(`Atribuindo chat ${selectedChatForAssignment} para usuário ${userId || 'nenhum'}`);
    
    // Fechar o diálogo
    setAssignDialogOpen(false);
    setSelectedChatForAssignment(null);
  };
  
  // Renderiza indicador de atribuição no ChatBox
  const renderAssignmentIndicator = (chat: any) => {
    if (!chat.userId) {
      return (
        <Tooltip title="Não atribuído">
          <IconButton 
            size="small" 
            onClick={(e) => handleOpenAssignDialog(chat.id, e)}
            sx={{ 
              p: '2px',
              color: 'rgba(107, 114, 128, 0.7)',
              '&:hover': {
                color: '#4F46E5',
                backgroundColor: 'rgba(79, 70, 229, 0.08)'
              }
            }}
          >
            <PersonAddIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    }
    
    // Verificar se está atribuído para o usuário atual
    const isAssignedToMe = chat.userId === currentUser.id;
    
    return (
      <Tooltip title={isAssignedToMe ? "Atribuído a você" : "Atribuído a outro usuário"}>
        <IconButton 
          size="small"
          onClick={(e) => handleOpenAssignDialog(chat.id, e)}
          sx={{ 
            p: '2px',
            color: isAssignedToMe ? '#4F46E5' : 'rgba(107, 114, 128, 0.7)',
            backgroundColor: isAssignedToMe ? 'rgba(79, 70, 229, 0.08)' : 'transparent',
            '&:hover': {
              color: '#4F46E5',
              backgroundColor: 'rgba(79, 70, 229, 0.08)'
            }
          }}
        >
          {isAssignedToMe ? <PersonIcon fontSize="small" /> : <PersonOutlineIcon fontSize="small" />}
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <div
      className={`${
        isSidebarOpen
          ? 'h-full overflow-hidden bg-white'
          : 'hidden'
      } flex flex-col py-4 overflow-hidden transition-all duration-300 ${
        isMobile ? 'fixed inset-y-0 left-0 z-30 w-[85vw] max-w-[350px] transform shadow-xl bg-white' : 'border-r border-gray-200'
      } ${isMobile && !isSidebarOpen ? '-translate-x-full' : 'translate-x-0'}`}
    >
      {/* Header with collapse button */}
      <div className="flex flex-row items-center justify-between px-4 h-16 overflow-hidden">
        {!isCollapsed ? (
          <>
            <div className="flex items-center">
              <div className="flex items-center justify-center rounded-full text-indigo-600 bg-indigo-100 h-10 w-10">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                  ></path>
                </svg>
              </div>
              <span className="ml-2 text-lg font-semibold text-gray-800">
                Conversas
              </span>
            </div>
            <div className="flex items-center">
              {isMobile && (
                <IconButton onClick={toggleSidebar} size="small" className="mr-1">
                  <Close />
                </IconButton>
              )}
              <IconButton 
                onClick={toggleCollapse} 
                size="small" 
                sx={{ 
                  color: 'rgba(107, 114, 128, 0.7)',
                  '&:hover': { color: 'rgba(79, 70, 229, 0.9)' }
                }}
              >
                <ChevronLeft />
              </IconButton>
            </div>
          </>
        ) : (
          // Collapsed header view
          <div className="flex flex-col items-center justify-between h-full w-full">
            <div className="flex items-center justify-center rounded-full text-indigo-600 bg-indigo-100 h-10 w-10 mb-2">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                ></path>
              </svg>
            </div>
            <IconButton 
              onClick={toggleCollapse} 
              size="small" 
              sx={{ 
                color: 'rgba(107, 114, 128, 0.7)',
                '&:hover': { color: 'rgba(79, 70, 229, 0.9)' } 
              }}
            >
              <ChevronRight />
            </IconButton>
          </div>
        )}
      </div>

      {/* Only show these elements if not collapsed */}
      {!isCollapsed && (
        <>
          {/* Search with integrated tag filter */}
          <Box sx={{ px: 3, pt: 2, pb: 1 }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Buscar conversa..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Search
                    fontSize="small"
                    sx={{ color: 'text.secondary', mr: 1 }}
                  />
                ),
                endAdornment: (
                  <Box sx={{ display: 'flex' }}>
                    <Tooltip title="Filtrar por tag">
                      <IconButton 
                        size="small" 
                        onClick={handleTagMenuOpen}
                        sx={{ 
                          color: tagFilter ? '#4F46E5' : 'rgba(107, 114, 128, 0.7)',
                        }}
                      >
                        <TagIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
                sx: {
                  borderRadius: '8px',
                  bgcolor: '#F3F4F6',
                  '& fieldset': { border: 'none' },
                  '&:hover fieldset': { border: 'none' },
                  '&.Mui-focused fieldset': { border: 'none' },
                },
              }}
            />
            
            {/* Filter indicators */}
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Tag filter indicator */}
              {tagFilter && (
                <Chip 
                  label={tagFilter} 
                  onDelete={clearTagFilter}
                  size="small"
                  color="primary"
                  sx={{ 
                    height: '22px', 
                    fontSize: '0.7rem',
                    borderRadius: '4px',
                    backgroundColor: '#4F46E5',
                    '& .MuiChip-deleteIcon': {
                      fontSize: '0.9rem',
                      margin: '0 2px 0 -4px'
                    }
                  }}
                />
              )}
              
              {/* Assignment Filter Toggle */}
              <ToggleButtonGroup
                size="small"
                value={assignmentFilter}
                exclusive
                onChange={(e, newValue) => {
                  if (newValue !== null) setAssignmentFilter(newValue);
                }}
                aria-label="filtro de atribuição"
                sx={{
                  height: '24px',
                  '.MuiToggleButton-root': {
                    fontSize: '0.65rem',
                    padding: '0 8px',
                    border: 'none',
                    color: 'rgba(107, 114, 128, 0.7)',
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(79, 70, 229, 0.08)',
                      color: '#4F46E5',
                    },
                  },
                }}
              >
                <ToggleButton value="all" aria-label="todas as conversas">
                  Todas
                </ToggleButton>
                <ToggleButton value="mine" aria-label="minhas conversas">
                  Minhas
                </ToggleButton>
                <ToggleButton value="unassigned" aria-label="não atribuídas">
                  Não atribuídas
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            
            {/* Tag Menu */}
            <Menu
              anchorEl={tagAnchorEl}
              open={Boolean(tagAnchorEl)}
              onClose={handleTagMenuClose}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: '8px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  maxHeight: '200px'
                }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {allTags.length > 0 ? (
                allTags.map((tag: string) => (
                  <MenuItem 
                    key={tag} 
                    onClick={() => handleTagSelect(tag)}
                    selected={tagFilter === tag}
                    sx={{
                      fontSize: '0.85rem',
                      minHeight: '32px',
                      paddingTop: '2px',
                      paddingBottom: '2px',
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(79, 70, 229, 0.1)',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: 'rgba(79, 70, 229, 0.2)',
                      },
                    }}
                  >
                    {tag}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled sx={{ fontSize: '0.85rem', minHeight: '32px' }}>
                  Nenhuma tag encontrada
                </MenuItem>
              )}
            </Menu>
          </Box>

          {/* Tabs */}
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            aria-label="chat tabs"
            sx={{
              px: 3,
              '& .MuiTabs-indicator': {
                backgroundColor: '#4F46E5',
              },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '0.875rem',
                minHeight: '48px',
                '&.Mui-selected': {
                  color: '#4F46E5',
                },
              },
            }}
          >
            <Tab
              label="Mensagens"
              value="messages"
              disabled={false}
            />
            <Tab label="Conversas LLM" value="llm" />
          </Tabs>

          {/* Recent Chats Header */}
          <div className="flex flex-row items-center justify-between text-xs mt-3 px-4 overflow-y-auto">
            <span className="font-medium text-gray-600">
              {tagFilter ? `Filtrado: ${tagFilter}` : "Conversas recentes"}
            </span>
            <button
              onClick={handleNewChat}
              className="flex items-center text-xs font-medium text-indigo-600 hover:text-indigo-700 transition-colors duration-150"
            >
              <Add fontSize="small" className="mr-0.5" />
              Nova
            </button>
          </div>

          {/* Chat List - Full View */}
          <div className="flex flex-col space-y-1 mt-2 px-3 overflow-y-auto flex-grow">
            {filteredAndAssignedChats.length === 0 ? (
              <EmptyChatList searchTerm={searchTerm} handleNewChat={handleNewChat} />
            ) : (
              filteredAndAssignedChats.map((chat) => (
                <div key={chat.id}>
                  {editingChatId === chat.id ? (
                    <ChatEditForm
                      editingChatName={editingChatName}
                      setEditingChatName={setEditingChatName}
                      handleSaveRename={handleSaveRename}
                      cancelEditing={() => setEditingChatId(null)}
                    />
                  ) : (
                    <div className="flex flex-col">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* Indicador de atribuição */}
                        <Box sx={{ mr: 1 }}>
                          {renderAssignmentIndicator(chat)}
                        </Box>
                        
                        {/* Chat Box */}
                        <Box sx={{ flexGrow: 1 }}>
                          <ChatBox
                            value={chat.name}
                            onClick={() => handleChatBoxClick(chat.id)}
                            onOptionsClick={(e) => handleOptionsClick(e, chat.id)}
                            isSelected={chat.id === selectedChatId}
                            isWhatsApp={chat.whatsapp}
                          />
                        </Box>
                      </Box>
                      
                      {/* Tags display for the chat */}
                      {chat.contact && chat.contact.tags && (
                        <div className="flex flex-wrap ml-8 mt-0.5 mb-1.5">
                          {chat.contact.tags.split(',').map(tag => tag.trim()).filter(tag => tag).map((tag: string, index: number) => (
                            <span 
                              key={index} 
                              onClick={() => setTagFilter(tag)}
                              className={`text-xs px-1.5 py-0.5 mr-1 mb-1 rounded cursor-pointer transition-colors duration-150 ${
                                tagFilter === tag 
                                  ? 'bg-indigo-600 text-white' 
                                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                              }`}
                              title={`Filtrar por ${tag}`}
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </>
      )}

      {/* Collapsed View - Only Icons */}
      {isCollapsed && (
        <div className="flex flex-col items-center mt-4 overflow-y-auto flex-grow">
          {/* Tab Icons */}
          <IconButton
            onClick={() => setActiveTab('messages')}
            sx={{ 
              mb: 1, 
              color: activeTab === 'messages' ? '#4F46E5' : 'rgba(107, 114, 128, 0.7)',
              backgroundColor: activeTab === 'messages' ? 'rgba(79, 70, 229, 0.1)' : 'transparent'
            }}
            title="Mensagens"
          >
            <MessageIcon />
          </IconButton>
          
          <IconButton
            onClick={() => setActiveTab('llm')}
            sx={{ 
              mb: 1, 
              color: activeTab === 'llm' ? '#4F46E5' : 'rgba(107, 114, 128, 0.7)',
              backgroundColor: activeTab === 'llm' ? 'rgba(79, 70, 229, 0.1)' : 'transparent'
            }}
            title="Conversas LLM"
          >
            <SmartToyIcon />
          </IconButton>
          
          {/* Tag filter icon */}
          <IconButton
            onClick={handleTagMenuOpen}
            sx={{ 
              mb: 1, 
              color: tagFilter ? '#4F46E5' : 'rgba(107, 114, 128, 0.7)',
              backgroundColor: tagFilter ? 'rgba(79, 70, 229, 0.1)' : 'transparent',
              padding: '6px',
              '&:hover': {
                backgroundColor: tagFilter ? 'rgba(79, 70, 229, 0.15)' : 'rgba(107, 114, 128, 0.1)',
              }
            }}
            title={tagFilter ? `Filtrado: ${tagFilter}` : "Filtrar por Tag"}
          >
            <TagIcon fontSize="small" />
          </IconButton>
          
          {/* Assignment filter icon */}
          <IconButton
            onClick={() => {
              // Cicla entre os tipos de filtro
              const filters = ['all', 'mine', 'unassigned'];
              const currentIndex = filters.indexOf(assignmentFilter);
              const nextIndex = (currentIndex + 1) % filters.length;
              setAssignmentFilter(filters[nextIndex]);
            }}
            sx={{ 
              mb: 1, 
              color: assignmentFilter !== 'all' ? '#4F46E5' : 'rgba(107, 114, 128, 0.7)',
              backgroundColor: assignmentFilter !== 'all' ? 'rgba(79, 70, 229, 0.1)' : 'transparent',
              padding: '6px',
              '&:hover': {
                backgroundColor: 'rgba(79, 70, 229, 0.15)',
              }
            }}
            title={
              assignmentFilter === 'all' 
                ? "Todas as conversas" 
                : assignmentFilter === 'mine' 
                  ? "Minhas conversas" 
                  : "Conversas não atribuídas"
            }
          >
            {assignmentFilter === 'all' ? (
              <FilterIcon fontSize="small" />
            ) : assignmentFilter === 'mine' ? (
              <PersonIcon fontSize="small" />
            ) : (
              <PersonAddIcon fontSize="small" />
            )}
          </IconButton>
          
          <div className="mt-2 border-t border-gray-200 w-10 mb-2"></div>
          
          {/* New Chat Button */}
          <IconButton
            onClick={handleNewChat}
            sx={{ 
              color: 'white',
              backgroundColor: '#4F46E5',
              '&:hover': { 
                backgroundColor: '#4338CA' 
              },
              mb: 2
            }}
            title="Nova Conversa"
          >
            <Add />
          </IconButton>
          
          {/* Chat Icons - Collapsed View */}
          <div className="flex flex-col items-center space-y-2 mt-2 overflow-y-auto">
            {filteredAndAssignedChats.length > 0 && 
              filteredAndAssignedChats.map((chat) => (
                <Tooltip 
                  key={chat.id} 
                  title={
                    chat.contact && chat.contact.tags 
                      ? `${chat.name} (Tags: ${chat.contact.tags})` 
                      : chat.name
                  }
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      chat.userId 
                        ? (chat.userId === currentUser.id
                          ? <PersonIcon 
                              sx={{ 
                                fontSize: '12px', 
                                color: 'white', 
                                backgroundColor: '#4F46E5',
                                borderRadius: '50%',
                                padding: '1px'
                              }}
                            />
                          : <PersonOutlineIcon 
                              sx={{ 
                                fontSize: '12px', 
                                color: 'white', 
                                backgroundColor: '#9CA3AF',
                                borderRadius: '50%',
                                padding: '1px'
                              }}
                            />)
                        : <PersonAddIcon 
                            sx={{ 
                              fontSize: '12px', 
                              color: 'white', 
                              backgroundColor: '#D1D5DB',
                              borderRadius: '50%',
                              padding: '1px'
                            }}
                          />
                    }
                  >
                    <div 
                      onClick={() => handleChatBoxClick(chat.id)}
                      className={`cursor-pointer flex items-center justify-center rounded-full w-10 h-10 ${
                        chat.id === selectedChatId 
                          ? 'bg-indigo-100 text-indigo-600' 
                          : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      {chat.name.charAt(0).toUpperCase()}
                    </div>
                  </Badge>
                </Tooltip>
              ))
            }
          </div>
        </div>
      )}

      {/* Diálogo de atribuição */}
      <Dialog 
        open={assignDialogOpen} 
        onClose={() => setAssignDialogOpen(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            overflow: 'hidden'
          }
        }}
      >
        <DialogTitle sx={{ 
          p: 2, 
          pb: 1.5,
          fontSize: '1rem',
          fontWeight: 600,
          color: '#2d3748', 
          borderBottom: '1px solid rgba(0,0,0,0.06)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          Atribuir conversa
          <IconButton 
            size="small" 
            onClick={() => setAssignDialogOpen(false)}
            sx={{ color: 'rgba(0,0,0,0.5)' }}
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ p: 0 }}>
          {isLoadingUsers ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
              <CircularProgress size={32} sx={{ color: '#4F46E5' }} />
            </Box>
          ) : (
            <>
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem 
                  button 
                  onClick={() => handleAssignChat(null)}
                  sx={{
                    borderBottom: '1px solid rgba(0,0,0,0.06)',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.02)'
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#E5E7EB', color: '#6B7280' }}>
                      <PersonOutlineIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary="Remover atribuição" 
                    secondary="Marcar como não atribuído"
                    primaryTypographyProps={{
                      fontSize: '0.95rem',
                      fontWeight: 500
                    }}
                    secondaryTypographyProps={{
                      fontSize: '0.8rem'
                    }}
                  />
                </ListItem>
                
                {users.map(user => (
                  <ListItem 
                    key={user.id}
                    button 
                    onClick={() => handleAssignChat(user.id)}
                    sx={{
                      borderBottom: '1px solid rgba(0,0,0,0.06)',
                      backgroundColor: user.id === currentUser.id ? 'rgba(79, 70, 229, 0.04)' : 'transparent',
                      '&:hover': {
                        backgroundColor: user.id === currentUser.id ? 'rgba(79, 70, 229, 0.08)' : 'rgba(0,0,0,0.02)'
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ 
                        bgcolor: user.id === currentUser.id ? '#4F46E5' : '#64748b', 
                        color: 'white',
                        fontWeight: 'bold'
                      }}>
                        {user.avatar}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={user.name} 
                      secondary={user.id === currentUser.id ? 'Você' : 'Agente disponível'}
                      primaryTypographyProps={{
                        fontSize: '0.95rem',
                        fontWeight: 500
                      }}
                      secondaryTypographyProps={{
                        fontSize: '0.8rem'
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>
        
        <DialogActions sx={{ p: 2, pt: 1.5, borderTop: '1px solid rgba(0,0,0,0.06)' }}>
          <Button 
            onClick={() => setAssignDialogOpen(false)}
            sx={{ 
              textTransform: 'none', 
              color: 'white',
              fontSize: '0.875rem',
              fontWeight: 500
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={() => handleAssignChat(currentUser.id)}
            variant="contained"
            sx={{ 
              textTransform: 'none', 
              backgroundColor: '#4F46E5',
              fontSize: '0.875rem',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: '#4338ca'
              }
            }}
          >
            Atribuir a mim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
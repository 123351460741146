import React from 'react'
import { RecipientTypeSelector } from './RecipientTypeSelector'
import { SearchField } from './SearchField'
import { RecipientSelector } from './RecipientSelector'
import { TemplateSelector } from './TemplateSelector'
import { TemplateFields } from './TemplateFields'

export const ContactForm = (props) => {
  return (
    <>
      {/* Tipo de Destinatário */}
      <RecipientTypeSelector
        recipientType={props.recipientType}
        setRecipientType={props.setRecipientType}
      />

      {/* Campo de busca */}
      <SearchField
        searchTerm={props.searchTerm}
        setSearchTerm={props.setSearchTerm}
        recipientType={props.recipientType}
      />

      {/* Seleção do destinatário */}
      <RecipientSelector
        recipientType={props.recipientType}
        modalSelectedContact={props.modalSelectedContact}
        setModalSelectedContact={props.setModalSelectedContact}
        modalSelectedEmpresa={props.modalSelectedEmpresa}
        setModalSelectedEmpresa={props.setModalSelectedEmpresa}
        modalSelectedLista={props.modalSelectedLista}
        setModalSelectedLista={props.setModalSelectedLista}
        filteredContacts={props.filteredContacts}
        filteredCompanies={props.filteredCompanies}
        filteredBroadcastLists={props.filteredBroadcastLists}
      />

      {/* Seleção de template */}
      <TemplateSelector
        modalSelectedTemplate={props.modalSelectedTemplate}
        setModalSelectedTemplate={props.setModalSelectedTemplate}
        templates={props.templates}
      />

      {/* Campos dinâmicos do template */}
      <TemplateFields
        modalSelectedTemplate={props.modalSelectedTemplate}
        dynamicFields={props.dynamicFields}
        handleTemplateFieldChange={props.handleTemplateFieldChange}
        replacedTemplateText={props.replacedTemplateText}
      />
    </>
  )
}
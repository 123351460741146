import { useState, useEffect, useCallback } from "react";
import { getUser } from "hooks/getUser";
import { llmEndpoint } from "infra";
import { useStore } from "../../../../../../stores/ChatStore";

export function useNewChatModal({ onClose, rpaId }) {
  // Estados do modal
  const [modalChatType, setModalChatType] = useState("llm");
  const [recipientType, setRecipientType] = useState("contact");
  const [modalSelectedContact, setModalSelectedContact] = useState("");
  const [modalSelectedEmpresa, setModalSelectedEmpresa] = useState("");
  const [modalSelectedLista, setModalSelectedLista] = useState("");
  const [modalSelectedTemplate, setModalSelectedTemplate] = useState("");
  const [modalTemplateFields, setModalTemplateFields] = useState({});
  const [modalChatName, setModalChatName] = useState("");
  const [replacedTemplateText, setReplacedTemplateText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Acessando dados da store
  const contacts = useStore((state) => state.contacts);
  const broadcastLists = useStore((state) => state.broadcastLists);
  const companies = useStore((state) => state.companies);
  const templates = useStore((state) => state.templates);

  // Lógica para template
  const selectedTemplateObject = templates?.find(
    (t) => t.id === modalSelectedTemplate,
  );
  const templateText = selectedTemplateObject
    ? selectedTemplateObject.template
    : "";

  const dynamicFields = templateText.match(/{{(\d+)}}/g) || [];

  // Handler para campos do template
  const handleTemplateFieldChange = useCallback((field, value) => {
    setModalTemplateFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  // Efeito para substituir campos do template
  useEffect(() => {
    let replacedText = templateText;

    Object.entries(modalTemplateFields).forEach(([key, value]) => {
      const pattern = `{{${key}}}`;

      while (replacedText.includes(pattern)) {
        replacedText = replacedText.replace(
          pattern,
          `<strong>${value}</strong>`,
        );
      }
    });

    setReplacedTemplateText(replacedText);
  }, [modalTemplateFields, templateText]);

  // Reset seleções ao mudar o tipo de destinatário
  useEffect(() => {
    setModalSelectedContact("");
    setModalSelectedEmpresa("");
    setModalSelectedLista("");
    setSearchTerm("");
  }, [recipientType]);

  // Handler para enviar mensagem
  const handleSendContactMessage = useCallback(async () => {
    try {
      const endpoint = `${llmEndpoint.url}/chat/contact`;

      // Payload básico
      const basePayload = {
        templateId: modalSelectedTemplate,
        message: replacedTemplateText,
        meta: false,
        type: "",
        contactId: "",
      };

      let finalPayload = { ...basePayload };

      if (recipientType === "contact") {
        finalPayload = {
          ...basePayload,
          type: "CONTACT",
          contactId: modalSelectedContact,
        };
      } else if (recipientType === "company") {
        finalPayload = {
          ...basePayload,
          type: "COMPANY",
          contactId: modalSelectedEmpresa,
        };
      } else if (recipientType === "broadcastList") {
        finalPayload = {
          ...basePayload,
          type: "LIST",
          contactId: modalSelectedLista,
        };
      }

      const response = await fetch(`${endpoint}?rpaId=${rpaId}`, {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(finalPayload),
      });

      if (!response.ok) {
        throw new Error(`Erro ao enviar mensagem para ${recipientType}`);
      }

      const data = await response.json();
      console.log(`Mensagem enviada com sucesso para ${recipientType}:`, data);

      onClose();
    } catch (error) {
      console.error(`Erro ao enviar mensagem para ${recipientType}:`, error);
    }
  }, [
    modalSelectedTemplate,
    replacedTemplateText,
    recipientType,
    rpaId,
    onClose,
    modalSelectedContact,
    modalSelectedEmpresa,
    modalSelectedLista,
  ]);

  // Handler para criar novo chat LLM
  const handleLlmNewChat = useCallback(async () => {
    console.log("Novo chat LLM", modalChatName);
    if (modalChatName.trim() !== "") {
      const userId = getUser().id;
      const companyId = getUser().companies[0].id;

      try {
        const response = await fetch(`${llmEndpoint.url}/folder`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: modalChatName,
            userId: userId,
            companyId: companyId,
            rpaId: rpaId,
          }),
        });

        if (!response.ok) {
          throw new Error("Erro ao criar novo chat");
        }

        const newChat = await response.json();
        console.log("Chat criado:", newChat);

        // setIsPolling(true);
        onClose();
      } catch (error) {
        console.error("Erro ao criar novo chat:", error);
      }
    }
  }, [modalChatName, rpaId, onClose]);

  // Função para determinar se o botão de ação primária deve estar desabilitado
  const isPrimaryActionDisabled = useCallback(() => {
    if (modalChatType === "llm") {
      return !modalChatName.trim();
    } else {
      // Verifica se um destinatário foi selecionado
      const hasRecipient =
        (recipientType === "contact" && modalSelectedContact) ||
        (recipientType === "company" && modalSelectedEmpresa) ||
        (recipientType === "broadcastList" && modalSelectedLista);

      // Verifica se todos os campos dinâmicos estão preenchidos
      const hasAllFieldsFilled =
        dynamicFields.length > 0
          ? dynamicFields.every((field) => {
              const fieldKey = field.replace(/[{}]/g, "");
              return !!modalTemplateFields[fieldKey];
            })
          : true;

      return !hasRecipient || !modalSelectedTemplate || !hasAllFieldsFilled;
    }
  }, [
    modalChatType,
    modalChatName,
    recipientType,
    modalSelectedContact,
    modalSelectedEmpresa,
    modalSelectedLista,
    modalSelectedTemplate,
    modalTemplateFields,
    dynamicFields,
  ]);

  // Handler para ação primária
  const handlePrimaryAction = useCallback(() => {
    if (modalChatType === "contact") {
      handleSendContactMessage();
    } else {
      handleLlmNewChat();
    }
  }, [modalChatType, handleSendContactMessage, handleLlmNewChat]);

  // Handler para fechar o modal
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  // Filtragem de listas com base no termo de busca
  const filteredContacts =
    contacts?.filter(
      (contact) =>
        contact?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (contact?.number &&
          contact.number.toLowerCase().includes(searchTerm.toLowerCase())),
    ) || [];

  const filteredCompanies = (companies || []).filter(
    (empresa) =>
      empresa?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (empresa?.description &&
        empresa.description.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  const filteredBroadcastLists = (broadcastLists || []).filter(
    (lista) =>
      lista?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (lista?.description &&
        lista.description.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  return {
    // Estados
    modalChatType,
    recipientType,
    modalSelectedContact,
    modalSelectedEmpresa,
    modalSelectedLista,
    modalSelectedTemplate,
    modalTemplateFields,
    modalChatName,
    replacedTemplateText,
    searchTerm,
    dynamicFields,

    // Dados filtrados
    filteredContacts,
    filteredCompanies,
    filteredBroadcastLists,
    templates,

    // Setters
    setModalChatType,
    setRecipientType,
    setModalSelectedContact,
    setModalSelectedEmpresa,
    setModalSelectedLista,
    setModalSelectedTemplate,
    setModalChatName,
    setSearchTerm,

    // Handlers
    handleTemplateFieldChange,
    handlePrimaryAction,
    handleClose,

    // Funções auxiliares
    isPrimaryActionDisabled,
  };
}

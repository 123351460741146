import React from 'react'
import { TextField } from '@mui/material'

export const RecipientSelector = ({
  recipientType,
  modalSelectedContact,
  setModalSelectedContact,
  modalSelectedEmpresa,
  setModalSelectedEmpresa,
  modalSelectedLista,
  setModalSelectedLista,
  filteredContacts,
  filteredCompanies,
  filteredBroadcastLists,
}) => {
  const getLabel = () => {
    if (recipientType === 'contact') return 'Contato'
    if (recipientType === 'empresa') return 'Empresa'
    return 'Lista'
  }

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {getLabel()}
      </label>
      
      {recipientType === 'contact' && (
        <TextField
          select
          fullWidth
          value={modalSelectedContact}
          onChange={(e) => setModalSelectedContact(e.target.value)}
          variant="outlined"
          size="small"
          SelectProps={{
            native: true,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        >
          <option value="">Selecione um contato</option>
          {filteredContacts.map((contact) => (
            <option key={contact.id} value={contact.id}>
              {contact.name} {contact.number ? `- ${contact.number}` : ''}
            </option>
          ))}
        </TextField>
      )}

      {recipientType === 'empresa' && (
        <TextField
          select
          fullWidth
          value={modalSelectedEmpresa}
          onChange={(e) => setModalSelectedEmpresa(e.target.value)}
          variant="outlined"
          size="small"
          SelectProps={{
            native: true,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        >
          <option value="">Selecione uma empresa</option>
          {filteredCompanies?.map((empresa) => (
            <option key={empresa.id} value={empresa.id}>
              {empresa.name}
            </option>
          ))}
        </TextField>
      )}

      {recipientType === 'lista' && (
        <TextField
          select
          fullWidth
          value={modalSelectedLista}
          onChange={(e) => setModalSelectedLista(e.target.value)}
          variant="outlined"
          size="small"
          SelectProps={{
            native: true,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        >
          <option value="">Selecione uma lista</option>
          {filteredBroadcastLists?.map((lista) => (
            <option key={lista.id} value={lista.id}>
              {lista.name}
            </option>
          ))}
        </TextField>
      )}
    </div>
  )
}
import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Chip,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Contato } from './ContactTypes'
import { ContactService } from './ContactService'

interface ContactFormDialogProps {
  open: boolean
  onClose: () => void
  contact: Contato | null
  rpaId: string
  onSave: (contact: Contato) => void
  onDelete?: (contactId: string) => void
  companyId?: string // ID da empresa para pré-selecionar no novo contato
}

export const ContactFormDialog: React.FC<ContactFormDialogProps> = ({
  open,
  onClose,
  contact,
  rpaId,
  onSave,
  onDelete,
  companyId,
}) => {
  // Log do que estamos recebendo para depuração
  console.log("ContactFormDialog - contact:", contact);
  console.log("ContactFormDialog - rpaId:", rpaId);
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [tags, setTags] = useState('')
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (contact) {
      setName(contact.name || '')
      setDescription(contact.description || '')
      setPhoneNumber(contact.number || '')
      setTags(contact.tags || '')
      setEmail(contact.email || '')
    } else {
      resetForm()
    }
  }, [contact, open])

  const resetForm = () => {
    setName('')
    setDescription('')
    setPhoneNumber('')
    setTags('')
    setEmail('')
  }

  // Função para formatar o número de telefone
  const formatPhoneNumber = (value: string) => {
    // Remove todos os caracteres não numéricos
    const numericValue = value.replace(/\D/g, '')

    // Aplica a formatação para número brasileiro
    if (numericValue.length <= 2) {
      return numericValue
    } else if (numericValue.length <= 6) {
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2)}`
    } else if (numericValue.length <= 10) {
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 6)}-${numericValue.slice(6)}`
    } else {
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 7)}-${numericValue.slice(7, 11)}`
    }
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatPhoneNumber(e.target.value)
    setPhoneNumber(formattedValue)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const contactData = {
        name,
        description,
        number: phoneNumber.replace(/\D/g, ''), // Remove formatação
        tags,
        email,
        rpaId,
        // Se for edição, inclui o ID
        ...(contact && { id: contact.id }),
        // Se tiver companyId definido (seja do contato existente ou passado como prop)
        companyId: contact?.companyId || companyId
      }

      let savedContact

      if (contact) {
        // Update existing contact
        savedContact = await ContactService.updateContact(
          rpaId,
          contact.id,
          contactData,
        )
      } else {
        // Create new contact
        savedContact = await ContactService.createContact(contactData)
      }

      onSave(savedContact)
      onClose()
    } catch (error) {
      console.error('Error saving contact:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDelete = async () => {
    if (contact && onDelete) {
      setIsSubmitting(true)
      try {
        await ContactService.deleteContact(rpaId, contact.id)
        onDelete(contact.id)
        onClose()
      } catch (error) {
        console.error('Error deleting contact:', error)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #eee',
          pb: 2,
        }}
      >
        {contact ? 'Editar Contato' : 'Novo Contato'}
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 3 }}>
          <TextField
            label="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
            required
            autoFocus
          />

          <TextField
            label="Descrição"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={3}
          />

          <TextField
            label="Número de Telefone"
            value={phoneNumber}
            onChange={handlePhoneChange}
            fullWidth
            margin="normal"
            placeholder="(99) 99999-9999"
            inputProps={{ maxLength: 15 }}
            required
            helperText="Digite o número com DDD sem parênteses ou traços"
          />

          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            type="email"
          />

          <TextField
            label="Tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            fullWidth
            margin="normal"
            placeholder="Lead, Cliente, Prospect"
            helperText="Separe as tags por vírgulas (ex: Lead, Cliente, Prospect)"
          />

          {/* Preview das tags */}
          {tags && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
              {tags
                .split(',')
                .map((tag) => tag.trim())
                .filter((tag) => tag)
                .map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    size="small"
                    sx={{
                      backgroundColor: '#3f51b5',
                      color: 'white',
                      fontSize: '0.75rem',
                    }}
                  />
                ))}
            </Box>
          )}
        </DialogContent>

        <DialogActions
          sx={{
            px: 3,
            pb: 3,
            justifyContent: contact ? 'space-between' : 'flex-end',
          }}
        >
          {contact && (
            <Button
              onClick={handleDelete}
              color="error"
              variant="outlined"
              disabled={isSubmitting}
            >
              Deletar
            </Button>
          )}

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={onClose} disabled={isSubmitting}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {contact ? 'Atualizar' : 'Salvar'}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}

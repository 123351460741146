import React from 'react'
import { useParams } from 'react-router-dom'
import { StyledModal } from '../styledModal/StyledModal'
import { useNewChatModal } from './useNewChatModal'
import { ChatTypeSelector } from './atoms/ChatTypeSelector'
import { ContactForm } from './atoms/ContactForm'
import { ChatNameInput } from './atoms/ChatNameInput'

export const NewChatModal = ({ isOpen, onClose }) => {
  const { rpaId } = useParams()

  const props = useNewChatModal({
    onClose,
    rpaId,
  })

  if (!isOpen) return null

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={props.handleClose}
      title="Novo Chat"
      primaryAction={props.handlePrimaryAction}
      primaryActionText={props.modalChatType === 'contact' ? 'Enviar' : 'Criar'}
      secondaryActionText="Cancelar"
    >
      <div className="space-y-4">
        <ChatTypeSelector
          modalChatType={props.modalChatType}
          setModalChatType={props.setModalChatType}
          rpaId={rpaId}
        />

        {props.modalChatType === 'contact' ? (
          <ContactForm {...props} />
        ) : (
          <ChatNameInput
            modalChatName={props.modalChatName}
            setModalChatName={props.setModalChatName}
          />
        )}
      </div>
    </StyledModal>
  )
}
import React, { useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  InputAdornment,
  Fade,
} from '@mui/material';
import {
  ReceiptLong as ReceiptIcon,
  Link as LinkIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  Business as BusinessIcon,
  Event as EventIcon,
  Info as InfoIcon,
  Payment as PaymentIcon,
} from '@mui/icons-material';
import { FormSection, SectionTitle } from './styles';
import { CardFormData, FormErrors, DebitoERP, Invoice } from './types/CardTypes';
import { mockDebits } from './mocks';

interface DebitsSectionProps {
  formData: CardFormData;
  errors: FormErrors;
  searchDebitoTerm: string;
  setSearchDebitoTerm: (term: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
  handleSelectDebito: (debito: DebitoERP) => void;
}

const DebitsSection: React.FC<DebitsSectionProps> = ({
  formData,
  errors,
  searchDebitoTerm,
  setSearchDebitoTerm,
  handleChange,
  handleSelectDebito,
}) => {
  // Filtrar débitos com base no termo de pesquisa
  const filteredDebitos = mockDebits.filter(
    (debito) =>
      debito.descricao.toLowerCase().includes(searchDebitoTerm.toLowerCase()) ||
      debito.empresa.toLowerCase().includes(searchDebitoTerm.toLowerCase())
  );

  // Check if invoice is set to show it as selected
  const hasInvoice = formData.invoice !== null;

  return (
    <FormSection>
      <SectionTitle>
        <ReceiptIcon color="error" />
        Dados do Débito
      </SectionTitle>

      {/* Campos para valor e dias em atraso */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          name="value"
          label="Valor"
          fullWidth
          variant="outlined"
          value={formData.value}
          onChange={handleChange}
          error={!!errors.value}
          helperText={errors.value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
            readOnly: formData.debitoERP !== null || hasInvoice,
          }}
        />

        <TextField
          name="diasAtraso"
          label="Dias em atraso"
          fullWidth
          variant="outlined"
          value={formData.diasAtraso}
          onChange={handleChange}
          error={!!errors.diasAtraso}
          helperText={errors.diasAtraso}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">dias</InputAdornment>
            ),
            readOnly: formData.debitoERP !== null || hasInvoice,
          }}
        />
      </Box>

      {/* Invoice section - Show when invoice exists */}
      {hasInvoice && (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            mb: 3,
            borderRadius: '8px',
            bgcolor: '#f0f9ff',
            borderColor: '#bbdefb',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PaymentIcon
                fontSize="small"
                sx={{ mr: 1, color: '#1976d2' }}
              />
              Fatura Gerada
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 500, mb: 1 }}
          >
            {formData.invoice.invoiceId}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              mt: 1,
            }}
          >
            <Typography
              variant="body2"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <BusinessIcon
                fontSize="small"
                sx={{ mr: 0.5, opacity: 0.7 }}
              />
              Tipo: {formData.invoice.paymentType}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              pt: 2,
              borderTop: '1px dashed rgba(0,0,0,0.1)',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: '#1976d2' }}
            >
              Código: {formData.invoice.paymentCode}
            </Typography>

            {/* <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {formData.value && parseFloat(formData.value).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography> */}
          </Box>

          {formData.invoice.paymentLink && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <a 
                href={formData.invoice.paymentLink} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: '#1976d2',
                  display: 'inline-block',
                  padding: '8px 16px',
                  border: '1px solid #1976d2',
                  borderRadius: '4px',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                Acessar Link de Pagamento
              </a>
            </Box>
          )}
        </Paper>
      )}

      {/* Seção para vincular débito do ERP - apenas mostrar se não tiver invoice */}
      {!hasInvoice && (
        <Box>
          <SectionTitle
            variant="subtitle2"
            sx={{ fontSize: '14px', mt: 2, mb: 1 }}
          >
            <LinkIcon fontSize="small" />
            Vincular a Débito Existente
          </SectionTitle>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <TextField
              placeholder="Buscar débito no sistema..."
              fullWidth
              variant="outlined"
              value={searchDebitoTerm}
              onChange={(e) => setSearchDebitoTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" fontSize="small" />
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          </Box>

          {/* Mensagem informativa */}
          {!formData.debitoERP && !searchDebitoTerm && (
            <Box
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                p: 1.5,
                display: 'flex',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <InfoIcon
                fontSize="small"
                sx={{ color: '#757575', mr: 1 }}
              />
              <Typography
                variant="body2"
                sx={{ color: '#757575', fontSize: '13px' }}
              >
                Você pode buscar um débito existente ou preencher os
                dados manualmente
              </Typography>
            </Box>
          )}

          {/* Lista de débitos encontrados */}
          {searchDebitoTerm && (
            <Fade in={!!searchDebitoTerm}>
              <Paper
                variant="outlined"
                sx={{ maxHeight: '200px', overflow: 'auto', mb: 2 }}
              >
                <List dense>
                  {filteredDebitos.length > 0 ? (
                    filteredDebitos.map((debito) => (
                      <ListItem
                        key={debito.id}
                        button
                        onClick={() => {
                          handleSelectDebito(debito);
                          setSearchDebitoTerm('');
                        }}
                        sx={{
                          borderRadius: '4px',
                          m: 0.5,
                          border: '1px solid #f0f0f0',
                          '&:hover': {
                            backgroundColor: '#fff5f5',
                          },
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: '36px' }}>
                          <ReceiptIcon fontSize="small" color="error" />
                        </ListItemIcon>

                        <ListItemText
                          primary={
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 500 }}
                              >
                                {debito.descricao}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 600,
                                  color: '#f44336',
                                }}
                              >
                                {debito.valor.toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </Typography>
                            </Box>
                          }
                          secondary={
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: 0.5,
                              }}
                            >
                              <Typography variant="caption">
                                {debito.empresa} - Venc:{' '}
                                {new Date(
                                  debito.vencimento,
                                ).toLocaleDateString('pt-BR')}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  fontWeight: 500,
                                  color: '#d32f2f',
                                }}
                              >
                                {debito.dias_atraso} dias em atraso
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText
                        primary="Nenhum débito encontrado"
                        primaryTypographyProps={{
                          style: {
                            textAlign: 'center',
                            color: '#757575',
                          },
                        }}
                      />
                    </ListItem>
                  )}
                </List>
              </Paper>
            </Fade>
          )}

          {/* Débito selecionado */}
          {formData.debitoERP && (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                mt: 2,
                mb: 3,
                borderRadius: '8px',
                bgcolor: '#fff5f5',
                borderColor: '#ffcdd2',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LinkIcon
                    fontSize="small"
                    sx={{ mr: 1, color: '#d32f2f' }}
                  />
                  Débito Vinculado
                </Typography>

                <IconButton
                  size="small"
                  onClick={() =>
                    handleSelectDebito({
                      id: '',
                      valor: 0,
                      vencimento: '',
                      descricao: '',
                      dias_atraso: 0,
                      empresa: '',
                    })
                  }
                  sx={{ color: '#d32f2f' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>

              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 500, mb: 1 }}
              >
                {formData.debitoERP.descricao}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <BusinessIcon
                    fontSize="small"
                    sx={{ mr: 0.5, opacity: 0.7 }}
                  />
                  {formData.debitoERP.empresa}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <EventIcon
                    fontSize="small"
                    sx={{ mr: 0.5, opacity: 0.7 }}
                  />
                  Venc:{' '}
                  {new Date(
                    formData.debitoERP.vencimento,
                  ).toLocaleDateString('pt-BR')}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  pt: 2,
                  borderTop: '1px dashed rgba(0,0,0,0.1)',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, color: '#d32f2f' }}
                >
                  {formData.debitoERP.dias_atraso} dias em atraso
                </Typography>

                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {formData.debitoERP.valor.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Box>
            </Paper>
          )}
        </Box>
      )}
    </FormSection>
  );
};

export default DebitsSection;
import React from 'react';
import { Delete } from '@mui/icons-material';
import { StyledModal } from '../styledModal/StyledModal';

export const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      title="Confirmar Exclusão"
      primaryAction={onConfirm}
      primaryActionText="Deletar"
      secondaryActionText="Cancelar"
    >
      <div className="py-2">
        <div className="flex justify-center mb-4">
          <div className="bg-red-100 text-red-500 p-3 rounded-full">
            <Delete />
          </div>
        </div>
        <p className="text-center text-gray-700">
          Tem certeza que deseja deletar esta conversa? Esta ação não pode ser
          desfeita.
        </p>
      </div>
    </StyledModal>
  );
};
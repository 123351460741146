import React from 'react'
import { Backdrop, CircularProgress, Box } from '@mui/material'
import { LoadingBackdropProps } from './types'

export const LoadingBackdrop = ({ open, message }: LoadingBackdropProps) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
      open={open}
    >
      <CircularProgress color="primary" size={60} />
      <Box sx={{ 
        mt: 2, 
        color: 'white', 
        fontWeight: 500,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        px: 3,
        py: 1,
        borderRadius: 2
      }}>
        {message}
      </Box>
    </Backdrop>
  )
}
import React, { useState } from 'react';
import { Box, Tooltip, Menu, MenuItem, Chip, Typography } from '@mui/material';
import { Add, LocalOffer as TagIcon } from '@mui/icons-material';
import { Button } from '@intraversa-lab/styleguide';
import { InputSearch, SearchBarMobile } from 'components';
import { FilterListProfile } from './Filter/FilterListProfile';

interface SearchBarProps {
  search: string;
  setSearch: (value: string) => void;
  tagFilter: string;
  setTagFilter: (value: string) => void;
  allTags: string[];
  onAddNewContact: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  search,
  setSearch,
  tagFilter,
  setTagFilter,
  allTags,
  onAddNewContact,
}) => {
  const [showInputSearch, setShowInputSearch] = useState(false);
  const [tagAnchorEl, setTagAnchorEl] = useState<HTMLElement | null>(null);

  const handleTagMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTagAnchorEl(event.currentTarget);
  };

  const handleTagMenuClose = () => {
    setTagAnchorEl(null);
  };

  const handleTagSelect = (tag: string) => {
    setTagFilter(tag);
    handleTagMenuClose();
  };

  const clearTagFilter = () => {
    setTagFilter('');
  };

  return (
    <>
      <div className="filters">
        {showInputSearch ? (
          <div className="inputSearchMobile">
            <SearchBarMobile
              placeholder={'Pesquisar contato'}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClose={() => setShowInputSearch(!showInputSearch)}
            />
          </div>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <InputSearch
                placeholder="Pesquisar contato"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onShowMobile={() => setShowInputSearch(!showInputSearch)}
              />
              
              <Tooltip title="Filtrar por tag">
                <Box>
                  <Button
                    startIcon={<TagIcon />}
                    onClick={handleTagMenuOpen}
                    className="tagFilter"
                    variant={tagFilter ? "contained" : "outlined"}
                    sx={{
                      minWidth: 'auto',
                      height: '40px',
                      borderRadius: '8px',
                      backgroundColor: tagFilter ? '#3f51b5' : 'transparent',
                      borderColor: '#3f51b5',
                      color: tagFilter ? 'white' : '#3f51b5',
                      '&:hover': {
                        backgroundColor: tagFilter ? '#303f9f' : 'rgba(63, 81, 181, 0.08)',
                      },
                    }}
                  >
                    {tagFilter ? tagFilter : 'Tags'}
                  </Button>
                </Box>
              </Tooltip>
              
              <Menu
                anchorEl={tagAnchorEl}
                open={Boolean(tagAnchorEl)}
                onClose={handleTagMenuClose}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    maxHeight: '300px'
                  }
                }}
              >
                {tagFilter && (
                  <MenuItem onClick={clearTagFilter} sx={{ color: '#f44336' }}>
                    Limpar filtro
                  </MenuItem>
                )}
                
                {allTags.length > 0 ? (
                  allTags.map((tag: string) => (
                    <MenuItem 
                      key={tag} 
                      onClick={() => handleTagSelect(tag)}
                      selected={tagFilter === tag}
                      sx={{
                        fontSize: '0.9rem',
                        minHeight: '40px',
                        '&.Mui-selected': {
                          backgroundColor: 'rgba(63, 81, 181, 0.1)',
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: 'rgba(63, 81, 181, 0.2)',
                        },
                      }}
                    >
                      {tag}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>Nenhuma tag encontrada</MenuItem>
                )}
              </Menu>
              
              <FilterListProfile orderBy={(v) => console.log(v)} />
            </Box>
          </>
        )}
      </div>

      <Button
        startIcon={<Add />}
        onClick={onAddNewContact}
        className="newProfile"
        sx={{
          '&:hover': {
            backgroundColor: '#3C454D !important',
            color: '#F9F9F9',
          },
        }}
      >
        Novo Contato
      </Button>
      
      {/* Status do filtro de tag */}
      {tagFilter && (
        <Box sx={{ mt: 2 }}>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: '#666'
            }}
          >
            <TagIcon fontSize="small" />
            <Typography variant="body2">
              Filtrando por tag: 
              <Chip 
                label={tagFilter} 
                size="small" 
                onDelete={clearTagFilter}
                sx={{ 
                  ml: 1,
                  backgroundColor: '#3f51b5',
                  color: 'white',
                  height: '24px',
                  '& .MuiChip-deleteIcon': {
                    color: 'white',
                    '&:hover': {
                      color: '#f1f1f1'
                    }
                  }
                }}
              />
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
import { useState } from "react";
import { Activity } from "../types/CardTypes";

interface UseActivitiesProps {
  cardActivities: Activity[];
  onAddActivity: (activity: Activity) => void;
  onUpdateActivity: (activity: Activity) => void;
  onRemoveActivity: (activityId: string) => void;
}

export const useActivities = ({
  cardActivities,
  onAddActivity,
  onUpdateActivity,
  onRemoveActivity,
}: UseActivitiesProps) => {
  const [newActivityForm, setNewActivityForm] = useState<
    Omit<Activity, "id" | "createdAt">
  >({
    type: "call",
    title: "",
    description: "",
    date: new Date().toISOString().split("T")[0],
    completed: false,
    userId: "current-user-id", // Normalmente viria de um contexto de autenticação
    userName: "Usuário Atual", // Normalmente viria de um contexto de autenticação
  });

  const [editActivityId, setEditActivityId] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<string>("all");
  const [filterCompleted, setFilterCompleted] = useState<string>("all");

  // Mock de usuário atual
  const currentUser = {
    id: "current-user-id",
    name: "Usuário Atual",
  };

  const handleNewActivityChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
  ) => {
    const { name, value } = e.target;
    if (name) {
      setNewActivityForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleNewActivityCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = e.target;
    setNewActivityForm((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const resetNewActivityForm = () => {
    setNewActivityForm({
      type: "call",
      title: "",
      description: "",
      date: new Date().toISOString().split("T")[0],
      completed: false,
      userId: currentUser.id,
      userName: currentUser.name,
    });
    setEditActivityId(null);
  };

  const saveActivity = () => {
    if (!newActivityForm.title.trim()) {
      return false; // Validação básica
    }

    const isEditing = editActivityId !== null;

    const activityData: Activity = {
      ...newActivityForm,
      id: isEditing ? editActivityId : `activity-${Date.now()}`,
      createdAt: isEditing
        ? cardActivities.find((a) => a.id === editActivityId)?.createdAt ||
          new Date().toISOString()
        : new Date().toISOString(),
    };

    if (isEditing) {
      onUpdateActivity(activityData);
    } else {
      onAddActivity(activityData);
    }

    resetNewActivityForm();
    return true;
  };

  const editActivity = (activity: Activity) => {
    setNewActivityForm({
      type: activity.type,
      title: activity.title,
      description: activity.description,
      date: activity.date,
      scheduled: activity.scheduled,
      completed: activity.completed,
      outcome: activity.outcome,
      userId: activity.userId,
      userName: activity.userName,
    });
    setEditActivityId(activity.id);
  };

  const deleteActivity = (activityId: string) => {
    if (window.confirm("Tem certeza que deseja excluir esta atividade?")) {
      onRemoveActivity(activityId);
    }
  };

  const completeActivity = (activity: Activity, completed: boolean) => {
    onUpdateActivity({
      ...activity,
      completed,
      outcome: completed ? activity.outcome || "Concluído" : undefined,
    });
  };

  // Filtrar atividades
  const filteredActivities = cardActivities.filter((activity) => {
    // Filtrar por tipo
    const typeMatch = filterType === "all" || activity.type === filterType;

    // Filtrar por status
    const completedMatch =
      filterCompleted === "all" ||
      (filterCompleted === "completed" && activity.completed) ||
      (filterCompleted === "pending" && !activity.completed);

    return typeMatch && completedMatch;
  });

  // Ordenar atividades - mais recentes primeiro
  const sortedActivities = [...filteredActivities].sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return {
    activities: sortedActivities,
    newActivityForm,
    editActivityId,
    filterType,
    filterCompleted,
    setFilterType,
    setFilterCompleted,
    handleNewActivityChange,
    handleNewActivityCheckbox,
    saveActivity,
    editActivity,
    deleteActivity,
    completeActivity,
    resetNewActivityForm,
  };
};

import React from 'react'
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Avatar,
  Chip
} from '@mui/material'
import { PersonAdd, Delete } from '@mui/icons-material'

const ContatosVinculadosSection = ({ 
  empresaContatos, 
  handleRemoveContato, 
  handleOpenContatosDialog 
}) => {
  return (
    <Box sx={{ mt: 3, mb: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        Contatos Vinculados ({empresaContatos.length})
      </Typography>
      <Divider sx={{ mb: 2 }} />

      {empresaContatos.length > 0 ? (
        <List>
          {empresaContatos.map((contato) => (
            <ListItem key={contato.id}>
              <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                {contato.name.charAt(0).toUpperCase()}
              </Avatar>
              <ListItemText
                primary={contato.name}
                secondary={
                  <Box>
                    <Typography variant="body2" component="span">
                      {contato.number}
                    </Typography>
                    {contato.tags && (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                        {contato.tags.split(',').map(tag => tag.trim()).filter(tag => tag).map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag}
                            size="small"
                            sx={{
                              height: '18px',
                              fontSize: '0.65rem',
                              backgroundColor: 'rgba(63, 81, 181, 0.08)',
                              color: '#3f51b5',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveContato(contato.id)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: 2 }}
        >
          Nenhum contato vinculado a esta empresa.
        </Typography>
      )}

      <Button
        variant="outlined"
        startIcon={<PersonAdd />}
        onClick={handleOpenContatosDialog}
        sx={{ mt: 2 }}
      >
        Vincular Contatos
      </Button>
    </Box>
  )
}

export default ContatosVinculadosSection
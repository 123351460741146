import React, { useState, useEffect } from 'react'

import { 
  Delete, 
  Save, 
  Cancel, 
  Person, 
  Email, 
  Phone, 
  Code, 
  AdminPanelSettings, 
  Info 
} from '@mui/icons-material'
import {
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Divider,
  Paper,
  Grid,
  InputAdornment,
  Avatar
} from '@mui/material'

import { User } from '../../../../types'
import * as S from '../../../ManagementScreen/styles'

interface UserFormProps {
  user: User | null
  isEdit: boolean
  onSave: (userData: User) => Promise<void>
  onDelete: (id: string) => Promise<void>
  onCancel: () => void
  loading: boolean
  error: string | null
}

export const UserForm: React.FC<UserFormProps> = ({
  user,
  isEdit,
  onSave,
  onDelete,
  onCancel,
  loading,
  error,
}) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState<string>('')
  const [integrationCode, setIntegrationCode] = useState('')
  const [active, setActive] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)

  // Validation errors
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [integrationCodeError, setIntegrationCodeError] = useState('')

  useEffect(() => {
    if (user) {
      setName(user.name)
      setEmail(user.email)
      setPhone(user.phone || '')
      setIntegrationCode(user.integrationCode)
      setActive(user.active)
      setIsAdmin(user.isAdmin)
    } else {
      // Reset form for new user
      setName('')
      setEmail('')
      setPhone('')
      setIntegrationCode('')
      setActive(true)
      setIsAdmin(false)
    }
  }, [user])

  const validateForm = (): boolean => {
    let isValid = true

    // Validate name
    if (!name.trim()) {
      setNameError('O nome é obrigatório')
      isValid = false
    } else {
      setNameError('')
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!email.trim()) {
      setEmailError('O email é obrigatório')
      isValid = false
    } else if (!emailRegex.test(email)) {
      setEmailError('Email inválido')
      isValid = false
    } else {
      setEmailError('')
    }

    // Validate integration code
    if (!integrationCode.trim()) {
      setIntegrationCodeError('O código de integração é obrigatório')
      isValid = false
    } else {
      setIntegrationCodeError('')
    }

    return isValid
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    const userData: User = {
      ...(user || {}),
      id: user?.id || '',
      name,
      email,
      phone: phone || null,
      integrationCode,
      active,
      isAdmin,
      role: user?.role || '',
      userId: user?.userId || '',
      createdAt: user?.createdAt || new Date().toISOString(),
    }

    await onSave(userData)
  }

  const handleDelete = () => {
    if (user && user.id) {
      onDelete(user.id)
    }
  }

  const formatPhoneNumber = (value: string) => {
    // Remove all non-numeric characters
    const phoneNumber = value.replace(/\D/g, '')

    // Format as (XX) XXXXX-XXXX for Brazilian phone numbers
    if (phoneNumber.length <= 2) {
      return phoneNumber
    } else if (phoneNumber.length <= 7) {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`
    } else {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7, 11)}`
    }
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value)
    setPhone(formattedPhone)
  }

  // Função para gerar iniciais do nome do usuário
  const getInitials = (userName: string): string => {
    if (!userName) return '';
    const names = userName.split(' ');
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase();
  };
  
  // Cor de fundo para o avatar
  const getAvatarColor = (userName: string): string => {
    if (!userName) return '#3f51b5';
    
    const colors = [
      '#1976d2', // blue
      '#388e3c', // green
      '#f57c00', // orange
      '#7b1fa2', // purple
      '#0288d1', // light blue
      '#3f51b5', // indigo
    ];
    
    // Hash simples para selecionar uma cor com base no nome
    const hash = userName.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[hash % colors.length];
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '85vh'  // Define uma altura máxima para o diálogo
      }}
    >
      <Box sx={{ p: '20px', flexShrink: 0 }}>
        <S.FormHeader>
          <Avatar 
            sx={{ 
              width: 60, 
              height: 60, 
              bgcolor: getAvatarColor(name), 
              fontSize: '1.8rem',
              fontWeight: 'bold'
            }}
          >
            {getInitials(name)}
          </Avatar>
          <Box sx={{ ml: 2 }}>
            <h1>{isEdit ? 'Editar Usuário' : 'Novo Usuário'}</h1>
            <p>{isEdit ? 'Atualize as informações do usuário' : 'Preencha os dados para criar um novo usuário'}</p>
          </Box>
        </S.FormHeader>
      </Box>

      {/* Container com scroll */}
      <Box 
        sx={{ 
          flex: 1, 
          overflowY: 'auto',
          p: '20px',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        <S.FormSection>
          <h2>
            <Person />
            Informações Básicas
          </h2>
          
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Nome Completo"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
                error={!!nameError}
                helperText={nameError}
                disabled={loading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person color="action" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', pt: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                      disabled={loading}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {active ? 'Usuário Ativo' : 'Usuário Inativo'}
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
                error={!!emailError}
                helperText={emailError}
                disabled={loading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email color="action" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                label="Telefone"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="(XX) XXXXX-XXXX"
                fullWidth
                disabled={loading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone color="action" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </S.FormSection>

        <S.FormSection>
          <h2>
            <AdminPanelSettings />
            Permissões e Integração
          </h2>
          
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Código de Integração"
                value={integrationCode}
                onChange={(e) => setIntegrationCode(e.target.value)}
                fullWidth
                required
                error={!!integrationCodeError}
                helperText={integrationCodeError || "Identificador único do usuário no sistema"}
                disabled={loading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Code color="action" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                height: '100%', 
                pt: 1,
                p: 2,
                border: '1px solid',
                borderColor: isAdmin ? 'rgba(63, 81, 181, 0.5)' : 'rgba(0, 0, 0, 0.23)',
                borderRadius: 1,
                backgroundColor: isAdmin ? 'rgba(63, 81, 181, 0.04)' : 'transparent',
                transition: 'all 0.2s'
              }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                      disabled={loading}
                      color="secondary"
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body1" sx={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                        <AdminPanelSettings sx={{ mr: 1, color: isAdmin ? 'secondary.main' : 'text.secondary' }} />
                        Administrador do Sistema
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Acesso a todas as funcionalidades
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </S.FormSection>

        {isEdit && (
          <S.FormSection>
            <h2>
              <Info />
              Informações do Sistema
            </h2>
            
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="ID do Usuário"
                  value={user?.userId || ''}
                  fullWidth
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  helperText="Identificador único do usuário"
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Data de Criação"
                  value={
                    user ? new Date(user.createdAt).toLocaleString('pt-BR') : ''
                  }
                  fullWidth
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  helperText="Data de criação do registro"
                />
              </Grid>
            </Grid>
          </S.FormSection>
        )}
      </Box>

      <Box sx={{ p: '20px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', flexShrink: 0 }}>
        <S.ButtonGroup>
          {isEdit && (
            <Button
              variant="text"
              color="error"
              startIcon={<Delete />}
              onClick={handleDelete}
              disabled={loading}
              className="delete-button"
            >
              Excluir
            </Button>
          )}
          <Box sx={{ flex: 1 }} />
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={onCancel}
            disabled={loading}
            className="cancel-button"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Save />}
            disabled={loading}
            className="save-button"
          >
            {isEdit ? 'Atualizar' : 'Salvar'}
          </Button>
        </S.ButtonGroup>
      </Box>
    </Box>
  )
}
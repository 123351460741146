// Arquivo: pages/ListEmpresas/index.jsx
import { useEffect, useState } from 'react'
import { llmEndpoint, rpaId } from 'infra'
import * as S from '../styles/styles'
import { apiUtils } from './apiUtils';

// Importação dos componentes criados
import EmpresaList from './EmpresaList'
import ContatosDialog from './ContatosDialog'
import useEmpresaModal from './hooks/useEmpresaModal'
import EmpresaFormModal from './EmpresaForm'
import { useParams } from 'react-router-dom'

// Interface para Contato
export interface Contato {
  id: string
  active: boolean
  name: string
  description: string
  number: string
  rpaId: string
  tags?: string
  email?: string
  type?: string
  companyId?: string
}

interface SearchParams {
  page: number;
  size: number;
  sort: string;
  direction: string;
  term?: string;
  name?: string;
  document?: string;
  description?: string;
  tags?: string;
  selectedTags?: string[]; // Nova propriedade para suportar múltiplas tags
}

// Interface para Endereço
export interface Endereco {
  id: string
  enterpriseId: string
  zipCode: string
  state: string
  city: string
  neighborhood: string
  street: string
  number: string
  complement?: string
}

// Interface para Empresa
export interface Empresa {
  id: string
  active: boolean
  createdAt: string
  updatedAt: string
  name: string
  description: string
  address?: Endereco
  document: string
  rpaId: string
  contacts: Contato[]
  tags?: string // Tags separadas por vírgula
  integrationCode?: string
  erpIntegrationId?: string
}

export const ListEmpresas = () => {
  const {
    isOpen,
    isEdit,
    empresaData,
    openCreateModal,
    openEditModal,
    closeModal,
    setEmpresaNome,
    setEmpresaDocumento,
    setEmpresaDescricao,
    setEmpresaEndereco,
    setEmpresaTags,
    handleRemoveContato,
    handleAddContato,
  } = useEmpresaModal()
  const [contatosDialogOpen, setContatosDialogOpen] = useState(false)

  const { rpaId } = useParams()


  // Estados para paginação e ordenação
  const [size, setSize] = useState(10)

  // Estado para a empresa selecionada
  const [selectedEmpresa, setSelectedEmpresa] = useState<Empresa | null>(null)

  // Estados para gerenciamento de contatos
  const [contatos, setContatos] = useState<Contato[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [contatosDisponiveis, setContatosDisponiveis] = useState<Contato[]>([])
  const [contatosSelecionados, setContatosSelecionados] = useState<string[]>([])
  const [searchContato, setSearchContato] = useState('')

  const [empresas, setEmpresas] = useState([]);
  // Estados para paginação de contatos
  const [contatoPage, setContatoPage] = useState(0);
  const [contatosHasMore, setContatosHasMore] = useState(true);
  const [loadingContatos, setLoadingContatos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [search, setSearch] = useState('');
  const [tagFilter, setTagFilter] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sort, setSort] = useState('name');
  const [direction, setDirection] = useState('asc');
  const [nameFilter, setNameFilter] = useState('');
  const [documentFilter, setDocumentFilter] = useState('');
  const [descriptionFilter, setDescriptionFilter] = useState('');
  const [availableTags, setAvailableTags] = useState([]);


  // Carrega os dados iniciais
  useEffect(() => {
    fetchEmpresas()
    fetchContatos('', 0, 10)
    fetchAllTags()
  }, [])

  const fetchEmpresas = async (pageNum = page, overrideParams = {}) => {
    if (!rpaId) return;
  
    setLoading(true);
    try {
      // Parâmetros base
      const params = {
        page: pageNum,
        size: 12,
        sort,
        direction,
        term: search,
        name: nameFilter,
        document: documentFilter,
        description: descriptionFilter,
        tags: tagFilter
      };
  
      // Mesclar com parâmetros de override
      const mergedParams = { ...params, ...overrideParams };
  
      // Usar o método dedicado para busca de empresas
      const result = await apiUtils.fetchEmpresas(rpaId, mergedParams);
      
      setEmpresas(result.content || []);
      setTotalPages(result.totalPages || 0);
      setTotalElements(result.totalElements || 0);
    } catch (error) {
      console.error('Erro ao buscar empresas:', error);
      // Você pode adicionar uma notificação de erro aqui
    } finally {
      setLoading(false);
    }
  };

  const fetchAllTags = async () => {
    if (!rpaId) return;

    setLoadingTags(true);
    try {
      const response = await fetch(`${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/tags`)
      const data = await response.json()
      setAvailableTags(data || []);
    } catch (error) {
      console.error('Erro ao buscar tags:', error);
    } finally {
      setLoadingTags(false);
    }
  };
  // Busca contatos da API com suporte a paginação e pesquisa
  const fetchContatos = async (term = '', page = 0, size = 10) => {
    if (!rpaId) return;
    
    try {
      // Usar apiUtils para aproveitar a manipulação de erros e configurações
      const response = await apiUtils.search(
        `/whatsapp/contacts/${rpaId}/search`, 
        {
          term,
          page,
          size,
          sort: 'name',
          direction: 'asc'
        }
      );
      
      if (response && response.content) {
        console.log('Contatos disponíveis:', response.content);
        
        // Se for a primeira página, substitui a lista, caso contrário, concatena
        if (page === 0) {
          setContatos(response.content);
        } else {
          setContatos(prevContatos => [...prevContatos, ...response.content]);
        }
        
        // Retorna os resultados da paginação para que possamos controlar
        return {
          content: response.content,
          last: response.last,
          totalPages: response.totalPages,
          totalElements: response.totalElements
        };
      }
      
      return null;
    } catch (error) {
      console.error('Error fetching contatos:', error);
      if (page === 0) {
        setContatos([]);
      }
      return null;
    }
  }

  // Função para processar o endereço string para objeto estruturado

  // Manipula o clique em um card de empresa (edição)
  const handleCardClick = (empresa: Empresa) => {
    console.log('Clicou na empresa:', empresa)
    setSelectedEmpresa(empresa)

    // Atualiza os dados no hook do modal
    setEmpresaNome(empresa.name)
    setEmpresaDescricao(empresa.description || '')
    setEmpresaTags(empresa.tags || '')

    // Inicializa o endereço estruturado
    if (empresa.address) {
      setEmpresaEndereco(empresa.address)
    } else {
      // Se não tiver endereço estruturado, inicializa vazio
      setEmpresaEndereco({
        zipCode: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
      })
    }

    // Adiciona os contatos
    empresa.contacts?.forEach((contato) => {
      handleAddContato(contato)
    })

    // Abre o modal em modo de edição
    openEditModal({
      id: empresa.id,
      nome: empresa.name,
      descricao: empresa.description || '',
      document: empresa.document || '',
      address: empresa.address || {
        zipCode: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
      },
      tags: empresa.tags || '',
      contatos: empresa.contacts || [],
    })
  }

  // Manipula a exclusão de uma empresa
  const handleDeleteEmpresa = async () => {
    if (selectedEmpresa) {
      try {
        const url = `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/${selectedEmpresa.id}`

        const response = await fetch(url, {
          method: 'DELETE',
        })
        if (response.ok) {
          console.log(`Deletando empresa com ID: ${selectedEmpresa.id}`)
          setEmpresas(empresas.filter((e) => e.id !== selectedEmpresa.id))
          closeModal()
        } else {
          console.error('Erro ao deletar empresa')
        }
      } catch (error) {
        console.error('Erro ao deletar empresa:', error)
      }
    }
  }

  // Inicializa o formulário para adicionar nova empresa
  function addNewEmpresa() {
    setSelectedEmpresa(null)
    openCreateModal()
  }

  // Manipula o envio do formulário (criar/editar empresa)
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const empresaDataAPI = {
      name: empresaData.nome,
      description: empresaData.descricao,
      tags: empresaData.tags,
      document: empresaData.document,
      rpaId: rpaId,
      contacts: empresaData.contatos.map((c) => c.id),
      // Inclui o objeto de endereço estruturado para persistência interna
      address: {
        zipCode: empresaData.address.zipCode,
        street: empresaData.address.street,
        number: empresaData.address.number,
        city: empresaData.address.city,
        neighborhood: empresaData.address.neighborhood,
        state: empresaData.address.state,
        complement: empresaData.address.complement,
      },
    }

    try {
      let url = `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}`
      let method = 'POST'

      // Se estiver editando, usa PUT e adiciona o ID
      if (isEdit && empresaData.id) {
        url = `${url}/${empresaData.id}`
        method = 'PUT'
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(empresaDataAPI),
      })

      if (response.ok) {
        const data = await response.json()
        setEmpresas([...empresas, data])

        // Adiciona o campo de endereço estruturado à resposta da API
        const empresaProcessada = {
          ...data,
          endereco: empresaData.address,
        }

        if (isEdit) {
          // Atualiza a empresa na lista
          setEmpresas(
            empresas.map((e) => (e.id === data.id ? empresaProcessada : e)),
          )
        } else {
          // Adiciona nova empresa à lista
          setEmpresas([...empresas, empresaProcessada])
        }

        closeModal()
      } else {
        console.error('Erro ao salvar empresa')
      }
    } catch (error) {
      console.error('Erro ao salvar empresa:', error)
    }
  }

  // Abre o diálogo para selecionar contatos e carrega a primeira página
  const handleOpenContatosDialog = async () => {
    // Resetar estados de paginação e busca
    setContatoPage(0);
    setContatosHasMore(true);
    setSearchContato('');
    setContatosSelecionados([]);
    setLoadingContatos(true);
    
    // Carregar a primeira página de contatos
    const result = await fetchContatos('', 0, 10);
    
    if (result) {
      // Obter IDs de contatos já adicionados à empresa
      const idsContatosJaAdicionados = empresaData.contatos?.map((c) => c.id) || [];
      
      // Filtrar contatos que ainda não foram adicionados
      const disponiveis = Array.isArray(result.content) 
        ? result.content.filter((c) => !idsContatosJaAdicionados.includes(c.id))
        : [];

      setContatosDisponiveis(disponiveis);
      setContatosHasMore(!result.last);
    } else {
      setContatosDisponiveis([]);
      setContatosHasMore(false);
    }
    
    setLoadingContatos(false);
    setDialogOpen(true);
  }

  // Manipula a seleção de contatos no diálogo
  const handleToggleContato = (contatoId: string) => {
    setContatosSelecionados((prev) => {
      if (prev.includes(contatoId)) {
        return prev.filter((id) => id !== contatoId)
      } else {
        return [...prev, contatoId]
      }
    })
  }

  // Adiciona os contatos selecionados à empresa
  const handleAddContatosSelecionados = () => {
    if (!Array.isArray(contatosDisponiveis) || contatosDisponiveis.length === 0) {
      setDialogOpen(false)
      return
    }
    
    const novosContatos = contatosDisponiveis.filter((c) =>
      contatosSelecionados.includes(c.id)
    )

    // Adiciona cada contato selecionado usando o hook
    novosContatos.forEach((contato) => {
      handleAddContato(contato)
    })

    setDialogOpen(false)
  }

  // Função para carregar mais contatos (paginação)
  const loadMoreContatos = async () => {
    if (!contatosHasMore || loadingContatos) return;
    
    setLoadingContatos(true);
    const nextPage = contatoPage + 1;
    
    const result = await fetchContatos(searchContato, nextPage, 10);
    
    if (result) {
      // Obter IDs de contatos já adicionados à empresa
      const idsContatosJaAdicionados = empresaData.contatos?.map((c) => c.id) || [];
      
      // Filtrar contatos que ainda não foram adicionados
      const novosContatos = Array.isArray(result.content) 
        ? result.content.filter((c) => !idsContatosJaAdicionados.includes(c.id))
        : [];

      // Adicionar os novos contatos à lista existente
      setContatosDisponiveis(prev => [...prev, ...novosContatos]);
      setContatoPage(nextPage);
      setContatosHasMore(!result.last);
    } else {
      setContatosHasMore(false);
    }
    
    setLoadingContatos(false);
  };
  
  // Função para buscar contatos ao digitar no campo de busca
  const handleSearchContatos = async (term: string) => {
    setSearchContato(term);
    setContatoPage(0);
    setLoadingContatos(true);
    
    const result = await fetchContatos(term, 0, 10);
    
    if (result) {
      // Obter IDs de contatos já adicionados à empresa
      const idsContatosJaAdicionados = empresaData.contatos?.map((c) => c.id) || [];
      
      // Filtrar contatos que ainda não foram adicionados
      const disponiveis = Array.isArray(result.content) 
        ? result.content.filter((c) => !idsContatosJaAdicionados.includes(c.id))
        : [];

      setContatosDisponiveis(disponiveis);
      setContatosHasMore(!result.last);
    } else {
      setContatosDisponiveis([]);
      setContatosHasMore(false);
    }
    
    setLoadingContatos(false);
  };
  
  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">
            Gerenciamento de Empresas
          </h1>
          <p style={{ marginTop: '16px' }}>
            Cadastre e gerencie suas empresas e vincule contatos a elas.
          </p>
        </S.WrapperHeader>
      </S.Container>

      <S.Content>
        <S.WrapperSerach>
        <EmpresaList
        empresas={empresas}
        search={search}
        setSearch={setSearch}
        tagFilter={tagFilter}
        setTagFilter={setTagFilter}
        handleCardClick={handleCardClick}
        addNewEmpresa={addNewEmpresa}
        page={page}
        totalPages={totalPages}
        totalElements={totalElements}
        fetchEmpresas={fetchEmpresas}
        setPage={setPage}
        sort={sort}
        direction={direction}
        setSort={setSort}
        setDirection={setDirection}
        nameFilter={nameFilter}
        setNameFilter={setNameFilter}
        documentFilter={documentFilter}
        setDocumentFilter={setDocumentFilter}
        descriptionFilter={descriptionFilter}
        setDescriptionFilter={setDescriptionFilter}
        availableTags={availableTags}
        loadingTags={loadingTags}
      />
        </S.WrapperSerach>
      </S.Content>

      {/* Modal do Formulário de Empresa */}
      <EmpresaFormModal
        open={isOpen}
        onClose={closeModal}
        isEdit={isEdit}
        empresaNome={empresaData.nome}
        setEmpresaNome={setEmpresaNome}
        empresaDocumento={empresaData.document}
        setEmpresaDocumento={setEmpresaDocumento}
        empresaDescricao={empresaData.descricao}
        setEmpresaDescricao={setEmpresaDescricao}
        endereco={empresaData.address}
        setEndereco={setEmpresaEndereco}
        empresaTags={empresaData.tags}
        setEmpresaTags={setEmpresaTags}
        empresaContatos={empresaData.contatos}
        handleRemoveContato={handleRemoveContato}
        handleOpenContatosDialog={handleOpenContatosDialog}
        handleDeleteEmpresa={handleDeleteEmpresa}
        handleSubmit={handleSubmit}
      />

      {/* Diálogo para seleção de contatos */}
      <ContatosDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        contatos={contatosDisponiveis}
        contatosSelecionados={contatosSelecionados}
        handleToggleContato={handleToggleContato}
        handleAddContatosSelecionados={handleAddContatosSelecionados}
        searchContato={searchContato}
        setSearchContato={setSearchContato}
        handleSearchContatos={handleSearchContatos}
        loadMoreContatos={loadMoreContatos}
        loading={loadingContatos}
        hasMore={contatosHasMore}
      />
    </>
  )
}

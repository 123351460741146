import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { TemplateCard } from './TemplateCard'
import { Template } from './types'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

interface TemplatesGridProps {
  templates: Template[]
  currentPage: number
  templatesPerPage: number
  totalItems: number
  isLoading: boolean
  onPageChange: (_event: React.ChangeEvent<unknown> | null, page: number) => void
  onTemplateClick: (template: Template) => void
  onSortChange?: (field: string) => void
  sortField?: string
  sortDirection?: string
}

export const TemplatesGrid = ({
  templates,
  currentPage,
  templatesPerPage,
  totalItems,
  isLoading,
  onPageChange,
  onTemplateClick,
  onSortChange,
  sortField = 'createdAt',
  sortDirection = 'desc'
}: TemplatesGridProps) => {
  
  // Renderiza mensagem quando não há templates
  if (templates.length === 0 && !isLoading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '40px 0' }}>
        <Typography variant="h6">Nenhum template encontrado</Typography>
        <Typography variant="body1">Tente outros termos de busca ou crie um novo template.</Typography>
      </Box>
    )
  }

  // Função para renderizar o ícone de ordenação
  const renderSortIcon = (field: string) => {
    if (sortField === field) {
      return sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
    }
    return null
  }

  return (
    <Box>
      {/* Cabeçalho de ordenação */}
      {onSortChange && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Typography variant="body2" sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
            Ordenar por:
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                cursor: 'pointer',
                bgcolor: sortField === 'name' ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                transition: 'background-color 0.2s',
                '&:hover': {
                  bgcolor: 'rgba(63, 81, 181, 0.2)',
                }
              }}
              onClick={() => onSortChange('name')}
            >
              <Typography variant="body2">Nome</Typography>
              {renderSortIcon('name')}
            </Box>
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                cursor: 'pointer',
                bgcolor: sortField === 'type' ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                transition: 'background-color 0.2s',
                '&:hover': {
                  bgcolor: 'rgba(63, 81, 181, 0.2)',
                }
              }}
              onClick={() => onSortChange('type')}
            >
              <Typography variant="body2">Tipo</Typography>
              {renderSortIcon('type')}
            </Box>
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                cursor: 'pointer',
                bgcolor: sortField === 'createdAt' ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                transition: 'background-color 0.2s',
                '&:hover': {
                  bgcolor: 'rgba(63, 81, 181, 0.2)',
                }
              }}
              onClick={() => onSortChange('createdAt')}
            >
              <Typography variant="body2">Data de criação</Typography>
              {renderSortIcon('createdAt')}
            </Box>
          </Box>
        </Box>
      )}

      {/* Grid de templates */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '20px',
          mb: 4,
          minHeight: '200px'
        }}
      >
        {templates.map((template) => (
          <TemplateCard
            key={template.id}
            template={template}
            onClick={onTemplateClick}
          />
        ))}
      </Box>
    </Box>
  )
}
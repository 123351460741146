// AllNotifications.jsx - Página completa de notificações
import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Avatar, 
  Chip, 
  IconButton,
  Divider,
  Button,
  TextField,
  InputAdornment,
  Paper
} from '@mui/material';
import {
  NotificationsNone as NotificationIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Chat as ChatIcon,
  Assignment as TaskIcon,
  Event as EventIcon,
  ViewKanban as KanbanIcon,
} from '@mui/icons-material';

import { useNotifications, NOTIFICATION_TYPES } from './NotificationContext';

export const AllNotifications = () => {
  const { notifications, markAsRead, markAllAsRead } = useNotifications();
  const [selectedTab, setSelectedTab] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const SoundToggleButton = () => {
    const { soundEnabled, toggleSound } = useNotifications();
    
    return (
      <Button
        variant="outlined"
        size="small"
        startIcon={soundEnabled ? 
          <VolumeUpIcon fontSize="small" /> : 
          <VolumeOffIcon fontSize="small" />
        }
        onClick={toggleSound}
        sx={{ 
          ml: 1,
          textTransform: 'none',
          borderColor: 'rgba(0,0,0,0.12)',
          color: 'text.secondary'
        }}
      >
        {soundEnabled ? 'Som ativado' : 'Som desativado'}
      </Button>
    );
  };

  // Função para filtrar notificações
  const getFilteredNotifications = () => {
    let filtered = [...notifications];
    
    // Filtrar por tipo
    if (selectedTab !== 'all') {
      filtered = filtered.filter(notification => notification.type === selectedTab);
    }
    
    // Filtrar por texto de busca
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(notification => 
        notification.title.toLowerCase().includes(query) || 
        notification.message.toLowerCase().includes(query)
      );
    }
    
    return filtered;
  };
  
  // Obter notificações filtradas
  const filteredNotifications = getFilteredNotifications();
  
  // Renderiza o ícone baseado no tipo
  const renderTypeIcon = (type) => {
    switch (type) {
      case NOTIFICATION_TYPES.CHAT:
        return <ChatIcon sx={{ color: '#1976d2' }} />;
      case NOTIFICATION_TYPES.TASK:
        return <TaskIcon sx={{ color: '#e53935' }} />;
      case NOTIFICATION_TYPES.CARD:
        return <KanbanIcon sx={{ color: '#43a047' }} />;
      case NOTIFICATION_TYPES.ACTIVITY:
        return <EventIcon sx={{ color: '#fb8c00' }} />;
      default:
        return <NotificationIcon />;
    }
  };
  
  // Formata o texto do tipo
  const formatType = (type) => {
    switch (type) {
      case NOTIFICATION_TYPES.CHAT:
        return 'Mensagem';
      case NOTIFICATION_TYPES.TASK:
        return 'Tarefa';
      case NOTIFICATION_TYPES.CARD:
        return 'Card';
      case NOTIFICATION_TYPES.ACTIVITY:
        return 'Atividade';
      default:
        return 'Notificação';
    }
  };
  
  // Navegar para a entidade ao clicar
  const handleNotificationClick = (notification) => {
    if (!notification.read) {
      markAsRead(notification.id);
    }
    
    if (window.navigateToEntity) {
      window.navigateToEntity(notification.type, notification.entityId);
    }
  };
  
  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h5" fontWeight={600}>
          Notificações
        </Typography>
        
        <Button 
          variant="outlined" 
          color="primary" 
          startIcon={<CheckCircleIcon />}
          onClick={markAllAsRead}
          disabled={!notifications.some(n => !n.read)}
        >
          Marcar todas como lidas
        </Button>
      </Box>
      
      <Paper 
        elevation={0} 
        sx={{ 
          mb: 3, 
          p: 2, 
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            fullWidth
            placeholder="Buscar notificações..."
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
            sx={{ flex: 1 }}
          />
          
          <Button 
            variant="outlined" 
            startIcon={<FilterIcon />}
            sx={{ height: 40 }}
          >
            Filtrar
          </Button>
        </Box>
      </Paper>
      
      <Tabs 
        value={selectedTab} 
        onChange={(_, value) => setSelectedTab(value)}
        sx={{ 
          mb: 2,
          '& .MuiTab-root': { 
            textTransform: 'none',
            minWidth: 'auto',
            px: 2
          }
        }}
      >
        <Tab label="Todas" value="all" />
        <Tab label="Mensagens" value={NOTIFICATION_TYPES.CHAT} />
        <Tab label="Tarefas" value={NOTIFICATION_TYPES.TASK} />
        <Tab label="Cards" value={NOTIFICATION_TYPES.CARD} />
        <Tab label="Atividades" value={NOTIFICATION_TYPES.ACTIVITY} />
      </Tabs>
      
      <Paper 
        elevation={0} 
        sx={{ 
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
        {filteredNotifications.length === 0 ? (
          <Box sx={{ py: 6, textAlign: 'center' }}>
            <NotificationIcon sx={{ fontSize: 48, color: 'text.disabled', mb: 2 }} />
            <Typography color="text.secondary">
              Nenhuma notificação encontrada
            </Typography>
          </Box>
        ) : (
          <List disablePadding>
            {filteredNotifications.map((notification, idx) => (
              <React.Fragment key={notification.id}>
                {idx > 0 && <Divider component="li" />}
                <ListItem 
                  sx={{ 
                    py: 2,
                    px: 3,
                    bgcolor: notification.read ? 'transparent' : 'rgba(25, 118, 210, 0.05)',
                    '&:hover': {
                      bgcolor: 'rgba(0, 0, 0, 0.03)'
                    },
                    cursor: 'pointer'
                  }}
                  onClick={() => handleNotificationClick(notification)}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'rgba(25, 118, 210, 0.1)' }}>
                      {renderTypeIcon(notification.type)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                        <Typography 
                          variant="subtitle1" 
                          fontWeight={notification.read ? 400 : 600}
                          sx={{ mr: 1 }}
                        >
                          {notification.title}
                        </Typography>
                        <Chip 
                          label={formatType(notification.type)} 
                          size="small"
                          sx={{ height: 20, fontSize: '0.75rem' }}
                        />
                        {!notification.read && (
                          <Box 
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: '50%',
                              bgcolor: 'primary.main',
                              ml: 1
                            }}
                          />
                        )}
                      </Box>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" sx={{ color: 'text.primary', mb: 0.5 }}>
                          {notification.message}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {new Date(notification.timestamp).toLocaleString()}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        )}
      </Paper>
      
      {filteredNotifications.length > 0 && (
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Button variant="outlined" color="primary">
            Carregar mais notificações
          </Button>
        </Box>
      )}
      
      {/* Seção de estatísticas de notificações */}
      {filteredNotifications.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" fontWeight={600} gutterBottom>
            Estatísticas de Notificações
          </Typography>
          
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
            gap: 2,
            mt: 2
          }}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 2,
                border: '1px solid rgba(0,0,0,0.08)',
                borderRadius: 2,
                textAlign: 'center'
              }}
            >
              <Typography variant="h3" color="primary" fontWeight={600}>
                {notifications.length}
              </Typography>
              <Typography color="text.secondary">
                Total
              </Typography>
            </Paper>
            
            <Paper 
              elevation={0}
              sx={{ 
                p: 2,
                border: '1px solid rgba(0,0,0,0.08)',
                borderRadius: 2,
                textAlign: 'center'
              }}
            >
              <Typography variant="h3" color="error" fontWeight={600}>
                {notifications.filter(n => !n.read).length}
              </Typography>
              <Typography color="text.secondary">
                Não lidas
              </Typography>
            </Paper>
            
            <Paper 
              elevation={0}
              sx={{ 
                p: 2,
                border: '1px solid rgba(0,0,0,0.08)',
                borderRadius: 2,
                textAlign: 'center'
              }}
            >
              <Typography variant="h3" color="success.main" fontWeight={600}>
                {notifications.filter(n => n.type === NOTIFICATION_TYPES.CHAT).length}
              </Typography>
              <Typography color="text.secondary">
                Mensagens
              </Typography>
            </Paper>
            
            <Paper 
              elevation={0}
              sx={{ 
                p: 2,
                border: '1px solid rgba(0,0,0,0.08)',
                borderRadius: 2,
                textAlign: 'center'
              }}
            >
              <Typography variant="h3" color="warning.main" fontWeight={600}>
                {notifications.filter(n => n.type === NOTIFICATION_TYPES.TASK).length}
              </Typography>
              <Typography color="text.secondary">
                Tarefas
              </Typography>
            </Paper>
          </Box>
        </Box>
      )}
    </Box>
  );
};
// Formatação de valores monetários
export const formatarMoeda = (valor: string): string => {
  // Remove caracteres não numéricos
  let numerico = valor.replace(/\D/g, "");

  // Se estiver vazio, retorna vazio
  if (numerico === "") return "";

  // Converte para número e formata como moeda
  const numero = parseInt(numerico) / 100;
  return numero.toFixed(2);
};

// Extrai tags de uma string separada por vírgulas
export const extractTags = (tagString?: string): string[] => {
  if (!tagString) return [];
  return tagString
    .split(",")
    .map((tag) => tag.trim())
    .filter((tag) => tag);
};

// Converte uma lista de produtos em um conjunto de categorias únicas
export const extractUniqueCategories = (
  produtos: Array<{ categoria: string }>,
): string[] => {
  const categorySet = new Set<string>();

  produtos.forEach((produto) => {
    if (produto.categoria) {
      categorySet.add(produto.categoria);
    }
  });

  return Array.from(categorySet).sort();
};

// Converte uma lista de produtos em um conjunto de tags únicas
export const extractUniqueTags = (
  produtos: Array<{ tags?: string }>,
): string[] => {
  const tagSet = new Set<string>();

  produtos.forEach((produto) => {
    extractTags(produto.tags).forEach((tag) => {
      if (tag) tagSet.add(tag);
    });
  });

  return Array.from(tagSet).sort();
};

// Converte valor de input para número (tratando formatação de moeda)
export const parseInputValue = (value: string): number => {
  return parseFloat(value.replace(/[^\d,.]/g, "").replace(",", "."));
};

// Prepara dados do formulário para envio à API
export const prepareProdutoData = (
  formData: Record<string, any>,
): Record<string, any> => {
  return {
    nome: formData.nome,
    descricao: formData.descricao,
    valor:
      typeof formData.valor === "string"
        ? parseFloat(formData.valor)
        : formData.valor,
    codigo: formData.codigo,
    categoria: formData.categoria,
    isServico: formData.isServico,
    estoque: formData.isServico
      ? undefined
      : formData.estoque
        ? typeof formData.estoque === "string"
          ? parseInt(formData.estoque)
          : formData.estoque
        : undefined,
    unidadeMedida: formData.unidadeMedida || undefined,
    marca: formData.marca || undefined,
    fornecedor: formData.fornecedor || undefined,
    tags: formData.tags,
  };
};

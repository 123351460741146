import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  CircularProgress,
  SelectChangeEvent,
} from '@mui/material';
import {
  Close as CloseIcon,
  Check as CheckIcon,
  Cancel as CancelIcon,
  Phone as PhoneIcon,
  EventNote as EventIcon,
  Email as EmailIcon,
  WhatsApp as WhatsAppIcon,
  More as MoreIcon,
  Event as CalendarIcon,
  EventAvailable as ScheduleIcon,
  Note as NoteIcon,
} from '@mui/icons-material';
import { Activity } from './types/CardTypes'; // Ajuste o caminho

interface NewActivityDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (activity: Omit<Activity, 'id' | 'createdAt'>) => void;
  initialData?: Omit<Activity, 'id' | 'createdAt'>;
  isEditing?: boolean;
}

const NewActivityDialog: React.FC<NewActivityDialogProps> = ({
  open,
  onClose,
  onSave,
  initialData,
  isEditing = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Omit<Activity, 'id' | 'createdAt'>>(
    initialData || {
      type: 'call',
      title: '',
      description: '',
      date: new Date().toISOString().split('T')[0],
      completed: false,
      userId: 'current-user-id', // Deve vir do contexto de autenticação
      userName: 'Usuário Atual', // Deve vir do contexto de autenticação
    }
  );

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    if (name) {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
      
      // Limpar erro
      if (errors[name as string]) {
        setErrors(prev => ({ ...prev, [name]: '' }));
      }
    }
  };

  // Novo manipulador específico para Select
  const handleSelectChange = (e: SelectChangeEvent<any>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    
    // Limpar erro
    if (errors[name as string]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    
    if (!formData.title.trim()) {
      newErrors.title = 'Título é obrigatório';
    }
    
    if (!formData.date) {
      newErrors.date = 'Data é obrigatória';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      
      // Simulação de salvamento assíncrono
      setTimeout(() => {
        onSave(formData);
        setLoading(false);
        onClose();
      }, 500);
    }
  };

  // Ícone baseado no tipo de atividade
  const getActivityIcon = (type: string) => {
    switch (type) {
      case 'call':
        return <PhoneIcon fontSize="small" />;
      case 'meeting':
        return <EventIcon fontSize="small" />;
      case 'email':
        return <EmailIcon fontSize="small" />;
      case 'whatsapp':
        return <WhatsAppIcon fontSize="small" />;
      default:
        return <MoreIcon fontSize="small" />;
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#f8f9fa',
          borderBottom: '1px solid #e0e0e0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
          {isEditing ? 'Editar Atividade' : 'Nova Atividade'}
        </Typography>
        <IconButton onClick={onClose} size="small" sx={{ color: '#757575' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', gap: 2, mb: 2, mt: 2 }}>
          <FormControl fullWidth variant="outlined" sx={{ flex: 3 }}>
            <InputLabel id="activity-type-label">Tipo</InputLabel>
            <Select
              labelId="activity-type-label"
              name="type"
              value={formData.type}
              onChange={handleSelectChange} // Use o manipulador específico para Select
              label="Tipo"
              startAdornment={
                <InputAdornment position="start">
                  {getActivityIcon(formData.type)}
                </InputAdornment>
              }
            >
              <MenuItem value="call" sx={{ display: 'flex', alignItems: 'center' }}>
                <PhoneIcon fontSize="small" sx={{ mr: 1 }} />
                Ligação
              </MenuItem>
              <MenuItem value="meeting" sx={{ display: 'flex', alignItems: 'center' }}>
                <EventIcon fontSize="small" sx={{ mr: 1 }} />
                Reunião
              </MenuItem>
              <MenuItem value="email" sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon fontSize="small" sx={{ mr: 1 }} />
                Email
              </MenuItem>
              <MenuItem value="whatsapp" sx={{ display: 'flex', alignItems: 'center' }}>
                <WhatsAppIcon fontSize="small" sx={{ mr: 1 }} />
                WhatsApp
              </MenuItem>
              <MenuItem value="other" sx={{ display: 'flex', alignItems: 'center' }}>
                <MoreIcon fontSize="small" sx={{ mr: 1 }} />
                Outro
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ flex: 2 }}>
            <InputLabel id="activity-status-label">Status</InputLabel>
            <Select
              labelId="activity-status-label"
              name="completed"
              value={formData.completed ? "true" : "false"} // Converta para string
              onChange={(e) => {
                // Converta de volta para boolean
                setFormData(prev => ({
                  ...prev,
                  completed: e.target.value === "true"
                }));
              }}
              label="Status"
            >
              <MenuItem value="false">Pendente</MenuItem>
              <MenuItem value="true">Concluído</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <TextField
          name="title"
          label="Título da Atividade"
          fullWidth
          variant="outlined"
          value={formData.title}
          onChange={handleChange}
          error={!!errors.title}
          helperText={errors.title}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <NoteIcon color="action" fontSize="small" />
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            name="date"
            label="Data"
            type="date"
            fullWidth
            variant="outlined"
            value={formData.date}
            onChange={handleChange}
            error={!!errors.date}
            helperText={errors.date}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarIcon color="action" fontSize="small" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            name="scheduled"
            label="Agendado para"
            type="datetime-local"
            fullWidth
            variant="outlined"
            value={formData.scheduled || ''}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ScheduleIcon color="action" fontSize="small" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <TextField
          name="description"
          label="Descrição"
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />

        {formData.completed && (
          <TextField
            name="outcome"
            label="Resultado/Observações"
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            value={formData.outcome || ''}
            onChange={handleChange}
            placeholder="Descreva o resultado desta atividade"
            sx={{ mb: 2 }}
          />
        )}
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          backgroundColor: '#f8f9fa',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Button
          onClick={onClose}
          color="inherit"
          variant="outlined"
          startIcon={<CancelIcon />}
          sx={{ mr: 1 }}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <CheckIcon />
            )
          }
        >
          {isEditing ? 'Atualizar' : 'Salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewActivityDialog;
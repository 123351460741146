import { useEffect, useRef, useState } from 'react'
import { Box, IconButton, LinearProgress } from '@mui/material'
import { Pause, PlayArrow } from '@mui/icons-material'

export const WhatsAppAudioPlayer = ({ src, isBot }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const audioRef = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    // Configurar eventos do áudio
    const audio = audioRef.current
    
    const setAudioData = () => {
      // Verificar se a duração é um valor válido
      if (audio.duration && !isNaN(audio.duration) && isFinite(audio.duration)) {
        setDuration(audio.duration)
      } else {
        setDuration(0)
      }
      setIsLoaded(true)
    }
    
    const setAudioTime = () => {
      // Verificar se o tempo atual é um valor válido
      if (!isNaN(audio.currentTime) && isFinite(audio.currentTime)) {
        setCurrentTime(audio.currentTime)
      }
    }
    
    const handleAudioEnd = () => {
      setIsPlaying(false)
      setCurrentTime(0)
    }
    
    const handleDurationChange = () => {
      // Capturar mudanças na duração após carregamento inicial
      if (audio.duration && !isNaN(audio.duration) && isFinite(audio.duration)) {
        setDuration(audio.duration)
      }
    }

    // Adicionar event listeners
    audio.addEventListener('loadeddata', setAudioData)
    audio.addEventListener('loadedmetadata', setAudioData) // Adiciona este evento para melhor detecção de duração
    audio.addEventListener('durationchange', handleDurationChange) // Adiciona este evento para capturar mudanças na duração
    audio.addEventListener('timeupdate', setAudioTime)
    audio.addEventListener('ended', handleAudioEnd)
    
    // Tentar carregar os metadados manualmente
    audio.load();
    
    // Limpar event listeners
    return () => {
      audio.removeEventListener('loadeddata', setAudioData)
      audio.removeEventListener('loadedmetadata', setAudioData)
      audio.removeEventListener('durationchange', handleDurationChange)
      audio.removeEventListener('timeupdate', setAudioTime)
      audio.removeEventListener('ended', handleAudioEnd)
    }
  }, [src]) // Adiciona src como dependência para recarregar quando a fonte mudar

  const togglePlay = () => {
    const audio = audioRef.current
    if (isPlaying) {
      audio.pause()
      setIsPlaying(false)
    } else {
      // Use uma promise para lidar com possíveis erros de reprodução
      const playPromise = audio.play()
      
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsPlaying(true)
          })
          .catch(error => {
            console.error("Erro ao reproduzir áudio:", error)
            setIsPlaying(false)
          })
      }
    }
  }

  // Formatar tempo em mm:ss
  const formatTime = (time) => {
    if (!time || isNaN(time) || !isFinite(time)) return '00:00'
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  // Calcular progresso com verificação de segurança
  const calculateProgress = () => {
    if (!duration || duration === 0 || isNaN(duration) || !isFinite(duration)) {
      return 0
    }
    const progress = (currentTime / duration) * 100
    return isNaN(progress) || !isFinite(progress) ? 0 : progress
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isBot ? '#e5e7eb' : 'transparent',
        borderRadius: '16px',
        padding: '4px',
        width: '100%',
        minWidth: '180px',
      }}
    >
      <IconButton
        onClick={togglePlay}
        size="small"
        sx={{
          color: isBot ? '#4f46e5' : 'white',
          backgroundColor: isBot ? 'white' : 'rgba(255,255,255,0.2)',
          '&:hover': {
            backgroundColor: isBot ? '#f3f4f6' : 'rgba(255,255,255,0.3)',
          },
          mr: 1,
        }}
      >
        {isPlaying ? (
          <Pause fontSize="small" />
        ) : (
          <PlayArrow fontSize="small" />
        )}
      </IconButton>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {/* Visualização de onda/progresso */}
        <LinearProgress
          variant="determinate"
          value={calculateProgress()}
          sx={{
            height: 4,
            borderRadius: 2,
            backgroundColor: isBot
              ? 'rgba(79, 70, 229, 0.2)'
              : 'rgba(255, 255, 255, 0.3)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: isBot ? '#4f46e5' : 'white',
            },
          }}
        />
        {/* Duração do áudio */}
        <Box
          sx={{
            alignSelf: 'flex-end',
            mt: 0.5,
            fontSize: '0.75rem',
            color: isBot ? 'text.secondary' : 'rgba(255,255,255,0.8)',
          }}
        >
          {formatTime(currentTime)} / {formatTime(duration)}
        </Box>
      </Box>
      {/* Elemento de áudio oculto */}
      <audio ref={audioRef} src={src} preload="metadata" />
    </Box>
  )
}
import React from 'react'
import { TextField } from '@mui/material'

export const TemplateSelector = ({
  modalSelectedTemplate,
  setModalSelectedTemplate,
  templates,
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Template
      </label>
      <TextField
        select
        fullWidth
        value={modalSelectedTemplate}
        onChange={(e) => {
          setModalSelectedTemplate(e.target.value)
        }}
        variant="outlined"
        size="small"
        SelectProps={{
          native: true,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
      >
        <option value="">Selecione um template</option>
        {templates?.map((template) => (
          <option key={template.id} value={template.id}>
            {template.name}
          </option>
        ))}
      </TextField>
    </div>
  )
}
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { DragDropContext } from 'react-beautiful-dnd'
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Chip,
  Snackbar,
  Alert,
} from '@mui/material'
import { Add as AddIcon, Settings as SettingsIcon } from '@mui/icons-material'

import {
  CardFormData,
  BoardType,
} from './components/CardDialog/types/CardTypes'
import KanbanColumn from './KanbanColumn'
import CardDialog from '../kanban/components/CardDialog'
import KanbanSettings from './KanbanSettings'
import KanbanFilter from './KanbanFilter'
import useKanbanData from './hooks/useKanbanData'
import { fetchBoardConfig, updateBoardConfig } from './services/kanbanApi'
import { llmEndpoint } from 'infra'
import { useParams } from 'react-router-dom'

// Definindo tipos para o TypeScript
type AlertType = 'info' | 'success' | 'error' | 'warning'

type NotificationType = {
  open: boolean
  message: string
  type: AlertType
}

type ColumnType = {
  id: string
  title: string
  assignedTo?: any
  closed: boolean
  integrations?: Array<any>
  [key: string]: any
}

type CardType = {
  id: string
  title: string
  columnId: string
  [key: string]: any
}

type BoardConfigType = {
  id: string
  title: string
  type: string
  columns: ColumnType[]
  [key: string]: any
}

interface KanbanProps {
  boardType?: string
  onCardMove?: (data?: any) => void
  onCardAction?: (action?: string, card?: any) => void
  onIntegrationTrigger?: (data?: any) => void
}

const KanbanApp: React.FC<KanbanProps> = (props) => {
  // Definir valores padrão usando desestruturação dentro da função
  const {
    boardType = 'lead',
    onCardMove = () => {},
    onCardAction = () => {},
    onIntegrationTrigger = () => {},
  } = props

  // TODOS os hooks devem estar aqui no topo, na mesma ordem em todas as renderizações
  const [boardConfig, setBoardConfig] = useState<BoardConfigType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [openCardDialog, setOpenCardDialog] = useState<boolean>(false) // Vamos usar apenas este para o diálogo
  const [openSettingsDialog, setOpenSettingsDialog] = useState<boolean>(false)
  const [selectedCard, setSelectedCard] = useState<CardType | null>(null)
  const [filteredCards, setFilteredCards] = useState<CardType[]>([])
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [notification, setNotification] = useState<NotificationType>({
    open: false,
    message: '',
    type: 'info',
  })

  // Removemos a duplicada declaração de dialogOpen que estava no final do componente

  // Usando nosso custom hook para gerenciar os dados do kanban
  const {
    columns,
    cards,
    moveCard,
    addCard,
    updateCard,
    deleteCard,
    reloadCards,
  } = useKanbanData(boardType)

  // Inicializa os cards filtrados com todos os cards quando cards mudam
  // Usamos useMemo para evitar recalcular a lista filtrada a cada re-renderização
  useEffect(() => {
    if (!isFiltered) {
      console.log("Cards atualizados, resetando filtros")
      setFilteredCards(cards)
    }
  }, [cards, isFiltered])
  
  const { rpaId } = useParams()
  // Carrega a configuração do quadro
  useEffect(() => {
    const loadBoardConfig = async () => {
      setLoading(true)
      try {
        // Em produção, isso viria da API
        const config = await fetchBoardConfig(boardType, rpaId)
        console.log('Configuração do quadro:', config)
        setBoardConfig(config)
      } catch (error) {
        console.error('Erro ao carregar configuração do quadro:', error)
        setNotification({
          open: true,
          message: 'Erro ao carregar configuração do quadro',
          type: 'error',
        })
      } finally {
        setLoading(false)
      }
    }

    loadBoardConfig()
  }, [boardType])

  // Manipula o movimento de cards entre colunas
  const handleDragEnd = (result: any) => {
    const { source, destination, draggableId } = result

    // Cancelado ou solto no mesmo lugar
    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return
    }

    const cardId = draggableId
    const sourceColumnId = source.droppableId
    const destinationColumnId = destination.droppableId
    const sourceIndex = source.index
    const destinationIndex = destination.index

    // Atualiza o estado local
    moveCard(
      cardId,
      sourceColumnId,
      destinationColumnId,
      sourceIndex,
      destinationIndex,
    )

    // Notifica sobre a mudança
    if (boardConfig) {
      const destinationColumn = boardConfig.columns.find(
        (col) => col.id === destinationColumnId,
      )

      if (destinationColumn) {
        setNotification({
          open: true,
          message: `Card movido para "${destinationColumn.title}"`,
          type: 'success',
        })

        // Chama o callback externo com os detalhes da movimentação (SE EXISTIR CALLBACK)
        if (onCardMove) {
          onCardMove({
            cardId,
            sourceColumnId,
            destinationColumnId,
            assignedTo: destinationColumn.assignedTo,
            integrations: destinationColumn.integrations || [],
          })
        }

        // Verifica se há integrações a serem disparadas na coluna de destino
        if (
          destinationColumn.integrations &&
          destinationColumn.integrations.length > 0 &&
          onIntegrationTrigger
        ) {
          onIntegrationTrigger({
            columnId: destinationColumnId,
            cardId,
            integrations: destinationColumn.integrations,
          })
        }
      }
    }
  }

  const handleCardClick = (card: CardType) => {
    console.log('Editando handleCardClick:', card)
    setSelectedCard(card)
    setOpenCardDialog(true)
  }

  const handleAddCard = () => {
    setSelectedCard(null)
    setOpenCardDialog(true)
  }

  const handleSaveCard = async (cardData: CardType) => {
    try {
      if (selectedCard) {
        // Atualizando card existente
        await updateCard(cardData)
        setNotification({
          open: true,
          message: 'Card atualizado com sucesso',
          type: 'success',
        })
      } else {
        // Adicionando novo card
        await addCard(cardData)
        setNotification({
          open: true,
          message: 'Card adicionado com sucesso',
          type: 'success',
        })
      }
      setOpenCardDialog(false)
    } catch (error) {
      console.error('Erro ao salvar card:', error)
      setNotification({
        open: true,
        message: 'Erro ao salvar card',
        type: 'error',
      })
    }
  }

  const handleDeleteCard = async (cardId: string) => {
    try {
      await deleteCard(cardId)
      setNotification({
        open: true,
        message: 'Card removido com sucesso',
        type: 'success',
      })
      setOpenCardDialog(false)
    } catch (error) {
      console.error('Erro ao remover card:', error)
      setNotification({
        open: true,
        message: 'Erro ao remover card',
        type: 'error',
      })
    }
  }

  const handleCardAction = (action: string, card: CardType) => {
    // Chama o callback externo (SE EXISTIR CALLBACK)
    if (onCardAction) {
      onCardAction(action, card)
    }

    if (action === 'edit') {
      console.log('Editando card:', card)
      setSelectedCard(card)
      setOpenCardDialog(true)
    } else if (action === 'delete') {
      handleDeleteCard(card.id)
    }
  }

  const handleOpenSettings = () => {
    setOpenSettingsDialog(true)
  }

  const handleSaveConfig = async (updatedConfig: BoardConfigType) => {
    try {
      // Salvar a configuração atualizada na API
      await updateBoardConfig(updatedConfig)

      // Atualizar o estado local
      setBoardConfig(updatedConfig)

      // Recarregar os cards para refletir as mudanças nas colunas
      if (reloadCards) {
        await reloadCards()
      }

      setNotification({
        open: true,
        message: 'Configurações do quadro atualizadas com sucesso',
        type: 'success',
      })
    } catch (error) {
      console.error('Erro ao salvar configurações:', error)
      setNotification({
        open: true,
        message: 'Erro ao salvar configurações do quadro',
        type: 'error',
      })
    }
  }

  // Manipulador para aplicar filtros
  const handleApplyFilter = (filtered: CardType[]) => {
    setFilteredCards(filtered)
    setIsFiltered(filtered.length !== cards.length)

    // Notificar o usuário sobre os resultados do filtro
    if (filtered.length === 0) {
      setNotification({
        open: true,
        message: 'Nenhum card corresponde aos filtros aplicados',
        type: 'info',
      })
    } else if (filtered.length < cards.length) {
      setNotification({
        open: true,
        message: `${filtered.length} de ${cards.length} cards correspondem aos filtros`,
        type: 'info',
      })
    }
  }

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false })
  }

  // Os condicionais de loading e erro foram movidos para cima, antes do useMemo

  const ensureBoardType = (type: string): BoardType => {
    return type === 'inadimplencia' ? 'inadimplencia' : 'lead'
  }

  const adaptCardToFormData = (card: any | null): CardFormData | null => {
    if (!card) return null
    console.log('Adaptando card para FormData:', card)
    return {
      id: card.id || '',
      title: card.title || '',
      type: boardType as BoardType,
      columnId: card.columnId || '',
      company: card.company || '',
      description: card.description || '',
      priority: (card.priority || 'média') as 'alta' | 'média' | 'baixa',
      value: card.value?.toString() || '',
      diasAtraso: card.diasAtraso?.toString() || '',
      erpName: card.erpName || '',
      invoice: card.invoice || null,
      contact: {
        name: card.contactName || card.contact?.name || '',
        email: card.contactEmail || card.contact?.email || '',
        phone: card.contactPhone || card.contact?.phone || '',
        whatsapp: card.contactWhatsapp || card.contact?.whatsapp || '',
      },
      produtos: card.products || [],
      debitoERP: card.debitoERP || null,
      contatoId: card.contatoId || card.contactId || '',
      empresaId: card.empresaId || card.enterpriseId || '',
      activities: card.activities || [],
    }
  }

  // Memoização das colunas com seus cards filtrados para evitar re-renderização
  // Importante: garantir que este hook sempre seja chamado, independente das condições abaixo
  const memoizedColumns = React.useMemo(() => {
    if (!boardConfig || !boardConfig.columns) return [];
    
    return boardConfig.columns.map(column => {
      // Pré-filtramos os cards para cada coluna
      const columnCards = filteredCards.filter(card => card.columnId === column.id);
      
      return {
        column,
        filteredCards: columnCards
      };
    });
  }, [boardConfig, filteredCards]);
  
  // Renderizações condicionais de loading e erro
  if (loading) {
    return (
      <Box className="flex flex-col items-center justify-center h-full w-full p-8">
        <style dangerouslySetInnerHTML={{ __html: `
          @keyframes card-shimmer {
            0% { transform: translateX(-150%); }
            100% { transform: translateX(150%); }
          }
          @keyframes fade-in {
            0% { opacity: 0; transform: translateY(10px); }
            100% { opacity: 1; transform: translateY(0); }
          }
          .card-skeleton {
            background: #f0f0f0;
            border-radius: 8px;
            height: 80px;
            margin-bottom: 8px;
            position: relative;
            overflow: hidden;
            animation: fade-in 0.5s ease forwards;
          }
          .card-skeleton::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, rgba(255,255,255,0.6), transparent);
            animation: card-shimmer 1.5s infinite;
          }
          .column-skeleton {
            background: #fafafa;
            border-radius: 8px;
            width: 280px;
            margin-right: 16px;
            padding: 16px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.05);
            opacity: 0;
            animation: fade-in 0.5s ease forwards;
          }
          .column-header {
            height: 24px;
            background: #e0e0e0;
            border-radius: 4px;
            margin-bottom: 16px;
          }
          .column-skeleton:nth-child(1) { animation-delay: 0.1s; }
          .column-skeleton:nth-child(2) { animation-delay: 0.2s; }
          .column-skeleton:nth-child(3) { animation-delay: 0.3s; }
          .column-skeleton:nth-child(4) { animation-delay: 0.4s; }
          .card-skeleton:nth-child(1) { animation-delay: 0.2s; }
          .card-skeleton:nth-child(2) { animation-delay: 0.3s; }
          .card-skeleton:nth-child(3) { animation-delay: 0.4s; }
          .card-skeleton:nth-child(4) { animation-delay: 0.5s; }
          .loading-text {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 24px;
            color: #635AFF;
            opacity: 0;
            animation: pulse 2s infinite, fade-in 0.5s ease forwards;
          }
          @keyframes pulse {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.6; }
          }
        `}} />
        
        <Typography className="loading-text" variant="h6">
          Carregando quadro...
        </Typography>
        
        <Box className="flex" sx={{ pt: 2 }}>
          {[1, 2, 3, 4].map((column) => (
            <div key={column} className="column-skeleton">
              <div className="column-header"></div>
              {[1, 2, 3, 4].map((card) => (
                <div key={card} className="card-skeleton"></div>
              ))}
            </div>
          ))}
        </Box>
      </Box>
    )
  }

  if (!boardConfig) {
    return (
      <Box className="flex items-center justify-center h-full w-full p-8">
        <Typography variant="body1" color="error">
          Não foi possível carregar a configuração do quadro.
        </Typography>
      </Box>
    )
  }
  
  return (
    <Box className="flex flex-col h-full w-full">
      {/* Cabeçalho do Kanban */}
      <Box className="flex justify-between items-center p-4 bg-white shadow-sm">
        <Typography variant="h5" className="font-medium">
          {boardConfig.title}
          <Chip
            label={boardConfig.type}
            size="small"
            color={boardConfig.type === 'lead' ? 'primary' : 'secondary'}
            className="ml-2"
          />
          {isFiltered && (
            <Chip
              label={`${filteredCards.length} cards filtrados`}
              size="small"
              color="default"
              variant="outlined"
              className="ml-2"
              onDelete={() => {
                setFilteredCards(cards)
                setIsFiltered(false)
              }}
            />
          )}
        </Typography>

        <Box className="flex space-x-2">
          {/* Componente de Filtro */}
          <KanbanFilter
            boardType={boardType}
            cards={cards}
            columns={boardConfig.columns}
            onFilter={handleApplyFilter}
          />

          <Button
            variant="outlined"
            size="small"
            startIcon={<SettingsIcon />}
            className="text-gray-700"
            onClick={handleOpenSettings}
          >
            Configurações
          </Button>

          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddCard}
            color="primary"
          >
            Novo Card
          </Button>
        </Box>
      </Box>

      {/* Área de arrastar e soltar */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Box className="flex overflow-x-auto p-4 h-full">
          {memoizedColumns.map(({ column, filteredCards: columnCards }) => (
            <KanbanColumn
              key={column.id}
              column={column}
              cards={columnCards}
              onCardClick={handleCardClick}
              onCardAction={handleCardAction}
            />
          ))}
        </Box>
      </DragDropContext>

      {/* Mensagem quando não há cards após filtro */}
      {isFiltered && filteredCards.length === 0 && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            p: 3,
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }}
        >
          <Typography variant="h6" color="text.secondary">
            Nenhum card corresponde aos filtros aplicados
          </Typography>
          <Button
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              setFilteredCards(cards)
              setIsFiltered(false)
            }}
          >
            Limpar Filtros
          </Button>
        </Box>
      )}

      {/* Dialog para edição/criação de card */}
      {boardConfig && (
        <CardDialog
          open={openCardDialog}
          onClose={() => setOpenCardDialog(false)}
          onSave={handleSaveCard}
          onDelete={handleDeleteCard}
          card={adaptCardToFormData(selectedCard)}
          columns={boardConfig.columns}
          boardType={ensureBoardType(boardType)}
        />
      )}

      {/* Dialog para configurações do quadro */}
      {boardConfig && (
        <KanbanSettings
          open={openSettingsDialog}
          onClose={() => setOpenSettingsDialog(false)}
          boardConfig={boardConfig}
          onSaveConfig={handleSaveConfig}
        />
      )}

      {/* Notificações */}
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.type}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default KanbanApp

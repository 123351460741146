import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Security,
  Code
} from '@mui/icons-material';
import * as S from './styles';

interface CredentialsSectionProps {
  appKey: string;
  setAppKey: (key: string) => void;
  appSecret: string;
  setAppSecret: (secret: string) => void;
  showAppSecret: boolean;
  setShowAppSecret: (show: boolean) => void;
  formErrors: {
    name: boolean;
    appKey: boolean;
    appSecret: boolean;
  };
  loading: boolean;
  erpVendor: string;
  isEdit: boolean;
  credentialsChanged: boolean;
  onUpdateCredentials: () => void;
}

const CredentialsSection: React.FC<CredentialsSectionProps> = ({
  appKey,
  setAppKey,
  appSecret,
  setAppSecret,
  showAppSecret,
  setShowAppSecret,
  formErrors,
  loading,
  erpVendor,
  isEdit,
  credentialsChanged,
  onUpdateCredentials
}) => {
  return (
    <S.FormSection>
      <S.SectionTitle>
        <Security fontSize="small" sx={{ mr: 1, verticalAlign: 'middle' }} />
        Credenciais de API
      </S.SectionTitle>
      
      <Alert severity="info" sx={{ mb: 3 }}>
        <Typography variant="body2">
          As credenciais abaixo são necessárias para autenticar a integração com o ERP {erpVendor === 'omie' ? 'Omie' : erpVendor}.
          Você pode obtê-las no painel de administração do seu ERP na seção de integrações ou API.
        </Typography>
      </Alert>
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="App Key"
            value={appKey}
            onChange={(e) => setAppKey(e.target.value)}
            fullWidth
            margin="normal"
            required
            error={formErrors.appKey}
            helperText={formErrors.appKey ? "App Key é obrigatório" : "Chave de identificação única da aplicação"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Code fontSize="small" />
                </InputAdornment>
              ),
            }}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="App Secret"
            type={showAppSecret ? 'text' : 'password'}
            value={appSecret}
            onChange={(e) => setAppSecret(e.target.value)}
            fullWidth
            margin="normal"
            required
            error={formErrors.appSecret}
            helperText={formErrors.appSecret ? "App Secret é obrigatório" : "Senha secreta para autenticação da API"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Security fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowAppSecret(!showAppSecret)}
                    edge="end"
                    size="small"
                    disabled={loading}
                  >
                    {showAppSecret ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled={loading}
          />
        </Grid>
      </Grid>
      
      {isEdit && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={onUpdateCredentials}
            disabled={!appKey.trim() || !appSecret.trim() || loading || !credentialsChanged}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Atualizar Credenciais
          </Button>
        </Box>
      )}
      
      {erpVendor === 'omie' && (
        <Box sx={{ mt: 2, p: 2, bgcolor: '#f8f9fa', borderRadius: 1, border: '1px solid #e9ecef' }}>
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Como obter as credenciais no Omie
          </Typography>
          <Typography variant="body2" color="text.secondary">
            1. Acesse o painel administrativo do Omie
          </Typography>
          <Typography variant="body2" color="text.secondary">
            2. Navegue até Configurações > Integrações > API
          </Typography>
          <Typography variant="body2" color="text.secondary">
            3. Crie uma nova aplicação ou utilize uma existente
          </Typography>
          <Typography variant="body2" color="text.secondary">
            4. Copie o App Key e App Secret gerados pelo sistema
          </Typography>
        </Box>
      )}
    </S.FormSection>
  );
};

export default CredentialsSection;
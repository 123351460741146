import React, { useState } from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import InfoIcon from '@mui/icons-material/Info'
import { TemplateFormProps, TemplateType, TemplateChannel, TemplateCategory, TemplateModel } from './types'

// Definição dos objetos do sistema e suas propriedades
const systemObjects = {
  card: {
    name: 'Card',
    properties: [
      { key: 'title', label: 'Título' },
      { key: 'type', label: 'Tipo' },
      { key: 'company', label: 'Empresa' },
      { key: 'description', label: 'Descrição' },
      { key: 'priority', label: 'Prioridade' },
      { key: 'value', label: 'Valor' },
      { key: 'dias_atraso', label: 'Dias de Atraso' },
      { key: 'invoice_id', label: 'ID da Fatura' },
      { key: 'contract_id', label: 'ID do Contrato' },
      { key: 'name', label: 'Nome' },
      { key: 'email', label: 'Email' },
      { key: 'phone', label: 'Telefone' },
      { key: 'whatsapp', label: 'WhatsApp' },
      { key: 'payment_code', label: 'Código de Pagamento' },
      { key: 'payment_link', label: 'Link de Pagamento' },
      { key: 'payment_method', label: 'Método de Pagamento' },
      { key: 'erp_name', label: 'Nome no ERP' }
    ]
  },
  contact: {
    name: 'Contato',
    properties: [
      { key: 'name', label: 'Nome' },
      { key: 'description', label: 'Descrição' },
      { key: 'number', label: 'Número' },
      { key: 'type', label: 'Tipo' },
      { key: 'tags', label: 'Tags' },
      { key: 'email', label: 'Email' }
    ]
  },
  enterprise: {
    name: 'Empresa',
    properties: [
      { key: 'name', label: 'Nome' },
      { key: 'description', label: 'Descrição' },
      { key: 'document', label: 'Documento' },
      { key: 'address', label: 'Endereço' },
      { key: 'integration_code', label: 'Código de Integração' },
      { key: 'tags', label: 'Tags' },
      { key: 'email', label: 'Email' }
    ]
  }
}

export const TemplateForm = ({
  templateName,
  setTemplateName,
  templateDescription,
  setTemplateDescription,
  templateType,
  templateModel,
  setTemplateModel,
  setTemplateType,
  templateChannel,
  setTemplateChannel,
  templateCategory,
  setTemplateCategory,
  templateText,
  handleTemplateTextChange,
  handleAddPlaceholder,
  placeholders,
  placeholderValues,
  handlePlaceholderValueChange,
  isSubmitting,
  edit,
  handleSubmit,
  handleDeleteTemplate,
  nextPlaceholderNumber,
  // Novos props para lidar com os dados vinculados
  placeholderBindings = [],
  setPlaceholderBindings = () => {},
}: TemplateFormProps) => {
  
  // Função para adicionar um placeholder com a possibilidade de vincular a um dado do sistema
  const handleAddPlaceholderWithBinding = () => {
    // Chamar a função de adicionar placeholder do componente pai
    handleAddPlaceholder();
    
    // Certificar-se de que o estado placeholderBindings está atualizado após a adição do novo placeholder
    // Isso é importante porque handleAddPlaceholder pode não atualizar o placeholderBindings no componente pai
    setPlaceholderBindings([
      ...placeholderBindings,
      { objectType: '', propertyKey: '' }
    ]);
  };

  // Função para atualizar o tipo de objeto vinculado a um placeholder
  const handleBindingObjectChange = (index, objectType) => {
    // Garantir que temos um array válido para trabalhar
    const currentBindings = Array.isArray(placeholderBindings) ? [...placeholderBindings] : [];
    
    // Criar um novo array com o tamanho adequado se necessário
    const newBindings = [...currentBindings];
    
    // Garantir que o array tem elementos suficientes
    while (newBindings.length <= index) {
      newBindings.push({ objectType: '', propertyKey: '' });
    }
    
    // Atualizar o elemento específico
    newBindings[index] = { 
      ...newBindings[index], 
      objectType, 
      propertyKey: '' // Resetar a propriedade quando o objeto muda
    };
    
    // Atualizar o estado
    setPlaceholderBindings(newBindings);
  };

  // Função para atualizar a propriedade vinculada a um placeholder
  const handleBindingPropertyChange = (index, propertyKey) => {
    // Garantir que temos um array válido para trabalhar
    const currentBindings = Array.isArray(placeholderBindings) ? [...placeholderBindings] : [];
    
    // Criar um novo array com o tamanho adequado se necessário
    const newBindings = [...currentBindings];
    
    // Garantir que o array tem elementos suficientes
    while (newBindings.length <= index) {
      newBindings.push({ objectType: '', propertyKey: '' });
    }
    
    // Atualizar o elemento específico
    newBindings[index] = { 
      ...newBindings[index], 
      propertyKey 
    };
    
    // Atualizar o estado
    setPlaceholderBindings(newBindings);
  };

  // Função para garantir que temos um valor seguro para o select
  const getSafeBindingValue = (index, property) => {
    if (
      Array.isArray(placeholderBindings) && 
      placeholderBindings[index] && 
      placeholderBindings[index][property] !== undefined
    ) {
      return placeholderBindings[index][property];
    }
    return '';
  };

  return (
    <Box sx={{ overflowY: 'auto', pb: 4 }}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nome"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          fullWidth
          margin="normal"
          inputProps={{ readOnly: edit }}
        />
        <TextField
          label="Descrição"
          value={templateDescription}
          onChange={(e) => setTemplateDescription(e.target.value)}
          fullWidth
          margin="normal"
          inputProps={{ readOnly: edit }}
        />
        
        {/* Tipo de template */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="template-type-label">Tipo</InputLabel>
          <Select
            labelId="template-type-label"
            value={templateType}
            label="Tipo"
            onChange={(e) => setTemplateType(e.target.value as TemplateType)}
            inputProps={{ readOnly: edit }}
          >
            {Object.values(TemplateType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="template-type-label">Modelo</InputLabel>
          <Select
            labelId="template-type-label"
            value={templateModel}
            label="Modelo"
            onChange={(e) => setTemplateModel(e.target.value as TemplateModel)}
            inputProps={{ readOnly: edit }}
          >
            {Object.values(TemplateModel).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        {/* Canal de comunicação */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="template-channel-label">Canal</InputLabel>
          <Select
            labelId="template-channel-label"
            value={templateChannel}
            label="Canal"
            onChange={(e) => setTemplateChannel(e.target.value as TemplateChannel)}
            inputProps={{ readOnly: edit }}
          >
            {Object.values(TemplateChannel).map((channel) => (
              <MenuItem key={channel} value={channel}>
                {channel === 'WHATSAPP' && '📱 '}
                {channel === 'EMAIL' && '📧 '}
                {channel === 'BOTH' && '📱📧 '}
                {channel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        {/* Categoria */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="template-category-label">Categoria</InputLabel>
          <Select
            labelId="template-category-label"
            value={templateCategory}
            label="Categoria"
            onChange={(e) => setTemplateCategory(e.target.value as TemplateCategory)}
            inputProps={{ readOnly: edit }}
          >
            {Object.values(TemplateCategory).map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <TextField
            label="Texto do Template"
            value={templateText}
            onChange={handleTemplateTextChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            inputProps={{ readOnly: edit }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: '8px',
              marginTop: '16px',
            }}
          >
            <IconButton
              onClick={handleAddPlaceholderWithBinding}
              title={`Adicionar {{${nextPlaceholderNumber}}}`}
              disabled={edit}
            >
              <AddCircleOutlineIcon />
            </IconButton>
            <span
              style={{
                fontSize: '12px',
                marginTop: '4px',
                textAlign: 'center',
              }}
            >
              Adicionar Placeholder
            </span>
          </div>
        </div>

        {/* Área de placeholders com vinculação aos dados do sistema */}
        {placeholders.map((placeholder, index) => (
          <Box key={placeholder} sx={{ mt: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              Placeholder {`{{${placeholder}}}`}
              <Tooltip title="Este placeholder será substituído pelos dados vinculados na integração automática">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  label={`Valor padrão para {{${placeholder}}}`}
                  fullWidth
                  value={placeholderValues[index] || ''}
                  onChange={(e) => handlePlaceholderValueChange(index, e.target.value)}
                  inputProps={{ readOnly: edit }}
                />
              </Grid>
              
              {/* Seleção do objeto do sistema */}
              <Grid item xs={12} md={4}>
                <FormControl fullWidth disabled={edit}>
                  <InputLabel>Vincular a Objeto</InputLabel>
                  <Select
                    value={getSafeBindingValue(index, 'objectType')}
                    onChange={(e) => handleBindingObjectChange(index, e.target.value)}
                    label="Vincular a Objeto"
                  >
                    <MenuItem value="">Nenhum</MenuItem>
                    {Object.entries(systemObjects).map(([key, obj]) => (
                      <MenuItem key={key} value={key}>{obj.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              {/* Seleção da propriedade do objeto */}
              <Grid item xs={12} md={4}>
                <FormControl 
                  fullWidth 
                  disabled={edit || !getSafeBindingValue(index, 'objectType')}
                >
                  <InputLabel>Propriedade</InputLabel>
                  <Select
                    value={getSafeBindingValue(index, 'propertyKey')}
                    onChange={(e) => handleBindingPropertyChange(index, e.target.value)}
                    label="Propriedade"
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {getSafeBindingValue(index, 'objectType') && 
                      systemObjects[getSafeBindingValue(index, 'objectType')].properties.map(prop => (
                        <MenuItem key={prop.key} value={prop.key}>{prop.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ))}
        
        <Box sx={{ mt: 2, mb: 4 }}>
          {edit ? (
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleDeleteTemplate}
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isSubmitting ? 'Deletando...' : 'Deletar Template'}
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isSubmitting ? 'Salvando...' : 'Salvar Template'}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  )
}
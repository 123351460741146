// Arquivo: components/EmpresaForm/EmpresaFormModal.tsx
import React from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EnderecoForm from './EnderecoForm';
import ContatosVinculadosSection from './ContatosVinculadosSection';
import TagsSection from './TagsSection';

// Interfaces
interface Contato {
  id: string;
  name: string;
  number: string;
  description?: string;
  active: boolean;
  rpaId: string;
  tags?: string;
}

interface Endereco {
  zipCode: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  complement?: string;
}

interface EmpresaFormModalProps {
  open: boolean;
  onClose: () => void;
  isEdit: boolean;
  empresaNome: string;
  setEmpresaNome: (nome: string) => void;
  empresaDocumento: string;
  setEmpresaDocumento: (nome: string) => void;
  empresaDescricao: string;
  setEmpresaDescricao: (descricao: string) => void;
  endereco: Endereco;
  setEndereco: (endereco: Endereco) => void;
  empresaTags: string;
  setEmpresaTags: (tags: string) => void;
  empresaContatos: Contato[];
  handleRemoveContato: (id: string) => void;
  handleOpenContatosDialog: () => void;
  handleDeleteEmpresa: () => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const EmpresaFormModal: React.FC<EmpresaFormModalProps> = ({
  open,
  onClose,
  isEdit,
  empresaNome,
  setEmpresaNome,
  empresaDocumento,
  setEmpresaDocumento,
  empresaDescricao,
  setEmpresaDescricao,
  endereco,
  setEndereco,
  empresaTags,
  setEmpresaTags,
  empresaContatos,
  handleRemoveContato,
  handleOpenContatosDialog,
  handleDeleteEmpresa,
  handleSubmit
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(e);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      aria-labelledby="empresa-form-dialog-title"
    >
      <DialogTitle id="empresa-form-dialog-title">
        {isEdit ? 'Editar Empresa' : 'Nova Empresa'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent dividers>
        <Box 
          component="div" 
          sx={{ 
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0,0,0,0.05)',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.3)',
              },
            },
          }}
        >
          <form id="empresa-form" onSubmit={onSubmitForm}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Informações Básicas
              </Typography>
              <Divider sx={{ mb: 3 }} />
              <TextField
                label="Nome da Empresa"
                value={empresaNome}
                onChange={(e) => setEmpresaNome(e.target.value)}
                fullWidth
                margin="normal"
                required
                sx={{ mb: 2 }}
              />
               <TextField
                label="CNPJ/CPF"
                value={empresaDocumento}
                onChange={(e) => setEmpresaDocumento(e.target.value)}
                fullWidth
                margin="normal"
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label="Descrição"
                value={empresaDescricao}
                onChange={(e) => setEmpresaDescricao(e.target.value)}
                fullWidth
                margin="normal"
                multiline
                rows={3}
                sx={{ mb: 2 }}
              />
              <TagsSection tags={empresaTags} setTags={setEmpresaTags} />
            </Paper>
            
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Endereço Completo
              </Typography>
              <Divider sx={{ mb: 3 }} />
              <EnderecoForm address={endereco} setAddress={setEndereco} />
            </Paper>
            
            <Paper sx={{ p: 3, mb: 3 }}>
              <ContatosVinculadosSection
                empresaContatos={empresaContatos}
                handleRemoveContato={handleRemoveContato}
                handleOpenContatosDialog={handleOpenContatosDialog}
              />
            </Paper>
          </form>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        {isEdit && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteEmpresa}
          >
            Deletar Empresa
          </Button>
        )}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={onClose} variant="outlined">
            Cancelar
          </Button>
          <Button 
            type="submit" 
            form="empresa-form"
            variant="contained" 
            color="primary"
          >
            {isEdit ? 'Atualizar Empresa' : 'Salvar Empresa'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EmpresaFormModal;
import React from 'react';
import { TextField } from '@mui/material';
import { Description as DescriptionIcon } from '@mui/icons-material';
import { FormSection, SectionTitle } from './styles';

interface DescriptionSectionProps {
  description: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const DescriptionSection: React.FC<DescriptionSectionProps> = ({
  description,
  handleChange,
}) => {
  return (
    <FormSection>
      <SectionTitle>
        <DescriptionIcon color="primary" />
        Descrição
      </SectionTitle>

      <TextField
        name="description"
        placeholder="Adicione uma descrição detalhada..."
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        value={description}
        onChange={handleChange}
      />
    </FormSection>
  );
};

export default DescriptionSection;
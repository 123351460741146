import React, { useEffect, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Box,
  Chip,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'

interface ContatosDialogProps {
  open: boolean;
  onClose: () => void;
  contatos: any[];
  contatosSelecionados: string[];
  handleToggleContato: (id: string) => void;
  handleAddContatosSelecionados: () => void;
  searchContato: string;
  setSearchContato: (value: string) => void;
  handleSearchContatos: (term: string) => void;
  loadMoreContatos: () => void;
  loading: boolean;
  hasMore: boolean;
}

const ContatosDialog: React.FC<ContatosDialogProps> = ({
  open,
  onClose,
  contatos,
  contatosSelecionados,
  handleToggleContato,
  handleAddContatosSelecionados,
  searchContato,
  setSearchContato,
  handleSearchContatos,
  loadMoreContatos,
  loading,
  hasMore
}) => {
  // Referência para o elemento que será observado para paginação infinita
  const observerRef = useRef(null);
  // Referência para o temporizador de debounce
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  // Referência para a lista de contatos
  const listRef = useRef<HTMLDivElement>(null);

  // Efeito para configurar o IntersectionObserver para paginação infinita
  useEffect(() => {
    // Aguardando 100ms para garantir que a lista esteja renderizada
    const timeoutId = setTimeout(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore && !loading) {
            loadMoreContatos();
          }
        },
        { threshold: 0.5 }
      );
      
      if (observerRef.current) {
        observer.observe(observerRef.current);
      }
      
      return () => {
        if (observerRef.current) {
          observer.unobserve(observerRef.current);
        }
      };
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, [hasMore, loading, loadMoreContatos]);

  // Handler com debounce para busca de contatos
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchContato(term);
    
    // Limpar o timer anterior
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    
    // Configurar novo timer (esperar 500ms após o usuário parar de digitar)
    debounceTimerRef.current = setTimeout(() => {
      handleSearchContatos(term);
    }, 500);
  };

  // Limpar o timer de debounce quando o componente for desmontado
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Selecionar Contatos</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Buscar contato"
          type="text"
          fullWidth
          variant="outlined"
          value={searchContato}
          onChange={handleSearchInputChange}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: loading && searchContato ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : null
          }}
        />

        <Box 
          ref={listRef} 
          sx={{ 
            height: '400px', 
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0,0,0,0.05)',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.3)',
              },
            },
          }}
        >
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {contatos.length > 0 ? (
              <>
                {contatos.map((contato) => (
                  <ListItem
                    key={contato.id}
                    onClick={() => handleToggleContato(contato.id)}
                    sx={{ 
                      borderRadius: '8px', 
                      mb: 1,
                      '&:hover': { 
                        backgroundColor: 'rgba(0, 0, 0, 0.04)' 
                      },
                      cursor: 'pointer'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          edge="start"
                          checked={contatosSelecionados.includes(contato.id)}
                          tabIndex={-1}
                          disableRipple
                        />
                      }
                      label={
                        <Box>
                          <ListItemText
                            primary={contato.name}
                            secondary={contato.number}
                          />
                          {contato.tags && (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                              {contato.tags.split(',').map(tag => tag.trim()).filter(tag => tag).map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={tag}
                                  size="small"
                                  sx={{
                                    height: '18px',
                                    fontSize: '0.65rem',
                                    backgroundColor: 'rgba(63, 81, 181, 0.08)',
                                    color: '#3f51b5',
                                  }}
                                />
                              ))}
                            </Box>
                          )}
                        </Box>
                      }
                      sx={{ width: '100%' }}
                    />
                  </ListItem>
                ))}
                
                {/* Elemento observador para paginação infinita */}
                {hasMore && (
                  <Box 
                    ref={observerRef} 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      p: 2 
                    }}
                  >
                    {loading ? <CircularProgress size={24} /> : <Typography variant="body2">Carregando mais contatos...</Typography>}
                  </Box>
                )}
              </>
            ) : (
              <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
                {loading ? "Carregando contatos..." : "Nenhum contato encontrado."}
              </Typography>
            )}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleAddContatosSelecionados}
          variant="contained"
          disabled={contatosSelecionados.length === 0}
        >
          Adicionar ({contatosSelecionados.length})
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContatosDialog
import React from 'react'

export const IconEdit = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.13165 13.7496C0.869155 13.8121 0.64103 13.7465 0.44728 13.5527C0.25353 13.359 0.187905 13.1309 0.250405 12.8684L1.0004 9.28711L4.7129 12.9996L1.13165 13.7496ZM4.7129 12.9996L1.0004 9.28711L9.86915 0.418359C10.0817 0.205859 10.3442 0.0996094 10.6567 0.0996094C10.9692 0.0996094 11.2317 0.205859 11.4442 0.418359L13.5817 2.55586C13.7942 2.76836 13.9004 3.03086 13.9004 3.34336C13.9004 3.65586 13.7942 3.91836 13.5817 4.13086L4.7129 12.9996ZM10.6567 1.22461L2.36915 9.51211L4.48791 11.6309L12.7754 3.34336L10.6567 1.22461Z"
        fill="#161F28"
      />
      <path
        d="M1.13165 13.7496C0.869155 13.8121 0.64103 13.7465 0.44728 13.5527C0.25353 13.359 0.187905 13.1309 0.250405 12.8684L1.0004 9.28711L4.7129 12.9996L1.13165 13.7496ZM4.7129 12.9996L1.0004 9.28711L9.86915 0.418359C10.0817 0.205859 10.3442 0.0996094 10.6567 0.0996094C10.9692 0.0996094 11.2317 0.205859 11.4442 0.418359L13.5817 2.55586C13.7942 2.76836 13.9004 3.03086 13.9004 3.34336C13.9004 3.65586 13.7942 3.91836 13.5817 4.13086L4.7129 12.9996ZM10.6567 1.22461L2.36915 9.51211L4.48791 11.6309L12.7754 3.34336L10.6567 1.22461Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M1.13165 13.7496C0.869155 13.8121 0.64103 13.7465 0.44728 13.5527C0.25353 13.359 0.187905 13.1309 0.250405 12.8684L1.0004 9.28711L4.7129 12.9996L1.13165 13.7496ZM4.7129 12.9996L1.0004 9.28711L9.86915 0.418359C10.0817 0.205859 10.3442 0.0996094 10.6567 0.0996094C10.9692 0.0996094 11.2317 0.205859 11.4442 0.418359L13.5817 2.55586C13.7942 2.76836 13.9004 3.03086 13.9004 3.34336C13.9004 3.65586 13.7942 3.91836 13.5817 4.13086L4.7129 12.9996ZM10.6567 1.22461L2.36915 9.51211L4.48791 11.6309L12.7754 3.34336L10.6567 1.22461Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M1.13165 13.7496C0.869155 13.8121 0.64103 13.7465 0.44728 13.5527C0.25353 13.359 0.187905 13.1309 0.250405 12.8684L1.0004 9.28711L4.7129 12.9996L1.13165 13.7496ZM4.7129 12.9996L1.0004 9.28711L9.86915 0.418359C10.0817 0.205859 10.3442 0.0996094 10.6567 0.0996094C10.9692 0.0996094 11.2317 0.205859 11.4442 0.418359L13.5817 2.55586C13.7942 2.76836 13.9004 3.03086 13.9004 3.34336C13.9004 3.65586 13.7942 3.91836 13.5817 4.13086L4.7129 12.9996ZM10.6567 1.22461L2.36915 9.51211L4.48791 11.6309L12.7754 3.34336L10.6567 1.22461Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M1.13165 13.7496C0.869155 13.8121 0.64103 13.7465 0.44728 13.5527C0.25353 13.359 0.187905 13.1309 0.250405 12.8684L1.0004 9.28711L4.7129 12.9996L1.13165 13.7496ZM4.7129 12.9996L1.0004 9.28711L9.86915 0.418359C10.0817 0.205859 10.3442 0.0996094 10.6567 0.0996094C10.9692 0.0996094 11.2317 0.205859 11.4442 0.418359L13.5817 2.55586C13.7942 2.76836 13.9004 3.03086 13.9004 3.34336C13.9004 3.65586 13.7942 3.91836 13.5817 4.13086L4.7129 12.9996ZM10.6567 1.22461L2.36915 9.51211L4.48791 11.6309L12.7754 3.34336L10.6567 1.22461Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}

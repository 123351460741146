import React from 'react';
import { Avatar, Button, Typography } from '@mui/material';
import { Chat as ChatIcon } from '@mui/icons-material';

export const EmptyStateMessage = ({ selectedChatId, messages, handleNewChat }) => {
  return (
    <div className="col-span-12 flex flex-col items-center justify-center py-8 px-4 mt-10">
      <div className="bg-gray-100 rounded-full p-5 mb-6">
        <Avatar
          sx={{
            bgcolor: '#635AFF',
            width: 60,
            height: 60,
            fontSize: '1.5rem',
          }}
        >
          <ChatIcon fontSize="large" />
        </Avatar>
      </div>
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
        Bem-vindo ao Chat
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        align="center"
        sx={{ maxWidth: 400, mb: 4 }}
      >
        {selectedChatId
          ? 'Nenhuma mensagem ainda. Comece a conversa digitando abaixo...'
          : 'Selecione uma conversa existente ou crie uma nova para começar'}
      </Typography>
      {!selectedChatId && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewChat}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            px: 3,
            py: 1,
          }}
        >
          Nova Conversa
        </Button>
      )}
    </div>
  );
};
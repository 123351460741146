import React, { useState, useEffect, useRef } from 'react'
import {
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
  Button,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Badge,
  InputAdornment,
} from '@mui/material'
import {
  Close,
  Psychology,
  Summarize,
  Send,
  DragIndicator,
  QuestionAnswer,
  Psychology as SmartToy,
  Lightbulb,
  Info,
  Fullscreen,
  FullscreenExit,
  ArrowForward,
} from '@mui/icons-material'

/**
 * AI Sales Assistant Wizard para auxiliar vendedores com IA durante o atendimento
 *
 * @param {Object} props - Propriedades do componente
 * @param {boolean} props.open - Controla se o wizard está visível
 * @param {Function} props.onClose - Função para fechar o wizard
 * @param {Function} props.onSendToChat - Função para enviar dados para o chat principal
 * @param {Array} props.chatMessages - Mensagens do chat atual (opcional)
 * @param {Object} props.productCatalog - Catálogo de produtos disponíveis (opcional)
 * @param {Object} props.clientInfo - Informações do cliente atual (opcional)
 */
const AISalesAssistantWizard = ({
  open,
  onClose,
  onSendToChat,
  chatMessages = [],
  productCatalog = [],
  clientInfo = null,
}) => {
  // Estado para gerenciar a posição do componente arrastável
  const [position, setPosition] = useState({
    x: window.innerWidth - 520,
    y: 60,
  })
  const [isDragging, setIsDragging] = useState(false)
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState({ width: 480, height: 520 })
  const [isExpanded, setIsExpanded] = useState(false)

  // Estado para guias e conteúdo do assistente
  const [activeTab, setActiveTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [aiMessage, setAiMessage] = useState('')
  const [userInput, setUserInput] = useState('')
  const [conversation, setConversation] = useState([])
  const [summary, setSummary] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [productInfo, setProductInfo] = useState([])
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)

  // Ref para o componente arrastável e para rolagem automática da conversa
  const dragRef = useRef(null)
  const messagesEndRef = useRef(null)

  // Rolar para o final da conversa quando novas mensagens são adicionadas
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [conversation])

  // Efeito para carregar dados quando o componente abre
  useEffect(() => {
    if (open) {
      // Inicializar o assistente com uma mensagem de boas-vindas
      setConversation([
        {
          role: 'assistant',
          content:
            'Olá! Sou seu assistente de vendas com IA. Como posso ajudar você hoje?',
        },
      ])

      // Gerar sugestões iniciais com base no contexto
      generateInitialSuggestions()
    }
  }, [open])

  // Gerar sugestões iniciais com base no contexto do chat
  const generateInitialSuggestions = () => {
    setIsLoading(true)

    // Simular algumas sugestões iniciais com base no contexto
    setTimeout(() => {
      const initialSuggestions = [
        {
          id: 1,
          title: 'Resumir a conversa atual',
          description:
            'Gerar um resumo dos principais pontos discutidos até agora',
          type: 'summary',
        },
        {
          id: 2,
          title: 'Sugerir próximos passos',
          description: 'Recomendar as próximas ações para avançar na venda',
          type: 'next_steps',
        },
        {
          id: 3,
          title: 'Identificar objeções',
          description: 'Analisar possíveis objeções do cliente na conversa',
          type: 'objections',
        },
        {
          id: 4,
          title: 'Esboçar proposta',
          description:
            'Criar um esboço de proposta com base nas necessidades detectadas',
          type: 'proposal',
        },
      ]

      if (clientInfo) {
        initialSuggestions.push({
          id: 5,
          title: 'Analisar perfil do cliente',
          description: 'Insights sobre o perfil e histórico do cliente atual',
          type: 'client_analysis',
        })
      }

      setSuggestions(initialSuggestions)
      setIsLoading(false)
    }, 800)
  }

  // Simular a geração de um resumo da conversa
  const generateChatSummary = () => {
    setIsLoading(true)
    setActiveTab(1)

    // Simular processamento da IA
    setTimeout(() => {
      const generatedSummary = `
## Resumo da Conversa

**Cliente:** ${clientInfo?.nome || 'Cliente atual'}

**Principais pontos:**
- Cliente demonstrou interesse em soluções de armazenamento digital
- Mencionou necessidade de aumentar capacidade para equipe de 12 pessoas
- Preocupação com segurança de dados e backup
- Orçamento aproximado: R$ 15.000,00
- Prazo para implementação: próximo trimestre

**Produtos discutidos:**
- SSD 1TB (código: SSD1T)
- Servidor NAS (código: NAS4B)

**Próximos passos recomendados:**
1. Enviar proposta detalhada com opções de armazenamento
2. Agendar demonstração técnica 
3. Apresentar opções de financiamento
`

      setSummary(generatedSummary)
      setIsLoading(false)
    }, 1500)
  }

  // Buscar informações detalhadas de produtos no catálogo
  const fetchProductInfo = (productName) => {
    setIsLoading(true)
    setActiveTab(2)

    // Simular busca de informações do produto
    setTimeout(() => {
      const productDetails = [
        {
          id: 101,
          name: 'SSD 1TB',
          code: 'SSD1T',
          description:
            'Unidade de estado sólido de alta performance com 1TB de capacidade.',
          technicalDetails: `
- Capacidade: 1TB
- Interface: NVMe PCIe 4.0 x4
- Velocidade de leitura: até 7.000 MB/s
- Velocidade de escrita: até 5.000 MB/s
- MTBF: 1.6 milhões de horas
- Garantia: 5 anos
          `,
          salesPoints: `
- 10x mais rápido que HDDs tradicionais
- Ideal para profissionais que trabalham com grandes arquivos
- Baixo consumo de energia, gerando economia a longo prazo
- Resistente a impactos e vibrações
- Instalação simples e compatível com a maioria dos sistemas
          `,
          competitiveAdvantages: `
- Nossa solução tem a melhor relação custo-benefício do mercado
- Suporte técnico especializado incluído
- Possibilidade de upgrade com desconto em até 3 anos
- Integração com nossas soluções de backup em nuvem
          `,
          price: 'R$ 899,00',
          stock: 42,
        },
      ]

      setProductInfo(productDetails)
      setIsLoading(false)
    }, 1000)
  }

  // Enviar mensagem para o assistente de IA
  const sendMessage = () => {
    if (!userInput.trim()) return

    // Adicionar mensagem do usuário à conversa
    const newMessage = {
      role: 'user',
      content: userInput,
    }

    setConversation([...conversation, newMessage])
    setUserInput('')
    setIsLoading(true)

    // Simular resposta do assistente
    setTimeout(() => {
      // Gerar uma resposta baseada no input do usuário
      let response

      if (userInput.toLowerCase().includes('resumo')) {
        response =
          'Posso gerar um resumo da conversa atual. Você gostaria de destacar algum aspecto específico?'
      } else if (userInput.toLowerCase().includes('produto')) {
        response =
          'Posso fornecer informações detalhadas sobre qualquer produto do nosso catálogo. Qual produto te interessa?'
      } else if (
        userInput.toLowerCase().includes('objeção') ||
        userInput.toLowerCase().includes('dúvida')
      ) {
        response =
          'Para lidar com objeções, primeiro identifique a preocupação real do cliente. Valide-a, faça perguntas para entender melhor, e então apresente soluções específicas.'
      } else if (userInput.toLowerCase().includes('proposta')) {
        response =
          'Posso ajudar a estruturar uma proposta. Geralmente, deve incluir: resumo das necessidades do cliente, soluções recomendadas, benefícios, investimento, e próximos passos.'
      } else if (userInput.toLowerCase().includes('cliente')) {
        response = clientInfo
          ? `Com base no histórico deste cliente, sugiro focar em soluções de alta performance e destacar nosso suporte premium, que foram aspectos valorizados em compras anteriores.`
          : `Não temos informações anteriores sobre este cliente. Seria bom fazer perguntas sobre o tamanho da empresa, desafios atuais e orçamento disponível.`
      } else {
        response =
          'Entendi. Como posso ajudar mais especificamente com sua venda atual? Posso analisar a conversa, sugerir abordagens, fornecer informações de produtos ou ajudar com objeções.'
      }

      // Adicionar resposta do assistente
      const aiResponse = {
        role: 'assistant',
        content: response,
      }

      setConversation((prev) => [...prev, aiResponse])
      setIsLoading(false)

      // Atualizar sugestões com base na conversa
      updateSuggestions(userInput)
    }, 1000)
  }

  // Atualizar sugestões com base na entrada do usuário
  const updateSuggestions = (input) => {
    // Lógica para adaptar sugestões com base no contexto da conversa
    const updatedSuggestions = [...suggestions]

    // Adicionar sugestões relevantes com base na entrada do usuário
    if (input.toLowerCase().includes('produto')) {
      updatedSuggestions.push({
        id: Math.max(...suggestions.map((s) => s.id)) + 1,
        title: 'Ver detalhes do SSD 1TB',
        description: 'Informações técnicas e argumentos de venda do SSD 1TB',
        type: 'product_info',
        productId: 'SSD1T',
      })
    }

    if (
      input.toLowerCase().includes('dúvida') ||
      input.toLowerCase().includes('preocupação')
    ) {
      updatedSuggestions.push({
        id: Math.max(...suggestions.map((s) => s.id)) + 1,
        title: 'Estratégias para objeções',
        description: 'Técnicas para lidar com as preocupações do cliente',
        type: 'objection_handling',
      })
    }

    setSuggestions(updatedSuggestions)
  }

  // Executar uma sugestão
  const executeSuggestion = (suggestion) => {
    setSelectedSuggestion(suggestion)

    switch (suggestion.type) {
      case 'summary':
        generateChatSummary()
        break
      case 'product_info':
        fetchProductInfo(suggestion.productId)
        break
      case 'objections':
        setActiveTab(0)
        setUserInput('Como lidar com objeções sobre preço?')
        sendMessage()
        break
      case 'next_steps':
        setActiveTab(0)
        setUserInput('Quais os próximos passos recomendados para esta venda?')
        sendMessage()
        break
      case 'proposal':
        setActiveTab(0)
        setUserInput('Como estruturar uma proposta eficaz?')
        sendMessage()
        break
      default:
        setActiveTab(0)
        break
    }
  }

  // Enviar conteúdo para o chat principal
  const sendToMainChat = (content) => {
    if (onSendToChat && content) {
      onSendToChat(content)
    }
  }

  // Funções para arrastar o componente
  const handleMouseDown = (e) => {
    if (e.target.closest('.drag-handle')) {
      setIsDragging(true)
      const rect = dragRef.current.getBoundingClientRect()
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      })
    }
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y,
      })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  // Alternar entre modo expandido e normal
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  // Efeito para adicionar e remover eventos de mouse para arrastar
  useEffect(() => {
    if (open) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [open, isDragging, dragOffset])

  // Se o componente não estiver aberto, não renderize nada
  if (!open) return null

  // Dimensões com base no modo expandido
  const dimensions = isExpanded ? { width: 720, height: 600 } : size

  // Renderiza a interface de chat com o assistente
  const renderChatInterface = () => {
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}
      >
        {/* Área de mensagens */}
        <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
          {conversation.map((message, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent:
                  message.role === 'user' ? 'flex-end' : 'flex-start',
                mb: 2,
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  maxWidth: '80%',
                  backgroundColor:
                    message.role === 'user'
                      ? 'primary.light'
                      : 'background.paper',
                  color: message.role === 'user' ? 'white' : 'text.primary',
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1">{message.content}</Typography>
              </Paper>
            </Box>
          ))}
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  backgroundColor: 'background.paper',
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={20} sx={{ mr: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  Pensando...
                </Typography>
              </Paper>
            </Box>
          )}
          <div ref={messagesEndRef} />
        </Box>

        {/* Entrada de texto */}
        <Box sx={{ display: 'flex' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Digite sua pergunta para o assistente de vendas..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            disabled={isLoading}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={sendMessage}
                    disabled={!userInput.trim() || isLoading}
                    color="primary"
                  >
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mr: 1 }}
          />
        </Box>
      </Box>
    )
  }

  // Renderiza o resumo do chat
  const renderSummary = () => {
    return (
      <Box sx={{ p: 2 }}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Resumo da Conversa
              </Typography>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  maxHeight: dimensions.height - 280,
                  overflow: 'auto',
                  whiteSpace: 'pre-line',
                }}
              >
                {summary}
              </Paper>
            </Box>

            <Button
              variant="contained"
              onClick={() => sendToMainChat(summary)}
              startIcon={<ArrowForward />}
              sx={{ mt: 2 }}
            >
              Enviar para o Chat
            </Button>
          </Box>
        )}
      </Box>
    )
  }

  // Renderiza informações de produtos
  const renderProductInfo = () => {
    return (
      <Box sx={{ p: 2 }}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {productInfo.length > 0 ? (
              productInfo.map((product) => (
                <Box key={product.id}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      {product.name} ({product.code})
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Card variant="outlined" sx={{ mb: 2 }}>
                          <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                              Descrição
                            </Typography>
                            <Typography variant="body2">
                              {product.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Card variant="outlined" sx={{ height: '100%' }}>
                          <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                              Detalhes Técnicos
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ whiteSpace: 'pre-line' }}
                            >
                              {product.technicalDetails}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Card variant="outlined" sx={{ height: '100%' }}>
                          <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                              Argumentos de Venda
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ whiteSpace: 'pre-line' }}
                            >
                              {product.salesPoints}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12}>
                        <Card variant="outlined" sx={{ mb: 2 }}>
                          <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                              Vantagens Competitivas
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ whiteSpace: 'pre-line' }}
                            >
                              {product.competitiveAdvantages}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6">
                        Preço: {product.price}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Em estoque: {product.stock} unidades
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    variant="contained"
                    onClick={() =>
                      sendToMainChat(
                        `**${product.name} (${product.code})**\n\n${product.description}\n\nPreço: ${product.price}\nEstoque: ${product.stock} unidades\n\n**Principais benefícios:**${product.salesPoints}`,
                      )
                    }
                    startIcon={<ArrowForward />}
                  >
                    Enviar Informações para o Chat
                  </Button>
                </Box>
              ))
            ) : (
              <Typography variant="body1">
                Selecione um produto para ver informações detalhadas
              </Typography>
            )}
          </Box>
        )}
      </Box>
    )
  }

  // Renderiza sugestões de IA
  const renderSuggestions = () => {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Sugestões do Assistente
        </Typography>

        <Grid container spacing={2}>
          {suggestions.map((suggestion) => (
            <Grid item xs={12} md={6} key={suggestion.id}>
              <Card
                variant="outlined"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: 'primary.main',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  },
                }}
                onClick={() => executeSuggestion(suggestion)}
              >
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {suggestion.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {suggestion.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }

  // Conteúdo para cada guia
  const renderTabContent = () => {
    switch (activeTab) {
      case 0: // Chat com o assistente
        return renderChatInterface()
      case 1: // Resumo da conversa
        return renderSummary()
      case 2: // Informações de produtos
        return renderProductInfo()
      case 3: // Sugestões
        return renderSuggestions()
      default:
        return renderChatInterface()
    }
  }

  return (
    <Paper
      ref={dragRef}
      onMouseDown={handleMouseDown}
      elevation={3}
      sx={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        width: dimensions.width,
        height: dimensions.height,
        zIndex: 1300,
        borderRadius: 2,
        // Remover overflow: hidden no Paper principal
        resize: 'both',
        transition: 'width 0.3s, height 0.3s',
        cursor: isDragging ? 'grabbing' : 'auto',
        display: 'flex', // Adicionar display flex
        flexDirection: 'column', // Organizar elementos em coluna
      }}
    >
      <Box
        className="drag-handle"
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'grab',
          '&:active': {
            cursor: 'grabbing',
          },
          zIndex: 1301, // Garantir que esteja acima de outros elementos
          flexShrink: 0, // Evitar que o cabeçalho encolha
        }}
      >
        <Box display="flex" alignItems="center">
          <DragIndicator fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle1">Assistente IA de Vendas</Typography>
        </Box>
        <Box>
          <Tooltip title={isExpanded ? 'Restaurar tamanho' : 'Tela cheia'}>
            <IconButton size="small" color="inherit" onClick={toggleExpand}>
              {isExpanded ? (
                <FullscreenExit fontSize="small" />
              ) : (
                <Fullscreen fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Fechar">
            <IconButton size="small" color="inherit" onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* Abas */}
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab icon={<QuestionAnswer fontSize="small" />} label="Chat" />
        <Tab icon={<Summarize fontSize="small" />} label="Resumo" />
        <Tab icon={<Info fontSize="small" />} label="Produtos" />
        <Tab icon={<Lightbulb fontSize="small" />} label="Sugestões" />
      </Tabs>

      {/* Conteúdo */}
      <Box sx={{ height: 'calc(100% - 104px)', overflow: 'hidden' }}>
        {renderTabContent()}
      </Box>
    </Paper>
  )
}

export default AISalesAssistantWizard

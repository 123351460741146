// src/pages/UserManagement/components/UserCard/index.tsx
import React from 'react'
import { User } from '../../../../types'
import {
  Card,
  CardContent,
  Typography,
  Box,
  Switch,
  IconButton,
  Chip,
  Tooltip,
  Avatar,
} from '@mui/material'
import {
  Delete,
  Email,
  Phone,
  AdminPanelSettings,
  Badge,
} from '@mui/icons-material'
import styled from 'styled-components'

interface UserCardProps {
  user: User
  onClick: () => void
  onDelete: () => void
  onToggleActive: (active: boolean) => void
}

const CardContainer = styled(Card)`
  position: relative;
  transition: all 0.3s ease;
  height: 100%;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const CardActions = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  gap: 8px;
`

const StyledCardContent = styled(CardContent)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const UserHeader = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`

const UserName = styled(Typography)`
  font-weight: 600;
  color: #333;
`

const InfoSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  flex-grow: 1;
`

const InfoItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const getInitials = (name: string): string => {
  const names = name.split(' ')
  if (names.length === 1) return names[0].charAt(0).toUpperCase()
  return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase()
}

const getAvatarColor = (name: string): string => {
  const colors = [
    '#1976d2', // blue
    '#388e3c', // green
    '#d32f2f', // red
    '#f57c00', // orange
    '#7b1fa2', // purple
    '#0288d1', // light blue
    '#455a64', // blue grey
    '#512da8', // deep purple
  ]

  // Simple hash function to select a color based on the name
  const hash = name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
  return colors[hash % colors.length]
}

export const UserCard: React.FC<UserCardProps> = ({
  user,
  onClick,
  onDelete,
  onToggleActive,
}) => {
  const handleToggleActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    onToggleActive(event.target.checked)
  }

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation()
    onDelete()
  }

  const avatarColor = getAvatarColor(user.name)

  return (
    <CardContainer onClick={onClick}>
      <CardActions>
        <Tooltip title="Excluir">
          <IconButton size="small" onClick={handleDelete} color="error">
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
      </CardActions>

      <StyledCardContent>
        <UserHeader>
          <Avatar
            sx={{
              bgcolor: avatarColor,
              width: 48,
              height: 48,
              fontSize: '1.2rem',
            }}
          >
            {getInitials(user.name)}
          </Avatar>
          <Box>
            <UserName variant="h6">{user.name}</UserName>
            {user.isAdmin && (
              <Chip
                icon={<AdminPanelSettings fontSize="small" />}
                label="Administrador"
                size="small"
                color="primary"
                variant="outlined"
                sx={{ mt: 0.5 }}
              />
            )}
          </Box>
        </UserHeader>

        <InfoSection>
          <InfoItem>
            <Email fontSize="small" color="action" />
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </InfoItem>

          {user.phone && (
            <InfoItem>
              <Phone fontSize="small" color="action" />
              <Typography variant="body2" color="textSecondary">
                {user.phone}
              </Typography>
            </InfoItem>
          )}

          <InfoItem>
            <Badge fontSize="small" color="action" />
            <Typography variant="body2" color="textSecondary">
              ID: {user.integrationCode}
            </Typography>
          </InfoItem>
        </InfoSection>

        <Box sx={{ mt: 2 }}>
          <Typography variant="caption" color="textSecondary">
            Criado em: {new Date(user.createdAt).toLocaleDateString('pt-BR')}
          </Typography>
        </Box>
      </StyledCardContent>
    </CardContainer>
  )
}

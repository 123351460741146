import { useState } from 'react'
import { Box, Button, Dialog, CircularProgress, Paper, Typography } from '@mui/material'
import { Add, Business } from '@mui/icons-material'

import { InputSearch, SearchBarMobile } from 'components'
import { FilterListProfile } from '../../../../../erp/components/Filter/FilterListProfile'
import { PaginationComponent } from '../../../../../../../../components/PaginationComponent/PaginationComponent'
import { DepartmentCard } from '../../../DepartmentManagement/components/DepartmentCard'
import { DepartmentForm } from '../../../DepartmentManagement/components/DepartmentForm'
import { Department, User, Board, DepartmentPayload } from '../../../../types'
import * as S from '../../styles'

interface DepartmentListProps {
  departments: Department[]
  users: User[]
  boards: Board[]
  loading: boolean
  onSave: (department: DepartmentPayload) => Promise<boolean>
  onDelete: (id: string) => Promise<boolean>
  onToggleActive: (id: string, active: boolean) => Promise<boolean>
}

export const DepartmentList: React.FC<DepartmentListProps> = ({
  departments,
  users,
  boards,
  loading,
  onSave,
  onDelete,
  onToggleActive,
}) => {

  console.log('DepartmentList', departments, users, boards)
  const [search, setSearch] = useState('')
  const [showInputSearch, setShowInputSearch] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const departmentsPerPage = 9
  const [localLoading, setLocalLoading] = useState(false)
  const [sortedDepartments, setSortedDepartments] =
    useState<Department[]>(departments)

  // Atualiza os departamentos ordenados quando a prop de departamentos muda
  if (departments !== sortedDepartments && !localLoading) {
    setSortedDepartments(departments)
  }

  const handleCardClick = (department: Department) => {
    setSelectedDepartment(department)
    setIsEdit(true)
    setShowForm(true)
  }

  const handleAddNew = () => {
    setSelectedDepartment(null)
    setIsEdit(false)
    setShowForm(true)
  }

  const handleCloseForm = () => {
    setShowForm(false)
    setSelectedDepartment(null)
  }

  const handleSave = async (departmentData: DepartmentPayload) => {
    setLocalLoading(true)
    const success = await onSave(departmentData)
    setLocalLoading(false)

    if (success) {
      setShowForm(false)
    }
  }

  const handleDelete = async (id: string) => {
    setLocalLoading(true)
    const success = await onDelete(id)
    setLocalLoading(false)

    if (success && selectedDepartment?.id === id) {
      setShowForm(false)
    }
  }

  // Continuação de src/pages/ManagementScreen/components/DepartmentList/index.tsx

  const handleToggleActive = async (id: string, active: boolean) => {
    setLocalLoading(true)
    await onToggleActive(id, active)
    setLocalLoading(false)
  }

  // Filtra os departamentos baseado na pesquisa
  const filteredDepartments = sortedDepartments.filter(
    (department) =>
      department.name.toLowerCase().includes(search.toLowerCase()) ||
      department.description?.toLowerCase().includes(search.toLowerCase()),
  )

  // Paginação dos departamentos
  const getCurrentPageDepartments = () => {
    const startIndex = (currentPage - 1) * departmentsPerPage
    const endIndex = startIndex + departmentsPerPage
    return filteredDepartments.slice(startIndex, endIndex)
  }

  // Manipulador para ordenação
  const handleOrderBy = (orderType: string) => {
    setLocalLoading(true)
    let orderedDepartments = [...sortedDepartments]

    switch (orderType) {
      case 'recent':
        orderedDepartments.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )
        break
      case 'oldest':
        orderedDepartments.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        )
        break
      case 'a-z':
        orderedDepartments.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'z-a':
        orderedDepartments.sort((a, b) => b.name.localeCompare(a.name))
        break
      case 'active':
        orderedDepartments.sort((a, b) => Number(b.active) - Number(a.active))
        break
      case 'inactive':
        orderedDepartments.sort((a, b) => Number(a.active) - Number(b.active))
        break
      default:
        break
    }

    setSortedDepartments(orderedDepartments)
    setLocalLoading(false)
  }

  return (
    <S.TabContentContainer>
      <S.WrapperSerach>
        <div className="filters">
          {showInputSearch ? (
            <div className="inputSearchMobile">
              <SearchBarMobile
                placeholder={'Pesquisar departamento'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClose={() => setShowInputSearch(!showInputSearch)}
              />
            </div>
          ) : (
            <>
              <InputSearch
                placeholder="Pesquisar departamento"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onShowMobile={() => setShowInputSearch(!showInputSearch)}
              />
              <FilterListProfile orderBy={handleOrderBy} />
            </>
          )}
        </div>

        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddNew}
          className="newProfile"
          disabled={loading || localLoading}
          sx={{
            borderRadius: '6px',
            boxShadow: '0 2px 8px rgba(63, 81, 181, 0.25)',
            transition: 'all 0.2s ease',
            '&:hover': {
              boxShadow: '0 4px 12px rgba(63, 81, 181, 0.4)',
            },
          }}
        >
          Novo Departamento
        </Button>
      </S.WrapperSerach>

      {loading ? (
        <S.LoadingContainer>
          <CircularProgress size={40} />
          <p>Carregando departamentos...</p>
        </S.LoadingContainer>
      ) : (
        <>
          {filteredDepartments.length > 0 ? (
            <>
              <Box sx={{ padding: '24px 24px 0' }}>
                <S.CardGrid>
                  {getCurrentPageDepartments().map((department) => (
                    <DepartmentCard
                      key={department.id}
                      department={department}
                      onClick={() => handleCardClick(department)}
                      onDelete={() => handleDelete(department.id)}
                      onToggleActive={(active) =>
                        handleToggleActive(department.id, active)
                      }
                    />
                  ))}
                </S.CardGrid>
              </Box>

              {filteredDepartments.length > departmentsPerPage && (
                <Box sx={{ padding: '0 24px 24px' }}>
                  <PaginationComponent
                    currentPage={currentPage}
                    totalItems={filteredDepartments.length}
                    itemsPerPage={departmentsPerPage}
                    onPageChange={setCurrentPage}
                    itemName="Departamentos"
                  />
                </Box>
              )}
            </>
          ) : (
            <S.EmptyState>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4, 
                  borderRadius: 4,
                  backgroundColor: 'rgba(245, 247, 250, 0.8)',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  width: '100%',
                  maxWidth: '500px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Box 
                  sx={{ 
                    color: '#3f51b5', 
                    fontSize: '4rem',
                    backgroundColor: 'rgba(63, 81, 181, 0.1)',
                    borderRadius: '50%',
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2
                  }}
                >
                  <Business fontSize="inherit" />
                </Box>
                <h3>Nenhum departamento encontrado</h3>
                <p>
                  {search
                    ? `Não encontramos departamentos para "${search}". Tente outro termo ou crie um novo departamento.`
                    : 'Crie departamentos para organizar usuários e definir permissões de acesso ao sistema.'}
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<Add />}
                  onClick={handleAddNew}
                  sx={{
                    borderRadius: '6px',
                    boxShadow: '0 2px 8px rgba(63, 81, 181, 0.25)',
                    padding: '10px 24px',
                    fontWeight: 500,
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(63, 81, 181, 0.4)',
                    },
                  }}
                >
                  Criar Departamento
                </Button>
              </Paper>
            </S.EmptyState>
          )}
        </>
      )}

      {/* Dialog para o formulário de departamento */}
      <Dialog
        open={showForm}
        onClose={localLoading ? undefined : handleCloseForm}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
          },
        }}
        TransitionProps={{
          style: { transition: 'all 0.3s ease' }
        }}
      >
        <Box sx={{ p: 0 }}>
          <DepartmentForm
            department={selectedDepartment}
            isEdit={isEdit}
            users={users}
            boards={boards}
            onSave={handleSave}
            onDelete={handleDelete}
            onCancel={handleCloseForm}
            loading={localLoading}
            error={null}
          />
        </Box>
      </Dialog>
    </S.TabContentContainer>
  )
}

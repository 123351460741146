import React, { useState, useEffect, useRef } from 'react'
import {
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
  Button,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Badge,
} from '@mui/material'
import {
  Close,
  ShoppingCart,
  LocalOffer,
  Receipt,
  Person,
  Refresh,
  Add,
  FilterList,
  DragIndicator,
  Search,
  ArrowForward,
  MoreVert,
  ArrowBack,
  FullscreenExit,
  Fullscreen,
} from '@mui/icons-material'
import axios from 'axios'
import { llmEndpoint } from 'infra'
import { useParams } from 'react-router-dom'

/**
 * OmieWizard Draggable para exibir dados contextuais do cliente no chat
 *
 * @param {Object} props - Propriedades do componente
 * @param {boolean} props.open - Controla se o wizard está visível
 * @param {Function} props.onClose - Função para fechar o wizard
 * @param {Function} props.onSelectData - Função chamada quando dados são selecionados para inserção no chat
 * @param {string} props.apiKey - Chave da API do Omie
 * @param {string} props.apiSecret - Secret da API do Omie
 * @param {Object} props.clientInfo - Informações do cliente atual no chat, se disponível
 */
const OmieWizard = ({
  open,
  onClose,
  onSelectData,
  apiKey = '',
  apiSecret = '',
  clientInfo = null, // Informações do cliente no contexto atual
  zIndex = 1300, // Valor padrão
  onFocus,
}) => {
  const handleWizardClick = () => {
    if (onFocus) onFocus()
  }
  // Estado para gerenciar a posição do componente arrastável
  const [position, setPosition] = useState({ x: 20, y: 60 })
  const [isDragging, setIsDragging] = useState(false)
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState({ width: 480, height: 520 })
  const [isExpanded, setIsExpanded] = useState(false)

  // Estado para debounce da pesquisa
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const debounceTimerRef = useRef(null)

  // Estado para guias e dados
  const [activeTab, setActiveTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [clientFound, setClientFound] = useState(!!clientInfo)
  const [clientData, setClientData] = useState(clientInfo || null)
  const [products, setProducts] = useState([])
  const [purchaseHistory, setPurchaseHistory] = useState([])
  const [receivables, setReceivables] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [clientSearchMode, setClientSearchMode] = useState(false)
  const [clientSearchResults, setClientSearchResults] = useState([])

  // Ref para o componente arrastável
  const dragRef = useRef(null)

  // Efeito para carregar dados quando o componente abre
  useEffect(() => {
    if (open) {
      loadInitialData()
    }
  }, [open, clientInfo])

  // Efeito para debounce de pesquisa de produto
  useEffect(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current)
    }

    if (searchTerm.length >= 2 && activeTab === 0 && !clientSearchMode) {
      debounceTimerRef.current = setTimeout(() => {
        setDebouncedSearch(searchTerm)
      }, 500)
    }

    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }
    }
  }, [searchTerm, activeTab, clientSearchMode])

  // Efeito para buscar produtos quando o termo de pesquisa debounced muda
  useEffect(() => {
    if (debouncedSearch.length >= 2 && activeTab === 0 && !clientSearchMode) {
      fetchProducts(debouncedSearch)
    }
  }, [debouncedSearch])

  // Função para carregar dados iniciais com base no contexto do cliente
  const loadInitialData = async () => {
    setIsLoading(true)

    try {
      // Se temos informações do cliente, carregamos seus dados específicos
      if (clientInfo) {
        await Promise.all([
          fetchProductRecommendations(),
          fetchPurchaseHistory(),
          fetchReceivables(),
        ])
        setClientFound(true)
        setClientData(clientInfo)
      } else {
        // Se não temos cliente no contexto, apenas carregamos o catálogo de produtos
        await fetchProducts('')
        setClientFound(false)
        setClientData(null)
      }
    } catch (error) {
      console.error('Erro ao carregar dados iniciais:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Função para buscar produtos da API
  const fetchProducts = async (term) => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${llmEndpoint.url}/product/search`,
        {
          params: {
            term: term || '',
            page: 0,
            size: 10,
            sort: 'nome',
            direction: 'asc',
          },
          headers: {
            accept: '*/*',
          },
        }
      )
      
      if (response.data && response.data.content) {
        // Mapear a resposta da API para o formato que o componente espera
        const mappedProducts = response.data.content.map(product => ({
          id: product.id,
          nome: product.nome,
          codigo: product.codigo,
          valor: product.valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          valorNumerico: product.valor, // Manter o valor numérico para cálculos
          estoque: product.estoque || 0,
          isServico: product.isServico,
          recomendacao: clientFound ? 'Alta' : undefined // Simulação de recomendação
        }))
        
        setProducts(mappedProducts)
      } else {
        setProducts([])
      }
    } catch (error) {
      console.error('Erro ao buscar produtos:', error)
      setProducts([])
    } finally {
      setIsLoading(false)
    }
  }

  // Função para buscar empresas da API
  const searchClients = async (term) => {
    if (term.length < 3) return
    const { rpaId } = useParams()
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}/search`,
        {
          params: {
            term,
            page: 0,
            size: 10,
            sort: 'name',
            direction: 'asc',
          },
          headers: {
            accept: '*/*',
          },
        }
      )
      
      if (response.data && response.data.content) {
        // Mapear a resposta da API para o formato que o componente espera
        const mappedClients = response.data.content.map(client => ({
          id: client.id,
          nome: client.name,
          email: client.contacts && client.contacts.length > 0 ? client.contacts[0].email : 'Não disponível',
          telefone: client.contacts && client.contacts.length > 0 ? client.contacts[0].number : 'Não disponível',
          cnpj: formatDocument(client.document),
          documento: client.document,
          endereco: client.address ? formatAddress(client.address) : 'Endereço não disponível'
        }))
        
        setClientSearchResults(mappedClients)
      } else {
        setClientSearchResults([])
      }
    } catch (error) {
      console.error('Erro ao buscar clientes:', error)
      setClientSearchResults([])
    } finally {
      setIsLoading(false)
    }
  }

  // Funções para formatar dados
  const formatDocument = (document) => {
    if (!document) return 'Não disponível'
    
    // Remove caracteres não numéricos
    const numericOnly = document.replace(/\D/g, '')
    
    // Verifica se é um CNPJ (14 dígitos)
    if (numericOnly.length === 14) {
      return numericOnly.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      )
    }
    
    // Verifica se é um CPF (11 dígitos)
    if (numericOnly.length === 11) {
      return numericOnly.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        '$1.$2.$3-$4'
      )
    }
    
    // Retorna o documento como está se não reconhecer o padrão
    return document
  }

  const formatAddress = (address) => {
    if (!address) return 'Endereço não disponível'
    
    const parts = []
    
    if (address.street) parts.push(address.street)
    if (address.number) parts.push(address.number)
    if (address.complement) parts.push(address.complement)
    
    let line1 = parts.join(', ')
    
    const parts2 = []
    if (address.neighborhood) parts2.push(address.neighborhood)
    if (address.city) parts2.push(address.city)
    if (address.state) parts2.push(address.state)
    if (address.zipCode) parts2.push(address.zipCode)
    
    let line2 = parts2.join(', ')
    
    return line1 + (line2 ? ` - ${line2}` : '')
  }

  // Funções simuladas para buscar dados do cliente
  const fetchProductRecommendations = async () => {
    // Em um cenário real, essa função chamaria a API com base no ID do cliente
    return fetchProducts('')
  }

  const fetchPurchaseHistory = async () => {
    // Buscar histórico de compras do cliente
    setIsLoading(true)
    setTimeout(() => {
      setPurchaseHistory([
        {
          id: 1001,
          data: '12/02/2025',
          numero: 'PED-23567',
          valor: 'R$ 5.198,90',
          status: 'Entregue',
          itens: 3,
        },
        {
          id: 1002,
          data: '05/01/2025',
          numero: 'PED-22498',
          valor: 'R$ 899,90',
          status: 'Entregue',
          itens: 1,
        },
        {
          id: 1003,
          data: '29/11/2024',
          numero: 'PED-21345',
          valor: 'R$ 1.748,00',
          status: 'Entregue',
          itens: 2,
        },
      ])
      setIsLoading(false)
    }, 400)
  }

  const fetchReceivables = async () => {
    // Buscar contas a receber do cliente
    setIsLoading(true)
    setTimeout(() => {
      setReceivables([
        {
          id: 2001,
          vencimento: '15/04/2025',
          numero: 'FIN-34521',
          valor: 'R$ 1.433,00',
          status: 'A vencer',
        },
        {
          id: 2002,
          vencimento: '20/03/2025',
          numero: 'FIN-34126',
          valor: 'R$ 899,90',
          status: 'Vencido',
          diasAtraso: 5,
        },
      ])
      setIsLoading(false)
    }, 350)
  }

  // Buscar cliente pelo termo pesquisado
  const searchClient = (term) => {
    if (term.length < 3) {
      setClientSearchResults([])
      return
    }
    
    // Chamada real para API de empresas
    searchClients(term)
  }

  // Controle das guias
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  // Selecionar um item para inserir no chat
  const handleSelectItem = (item, type) => {
    setSelectedItem({ ...item, type })
  }

  // Inserir dados selecionados no chat
  const handleInsertData = () => {
    if (!selectedItem) return

    let formattedMessage = ''

    switch (selectedItem.type) {
      case 'product':
        formattedMessage = `**Produto Omie**\nNome: ${selectedItem.nome}\nCódigo: ${selectedItem.codigo}\nValor: ${selectedItem.valor}\nEstoque: ${selectedItem.estoque}`
        break
      case 'history':
        formattedMessage = `**Pedido Omie**\nNúmero: ${selectedItem.numero}\nData: ${selectedItem.data}\nValor: ${selectedItem.valor}\nStatus: ${selectedItem.status}`
        break
      case 'receivable':
        formattedMessage = `**Financeiro Omie**\nNúmero: ${selectedItem.numero}\nVencimento: ${selectedItem.vencimento}\nValor: ${selectedItem.valor}\nStatus: ${selectedItem.status}`
        break
      case 'client':
        formattedMessage = `**Cliente Omie**\nNome: ${selectedItem.nome}\nE-mail: ${selectedItem.email}\nTelefone: ${selectedItem.telefone}\nCNPJ: ${selectedItem.cnpj}`
        if (selectedItem.endereco && selectedItem.endereco !== 'Endereço não disponível') {
          formattedMessage += `\nEndereço: ${selectedItem.endereco}`
        }
        break
      default:
        formattedMessage = JSON.stringify(selectedItem, null, 2)
    }

    onSelectData(formattedMessage)
    setSelectedItem(null)
  }

  // Selecionar um cliente da busca
  const selectClient = (client) => {
    setClientData(client)
    setClientFound(true)
    setClientSearchMode(false)

    // Carregar dados específicos do cliente
    fetchProductRecommendations()
    fetchPurchaseHistory()
    fetchReceivables()
  }

  // Iniciar uma busca de cliente
  const startClientSearch = () => {
    setClientSearchMode(true)
    setActiveTab(0)
    setSearchTerm('')
    setClientSearchResults([])
  }

  // Cancelar busca de cliente
  const cancelClientSearch = () => {
    setClientSearchMode(false)

    if (clientInfo) {
      setClientData(clientInfo)
      setClientFound(true)
    } else {
      fetchProducts('')
    }
  }

  // Funções para arrastar o componente
  const handleMouseDown = (e) => {
    if (e.target.closest('.drag-handle')) {
      setIsDragging(true)
      const rect = dragRef.current.getBoundingClientRect()
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      })
    }
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y,
      })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  // Alternar entre modo expandido e normal
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  // Função para manipular a mudança no campo de pesquisa de cliente
  const handleClientSearchChange = (e) => {
    const term = e.target.value
    setSearchTerm(term)
    
    // Clear any existing timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current)
    }
    
    // Debounce para busca de clientes
    if (term.length >= 3) {
      debounceTimerRef.current = setTimeout(() => {
        searchClient(term)
      }, 500)
    } else {
      setClientSearchResults([])
    }
  }

  // Efeito para adicionar e remover eventos de mouse para arrastar
  useEffect(() => {
    if (open) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [open, isDragging, dragOffset])

  // Se o componente não estiver aberto, não renderize nada
  if (!open) return null

  // Dimensões com base no modo expandido
  const dimensions = isExpanded ? { width: 720, height: 600 } : size

  // Conteúdo para cada guia
  const renderTabContent = () => {
    if (clientSearchMode) {
      // Modo de busca de cliente
      return (
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 2, display: 'flex' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Buscar cliente por nome, e-mail ou CNPJ"
              value={searchTerm}
              onChange={handleClientSearchChange}
              size="small"
              sx={{ mr: 1 }}
              InputProps={{
                startAdornment: (
                  <Search color="action" sx={{ mr: 1, opacity: 0.5 }} />
                ),
              }}
            />
            <Button
              variant="contained"
              onClick={() => searchClient(searchTerm)}
              disabled={!searchTerm.trim() || searchTerm.length < 3 || isLoading}
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <Search />
              }
            >
              Buscar
            </Button>
          </Box>

          {searchTerm.length > 0 && searchTerm.length < 3 && (
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 2 }}>
              Digite pelo menos 3 caracteres para buscar
            </Typography>
          )}

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <List sx={{ maxHeight: dimensions.height - 200, overflow: 'auto' }}>
              {Array.isArray(clientSearchResults) && clientSearchResults.length > 0 ? (
                clientSearchResults.map((client) => (
                  <ListItem
                    key={client.id}
                    button
                    onClick={() => {
                      selectClient(client)
                      // Também selecionamos o cliente para possível inserção
                      handleSelectItem(client, 'client')
                    }}
                    divider
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Person />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={client.nome}
                      secondary={
                        <>
                          <Typography variant="body2" component="span">
                            {client.email !== 'Não disponível' ? client.email : 'Email não disponível'}
                          </Typography>
                          <br />
                          <Typography variant="body2" component="span">
                            CNPJ: {client.cnpj} | Tel: {client.telefone !== 'Não disponível' ? client.telefone : 'Não disponível'}
                          </Typography>
                        </>
                      }
                    />
                    <ArrowForward color="primary" />
                  </ListItem>
                ))
              ) : searchTerm.trim() && searchTerm.length >= 3 ? (
                <ListItem>
                  <ListItemText
                    primary="Nenhum cliente encontrado"
                    secondary="Tente outros termos de busca"
                  />
                </ListItem>
              ) : null}
            </List>
          )}
        </Box>
      )
    }

    // Para cliente não encontrado ou novo lead, mostrar apenas produtos
    if (!clientFound) {
      return (
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle1">Catálogo de Produtos</Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Person />}
              onClick={startClientSearch}
            >
              Buscar Cliente
            </Button>
          </Box>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Filtrar produtos"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <Search color="action" sx={{ mr: 1, opacity: 0.5 }} />
              ),
            }}
          />

          {searchTerm.length > 0 && searchTerm.length < 2 && (
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 2 }}>
              Digite pelo menos 2 caracteres para filtrar
            </Typography>
          )}

          {renderProductList()}
        </Box>
      )
    }

    // Renderização normal para cliente existente
    switch (activeTab) {
      case 0: // Produtos Recomendados / Catálogo
        return (
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                mb: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle1">Produtos Recomendados</Typography>
              <Tooltip title="Atualizar recomendações">
                <IconButton size="small" onClick={fetchProductRecommendations}>
                  <Refresh fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>

            <TextField
              fullWidth
              variant="outlined"
              placeholder="Filtrar produtos"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              sx={{ mb: 2 }}
              InputProps={{
                startAdornment: (
                  <Search color="action" sx={{ mr: 1, opacity: 0.5 }} />
                ),
              }}
            />

            {searchTerm.length > 0 && searchTerm.length < 2 && (
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 2 }}>
                Digite pelo menos 2 caracteres para filtrar
              </Typography>
            )}

            {renderProductList()}
          </Box>
        )

      case 1: // Últimas Compras
        return (
          <Box sx={{ p: 2 }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <List
                sx={{ maxHeight: dimensions.height - 200, overflow: 'auto' }}
              >
                {purchaseHistory.length > 0 ? (
                  purchaseHistory.map((purchase) => (
                    <ListItem
                      key={purchase.id}
                      button
                      onClick={() => handleSelectItem(purchase, 'history')}
                      selected={
                        selectedItem &&
                        selectedItem.id === purchase.id &&
                        selectedItem.type === 'history'
                      }
                      divider
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          <ShoppingCart />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`Pedido ${purchase.numero}`}
                        secondary={
                          <>
                            <Typography variant="body2" component="span">
                              {purchase.data} | {purchase.valor}
                            </Typography>
                            <br />
                            <Typography variant="body2" component="span">
                              {purchase.itens} itens | Status: {purchase.status}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="Nenhum histórico de compra encontrado" />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        )

      case 2: // Financeiro
        return (
          <Box sx={{ p: 2 }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <List
                sx={{ maxHeight: dimensions.height - 200, overflow: 'auto' }}
              >
                {receivables.length > 0 ? (
                  receivables.map((receivable) => (
                    <ListItem
                      key={receivable.id}
                      button
                      onClick={() => handleSelectItem(receivable, 'receivable')}
                      selected={
                        selectedItem &&
                        selectedItem.id === receivable.id &&
                        selectedItem.type === 'receivable'
                      }
                      divider
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            bgcolor:
                              receivable.status === 'Vencido'
                                ? 'error.main'
                                : 'success.main',
                          }}
                        >
                          <Receipt />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={receivable.numero}
                        secondary={
                          <>
                            <Typography variant="body2" component="span">
                              Vencimento: {receivable.vencimento} |{' '}
                              {receivable.valor}
                            </Typography>
                            <br />
                            <Typography
                              variant="body2"
                              component="span"
                              color={
                                receivable.status === 'Vencido'
                                  ? 'error.main'
                                  : 'text.primary'
                              }
                            >
                              Status: {receivable.status}
                              {receivable.diasAtraso
                                ? ` (${receivable.diasAtraso} dias)`
                                : ''}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="Nenhum título a receber encontrado" />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        )

      default:
        return null
    }
  }

  // Renderiza a lista de produtos filtrada
  const renderProductList = () => {
    const filteredProducts = products.filter((product) =>
      JSON.stringify(product).toLowerCase().includes(searchTerm.toLowerCase()),
    )

    return isLoading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    ) : (
      <Grid container spacing={2}>
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Grid item xs={12} sm={isExpanded ? 6 : 12} key={product.id}>
              <Card
                variant="outlined"
                sx={{
                  cursor: 'pointer',
                  border:
                    selectedItem &&
                    selectedItem.id === product.id &&
                    selectedItem.type === 'product'
                      ? '2px solid'
                      : '1px solid',
                  borderColor:
                    selectedItem &&
                    selectedItem.id === product.id &&
                    selectedItem.type === 'product'
                      ? 'primary.main'
                      : 'divider',
                  '&:hover': {
                    borderColor: 'primary.main',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  },
                }}
                onClick={() => handleSelectItem(product, 'product')}
              >
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="subtitle1"
                      component="div"
                      gutterBottom
                    >
                      {product.nome}
                    </Typography>
                    {product.recomendacao && (
                      <Chip
                        size="small"
                        label={`Rec. ${product.recomendacao}`}
                        color={
                          product.recomendacao === 'Alta'
                            ? 'success'
                            : product.recomendacao === 'Média'
                              ? 'warning'
                              : 'default'
                        }
                      />
                    )}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Código: {product.codigo}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography variant="body1" fontWeight="bold">
                      {product.valor}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Estoque: {product.estoque}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" py={2}>
              Nenhum produto encontrado para "{searchTerm}"
            </Typography>
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Paper
      ref={dragRef}
      onMouseDown={(e) => {
        handleMouseDown(e);
        handleWizardClick(); // Adicione esta chamada
      }}
      elevation={3}
      sx={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        width: dimensions.width,
        height: dimensions.height,
        zIndex,
        borderRadius: 2,
        overflow: 'hidden',
        resize: 'both',
        transition: 'width 0.3s, height 0.3s',
        cursor: isDragging ? 'grabbing' : 'auto',
      }}
    >
      {/* Cabeçalho */}
      <Box
        className="drag-handle"
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'grab',
          '&:active': {
            cursor: 'grabbing',
          },
        }}
      >
        <Box display="flex" alignItems="center">
          <DragIndicator fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle1">
            {clientSearchMode
              ? 'Buscar Cliente Omie'
              : clientFound
                ? `Cliente: ${clientData.nome}`
                : 'Catálogo de Produtos Omie'}
          </Typography>
        </Box>
        <Box>
          <Tooltip title={isExpanded ? 'Restaurar tamanho' : 'Tela cheia'}>
            <IconButton size="small" color="inherit" onClick={toggleExpand}>
              {isExpanded ? (
                <FullscreenExit fontSize="small" />
              ) : (
                <Fullscreen fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Fechar">
            <IconButton size="small" color="inherit" onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* Conteúdo */}
      <Box sx={{ height: 'calc(100% - 112px)', overflow: 'hidden' }}>
        {/* Abas para cliente encontrado */}
        {clientFound && !clientSearchMode && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab
              label="Produtos"
              icon={<LocalOffer fontSize="small" />}
              iconPosition="start"
            />
            <Tab
              label="Compras"
              icon={<ShoppingCart fontSize="small" />}
              iconPosition="start"
              disabled={isLoading}
            />
            <Tab
              label="Financeiro"
              icon={<Receipt fontSize="small" />}
              iconPosition="start"
              disabled={isLoading}
            />
          </Tabs>
        )}

        {/* Conteúdo de cada aba */}
        <Box
          sx={{
            height:
              clientFound && !clientSearchMode ? 'calc(100% - 48px)' : '100%',
            overflow: 'auto',
          }}
        >
          {clientSearchMode && (
            <Box px={2} py={1} display="flex" alignItems="center">
              <Button
                startIcon={<ArrowBack />}
                size="small"
                onClick={cancelClientSearch}
                sx={{ mr: 1 }}
              >
                Voltar
              </Button>
              <Typography variant="body2" color="text.secondary">
                Busque um cliente para ver histórico e recomendações
              </Typography>
            </Box>
          )}
          {renderTabContent()}
        </Box>
      </Box>

      {/* Barra de ações */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          p: 1.5,
          borderTop: '1px solid',
          borderColor: 'divider',
          bgcolor: 'background.paper',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          {selectedItem && (
            <Typography variant="caption" color="primary.main">
              {selectedItem.type === 'product'
                ? 'Produto selecionado: '
                : selectedItem.type === 'history'
                  ? 'Pedido selecionado: '
                  : selectedItem.type === 'receivable'
                    ? 'Título selecionado: '
                    : 'Item selecionado: '}
              <b>{selectedItem.nome || selectedItem.numero}</b>
            </Typography>
          )}
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedItem}
            onClick={handleInsertData}
          >
            Inserir no Chat
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default OmieWizard
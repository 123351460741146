// src/pages/Main/containers/gerenciamento/pages/ManagementScreen/ManagementScreen.tsx
import { useState, useEffect } from 'react'
import { 
  Box, 
  Tabs, 
  Tab, 
  Typography, 
  Snackbar, 
  Alert, 
  Paper, 
  Grid, 
  Card, 
  CardContent,
  Chip,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { 
  Group, 
  Person, 
  Business, 
  SupervisorAccount, 
  CheckCircle, 
  DoNotDisturb, 
  Dashboard 
} from '@mui/icons-material'
import { useParams } from 'react-router-dom'

import { ApiService } from '../../services/api'
import { Department, User, Board, DepartmentPayload } from '../../types'
import { DepartmentList } from './components/DepartmentList'
import { UserList } from './components/UserList'
import * as S from './styles'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`management-tabpanel-${index}`}
      aria-labelledby={`management-tab-${index}`}
      style={{ height: 'calc(100% - 56px)', overflow: 'hidden' }} // Altura total menos a altura da tab
      {...other}
    >
      {value === index && (
        <S.TabContentContainer>
          {children}
        </S.TabContentContainer>
      )}
    </div>
  );
}
export const ManagementScreen = () => {
  const [tabValue, setTabValue] = useState(0)
  const [departments, setDepartments] = useState<Department[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [boards, setBoards] = useState<Board[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [notification, setNotification] = useState<{type: 'success' | 'error', message: string} | null>(null)
  const [showDashboard, setShowDashboard] = useState(true)
  
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  
  // Obtenha o rpaId da URL ou use um valor padrão para desenvolvimento
  const { rpaId = '323457c4-abce-4724-9fce-de4021e1717b' } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    setError(null)
    try {
      // Buscar todos os dados necessários em paralelo
      const [departmentsData, usersData, boardsData] = await Promise.all([
        ApiService.getDepartments(rpaId),
        ApiService.getUsers(rpaId),
        ApiService.getBoards(rpaId)
      ])
      
      console.log('Departamentos:', departmentsData)
      setDepartments(departmentsData)
      setUsers(usersData)
      setBoards(boardsData)
    } catch (error) {
      console.error('Erro ao buscar dados:', error)
      setError(
        'Não foi possível carregar os dados. Tente novamente mais tarde.'
      )
      showNotification('error', 'Falha ao carregar os dados. Verifique sua conexão.')
    } finally {
      setLoading(false)
    }
  }
  
  // Função para calcular estatísticas
  const getStats = () => {
    const activeDepartments = departments.filter(dept => dept.active).length
    const inactiveDepartments = departments.length - activeDepartments
    
    const activeUsers = users.filter(user => user.active).length
    const inactiveUsers = users.length - activeUsers
    
    return {
      departments: {
        total: departments.length,
        active: activeDepartments,
        inactive: inactiveDepartments
      },
      users: {
        total: users.length,
        active: activeUsers,
        inactive: inactiveUsers
      },
      boards: boards.length
    }
  }
  
  // Componente do painel de estatísticas
  const DashboardPanel = () => {
    const stats = getStats()
    
    return (
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3, 
          borderRadius: 2,
          backgroundColor: 'rgba(245, 247, 250, 0.8)',
          border: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ borderLeft: '4px solid #3f51b5', height: '100%' }}>
              <CardContent>
                <Box display="flex" alignItems="center" mb={1}>
                  <Business sx={{ mr: 1, color: '#3f51b5' }} />
                  <Typography variant="h6" color="textSecondary">
                    Departamentos
                  </Typography>
                </Box>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  {stats.departments.total}
                </Typography>
                <Box display="flex" gap={1} flexWrap="wrap">
                  <Chip 
                    icon={<CheckCircle fontSize="small" />} 
                    label={`${stats.departments.active} ativos`} 
                    size="small" 
                    color="success" 
                    variant="outlined"
                  />
                  <Chip 
                    icon={<DoNotDisturb fontSize="small" />} 
                    label={`${stats.departments.inactive} inativos`} 
                    size="small" 
                    color="error" 
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Card sx={{ borderLeft: '4px solid #f50057', height: '100%' }}>
              <CardContent>
                <Box display="flex" alignItems="center" mb={1}>
                  <SupervisorAccount sx={{ mr: 1, color: '#f50057' }} />
                  <Typography variant="h6" color="textSecondary">
                    Usuários
                  </Typography>
                </Box>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  {stats.users.total}
                </Typography>
                <Box display="flex" gap={1} flexWrap="wrap">
                  <Chip 
                    icon={<CheckCircle fontSize="small" />} 
                    label={`${stats.users.active} ativos`} 
                    size="small" 
                    color="success" 
                    variant="outlined"
                  />
                  <Chip 
                    icon={<DoNotDisturb fontSize="small" />} 
                    label={`${stats.users.inactive} inativos`} 
                    size="small" 
                    color="error" 
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Card sx={{ borderLeft: '4px solid #4caf50', height: '100%' }}>
              <CardContent>
                <Box display="flex" alignItems="center" mb={1}>
                  <Dashboard sx={{ mr: 1, color: '#4caf50' }} />
                  <Typography variant="h6" color="textSecondary">
                    Painéis
                  </Typography>
                </Box>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  {stats.boards}
                </Typography>
                <Box display="flex" gap={1} flexWrap="wrap">
                  <Chip 
                    label="Quadros configurados" 
                    size="small" 
                    color="primary" 
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }

  const showNotification = (type: 'success' | 'error', message: string) => {
    setNotification({ type, message });
  };

  const closeNotification = () => {
    setNotification(null);
  };

  // ===== FUNÇÕES DE GERENCIAMENTO DE DEPARTAMENTOS =====
  const handleDepartmentSave = async (departmentData: DepartmentPayload) => {
    setLoading(true)
    setError(null)
    try {
      if (departmentData.id) {
        // Atualizar departamento existente
        await ApiService.updateDepartment(rpaId, departmentData.id, departmentData)
        showNotification('success', 'Departamento atualizado com sucesso!')
      } else {
        // Criar novo departamento
        await ApiService.createDepartment(rpaId, departmentData)
        showNotification('success', 'Departamento criado com sucesso!')
      }
      // Atualizar a lista após sucesso
      await fetchData()
      return true
    } catch (error) {
      console.error('Erro ao salvar departamento:', error)
      setError('Não foi possível salvar o departamento. Tente novamente.')
      showNotification('error', 'Falha ao salvar departamento. Verifique os dados e tente novamente.')
      return false
    } finally {
      setLoading(false)
    }
  }

  const handleDepartmentDelete = async (departmentId: string) => {
    if (!confirm('Tem certeza que deseja excluir este departamento?')) {
      return false
    }

    setLoading(true)
    setError(null)
    try {
      await ApiService.deleteDepartment(departmentId)
      showNotification('success', 'Departamento excluído com sucesso!')
      
      // Atualizar a lista após sucesso
      await fetchData()
      return true
    } catch (error) {
      console.error('Erro ao excluir departamento:', error)
      setError('Não foi possível excluir o departamento. Tente novamente.')
      showNotification('error', 'Falha ao excluir departamento. Tente novamente.')
      return false
    } finally {
      setLoading(false)
    }
  }

  const handleToggleDepartmentActive = async (departmentId: string, active: boolean) => {
    setLoading(true)
    setError(null)
    try {
      await ApiService.toggleDepartmentActive(departmentId, active)
      showNotification('success', `Departamento ${active ? 'ativado' : 'desativado'} com sucesso!`)
      
      // Atualizar a lista após sucesso
      await fetchData()
      return true
    } catch (error) {
      console.error('Erro ao alterar status do departamento:', error)
      setError(
        'Não foi possível alterar o status do departamento. Tente novamente.'
      )
      showNotification('error', 'Falha ao alterar status do departamento.')
      return false
    } finally {
      setLoading(false)
    }
  }

  // ===== FUNÇÕES DE GERENCIAMENTO DE USUÁRIOS =====
  const handleUserSave = async (userData: User) => {
    setLoading(true)
    setError(null)
    try {
      if (userData.id) {
        // Atualizar usuário existente
        await ApiService.updateUser(userData.id, userData)
        showNotification('success', 'Usuário atualizado com sucesso!')
      } else {
        // Criar novo usuário
        await ApiService.createUser(userData)
        showNotification('success', 'Usuário criado com sucesso!')
      }
      // Atualizar a lista após sucesso
      await fetchData()
      return true
    } catch (error) {
      console.error('Erro ao salvar usuário:', error)
      setError('Não foi possível salvar o usuário. Tente novamente.')
      showNotification('error', 'Falha ao salvar usuário. Verifique os dados e tente novamente.')
      return false
    } finally {
      setLoading(false)
    }
  }

  const handleUserDelete = async (userId: string) => {
    if (!confirm('Tem certeza que deseja excluir este usuário?')) {
      return false
    }

    setLoading(true)
    setError(null)
    try {
      await ApiService.deleteUser(userId)
      showNotification('success', 'Usuário excluído com sucesso!')
      
      // Atualizar a lista após sucesso
      await fetchData()
      return true
    } catch (error) {
      console.error('Erro ao excluir usuário:', error)
      setError('Não foi possível excluir o usuário. Tente novamente.')
      showNotification('error', 'Falha ao excluir usuário. Tente novamente.')
      return false
    } finally {
      setLoading(false)
    }
  }

  const handleToggleUserActive = async (userId: string, active: boolean) => {
    setLoading(true)
    setError(null)
    try {
      await ApiService.toggleUserActive(userId, active)
      showNotification('success', `Usuário ${active ? 'ativado' : 'desativado'} com sucesso!`)
      
      // Atualizar a lista após sucesso
      await fetchData()
      return true
    } catch (error) {
      console.error('Erro ao alterar status do usuário:', error)
      setError(
        'Não foi possível alterar o status do usuário. Tente novamente.'
      )
      showNotification('error', 'Falha ao alterar status do usuário.')
      return false
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <Box display="flex" alignItems="center" gap={1}>
            <SupervisorAccount color="primary" fontSize="large" />
            <h1 className="text-large font-semibold">
              Gerenciamento de Acesso
            </h1>
          </Box>
          <p style={{ marginTop: '8px', marginBottom: '16px' }}>
            Gerencie departamentos, usuários e níveis de acesso no sistema.
          </p>
          
          {/* Painel de estatísticas */}
          {showDashboard && !loading && <DashboardPanel />}
        </S.WrapperHeader>
      </S.Container>

      <S.Content>
        <Paper 
          elevation={0} 
          sx={{ 
            borderRadius: '8px 8px 0 0',
            overflow: 'hidden',
            boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
          }}
        >
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            aria-label="management tabs"
            variant={isMobile ? "fullWidth" : "standard"}
            sx={{ 
              backgroundColor: '#fff',
              '& .MuiTab-root': { 
                fontSize: '0.95rem', 
                fontWeight: 500,
                textTransform: 'none',
                minHeight: '60px',
                transition: 'all 0.2s ease-in-out',
                '&.Mui-selected': {
                  fontWeight: 600,
                  backgroundColor: 'rgba(63, 81, 181, 0.04)'
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              },
              '& .MuiTabs-indicator': {
                height: 3,
                borderRadius: '3px 3px 0 0'
              }
            }}
          >
            <Tab 
              label={isMobile ? "Departamentos" : "Gerenciar Departamentos"} 
              icon={<Business />} 
              iconPosition="start"
              sx={{ px: isTablet ? 2 : 3 }}
            />
            <Tab 
              label={isMobile ? "Usuários" : "Gerenciar Usuários"} 
              icon={<Person />} 
              iconPosition="start"
              sx={{ px: isTablet ? 2 : 3 }}
            />
          </Tabs>
        </Paper>

        {/* Mensagem de erro, se houver */}
        {error && (
          <Paper
            sx={{
              p: 2,
              m: 2,
              backgroundColor: '#ffebee',
              color: '#d32f2f',
              borderRadius: 2,
              border: '1px solid rgba(211, 47, 47, 0.2)',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <DoNotDisturb color="error" />
            <Typography>{error}</Typography>
          </Paper>
        )}

        <TabPanel value={tabValue} index={0}>
          <DepartmentList 
            departments={departments} 
            users={users}
            boards={boards}
            loading={loading}
            onSave={handleDepartmentSave}
            onDelete={handleDepartmentDelete}
            onToggleActive={handleToggleDepartmentActive}
          />
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          <UserList
            users={users}
            loading={loading}
            onSave={handleUserSave}
            onDelete={handleUserDelete}
            onToggleActive={handleToggleUserActive}
          />
        </TabPanel>
      </S.Content>

      {/* Notificações Toast */}
      <Snackbar 
        open={!!notification} 
        autoHideDuration={6000} 
        onClose={closeNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {notification && (
          <Alert 
            onClose={closeNotification} 
            severity={notification.type} 
            sx={{ 
              width: '100%',
              borderRadius: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
              '& .MuiAlert-message': { fontWeight: 500 },
              '& .MuiAlert-icon': { fontSize: '1.2rem' }
            }}
          >
            {notification.message}
          </Alert>
        )}
      </Snackbar>
    </>
  )
}
// ChatStore.ts
import { llmEndpoint } from "infra";
import { create } from "zustand";
import { devtools, DevtoolsOptions } from "zustand/middleware";

import {
  BroadcastList,
  Company,
  Contact,
  Template,
} from "../hooks/domainTypes";

interface ChatState {
  contacts: Contact[];
  companies: Company[];
  templates: Template[];
  broadcastLists: BroadcastList[];
  rpaId: string | null;
  isLoading: boolean;

  setContacts: (contacts: Contact[]) => void;
  setCompanies: (companies: Company[]) => void;
  setBroadcastLists: (broadcastLists: BroadcastList[]) => void;
  setTemplates: (templates: Template[]) => void;
  setRpaId: (rpaId: string) => void;
  fetchContacts: () => Promise<Contact[] | undefined>;
  fetchCompanies: () => Promise<Company[] | undefined>;
  fetchBroadcastLists: () => Promise<BroadcastList[] | undefined>;
  fetchTemplates: () => Promise<Template[] | undefined>;
  initialize: (rpaId: string) => Promise<void>;
}

// Use um tipo mais específico para o middleware
type ChatStore = ReturnType<typeof createChatStore>;

// Crie a store em uma função separada para melhor inferência de tipo
const createChatStore = (options?: DevtoolsOptions) => {
  return create<ChatState>()(
    devtools(
      (set, get) => ({
        // Estados
        contacts: [] as Contact[],
        companies: [] as Company[],
        templates: [] as Template[],
        broadcastLists: [] as BroadcastList[],
        rpaId: null,
        isLoading: false,

        // Setters
        setContacts: (contacts: Contact[]) => set({ contacts }),
        setCompanies: (companies: Company[]) => set({ companies }),
        setBroadcastLists: (broadcastLists: BroadcastList[]) =>
          set({ broadcastLists }),
        setTemplates: (templates: Template[]) => set({ templates }),
        setRpaId: (rpaId: string) => set({ rpaId }),

        // Funções de fetch
        fetchContacts: async () => {
          const rpaId = get().rpaId;
          if (!rpaId) return;
          try {
            const response = await fetch(
              `${llmEndpoint.url}/whatsapp/contacts/${rpaId}`,
            );
            if (!response.ok) throw new Error("Erro ao buscar contatos");
            const data = await response.json();
            set({ contacts: data });
            return data;
          } catch (error) {
            console.error("Erro ao buscar contatos:", error);
            return [];
          }
        },

        fetchCompanies: async () => {
          const rpaId = get().rpaId;
          if (!rpaId) return;
          try {
            const response = await fetch(
              `${llmEndpoint.url}/whatsapp/enterprise/${rpaId}`,
            );
            if (!response.ok) throw new Error("Erro ao buscar empresas");
            const data = await response.json();
            set({ companies: data });
            return data;
          } catch (error) {
            console.error("Erro ao buscar empresas:", error);
            return [];
          }
        },

        fetchBroadcastLists: async () => {
          const rpaId = get().rpaId;
          if (!rpaId) return;
          try {
            const response = await fetch(
              `${llmEndpoint.url}/whatsapp/broadcast-list/${rpaId}`,
            );
            if (!response.ok)
              throw new Error("Erro ao buscar listas de broadcast");
            const data = await response.json();
            set({ broadcastLists: data });
            return data;
          } catch (error) {
            console.error("Erro ao buscar listas de broadcast:", error);
            return [];
          }
        },

        fetchTemplates: async () => {
          const rpaId = get().rpaId;
          if (!rpaId) return;
          try {
            const response = await fetch(
              `${llmEndpoint.url}/whatsapp/chat-template/${rpaId}`,
            );
            if (!response.ok) throw new Error("Erro ao buscar templates");
            const data = await response.json();
            set({ templates: data });
            return data;
          } catch (error) {
            console.error("Erro ao buscar templates:", error);
            return [];
          }
        },

        // Função de inicialização que carrega todos os dados
        initialize: async (rpaId: string) => {
          // Primeiro define o rpaId
          set({ rpaId, isLoading: true });
          try {
            // Inicia todas as chamadas em paralelo
            const promises = [
              get().fetchContacts(),
              get().fetchCompanies(),
              get().fetchBroadcastLists(),
              get().fetchTemplates(),
            ];
            // Aguarda todas as chamadas terminarem
            await Promise.all(promises);
            console.log("Inicialização concluída com sucesso");
          } catch (error) {
            console.error("Erro durante a inicialização:", error);
          } finally {
            set({ isLoading: false });
          }
        },
      }),
      { name: "ChatStore", ...(options || {}) },
    ),
  );
};

// Exporte a store criada pela função
export const useStore = createChatStore();

import React, { useState } from 'react'
import {
  Button,
  Menu,
  MenuItem,
  Box,
  Divider,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { FilterList } from '@mui/icons-material'

interface FilterListProfileProps {
  orderBy: (value: string) => void
}

export const FilterListProfile: React.FC<FilterListProfileProps> = ({
  orderBy,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedFilter, setSelectedFilter] = useState('recent')

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSelectedFilter(value)
    orderBy(value)
    handleClose()
  }

  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<FilterList />}
        onClick={handleClick}
        sx={{
          height: '40px',
          fontSize: '14px',
          color: '#637381',
          borderColor: '#E4E7E9',
          '&:hover': {
            borderColor: '#919EAB',
            backgroundColor: 'transparent',
          },
        }}
      >
        Filtrar
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            mt: 1,
            width: '250px',
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" fontWeight={600} gutterBottom>
            Ordenar por
          </Typography>
          <Divider sx={{ my: 1 }} />
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              aria-label="order"
              name="order"
              value={selectedFilter}
              onChange={handleFilterChange}
            >
              <FormControlLabel
                value="recent"
                control={<Radio size="small" />}
                label="Mais recentes"
              />
              <FormControlLabel
                value="oldest"
                control={<Radio size="small" />}
                label="Mais antigos"
              />
              <FormControlLabel
                value="a-z"
                control={<Radio size="small" />}
                label="Nome (A-Z)"
              />
              <FormControlLabel
                value="z-a"
                control={<Radio size="small" />}
                label="Nome (Z-A)"
              />
              <FormControlLabel
                value="active"
                control={<Radio size="small" />}
                label="Ativos primeiro"
              />
              <FormControlLabel
                value="inactive"
                control={<Radio size="small" />}
                label="Inativos primeiro"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Menu>
    </div>
  )
}
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  InputAdornment,
  IconButton,
  CircularProgress,
  Chip,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Close as CloseIcon } from '@mui/icons-material';
import { Product } from './types/CardTypes';
import { useParams } from 'react-router-dom';

// Categorias predefinidas
const categoriasPredefinidas = [
  'Eletrônicos',
  'Móveis',
  'Vestuário',
  'Alimentação',
  'Beleza',
  'Informática',
  'Consultoria',
  'Desenvolvimento',
  'Manutenção',
  'Treinamento',
  'Suporte Técnico',
  'Design'
];

// Unidades de medida predefinidas
const unidadesMedida = [
  'Unidade',
  'Kg',
  'g',
  'L',
  'ml',
  'Metro',
  'cm',
  'm²',
  'Pacote',
  'Caixa',
  'Hora'
];

interface NewProductDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (product: Product) => void;
  productId?: string;
  isEdit?: boolean;
}

const NewProductDialog: React.FC<NewProductDialogProps> = ({
  open,
  onClose,
  onSave,
  productId,
  isEdit = false
}) => {
  const { rpaId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  
  // Estados para os campos do produto
  const [produtoNome, setProdutoNome] = useState('');
  const [produtoDescricao, setProdutoDescricao] = useState('');
  const [produtoValor, setProdutoValor] = useState('');
  const [produtoCodigo, setProdutoCodigo] = useState('');
  const [produtoCategoria, setProdutoCategoria] = useState('');
  const [produtoIsServico, setProdutoIsServico] = useState(false);
  const [produtoEstoque, setProdutoEstoque] = useState('');
  const [produtoUnidadeMedida, setProdutoUnidadeMedida] = useState('');
  const [produtoMarca, setProdutoMarca] = useState('');
  const [produtoFornecedor, setProdutoFornecedor] = useState('');
  const [produtoTags, setProdutoTags] = useState('');

  useEffect(() => {
    if (open && isEdit && productId) {
      fetchProductData();
    } else if (open && !isEdit) {
      // Resetar o formulário para criar novo produto
      setProdutoNome('');
      setProdutoDescricao('');
      setProdutoValor('');
      setProdutoCodigo('');
      setProdutoCategoria('');
      setProdutoIsServico(false);
      setProdutoEstoque('');
      setProdutoUnidadeMedida('');
      setProdutoMarca('');
      setProdutoFornecedor('');
      setProdutoTags('');
    }
  }, [open, isEdit, productId]);

  const fetchProductData = async () => {
    if (!productId || !rpaId) return;
    
    setLoading(true);
    try {
      const axios = (await import('axios')).default;
      const { llmEndpoint } = await import('infra');
      
      const response = await axios.get(
        `${llmEndpoint.url}/product/${rpaId}/${productId}`,
        {
          headers: {
            accept: '*/*',
          },
        }
      );
      
      if (response.data) {
        const productData = response.data;
        setProdutoNome(productData.nome || '');
        setProdutoDescricao(productData.descricao || '');
        setProdutoValor(productData.valor.toString() || '');
        setProdutoCodigo(productData.codigo || '');
        setProdutoCategoria(productData.categoria || '');
        setProdutoIsServico(productData.isServico || false);
        setProdutoEstoque(productData.estoque?.toString() || '');
        setProdutoUnidadeMedida(productData.unidadeMedida || '');
        setProdutoMarca(productData.marca || '');
        setProdutoFornecedor(productData.fornecedor || '');
        setProdutoTags(productData.tags || '');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do produto:', error);
    } finally {
      setLoading(false);
    }
  };

  // Formatação de valores monetários
  const handleValorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valor = e.target.value.replace(/[^0-9.]/g, '');
    if (valor === '') {
      setProdutoValor('');
      return;
    }
    
    setProdutoValor(valor);
  };

  const handleSubmit = async () => {
    if (!produtoNome || !produtoCodigo || !produtoValor) {
      // Aqui você poderia adicionar validação mais completa
      return;
    }

    setSaving(true);
    try {
      const productData = {
        nome: produtoNome,
        descricao: produtoDescricao,
        valor: parseFloat(produtoValor),
        codigo: produtoCodigo,
        categoria: produtoCategoria,
        isServico: produtoIsServico,
        estoque: produtoIsServico ? undefined : produtoEstoque ? parseInt(produtoEstoque) : undefined,
        unidadeMedida: produtoUnidadeMedida || undefined,
        marca: produtoMarca || undefined,
        fornecedor: produtoFornecedor || undefined,
        tags: produtoTags
      };

      if (isEdit && productId && rpaId) {
        // Atualizando produto existente
        const axios = (await import('axios')).default;
        const { llmEndpoint } = await import('infra');
        
        const response = await axios.put(
          `${llmEndpoint.url}/product/${rpaId}/${productId}`,
          productData,
          {
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (response.data) {
          const updatedProduct: Product = {
            id: productId,
            nome: produtoNome,
            valor: parseFloat(produtoValor),
            codigo: produtoCodigo,
            tipo: produtoIsServico ? 'servico' : 'produto'
          };
          
          onSave(updatedProduct);
        }
      } else if (rpaId) {
        // Criando novo produto
        const axios = (await import('axios')).default;
        const { llmEndpoint } = await import('infra');
        
        const response = await axios.post(
          `${llmEndpoint.url}/product/${rpaId}`,
          productData,
          {
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (response.data && response.data.id) {
          const newProduct: Product = {
            id: response.data.id,
            nome: produtoNome,
            valor: parseFloat(produtoValor),
            codigo: produtoCodigo,
            tipo: produtoIsServico ? 'servico' : 'produto'
          };
          
          onSave(newProduct);
        }
      }
      
      onClose();
    } catch (error) {
      console.error('Erro ao salvar produto:', error);
    } finally {
      setSaving(false);
    }
  };

  // Extrair tags para exibição
  const extractTags = (tagString: string): string[] => {
    if (!tagString) return [];
    return tagString.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">
          {isEdit ? `Editar ${produtoIsServico ? 'Serviço' : 'Produto'}` : `Novo ${produtoIsServico ? 'Serviço' : 'Produto'}`}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent dividers>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Tipo de item (Produto/Serviço) */}
            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" sx={{ mr: 2 }}>Tipo:</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={produtoIsServico}
                    onChange={(e) => setProdutoIsServico(e.target.checked)}
                    color={produtoIsServico ? "secondary" : "primary"}
                  />
                }
                label={produtoIsServico ? "Serviço" : "Produto"}
              />
            </Box>
            
            {/* Dados básicos */}
            <TextField
              label="Nome do produto/serviço"
              value={produtoNome}
              onChange={(e) => setProdutoNome(e.target.value)}
              fullWidth
              required
            />
            
            <TextField
              label="Código"
              value={produtoCodigo}
              onChange={(e) => setProdutoCodigo(e.target.value)}
              fullWidth
              required
              helperText="Código único para identificação do produto/serviço"
            />
            
            <TextField
              label="Descrição"
              value={produtoDescricao}
              onChange={(e) => setProdutoDescricao(e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
            
            {/* Valor */}
            <TextField
              label="Valor"
              value={produtoValor}
              onChange={handleValorChange}
              fullWidth
              required
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
            />
            
            {/* Categoria */}
            <FormControl fullWidth>
              <InputLabel id="categoria-label">Categoria</InputLabel>
              <Select
                labelId="categoria-label"
                value={produtoCategoria}
                onChange={(e: SelectChangeEvent) => setProdutoCategoria(e.target.value)}
                label="Categoria"
                required
              >
                {categoriasPredefinidas.map((categoria) => (
                  <MenuItem key={categoria} value={categoria}>
                    {categoria}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            {/* Campos específicos para produtos */}
            {!produtoIsServico && (
              <>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    label="Estoque"
                    value={produtoEstoque}
                    onChange={(e) => setProdutoEstoque(e.target.value.replace(/\D/g, ''))}
                    type="number"
                    sx={{ flex: 1 }}
                  />
                  
                  <FormControl sx={{ flex: 1 }}>
                    <InputLabel id="unidade-medida-label">Unidade de Medida</InputLabel>
                    <Select
                      labelId="unidade-medida-label"
                      value={produtoUnidadeMedida}
                      onChange={(e: SelectChangeEvent) => setProdutoUnidadeMedida(e.target.value)}
                      label="Unidade de Medida"
                    >
                      {unidadesMedida.map((unidade) => (
                        <MenuItem key={unidade} value={unidade}>
                          {unidade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    label="Marca"
                    value={produtoMarca}
                    onChange={(e) => setProdutoMarca(e.target.value)}
                    fullWidth
                  />
                  
                  <TextField
                    label="Fornecedor"
                    value={produtoFornecedor}
                    onChange={(e) => setProdutoFornecedor(e.target.value)}
                    fullWidth
                  />
                </Box>
              </>
            )}
            
            {/* Tags */}
            <TextField
              label="Tags"
              value={produtoTags}
              onChange={(e) => setProdutoTags(e.target.value)}
              fullWidth
              placeholder="tecnologia, hardware, periférico"
              helperText="Separe as tags por vírgulas"
            />

            {/* Preview das tags */}
            {produtoTags && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {extractTags(produtoTags).map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    size="small"
                    sx={{ 
                      backgroundColor: '#f5f5f5',
                      color: '#666',
                      fontSize: '0.75rem'
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      
      <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
        <Button onClick={onClose} variant="outlined" color="inherit">
          Cancelar
        </Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color={produtoIsServico ? "secondary" : "primary"}
          disabled={saving || !produtoNome || !produtoCodigo || !produtoValor}
          startIcon={saving ? <CircularProgress size={20} color="inherit" /> : null}
        >
          {isEdit ? 'Atualizar' : 'Salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewProductDialog;
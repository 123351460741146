import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  Save,
  Delete,
  Business,
  CheckCircle,
  Error
} from '@mui/icons-material';
import { ERPFormProps, IntegrationModule, AVAILABLE_MODULES, ERP, DebtReminderConfig } from './types';

// Componentes
import BasicInfoSection from './BasicInfoSection';
import CredentialsSection from './CredentialsSection';
import ModulesSection from './ModulesSection';
import FunnelsSection from './FunnelsSection';
import DebtReminderConfigDialog from './DebtReminderConfigDialog';

/**
 * Componente principal de formulário para criação e edição de ERPs
 */
export const ERPForm: React.FC<ERPFormProps> = ({ 
  erp, 
  isEdit, 
  onSave, 
  onDelete, 
  onCancel,
  onUpdateCredentials,
  loading,
  error
}) => {
  // Estado para o formulário
  const [erpName, setErpName] = useState('');
  const [erpVendor, setErpVendor] = useState('omie'); // Default para Omie
  const [erpDescription, setErpDescription] = useState('');
  const [appKey, setAppKey] = useState('');
  const [appSecret, setAppSecret] = useState('');
  const [showAppSecret, setShowAppSecret] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [modules, setModules] = useState<IntegrationModule[]>([]);
  const [leadFunnelIntegration, setLeadFunnelIntegration] = useState(false);
  const [debtFunnelIntegration, setDebtFunnelIntegration] = useState(false);
  
  // Estado para configuração de inadimplência
  const [debtReminderConfig, setDebtReminderConfig] = useState<DebtReminderConfig[]>([
    { days: 2, enabled: true, whatsappTemplateId: '', emailTemplateId: '', channel: 'both' },
    { days: 10, enabled: true, whatsappTemplateId: '', emailTemplateId: '', channel: 'both' },
    { days: 20, enabled: true, whatsappTemplateId: '', emailTemplateId: '', channel: 'both' },
    { days: 40, enabled: true, whatsappTemplateId: '', emailTemplateId: '', channel: 'both' }
  ]);
  
  // Estado para diálogos
  const [openDebtConfigDialog, setOpenDebtConfigDialog] = useState(false);
  
  // Estados para validação
  const [formErrors, setFormErrors] = useState({
    name: false,
    appKey: false,
    appSecret: false
  });
  
  // Estado para feedback de credenciais atualizadas
  const [credentialsUpdated, setCredentialsUpdated] = useState(false);

  // Carrega os dados do ERP se estiver em modo de edição
  useEffect(() => {
    if (isEdit && erp) {
      setErpName(erp.name);
      setErpVendor(erp.vendor);
      setErpDescription(erp.description || '');
      setAppKey(erp.appKey);
      setAppSecret(erp.appSecret);
      setIsActive(erp.active);
      setModules(erp.modules || []);
      setLeadFunnelIntegration(erp.leadFunnelIntegration);
      setDebtFunnelIntegration(erp.debtFunnelIntegration);
      
      // Carrega configurações de inadimplência se existirem
      if (erp.debtReminderConfig && erp.debtReminderConfig.length > 0) {
        setDebtReminderConfig(erp.debtReminderConfig);
      }
    } else {
      // Para um novo ERP, inicializa os módulos disponíveis como inativos
      setModules(AVAILABLE_MODULES.map(module => ({
        ...module,
        id: '', // Será gerado pelo servidor
        active: false
      })));
      
      // Reseta os outros valores
      setErpName('');
      setErpVendor('omie');
      setErpDescription('');
      setAppKey('');
      setAppSecret('');
      setIsActive(true);
      setLeadFunnelIntegration(false);
      setDebtFunnelIntegration(false);
    }
    
    // Reset de feedback
    setCredentialsUpdated(false);
  }, [erp, isEdit]);
  
  // Valida o formulário antes de salvar
  const validateForm = () => {
    const errors = {
      name: erpName.trim() === '',
      appKey: appKey.trim() === '',
      appSecret: appSecret.trim() === ''
    };
    
    setFormErrors(errors);
    return !Object.values(errors).some(error => error);
  };

  // Manipula a mudança do estado de um módulo
  const handleModuleChange = (moduleId: string, checked: boolean) => {
    setModules(modules.map(module => 
      module.id === moduleId || module.key === moduleId
        ? { ...module, active: checked } 
        : module
    ));
  };

  // Manipula o envio do formulário
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    const erpData: ERP = {
      id: isEdit && erp ? erp.id : '',
      vendor: erpVendor,
      name: erpName,
      description: erpDescription,
      appKey: appKey,
      appSecret: appSecret,
      active: isActive,
      createdAt: isEdit && erp ? erp.createdAt : new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      modules,
      leadFunnelIntegration,
      debtFunnelIntegration,
      debtReminderConfig: debtFunnelIntegration ? debtReminderConfig : undefined
    };
    
    onSave(erpData);
  };
  
  // Manipula atualização de credenciais
  const handleCredentialsUpdate = () => {
    if (isEdit && erp && appKey.trim() && appSecret.trim()) {
      // Reset de feedback
      setCredentialsUpdated(false);
      
      onUpdateCredentials(erp.id, appKey, appSecret);
      
      // Mostrar feedback de sucesso (se não houver erro após a operação)
      if (!loading && !error) {
        setCredentialsUpdated(true);
        
        // Ocultar feedback após 3 segundos
        setTimeout(() => {
          setCredentialsUpdated(false);
        }, 3000);
      }
    }
  };
  
  // Manipula exclusão de ERP
  const handleDeleteERP = () => {
    if (isEdit && erp && confirm('Tem certeza que deseja excluir esta integração ERP? Esta ação não pode ser desfeita.')) {
      onDelete(erp.id);
    }
  };
  
  // Verifica se houve alterações nas credenciais
  const credentialsChanged = isEdit && erp && (appKey !== erp.appKey || appSecret !== erp.appSecret);
  
  // Abre o diálogo de configuração de inadimplência
  const handleOpenDebtConfigDialog = () => {
    setOpenDebtConfigDialog(true);
  };
  
  // Fecha o diálogo de configuração de inadimplência
  const handleCloseDebtConfigDialog = () => {
    setOpenDebtConfigDialog(false);
  };
  
  // Atualiza configuração de inadimplência
  const handleUpdateDebtConfig = (config: DebtReminderConfig[]) => {
    setDebtReminderConfig(config);
    setOpenDebtConfigDialog(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ overflowY: 'auto', pb: 4 }}>
      {/* Título do formulário */}
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <Business sx={{ fontSize: 24, mr: 1.5, color: '#3f51b5' }} />
        <Typography variant="h5" fontWeight={600} color="#32394a">
          {isEdit ? 'Editar Integração ERP' : 'Nova Integração ERP'}
        </Typography>
      </Box>

      {/* Exibir erro, se houver */}
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Error sx={{ mr: 1 }} />
            <Typography>{error}</Typography>
          </Box>
        </Alert>
      )}
      
      {/* Exibir feedback de credenciais atualizadas */}
      {credentialsUpdated && (
        <Alert severity="success" sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircle sx={{ mr: 1 }} />
            <Typography>Credenciais atualizadas com sucesso!</Typography>
          </Box>
        </Alert>
      )}

      {/* Seção 1: Informações Básicas */}
      <BasicInfoSection 
        erpName={erpName}
        setErpName={setErpName}
        erpVendor={erpVendor}
        setErpVendor={setErpVendor}
        erpDescription={erpDescription}
        setErpDescription={setErpDescription}
        isActive={isActive}
        setIsActive={setIsActive}
        formErrors={formErrors}
        isEdit={isEdit}
        loading={loading}
      />
      
      {/* Seção 2: Credenciais de API */}
      <CredentialsSection 
        appKey={appKey}
        setAppKey={setAppKey}
        appSecret={appSecret}
        setAppSecret={setAppSecret}
        showAppSecret={showAppSecret}
        setShowAppSecret={setShowAppSecret}
        formErrors={formErrors}
        loading={loading}
        erpVendor={erpVendor}
        isEdit={isEdit}
        credentialsChanged={credentialsChanged}
        onUpdateCredentials={handleCredentialsUpdate}
      />
      
      {/* Seção 3: Módulos de Integração */}
      <ModulesSection 
        modules={modules}
        onModuleChange={handleModuleChange}
        loading={loading}
      />
      
      {/* Seção 4: Integrações com Funis */}
      <FunnelsSection 
        leadFunnelIntegration={leadFunnelIntegration}
        setLeadFunnelIntegration={setLeadFunnelIntegration}
        debtFunnelIntegration={debtFunnelIntegration}
        setDebtFunnelIntegration={setDebtFunnelIntegration}
        onConfigureDebtReminders={handleOpenDebtConfigDialog}
        loading={loading}
      />
      
      {/* Diálogo de Configuração de Inadimplência */}
      <DebtReminderConfigDialog 
        open={openDebtConfigDialog}
        onClose={handleCloseDebtConfigDialog}
        debtReminderConfig={debtReminderConfig}
        onUpdate={handleUpdateDebtConfig}
        loading={loading}
      />
      
      {/* Botões de Ação */}
      <Box sx={{ mt: 4, mb: 4, display: 'flex', gap: 2 }}>
        {isEdit ? (
          <>
            <Button
              variant="contained"
              color="error"
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Delete />}
              onClick={handleDeleteERP}
              sx={{ minWidth: '140px' }}
              disabled={loading}
            >
              Excluir ERP
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Save />}
              type="submit"
              sx={{ minWidth: '140px' }}
              disabled={loading}
            >
              Atualizar ERP
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Save />}
            type="submit"
            sx={{ minWidth: '140px' }}
            disabled={loading}
          >
            Salvar ERP
          </Button>
        )}
        
        <Button
          variant="outlined"
          onClick={onCancel}
          sx={{ minWidth: '140px' }}
          disabled={loading}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

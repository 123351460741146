import React from 'react'
import {
  Box,
  TextField,
  Chip
} from '@mui/material'

const TagsSection = ({ tags, setTags }) => {
  return (
    <>
      <TextField
        label="Tags"
        value={tags}
        onChange={(e) => setTags(e.target.value)}
        fullWidth
        margin="normal"
        placeholder="Cliente, Fornecedor, VIP"
        helperText="Separe as tags por vírgulas (ex: Cliente, Fornecedor, VIP)"
      />

      {/* Preview das tags */}
      {tags && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1, mb: 2 }}>
          {tags.split(',').map(tag => tag.trim()).filter(tag => tag).map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              size="small"
              sx={{ 
                backgroundColor: '#3f51b5',
                color: 'white',
                fontSize: '0.75rem'
              }}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default TagsSection
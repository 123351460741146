import React, { useState, useRef, useEffect } from 'react'

import {
  Send,
  AttachFile,
  Image,
  Mic,
  LocationOn,
  Close,
  AccessTime,
  InsertDriveFile,
  PictureAsPdf,
  Delete,
  CloseOutlined,
  BusinessCenter,
  Task,
} from '@mui/icons-material'
import {
  TextField,
  IconButton,
  Tooltip,
  Box,
  CircularProgress,
  Typography,
  Grid,
  Button,
} from '@mui/material'
import { X } from 'lucide-react'
import { AudioRecorder } from 'react-audio-voice-recorder'
import OmieWizard from '../omieWizard/OmieWizard'
import useOmieIntegration from '../omieWizard/useOmieWizard'
import useAISalesAssistant from '../aISalesAssistantWizard/useAISalesAssistant'
import { Psychology } from '@mui/icons-material'

import ContactCardsButton from '../contactCardsButton/ContactCardsButton' // We'll create this component
import KanbanCard from '../../../kanban/KanbanCard'

interface FileAttachment {
  file: File
  name: string
  type: string
  size: number
  preview: string | null
  base64?: string
  duration?: number
  originalBlob?: Blob
}

// Importar os hooks adaptados para usar o Context
import useOmieIntegrationWithContext from '../omieWizard/useOmieIntegrationWithContext'
import useAISalesAssistantWithContext from '../aISalesAssistantWizard/useAISalesAssistantWithContext'

import { TaskAlt as TaskIcon } from '@mui/icons-material'
import CardDialog from '../../../kanban/CardDialog' // Ajuste o caminho conforme necessário

/**
 * Componente de entrada de mensagem com suporte a anexos de mídia,
 * múltiplas imagens e arquivos
 */
export const MediaMessageInput = ({
  disabled = false,
  isLoading = false,
  onSendMessage,
  replyingTo, // Mensagem que estamos respondendo
  onCancelReply, // Função para cancelar reply
  placeholder = 'Digite sua mensagem...',
  omieEnabled = true,
  omieApiKey = '',
  omieApiSecret = '',
  clientInfo = null,
  aiAssistantEnabled = true,
  chatMessages = [],
  productCatalog = [],
  boardType = 'lead', // Tipo padrão do board
  columns = [], // Colunas disponíveis
}) => {
  const [cardDialogOpen, setCardDialogOpen] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)

  // Função para abrir o CardDialog
  const handleOpenCardDialog = () => {
    setCardDialogOpen(true)
  }

  // Função para fechar o CardDialog
  const handleCloseCardDialog = () => {
    setCardDialogOpen(false)
    setSelectedCard(null)
  }

  // Função para salvar o card
  const handleSaveCard = (cardData) => {
    console.log('Card salvo:', cardData)

    // Opcionalmente, você pode adicionar o card como mensagem
    const cardMessage =
      `🎯 *Card criado:* ${cardData.title}\n` +
      `📝 *Descrição:* ${cardData.description || 'N/A'}\n` +
      `🏢 *Empresa:* ${cardData.company || 'N/A'}\n` +
      `💲 *Valor:* ${cardData.value ? `R$ ${cardData.value}` : 'N/A'}`

    setInputMessage(cardMessage)
    setCardDialogOpen(false)
  }

  const handleDeleteCard = (cardId) => {
    console.log('Card excluído:', cardId)
    setCardDialogOpen(false)
  }

  const CardDialogButton = () => (
    <Tooltip title="Adicionar Card">
      <span>
        <IconButton
          onClick={handleOpenCardDialog}
          disabled={disabled || isLoading}
          color="default"
        >
          <TaskIcon />
        </IconButton>
      </span>
    </Tooltip>
  )

  // Estados para entrada de texto e anexos
  const [inputMessage, setInputMessage] = useState('')
  const [mediaType, setMediaType] = useState(null)
  const [mediaContent, setMediaContent] = useState(null)
  const [isMediaMenuOpen, setIsMediaMenuOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [showLocationPicker, setShowLocationPicker] = useState(false)
  const [loadingDots, setLoadingDots] = useState('.')
  const [showRecorder, setShowRecorder] = useState(false)
  const [audioDuration, setAudioDuration] = useState(null)
  const [attachments, setAttachments] = useState<FileAttachment[]>([])

  const [showOmieWizard, setShowOmieWizard] = useState(false)

  const [audioData, setAudioData] = useState<FileAttachment | null>(null)

  const [message, setMessage] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const textareaRef = useRef(null)
  const emojiPickerRef = useRef(null)

  // Refs para inputs ocultos
  const imageInputRef = useRef(null)
  const fileInputRef = useRef(null)
  const audioRef = useRef(null)
  const audioContextRef = useRef(null)

  const { OmieShortcutButton, OmieMenuItem } = useOmieIntegrationWithContext({
    enabled: omieEnabled,
    clientInfo,
    apiKey: omieApiKey,
    apiSecret: omieApiSecret,
    inputMessage,
    setInputMessage,
    disabled,
    isLoading,
  })

  const { AIAssistantShortcutButton, AIAssistantMenuItem } =
    useAISalesAssistantWithContext({
      enabled: aiAssistantEnabled,
      chatMessages,
      productCatalog,
      clientInfo,
      inputMessage,
      setInputMessage,
      disabled,
      isLoading,
    })

  const handleOpenOmieWizard = () => {
    setShowOmieWizard(true)
    setIsMediaMenuOpen(false) // Fechar o menu de mídia se estiver aberto
  }

  const handleOmieDataSelected = (formattedData) => {
    setInputMessage(inputMessage + (inputMessage ? '\n\n' : '') + formattedData)
  }

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const result = reader.result as string
        const base64String = result.split(',')[1]
        resolve(base64String)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const forceAudioMp3Format = (blob: Blob): Blob => {
    return new Blob([blob], { type: 'audio/mpeg' })
  }

  const convertWebmToMp3Format = async (
    blob: Blob,
  ): Promise<{ mp3Blob: Blob; base64: string }> => {
    try {
      // Não modificamos o conteúdo do áudio, apenas forçamos o tipo MIME para MP3
      const mp3Blob = forceAudioMp3Format(blob)

      // Converter para base64 com cabeçalho MP3
      const reader = new FileReader()
      const base64Promise = new Promise<string>((resolve, reject) => {
        reader.onloadend = () => {
          const result = reader.result as string
          // Apenas obter a parte base64 sem o cabeçalho
          const base64Data = result.split(',')[1]
          resolve(base64Data)
        }
        reader.onerror = reject
        reader.readAsDataURL(mp3Blob)
      })

      const base64Data = await base64Promise

      return {
        mp3Blob,
        base64: base64Data,
      }
    } catch (error) {
      console.error('Erro ao converter formato de áudio:', error)
      throw error
    }
  }

  useEffect(() => {
    let interval
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingDots((dots) => (dots.length >= 3 ? '.' : dots + '.'))
      }, 500)
    }
    return () => clearInterval(interval)
  }, [isLoading])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [message])

  // Fechar menu ao clicar fora dele
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMediaMenuOpen && !event.target.closest('.media-menu-container')) {
        setIsMediaMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMediaMenuOpen])

  // Limpar objetos ao desmontar o componente
  useEffect(() => {
    return () => {
      // Limpar URLs de blob
      if (
        mediaContent &&
        typeof mediaContent === 'string' &&
        mediaContent.startsWith('blob:')
      ) {
        URL.revokeObjectURL(mediaContent)
      }

      // Limpar URLs de anexos
      attachments.forEach((attachment) => {
        if (attachment.preview && attachment.preview.startsWith('blob:')) {
          URL.revokeObjectURL(attachment.preview)
        }
      })
    }
  }, [mediaContent, attachments])

  useEffect(() => {
    if (replyingTo && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [replyingTo])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && message.trim()) {
      e.preventDefault()
      handleSendMessage()
    }
  }
  // Função para formatar a duração em mm:ss
  const formatDuration = (seconds) => {
    if (!seconds && seconds !== 0) return '--:--'

    // Verificar se a duração é válida
    if (isNaN(seconds) || !isFinite(seconds)) return '--:--'

    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  // Função para obter o tamanho formatado do arquivo
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes'

    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i]
  }

  // Função para obter o ícone correto com base no tipo de arquivo
  const getFileIcon = (fileType) => {
    if (fileType.startsWith('image/')) return <Image />
    if (fileType === 'application/pdf') return <PictureAsPdf />
    return <InsertDriveFile />
  }

  // Funções para manipulação de mídia
  const handleImageSelection = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files)
      if (files.length > 0) {
        const newAttachments = await Promise.all(
          files.map(async (file: File) => {
            // Criar URL para preview
            const previewUrl = URL.createObjectURL(file)

            // Converter para base64
            const base64Data = await blobToBase64(file)

            return {
              file,
              name: file.name,
              type: file.type,
              size: file.size,
              preview: file.type.startsWith('image/') ? previewUrl : null,
              base64: base64Data,
            } as FileAttachment
          }),
        )

        setAttachments((prev) => [...prev, ...newAttachments])
        setIsMediaMenuOpen(false)
        e.target.value = null
      }
    }
  }

  // Função para seleção de arquivos
  const handleFileSelection = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files)
      if (files.length > 0) {
        const newAttachments = await Promise.all(
          files.map(async (file: File) => {
            // Converter para base64
            const base64Data = await blobToBase64(file)

            return {
              file,
              name: file.name,
              type: file.type,
              size: file.size,
              preview: file.type.startsWith('image/')
                ? URL.createObjectURL(file)
                : null,
              base64: base64Data,
            } as FileAttachment
          }),
        )

        setAttachments((prev) => [...prev, ...newAttachments])
        setIsMediaMenuOpen(false)
      }
    }
  }

  // Remover um anexo específico
  const removeAttachment = (index) => {
    setAttachments((prev) => {
      const newAttachments = [...prev]

      // Revogar URL se for uma imagem
      if (newAttachments[index].preview) {
        URL.revokeObjectURL(newAttachments[index].preview)
      }

      newAttachments.splice(index, 1)
      return newAttachments
    })
  }

  // Iniciar gravação de áudio
  const startAudioRecording = () => {
    setShowRecorder(true)
    setIsMediaMenuOpen(false)
    setAudioDuration(null)
  }

  // Função para calcular a duração do áudio a partir do blob
  const calculateAudioDuration = async (blob: Blob): Promise<number | null> => {
    return new Promise((resolve) => {
      // Usando AudioContext
      try {
        const fileReader = new FileReader()

        fileReader.onloadend = () => {
          const arrayBuffer = fileReader.result as ArrayBuffer

          // Criar AudioContext se não existir
          if (!audioContextRef.current) {
            const AudioContextClass =
              window.AudioContext || (window as any).webkitAudioContext
            audioContextRef.current = new AudioContextClass()
          }

          // Decodificar o áudio e obter a duração
          audioContextRef.current.decodeAudioData(
            arrayBuffer,
            (buffer) => {
              const duration = buffer.duration
              console.log('Duration calculated with AudioContext:', duration)
              resolve(duration)
            },
            (error) => {
              console.error('Failed to decode audio data:', error)
              resolve(null)
            },
          )
        }

        fileReader.readAsArrayBuffer(blob)
      } catch (error) {
        console.error('Error calculating audio duration:', error)
        resolve(null)
      }
    })
  }

  const addAudioElement = async (blob: Blob) => {
    console.log('Audio recording complete, blob created:', blob)

    try {
      // Calcular duração do áudio usando AudioContext
      const duration = await calculateAudioDuration(blob)
      // Converter para number ou usar 0 como fallback
      const durationNumber: number = duration !== null ? duration : 0

      setAudioDuration(durationNumber)

      // Criar URL para o blob original (WebM) para visualização
      const webmUrl = URL.createObjectURL(blob)

      // Converter formato para MP3 (apenas MIME type)
      const { mp3Blob, base64 } = await convertWebmToMp3Format(blob)

      // Criar um nome de arquivo único para o áudio com extensão MP3
      const fileName = `audio_${new Date().getTime()}.mp3`

      // Criar objeto de áudio - note que usamos o blob WebM para visualização
      // mas o formato MP3 para o dados de envio
      const audioAttachment: FileAttachment = {
        file: mp3Blob as unknown as File,
        name: fileName,
        type: 'audio/mpeg', // Tipo MP3 para envio
        size: blob.size,
        preview: null,
        base64: base64, // Base64 do MP3
        duration: durationNumber,
        originalBlob: blob, // Mantemos o blob original para referência
      }

      // Definir WebM para visualização, mas armazenar MP3 para envio
      setMediaContent(webmUrl)
      setMediaType('audio')
      setAudioData(audioAttachment)
      setShowRecorder(false)

      console.log('Audio URL created for preview:', webmUrl)
      console.log('Audio data prepared for sending as MP3')
    } catch (error) {
      console.error('Error processing audio:', error)

      // Fallback com duração desconhecida
      const webmUrl = URL.createObjectURL(blob)
      setMediaContent(webmUrl)

      try {
        // Tentar pelo menos converter o formato
        const { mp3Blob, base64 } = await convertWebmToMp3Format(blob)

        const audioAttachment: FileAttachment = {
          file: mp3Blob as unknown as File,
          name: `audio_${new Date().getTime()}.mp3`,
          type: 'audio/mpeg',
          size: blob.size,
          preview: null,
          base64: base64,
          duration: 0,
          originalBlob: blob,
        }

        setAudioData(audioAttachment)
        setMediaType('audio')
        setShowRecorder(false)
      } catch (convError) {
        console.error('Error converting audio format:', convError)
      }
    }
  }

  const handleLocationSelect = () => {
    setShowLocationPicker(true)
    setIsMediaMenuOpen(false)

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setSelectedLocation({ latitude, longitude })
          setMediaType('location')

          // A URL do Google Maps para iframe
          setMediaContent(
            `https://maps.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`,
          )
        },
        (error) => {
          console.error('Erro ao obter localização:', error)
          setShowLocationPicker(false)
        },
      )
    } else {
      console.error('Geolocalização não é suportada neste navegador')
      setShowLocationPicker(false)
    }
  }

  const removeMedia = () => {
    // Revogar URL do objeto para evitar vazamentos de memória
    if (
      mediaContent &&
      typeof mediaContent === 'string' &&
      mediaContent.startsWith('blob:')
    ) {
      URL.revokeObjectURL(mediaContent)
    }

    setMediaType(null)
    setMediaContent(null)
    setSelectedLocation(null)
    setAudioDuration(null)
    setAudioData(null) // Adicione esta linha
  }

  // Limpar todos os anexos
  const clearAllAttachments = () => {
    // Revogar todas as URLs de blob para evitar vazamentos de memória
    attachments.forEach((attachment) => {
      if (attachment.preview && attachment.preview.startsWith('blob:')) {
        URL.revokeObjectURL(attachment.preview)
      }
    })

    setAttachments([])
    removeMedia()
  }

  // Tentar atualizar duração quando o áudio carregar metadados
  const handleAudioLoad = (e) => {
    const audio = e.target
    if (audio && !isNaN(audio.duration) && isFinite(audio.duration)) {
      console.log('Audio duration from metadata:', audio.duration)
      setAudioDuration(audio.duration)
    }
  }

  // Renderizar a prévia da mensagem sendo respondida
  const renderReplyPreview = () => {
    if (!replyingTo) return null

    const previewContent = replyingTo.message
    const previewContentType = replyingTo.type || 'MESSAGE'
    const isReplyToBot = replyingTo.isBot

    return (
      <div className="bg-gray-50 p-2 rounded-t-lg border-t border-x border-gray-200">
        <div className="flex justify-between items-center mb-1">
          <span className="text-xs font-medium text-gray-500">
            Respondendo para {isReplyToBot ? 'AI' : 'Você'}
          </span>
          <IconButton
            size="small"
            onClick={onCancelReply}
            className="text-gray-500 hover:text-gray-700"
          >
            <X fontSize="small" />
          </IconButton>
        </div>

        <div
          className="text-xs text-gray-600 pl-2 border-l-2 py-1 pr-2"
          style={{
            borderLeftColor: isReplyToBot ? '#635AFF' : '#22C55E',
            backgroundColor: isReplyToBot
              ? 'rgba(99, 90, 255, 0.05)'
              : 'rgba(34, 197, 94, 0.05)',
            maxWidth: '100%',
            maxHeight: '60px',
            overflow: 'hidden',
          }}
        >
          {previewContentType === 'IMAGE' && (
            <span className="font-medium">[Imagem] </span>
          )}
          {previewContentType === 'AUDIO' && (
            <span className="font-medium">[Áudio] </span>
          )}
          {previewContent
            ? previewContent.length > 100
              ? previewContent.substring(0, 100) + '...'
              : previewContent
            : previewContentType !== 'MESSAGE'
              ? ''
              : 'Mensagem'}
        </div>
      </div>
    )
  }

  // Enviar mensagem
  const handleSendMessage = () => {
    if (
      (inputMessage.trim() !== '' ||
        mediaContent ||
        attachments.length > 0 ||
        audioData) &&
      !isLoading &&
      !disabled
    ) {
      console.log('Enviando inputMessage:', inputMessage)
      console.log('Enviando mediaContent:', mediaContent)
      console.log('Enviando attachments:', attachments)
      console.log('Enviando audioData:', audioData)

      // Crie uma cópia dos anexos para não modificar o estado diretamente
      const allAttachments = [...attachments]

      // Adicione o áudio aos anexos apenas na hora de enviar
      if (audioData && mediaType === 'audio') {
        allAttachments.push(audioData)
      }

      const messageData = {
        message: inputMessage,
        mediaType,
        mediaContent,
        replyingTo: replyingTo?.id ?? null,
        duration: audioData?.duration || audioDuration || null,
        location: mediaType === 'location' ? selectedLocation : null,
        attachments: allAttachments.map((attachment) => {
          // Retorna objeto com os dados do anexo
          const attachmentData = {
            file: attachment.file,
            name: attachment.name,
            type: attachment.type,
            size: attachment.size,
            preview: attachment.preview,
            base64: attachment.base64,
            duration: attachment.duration || null,
          }

          // Para áudio, garantir que tenha formato MP3
          if (
            attachment.type.startsWith('audio/') &&
            attachment.name.endsWith('.mp3') &&
            attachment.base64
          ) {
            // Formato base64 para o backend
            return {
              ...attachmentData,
              type: 'audio/mpeg',
            }
          }

          return attachmentData
        }),
      }

      onSendMessage(messageData)

      // Limpar estados
      setInputMessage('')
      setMediaType(null)
      setMediaContent(null)
      setSelectedLocation(null)
      setAudioDuration(null)
      setAttachments([])
      setAudioData(null)
      if (imageInputRef.current) imageInputRef.current.value = null
      if (fileInputRef.current) fileInputRef.current.value = null
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  // Componentes de UI
  const MediaPreview = () => {
    const hasMedia = mediaType !== null && mediaContent !== null
    const hasAttachments = attachments.length > 0

    if (!hasMedia && !hasAttachments) return null

    return (
      <Box
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          mb: 2,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        {hasAttachments && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <Typography variant="subtitle2">
                Anexos ({attachments.length})
              </Typography>

              <Button
                size="small"
                color="error"
                variant="text"
                onClick={clearAllAttachments}
                startIcon={<Delete fontSize="small" />}
              >
                Limpar todos
              </Button>
            </Box>

            <Grid container spacing={1} sx={{ mb: hasMedia ? 2 : 0 }}>
              {attachments.map((attachment, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      p: 1,
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: '4px',
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => removeAttachment(index)}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                      }}
                    >
                      <Close fontSize="small" />
                    </IconButton>

                    {attachment.preview ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          overflow: 'hidden',
                          height: '100px',
                          borderRadius: '4px',
                          my: 1,
                        }}
                      >
                        <img
                          src={attachment.preview}
                          alt={attachment.name}
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '80px',
                          color: 'text.secondary',
                          my: 1,
                        }}
                      >
                        {getFileIcon(attachment.type)}
                      </Box>
                    )}

                    <Box sx={{ mt: 'auto' }}>
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'medium',
                        }}
                      >
                        {attachment.name}
                      </Typography>

                      <Typography
                        variant="caption"
                        color="text.secondary"
                        component="div"
                        sx={{ mt: 0.5 }}
                      >
                        {formatFileSize(attachment.size)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {hasMedia && (
          <>
            {mediaType === 'audio' && (
              <Box sx={{ position: 'relative' }}>
                <IconButton
                  size="small"
                  onClick={removeMedia}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'background.paper',
                    boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                    zIndex: 2,
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>

                <Box sx={{ p: 1 }}>
                  <audio
                    controls
                    src={mediaContent}
                    onLoadedMetadata={handleAudioLoad}
                    ref={audioRef}
                    style={{ width: '100%', display: 'block' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 1,
                      color: 'text.secondary',
                    }}
                  >
                    <AccessTime sx={{ fontSize: '0.875rem', mr: 0.5 }} />
                    <Typography variant="caption">
                      {audioDuration &&
                      !isNaN(audioDuration) &&
                      isFinite(audioDuration)
                        ? formatDuration(audioDuration)
                        : '--:--'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {/* Resto do código para outros tipos de mídia */}
          </>
        )}
      </Box>
    )
  }

  const MediaOptionsMenu = () => {
    if (!isMediaMenuOpen) return null

    return (
      <Box
        className="media-menu-container"
        sx={{
          position: 'absolute',
          bottom: '60px',
          left: '0',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          padding: '8px',
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '8px',
          }}
        >
          <Box
            onClick={() => imageInputRef.current.click()}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px',
              borderRadius: '8px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            <Image color="primary" />
            <Box sx={{ fontSize: '0.75rem', mt: '4px' }}>Imagens</Box>
          </Box>

          <Box
            onClick={() => fileInputRef.current.click()}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px',
              borderRadius: '8px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            <InsertDriveFile color="primary" />
            <Box sx={{ fontSize: '0.75rem', mt: '4px' }}>Arquivos</Box>
          </Box>

          <Box
            onClick={startAudioRecording}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px',
              borderRadius: '8px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            <Mic color="primary" />
            <Box sx={{ fontSize: '0.75rem', mt: '4px' }}>Áudio</Box>
          </Box>

          <Box
            onClick={handleLocationSelect}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px',
              borderRadius: '8px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            <LocationOn color="primary" />
            <Box sx={{ fontSize: '0.75rem', mt: '4px' }}>Local</Box>
          </Box>
          {/* Adicione o item do menu para o Omie */}
          <OmieMenuItem onClose={() => setIsMediaMenuOpen(false)} />

          {/* Adicione o item do menu para o AI Assistant */}
          <AIAssistantMenuItem onClose={() => setIsMediaMenuOpen(false)} />
        </Box>
      </Box>
    )
  }

  return (
    <div className="bg-white p-4 border-t border-gray-200 backdrop-blur-sm" style={{ backgroundColor: 'rgba(255, 255, 255, 0.98)' }}>
      {/* No final do componente, antes do último fechamento div */}
      <CardDialog
        open={cardDialogOpen}
        onClose={handleCloseCardDialog}
        onSave={handleSaveCard}
        onDelete={handleDeleteCard}
        card={selectedCard}
        columns={columns}
        boardType={boardType}
      />
      {renderReplyPreview()}
      {/* Gravador de áudio */}
      {showRecorder && (
        <Box
          sx={{
            p: 2,
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{ mb: 2, color: 'error.main', fontWeight: 'medium' }}
          >
            Gravador de Áudio
          </Typography>

          <AudioRecorder
            onRecordingComplete={addAudioElement}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadOnSavePress={false}
            downloadFileExtension="mp3"
            showVisualizer={true}
          />

          <Typography variant="caption" sx={{ mt: 2, color: 'text.secondary' }}>
            Clique no botão acima para iniciar e parar a gravação
          </Typography>
        </Box>
      )}

      {/* Prévia de mídia */}
      <MediaPreview />

      <div className="flex flex-row items-center rounded-xl bg-gray-50 w-full px-4 py-1 relative">
        {/* Botão de anexos */}
        <Tooltip title="Adicionar anexo">
          <span>
            <IconButton
              onClick={() => setIsMediaMenuOpen(!isMediaMenuOpen)}
              disabled={disabled || showRecorder}
              color={isMediaMenuOpen ? 'primary' : 'default'}
            >
              <AttachFile />
            </IconButton>
          </span>
        </Tooltip>

        {/* Menu de opções de mídia */}
        <MediaOptionsMenu />

        {/* Inputs ocultos para mídia */}
        <input
          type="file"
          ref={imageInputRef}
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageSelection}
          multiple
        />

        <input
          type="file"
          ref={fileInputRef}
          accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.zip,.rar"
          style={{ display: 'none' }}
          onChange={handleFileSelection}
          multiple
        />

        <div className="flex-grow">
          <TextField
            fullWidth
            placeholder={showRecorder ? 'Gravando áudio...' : placeholder}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            disabled={disabled || showRecorder}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: {
                padding: '10px 0',
                fontSize: '0.95rem',
              },
            }}
          />
        </div>

        {/* Botão de atalho do Omie - adicione esta parte */}
        {/* Adicione os botões de atalho para os wizards */}
        <OmieShortcutButton />
        <AIAssistantShortcutButton />
        <Tooltip title="Adicionar Card">
          <span>
            <IconButton
              onClick={handleOpenCardDialog}
              disabled={disabled || isLoading}
            >
              <TaskIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Enviar mensagem">
          <span>
            <IconButton
              color="primary"
              onClick={handleSendMessage}
              disabled={
                disabled ||
                isLoading ||
                showRecorder ||
                (!inputMessage.trim() &&
                  !mediaContent &&
                  attachments.length === 0)
              }
              sx={{
                backgroundColor:
                  (inputMessage.trim() ||
                    mediaContent ||
                    attachments.length > 0) &&
                  !isLoading &&
                  !disabled &&
                  !showRecorder
                    ? 'primary.main'
                    : 'gray.200',
                color:
                  (inputMessage.trim() ||
                    mediaContent ||
                    attachments.length > 0) &&
                  !isLoading &&
                  !disabled &&
                  !showRecorder
                    ? 'white'
                    : 'gray.400',
                '&:hover': {
                  backgroundColor:
                    (inputMessage.trim() ||
                      mediaContent ||
                      attachments.length > 0) &&
                    !isLoading &&
                    !disabled &&
                    !showRecorder
                      ? 'primary.dark'
                      : 'gray.200',
                },
                ml: 1,
                width: 40,
                height: 40,
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Send />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

export default MediaMessageInput

import React from 'react';
import { Card, CardContent, Typography, Box, Avatar, Chip } from '@mui/material';
import { Contato } from './ContactTypes';

interface ContactCardProps {
  contact: Contato;
  onClick: (contact: Contato) => void;
  tagFilter: string;
  onTagSelect: (tag: string) => void;
}

export const ContactCard: React.FC<ContactCardProps> = ({
  contact,
  onClick,
  tagFilter,
  onTagSelect,
}) => {
  return (
    <Card
      onClick={() => onClick(contact)}
      sx={{
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        overflow: 'hidden',
        position: 'relative',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        height: '100%',
        minHeight: '150px',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          transform: 'translateY(-3px)',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)'
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          backgroundColor: '#3f51b5'
        }
      }}
    >
      <CardContent sx={{ 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        height: '100%'
      }}>
        {/* Cabeçalho com nome e avatar */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1.5 }}>
          <Box>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontWeight: 'bold',
                color: '#333',
                fontSize: '16px'
              }}
            >
              {contact.name}
            </Typography>
            
            <Typography 
              variant="body2"
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                color: '#666',
                mt: 0.5,
                fontSize: '14px'
              }}
            >
              <span style={{ marginRight: '8px' }}>📱</span>
              {contact.number}
            </Typography>
            
            {contact.email && (
              <Typography 
                variant="body2"
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  color: '#666',
                  mt: 0.5,
                  fontSize: '14px'
                }}
              >
                <span style={{ marginRight: '8px' }}>✉️</span>
                {contact.email}
              </Typography>
            )}
          </Box>
          
          <Avatar
            sx={{
              width: 36,
              height: 36,
              bgcolor: '#f5f5f5',
              color: '#3f51b5',
              fontWeight: 'bold',
              fontSize: '16px'
            }}
          >
            {contact.name.charAt(0).toUpperCase()}
          </Avatar>
        </Box>
        
        {/* Descrição do contato (se houver) */}
        {contact.description && (
          <Typography
            variant="body2"
            sx={{
              fontSize: '13px',
              color: '#777',
              mb: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical'
            }}
          >
            {contact.description}
          </Typography>
        )}
        
        {/* Tags do contato (se houver) */}
        {contact.tags && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1.5 }}>
            {contact.tags.split(',').map(tag => tag.trim()).filter(tag => tag).map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                size="small"
                sx={{
                  height: '20px',
                  fontSize: '11px',
                  backgroundColor: tagFilter === tag ? '#3f51b5' : '#f5f5f5',
                  color: tagFilter === tag ? 'white' : '#666',
                  '&:hover': {
                    backgroundColor: tagFilter === tag ? '#303f9f' : '#e0e0e0',
                    cursor: 'pointer'
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onTagSelect(tag);
                }}
              />
            ))}
          </Box>
        )}
        
        {/* Rodapé com etiqueta e mensagem */}
        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                backgroundColor: '#f5f5f5',
                padding: '3px 8px',
                borderRadius: '4px',
                color: '#666',
                fontSize: '11px'
              }}
            >
              {contact.type || 'WhatsApp Contact'}
            </Typography>
            
            <Typography
              sx={{
                fontSize: '12px',
                color: '#919eab',
                fontStyle: 'italic'
              }}
            >
              Clique para editar
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
  FormControlLabel,
  Switch,
  Paper,
  RadioGroup,
  Radio,
  InputAdornment,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Grid
} from '@mui/material';
import {
  Close,
  Save,
  Add,
  Delete,
  Timer,
  Email,
  WhatsApp,
  ExpandMore,
  Info,
  Schedule,
  AccessTime
} from '@mui/icons-material';
import { DebtReminderConfig } from './types';

// Mensagens pré-cadastradas (simulação)
const presetMessages = {
  whatsapp: [
    { id: 'wts1', name: 'Lembrete Amigável', content: 'Olá, notamos que há uma fatura em aberto...' },
    { id: 'wts2', name: 'Primeiro Aviso', content: 'Prezado cliente, sua fatura está vencida...' },
    { id: 'wts3', name: 'Segundo Aviso', content: 'Atenção! Sua fatura continua em aberto...' },
    { id: 'wts4', name: 'Aviso Final', content: 'Última chance para regularizar seu débito...' }
  ],
  email: [
    { id: 'eml1', name: 'Lembrete Cordial', content: 'Prezado cliente, gostaríamos de lembrá-lo...' },
    { id: 'eml2', name: 'Notificação de Atraso', content: 'Sua fatura está atrasada. Por favor...' },
    { id: 'eml3', name: 'Segundo Comunicado', content: 'É importante regularizar seu débito...' },
    { id: 'eml4', name: 'Notificação Final', content: 'Este é o último aviso antes de medidas...' }
  ]
};

// Opções de horário para seleção
const timeOptions = Array.from({ length: 24 }, (_, i) => {
  const hour = i.toString().padStart(2, '0');
  return { value: `${hour}:00`, label: `${hour}:00` };
});

interface DebtReminderConfigDialogProps {
  open: boolean;
  onClose: () => void;
  debtReminderConfig: DebtReminderConfig[];
  onUpdate: (config: DebtReminderConfig[]) => void;
  loading: boolean;
}

// Adicionando horário padrão para configuração global
interface GlobalSettings {
  scheduledTime: string;
  sendOnWeekends: boolean;
}

const DebtReminderConfigDialog: React.FC<DebtReminderConfigDialogProps> = ({
  open,
  onClose,
  debtReminderConfig,
  onUpdate,
  loading
}) => {
  // Estado local para edição da configuração
  const [localConfig, setLocalConfig] = useState<DebtReminderConfig[]>([...debtReminderConfig]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Estado para configurações globais de agendamento
  const [globalSettings, setGlobalSettings] = useState<GlobalSettings>({
    scheduledTime: '09:00',
    sendOnWeekends: false
  });

  // Atualiza o estado local quando as props mudam
  useEffect(() => {
    if (open) {
      setLocalConfig([...debtReminderConfig]);
    }
  }, [open, debtReminderConfig]);

  // Manipula alterações nas configurações globais
  const handleGlobalSettingChange = (field: keyof GlobalSettings, value: any) => {
    setGlobalSettings({
      ...globalSettings,
      [field]: value
    });
  };

  // Manipula alterações na configuração de lembretes
  const handleReminderConfigChange = (index: number, field: keyof DebtReminderConfig, value: any) => {
    const updatedConfig = [...localConfig];
    updatedConfig[index] = {
      ...updatedConfig[index],
      [field]: value
    };
    setLocalConfig(updatedConfig);
  };

  // Adiciona um novo lembrete
  const handleAddReminder = () => {
    const lastDay = Math.max(...localConfig.map(c => c.days));
    setLocalConfig([
      ...localConfig,
      {
        days: lastDay + 10,
        enabled: true,
        whatsappTemplateId: '',
        emailTemplateId: '',
        channel: 'both'
      }
    ]);
  };

  // Remove o último lembrete
  const handleRemoveReminder = () => {
    if (localConfig.length > 1) {
      setLocalConfig(localConfig.slice(0, -1));
    }
  };

  // Salva a configuração
  const handleSave = () => {
    setIsSubmitting(true);
    // Simulando um delay para mostrar o loading
    setTimeout(() => {
      // Aqui você precisaria modificar a estrutura original para incluir as configurações de horário
      // Por agora, vamos apenas passar a configuração de lembretes existente
      // Em uma implementação real, você combinaria os dados com globalSettings
      onUpdate(localConfig);
      setIsSubmitting(false);
    }, 500);
  };

  // Fecha o diálogo sem salvar
  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={loading || isSubmitting ? undefined : handleCancel}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: '1px solid #e0e0e0',
        bgcolor: 'rgba(156, 39, 176, 0.08)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Timer sx={{ mr: 1, color: '#9c27b0' }} />
          <Typography variant="h6" component="div">
            Configuração de Lembretes de Inadimplência
          </Typography>
        </Box>
        <IconButton 
          onClick={handleCancel}
          disabled={loading || isSubmitting}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      
      <DialogContent dividers>
        {/* Seção de configuração de horário - NOVA SEÇÃO */}
        <Box sx={{ mb: 3, p: 2, bgcolor: 'rgba(156, 39, 176, 0.04)', borderRadius: 1, border: '1px solid rgba(156, 39, 176, 0.2)' }}>
          <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <Schedule sx={{ mr: 1, color: '#9c27b0' }} />
            Configuração de Horário de Envio
          </Typography>
          
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Typography variant="body2" gutterBottom>
                  Horário de execução da rotina
                </Typography>
                <Select
                  value={globalSettings.scheduledTime}
                  onChange={(e) => handleGlobalSettingChange('scheduledTime', e.target.value)}
                  disabled={loading || isSubmitting}
                  size="small"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccessTime fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    </InputAdornment>
                  }
                >
                  {timeOptions.map((time) => (
                    <MenuItem key={time.value} value={time.value}>
                      {time.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  As mensagens serão enviadas diariamente neste horário
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={globalSettings.sendOnWeekends}
                      onChange={(e) => handleGlobalSettingChange('sendOnWeekends', e.target.checked)}
                      color="secondary"
                      disabled={loading || isSubmitting}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Enviar mensagens nos finais de semana
                    </Typography>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Alert severity="info" sx={{ mb: 3 }}>
          <Typography variant="body2">
            Configure quando e quais mensagens serão enviadas aos clientes inadimplentes.
            Para cada período de inadimplência, selecione o canal e o modelo de mensagem a ser utilizado.
          </Typography>
        </Alert>
        
        {localConfig.map((config, index) => (
          <Accordion key={index} sx={{ mb: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{ bgcolor: config.enabled ? 'rgba(156, 39, 176, 0.08)' : 'white' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    {config.days} dias de inadimplência
                  </Typography>
                </Box>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={config.enabled}
                      onChange={(e) => handleReminderConfigChange(index, 'enabled', e.target.checked)}
                      color="secondary"
                      size="small"
                      disabled={loading || isSubmitting}
                    />
                  }
                  label="Ativo"
                  onClick={(e) => e.stopPropagation()}
                  sx={{ mr: 2 }}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {config.enabled && (
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Canal de comunicação
                  </Typography>
                  <RadioGroup 
                    row 
                    value={config.channel}
                    onChange={(e) => handleReminderConfigChange(index, 'channel', e.target.value)}
                  >
                    <FormControlLabel 
                      value="whatsapp" 
                      control={<Radio color="secondary" />} 
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <WhatsApp sx={{ mr: 0.5, color: '#25D366' }} fontSize="small" />
                          <Typography variant="body2">Apenas WhatsApp</Typography>
                        </Box>
                      }
                      disabled={loading || isSubmitting}
                    />
                    <FormControlLabel 
                      value="email" 
                      control={<Radio color="secondary" />} 
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Email sx={{ mr: 0.5, color: '#4285F4' }} fontSize="small" />
                          <Typography variant="body2">Apenas Email</Typography>
                        </Box>
                      }
                      disabled={loading || isSubmitting}
                    />
                    <FormControlLabel 
                      value="both" 
                      control={<Radio color="secondary" />} 
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2">Ambos</Typography>
                        </Box>
                      }
                      disabled={loading || isSubmitting}
                    />
                  </RadioGroup>
                  
                  <Divider sx={{ my: 2 }} />
                  
                  {(config.channel === 'whatsapp' || config.channel === 'both') && (
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <WhatsApp sx={{ mr: 0.5, color: '#25D366' }} fontSize="small" />
                        Mensagem do WhatsApp
                      </Typography>
                      <FormControl fullWidth margin="dense">
                        <Select
                          value={config.whatsappTemplateId}
                          onChange={(e) => handleReminderConfigChange(index, 'whatsappTemplateId', e.target.value)}
                          displayEmpty
                          disabled={loading || isSubmitting}
                        >
                          <MenuItem value="" disabled>Selecione um modelo</MenuItem>
                          {presetMessages.whatsapp.map(template => (
                            <MenuItem key={template.id} value={template.id}>
                              {template.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          Selecione o modelo de mensagem para WhatsApp
                        </FormHelperText>
                      </FormControl>
                      
                      {config.whatsappTemplateId && (
                        <Paper variant="outlined" sx={{ p: 2, mt: 1, bgcolor: '#f5f5f5' }}>
                          <Typography variant="caption" color="text.secondary">
                            Prévia:
                          </Typography>
                          <Typography variant="body2">
                            {presetMessages.whatsapp.find(t => t.id === config.whatsappTemplateId)?.content || ''}
                          </Typography>
                        </Paper>
                      )}
                    </Box>
                  )}
                  
                  {(config.channel === 'email' || config.channel === 'both') && (
                    <Box>
                      <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <Email sx={{ mr: 0.5, color: '#4285F4' }} fontSize="small" />
                        Mensagem de Email
                      </Typography>
                      <FormControl fullWidth margin="dense">
                        <Select
                          value={config.emailTemplateId}
                          onChange={(e) => handleReminderConfigChange(index, 'emailTemplateId', e.target.value)}
                          displayEmpty
                          disabled={loading || isSubmitting}
                        >
                          <MenuItem value="" disabled>Selecione um modelo</MenuItem>
                          {presetMessages.email.map(template => (
                            <MenuItem key={template.id} value={template.id}>
                              {template.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          Selecione o modelo de mensagem para Email
                        </FormHelperText>
                      </FormControl>
                      
                      {config.emailTemplateId && (
                        <Paper variant="outlined" sx={{ p: 2, mt: 1, bgcolor: '#f5f5f5' }}>
                          <Typography variant="caption" color="text.secondary">
                            Prévia:
                          </Typography>
                          <Typography variant="body2">
                            {presetMessages.email.find(t => t.id === config.emailTemplateId)?.content || ''}
                          </Typography>
                        </Paper>
                      )}
                    </Box>
                  )}
                  
                  <Box sx={{ mt: 3 }}>
                    <TextField
                      label="Dias de inadimplência"
                      type="number"
                      value={config.days}
                      onChange={(e) => handleReminderConfigChange(index, 'days', parseInt(e.target.value) || 0)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Timer fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      helperText="Defina quando este lembrete será enviado (dias após vencimento)"
                      fullWidth
                      margin="normal"
                      disabled={loading || isSubmitting}
                    />
                  </Box>
                </Box>
              )}
              
              {!config.enabled && (
                <Alert severity="info">
                  <Typography variant="body2">
                    Ative esta configuração para definir as mensagens que serão enviadas após {config.days} dias de inadimplência.
                  </Typography>
                </Alert>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button 
            variant="outlined" 
            color="secondary"
            startIcon={<Add />}
            onClick={handleAddReminder}
            disabled={loading || isSubmitting}
          >
            Adicionar Lembrete
          </Button>
          
          <Button 
            variant="outlined"
            color="error"
            startIcon={<Delete />}
            onClick={handleRemoveReminder}
            disabled={localConfig.length <= 1 || loading || isSubmitting}
          >
            Remover Último
          </Button>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
        <Button 
          onClick={handleCancel}
          disabled={loading || isSubmitting}
        >
          Cancelar
        </Button>
        <Button 
          variant="contained" 
          color="secondary"
          onClick={handleSave}
          startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <Save />}
          disabled={loading || isSubmitting}
        >
          Salvar Configurações
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DebtReminderConfigDialog;
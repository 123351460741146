/**
 * API para operações do kanban
 *
 * Em ambiente de produção, estes métodos fariam chamadas reais para o backend.
 * Para este exemplo, estamos usando dados mockados diretamente.
 */
import axios from "axios";
import { llmEndpoint } from "infra";
import { getUser } from "hooks/getUser";

// Mocks diretos para eliminar problemas de importação
const mockUsers = [
  { id: "user1", name: "Ana Silva", department: "Vendas", avatar: "" },
  { id: "user2", name: "Bruno Costa", department: "Financeiro", avatar: "" },
  { id: "user3", name: "Carla Mendes", department: "Vendas", avatar: "" },
  { id: "user4", name: "Daniel Alves", department: "Atendimento", avatar: "" },
  { id: "user5", name: "Eduardo Santos", department: "Marketing", avatar: "" },
];

// Mock para funil de Leads
const mockLeadConfig = {
  id: "lead-board",
  title: "Funil de Vendas",
  type: "lead",
  columns: [
    {
      id: "col-novo",
      title: "Novo Lead",
      color: "#f0f9ff", // Azul claro
      wip: 15, // Work in progress limit
      assignedTo: mockUsers[0],
      integrations: [],
    },
    {
      id: "col-qualificado",
      title: "Qualificado",
      color: "#ecfdf5", // Verde claro
      assignedTo: mockUsers[0],
      integrations: [
        {
          id: "integration-crm-qualify",
          name: "Atualizar CRM",
          type: "webhook",
          description: "Atualiza status do lead no CRM",
          status: "active",
        },
      ],
    },
    {
      id: "col-proposta",
      title: "Proposta Enviada",
      color: "#fef3c7", // Amarelo claro
      assignedTo: mockUsers[2],
      integrations: [
        {
          id: "integration-doc-proposal",
          name: "Gerar Proposta",
          type: "invoice",
          description: "Gera documento de proposta",
          status: "active",
        },
      ],
    },
    {
      id: "col-negociacao",
      title: "Em Negociação",
      color: "#fff7ed", // Laranja claro
      assignedTo: mockUsers[2],
      integrations: [],
    },
    {
      id: "col-ganho",
      title: "Ganho",
      color: "#dcfce7", // Verde médio
      assignedTo: mockUsers[1],
      integrations: [
        {
          id: "integration-erp-order",
          name: "Criar Pedido no ERP",
          type: "erp",
          description: "Envia pedido para o sistema ERP",
          status: "active",
        },
        {
          id: "integration-invoice-generate",
          name: "Gerar Fatura",
          type: "invoice",
          description: "Gera fatura para o cliente",
          status: "active",
        },
      ],
    },
    {
      id: "col-contrato-finalizado",
      title: "Contrato Finalizado",
      color: "#a7f3d0", // Verde mais intenso
      assignedTo: mockUsers[1],
      integrations: [
        {
          id: "integration-erp-contract-complete",
          name: "Atualizar Status no ERP",
          type: "erp",
          description: "Marca contrato como finalizado no ERP",
          status: "active",
        },
        {
          id: "integration-customer-satisfaction",
          name: "Pesquisa de Satisfação",
          type: "webhook",
          description: "Envia pesquisa de satisfação para o cliente",
          status: "active",
        },
        {
          id: "integration-finance-revenue",
          name: "Registrar Receita",
          type: "payment",
          description: "Registra receita no sistema financeiro",
          status: "active",
        },
      ],
    },
    {
      id: "col-perdido",
      title: "Perdido",
      color: "#fee2e2", // Vermelho claro
      assignedTo: mockUsers[4],
      integrations: [
        {
          id: "integration-crm-lost",
          name: "Atualizar CRM",
          type: "webhook",
          description: "Registra motivo da perda no CRM",
          status: "active",
        },
      ],
    },
  ],
  cards: [
    {
      id: "card-lead-1",
      title: "Implementação de CRM",
      type: "lead",
      columnId: "col-novo",
      company: "Tech Solutions Ltda",
      description: "Cliente interessado em implementação de CRM",
      priority: "alta",
      value: 75000,
      createdAt: "2023-03-10T14:30:00Z",
      updatedAt: "2023-03-10T14:30:00Z",
      contact: {
        name: "Roberto Martins",
        email: "roberto@example.com",
        phone: "(11) 3456-7890",
        whatsapp: "11987654321",
      },
      assignedTo: mockUsers[0],
    },
    {
      id: "card-lead-2",
      title: "Expansão de Infraestrutura",
      type: "lead",
      columnId: "col-qualificado",
      company: "Distribuidora Norte",
      description: "Expansão de infraestrutura de TI",
      priority: "média",
      value: 120000,
      createdAt: "2023-03-08T10:15:00Z",
      updatedAt: "2023-03-09T16:45:00Z",
      contact: {
        name: "Amanda Souza",
        email: "amanda@example.com",
        phone: "(11) 2345-6789",
        whatsapp: "11976543210",
      },
      assignedTo: mockUsers[2],
    },
  ],
};

// Mock para funil de Inadimplência
const mockInadimplenciaConfig = {
  id: "inadimplencia-board",
  title: "Gestão de Inadimplência",
  type: "inadimplencia",
  columns: [
    {
      id: "col-vencido",
      title: "Recém Vencido",
      color: "#fff7ed", // Laranja claro
      wip: 20,
      assignedTo: mockUsers[3],
      integrations: [
        {
          id: "integration-crm-late",
          name: "Atualizar Status no CRM",
          type: "webhook",
          description: "Marca cliente como inadimplente no CRM",
          status: "active",
        },
      ],
    },
    {
      id: "col-contato",
      title: "Contato Realizado",
      color: "#fef3c7", // Amarelo claro
      assignedTo: mockUsers[3],
      integrations: [],
    },
    {
      id: "col-negociacao",
      title: "Em Negociação",
      color: "#ecfdf5", // Verde claro
      assignedTo: mockUsers[1],
      integrations: [],
    },
    {
      id: "col-acordo",
      title: "Acordo Firmado",
      color: "#dcfce7", // Verde médio
      assignedTo: mockUsers[1],
      integrations: [
        {
          id: "integration-finance-agreement",
          name: "Registrar Acordo",
          type: "payment",
          description: "Registra acordo de pagamento no sistema financeiro",
          status: "active",
        },
      ],
    },
    {
      id: "col-pago",
      title: "Pagamento Realizado",
      color: "#d1fae5", // Verde escuro
      assignedTo: mockUsers[1],
      integrations: [
        {
          id: "integration-finance-paid",
          name: "Baixar Fatura",
          type: "payment",
          description: "Registra pagamento no sistema financeiro",
          status: "active",
        },
        {
          id: "integration-crm-update",
          name: "Atualizar CRM",
          type: "webhook",
          description: "Remove marcação de inadimplente",
          status: "active",
        },
      ],
    },
    {
      id: "col-juridico",
      title: "Enviado ao Jurídico",
      color: "#fee2e2", // Vermelho claro
      assignedTo: mockUsers[4],
      integrations: [
        {
          id: "integration-legal-case",
          name: "Abrir Processo",
          type: "webhook",
          description: "Registra caso no sistema jurídico",
          status: "active",
        },
      ],
    },
  ],
  cards: [
    {
      id: "card-inad-1",
      title: "Fatura #12458",
      type: "inadimplencia",
      columnId: "col-vencido",
      company: "Mercado Central Ltda",
      description: "Fatura de serviços de manutenção",
      priority: "média",
      diasAtraso: 15,
      invoiceId: "12458",
      value: 5800,
      createdAt: "2023-03-10T14:30:00Z",
      updatedAt: "2023-03-10T14:30:00Z",
      contact: {
        name: "José Pereira",
        email: "jose@example.com",
        phone: "(11) 3456-7890",
        whatsapp: "11987654321",
      },
      assignedTo: mockUsers[3],
    },
  ],
};

// Cache simulado para persistir alterações durante a sessão
let boardsCache = {
  lead: { ...mockLeadConfig },
  inadimplencia: { ...mockInadimplenciaConfig },
};

// Log para debug
console.log("KanbanAPI inicializado com dados mockados:", {
  lead: boardsCache.lead,
  inadimplencia: boardsCache.inadimplencia,
});

/**
 * Obtém a configuração de um quadro específico
 */
export const fetchBoardConfig = async (boardType, rpaId) => {
  // Log para debug
  console.log(`fetchBoardConfig chamado para tipo: ${boardType}`);
  const response = await axios.get(
    `${llmEndpoint.url}/kanban/boards/${rpaId}`,
    {
      params: {
        type: boardType,
      },
      headers: {
        accept: "*/*",
      },
    },
  );

  const userId = getUser().id;

  const responseLead = await axios.get(
    `${llmEndpoint.url}/kanban/boards/${rpaId}/${response.data[0].id}?userId=${userId}`,
    {
      headers: {
        accept: "*/*",
      },
    },
  );

  return responseLead.data;

  // // Simula latência de rede
  // await new Promise((resolve) => setTimeout(resolve, 300));

  // if (boardType === "lead") {
  //   console.log("Retornando configuração de Lead:", boardsCache.lead);
  //   return boardsCache.lead;
  // } else if (boardType === "inadimplencia") {
  //   console.log(
  //     "Retornando configuração de Inadimplência:",
  //     boardsCache.inadimplencia,
  //   );
  //   return boardsCache.inadimplencia;
  // } else {
  //   console.error(`Tipo de quadro não suportado: ${boardType}`);
  //   // Fallback para evitar erros
  //   console.log("Retornando configuração de Lead como fallback");
  //   return boardsCache.lead;
  // }
};

/**
 * Obtém os cards de um quadro específico
 */
export const fetchBoardCards = async (boardType, rpaId) => {
  // Simula latência de rede
  const response = await axios.get(
    `${llmEndpoint.url}/kanban/boards/${rpaId}`,
    {
      params: {
        type: boardType,
      },
      headers: {
        accept: "*/*",
      },
    },
  );

  console.log(`fetchBoardCards chamado para tipo: ${boardType}`);
  const userId = getUser().id;
  const responseBoard = await axios.get(
    `${llmEndpoint.url}/kanban/boards/${rpaId}/${response.data[0].id}?userId=${userId}`,
    {
      headers: {
        accept: "*/*",
      },
    },
  );

  console.log(responseBoard.data);

  if (boardType === "lead" || boardType === "inadimplencia") {
    return {
      cards: responseBoard.data.cards,
      columns: responseBoard.data.columns,
    };
  } else {
    console.error(`Tipo de quadro não suportado: ${boardType}`);
    // Fallback para evitar erros
    return {
      cards: [],
      columns: [],
    };
  }
};

export const updateBoardConfig = async (updatedConfig) => {
  // Simula latência de rede
  await new Promise((resolve) => setTimeout(resolve, 500));

  const boardType = updatedConfig.type;

  if (boardType === "lead" || boardType === "inadimplencia") {
    // Atualiza o cache local
    boardsCache[boardType] = updatedConfig;

    // Em um ambiente de produção, você faria uma chamada à API:
    // const response = await fetch(`${API_URL}/boards/${updatedConfig.id}`, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(updatedConfig),
    // });
    //
    // if (!response.ok) {
    //   throw new Error('Falha ao atualizar configuração do quadro');
    // }
    //
    // return await response.json();

    return updatedConfig;
  } else {
    throw new Error(`Tipo de quadro não suportado: ${boardType}`);
  }
};

/**
 * Atualiza a posição de um card
 */
export const updateCardPosition = async (
  cardId,
  destinationColumnId,
  destinationIndex,
  rpaId,
) => {
  // Simula latência de rede
  await new Promise((resolve) => setTimeout(resolve, 300));

  const response = await axios.post(
    `${llmEndpoint.url}/kanban/cards/${rpaId}/move`,
    {
      cardId: cardId,
      targetColumnId: destinationColumnId,
    },
    {
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
    },
  );

  console.log(response.data);
  console.log(`updateCardPosition chamado para cardId: ${cardId}`);

  // Encontra em qual board o card está
  let foundBoard = null;
  let foundCard = null;

  // for (const boardType in boardsCache) {
  //   const board = boardsCache[boardType];
  //   const card = board.cards.find((c) => c.id === cardId);

  //   if (card) {
  //     foundBoard = board;
  //     foundCard = card;
  //     break;
  //   }
  // }

  // if (!foundBoard || !foundCard) {
  //   throw new Error(`Card não encontrado: ${cardId}`);
  // }

  // // Atualiza a coluna do card
  // foundCard.columnId = destinationColumnId;
  // foundCard.updatedAt = new Date().toISOString();

  return response.data;
};

/**
 * Salva um card (novo ou existente)
 */
export const saveCard = async (cardData) => {
  // Simula latência de rede
  await new Promise((resolve) => setTimeout(resolve, 300));

  const boardType = cardData.type;
  const board = boardsCache[boardType];

  if (!board) {
    throw new Error(`Tipo de quadro não suportado: ${boardType}`);
  }

  // Caso seja um card existente
  if (cardData.id) {
    const cardIndex = board.cards.findIndex((c) => c.id === cardData.id);

    if (cardIndex === -1) {
      // Em vez de lançar um erro, adiciona o card como novo
      console.log(
        `Card não encontrado no cache local: ${cardData.id}, adicionando-o como novo`,
      );

      const newCard = {
        ...cardData,
        createdAt: cardData.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      board.cards.push(newCard);
      return newCard;
    }

    // Atualiza o card
    const updatedCard = {
      ...board.cards[cardIndex],
      ...cardData,
      updatedAt: new Date().toISOString(),
    };

    board.cards[cardIndex] = updatedCard;
    return updatedCard;
  }
  // Caso seja um novo card
  else {
    // Usa o ID fornecido ou gera um novo
    const newCard = {
      ...cardData,
      id: cardData.id || `card-${Date.now()}`,
      createdAt: cardData.createdAt || new Date().toISOString(),
      updatedAt: cardData.updatedAt || new Date().toISOString(),
    };

    board.cards.push(newCard);
    return newCard;
  }
};

/**
 * Remove um card
 */
export const deleteCardById = async (cardId) => {
  // Simula latência de rede
  await new Promise((resolve) => setTimeout(resolve, 300));

  // Encontra em qual board o card está
  for (const boardType in boardsCache) {
    const board = boardsCache[boardType];
    const cardIndex = board.cards.findIndex((c) => c.id === cardId);

    if (cardIndex !== -1) {
      // Remove o card
      board.cards.splice(cardIndex, 1);
      return true;
    }
  }

  throw new Error(`Card não encontrado: ${cardId}`);
};

/**
 * Adiciona uma nova coluna
 */
export const addColumn = async (boardType, columnData) => {
  // Simula latência de rede
  await new Promise((resolve) => setTimeout(resolve, 300));

  const board = boardsCache[boardType];

  if (!board) {
    throw new Error(`Tipo de quadro não suportado: ${boardType}`);
  }

  // Gera um ID para a nova coluna
  const newColumn = {
    ...columnData,
    id: `col-${Date.now()}`,
  };

  board.columns.push(newColumn);
  return newColumn;
};

/**
 * Reseta os dados para o estado inicial (útil para testes)
 */
export const resetBoardData = () => {
  boardsCache = {
    lead: { ...mockLeadConfig },
    inadimplencia: { ...mockInadimplenciaConfig },
  };
};

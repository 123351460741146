import { useParams } from "react-router-dom";
import { Contato, ContactsResponse } from "./ContactTypes";
import { llmEndpoint } from "infra";

export const ContactService = {
  async fetchContacts(
    rpaId: string,
    term: string = "",
    page: number = 0,
    size: number = 10,
    sort: string = "name",
    direction: string = "asc",
  ): Promise<ContactsResponse> {
    try {
      const response = await fetch(
        `${llmEndpoint.url}/whatsapp/contacts/${rpaId}/search?term=${term}&page=${page}&size=${size}&sort=${sort}&direction=${direction}`,
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching contacts:", error);
      throw error;
    }
  },

  async createContact(contactData: Partial<Contato>): Promise<Contato> {
    try {
      const response = await fetch(`${llmEndpoint.url}/whatsapp/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error creating contact:", error);
      throw error;
    }
  },

  async updateContact(
    rpaId: string,
    contactId: string,
    contactData: Partial<Contato>,
  ): Promise<Contato> {
    try {
      const response = await fetch(
        `${llmEndpoint.url}/whatsapp/contacts/${rpaId}/${contactId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactData),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error updating contact:", error);
      throw error;
    }
  },

  async deleteContact(rpaId: string, contactId: string): Promise<void> {
    try {
      const response = await fetch(
        `${llmEndpoint.url}/whatsapp/contacts/${rpaId}/${contactId}`,
        {
          method: "DELETE",
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error deleting contact:", error);
      throw error;
    }
  },
};

import { useState, useEffect, useCallback } from 'react'
import {
  Add,
  LocalOffer as TagIcon,
  ChevronLeft,
  ChevronRight,
  Search as SearchIcon,
  FilterList as FilterIcon,
  Sort as SortIcon,
  Clear as ClearIcon
} from '@mui/icons-material'
import { Button } from '@intraversa-lab/styleguide'
import {
  Box,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  TextField,
  CircularProgress,
  InputAdornment,
  Badge,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox
} from '@mui/material'
import debounce from 'lodash/debounce'
import { InputSearch, SearchBarMobile } from 'components'
import * as S from '../styles/styles'
import EmpresaCard from './EmpresaCard'

// Interfaces
interface Contato {
  id: string
  active: boolean
  name: string
  description: string
  number: string
  rpaId: string
  tags?: string
  email?: string
  type?: string
  companyId?: string
}

interface Endereco {
  id: string
  enterpriseId: string
  zipCode: string
  state: string
  city: string
  neighborhood: string
  street: string
  number: string
  complement?: string
}

interface Empresa {
  id: string
  active: boolean
  createdAt: string
  updatedAt: string
  name: string
  description: string
  address?: Endereco
  document: string
  rpaId: string
  contacts: Contato[]
  tags?: string
  integrationCode?: string
  erpIntegrationId?: string
}

// Props do componente
interface EmpresaListProps {
  empresas: Empresa[]
  search: string
  setSearch: (value: string) => void
  tagFilter: string
  setTagFilter: (tag: string) => void
  handleCardClick: (empresa: Empresa) => void
  addNewEmpresa: () => void
  // Novas props para paginação e ordenação
  page: number
  totalPages: number
  totalElements: number
  fetchEmpresas: (pageNum?: number, overrideParams?: any) => void
  setPage: (page: number) => void
  sort: string
  direction: string
  setSort: (field: string) => void
  setDirection: (direction: string) => void
  // Props para filtros específicos
  nameFilter: string
  setNameFilter: (value: string) => void
  documentFilter: string
  setDocumentFilter: (value: string) => void
  descriptionFilter: string
  setDescriptionFilter: (value: string) => void
  // Nova prop para receber todas as tags disponíveis
  availableTags: string[]
  // Flag para indicar se está carregando as tags
  loadingTags?: boolean
}
const EmpresaList: React.FC<EmpresaListProps> = ({
  empresas,
  search,
  setSearch,
  tagFilter,
  setTagFilter,
  handleCardClick,
  addNewEmpresa,
  page,
  totalPages,
  totalElements,
  fetchEmpresas,
  setPage,
  sort,
  direction,
  setSort,
  setDirection,
  nameFilter,
  setNameFilter,
  documentFilter,
  setDocumentFilter,
  descriptionFilter,
  setDescriptionFilter,
  availableTags = [],
  loadingTags = false
}) => {
  
  const [showInputSearch, setShowInputSearch] = useState<boolean>(false)
  const [tagAnchorEl, setTagAnchorEl] = useState<HTMLElement | null>(null)
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null)
  const [sortAnchorEl, setSortAnchorEl] = useState<HTMLElement | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [tagSearchTerm, setTagSearchTerm] = useState<string>('')
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  
  const [localSearch, setLocalSearch] = useState<string>(search)
  
  const getActiveFiltersCount = () => {
    let count = 0
    if (nameFilter) count++
    if (documentFilter) count++
    if (descriptionFilter) count++
    return count
  }

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value.length >= 3) {
        setLoading(true)
        setSearch(value)
        setPage(0)
        fetchEmpresas(0)
        setLoading(false)
      }
    }, 500),
    [setSearch, setPage, fetchEmpresas]
  )

  // Atualizar o localSearch quando search mudar externamente
  useEffect(() => {
    setLocalSearch(search)
  }, [search])

  // Efeito para o debounce
  useEffect(() => {
    debouncedSearch(localSearch)
    // Cancelar o debounce quando o componente for desmontado
    return () => {
      debouncedSearch.cancel()
    }
  }, [localSearch, debouncedSearch])
  // Manipuladores para o menu de tags
  const handleTagMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTagAnchorEl(event.currentTarget)
    // Inicializar selectedTags com a tag atual se existir
    if (tagFilter) {
      setSelectedTags([tagFilter])
    } else {
      setSelectedTags([])
    }
  }
  
  const handleTagMenuClose = () => setTagAnchorEl(null)
  
  const handleTagSelect = async (tag: string) => {
    setLoading(true)
    setTagFilter(tag)
    handleTagMenuClose()
    setPage(0)
    await fetchEmpresas(0)
    setLoading(false)
  }
  
  const clearTagFilter = async () => {
    setLoading(true)
    setTagFilter('')
    setSelectedTags([])
    setPage(0)
    await fetchEmpresas(0)
    setLoading(false)
  }

  // Novo manipulador para selecionar múltiplas tags
  const handleTagToggle = (tag: string) => {
    const currentIndex = selectedTags.indexOf(tag)
    const newSelected = [...selectedTags]

    if (currentIndex === -1) {
      newSelected.push(tag)
    } else {
      newSelected.splice(currentIndex, 1)
    }

    setSelectedTags(newSelected)
  }

 // Aplicar filtro de múltiplas tags
const applyTagsFilter = async () => {
  setLoading(true);
  
  // Criar uma string com todas as tags selecionadas
  const tagsString = selectedTags.join(',');
  setTagFilter(tagsString);
  
  handleTagMenuClose();
  setPage(0);
  
  await fetchEmpresas(0, { 
    tags: tagsString,                
    selectedTags: selectedTags,      
    term: search                    
  });
  
  setLoading(false);
};

  const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setSortAnchorEl(event.currentTarget)
  const handleSortMenuClose = () => setSortAnchorEl(null)
  const handleSortSelect = async (field: string) => {
    setLoading(true)
    if (sort === field) {
      setDirection(direction === 'asc' ? 'desc' : 'asc')
    } else {
      setSort(field)
      setDirection('asc')
    }
    setSortAnchorEl(null)
    setPage(0)
    await fetchEmpresas(0)
    setLoading(false)
  }

  // Manipuladores para o menu de filtros avançados
  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setFilterAnchorEl(event.currentTarget)
  const handleFilterMenuClose = () => setFilterAnchorEl(null)

  // Função para filtrar tags pelo termo de busca
  const getFilteredTags = (): string[] => {
    if (!tagSearchTerm) {
      return availableTags
    }
    
    return availableTags.filter(tag => 
      tag.toLowerCase().includes(tagSearchTerm.toLowerCase())
    )
  }
  // Manipula a mudança de página
  const handlePageChange = async (
    _event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setLoading(true)
    setPage(newPage - 1) // API é baseada em zero, UI é baseada em 1
    await fetchEmpresas(newPage - 1)
    setLoading(false)
  }

  const handlePrevPage = async () => {
    if (page > 0) {
      setLoading(true)
      setPage(page - 1)
      await fetchEmpresas(page - 1)
      setLoading(false)
    }
  }

  const handleNextPage = async () => {
    if (page < totalPages - 1) {
      setLoading(true)
      setPage(page + 1)
      await fetchEmpresas(page + 1)
      setLoading(false)
    }
  }

  // Aplicar filtros
  const handleApplyFilters = async () => {
    setLoading(true)
    setPage(0)
    await fetchEmpresas(0)
    handleFilterMenuClose()
    setLoading(false)
  }

  // Limpar todos os filtros
  const handleClearAllFilters = async () => {
    setLoading(true)
    setLocalSearch('')
    setSearch('')
    setNameFilter('')
    setDocumentFilter('')
    setDescriptionFilter('')
    setTagFilter('')
    setSelectedTags([])
    setPage(0)
    await fetchEmpresas(0)
    handleFilterMenuClose()
    setLoading(false)
  }

  // Manipulador de pesquisa manual (para quando o usuário pressionar Enter)
  const handleManualSearch = async () => {
    setLoading(true)
    setSearch(localSearch)
    setPage(0)
    await fetchEmpresas(0)
    setLoading(false)
  }

  // Função para rolar para o topo da lista quando mudar de página
  const scrollToTop = () => {
    const listContainer = document.getElementById('empresas-list-container')
    if (listContainer) {
      listContainer.scrollTop = 0
    }
  }

  // Rolar para o topo sempre que mudar de página
  useEffect(() => {
    scrollToTop()
  }, [page])
  return (
    <div
      style={{
        width: '100%',
        overflowX: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
     <S.WrapperSerach>
  <div className="filters">
    {showInputSearch ? (
      <div className="inputSearchMobile">
        <SearchBarMobile
          placeholder={'Pesquisar empresa'}
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
          onSubmit={handleManualSearch}
          onClose={() => setShowInputSearch(!showInputSearch)}
        />
      </div>
    ) : (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: 2,
          width: '100%',
        }}
      >
        {/* Campo de pesquisa aprimorado */}
        <Box sx={{ 
          position: 'relative', 
          flexGrow: 1, 
          width: '100%',
          mb: { xs: 2, md: 0 } 
        }}>
          <TextField
            placeholder="Pesquisar empresa"
            value={localSearch}
            onChange={(e) => setLocalSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleManualSearch()
              }
            }}
            fullWidth
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                pr: '14px',
                height: '44px',
                transition: 'all 0.2s ease',
                backgroundColor: '#fff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                '&:hover': {
                  boxShadow: '0 3px 6px rgba(0,0,0,0.08)',
                },
                '&.Mui-focused': {
                  boxShadow: '0 3px 8px rgba(63, 81, 181, 0.15)',
                }
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#9e9e9e', ml: 1 }} />
                </InputAdornment>
              ),
              endAdornment: localSearch && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear search"
                    onClick={() => {
                      setLocalSearch('')
                      setSearch('')
                      setPage(0)
                      fetchEmpresas(0)
                    }}
                    edge="end"
                    size="small"
                  >
                    <ClearIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Tooltip title="Pesquisar">
            <IconButton
              onClick={handleManualSearch}
              sx={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: '#3f51b5',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
                height: 44,
                width: 44,
                borderRadius: '0 8px 8px 0',
              }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              bottom: -18,
              left: 2,
              color: '#757575',
              fontSize: '0.7rem'
            }}
          >
            {localSearch && localSearch.length < 3
              ? 'Digite pelo menos 3 caracteres ou pressione Enter'
              : 'Busca automática após 3 caracteres'}
          </Typography>
        </Box>
        
        {/* Área de botões */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          flexWrap: { xs: 'wrap', md: 'nowrap' },
          justifyContent: { xs: 'space-between', md: 'flex-end' },
          width: { xs: '100%', md: 'auto' } 
        }}>
          {/* Tag filter button */}
          <Tooltip title="Filtrar por tag">
            <Box>
              <Badge
                badgeContent={selectedTags.length || (tagFilter ? 1 : 0)}
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    right: 5,
                    top: 5,
                  }
                }}
              >
                <Button
                  startIcon={<TagIcon />}
                  onClick={handleTagMenuOpen}
                  className="tagFilter"
                  variant={tagFilter ? 'contained' : 'outlined'}
                  sx={{
                    minWidth: { xs: '90px', md: 'auto' },
                    height: '44px',
                    borderRadius: '8px',
                    backgroundColor: tagFilter ? '#3f51b5' : 'transparent',
                    borderColor: '#3f51b5',
                    color: tagFilter ? 'white' : '#3f51b5',
                    '&:hover': {
                      backgroundColor: tagFilter
                        ? '#303f9f'
                        : 'rgba(63, 81, 181, 0.08)',
                      boxShadow: tagFilter ? '0 3px 6px rgba(63, 81, 181, 0.3)' : 'none',
                    },
                  }}
                >
                  {tagFilter ? (tagFilter.length > 12 ? `${tagFilter.substring(0, 12)}...` : tagFilter) : 'Tags'}
                </Button>
              </Badge>
            </Box>
          </Tooltip>

          {/* <Tooltip title="Filtros avançados">
            <Box>
              <Badge
                badgeContent={getActiveFiltersCount()}
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    right: 5,
                    top: 5,
                  }
                }}
              >
                <Button
                  startIcon={<FilterIcon />}
                  onClick={handleFilterMenuOpen}
                  className="advancedFilter"
                  variant={
                    nameFilter || documentFilter || descriptionFilter
                      ? 'contained'
                      : 'outlined'
                  }
                  sx={{
                    minWidth: { xs: '90px', md: 'auto' },
                    height: '44px',
                    borderRadius: '8px',
                    backgroundColor:
                      nameFilter || documentFilter || descriptionFilter
                        ? '#3f51b5'
                        : 'transparent',
                    borderColor: '#3f51b5',
                    color:
                      nameFilter || documentFilter || descriptionFilter
                        ? 'white'
                        : '#3f51b5',
                    '&:hover': {
                      backgroundColor:
                        nameFilter || documentFilter || descriptionFilter
                          ? '#303f9f'
                          : 'rgba(63, 81, 181, 0.08)',
                      boxShadow: (nameFilter || documentFilter || descriptionFilter) ? '0 3px 6px rgba(63, 81, 181, 0.3)' : 'none',
                    },
                  }}
                >
                  Filtros
                </Button>
              </Badge>
            </Box>
          </Tooltip> */}

          {/* Botão de ordenação */}
          <Tooltip title="Ordenação">
            <Box>
              <Button
                startIcon={<SortIcon />}
                onClick={handleSortMenuOpen}
                className="sortButton"
                variant="outlined"
                sx={{
                  minWidth: { xs: '90px', md: 'auto' },
                  height: '44px',
                  borderRadius: '8px',
                  borderColor: '#3f51b5',
                  color: '#3f51b5',
                  '&:hover': {
                    backgroundColor: 'rgba(63, 81, 181, 0.08)',
                  },
                }}
              >
                Ordenar
              </Button>
            </Box>
          </Tooltip>
        </Box>

        {/* Menus (mantidos sem alterações) */}
        <Menu
          anchorEl={tagAnchorEl}
          open={Boolean(tagAnchorEl)}
          onClose={handleTagMenuClose}
          PaperProps={{
            style: {
              maxHeight: 500,
              width: '320px',
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            },
          }}
        >
          <Box sx={{ p: 2, pb: 1 }}>
            <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 1 }}>
              Filtrar por Tags
            </Typography>
            <TextField
              placeholder="Buscar tags..."
              value={tagSearchTerm}
              onChange={(e) => setTagSearchTerm(e.target.value)}
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#9e9e9e', fontSize: 20 }} />
                  </InputAdornment>
                ),
                endAdornment: tagSearchTerm && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setTagSearchTerm('')}
                      edge="end"
                      size="small"
                    >
                      <ClearIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{ mb: 1 }}
            />
          </Box>
          <Divider />
          {loadingTags ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <>
              <Box sx={{ maxHeight: 350, overflow: 'auto' }}>
                <List dense>
                  {getFilteredTags().length > 0 ? (
                    getFilteredTags().map((tag) => (
                      <ListItem
                        key={tag}
                        sx={{
                          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                          '&:hover': {
                            backgroundColor: 'rgba(63, 81, 181, 0.05)',
                          },
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <Checkbox
                            edge="start"
                            checked={selectedTags.indexOf(tag) !== -1}
                            tabIndex={-1}
                            disableRipple
                            onChange={() => handleTagToggle(tag)}
                            sx={{
                              color: '#3f51b5',
                              '&.Mui-checked': {
                                color: '#3f51b5',
                              },
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={tag}
                          primaryTypographyProps={{
                            style: { fontSize: '0.875rem' }
                          }}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText
                        primary={
                          tagSearchTerm
                            ? `Nenhuma tag encontrada para "${tagSearchTerm}"`
                            : "Nenhuma tag disponível"
                        }
                        primaryTypographyProps={{
                          style: {
                            fontSize: '0.875rem',
                            fontStyle: 'italic',
                            color: '#757575',
                            textAlign: 'center'
                          }
                        }}
                      />
                    </ListItem>
                  )}
                </List>
              </Box>
              <Divider />
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={clearTagFilter}
                  sx={{ color: '#f44336', borderColor: '#f44336' }}
                >
                  Limpar
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={applyTagsFilter}
                  disabled={selectedTags.length === 0}
                  sx={{
                    backgroundColor: '#3f51b5',
                    '&:hover': {
                      backgroundColor: '#303f9f',
                    },
                  }}
                >
                  Aplicar ({selectedTags.length})
                </Button>
              </Box>
            </>
          )}
        </Menu>

        {/* Menu de filtros avançados */}
        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={handleFilterMenuClose}
          PaperProps={{
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
              padding: '16px',
              width: '320px',
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ px: 2, mb: 1, fontWeight: 'medium' }}
          >
            Filtros Avançados
          </Typography>
          <Box sx={{ px: 2, py: 1 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Nome:
            </Typography>
            <TextField
              placeholder="Filtrar por nome"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              fullWidth
              size="small"
            />
          </Box>
          <Box sx={{ px: 2, py: 1 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Documento:
            </Typography>
            <TextField
              placeholder="CNPJ/CPF"
              value={documentFilter}
              onChange={(e) => setDocumentFilter(e.target.value)}
              fullWidth
              size="small"
            />
          </Box>
          <Box sx={{ px: 2, py: 1 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Descrição:
            </Typography>
            <TextField
              placeholder="Filtrar por descrição"
              value={descriptionFilter}
              onChange={(e) => setDescriptionFilter(e.target.value)}
              fullWidth
              size="small"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
              pt: 2,
              pb: 1,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClearAllFilters}
              size="small"
              sx={{ color: '#f44336', borderColor: '#f44336' }}
            >
              Limpar
            </Button>
            <Button
              variant="contained"
              onClick={handleApplyFilters}
              size="small"
              sx={{
                backgroundColor: '#3f51b5',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
              }}
            >
              Aplicar
            </Button>
          </Box>
        </Menu>

        {/* Menu de ordenação */}
        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={handleSortMenuClose}
          PaperProps={{
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            },
          }}
        >
          <MenuItem
            onClick={() => handleSortSelect('name')}
            selected={sort === 'name'}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'rgba(63, 81, 181, 0.1)',
              },
            }}
          >
            Nome {sort === 'name' && (direction === 'asc' ? '↑' : '↓')}
          </MenuItem>
          <MenuItem
            onClick={() => handleSortSelect('document')}
            selected={sort === 'document'}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'rgba(63, 81, 181, 0.1)',
              },
            }}
          >
            Documento{' '}
            {sort === 'document' && (direction === 'asc' ? '↑' : '↓')}
          </MenuItem>
          <MenuItem
            onClick={() => handleSortSelect('createdAt')}
            selected={sort === 'createdAt'}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'rgba(63, 81, 181, 0.1)',
              },
            }}
          >
            Data de cadastro{' '}
            {sort === 'createdAt' && (direction === 'asc' ? '↑' : '↓')}
          </MenuItem>
        </Menu>
      </Box>
    )}
  </div>
  <Button
    startIcon={<Add />}
    onClick={addNewEmpresa}
    className="newProfile"
    sx={{
      height: '44px',
      minWidth: '140px',
      borderRadius: '8px',
      fontWeight: 500,
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      '&:hover': {
        backgroundColor: '#3C454D !important',
        color: '#F9F9F9',
        boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
      },
    }}
  >
    Nova Empresa
  </Button>
</S.WrapperSerach>
          {/* Status dos filtros ativos */}
      {(tagFilter || nameFilter || documentFilter || descriptionFilter || search) && (
        <Box sx={{ px: '30px', pb: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: 1,
              color: '#666',
            }}
          >
            <Typography variant="body2">Filtros ativos:</Typography>

            {search && (
              <Chip
                icon={
                  <SearchIcon
                    sx={{
                      fontSize: '16px !important',
                      color: 'white !important',
                    }}
                  />
                }
                label={`Termo: ${search}`}
                size="small"
                onDelete={async () => {
                  setLoading(true)
                  setLocalSearch('')
                  setSearch('')
                  setPage(0)
                  await fetchEmpresas(0)
                  setLoading(false)
                }}
                sx={{
                  backgroundColor: '#3f51b5',
                  color: 'white',
                  height: '24px',
                  '& .MuiChip-deleteIcon': {
                    color: 'white',
                    '&:hover': {
                      color: '#f1f1f1',
                    },
                  },
                }}
              />
            )}

{tagFilter && (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    {tagFilter.split(',').map((tag) => (
      <Chip
        key={tag}
        icon={<TagIcon sx={{ fontSize: '16px !important', color: 'white !important' }} />}
        label={tag.trim()}
        size="small"
        onDelete={clearTagFilter}
        sx={{
          backgroundColor: '#3f51b5',
          color: 'white',
          height: '24px',
          '& .MuiChip-deleteIcon': {
            color: 'white',
            '&:hover': { color: '#f1f1f1' },
          },
        }}
      />
    ))}
  </Box>
)}

            {nameFilter && (
              <Chip
                label={`Nome: ${nameFilter}`}
                size="small"
                onDelete={async () => {
                  setLoading(true)
                  setNameFilter('')
                  setPage(0)
                  await fetchEmpresas(0)
                  setLoading(false)
                }}
                sx={{
                  backgroundColor: '#3f51b5',
                  color: 'white',
                  height: '24px',
                  '& .MuiChip-deleteIcon': {
                    color: 'white',
                    '&:hover': {
                      color: '#f1f1f1',
                    },
                  },
                }}
              />
            )}

            {documentFilter && (
              <Chip
                label={`Documento: ${documentFilter}`}
                size="small"
                onDelete={async () => {
                  setLoading(true)
                  setDocumentFilter('')
                  setPage(0)
                  await fetchEmpresas(0)
                  setLoading(false)
                }}
                sx={{
                  backgroundColor: '#3f51b5',
                  color: 'white',
                  height: '24px',
                  '& .MuiChip-deleteIcon': {
                    color: 'white',
                    '&:hover': {
                      color: '#f1f1f1',
                    },
                  },
                }}
              />
            )}

            {descriptionFilter && (
              <Chip
                label={`Descrição: ${descriptionFilter}`}
                size="small"
                onDelete={async () => {
                  setLoading(true)
                  setDescriptionFilter('')
                  setPage(0)
                  await fetchEmpresas(0)
                  setLoading(false)
                }}
                sx={{
                  backgroundColor: '#3f51b5',
                  color: 'white',
                  height: '24px',
                  '& .MuiChip-deleteIcon': {
                    color: 'white',
                    '&:hover': {
                      color: '#f1f1f1',
                    },
                  },
                }}
              />
            )}

            {(tagFilter ||
              nameFilter ||
              documentFilter ||
              descriptionFilter ||
              search) && (
              <Button
                size="small"
                onClick={handleClearAllFilters}
                sx={{
                  ml: 1,
                  color: 'white',
                  textTransform: 'none',
                  minWidth: 'auto',
                  fontSize: '0.75rem',
                }}
              >
                Limpar todos
              </Button>
            )}
          </Box>
        </Box>
      )}
      {/* Paginação no topo da lista */}
      {totalElements > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 30px 15px',
            width: '100%',
            borderBottom: '1px solid #eee',
            backgroundColor: 'white',
            zIndex: 5,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ color: '#666' }}>
              Mostrando {empresas.length} de {totalElements} empresas
            </Typography>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={handlePrevPage}
              disabled={page === 0 || loading}
              size="small"
              sx={{
                mr: 1,
                backgroundColor: page === 0 ? '#f5f5f5' : '#e0e0e0',
                color: page === 0 ? '#bdbdbd' : '#616161',
                '&:hover': { backgroundColor: '#d5d5d5' },
              }}
            >
              <ChevronLeft />
            </IconButton>

            <Pagination
              count={totalPages}
              page={page + 1} // API é baseada em zero, UI é baseada em 1
              onChange={handlePageChange}
              size="small"
              hideNextButton
              hidePrevButton
              disabled={loading}
              sx={{
                '& .MuiPaginationItem-root': {
                  minWidth: '30px',
                  height: '30px',
                  fontSize: '0.875rem',
                },
                '& .Mui-selected': {
                  backgroundColor: '#3f51b5',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#303f9f',
                  },
                },
              }}
            />

            <IconButton
              onClick={handleNextPage}
              disabled={page >= totalPages - 1 || totalPages === 0 || loading}
              size="small"
              sx={{
                ml: 1,
                backgroundColor:
                  page >= totalPages - 1 || totalPages === 0
                    ? '#f5f5f5'
                    : '#e0e0e0',
                color:
                  page >= totalPages - 1 || totalPages === 0
                    ? '#bdbdbd'
                    : '#616161',
                '&:hover': { backgroundColor: '#d5d5d5' },
              }}
            >
              <ChevronRight />
            </IconButton>
          </div>
        </div>
      )}
      {/* Listagem de Empresas */}
      <div
        id="empresas-list-container"
        style={{
          padding: '0 30px',
          width: '100%',
          flex: 1,
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 250px)',
          position: 'relative',
        }}
      >
        {/* Indicador de loading */}
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
              backdropFilter: 'blur(2px)',
            }}
          >
            <Box
              sx={{
                p: 3,
                borderRadius: '8px',
                backgroundColor: 'white',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <CircularProgress size={30} color="primary" />
              <Typography variant="body2">Carregando empresas...</Typography>
            </Box>
          </Box>
        )}

        {empresas.length === 0 && !loading ? (
          <Box
            sx={{
              textAlign: 'center',
              py: 6,
              backgroundColor: '#f9f9f9',
              borderRadius: '8px',
              mb: 3,
              width: '100%',
            }}
          >
            <Typography variant="body1" color="textSecondary">
              Nenhuma empresa encontrada com os filtros aplicados.
            </Typography>
            {(search || tagFilter || nameFilter || documentFilter || descriptionFilter) && (
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClearAllFilters}
                  startIcon={<ClearIcon />}
                  sx={{ 
                    borderRadius: '8px',
                    borderColor: '#3f51b5',
                    color: '#3f51b5',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.08)',
                    },
                  }}
                >
                  Limpar todos os filtros
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              gap: '16px',
              marginBottom: '30px',
              width: '100%',
            }}
          >
            {empresas.map((empresa) => (
              <EmpresaCard
                key={empresa.id}
                empresa={empresa}
                handleCardClick={handleCardClick}
                tagFilter={tagFilter}
                handleTagSelect={handleTagSelect}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default EmpresaList
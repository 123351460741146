// src/styles/index.ts
import { styled } from "@mui/material/styles";
import { Box, Typography, Grid } from "@mui/material";

/**
 * Seção de formulário com espaçamento e bordas consistentes
 */
export const FormSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  border: `1px solid ${theme.palette.divider}`,
  transition: "all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)",
  "&:hover": {
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },
}));

/**
 * Título de seção consistente com ícone opcional
 */
export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  fontWeight: 600,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  "&::after": {
    content: '""',
    display: "block",
    height: "2px",
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.background.paper} 100%)`,
    flex: 1,
    marginLeft: theme.spacing(1),
  },
}));

/**
 * Grid para exibição de módulos em um layout responsivo
 */
export const ModulesGrid = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

/**
 * Seção para integrações com funis
 */
export const FunnelIntegrationSection = styled(Box)(({ theme }) => ({
  "& > :not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Container para exibição de mensagens/alertas
 */
export const AlertContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& > :not(:last-child)": {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Estilo para ícones consistentes nos formulários
 */
export const FormIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(1),
}));

/**
 * Container de botões com espaçamento consistente
 */
export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
  justifyContent: "flex-end",
}));

/**
 * Container para configurações condicionais
 */
export const ConditionalContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  marginTop: theme.spacing(2),
}));

/**
 * Layout de formulário responsivo
 */
export const FormGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

/**
 * Estilo para cards com hover e seleção
 */
export const SelectableCard = styled(Box)(
  ({ theme, selected }: { theme: any; selected: boolean }) => ({
    border: selected
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: selected
      ? theme.palette.action.selected
      : theme.palette.background.paper,
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: selected
        ? theme.palette.action.selected
        : theme.palette.action.hover,
    },
  }),
);

/**
 * Divider com margem consistente
 */
export const StyledDivider = styled(Box)(({ theme }) => ({
  height: "1px",
  backgroundColor: theme.palette.divider,
  margin: theme.spacing(3, 0),
}));

/**
 * Estilo para agrupar form controls
 */
export const FormControlGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

/**
 * Container para preview de conteúdo
 */
export const PreviewContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(1),
}));

/**
 * Estilos adicionais para o diálogo
 */
export const DialogHeaderStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #e0e0e0",
  bgcolor: "rgba(156, 39, 176, 0.08)",
  py: 1.5,
  px: 2,
};

export const DialogActionStyle = {
  p: 2,
  borderTop: "1px solid #e0e0e0",
};

/**
 * Lista com estilo para items de informação
 */
export const InfoList = styled("ul")(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "0.875rem",
  paddingLeft: theme.spacing(2.5),
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  "& li": {
    marginBottom: theme.spacing(0.5),
  },
}));

export default {
  FormSection,
  SectionTitle,
  ModulesGrid,
  FunnelIntegrationSection,
  AlertContainer,
  FormIcon,
  ButtonContainer,
  ConditionalContainer,
  FormGrid,
  SelectableCard,
  StyledDivider,
  FormControlGroup,
  PreviewContainer,
  DialogHeaderStyle,
  DialogActionStyle,
  InfoList,
};

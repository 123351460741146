import { useState, useCallback } from "react";
import { FilterOptions } from "../types";

export const useProdutoFilters = () => {
  const [search, setSearch] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [tipoFilter, setTipoFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const getFilterOptions = useCallback((): FilterOptions => {
    return {
      search,
      categoria: categoryFilter,
      tipoFilter,
    };
  }, [search, categoryFilter, tipoFilter]);

  const handleCategoryMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setCategoryAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleCategoryMenuClose = useCallback(() => {
    setCategoryAnchorEl(null);
  }, []);

  const handleCategorySelect = useCallback(
    (category: string) => {
      setCategoryFilter(category);
      handleCategoryMenuClose();
      setCurrentPage(1); // Reset para a primeira página ao filtrar
    },
    [handleCategoryMenuClose],
  );

  const clearCategoryFilter = useCallback(() => {
    setCategoryFilter("");
  }, []);

  const handleTipoFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setTipoFilter(event.target.name);
      } else {
        setTipoFilter("");
      }
      setCurrentPage(1); // Reset para a primeira página ao filtrar
    },
    [],
  );

  const handleSearchChange = useCallback((value: string) => {
    setSearch(value);
    setCurrentPage(1); // Reset para a primeira página ao pesquisar
  }, []);

  const clearAllFilters = useCallback(() => {
    setSearch("");
    setCategoryFilter("");
    setTipoFilter("");
    setCurrentPage(1);
  }, []);

  return {
    search,
    categoryFilter,
    categoryAnchorEl,
    tipoFilter,
    currentPage,
    setCurrentPage,
    getFilterOptions,
    handleCategoryMenuOpen,
    handleCategoryMenuClose,
    handleCategorySelect,
    clearCategoryFilter,
    handleTipoFilterChange,
    handleSearchChange,
    clearAllFilters,
  };
};

import React, { useState, useMemo } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import {
  Box,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  Badge,
  Divider,
  Chip,
  Collapse,
} from '@mui/material'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Settings as SettingsIcon,
  Person as PersonIcon,
  AttachMoney as MoneyIcon,
} from '@mui/icons-material'

import KanbanCard from './KanbanCard'
import IntegrationPanel from './IntegrationPanel'
import UserAssignment from './UserAssignment'

const KanbanColumn = ({ column, cards, onCardClick, onCardAction }) => {
  const [expanded, setExpanded] = useState(true)
  const [showSettings, setShowSettings] = useState(false)

  const handleToggleExpand = () => {
    setExpanded(!expanded)
  }

  const handleToggleSettings = () => {
    setShowSettings(!showSettings)
  }
  
  // Calcula o valor total de todos os cards na coluna
  const totalValue = useMemo(() => {
    const sum = cards.reduce((total, card) => {
      // Adiciona o valor do card ao total apenas se existir
      return total + (Number(card.value) || 0)
    }, 0)
    
    // Formata o valor total em formato de moeda brasileira
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(sum)
  }, [cards])

  return (
    <Box
      className="flex flex-col min-w-[280px] max-w-[320px] h-full mr-4 rounded-lg"
      style={{ backgroundColor: column.color || '#f1f5f9' }}
    >
      {/* Cabeçalho da coluna */}
      <Paper
        elevation={0}
        className="flex flex-col p-3 rounded-t-lg border-b"
      >
        {/* Linha superior com título e botões */}
        <Box className="flex justify-between items-center mb-2">
          <Box className="flex items-center">
            <Typography variant="subtitle1" className="font-medium">
              {column.title}
            </Typography>
            {column.closed ? (
              <>
                <Badge
                  badgeContent={cards.length}
                  color="primary"
                  className="ml-4"
                  showZero
                />
                <Badge
                  badgeContent="Final"
                  color="success"
                  className="ml-10"
                  showZero
                />
              </>
            ) : (
              <Badge
                badgeContent={cards.length}
                color="primary"
                className="ml-4"
                showZero
              />
            )}
          </Box>

          <Box className="flex">
            <Tooltip title="Configurações da coluna">
              <IconButton size="small" onClick={handleToggleSettings}>
                <SettingsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={expanded ? 'Recolher' : 'Expandir'}>
              <IconButton size="small" onClick={handleToggleExpand}>
                {expanded ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        
        {/* Linha inferior com somatório de valores e estatísticas */}
        <Box className="flex justify-between items-center">
          <Box className="flex items-center">
            <Typography variant="caption" className="text-gray-500 mr-2">
              {cards.length} {cards.length === 1 ? 'card' : 'cards'}
            </Typography>
          </Box>

          <Box className="flex items-center">
            {/* Somatório dos valores em destaque */}
            <Chip
              icon={<MoneyIcon fontSize="small" />}
              label={totalValue}
              size="small"
              color="success"
              sx={{ 
                fontWeight: 'bold', 
                '& .MuiChip-label': { fontWeight: 600 },
                display: 'flex'
              }}
            />
          </Box>

          {column.wip && (
            <Tooltip title="Limite de trabalho em progresso">
              <Chip
                label={`${cards.length}/${column.wip}`}
                size="small"
                color={cards.length > column.wip ? 'error' : 'default'}
                variant="outlined"
              />
            </Tooltip>
          )}
        </Box>
      </Paper>

      {/* Área de configurações da coluna (expandível) */}
      <Collapse in={showSettings}>
        <Paper className="p-3 mb-2 rounded-none shadow-none border-b">
          {/* Dono da coluna / Responsável */}
          <Box className="mb-2">
            <Typography variant="caption" className="text-gray-500 block">
              Responsável:
            </Typography>
            {column.assignedTo ? (
              <UserAssignment user={column.assignedTo} />
            ) : (
              <Chip
                icon={<PersonIcon fontSize="small" />}
                label="Não atribuído"
                size="small"
                variant="outlined"
                className="mt-1"
              />
            )}
          </Box>

          {/* Integrações */}
          {column.integrations && column.integrations.length > 0 && (
            <Box className="mt-3">
              <Typography variant="caption" className="text-gray-500 block">
                Integrações:
              </Typography>
              <IntegrationPanel integrations={column.integrations} />
            </Box>
          )}
        </Paper>
      </Collapse>

      {/* Container principal que ocupa todo o espaço restante */}
      <Box className="flex-grow flex flex-col overflow-hidden relative">
        {/* Área de cards com scroll */}
        <Droppable droppableId={column.id}>
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`flex-grow p-2 overflow-y-auto transition-colors ${
                expanded ? 'h-full' : 'h-0 p-0 overflow-hidden'
              } ${snapshot.isDraggingOver ? 'bg-blue-50' : ''}`}
              style={{
                backgroundColor: snapshot.isDraggingOver
                  ? '#e6f0fd'
                  : column.color || '#f1f5f9',
                minHeight: expanded ? '100px' : '0',
                maxHeight: expanded ? 'calc(100vh - 200px)' : '0',
              }}
            >
              {expanded &&
                cards.map((card, index) => (
                  <KanbanCard
                    key={card.id}
                    card={card}
                    index={index}
                    onClick={() => onCardClick(card)}
                    onAction={(action) => onCardAction(action, card)}
                  />
                ))}
              {provided.placeholder}

              {expanded && cards.length === 0 && (
                <Box className="flex items-center justify-center h-24 border-2 border-dashed border-gray-300 rounded-lg">
                  <Typography variant="body2" className="text-gray-500">
                    Arraste cards para esta coluna
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Droppable>
      </Box>

      {/* Rodapé simplificado da coluna */}
      <Paper
        elevation={0}
        className="p-2 border-t rounded-b-lg sticky bottom-0 z-10"
        sx={{ backgroundColor: '#fff' }}
      >
        <Typography variant="caption" className="text-gray-500">
          {column.closed ? 'Coluna finalizada' : 'Arraste para organizar'}
        </Typography>
      </Paper>
    </Box>
  )
}

export default KanbanColumn
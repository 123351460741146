import React from 'react';
import { 
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText, 
  Divider 
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  WhatsApp as WhatsAppIcon, 
  Email as EmailIcon, 
  FileCopy as CloneIcon, 
  Assignment as TaskIcon, 
  Person as AssignIcon,
  History as HistoryIcon,
  Print as PrintIcon,
  PermMedia as AttachmentIcon
} from '@mui/icons-material';

const ActionMenu = ({ 
  anchorEl, 
  open, 
  onClose, 
  onAction, 
  card 
}) => {
  const hasWhatsapp = card?.contact?.whatsapp;
  const hasEmail = card?.contact?.email;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        className: 'shadow-lg',
        sx: { minWidth: 200 }
      }}
    >
      <MenuItem onClick={() => onAction('edit')}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Editar</ListItemText>
      </MenuItem>
      
      {/* <MenuItem onClick={() => onAction('clone')}>
        <ListItemIcon>
          <CloneIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Duplicar</ListItemText>
      </MenuItem> */}
      
      {/* <MenuItem onClick={() => onAction('assign')}>
        <ListItemIcon>
          <AssignIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Atribuir</ListItemText>
      </MenuItem> */}
      
      <Divider />
      
      {hasWhatsapp && (
        <MenuItem onClick={() => onAction('whatsapp')}>
          <ListItemIcon>
            <WhatsAppIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Enviar WhatsApp</ListItemText>
        </MenuItem>
      )}
      
      {hasEmail && (
        <MenuItem onClick={() => onAction('email')}>
          <ListItemIcon>
            <EmailIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Enviar Email</ListItemText>
        </MenuItem>
      )}
      
      {/* <MenuItem onClick={() => onAction('task')}>
        <ListItemIcon>
          <TaskIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Adicionar Tarefa</ListItemText>
      </MenuItem>
      
      <MenuItem onClick={() => onAction('attachment')}>
        <ListItemIcon>
          <AttachmentIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Anexos</ListItemText>
      </MenuItem>
      
      <Divider />
       */}
      {/* <MenuItem onClick={() => onAction('history')}>
        <ListItemIcon>
          <HistoryIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Histórico</ListItemText>
      </MenuItem>
      
      <MenuItem onClick={() => onAction('print')}>
        <ListItemIcon>
          <PrintIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Imprimir</ListItemText>
      </MenuItem> */}
      
      <Divider />
      
      <MenuItem onClick={() => onAction('delete')} className="text-red-500">
        <ListItemIcon className="text-red-500">
          <DeleteIcon fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText className="text-red-500">Excluir</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default ActionMenu;
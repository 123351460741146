import React, { useState } from "react";

import * as S from "./styles";

import { IconClose, IconOrdernar } from "components/Icons";

type TFilterProps = {
  orderBy: (v: string) => void;
};

export const FilterListProfile = ({ orderBy }: TFilterProps) => {
  const [showDropFilter, setShowDropFilter] = useState(false)
  const [showDropOrdernar, setShowDropOrdenar] = useState(false)

  const [ordenarPor, setOrdernarPor] = useState(null)

  orderBy(ordenarPor)


  function resetFilterOrder() {
    setOrdernarPor('')
    setShowDropOrdenar(false)
  }

  console.log('showDorpFilter :', showDropFilter)

  return (
    <S.Wrapper>
      <S.ContentButtonOrdenar>
        <S.ButtonFilter
          isFilter={!!ordenarPor || showDropOrdernar}>

          <div onClick={() => setShowDropOrdenar(!showDropOrdernar)}>
            <IconOrdernar />
            Ordernar por

            {!!ordenarPor && (
              <div onClick={resetFilterOrder}>
                <IconClose />
              </div>
            )}
          </div>

         
        </S.ButtonFilter>

        {showDropOrdernar && (
          <>
            <div className="drop-order">
              <h4>Ordenar</h4>

              <div className="category-order">
                {/* <div>
                  <p>Criado em</p>
                </div> */}

                <label>
                  Criado em
                  <input
                    type="radio"
                    id="criado_mais_recente"
                    name="criadoEm"
                    value={'created_at'}
                    checked={ordenarPor === 'created_at'}
                    onChange={(e) => setOrdernarPor('created_at')}
                  />
                </label>

                <label>
                  Última atualização
                  <input
                    type="radio"
                    id="criado_mais_antigos"
                    name="criadoEm"
                    value={'updated_at'}
                    checked={ordenarPor === 'updated_at'}
                    onChange={(e) => setOrdernarPor('updated_at')}
                  />
                </label>
              </div>
            </div>

            <div
              onClick={() => setShowDropOrdenar(!showDropOrdernar)}
              className="overlay-drop-order"
            ></div>
          </>
        )}
      </S.ContentButtonOrdenar>
    </S.Wrapper>
  )
};
